import Instance from '../../../Axios';

// New Actions for the app

const GetUtilizadores = (perfilId) => async (dispatch) => {
  await Instance()
    .get(`/utilizadores`, { params: { perfilId } })
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: "GET_UTILIZADORES", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetUtilizadores;
