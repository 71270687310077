/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  useTheme,
  Tooltip,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import "moment/locale/pt";
import MUIDataTable from "mui-datatables";

// Components
import FooterLinks from "../../../../Global/Components/Footer";
import SideDrawer from "./components/drawer";

//Icons
import FeatherIcon from "feather-icons-react";
import GetAllCredDestinatarios from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetAllCredDestinatariosActions";
import GetCredDestinatarioById from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredDestinatarioByIdAction";

//Actions

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
  },
  btnFiltrar: {
    color: "#E21450",
    width: "150px",
    height: "40px",
    fontWeight: "900",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  stateLabel: {
    textTransform: "none",
    transition: "0.2s",
    display: "flex",
    alignItems: "center",
  },

  paperFilters: {
    background: "transparent",
    padding: "20px 0px",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    height: 40,
    borderTop: "4px solid transparent",
    borderBottom: "4px solid transparent",

    "&:hover": {
      borderRight: "4px solid #E21450",
    },
  },
}));

// ############################################################## //
// ####################  Window Dimensions  ##################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const CredDestinatarios = () => {
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");
  const [destinatario, setDestinatario] = useState({});

  const resetDestinatario = () => {
    setDestinatario({});
  };

  // ############################################################## //
  // ######################  Autocompletes  ####################### //
  // ############################################################## //

  const destinatariosState = useSelector(
    (state) => state.credenciais.credDestinatarios
  );

  const permissoes = useSelector((state) => state.user.permissoes);

  const fetchAllCredDestinatarios = () => {
    dispatch(GetAllCredDestinatarios());
  };

  useEffect(() => {
    fetchAllCredDestinatarios();
  }, []);

  // const user = useSelector((state) => state.user);

  // ############################################################## //
  // #########################  Drawer  ########################### //
  // ############################################################## //

  const fetchCredDestinatarioById = (id) => {
    dispatch(GetCredDestinatarioById(id));
  };

  const [destId, setDestId] = useState();

  const [drawerWidth, setDrawerWidth] = useState("90vw");
  const dimensions = useWindowDimensions();
  const [state, setState] = useState({
    right: false,
  });
  const [drawerState, setDrawerState] = useState(null);
  const toggleDrawer = (anchor, open, id) => {
    if (open == false) {
      setState({ ...state, [anchor]: open });
      resetDestinatario();
    }
    if (open == true) {
      setState({ ...state, [anchor]: open });
      setDestId(id);
      fetchCredDestinatarioById(id);
    }
  };

  // ############################################################## //
  // #######################  Modal Delete  ####################### //
  // ############################################################## //

  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = (e, id) => {
    e.preventDefault();
    setDestId(id);
    setLoading(false);
    setOpenDelete(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpenDelete(false);
  };

  // ############################################################## //
  // #########################  Messages  ######################### //
  // ############################################################## //

  const messageEmail = {
    isOpen: true,
    message: "Email enviado com sucesso!!",
    type: "success",
  };

  // ############################################################## //
  // ##################  MUI Datatable columns  ################### //
  // ############################################################## //

  const columns = [
    {
      name: "id",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "descricao",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "contact",
      label: "Contacto",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const phoneNumber = value && value.replace(/\D/g, "");

          // Insert spaces at intervals
          const formattedNumber =
            phoneNumber &&
            phoneNumber.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? " - " : formattedNumber}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { width: "5%" },
          align: "center",
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const o = tableMeta.rowData[0];

          return (
            <div style={{ display: "flex", float: "right" }}>
              {permissoes.includes("APP_WORK_PERMITS_RESPONSAVEIS_EDITAR") && (
                <IconButton
                  onClick={() => {
                    setDrawerState("edit");
                    toggleDrawer("right", true, o);
                  }}
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                >
                  <FeatherIcon
                    icon="edit"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              )}
              {/* {permissoes.includes("APP_WORK_PERMITS_RESPONSAVEIS_REMOVER") && (
                <IconButton
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                  onClick={(e) => {
                    handleOpen(e, o);
                  }}
                >
                  <FeatherIcon
                    icon="trash-2"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              )} */}
            </div>
          );
        },
      },
    },
  ];

  // ############################################################## //
  // ##################  MUI Datatable Options  ################### //
  // ############################################################## //

  const searchText =
    location.state && location.state.funcionarioNome
      ? location.state.funcionarioNome
      : null;

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    searchText: searchText,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },

    customToolbar: () => {
      return (
        <>
          {permissoes.includes("APP_WORK_PERMITS_RESPONSAVEIS_ADICIONAR") && (
            <Tooltip title={"Adicionar Responsável"}>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  setDrawerState("add");
                  toggleDrawer("right", true, null);
                }}
              >
                <FeatherIcon
                  icon="plus-square"
                  size={22}
                  className={classes.deleteIcon}
                  style={{ strokeWidth: "2" }}
                />
              </IconButton>
            </Tooltip>
          )}
        </>
      );
    },
  };

  // const handleSubmit = () => {
  //   const entityId = entityFilter.id;

  //   fetchResponsaveisWorkPermits(entityId);
  // };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mr: 10,
          width: "100%",
        }}
      >
        <SideDrawer
          state={state}
          toggleDrawer={toggleDrawer}
          drawerState={drawerState}
          drawerWidth={drawerWidth}
          dimensions={dimensions}
          destinatario={destinatario}
          setDestinatario={setDestinatario}
          resetDestinatario={resetDestinatario}
          fetchAllCredDestinatarios={fetchAllCredDestinatarios}
          destId={destId}
        />
        {/* <ModalDelete
          openDelete={openDelete}
          handleClose={handleClose}
          respId={respId}
          fetchAllCredDestinatarios={fetchAllCredDestinatarios}
          loading={loading}
          setLoading={setLoading}
        /> */}
        <Box sx={{ width: "100%" }}>
          {/* <Box
            sx={{
              mx: 5,
              mt: 5,
              width: "100%",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/construcoes/obras">
                Gestão Aeroportuária
              </Link>
              <Typography color="text.primary">Responsáveis</Typography>
            </Breadcrumbs>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Box sx={{ width: "240px" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Entidade
                </Typography>
                <Autocomplete
                  fullWidth
                  name="entity"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  value={entityFilter}
                  className="unidadesId-form"
                  options={entities || []}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setEntityFilter({ id: null, descricao: "" });
                      return;
                    } else {
                      setEntityFilter(value);
                      return value;
                    }
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "end",
                }}
              >
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  className={classes.btnFiltrar}
                >
                  Filtrar
                </Button>
              </Box>
            </Box>
          </Box> */}
          <div
            style={{
              margin: "40px 40px 40px 40px",
              width: "100%",
            }}
          >
            <MUIDataTable
              title={
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  className="text-underline"
                >
                  Lista de Destinatários
                </span>
              }
              data={destinatariosState || []}
              columns={columns}
              options={options}
            />
          </div>
          <div style={{ margin: "20px 40px" }}>
            <FooterLinks />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default CredDestinatarios;
