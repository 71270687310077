import Instance from "../../../../Axios";

// New Actions for the app

const GetValorAdjudicado =
  (parceirosId, dataInicio, dataFim) => async (dispatch) => {
    await Instance()
      .get(`/energias/dashboard/valorAdjudicado`, {
        params: { parceirosId, dataInicio, dataFim },
      })
      .then(({ data }) => {
        //   console.log(data);
        dispatch({
          type: "GET_VALOR_ADJUDICADO",
          payload: data.yData[0].valorAdjudicado,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default GetValorAdjudicado;
