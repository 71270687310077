import axios from 'axios';
import BASE_URL from '../../BASE_URL';

// New Actions for the app
const SignIn =
  (ydata, navigate, ShowNotification, message, errorMessage) =>
  async (dispatch) => {
    console.log(ydata);
    await axios
      .post(`${BASE_URL}/auth/user/login`, { ydata })
      .then(({ data }) => {
        if (data.token) {
          dispatch({ type: 'SIGN_IN', payload: data });
          localStorage.setItem('token', data.token);
          // localStorage.setItem('permissao', 'UTILIZADORES');
          dispatch(ShowNotification(message));
          navigate('/dashboard');
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          if (err.response.status === 403 || err.response.status === 401) {
            const warningMessage = {
              isOpen: true,
              message: err.response.data.message,
              type: 'warning',
            };
            dispatch(ShowNotification(warningMessage));
          }
        } else {
          dispatch(ShowNotification(errorMessage));
        }
      });
  };

export default SignIn;
