import React from 'react';
import { TextField, IconButton } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { Controller } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptLocale from 'date-fns/locale/pt';
import { useEffect } from 'react';

const calendarIcon = () => {
  return <FeatherIcon icon='calendar' size={20} />;
};

const HistoricoEsperas = ({
  intervencaoSelecionada,
  setIntervencaoSelecionada,
  control,
  getValues,
  resetForm,
  setResetForm,
  reset,
  watch,
}) => {
  const intervencao = getValues();

  useEffect(() => console.log(intervencao), [intervencao]);

  const handleRemoveSub = async (espera, index, obraId, setObraId) => {
    await setResetForm(true);

    if (index !== -1) {
      intervencao.esperas.splice(index, 1);
    }

    await setIntervencaoSelecionada({ ...intervencao });
    await setResetForm(false);
    await reset({ ...intervencao });
  };

  return (
    <>
      <div
        style={{
          width: '100%',
        }}
      >
        {intervencaoSelecionada &&
          intervencaoSelecionada.esperas &&
          intervencaoSelecionada.esperas.map((espera, index) => (
            <div
              key={index}
              style={{
                background: 'white',
                minHeight: '40px',
                marginTop: '10px',
                marginBottom: '10px',
                border: '1px solid #707070',
                borderRadius: '8px',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  padding: '5px',
                }}
              >
                <form
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  autocomple='off'
                >
                  <>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <IconButton
                        aria-label='account of current user'
                        aria-controls='menu-appbar'
                        aria-haspopup='true'
                        onClick={() => handleRemoveSub(espera, index)}
                        color='inherit'
                      >
                        <FeatherIcon icon='x-square' size={20} />
                      </IconButton>

                      <Controller
                        name={`esperas[${index}][dataInicio]`}
                        control={control}
                        render={({ field }) => (
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={ptLocale}
                          >
                            <DatePicker
                              invalidDateMessage='Data Inválida'
                              disabled
                              {...field}
                              components={{
                                OpenPickerIcon: calendarIcon,
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: 'dd/mm/aaaa',
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                  }}
                                  variant='standard'
                                  size='small'
                                  sx={{
                                    width: '12%',
                                    marginRight: '2.5%',
                                    marginTop: '2px',
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />

                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={ptLocale}
                      >
                        <Controller
                          name={`esperas[${index}][dataFim]`}
                          control={control}
                          render={({
                            field: { onChange, value },
                            fieldState: { error, invalid },
                          }) => (
                            <>
                              <DatePicker
                                inputFormat='dd/MM/yyyy'
                                invalidDateMessage='Data Inválida'
                                disabled
                                minDate={
                                  new Date(
                                    watch(`esperas[${index}][dataInicio]`)
                                  )
                                }
                                value={value}
                                components={{
                                  OpenPickerIcon: calendarIcon,
                                }}
                                onChange={(value) => {
                                  onChange(value);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={invalid}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: 'dd/mm/aaaa',
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      disableUnderline: true,
                                    }}
                                    variant='standard'
                                    size='small'
                                    sx={{
                                      width: '12%',
                                      marginRight: '2.5%',
                                      marginTop: '2px',
                                    }}
                                  />
                                )}
                              />
                            </>
                          )}
                        />
                      </LocalizationProvider>

                      <Controller
                        name={`esperas[${index}][observacoes]`}
                        control={control}
                        render={({ field }) => (
                          <>
                            <TextField
                              placeholder='Observações'
                              multiline
                              disabled
                              color='primary'
                              {...field}
                              style={{ width: '75%' }}
                              variant='standard'
                              InputProps={{ disableUnderline: true }}
                            />
                          </>
                        )}
                      />
                    </div>
                  </>
                </form>
              </div>
            </div>
          ))}
        <div style={{ display: 'flex' }}></div>
      </div>
    </>
  );
};

export default HistoricoEsperas;
