import Instance from "../../../Axios";

const GetClientById = (id) => async (dispatch) => {
  await Instance()
    .get(`/plus/adesoes/clients/profiles/${id}`)
    .then(({ data }) => {
      dispatch({ type: "GET_CLIENT_BY_ID", payload: data.yData[0] });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetClientById;
