import Instance from "../../../../Axios";

// New Actions for the app

const GetAllWorkPermitAccountables = (entityId) => async (dispatch) => {
  const entity = entityId ? entityId : null;
  await Instance()
    .get(`/construcoes/workpermits/allAccountables`, {
      params: { entity: entity },
    })
    .then(({ data }) => {
      // console.log(data);
      dispatch({
        type: "GET_ALL_WORK_PERMIT_ACCOUNTABLES",
        payload: data.yData,
      });
      // setObraSelecionada(data.yData[0]);
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetAllWorkPermitAccountables;
