import React from 'react';
import { TextField } from '@mui/material';

import { format } from 'date-fns';

const HistoricoEsperas = ({ intervencaoSelecionada }) => {
  return (
    <>
      <div
        style={{
          width: '80%',
        }}
      >
        <h4
          style={{ color: '#E21450' }}
          className='text-underline-title-drawer'
        >
          Esperas
        </h4>
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '5px',
            background: '#F1F1F1',
            border: '1px solid #707070',
            borderRadius: '8px',
          }}
        >
          <h4
            style={{
              width: '13%',
              margin: '10px 0px 10px 10px',
              padding: '0px',
            }}
          >
            Data Inicial
          </h4>
          <h4
            style={{
              width: '13%',
              margin: '10px 0px 10px 10px',
              padding: '0px',
            }}
          >
            Data Final
          </h4>

          <h4
            style={{
              width: '21.7%',
              margin: '10px 0px 10px 0px',
              padding: '0px',
            }}
          >
            Observações
          </h4>
        </div>
        {intervencaoSelecionada &&
          intervencaoSelecionada.esperas &&
          intervencaoSelecionada.esperas.map((espera, index) => (
            <div
              key={index}
              style={{
                background: 'white',
                minHeight: '40px',
                marginTop: '10px',
                marginBottom: '10px',
                border: '1px solid #707070',
                borderRadius: '8px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  padding: '5px',
                }}
              >
                <form
                  style={{ width: '100%', display: 'flex' }}
                  autocomple='off'
                >
                  <TextField
                    size='small'
                    value={
                      (espera.dataInicio &&
                        format(new Date(espera.dataInicio), 'dd/MM/yyyy')) ||
                      ' '
                    }
                    disabled
                    style={{
                      width: '11.5%',
                      marginRight: '2.5%',
                      marginLeft: '1%',
                    }}
                    variant='standard'
                    placeholder='Selecione uma opção'
                    InputProps={{ disableUnderline: true }}
                  />

                  <TextField
                    size='small'
                    value={
                      (espera.dataFim &&
                        format(new Date(espera.dataFim), 'dd/MM/yyyy')) ||
                      ' '
                    }
                    disabled
                    style={{
                      width: '10.5%',
                      marginRight: '2.5%',
                    }}
                    variant='standard'
                    placeholder='Selecione uma opção'
                    InputProps={{ disableUnderline: true }}
                  />
                  <TextField
                    size='small'
                    value={espera.observacoes || ' '}
                    disabled
                    multiline
                    style={{
                      width: '72.5%',
                    }}
                    variant='standard'
                    placeholder='Observações'
                    InputProps={{ disableUnderline: true }}
                  />
                </form>
              </div>
            </div>
          ))}
        <div style={{ display: 'flex' }}></div>
      </div>
    </>
  );
};

export default HistoricoEsperas;
