/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Drawer } from "@mui/material";

//COMPONENTS
import FormAdd from "./formAdd";
import FormEdit from "./formEdit";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
});

const SideDrawer = ({
  state,
  toggleDrawer,
  drawerState,
  drawerWidth,
  dimensions,
  responsavel,
  setResponsavel,
  resetResponsavel,
  entities,
  entity,
  setEntity,
  checkDefault,
  setCheckDefault,
  checkSendEmail,
  setCheckSendEmail,
  checkSendEmailDefault,
  setCheckSendEmailDefault,
  checkCCEmails,
  setCheckCCEmails,
  fetchResponsaveisWorkPermits,
  respId,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={() => toggleDrawer("right", false)}
        classes={{ paper: classes.paper }}
      >
        {drawerState === "edit" ? (
          <FormEdit
            toggleDrawer={toggleDrawer}
            drawerWidth={drawerWidth}
            dimensions={dimensions}
            responsavel={responsavel}
            setResponsavel={setResponsavel}
            resetResponsavel={resetResponsavel}
            entities={entities}
            entity={entity}
            setEntity={setEntity}
            checkDefault={checkDefault}
            setCheckDefault={setCheckDefault}
            checkSendEmail={checkSendEmail}
            setCheckSendEmail={setCheckSendEmail}
            checkSendEmailDefault={checkSendEmailDefault}
            setCheckSendEmailDefault={setCheckSendEmailDefault}
            checkCCEmails={checkCCEmails}
            setCheckCCEmails={setCheckCCEmails}
            fetchResponsaveisWorkPermits={fetchResponsaveisWorkPermits}
            respId={respId}
          />
        ) : (
          <FormAdd
            toggleDrawer={toggleDrawer}
            drawerWidth={drawerWidth}
            dimensions={dimensions}
            responsavel={responsavel}
            setResponsavel={setResponsavel}
            resetResponsavel={resetResponsavel}
            entities={entities}
            entity={entity}
            setEntity={setEntity}
            checkDefault={checkDefault}
            setCheckDefault={setCheckDefault}
            checkSendEmail={checkSendEmail}
            setCheckSendEmail={setCheckSendEmail}
            checkSendEmailDefault={checkSendEmailDefault}
            setCheckSendEmailDefault={setCheckSendEmailDefault}
            checkCCEmails={checkCCEmails}
            setCheckCCEmails={setCheckCCEmails}
            fetchResponsaveisWorkPermits={fetchResponsaveisWorkPermits}
          />
        )}
      </Drawer>
    </div>
  );
};

export default SideDrawer;
