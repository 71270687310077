import moment from "moment";
import Instance from "../../../../Axios";
import NotifyCredenciacoes from "./NotifyCredenciacoesActions";

// New Actions for the app

const AddCredenciacao =
  (
    yData,
    copiaCredenciacao,
    copiaCredenciacaoExterna,
    ficheiros,
    datePickerValues,
    toggleDrawer,
    ShowNotification,
    message,
    errorMessage,
    messageEmail,
    messageEmailErro,
    estado,
    tipoCredSel,
    fetchCredenciacoes
  ) =>
  async (dispatch) => {
    const formdata = new FormData();
    formdata.append("yData", JSON.stringify(yData[0]));

    if (
      copiaCredenciacao &&
      Array.isArray(copiaCredenciacao) &&
      copiaCredenciacao.length > 0
    ) {
      formdata.append("copiaCredenciacao", copiaCredenciacao[0].file);
      formdata.append("copiaCredenciacaoidtipo", copiaCredenciacao[0].idTipo);
      formdata.append("copiaCredenciacaopath", copiaCredenciacao[0].path);
    }

    if (
      copiaCredenciacaoExterna &&
      Array.isArray(copiaCredenciacaoExterna) &&
      copiaCredenciacaoExterna.length > 0
    ) {
      formdata.append(
        "copiaCredenciacaoExterna",
        copiaCredenciacaoExterna[0].file
      );
      formdata.append(
        "copiaCredenciacaoExternaidtipo",
        copiaCredenciacaoExterna[0].idTipo
      );
      formdata.append(
        "copiaCredenciacaoExternapath",
        copiaCredenciacaoExterna[0].path
      );
    }

    if (ficheiros && Array.isArray(ficheiros) && ficheiros.length > 0) {
      ficheiros.forEach((ficheiro, index) => {
        if (ficheiro) {
          formdata.append(`file${index}`, ficheiro.file);
          formdata.append(`idTipo${index}`, ficheiro.idTipo);
          formdata.append(`path${index}`, ficheiro.path);

          const datePickerValue = datePickerValues[index];
          if (datePickerValue) {
            formdata.append(
              `datePicker${index}`,
              moment(datePickerValue).format("YYYY-MM-DD")
            );
          }
        }
      });
    }

    await Instance()
      .post(`/credenciacoes/cred`, formdata)
      .then(({ data }) => {
        //   console.log(data);
        dispatch({ type: "ADD_CREDENCIACAO", payload: data.data });
        dispatch(ShowNotification(message));

        // toggleDrawer("right", false);
        // dispatch(SendCredNotifications(data.yData, estado.id));
        dispatch(NotifyCredenciacoes(data.data.insertId, estado.id));
        // if (estado.id == 3) {
        //   if (tipoCredSel.id == 2 || tipoCredSel.id == 3) {
        //     dispatch(
        //       SendCredPontual(
        //         data.yData,
        //         ShowNotification,
        //         messageEmail,
        //         messageEmailErro
        //       )
        //     );
        //   }
        //   if (tipoCredSel.id == 5) {
        //     dispatch(
        //       SendCredPontualViatura(
        //         data.yData,
        //         ShowNotification,
        //         messageEmail,
        //         messageEmailErro
        //       )
        //     );
        //   }
        // }

        return Promise.resolve();
      })
      .then(() => {
        fetchCredenciacoes();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response === 403) {
            dispatch(ShowNotification(errorMessage));
          } else {
            dispatch(ShowNotification(errorMessage));
          }
        }
      });
  };

export default AddCredenciacao;
