import Instance from "../../../Axios";

const GetAdesaoHistoricoById = (id) => async (dispatch) => {
  await Instance()
    .get(`/plus/adesoes/historico/${id}`)
    .then(({ data }) => {
      dispatch({ type: "GET_ADESAO_HISTORICO_BY_ID", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetAdesaoHistoricoById;
