import Instance from '../../../../Axios';

// New Actions for the app

const GetSubMateriais = () => async (dispatch) => {
  await Instance()
    .get(`/energias/tiposSubMateriais`)
    .then(({ data }) => {
      //   console.log(data);
      dispatch({ type: 'GET_ENERGIAS_SUB_MATERIAIS', payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetSubMateriais;
