import Instance from "../../../../Axios";

// New Actions for the app

const NotifyCredenciacoes = (id, estado) => async (dispatch) => {
  await Instance()
    .get(`/credenciacoes/notificacoes/changeStatesCredenciacoes`, {
      params: { id: id, estado: estado },
    })
    .then(({ data }) => {
      // console.log(data);
      //fetchCredenciacoes();
    })
    .catch((err) => {
      console.log(err);
    });
};

export default NotifyCredenciacoes;
