import Instance from '../../../../Axios';

// New Actions for the app

const GetEstadosProjeto = () => async (dispatch) => {
  await Instance()
    .get(`/construcoes/estadosProjetos`)
    .then(({ data }) => {
      //   console.log(data);
      dispatch({ type: 'GET_ESTADOS_PROJETO', payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetEstadosProjeto;
