import Instance from "../../../Axios";

const GetPlusStatus = () => async (dispatch) => {
  await Instance()
    .get(`/plus/adesoes/data/status`)
    .then(({ data }) => {
      dispatch({ type: "GET_PLUS_STATUS", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetPlusStatus;
