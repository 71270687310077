import moment from "moment";
import Instance from "../../../../Axios";

// New Actions for the app

const GetCredAcompanhantesDisponibilidade = (dates) => async (dispatch) => {
  const datas = dates.map((date) => {
    return moment(date).format("YYYY-MM-DD");
  });
  await Instance()
    .get(`/credenciacoes/acompanhantesDisponibilidade`, {
      params: {
        dates: datas.join(","), // Join the array into a comma-separated string
      },
    })
    .then(({ data }) => {
      dispatch({
        type: "GET_CRED_ACOMPANHANTES_DISPONIBILIDADE",
        payload: data.yData,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetCredAcompanhantesDisponibilidade;
