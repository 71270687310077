import { utilizadoresInitialState } from '../initialState/utilizadores';

const utilizadoresReducer = (
  state = utilizadoresInitialState,
  { type, payload }
) => {
  switch (type) {
    case 'GET_UTILIZADORES':
      return {
        ...state,
        utilizadores: payload,
      };
    case 'GET_UTILIZADOR':
      return {
        ...state,
        utilizador: payload,
      };
    case 'POST_UTILIZADOR':
      return {
        ...state,
        novoUtilizador: payload,
      };
    case 'DELETE_UTILIZADOR':
      return {
        ...state,
        utilizadorRemovido: payload,
      };

    case 'GET_PERFIS':
      return {
        ...state,
        perfis: payload,
      };
    case 'GET_DEPARTAMENTOS':
      return {
        ...state,
        departamentos: payload,
      };
    case 'GET_PERMISSOES':
      return {
        ...state,
        permissoes: payload,
      };

    case 'USER_LOGOUT':
      return {
        ...utilizadoresInitialState,
      };

    default:
      return state;
  }
};

export default utilizadoresReducer;
