/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

//IMAGES
import LoginLeft from '../../../assets/images/login-left.png';

//ACTIONS
import DadosSenhaUtilizador from '../../../utils/redux/actions/senha/dadosSenhaAction';

//COMPONENTS
import SenhaJaAssociada from './components/senhaJaAssociada';
import FormDefinirSenha from './components/formDefinirSenha';
import Spinner from '../../../Global/Components/Spinner';
import FooterLinks from '../../../Global/Components/Footer';

const useStyles = makeStyles({
  btnGuardar: {
    color: 'white',
    width: '120px',
    height: '40px',
    // borderRadius: '15px',
    backgroundColor: '#E21450',
    border: `1px solid #E21450`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#bc1041',
      border: '1px solid #bc1041',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#bc1041',
      },
      '&:disabled': {
        backgroundColor: '#bc1041',
      },
    },
  },
});

const DefinirSenha = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hash = location.pathname.split('/')[2];

  const [component, setComponent] = useState('');

  const fetchHash = () => {
    dispatch(DadosSenhaUtilizador(hash, setComponent));
  };

  useEffect(() => fetchHash(), []);

  const dados = useSelector((state) => state.senha.dadosDefSenha);

  const storage = localStorage;

  return (
    <>
      {storage.token ? (
        navigate('/dashboard')
      ) : (
        <div className='home'>
          <div className='home-left'>
            <div className='left-content'>
              <div className='left-content-inside'>
                <img src={LoginLeft} alt='' />
              </div>
            </div>
          </div>
          <div className='home-right'>
            <div className='login-form'>
              <div className='titulo'>
                <h3>Definir senha</h3>
                <p>Plataforma de Gestão Grupo JAF</p>
              </div>

              {dados == [] ? (
                fetchHash()
              ) : (
                <div style={{ width: '100%' }}>
                  {component === '404' && (
                    <SenhaJaAssociada
                      onSubmit
                      form='log_in_form'
                      dispatch={dispatch}
                      classes={classes}
                    />
                  )}
                  {component === 'form' && (
                    <FormDefinirSenha
                      onSubmit
                      form='log_in_form'
                      dispatch={dispatch}
                      dados={dados}
                      hash={hash}
                      classes={classes}
                    />
                  )}
                  {component === '' && (
                    <div style={{ marginTop: '40px' }}>
                      <Spinner />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className='bottom'>
              <div style={{ padding: '20px 40px' }}>
                <FooterLinks />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DefinirSenha;
