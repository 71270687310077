import Instance from "../../../Axios";

const GetPlusTipoDocsCompExc = () => async (dispatch) => {
  await Instance()
    .get(`/plus/adesoes/data/tipoDocsCompExc`)
    .then(({ data }) => {
      dispatch({ type: "GET_PLUS_TIPO_DOCS_COMP_EXC", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetPlusTipoDocsCompExc;
