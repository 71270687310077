import Instance from "../../../../Axios";

// New Actions for the app

const AddCredDestinatario = (yData) => async (dispatch) => {
  await Instance()
    .post(`/credenciacoes/credDestinatario`, { yData })
    .then(({ data }) => {
      //   console.log(data);
      dispatch({ type: "ADD_CRED_DESTINATARIO", payload: data.yData[0] });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default AddCredDestinatario;
