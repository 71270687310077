import Instance from '../../../../Axios';

// New Actions for the app

const GetObrasFotos = (obraId) => async (dispatch) => {
  await Instance()
    .get(`/construcoes/obras/${obraId}/fotos`)
    .then(({ data }) => {
      dispatch({ type: 'GET_OBRAS_FOTOS', payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetObrasFotos;