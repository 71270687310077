import Instance from "../../../../Axios";

// New Actions for the app

const GetWorkPermitById = (idwp) => async (dispatch) => {
  await Instance()
    .get(`/construcoes/workpermits/workpermit/${idwp}/workpermit`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({ type: "GET_WORK_PERMIT", payload: data.yData[0] });
      // setObraSelecionada(data.yData[0]);
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetWorkPermitById;
