import Instance from "../../../../Axios";

// New Actions for the app

const GetLastDocumentosOficiais = () => async (dispatch) => {
  await Instance()
    .get(`/energias/docsOficiais/last/docsOficiais`)
    .then(({ data }) => {
      dispatch({ type: "GET_LAST_DOCUMENTOS_OFICIAIS", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetLastDocumentosOficiais;
