import React from 'react';
import { TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";

const MaoDeObra = ({
  control,
}) => {
  return (
    <>
      <div style={{ width: "18%" }}>
        <h4
          style={{ color: "#E21450" }}
          className="text-underline-title-drawer "
        >
          Dimensão das equipas
        </h4>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "47%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Contratados{" "}
            </Typography>
            <Controller
              name="maoObraContratada"
              control={control}
              render={({ field }) => (
                <>
                  <TextField
                    // label='Contratados'
                    color="primary"
                    variant="outlined"
                    size="small"
                    {...field}
                    style={{ width: "100%" }}
                  />
                </>
              )}
            />
          </div>
          <div style={{ width: "47%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              JAF
            </Typography>
            <Controller
              name="maoObraJaf"
              control={control}
              render={({ field }) => (
                <>
                  <TextField
                    name="maoObraJaf"
                    // label='JAF'
                    color="primary"
                    variant="outlined"
                    size="small"
                    {...field}
                    autoComplete="off"
                    style={{ width: "100%" }}
                  />
                </>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MaoDeObra;
