/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, styled } from "@mui/styles";
import {
  Typography,
  Box,
  Card,
  ListItem,
  TextField,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import "moment/locale/pt";
import ptLocale from "date-fns/locale/pt";
import { startOfYear } from "date-fns";
import pt from "apexcharts/dist/locales/pt.json";
import ReactPDF, { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import jsPDF from "jspdf";
import ReactApexChart from "react-apexcharts";
import logo from "../../../../assets/images/grupoJaf_logo.png";

// Components
import FooterLinks from "../../../../Global/Components/Footer";
import FilterDrawer from "./components/filterDrawer";
import { ReactComponent as Loader } from "../../../../assets/images/loader.svg";

//Actions
import GetIntervencoesTimeline from "../../../../utils/redux/actions/intervencoes/GetIntervencoesTimeline";
import GetDepartamentos from "../../../../utils/redux/actions/utilizadores/GetDepartamentosAction";
import GetAeroportosTimeline from "../../../../utils/redux/actions/intervencoes/GetAeroportosTimeline";
import { breakpoints } from "@mui/system";
import { LoadingButton } from "@mui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "900",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  stateLabel: {
    textTransform: "none",
    transition: "0.2s",
    display: "flex",
    alignItems: "center",
    maxWidth: "100px",
  },

  paperFilters: {
    background: "transparent",
    padding: "20px 0px",
    boxShadow: "none",
  },
}));

// ############################################################## //
// ####################  Window Dimensions  ##################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const IntervencoesTimeline = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const timeline = useSelector(
    (state) => state.intervencoes.intervencoesTimeline
  );

  const [guardarLoading, setGuardarLoading] = useState(false);

  //DRAWER
  const [drawerWidth, setDrawerWidth] = useState("25vw");
  const dimensions = useWindowDimensions();
  const [state, setState] = useState({
    right: false,
  });
  const [drawerState, setDrawerState] = useState(null);
  const toggleDrawer = (anchor, open) => {
    if (open == false) {
      setState({ ...state, [anchor]: open });
    }
    if (open == true) {
      setState({ ...state, [anchor]: open });
    }
  };

  // FILTERS
  const situacoes = useSelector((state) => state.intervencoes.timelineSit);

  let situacoesPreSelectedId = useSelector(
    (state) => state.intervencoes.timelineSitPreSelected
  );

  const dataInicial = useSelector(
    (state) => state.intervencoes.timelineDataInicio
  );
  const dataFinal = useSelector((state) => state.intervencoes.timelineDataFim);

  const [dataInicio, setDataInicio] = useState(dataInicial);
  const [dataFim, setDataFim] = useState(dataFinal);

  const aeroportos = useSelector(
    (state) => state.intervencoes.aeroportosTimeline
  );
  const aeroporto = useSelector(
    (state) => state.intervencoes.aeroportoSelected
  );

  const [aeroportoId, setAeroportoId] = useState(aeroporto);

  const departamentos = useSelector(
    (state) => state.utilizadores.departamentos
  );
  const departamento = useSelector(
    (state) => state.intervencoes.timelineDepartamentoSelected
  );
  const [departamentoId, setDepartamentoId] = useState(departamento);

  const fetchDepartamentos = (aeroportoId) => {
    dispatch(GetDepartamentos(aeroportoId));
  };

  useEffect(() => fetchDepartamentos(aeroportoId), [aeroportoId]);

  const fetchAeroportos = () => {
    dispatch(GetAeroportosTimeline());
  };

  useEffect(() => fetchAeroportos(), []);

  const fetchIntervencoesTimeline = (
    dataInicial,
    dataFinal,
    aeroportoId,
    departamentoId,
    situacoesId
  ) => {
    dispatch(
      GetIntervencoesTimeline(
        dataInicial,
        dataFinal,
        aeroportoId,
        departamentoId,
        situacoesId
      )
    );
  };

  useEffect(() => {
    const situacoesId = situacoesPreSelectedId.map((sit) => sit.id);
    fetchIntervencoesTimeline(
      dataInicio,
      dataFim,
      aeroportoId,
      departamentoId,
      situacoesId
    );
  }, []);

  timeline.map((item) => {
    item.data_inicial = moment(item.data_inicial).format("YYYY-MM-DD");
    item.data_final = moment(item.data_final).format("YYYY-MM-DD");
  });

  const [timelineState, setTimelineState] = useState({
    series: [],
    options: {
      chart: {
        id: "basic-bar",
        type: "rangeBar",
        locales: [pt],
        defaultLocale: "pt",
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      xaxis: {
        type: "datetime",
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        labels: {
          align: "left",
          maxWidth: 600,
          style: {
            fontSize: "16px",
          },
        },
      },
    },
  });

  const [height, setHeight] = useState(650);

  useEffect(() => {
    setTimelineState({
      ...timelineState,
      series: [
        {
          data: timeline.map((item) => {
            return {
              x: item.descricao,
              y: [
                new Date(item.data_inicial).getTime(),
                new Date(item.data_final).getTime(),
              ],
            };
          }),
        },
      ],
    });

    if (timeline.length < 5) {
      let newheight = timeline && timeline.length * 50 + 50;
      setHeight(newheight);
    } else {
      let newheight = timeline && timeline.length * 50;
      setHeight(newheight);
    }
    
  }, [timeline]);

  //PDF
  const getDataUri = () => {
    let grafico = new Image();
    let footer = null;
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");

    setGuardarLoading(true);

    // HEADER

    const doc = new jsPDF("l", "px", "a4", true);

    let pageHeight = 922;

    return ApexCharts.exec("basic-bar", "dataURI")
      .then(({ imgURI }) => {
        const pagesDoc = [];

        let pageNum = 0;

        //New option
        const chart = document.getElementById("gantt");
        let imgHeight =
          (chart.offsetHeight * grafico.naturalWidth) / chart.offsetWidth;

        grafico = new Image();
        grafico.src = `${imgURI}`;
        grafico.onload = function () {
          while (pageHeight * pageNum < grafico.naturalHeight - 42) {
            canvas = document.createElement("canvas");
            const dpr = window.devicePixelRatio || 1;
            canvas.width = grafico.naturalWidth * dpr;
            canvas.height = grafico.naturalHeight * dpr;
            ctx = canvas.getContext("2d");
            ctx.imageSmoothingQuality = "high";
            ctx.scale(dpr, dpr);
            ctx.drawImage(
              grafico,
              0,
              pageHeight * pageNum + 31,
              grafico.naturalWidth,
              pageHeight,
              0,
              0,
              grafico.naturalWidth,
              pageHeight
            );

            canvas.style.width = grafico.naturalWidth * dpr + "px"; // enlarge canvas by stretching
            canvas.style.height = grafico.naturalHeight * dpr + "px";

            pagesDoc.push(canvas.toDataURL("image/png"));
            pageNum += 1;
          }

          if (!footer) {
            canvas = document.createElement("canvas");
            canvas.width = grafico.naturalWidth;
            canvas.height = grafico.naturalHeight;
            ctx = canvas.getContext("2d");
            ctx.imageSmoothingQuality = "high";
            ctx.drawImage(
              grafico,
              0,
              grafico.naturalHeight - 42,
              grafico.naturalWidth,
              pageHeight,
              0,
              0,
              grafico.naturalWidth,
              pageHeight
            );

            footer = new Image();
            footer.src = canvas.toDataURL("image/png");
          }

          for (let i = 0; i < pagesDoc.length - 1; i++) {
            doc.addPage();
          }

          // // FOOTER (Paginação)
          const totalPag = doc.internal.getNumberOfPages();
          for (let i = 0; i < totalPag; i++) {
            doc.setPage(i + 1);

            let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;

            if (pagesDoc[i] !== "data:,") {
              doc.addImage(
                pagesDoc[pageCurrent - 1],
                "PNG",
                5,
                45,
                doc.internal.pageSize.width - 20,
                imgHeight,
                undefined,
                "FAST"
              );
              if (i + 1 < totalPag) {
                doc.addImage(
                  footer,
                  "PNG",
                  5,
                  doc.internal.pageSize.height - 80,
                  doc.internal.pageSize.width - 20,
                  pageHeight,
                  undefined,
                  "FAST"
                );
              }
            }

            // Logotipo
            doc.addImage(logo, "PNG", 20, 20, 50, 15);
            const data = moment().format("DD-MM-YYYY");

            // Titulo do documento
            doc.setFontSize(9);

            doc
              .text("Timeline ", doc.internal.pageSize.width / 2 - 4, 30, {
                align: "center",
              })
              .setFont("Lato", "normal");

            // Data
            doc
              .text(520, 30, `Data de Impressão: ${data}`)
              .setFont("Lato", "normal");

            doc.setFontSize(8);
            doc.text(
              "Página " + pageCurrent + " de " + totalPag,
              doc.internal.pageSize.width / 2 - 10,
              doc.internal.pageSize.height - 20,
              { align: "center" }
            );
          }

          doc.save("file.pdf");
        };
      })
      .then(() => setGuardarLoading(false));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <FilterDrawer
          state={state}
          toggleDrawer={toggleDrawer}
          drawerState={drawerState}
          drawerWidth={drawerWidth}
          dimensions={dimensions}
          fetchIntervencoesTimeline={fetchIntervencoesTimeline}
          dataInicio={dataInicio}
          dataFim={dataFim}
          setDataInicio={setDataInicio}
          setDataFim={setDataFim}
          aeroporto={aeroporto}
          aeroportos={aeroportos}
          aeroportoId={aeroportoId}
          setAeroportoId={setAeroportoId}
          departamento={departamento}
          departamentos={departamentos}
          departamentoId={departamentoId}
          setDepartamentoId={setDepartamentoId}
          situacoes={situacoes}
          situacoesPreSelectedId={situacoesPreSelectedId}
        />
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mx: 5,
              mt: 3,
            }}
          >
            <Box>
              <h4
                style={{
                  fontSize: "22px",
                  width: "100%",
                }}
                className="text-underline"
              >
                Timeline
              </h4>
            </Box>
            <Box>
              <Button
                variant="contained"
                className={classes.btnFiltrar}
                onClick={() => toggleDrawer("right", true)}
              >
                Filtros
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mx: 5,
              mb: 3,
            }}
          >
            {!guardarLoading ? (
              <Button
                variant="contained"
                className={classes.btnFiltrar}
                onClick={getDataUri}
              >
                Download
              </Button>
            ) : (
              <LoadingButton
                color="secondary"
                loading={guardarLoading}
                loadingPosition="start"
                variant="contained"
                className={classes.btnFiltrar}
              >
                <Loader />
              </LoadingButton>
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mx: 5 }}>
            <Card
              sx={{
                mb: 5,
                p: 2,
                boxShadow: "0px 5px 15px lightgrey",
                width: "100%",
              }}
            >
              <Box
                sx={{ display: "flex", justifyContent: "center" }}
                id="gantt"
              >
                <ReactApexChart
                  options={timelineState.options}
                  series={timelineState.series}
                  type="rangeBar"
                  height={height}
                  width={1725}
                />
              </Box>
            </Card>
          </Box>
          <div style={{ margin: "20px 40px" }}>
            <FooterLinks />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default IntervencoesTimeline;
