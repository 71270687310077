import Instance from "../../../../Axios";

// New Actions for the app

const GetWPFileTypes = () => async (dispatch) => {
  await Instance()
    .get(`/construcoes/workpermits/fileTypes`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({ type: "GET_WP_FILE_TYPES", payload: data.yData });
      // setObraSelecionada(data.yData[0]);
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetWPFileTypes;
