/* eslint-disable eqeqeq */
import React from 'react';
import { TextField, Autocomplete, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptLocale from "date-fns/locale/pt";
import { Controller } from "react-hook-form";

//Icons
import FeatherIcon from "feather-icons-react";

const Especialidades = ({
  obraSelecionada,
  setObraSelecionada,
  control,
  reset,
  watch,
  resetForm,
  errors,
  setResetForm,
  getValues,
  setValue,
  updateGlobals,
  renderSwitch,
  setEstDT,
  setEstDC,
}) => {
  const trabalhos = useSelector((state) => state.obras.obrasTrabalhos);
  const empresas = useSelector((state) => state.obras.obrasEmpresas);
  const estadosEspecialidade = useSelector(
    (state) => state.obras.especialidadesEstados
  );

  const calendarIcon = () => {
    return <FeatherIcon icon="calendar" size={20} />;
  };

  // ############################################################## //
  // ######################  Semaforizações  ###################### //
  // ############################################################## //

  const onEspChange = (index, value) => {
    if (value) {
      if (getValues(`especialidades[${index}].dataInicio`)) {
        setEstDT([index, "1"]);
        setValue(`especialidades[${index}].estadodt`, "1");
      } else {
        setEstDT([index, "2"]);
        setValue(`especialidades[${index}].estadodt`, "2");
      }
      updateGlobals();
    } else {
      setEstDT([index, "2"]);
      setValue(`especialidades[${index}].estadodt`, "2");
      updateGlobals();
    }
    if (
      !getValues(`especialidades[${index}].empresasDropdown.id`) &&
      !getValues(`especialidades[${index}].estadosDropdown.id`)
    ) {
      setEstDC([index, "3"]);
      setValue(`especialidades[${index}].estadodc`, "3");
      updateGlobals();
    }
    console.log(getValues());
  };

  const onEspStateChange = (value, index) => {
    if (value) {
      if (getValues(`especialidades[${index}].empresasDropdown`)) {
        switch (value.id) {
          case 1:
            setEstDC([index, "1"]);
            setValue(`especialidades[${index}].estadodc`, "1");
            break;
          case 2:
            setEstDC([index, "2"]);
            setValue(`especialidades[${index}].estadodc`, "2");
            break;
          case 3:
            setEstDC([index, "2"]);
            setValue(`especialidades[${index}].estadodc`, "2");
            break;
          case 4:
            setEstDC([index, "1"]);
            setValue(`especialidades[${index}].estadodc`, "1");
            break;
          default:
            break;
        }
        updateGlobals();
      } else {
        setEstDC([index, "2"]);
        setValue(`especialidades[${index}].estadodc`, "2");
        updateGlobals();
      }
      console.log(getValues());
    }
  };

  const onEmpChange = (value, index) => {
    if (value) {
      if (getValues(`especialidades[${index}].estadosDropdown`)) {
        if (
          getValues(`especialidades[${index}].estadosDropdown.id`) == "1" ||
          getValues(`especialidades[${index}].estadosDropdown.id`) == "4"
        ) {
          setEstDT([index, "1"]);
          setValue(`especialidades[${index}].estadodc`, "1");
        } else {
          setEstDT([index, "2"]);
          setValue(`especialidades[${index}].estadodc`, "2");
        }
      } else {
        setEstDT([index, "2"]);
        setValue(`especialidades[${index}].estadodc`, "2");
      }
      updateGlobals();
    } else {
      setEstDT([index, "2"]);
      setValue(`especialidades[${index}].estadodc`, "2");
      updateGlobals();
    }
    console.log(getValues());
  };

  const onDateChange = (value, index) => {
    if (value) {
      if (getValues(`especialidades[${index}].especialidadesDropdown.id`)) {
        setEstDT([index, "1"]);
        setValue(`especialidades[${index}].estadodt`, "1");
      } else {
        setEstDT([index, "2"]);
        setValue(`especialidades[${index}].estadodt`, "2");
      }
      updateGlobals();
    } else {
      setEstDT([index, "2"]);
      setValue(`especialidades[${index}].estadodt`, "2");
      updateGlobals();
    }
    console.log(getValues());
  };

  // ############################################################## //
  // #####################  Hanlde Trabalhos  ##################### //
  // ############################################################## //

  const handleAddEsp = async () => {
    console.log(obraSelecionada.especialidades.length);
    const lastEsp =
      obraSelecionada &&
      obraSelecionada.especialidades &&
      obraSelecionada.especialidades.length + 1;

    setResetForm(true);

    const newTrabalho = {
      dataFim: null,
      dataInicio: null,
      especialidadesDescricao: null,
      especialidadesDropdown: { id: "", descricao: "" },
      especialidadesId: lastEsp,
      empresasDropdown: { id: "", descricao: "" },
      empresasId: null,
      empresasDescricao: null,
      estadosDescricao: null,
      estadosDropdown: { id: "", descricao: "" },
      estadosId: null,
      obrasId: obraSelecionada && obraSelecionada.obrasId,
      observacoes: null,
      estadodt: null,
      estadodc: null,
    };

    obraSelecionada.especialidades.push(newTrabalho);
    await setObraSelecionada({ ...obraSelecionada });

    await setResetForm(false);

  };

  const handleRemoveEsp = async (index) => {
    const obra = getValues();
    await setResetForm(true);
    if (index !== -1) {
      obra.especialidades.splice(index, 1);
    }

    await setObraSelecionada({ ...obra });
    await setResetForm(false);
    await reset({ ...obra });
    updateGlobals();
  };

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            width: "100%",
            padding: "5px",
            background: "#F1F1F1",
            border: "1px solid #707070",
            borderRadius: "8px",
          }}
        >
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => handleAddEsp()}
            color="inherit"
          >
            <FeatherIcon icon="plus-square" size={20} />
          </IconButton>
          <h4
            style={{
              width: "25%",
              margin: "10px 2% 10px 10px",
              padding: "0px",
            }}
          >
            Trabalho
          </h4>

          <h4
            style={{
              width: "15%",
              margin: "10px 2% 10px 0px",
              padding: "0px",
            }}
          >
            Fornecedor
          </h4>

          <h4
            style={{
              width: "15%",
              margin: "10px 2% 10px 0px",
              padding: "0px",
            }}
          >
            Estado
          </h4>

          <h4
            style={{
              width: "10%",
              margin: "10px 2% 10px 20px",
              padding: "0px",
            }}
          >
            Data Início
          </h4>

          <h4
            style={{
              width: "10%",
              margin: "10px 2% 10px 10px",
              padding: "0px",
            }}
          >
            Data Fim
          </h4>

          <h4
            style={{
              width: "25%",
              margin: "10px 2% 10px 0px",
              padding: "0px",
            }}
          >
            Observações
          </h4>
          <div
            style={{
              width: "10%",
              display: "flex",
              justifyContent: "space-around",
              marginLeft: "5px",
              float: "right",
            }}
          >
            <h4
              style={{
                margin: "10px 0px 10px 0px",
                padding: "0px",
              }}
            >
              Téc.
            </h4>
            <h4
              style={{
                margin: "10px 0px 10px 0px",
                padding: "0px",
              }}
            >
              Com.
            </h4>
          </div>
        </div>

        <>
          {obraSelecionada &&
            obraSelecionada.especialidades &&
            obraSelecionada.especialidades.map((especialidade, index) => (
              <div
                key={`especialidade[${index}]`}
                style={{
                  background: "white",
                  minHeight: "40px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  border: "1px solid #707070",
                  borderRadius: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: "5px",
                  }}
                >
                  <form
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                    autocomple="off"
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => handleRemoveEsp(index)}
                        color="inherit"
                      >
                        <FeatherIcon icon="trash-2" size={20} />
                      </IconButton>
                      <Controller
                        name={`especialidades[${index}][especialidadesDropdown]`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <Autocomplete
                              fullWidth
                              clearText="Limpar"
                              openText="Abrir"
                              closeText="Fechar"
                              noOptionsText="Sem dados"
                              className="unidadesId-form"
                              options={trabalhos || []}
                              value={value || null}
                              getOptionLabel={(option) =>
                                `${option.descricao}` || ""
                              }
                              isOptionEqualToValue={(option, value) => {
                                // if (!value.id) return true;
                                const intOption = option && parseInt(option.id);
                                return value.id === intOption;
                                // console.log(intOption);
                              }}
                              filterOptions={(options, state) => {
                                let newOptions = [];
                                options.forEach((option) => {
                                  if (
                                    option.id !== "" &&
                                    option.descricao
                                      .toLowerCase()
                                      .includes(state.inputValue.toLowerCase())
                                  )
                                    newOptions.push(option);
                                });
                                return newOptions;
                              }}
                              style={{
                                width: "23%",
                                marginLeft: "10px",
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Trabalho"
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                  }}
                                  variant="standard"
                                />
                              )}
                              onChange={(_, value) => {
                                onEspChange(index, value);
                                onChange(value);
                                return value;
                              }}
                            />
                          </>
                        )}
                      />
                      <Controller
                        name={`especialidades[${index}][empresasDropdown]`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <Autocomplete
                              freeSolo
                              fullWidth
                              clearText="Limpar"
                              openText="Abrir"
                              closeText="Fechar"
                              noOptionsText="Sem dados"
                              className="unidadesId-form"
                              options={empresas || []}
                              value={value || null}
                              getOptionLabel={(option) =>
                                `${option.descricao}` || ""
                              }
                              isOptionEqualToValue={(option, value) => {
                                // if (!value.id) return true;
                                const intOption = option && parseInt(option.id);
                                return value.id === intOption;
                                // console.log(intOption);
                              }}
                              // filterOptions={(options, state) => {
                              //   let newOptions = [];
                              //   options.forEach((option) => {
                              //     if (
                              //       option.id !== "" &&
                              //       option.descricao
                              //         .toLowerCase()
                              //         .includes(state.inputValue.toLowerCase())
                              //     )
                              //       newOptions.push(option);
                              //   });
                              //   return newOptions;
                              // }}
                              style={{
                                width: "14%",
                                marginLeft: "10px",
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Empresa"
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                  }}
                                  variant="standard"
                                />
                              )}
                              onChange={(_, value, reason) => {
                                if (reason === "clear") {
                                  onEmpChange(value, index);
                                  onChange(value);
                                } else {
                                  if (typeof value === "string") {
                                    // The user entered a new value that is not in the options list
                                    const newEmpresa = {
                                      id: null,
                                      descricao: value,
                                    };
                                    onChange(newEmpresa);
                                    onEmpChange(newEmpresa, index);
                                    return newEmpresa;
                                  } else {
                                    // User selected an existing option from the options list
                                    onChange(value);
                                    return value;
                                  }
                                }
                              }}
                            />
                          </>
                        )}
                      />
                      <Controller
                        name={`especialidades[${index}][estadosDropdown]`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <Autocomplete
                              fullWidth
                              clearText="Limpar"
                              openText="Abrir"
                              closeText="Fechar"
                              noOptionsText="Sem dados"
                              className="unidadesId-form"
                              options={estadosEspecialidade || []}
                              value={value || null}
                              getOptionLabel={(option) =>
                                `${option.descricao}` || ""
                              }
                              isOptionEqualToValue={(option, value) => {
                                // if (!value.id) return true;
                                return value.id === option.id;
                              }}
                              filterOptions={(options, state) => {
                                let newOptions = [];
                                options.forEach((option) => {
                                  if (
                                    option.id > 0 &&
                                    option.descricao
                                      .toLowerCase()
                                      .includes(state.inputValue.toLowerCase())
                                  )
                                    newOptions.push(option);
                                });
                                return newOptions;
                              }}
                              style={{
                                width: "14%",
                                marginLeft: "10px",
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                  }}
                                  variant="standard"
                                  placeholder="Selecione uma opção"
                                  disableUnderline={false}
                                  style={{
                                    border: "none",
                                    color: "#000 !important",
                                  }}
                                  size="small"
                                />
                              )}
                              onChange={(_, value) => {
                                onEspStateChange(value, index);
                                onChange(value);
                                console.log(value);
                                return value;
                              }}
                            />
                          </>
                        )}
                      />
                      <Controller
                        name={`especialidades[${index}][dataInicio]`}
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error, invalid },
                        }) => (
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={ptLocale}
                          >
                            <DatePicker
                              inputFormat="dd/MM/yyyy"
                              invalidDateMessage="Data Inválida"
                              value={value}
                              onChange={(value) => {
                                onDateChange(value, index);
                                onChange(value);
                              }}
                              components={{
                                OpenPickerIcon: calendarIcon,
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={invalid}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "dd/mm/aaaa",
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                  }}
                                  variant="standard"
                                  size="small"
                                  sx={{
                                    width: "10%",
                                    marginLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />

                      <Controller
                        name={`especialidades[${index}][dataFim]`}
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error, invalid },
                        }) => (
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={ptLocale}
                          >
                            <DatePicker
                              inputFormat="dd/MM/yyyy"
                              invalidDateMessage="Data Inválida"
                              value={value}
                              onChange={(value) => {
                                onChange(value);
                              }}
                              components={{
                                OpenPickerIcon: calendarIcon,
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={invalid}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "dd/mm/aaaa",
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                  }}
                                  variant="standard"
                                  size="small"
                                  sx={{
                                    width: "10%",
                                    marginLeft: "20px",
                                    paddingRight: "10px",
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />

                      <Controller
                        name={`especialidades[${index}][observacoes]`}
                        control={control}
                        render={({ field }) => (
                          <>
                            <TextField
                              color="primary"
                              placeholder="Observações"
                              size="small"
                              {...field}
                              style={{ width: "25%", paddingLeft: "30px" }}
                              variant="standard"
                              InputProps={{ disableUnderline: true }}
                            />
                          </>
                        )}
                      />
                      <div
                        style={{
                          width: "10%",
                          display: "flex",
                          justifyContent: "space-around",
                          paddingLeft: "20px",
                        }}
                      >
                        <div>
                          {renderSwitch(
                            getValues(`especialidades[${index}].estadodt`)
                          )}
                        </div>
                        <div>
                          {renderSwitch(
                            getValues(`especialidades[${index}].estadodc`)
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ))}
        </>
      </div>
    </>
  );
};

export default Especialidades;
