import React from 'react';
import { TextField } from '@mui/material';

const Observacoes = ({ obraSelecionada }) => {
  return (
    <>
      <div>
        <h4 style={{ fontSize: '18px' }}>Notas</h4>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '100%', marginBottom: '16px' }}>
            <TextField
              color='primary'
              placeholder='Observações'
              multiline
              size='small'
              value={
                obraSelecionada && obraSelecionada.observacoes
                  ? obraSelecionada.observacoes
                  : ' ---'
              }
              style={{ width: '100%' }}
              variant='standard'
              InputProps={{ disableUnderline: true }}
              disabled
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Observacoes;
