import moment from "moment";
import Instance from "../../../../Axios";

// New Actions for the app

const AddCredFuncionario =
  (
    yData,
    aeroporto,
    alocacao,
    ficheiros,
    evidencias,
    evidenciasNomesValues,
    datePickerValues,
    toggleDrawer,
    ShowNotification,
    message,
    errorMessage
  ) =>
  async (dispatch) => {
    const formdata = new FormData();

    console.log(ficheiros, evidencias, evidenciasNomesValues);

    formdata.append("yData", JSON.stringify(yData[0]));
    formdata.append("aeroportos", JSON.stringify(aeroporto));
    formdata.append("alocacoes", JSON.stringify(alocacao));

    if (ficheiros && Array.isArray(ficheiros) && ficheiros.length > 0) {
      ficheiros.forEach((ficheiro, index) => {
        if (ficheiro) {
          formdata.append(`file${index}`, ficheiro.file);
          formdata.append(`idTipo${index}`, ficheiro.idTipo);
          formdata.append(`path${index}`, ficheiro.path);

          const datePickerValue = datePickerValues[index];
          if (datePickerValue) {
            formdata.append(
              `datePicker${index}`,
              moment(datePickerValue).format("YYYY-MM-DD")
            );
          }
        }
      });
    }

    if (evidencias && Array.isArray(evidencias) && evidencias.length > 0) {
      evidencias.forEach((ficheiro, index) => {
        if (ficheiro.file) {
          formdata.append(`Evfile${index}`, ficheiro.file);
          formdata.append(`EvidTipo${index}`, ficheiro.idTipo);
          formdata.append(`Evpath${index}`, ficheiro.path);

          const evidenciaNome = evidenciasNomesValues[index];
          if (evidenciaNome) {
            formdata.append(`Evnome${index}`, evidenciaNome);
          }
        }
      });
    }

    await Instance()
      .post(`/credenciacoes/funcionarios`, formdata)
      .then(({ data }) => {
        //   console.log(data);
        dispatch({ type: "ADD_CRED_FUNCIONARIO", payload: data });
        toggleDrawer("right", false);
        dispatch(ShowNotification(message));
      })
      .catch((err) => {
        if (err.response) {
          if (err.response === 403) {
            dispatch(ShowNotification(errorMessage));
          } else {
            dispatch(ShowNotification(errorMessage));
          }
        }
      });
  };

export default AddCredFuncionario;
