/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const GraficoValorIntervencoesAdjudicadas = ({
  valorIntervencoesAdjudicadas,
}) => {
  const [state, setState] = useState({
    series: [],
    options: {
      labels: [],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return Math.round(val) + '%';
        },
      },
      title: {
        text: 'Peso dos Valor das Intervenções Adjudicadas',
        align: 'center',
      },
      legend: {
        position: 'right',
        fontSize: '10px',
        offsetX: 10,
        offsetY: 50,
        markers: {
          width: 10,
          height: 10,
        },
        itemMargin: {
          horizontal: 0,
          vertical: 0,
        },
      },
      plotOptions: {
        pie: {
          offsetY: 20,
        },
      },
      colors: [
        '#4FC0E8',
        '#ED5564',
        '#8CC051',
        '#5D9CEC',
        '#967BDC',
        '#FB6E52',
        '#36BC9B',
        '#F6BB43',
        '#D870AD',
      ],
    },
  });

  useEffect(() => {
    valorIntervencoesAdjudicadas && setState(valorIntervencoesAdjudicadas);
  }, [valorIntervencoesAdjudicadas]);

  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type='donut'
        width={350}
      />
    </>
  );
};

export default GraficoValorIntervencoesAdjudicadas;
