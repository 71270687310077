import React from 'react';
import { TextField, Typography } from '@mui/material';

const Estados = ({ intervencaoSelecionada }) => {
  return (
    <>
      <div>
        <h4
          style={{ color: '#E21450' }}
          className='text-underline-title-drawer '
        >
          Estados
        </h4>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '250px' }}>
            <div style={{ marginBottom: '16px' }}>
              <Typography style={{ fontWeight: 'bold' }}>Projeto</Typography>
              <TextField
                size='small'
                value={
                  (intervencaoSelecionada &&
                    intervencaoSelecionada.estadosPropostasName) ||
                  ' - '
                }
                variant='outlined'
                disabled
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Estados;
