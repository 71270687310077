import Instance from "../../../../Axios";

// New Actions for the app

const GetCredDestinatarioById = (id) => async (dispatch) => {
  await Instance()
    .get(`/credenciacoes/destinatarios/${id}`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({
        type: "GET_CRED_DESTINATARIO_BY_ID",
        payload: data.yData[0],
      });
      // setObraSelecionada(data.yData[0]);
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetCredDestinatarioById;