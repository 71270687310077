import { fabricantesInitialState } from "../initialState/fabricantes";

const fabricantesReducer = (
  state = fabricantesInitialState,
  { type, payload }
) => {
  switch (type) {
    case "GET_ALL_FABRICANTES":
      return {
        ...state,
        fabricantes: payload,
      };
    case "GET_FABRICANTE_BY_ID":
      return {
        ...state,
        fabricante: payload,
      };
    case "DELETE_FABRICANTE":
      return {
        ...state,
        fabricanteApagado: payload,
      };
    case "ADD_FABRICANTE":
      return {
        ...state,
        fabricanteAdicionado: payload,
      };
    case "UPDATE_FABRICANTE":
      return {
        ...state,
        fabricanteEditado: payload,
      };

    default:
      return state;
  }
};

export default fabricantesReducer;
