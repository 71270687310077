/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { format } from "date-fns";
import MUIDataTable from "mui-datatables";
import { Paper, IconButton, Typography, Tooltip } from "@mui/material";

// Components
import SideDrawer from "./components/drawer/drawer";
// import FiltersObras from './components/filters';
import FilterObrasCheck from "./components/filters/indexCheck";

//Icons
import FeatherIcon from "feather-icons-react";

//Actions
import GetObras from "../../../../utils/redux/actions/energias/obras/GetObrasAction";
import GetObra from "../../../../utils/redux/actions/energias/obras/GetObraAction";
//--filters
import GetEstadosProjeto from "../../../../utils/redux/actions/energias/filters/GetEstadosProjetoAction";
import GetEstadosSubProjeto from "../../../../utils/redux/actions/energias/filters/GetEstadosSubProjetoAction";

//--estados
import GetEstadosControlo from "../../../../utils/redux/actions/energias/estados/GetEstadosControlo";
import GetEstadosMaterial from "../../../../utils/redux/actions/energias/estados/GetEstadosMaterial";
import FooterLinks from "../../../../Global/Components/Footer";
import GetSubMateriais from "../../../../utils/redux/actions/energias/subMateriais/GetSubMateriais";
import GetMateriais from "../../../../utils/redux/actions/energias/materiais/GetMateriais";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
  },
  stateLabel: {
    textTransform: "none",
    transition: "0.2s",
    display: "flex",
    alignItems: "center",
    maxWidth: "100px",
  },

  paperFilters: {
    background: "transparent",
    padding: "20px 0px",
    boxShadow: "none",
  },
}));

const ColorStateCube = ({ color }) => {
  return (
    <div
      style={{
        height: "15px",
        width: "15px",
        backgroundColor: color,
        marginRight: "10px",
        borderRadius: "3px",
      }}
    />
  );
};

const Obras = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");

  const [state, setState] = useState({
    right: false,
  });

  const user = useSelector((state) => state.user);
  const perfilId = useSelector((state) => state.user.perfilId);
  const parceiroId = useSelector((state) => state.user.energParceiroId);

  const obrasState = useSelector((state) => state.energias.obras);

  const estadoProjeto = useSelector((state) => state.energias.estadosProjeto);
  const estadoSubProjeto = useSelector(
    (state) => state.energias.estadosSubProjeto
  );
  const controlo = useSelector((state) => state.energias.estadosControlo);

  let estadosProjetoPreSelectedId = useSelector(
    (state) => state.energias.estadosProjetoPreSelected
  );
  let estadosSubProjetoPreSelectedId = useSelector(
    (state) => state.energias.estadosSubProjetoPreSelected
  );
  let controloPreSelectedId = useSelector(
    (state) => state.energias.controloPreSelected
  );

  const [drawerState, setDrawerState] = useState("");

  const toggleDrawer = (anchor, open, obra, dState) => {
    console.log(obra);
    if (open == false) {
      setState({ ...state, [anchor]: open });
      dispatch({ type: "GET_ENERGIAS_OBRA", payload: [] });
      // setObraSelecionada(null);
      setDrawerState("");
    }
    if (open == true) {
      // setObraSelecionada(null);
      setDrawerState(dState);
      setState({ ...state, [anchor]: open });
      fetchObra(obra);
    }
  };

  const fetchObra = (obra) => {
    const obraId = obra;
    dispatch(GetObra(obraId));
  };

  const fetchObras = (
    estadosProjetoPreSelectedId,
    estadosSubProjetoPreSelectedId,
    estadosControloIdPreSelectedId,
    energParceiroId
  ) => {
    dispatch(
      GetObras(
        estadosProjetoPreSelectedId,
        estadosSubProjetoPreSelectedId,
        estadosControloIdPreSelectedId,
        energParceiroId
      )
    );
  };

  // FILTERS
  const fetchEstadosProjeto = () => {
    dispatch(GetEstadosProjeto());
  };
  const fetchEstadosSubProjeto = () => {
    dispatch(GetEstadosSubProjeto());
  };

  useEffect(() => fetchEstadosProjeto(), []);
  useEffect(() => fetchEstadosSubProjeto(), []);

  // OBRAS - ESTADOS
  const fetchEstadosControlo = () => {
    dispatch(GetEstadosControlo());
  };

  const fetchEstadosMaterial = () => {
    dispatch(GetEstadosMaterial());
  };

  useEffect(() => fetchEstadosControlo(), []);

  useEffect(() => fetchEstadosMaterial(), []);

  useEffect(() => {
    const eProjetosId = estadosProjetoPreSelectedId.map((ep) => ep.id);
    const eSubProjetosId = estadosSubProjetoPreSelectedId.map((ep) => ep.id);
    const controloId = controloPreSelectedId.map((ep) => ep.id);

    if (perfilId == 5) {
      fetchObras(eProjetosId, eSubProjetosId, controloId, parceiroId);
    } else {
      fetchObras(eProjetosId, eSubProjetosId, controloId, null);
    }
  }, []);

  //MATERIAIS
  const fetchMateriais = () => {
    dispatch(GetMateriais());
  };

  const fetchSubMateriais = () => {
    dispatch(GetSubMateriais());
  };

  useEffect(() => fetchMateriais(), []);
  useEffect(() => fetchSubMateriais(), []);

  // ############################################################## //
  // ###################  Render Cores Estados  ################### //
  // ############################################################## //

  const renderSwitch = (param) => {
    switch (param) {
      case "1":
        return <ColorStateCube color="#4CB64C" />;
      case "2":
        return <ColorStateCube color="#FFD500" />;
      case "3":
        return <ColorStateCube color="#FF5B5B" />;
      default:
        return null;
    }
  };

  // ############################################################## //
  // ###################  MUI Datatable Obras  #################### //
  // ############################################################## //

  const columns = [
    {
      name: "id",
      label: "Nº de Obra",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "400px" },
        }),
      },
    },
    {
      name: "dataCriacao",
      label: "Data de Criação",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumnSort, options) => {
          const sortDirection = options.direction || null;
          const columnName = options.name || null;
          console.log(options);
          return (
            <>
              <th
                onClick={() => handleToggleColumnSort(columnMeta.index)}
                style={{
                  cursor: "pointer",
                  position: "sticky",
                  top: "0px",
                  zIndex: "100",
                  background: "#F1F1F1",
                }}
              >
                <span>
                  <Tooltip title="Custom tooltip for Name column">
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {columnMeta.label}
                        {columnName == columnMeta.name &&
                          sortDirection === "asc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-up"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                        {columnName == columnMeta.name &&
                          sortDirection === "desc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-down"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                      </div>
                    </div>
                  </Tooltip>
                </span>
              </th>
            </>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "idade",
      label: "Idade",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumnSort, options) => {
          const sortDirection = options.direction || null;
          const columnName = options.name || null;
          console.log(options);
          return (
            <>
              <th
                onClick={() => handleToggleColumnSort(columnMeta.index)}
                style={{
                  cursor: "pointer",
                  position: "sticky",
                  top: "0px",
                  zIndex: "100",
                  background: "#F1F1F1",
                }}
              >
                <span>
                  <Tooltip title="Custom tooltip for Name column">
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {columnMeta.label}
                        {columnName == columnMeta.name &&
                          sortDirection === "asc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-up"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                        {columnName == columnMeta.name &&
                          sortDirection === "desc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-down"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                      </div>
                    </div>
                  </Tooltip>
                </span>
              </th>
            </>
          );
        },
      },
    },
    {
      name: "dataInicial",
      label: "Data Início",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumnSort, options) => {
          const sortDirection = options.direction || null;
          const columnName = options.name || null;
          console.log(options);
          return (
            <>
              <th
                onClick={() => handleToggleColumnSort(columnMeta.index)}
                style={{
                  cursor: "pointer",
                  position: "sticky",
                  top: "0px",
                  zIndex: "100",
                  background: "#F1F1F1",
                }}
              >
                <span>
                  <Tooltip title="Custom tooltip for Name column">
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {columnMeta.label}
                        {columnName == columnMeta.name &&
                          sortDirection === "asc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-up"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                        {columnName == columnMeta.name &&
                          sortDirection === "desc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-down"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                      </div>
                    </div>
                  </Tooltip>
                </span>
              </th>
            </>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "dataFinal",
      label: "Data Fim",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumnSort, options) => {
          const sortDirection = options.direction || null;
          const columnName = options.name || null;
          console.log(options);
          return (
            <>
              <th
                onClick={() => handleToggleColumnSort(columnMeta.index)}
                style={{
                  cursor: "pointer",
                  position: "sticky",
                  top: "0px",
                  zIndex: "100",
                  background: "#F1F1F1",
                }}
              >
                <span>
                  <Tooltip title="Custom tooltip for Name column">
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {columnMeta.label}
                        {columnName == columnMeta.name &&
                          sortDirection === "asc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-up"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                        {columnName == columnMeta.name &&
                          sortDirection === "desc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-down"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                      </div>
                    </div>
                  </Tooltip>
                </span>
              </th>
            </>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "idadeReal",
      label: "Idade Real",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumnSort, options) => {
          const sortDirection = options.direction || null;
          const columnName = options.name || null;
          console.log(options);
          return (
            <>
              <th
                onClick={() => handleToggleColumnSort(columnMeta.index)}
                style={{
                  cursor: "pointer",
                  position: "sticky",
                  top: "0px",
                  zIndex: "100",
                  background: "#F1F1F1",
                }}
              >
                <span>
                  <Tooltip title="Custom tooltip for Name column">
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {columnMeta.label}
                        {columnName == columnMeta.name &&
                          sortDirection === "asc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-up"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                        {columnName == columnMeta.name &&
                          sortDirection === "desc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-down"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                      </div>
                    </div>
                  </Tooltip>
                </span>
              </th>
            </>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {value == null ? (
                " - "
              ) : value == 1 ? (
                <Typography>{value} dia</Typography>
              ) : (
                <Typography>{value} dias</Typography>
              )}
            </>
          );
        },
      },
    },
    {
      name: "dataRealInicio",
      label: "Data Real Início",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumnSort, options) => {
          const sortDirection = options.direction || null;
          const columnName = options.name || null;
          console.log(options);
          return (
            <>
              <th
                onClick={() => handleToggleColumnSort(columnMeta.index)}
                style={{
                  cursor: "pointer",
                  position: "sticky",
                  top: "0px",
                  zIndex: "100",
                  background: "#F1F1F1",
                }}
              >
                <span>
                  <Tooltip title="Custom tooltip for Name column">
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {columnMeta.label}
                        {columnName == columnMeta.name &&
                          sortDirection === "asc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-up"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                        {columnName == columnMeta.name &&
                          sortDirection === "desc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-down"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                      </div>
                    </div>
                  </Tooltip>
                </span>
              </th>
            </>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "dataRealFim",
      label: "Data Real Fim",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumnSort, options) => {
          const sortDirection = options.direction || null;
          const columnName = options.name || null;
          console.log(options);
          return (
            <>
              <th
                onClick={() => handleToggleColumnSort(columnMeta.index)}
                style={{
                  cursor: "pointer",
                  position: "sticky",
                  top: "0px",
                  zIndex: "100",
                  background: "#F1F1F1",
                }}
              >
                <span>
                  <Tooltip title="Custom tooltip for Name column">
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {columnMeta.label}
                        {columnName == columnMeta.name &&
                          sortDirection === "asc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-up"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                        {columnName == columnMeta.name &&
                          sortDirection === "desc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-down"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                      </div>
                    </div>
                  </Tooltip>
                </span>
              </th>
            </>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },

    {
      name: "estadosProjetosDescricao",
      label: "Estado de Projeto",
      options: {
        filter: false,
        sort: true,
        display: false,

        customBodyRender: (value, tableMeta) => {
          switch (value) {
            case "Aberto":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#54B7D3" />
                  {value}
                </div>
              );
            case "Fechado":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#D6DFE8" />
                  {value}
                </div>
              );
            case "Proposta":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="" />
                  {value}
                </div>
              );
            default:
              return (
                <div>
                  <ColorStateCube color="" />
                  {value}
                </div>
              );
          }
        },
      },
    },
    {
      name: "estadosSubProjetosDescricao",
      label: "SubProjeto",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          switch (value) {
            case "-":
              return <div className={classes.stateLabel}>{value}</div>;
            case "Aberto":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#54B7D3" />
                  {value}
                </div>
              );
            case "Iniciado":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#FFD500" />
                  {value}
                </div>
              );
            case "Executado":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#4CB64C" />
                  {value}
                </div>
              );
            case "Terminado":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#929CA6" />
                  {value}
                </div>
              );
            case "Fechado":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#929CA6" />
                  {value}
                </div>
              );
            case "Suspenso":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#FF8521" />
                  {value}
                </div>
              );
            default:
              return <div>{value}</div>;
          }
        },
      },
    },
    {
      name: "estadosControloDescricao",
      label: "Controlo",
      options: {
        filter: false,
        sort: true,
        display: false,
        // filterType: 'custom',
        customBodyRender: (value, tableMeta) => {
          // const o = obrasState[tableMeta.rowIndex];

          // console.log(obrasState);

          switch (value) {
            case "Tratado":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#4CB64C" />
                  {value}
                </div>
              );

            case "Em Curso":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#FFD500" />
                  {value}
                </div>
              );

            case "Suspensa":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#FF8521" />
                  {value}
                </div>
              );

            default:
              return (
                <div>
                  {/* <FaRegCheckCircle style={{ marginRight: '8px' }} /> */}
                  {value}
                </div>
              );
          }
        },
      },
    },
    {
      name: "estadodt",
      label: "Téc.",
      options: {
        filter: false,
        sort: true,
        // filterType: 'custom',
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {renderSwitch(value)}
            </div>
          );
        },
      },
    },
    {
      name: "estadodc",
      label: "Com.",
      options: {
        filter: false,
        sort: true,
        // filterType: 'custom',
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {renderSwitch(value)}
            </div>
          );
        },
      },
    },

    {
      name: "estadosControloCor",
      label: "",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },

    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: "center",
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const o = tableMeta.rowData[0];

          const userEditar =
            user &&
            user.permissoes &&
            user.permissoes.includes("APP_ENERGIAS_LISTAS_OBRAS_EDITAR");
          const userConsultar =
            user &&
            user.permissoes &&
            user.permissoes.includes("APP_ENERGIAS_LISTAS_OBRAS_CONSULTA");

          if (userEditar === true) {
            return (
              <div style={{ display: "flex" }}>
                <IconButton
                  onClick={() => toggleDrawer("right", true, o, "edit")}
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                >
                  <FeatherIcon
                    icon="edit"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              </div>
            );
          } else if (userConsultar === true) {
            return (
              <IconButton
                onClick={() => toggleDrawer("right", true, o, "consultar")}
                style={{
                  padding: 6,
                  color: theme.palette.secondary.main,
                }}
              >
                <FeatherIcon
                  icon="eye"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
            );
          } else if (userEditar === true && userConsultar === true) {
            return (
              <div style={{ display: "flex" }}>
                <IconButton
                  onClick={() => toggleDrawer("right", true, o, "edit")}
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                >
                  <FeatherIcon
                    icon="edit"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              </div>
            );
          }
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    // setRowProps: (row, dataIndex, rowIndex) => {
    //   return {
    //     style:
    //       rowIndex % 2
    //         ? { backgroundColor: 'transparent' }
    //         : { backgroundColor: '#F3F5F8' },
    //   };
    // },
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
      // noMatch: obrasState ? (
      //   <Spinner />
      // ) : (
      //   'Sorry, there is no matching data to display'
      // ),
    },
    // setRowProps: (row, dataIndex, rowIndex) => {
    //   const od = obrasState.find((item) => item.id == row[0]);
    //   console.log(od);
    //   return {
    //     style: {
    //       background: od.estadodc == "1" && od.estadodt == "1" ? "lightgreen" : "", // Change the condition as needed
    //     },
    //   };
    // },
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          minHeight: "94vh",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <SideDrawer
          state={state}
          toggleDrawer={toggleDrawer}
          obras={obrasState}
          fetchObras={fetchObras}
          drawerState={drawerState}
          estadosProjetoPreSelectedId={estadosProjetoPreSelectedId}
          estadosSubProjetoPreSelectedId={estadosSubProjetoPreSelectedId}
          controloPreSelectedId={controloPreSelectedId}
        />

        <div
          style={{
            margin: "0px 40px 40px 40px",
          }}
        >
          {/* <Paper> */}
          <Paper className={classes.paperFilters} elevation={5}>
            {/* <FiltersObras
                    estadoProjeto={estadoProjeto}
                    estadoSubProjeto={estadoSubProjeto}
                    fetchObras={fetchObras}
                    estadosProjetoPreSelectedId={estadosProjetoPreSelectedId}
                    estadosSubProjetoPreSelectedId={
                      estadosSubProjetoPreSelectedId
                    }
                    // CONTROLO
                    controlo={controlo}
                    controloPreSelectedId={controloPreSelectedId}
                  /> */}
            <FilterObrasCheck
              estadoProjeto={estadoProjeto}
              estadoSubProjeto={estadoSubProjeto}
              fetchObras={fetchObras}
              estadosProjetoPreSelectedId={estadosProjetoPreSelectedId}
              estadosSubProjetoPreSelectedId={estadosSubProjetoPreSelectedId}
              parceiroId={parceiroId}
              // CONTROLO
              controlo={controlo}
              controloPreSelectedId={controloPreSelectedId}
            />
          </Paper>

          <MUIDataTable
            title={
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
                className="text-underline"
              >
                Lista de obras
              </span>
            }
            data={obrasState || []}
            columns={columns}
            options={options}
          />
        </div>
        <div style={{ margin: "20px 40px" }}>
          <FooterLinks />
        </div>
      </div>
    </>
  );
};

export default Obras;
