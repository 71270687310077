import Instance from "../../../Axios";

// New Actions for the app

const AddPlusPartner = (yData) => async (dispatch) => {
  await Instance()
    .post(`/plus/adesoes/partners/add`, { yData })
    .then(({ data }) => {
      dispatch({ type: "ADD_PLUS_PARTNER", payload: data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default AddPlusPartner;
