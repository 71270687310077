import Instance from "../../../../Axios";

// New Actions for the app

const GetCredFuncionarios = (intervencaoId, rascunhoId) => async (dispatch) => {
  const intervencao = intervencaoId === "0" ? null : intervencaoId;
  const rascunho = rascunhoId ? rascunhoId : null;
  await Instance()
    .get(`/credenciacoes/funcionarios`, {
      params: {
        intervencao,
        rascunho,
      },
    })
    .then(({ data }) => {
      //   console.log(data);
      dispatch({
        type: "GET_CRED_FUNCIONARIOS",
        payload: data.yData,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetCredFuncionarios;
