import Instance from "../../../../Axios";

// New Actions for the app

const GetCredCores = () => async (dispatch) => {
  await Instance()
    .get(`/credenciacoes/cores`)
    .then(({ data }) => {
      dispatch({
        type: "GET_CRED_CORES",
        payload: data.yData,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetCredCores;
