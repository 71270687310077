import Instance from "../../../../Axios";

// New Actions for the app

const GetOrcamentosParceirosAll = (parceirosId, dataInicio, dataFim) => async (dispatch) => {
  await Instance()
    .get(`/energias/dashboard/orcamentosParceirosAll`, {
      params: { parceirosId, dataInicio, dataFim },
    })
    .then(({ data }) => {
      //   console.log(data);
      dispatch({
        type: "GET_ORCAMENTOS_PARCEIROS_ALL",
        payload: data.yData,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetOrcamentosParceirosAll;