import Instance from "../../../../Axios";

// New Actions for the app

const GetCredFuncionariosFicheiros = (aeroportos) => async (dispatch) => {
  await Instance()
    .get(`/credenciacoes/ficheiros`, { params: { aeroportos } })
    .then(({ data }) => {
      dispatch({
        type: "GET_CRED_FUNCIONARIOS_FICHEIROS",
        payload: data.yData,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetCredFuncionariosFicheiros;