/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { IconButton, Paper, Tooltip, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import Instance from "../../../../utils/Axios";
import BASE_URL from "../../../../utils/BASE_URL";

//ICONS
import FeatherIcon from "feather-icons-react";

//COMPONENTS
import ListaIntervencoesMobile from "./ListaIntervencoesMobile";
import FooterLinks from "../../../../Global/Components/Footer";
import SideDrawer from "../../../../pages/App/Intervencoes/components/drawer";
import CustomToolbar from "../components/customToolbar";
import ModalDelete from "../components/modals/modalDelete";
import ModalAdd from "../components/modals/modalAdd";
import FiltersIntervencoesCheck from "./filters/indexCheck";

//ACTIONS
import GetIntervencoes from "../../../../utils/redux/actions/intervencoes/GetIntervencoesAction";
import GetIntervencoesMobile from "../../../../utils/redux/actions/intervencoes/GetIntervencoesMobileAction";
import GetObras from "../../../../utils/redux/actions/intervencoes/GetObrasAction";
import GetIntervencao from "../../../../utils/redux/actions/intervencoes/GetIntervencaoAction";
import GetTipoPropostas from "../../../../utils/redux/actions/intervencoes/GetTipoPropostasAction";
import GetAllPrioridades from "../../../../utils/redux/actions/intervencoes/GetAllPrioridadesAction";


const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
  },
  stateLabel: {
    textTransform: "none",
    transition: "0.2s",
    display: "flex",
    alignItems: "center",
    maxWidth: "100px",
  },

  paperFilters: {
    background: "transparent",
    padding: "20px 0px",
    boxShadow: "none",
  },
}));

const ColorStateCube = ({ color }) => {
  return (
    <div
      style={{
        height: "10px",
        width: "10px",
        backgroundColor: color,
        marginRight: "10px",
        borderRadius: "3px",
      }}
    />
  );
};

// ############################################################## //
// ####################  Window Dimensions  ##################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Intervencoes = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [drawerWidth, setDrawerWidth] = useState("90vw");
  const dimensions = useWindowDimensions();

  const anoAtual = useSelector(
    (state) => state.intervencoes.intervencoesAnoSelected
  );
  const [ano, setAno] = useState(anoAtual);

  const [intervencaoId, setIntervencaoId] = useState();
  const [intervencaoSelecionada, setIntervencaoSelecionada] = useState(null);
  const [firstFetch, setFirstFetch] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const user = useSelector((state) => state.user);

  //Filters
  let situacaoPreSelectedId = useSelector(
    (state) => state.intervencoes.situacaoPreSelected
  );

  useEffect(() => {
    if (situacaoPreSelectedId && situacaoPreSelectedId.length <= 0) {
      dispatch({
        type: "SITUACAO_PRE_SELECTED",
        payload: [
          { id: 0, descricao: "Respondida", estado: true },
          { id: 1, descricao: "Não Repondida", estado: true },
          { id: 2, descricao: "Aguarda Visita", estado: false },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFirstFetch(true);
  }, [situacaoPreSelectedId]);

  const tipoPropostas = useSelector(
    (state) => state.intervencoes.tipoPropostas
  );
  const subEstados = [
    {id: 1, descricao: "Não Respondida"},
    {id: 2, descricao: "Aguarda Resposta"},
    {id: 3, descricao: "Aguarda Revisão"},
    {id: 4, descricao: "Respondida"},
  ]
  const [estado, setEstado] = useState({ id: null, descricao: "" });
  const [subEstado, setSubEstado] = useState({ id: null, descricao: "" });

  const fetchTipoPropostas = () => {
    dispatch(GetTipoPropostas());
  };

  const fetchAllPrioridades = () => {
    dispatch(GetAllPrioridades());
  };

  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [state, setState] = useState({
    right: false,
  });

  const [drawerState, setDrawerState] = useState(null);
  const [errorsIntervencao, setErrorsIntervencao] = useState({});
  const [intervencao, setIntervencao] = useState({
    pedidoNome: "",
    pedidoDescricao: "",
    prioridade: "",
    sig: "",
    imagesName: [],
  });
  const [images, setImages] = useState([]);

  const intervencoesState = useSelector(
    (state) => state.intervencoes.intervencoes
  );

  const obrasState = useSelector(
    (state) => state.intervencoes.intervencoesObras
  );

  const permissionsState = useSelector((state) => state.user.permissoes);
  const prioridades = useSelector(
    (state) => state.intervencoes.prioridades
  );

  // console.log(useWindowDimensions());

  const handleOpen = (btn, e, id) => {
    e.preventDefault();

    if (btn == "edit") {
    } else if (btn == "delete") {
      setOpenDelete(true);
      setIntervencaoId(id);
    } else {
      setOpenAdicionar(true);
      setErrorsIntervencao({});
      setIntervencao({
        pedidoNome: "",
        pedidoDescricao: "",
        imagesName: [],
      });
      setImages([]);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();

    if (btn == "edit") {
      setOpenEdit(false);
    } else if (btn == "delete") {
      setOpenDelete(false);
    } else {
      setOpenAdicionar(false);
    }
  };

  const { control, handleSubmit, reset, register, getValues, setValue, watch } =
    useForm({
      defaultValues: {
        aero_intervencoes_sig_coordenadas: "",
        dataCriacao: null,
        dataEntrega: null,
        esperaAtiva: {
          dataFim: null,
          dataInicio: null,
          intervencoesId: null,
          observacoes: "",
        },
        revisaoAtiva: {
          dataFim: null,
          dataInicio: null,
          intervencoesId: null,
          nRevisao: "",
        },
        esperas: [
          {
            dataInicio: "",
            dataFim: "",
            observacoes: "",
          },
        ],
        revisoes: [
          {
            dataInicio: "",
            dataFim: "",
            nRevisao: "",
          },
        ],
        estadosPropostasId: null,
        estadosPropostasName: null,
        files: [{ id: "", image: "", nome: "" }],
        funcionarioId: "",
        funcionarioNome: "",
        id: "",
        images: [{ id: "", image: "", nome: "" }],
        obraDescricao: null,
        obraId: "",
        pedidoData: "",
        pedidoDescricao: "",
        pedidoIdade: "",
        pedidoNome: "",
        prioridadeId: null,
        prioridadeDescricao: "",
        propostaData: "",
        propostaIdade: "",
        propostaValor: "",
        totalFicheiros: "",
        utilizadoresId: "",
        versaoApresentada: null,
        versaoApresentadaDescricao: null,
      },
    });
  // console.log(watch('esperas[0][dataInicio]'));

  const toggleDrawer = (anchor, open, id, ds) => {
    if (open == false) {
      setState({ ...state, [anchor]: open });
      dispatch({ type: "GET_INTERVENCAO", payload: [] });
    }
    if (open == true) {
      setDrawerState(ds);
      setIntervencaoSelecionada(null);
      setState({ ...state, [anchor]: open });
      fetchIntervencao(id);
      reset({
        obraId: "",
      });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (intervencaoSelecionada && resetForm == false) {
      reset({
        ...intervencaoSelecionada,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervencaoSelecionada]);

  const fetchIntervencoes = (ano, estado, subEstado) => {
    if (dimensions && dimensions.width <= 1100) {
      dispatch(GetIntervencoesMobile(situacaoPreSelectedId, ano, estado, subEstado, user.perfilId));
    } else dispatch(GetIntervencoes(situacaoPreSelectedId, ano, estado, subEstado, user.perfilId));
  };
  const fetchObras = () => {
    dispatch(GetObras());
  };

  const fetchIntervencao = (id) => {
    // const intId = int && int.id;
    dispatch(GetIntervencao(id, setIntervencaoSelecionada));
  };

  useEffect(() => {
    fetchIntervencoes(anoAtual, estado, subEstado);
    fetchObras();
    fetchTipoPropostas();
    fetchAllPrioridades();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstFetch]);

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "obraId",
      label: "Proposta",
      options: {
        filter: true,
        sort: true,
        display: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta.rowData[14] == " " || tableMeta.rowData[14] == null) {
            return (
              <Tooltip title={"Descrição: N/D "}>
                <Typography>{value}</Typography>
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title={"Descrição:  " + tableMeta.rowData[14]}>
                <Typography>{value}</Typography>
              </Tooltip>
            );
          }
        },
      },
    },
    {
      name: "pedidoNome",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "100px" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta.rowData[14] == " " || tableMeta.rowData[14] == null) {
            return <Tooltip title={"Descrição: N/D "}>
            <Typography>{value}</Typography>
          </Tooltip>;
          } else {
            return (
              <Tooltip title={"Descrição:  " + tableMeta.rowData[14]}>
                <Typography>{value}</Typography>
              </Tooltip>
            );
          }
        },
      },
    },
    {
      name: "pedidoData",
      label: "Data Pedido",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("DD/MM/yy");
        },
      },
    },
    {
      name: "pedidoIdade",
      label: "Idade Pedido",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          if (value != null) {
            return value + " dias";
          } else {
            return " - ";
          }
        },
      },
    },
    {
      name: "propostaData",
      label: "Data Proposta",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value != null) {
            return moment(value).format("DD/MM/yy");
          } else {
            return " - ";
          }
        },
      },
    },
    {
      name: "propostaIdade",
      label: "Idade Proposta",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value != null) {
            return value + " dias";
          } else {
            return " - ";
          }
        },
      },
    },

    {
      name: "funcionarioNome",
      label: "Responsável",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "250px" },
        }),
      },
    },
    {
      name: "estadosPropostasName",
      label: "Estado",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          switch (tableMeta.rowData[8]) {
            case "-":
              return (
                <div className={classes.stateLabel}>{tableMeta.rowData[9]}</div>
              );
            case "Aberta":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#54B7D3" />

                  {tableMeta.rowData[8]}
                </div>
              );
            case "Adjudicada":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#D6DFE8" />
                  {tableMeta.rowData[8]}
                </div>
              );
            case "Fechada":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#D6DFE8" />
                  {tableMeta.rowData[8]}
                </div>
              );
            case "Suspenso":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#FF8521" />
                  {tableMeta.rowData[8]}
                </div>
              );
            default:
              return <div>{tableMeta.rowData[8]}</div>;
          }
        },
      },
    },
    {
      name: "propostaValor",
      label: "Valor Proposta",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ align: "right" }),
        setCellHeaderProps: () => ({
          style: {
            display: "flex",
            justifyContent: "right",
            paddingRight: 0,
          },
        }),

        customBodyRender: (value, tableMeta, updateValue) => {
          if (value != null) {
            return value.toLocaleString("PT") + "€";
          } else {
            return "-";
          }
        },
      },
    },
    {
      name: "prioridade",
      label: "Prioridade",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "temEsperaAtiva",
      label: " ",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (value == 0) {
            return <> </>;
          } else if (value == 1) {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <FeatherIcon
                  icon="clock"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </div>
            );
          }
        },
      },
    },
    {
      name: "totalFicheiros",
      label: " ",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value, tableMeta) => {
          if (value == 0) {
            return <> </>;
          } else if (value == 1) {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <FeatherIcon
                  icon="clock"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </div>
            );
          }
        },
      },
    },

    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: "right",
          style: {
            minWidth: "140px",
          },
        }),

        customBodyRender: (value, tableMeta) => {
          // const int = intervencoesState[tableMeta.rowIndex];
          // console.log(int);
          const id = tableMeta.rowData[0];
          const fileLength = tableMeta.rowData[11];
          // console.log(intervencoesState[tableMeta.rowIndex]);
          // console.log(int.id, id);
          // console.log(tableMeta.rowData);

          const userEditar = permissionsState.includes(
            "APP_INTERVENCOES_EDITAR"
          );
          const userConsultar = permissionsState.includes(
            "APP_INTERVENCOES_CONSULTA"
          );
          const userRemover = permissionsState.includes(
            "APP_INTERVENCOES_REMOVER"
          );

          return (
            <>
              {userEditar == true && userConsultar == true ? (
                <>
                  <IconButton
                    style={{
                      padding: 6,
                      color: theme.palette.secondary.main,
                    }}
                    onClick={() => toggleDrawer("right", true, id, "edit")}
                  >
                    <FeatherIcon
                      icon="edit"
                      size={20}
                      style={{ strokeWidth: "1.5" }}
                    />
                  </IconButton>{" "}
                </>
              ) : null}
              {userEditar == true && userConsultar == false ? (
                <>
                  <IconButton
                    style={{
                      padding: 6,
                      color: theme.palette.secondary.main,
                    }}
                    onClick={() => toggleDrawer("right", true, id, "edit")}
                  >
                    <FeatherIcon
                      icon="edit"
                      size={20}
                      style={{ strokeWidth: "1.5" }}
                    />
                  </IconButton>{" "}
                </>
              ) : null}

              {userEditar == false && userConsultar == true ? (
                <>
                  <IconButton
                    size="0.9rem"
                    style={{
                      padding: 6,
                      color: theme.palette.secondary.main,
                    }}
                    onClick={() => toggleDrawer("right", true, id, "check")}
                  >
                    <FeatherIcon
                      icon="eye"
                      size={20}
                      style={{ strokeWidth: "1.5" }}
                    />
                  </IconButton>
                </>
              ) : null}

              {fileLength > 0 ? (
                <IconButton
                  size="0.9rem"
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                  onClick={() => {
                    Instance()
                      .get(
                        `${BASE_URL}/intervencoes/intervencoes/${id}/download`,
                        { responseType: "blob" }
                      )
                      .then(({ data }) => {
                        const downloadUrl = window.URL.createObjectURL(
                          new Blob([data])
                        );

                        const link = document.createElement("a");

                        link.href = downloadUrl;

                        link.setAttribute(
                          "download",
                          `intervencao_${id}_propostas.zip`
                        ); //any other extension

                        document.body.appendChild(link);

                        link.click();
                        link.remove();
                        // dispatch(ShowNotification(message));
                      })
                      .catch((err) => {
                        console.log(err.response);
                        if (err.response) {
                          if (err.response === 403) {
                            // dispatch(ShowNotification(errorMessage));
                          }
                        }
                      });
                  }}
                >
                  <FeatherIcon
                    icon="download"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              ) : null}

              {userRemover == true ? (
                <>
                  <IconButton
                    style={{
                      padding: 6,
                      color: theme.palette.secondary.main,
                    }}
                    onClick={(e) => handleOpen("delete", e, id)}
                  >
                    <FeatherIcon
                      icon="trash-2"
                      size={20}
                      style={{ strokeWidth: "1.5" }}
                    />
                  </IconButton>
                </>
              ) : null}
            </>
          );
        },
      },
    },
    {
      name: "pedidoDescricao",
      label: "",
      options: {
        display: false,
      },
    },
  ];

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    // setRowProps: (row, dataIndex, rowIndex) => {
    //   return {
    //     style:
    //       rowIndex % 2
    //         ? { backgroundColor: 'transparent' }
    //         : { backgroundColor: '#F3F5F8' },
    //   };
    // },
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },
    customToolbar: () => {
      return (
        permissionsState.includes("APP_INTERVENCOES_ADICIONAR") && (
          <CustomToolbar handleOpen={handleOpen} onClose={handleClose} />
        )
      );
    },
  };

  // eslint-disable-next-line no-unused-vars

  // useEffect(() => {
  //   if (dimensions.width <= 800) {
  //     setDrawerWidth('90vw');
  //   } else if (dimensions.width <= 1100) {
  //     setDrawerWidth('80vw');
  //   } else if (dimensions.width <= 1300) {
  //     setDrawerWidth('60vw');
  //   } else {
  //     setDrawerWidth('45vw');
  //   }
  // }, [dimensions]);

  return (
    <>
      <div
        style={{
          display: "flex",
          minHeight: "94vh",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <>
            <SideDrawer
              state={state}
              toggleDrawer={toggleDrawer}
              intervencaoSelecionada={intervencaoSelecionada}
              setIntervencaoSelecionada={setIntervencaoSelecionada}
              fetchIntervencoes={fetchIntervencoes}
              drawerState={drawerState}
              obras={obrasState}
              drawerWidth={drawerWidth}
              dimensions={dimensions}
              // React hook form
              control={control}
              handleSubmit={handleSubmit}
              register={register}
              reset={reset}
              getValues={getValues}
              setValue={setValue}
              resetForm={resetForm}
              setResetForm={setResetForm}
              watch={watch}
              anoAtual={anoAtual}
              estado={estado}
              subEstado={subEstado}
              prioridades={prioridades}
            />

            <ModalDelete
              openDelete={openDelete}
              handleClose={handleClose}
              intervencaoId={intervencaoId}
              fetchIntervencoes={fetchIntervencoes}
              anoAtual={anoAtual}
              estado={estado}
              subEstado={subEstado}
            />
            <ModalAdd
              openAdd={openAdicionar}
              handleClose={handleClose}
              fetchIntervencoes={fetchIntervencoes}
              errorsIntervencao={errorsIntervencao}
              setErrorsIntervencao={setErrorsIntervencao}
              intervencao={intervencao}
              setIntervencao={setIntervencao}
              images={images}
              setImages={setImages}
              anoAtual={anoAtual}
              estado={estado}
              subEstado={subEstado}
            />
            <div
              style={{
                padding:
                  dimensions && dimensions.width < 800
                    ? "0px 10px 0px 20px"
                    : "0px 40px 0px 50px",
              }}
            >
              <Paper className={classes.paperFilters} elevation={5}>
                <FiltersIntervencoesCheck
                  user={user}
                  fetchIntervencoes={fetchIntervencoes}
                  ano={ano}
                  setAno={setAno}
                  // SITUACAO
                  // situacao={situacao}
                  situacaoPreSelectedId={situacaoPreSelectedId}
                  dimensions={dimensions}
                  estado={estado}
                  setEstado={setEstado}
                  tipoPropostas={tipoPropostas}
                  subEstado={subEstado}
                  setSubEstado={setSubEstado}
                  subEstados={subEstados}
                />
              </Paper>

              {useWindowDimensions().width <= 1100 ? (
                <ListaIntervencoesMobile
                  toggleDrawer={toggleDrawer}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  permissionsState={permissionsState}
                />
              ) : (
                <MUIDataTable
                  title={
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                      className="text-underline"
                    >
                      Lista de intervenções
                    </span>
                  }
                  data={intervencoesState}
                  columns={columns}
                  options={options}
                />
              )}
            </div>

            <div style={{ padding: "20px 40px" }}>
              <FooterLinks />
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default Intervencoes;
