import React from 'react';
import { TextField, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

const HistoricoEsperas = ({ intervencaoSelecionada }) => {
  return (
    <>
      <div style={{ width: '100%' }}>
        <h4
          style={{ color: '#E21450' }}
          className='text-underline-title-drawer '
        >
          Esperas
        </h4>
        <Carousel autoPlay={false}>
          {intervencaoSelecionada &&
            intervencaoSelecionada.esperas &&
            intervencaoSelecionada.esperas.map((espera, index) => (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ width: '49%', marginBottom: '16px' }}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Data Inicial
                  </Typography>
                  <TextField
                    size='small'
                    value={espera.dataInicio || ' --/--/---- '}
                    variant='outlined'
                    disabled
                    style={{ width: '100%' }}
                  />
                </div>
                <span style={{ width: '2%' }} />
                <div style={{ width: '49%' }}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Data Final
                  </Typography>

                  <TextField
                    size='small'
                    value={espera.dataFim || ' - '}
                    variant='outlined'
                    disabled
                    style={{ width: '100%' }}
                  />
                </div>

                <div style={{ width: '100%', marginBottom: '16px' }}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Observacoes
                  </Typography>
                  <TextField
                    size='small'
                    value={espera.observacoes || ' - '}
                    variant='outlined'
                    disabled
                    style={{ width: '100%' }}
                    multiline
                  />
                </div>
                {/* <span style={{ width: '2%' }} />
          <div style={{ width: '49%' }}>
            <Typography style={{ fontWeight: 'bold' }}>
              Idade da Proposta
            </Typography>
            <TextField
              size='small'
              value={
                (intervencaoSelecionada &&
                  intervencaoSelecionada.propostaIdade) ||
                ' --/--/---- '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <div style={{ width: '49%', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold' }}>
              Data de Criação
            </Typography>

            <TextField
              size='small'
              value={dataCriacao || ' --/--/---- '}
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <span style={{ width: '2%' }} />

          <div style={{ width: '49%', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold' }}>
              Data de Entrega
            </Typography>
            <TextField
              size='small'
              value={dataEntrega || ' --/--/---- '}
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div> */}
              </div>
            ))}
        </Carousel>

        <div style={{ display: 'flex' }}></div>
      </div>
    </>
  );
};

export default HistoricoEsperas;
