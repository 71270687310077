import Instance from "../../../../Axios";

// New Actions for the app

const GetAllPropostasTimeline =
  (
    estadosProjetoPreSelectedId,
    estadosSubProjetoPreSelectedId,
    dataInicial,
    dataFinal
  ) =>
  async (dispatch) => {
    const estadosProjetos = estadosProjetoPreSelectedId;
    const estadosSubProjetos = estadosSubProjetoPreSelectedId;
    await Instance()
      .get(`/energias/dashboard/allPropostasTimeline`, {
        params: {
          estadosProjetos,
          estadosSubProjetos,
          dataInicial,
          dataFinal,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: "GET_ALL_PROPOSTAS_TIMELINE",
          payload: data.yData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default GetAllPropostasTimeline;
