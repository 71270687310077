/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const GraficoPesoValoresAdjudicados = (orcamentosAdjudicados) => {
  // const orcamentosAdjudicadosValor = useSelector(
  //   (state) => state.energiasDashboard.orcamentosAdjudicadosValor
  // );

  const orcamentos =
    orcamentosAdjudicados && orcamentosAdjudicados.orcamentosAdjudicados;
  
  const [state, setState] = useState({
    series: [],
    options: {
      labels: [],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return Math.round(val) + "%";
        },
      },
      title: {
        text: "Peso dos Valor dos Orçamentos Adjudicados",
        align: "center",
        style: {
          fontFamily: "Lato",
        },
      },
      legend: {
        position: "right",
        fontSize: "10px",
        offsetX: 10,
        offsetY: 50,
        markers: {
          width: 10,
          height: 10,
        },
        itemMargin: {
          horizontal: 0,
          vertical: 0,
        },
      },
      plotOptions: {
        pie: {
          offsetY: 20,
        },
      },
      colors: ["#4FC0E8","#FF4A4A","#8CC051","#5D9CEC","#967BDC","#FFA65D","#36BC9B","#F6BB43","#D870AD"]
    },
  });

  useEffect(() => {
    setState({
      series: orcamentos.map((item) => item.valorTotalAdjudicado),
      options: {
        labels: orcamentos.map((item) => item.nome),
      },
    });
  }, [orcamentos]);

  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="donut"
        width={400}
      />
    </>
  );
};

export default GraficoPesoValoresAdjudicados;
