import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Card, TextField, Typography } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { useDispatch } from 'react-redux';

//ACTIONS
import AddSetor from '../../../../../utils/redux/actions/energias/setores/AddSetor';

const useStyles = makeStyles({
  list: {
    width: '80vw',
    borderRadius: '10px 0px 0px 10px !important',
    // background: 'red !important',
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    background: '#FAFAFA',
  },
  btnGuardar: {
    color: 'white',
    width: '120px',
    height: '40px',
    // borderRadius: '15px',
    backgroundColor: '#E21450',
    border: `1px solid #E21450`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#bc1041',
      border: '1px solid #bc1041',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#bc1041',
      },
      '&:disabled': {
        backgroundColor: '#bc1041',
      },
    },
  },
  btnCloseDrawer: {
    color: '#464255',
    width: '30px',
    height: '40px',
    borderRadius: '15px',
    backgroundColor: '#f2f2f2',
    border: `1px solid #f2f2f2`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#e0e0e0',
      border: '1px solid #e0e0e0',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#e0e0e0',
      },
    },
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  noBorder: {
    border: 'none',
  },
  btnFiltrar: {
    color: '#E21450',
    width: '115px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  btnTrue: {
    display: 'inline-block',
    width: '100%',
    height: 40,
    border: '1px solid lightgrey',
    borderRadius: '5px',
    background: 'white',
    '&:focus': {
      background: 'white',
    },
    '&:hover': {
      backgroundColor: 'lightgrey',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'lightgrey',
      },
    },
  },
  btnFalse: {
    display: 'inline-block',
    width: '100%',
    height: 40,
    border: '1px solid lightgrey',
    borderRadius: '5px',
    background: 'lightgrey',
    '&:focus': {
      background: 'lightgrey',
    },
    '&:hover': {
      backgroundColor: 'lightgrey',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'lightgrey',
      },
    },
  },
  btnUpload: {
    color: '#E21450',
    // width: '125px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
});

const FormAdd = ({
  toggleDrawer,
  drawerWidth,
  dimensions,
  setor,
  setSetor,
  fetchSetores,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    e.preventDefault();
    setSetor({
      ...setor,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        descricao: setor.descricao,
      },
    ];
    dispatch(AddSetor(ydata))
      .then(() => toggleDrawer('right', false))
      .then(() => fetchSetores());
  };

  return (
    <div
      className={classes.list}
      style={
        dimensions.width <= 1100
          ? { width: '60vw' }
          : { width: `${drawerWidth}` }
      }
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          m: 5,
        }}
        onClick={() => toggleDrawer('right', false)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <div>
            <FeatherIcon
              icon='chevron-left'
              size={20}
              style={{ strokeWidth: '1.5' }}
            />
          </div>
          <div className='text-underline-close-drawer'>Setores</div>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant='contained'
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box>
      </Box>
      <Card
        sx={{
          m: 5,
          p: 2,
          boxShadow: '0px 10px 15px lightgrey',
        }}
      >
        <Box
          sx={{
            m: 2,
          }}
        >
          <h4
            style={{
              fontSize: '22px',
              width: '100%',
              marginBottom: '0px',
            }}
            className='text-underline'
          >
            Setor
          </h4>
        </Box>
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: '#E21450' }}
            className='text-underline-title-drawer '
          >
            Detalhes
          </h4>
        </Box>

        <Box sx={{ m: 2 }}>
          <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
            Nome
          </Typography>
          <TextField
            name='descricao'
            size='small'
            variant='outlined'
            onChange={handleChange}
            style={{ width: '100%' }}
          />
        </Box>
      </Card>
    </div>
  );
};

export default FormAdd;
