import Instance from '../../../../Axios';

const GetDepartamentos = () => async (dispatch) => {
  await Instance()
    .get(`/intervencoes/dashboard/departamentos`)
    .then(({ data }) => {
      dispatch({ type: 'GET_DEPARTAMENTOS_DASHBOARD', payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetDepartamentos;
