import Instance from "../../../../Axios";

// New Actions for the app

const GetWorkPermitAccountablesAHD = () => async (dispatch) => {
  await Instance()
    .get(`/construcoes/workpermits/accountablesAHD`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({ type: "GET_WORK_PERMIT_ACCOUNTABLES_AHD", payload: data.yData });
      // setObraSelecionada(data.yData[0]);
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetWorkPermitAccountablesAHD;