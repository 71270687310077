import Instance from "../../../../Axios";

// New Actions for the app

const DeleteWorkPermitAccountableById =
  (id, ShowNotification, message, errorMessage) =>
  async (dispatch) => {
    await Instance()
      .put(`/construcoes/workpermits/accountable/delete/${id}`)
      .then(({ data }) => {
        // console.log(data);
        dispatch({
          type: "EDIT_WORK_PERMIT_ACCOUNTABLE_ID",
          payload: data.yData[0],
        });
        dispatch(ShowNotification(message));
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default DeleteWorkPermitAccountableById;
