import Instance from '../../../Axios';

// New Actions for the app

const GetDepartamentos = (aeroId) => async (dispatch) => {
  console.log('Passei na action', aeroId);
  await Instance()
    .get(`/departamentos/${aeroId}`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({ type: 'GET_DEPARTAMENTOS', payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetDepartamentos;
