import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

const Controlo = ({
  obraSelecionada,
  control,
  renderSwitch,
  estDTGlobal,
  estDCGlobal,
  getValues,
}) => {
  return (
    <>
      <div style={{ marginLeft: "30px" }}>
        {/* <h4
          style={{ color: "#E21450" }}
          className="text-underline-title-drawer "
        >
          Controlo
        </h4>
        <div style={{ display: "flex" }}>
          <div style={{ width: "250px" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Estados
            </Typography>
            <Controller
              name="estadosControloDropdown"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Autocomplete
                    fullWidth
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    className="unidadesId-form"
                    options={estadosControlo || []}
                    value={value || null}
                    getOptionLabel={(option) => `${option.descricao}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    filterOptions={(options, state) => {
                      let newOptions = [];
                      options.forEach((option) => {
                        if (
                          option.id > 0 &&
                          option.descricao
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        )
                          newOptions.push(option);
                      });
                      return newOptions;
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                    onChange={(_, value) => {
                      onChange(value);

                      return value;
                    }}
                  />
                </>
              )}
            />
          </div>
        </div> */}
        <h4
          style={{ color: "#E21450" }}
          className="text-underline-title-drawer "
        >
          Estados
        </h4>
        <div
          style={{
            display: "flex",
            width: "260px",
          }}
        >
          <Box sx={{ width: "50%", display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Dep. Téc.
            </Typography>
          </Box>
          <Box sx={{ width: "50%", display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Compras
            </Typography>
          </Box>
          {/* <Box sx={{ width: "33%", display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Logística
            </Typography>
          </Box> */}
        </div>
        <div
          style={{
            display: "flex",
            width: "260px",
            justifyContent: "space-around",
          }}
        >
          <div>{renderSwitch(getValues("estadodt"))}</div>
          <div>{renderSwitch(getValues("estadodc"))}</div>
          {/* <div>{renderSwitch(getValues("estadodc"))}</div> */}
        </div>
      </div>
    </>
  );
};

export default Controlo;
