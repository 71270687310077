import { docsOficiaisInitialState } from "../initialState/docsOficiais";

const docsOficiaisReducer = (
  state = docsOficiaisInitialState,
  { type, payload }
) => {
  switch (type) {
    case "GET_DOCUMENTOS_OFICIAIS":
      return {
        ...state,
        documentosOficiais: payload,
      };
    case "GET_DOCUMENTO_OFICIAL":
      return {
        ...state,
        documentoOficial: payload,
      };
    case "DELETE_DOCUMENTO_OFICIAL":
      return {
        ...state,
        documentoApagado: payload,
      };
    case "ADD_DOCUMENTO_OFICIAL":
      return {
        ...state,
        documentoOficialAdicionado: payload,
      };
    case "GET_LAST_DOCUMENTOS_OFICIAIS":
      return {
        ...state,
        lastDocumentosOficiais: payload,
      };

    default:
      return state;
  }
};

export default docsOficiaisReducer;
