/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import ptLocale from "date-fns/locale/pt";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Instance from "../../../../../../utils/Axios";
import BASE_URL from "../../../../../../utils/BASE_URL";
import { Tooltip } from "@mui/material";
import fileDownload from "js-file-download";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import advanceFormat from "dayjs/plugin/advancedFormat";
import { Checkbox } from "@mui/material";
import moment from "moment";

//Icons
import FeatherIcon from "feather-icons-react";

//Actions
import GetAllWorkPermits from "../../../../../../utils/redux/actions/aprovisionamento/obras/GetAllWorkPermitsAction";
import GetObra from "../../../../../../utils/redux/actions/aprovisionamento/obras/GetObraAction";
import GetAllMEIO from "../../../../../../utils/redux/actions/aprovisionamento/obras/GetAllMEIOAction";
import GetWPImageTypes from "../../../../../../utils/redux/actions/aprovisionamento/obras/GetWPImageTypesAction";
import GetWorkPermitAccountables from "../../../../../../utils/redux/actions/aprovisionamento/obras/GetWorkPermitAccountablesAction";
import GetWorkPermitAccountablesAHD from "../../../../../../utils/redux/actions/aprovisionamento/obras/GetWorkPermitAccountablesAHDAction";
import GetWorkPermitEntities from "../../../../../../utils/redux/actions/aprovisionamento/obras/GetWorkPermitEntitiesAction";
import GetWorkPermitsSendContacts from "../../../../../../utils/redux/actions/aprovisionamento/obras/GetWorkPermitsSendContactsAction";
import UpdateWorkPermit from "../../../../../../utils/redux/actions/aprovisionamento/obras/UpdateWorkPermitAction";
import ShowNotification from "../../../../../../utils/redux/actions/global/notifications/NotificationAction";
import GetWorkPermitDefaultAccountablesAHD from "../../../../../../utils/redux/actions/aprovisionamento/obras/GetDefaultAccountablesAHDAction";
import GetDefaultNotifyEmailsWP from "../../../../../../utils/redux/actions/aprovisionamento/obras/GetDefaultNotifyEmailsWPAction";
import ModalRascunhoWP from "./modalRascunhoWP";

const FormEditarWorkPermit = ({
  toggleDrawer,
  classes,
  workPermit,
  setWorkPermit,
  fetchWorkPermit,
  resetFieldsToNull,
}) => {
  dayjs.extend(advanceFormat);
  const dispatch = useDispatch();

  const obra = useSelector((state) => state.obras.obra);
  const idObra = obra.obrasId;
  const selectedworkpermit = useSelector((state) => state.obras.workPermit);
  const uploadedFiles = useSelector(
    (state) => state.obras.workPermitUploadedFiles
  );

  // ############################################################## //
  // ######################  Autocompletes  ####################### //
  // ############################################################## //

  const accountables = useSelector(
    (state) => state.obras.workPermitsAccountables
  );
  const accountablesAHD = useSelector(
    (state) => state.obras.workPermitsAccountablesAHD
  );
  const entidades = useSelector((state) => state.obras.workPermitsEntities);

  const estadosWP = [
    {
      id: 1,
      descricao: "Em curso",
    },
    {
      id: 2,
      descricao: "Concluído",
    },
  ];

  const contactsAHD = useSelector(
    (state) => state.obras.workPermitsContactsAHD
  );
  const contactsAHDList =
    contactsAHD &&
    contactsAHD.map((c) => {
      return c.descricao;
    });

  const emailBodyTemplates = useSelector(
    (state) => state.obras.workPermitsEmailBodyTemplate
  );
  const emailBodyDefault =
    emailBodyTemplates && emailBodyTemplates.find((e) => e.type === 1);
  const emailBodyProrrogacao =
    emailBodyTemplates && emailBodyTemplates.find((e) => e.type === 2);
  const emails = useSelector((state) => state.obras.workPermitsSendContacts);
  const emailsCCDefault = useSelector(
    (state) => state.obras.workPermitsDefaultEmails
  );

  const emailsdefJAF = emailsCCDefault
    .filter((e) => e.entity === 22)
    .map((e) => ({ id: e.id, descricao: e.descricao, email: e.email }));
  const emailsdefAHD = emailsCCDefault
    .filter((e) => e.entity === 23)
    .map((e) => ({ id: e.id, descricao: e.descricao, email: e.email }));

  const [entidadeExec, setEntidadeExec] = useState({
    id: "FI000001",
    descricao: "JAF",
  });
  const [entidadeSol, setEntidadeSol] = useState({ id: null, descricao: "" });
  const [estadoWP, setEstadoWP] = useState({ id: null, descricao: "" });
  const [responsavel, setResponsavel] = useState([]);
  const [responsavelAHD, setResponsavelAHD] = useState([]);
  const [enderecos, setEnderecos] = useState([]);
  const [contactos, setContactos] = useState([]);
  const [contactosAHD, setContactosAHD] = useState([]);
  const [emailsDest, setEmailsDest] = useState([]);
  const [emailsCC, setEmailsCC] = useState([]);

  const filterOptions = (options, { inputValue }) => {
    return options.filter(
      (option) =>
        option.descricao.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.email.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  // const handleChangeDefaultResponsavelAHD = () => {
  //   setResponsavelAHD(defaultAccountablesAHD);
  //   setContactosAHD(defaultAccountablesAHD);
  // };

  // ############################################################## //
  // #################  Dates and Time changes  ################### //
  // ############################################################## //

  const [acessoInicial, setAcessoInicial] = useState(new Date());
  const [acessoFinal, setAcessoFinal] = useState(new Date());
  const [horasInicial, setHorasInicial] = useState(dayjs());
  const [horasFinal, setHorasFinal] = useState(dayjs());

  const handleAcessoInicialChange = (date) => {
    setAcessoInicial(date);
  };

  const handleAcessoFinalChange = (date) => {
    setAcessoFinal(date);
    const newText = emailBodyProrrogacao.body.replace(
      /{data de expiração}/g,
      moment(date).format("LL")
    );

    setWorkPermit({
      ...workPermit,
      corpo: prorrogacao === "YES" ? newText : emailBodyDefault.body,
    });
  };

  const handleHorasInicialChange = (hour) => {
    setHorasInicial(hour);
  };

  const handleHorasFinalChange = (hour) => {
    setHorasFinal(hour);
  };

  // ############################################################## //
  // ########################  Checkboxes  ######################## //
  // ############################################################## //

  const allmeio = useSelector((state) => state.obras.workPermitsMEIO);

  const initialState = {
    1: { id: 1, value: false }, //Ferramentas e Materiais
    2: { id: 2, value: false }, //Check-List Trabalhos em Altura
    3: { id: 3, value: false }, //Fichas de Procedimentos de Segurança
    4: { id: 4, value: false }, //Trabalhos de Corte e Soldadura
    5: { id: 5, value: false }, //Utilização de Meios de Elevação
    6: { id: 6, value: false }, //Odores
    7: { id: 7, value: false }, //Poeiras
    8: { id: 8, value: false }, //SADI
    9: { id: 9, value: false }, //SACA
    10: { id: 10, value: false }, //AVAC
    11: { id: 11, value: false }, //ITED
    12: { id: 12, value: false }, //I. Elétrica
    13: { id: 13, value: false }, //Publicidade
  };

  const [meio, setMeio] = useState(initialState);

  // Function to update the meio state
  const updateMeioState = (array) => {
    const updatedMeio = { ...meio };
    array.forEach((id) => {
      // Convert id to number
      const idNumber = parseInt(id, 10);
      // Check if the id exists in initialState
      if (updatedMeio[idNumber]) {
        // Update value to true
        updatedMeio[idNumber].value = true;
      }
    });
    // Set the updated meio state
    setMeio(updatedMeio);
  };

  const handleCheckboxChange = (id) => {
    setMeio((prevMeio) => ({
      ...prevMeio,
      [id]: {
        ...prevMeio[id],
        value: !prevMeio[id].value,
      },
    }));
  };

  const renderCheckboxes = (startId, endId) => {
    return (
      allmeio &&
      allmeio
        .filter((checkbox) => checkbox.id >= startId && checkbox.id <= endId)
        .map((checkbox) => (
          <Box
            key={checkbox.id}
            sx={{
              display: "flex",
              my: 1,
              alignItems: "center",
            }}
          >
            <Box>
              <Checkbox
                checked={meio[checkbox.id] ? meio[checkbox.id].value : false}
                onChange={() => handleCheckboxChange(checkbox.id)}
              />
            </Box>
            <Typography style={{ fontWeight: "bold" }}>
              {checkbox.descricao}
            </Typography>
          </Box>
        ))
    );
  };

  const renderMeioText = (startId, endId) => {
    return (
      allmeio &&
      allmeio
        .filter((tf) => tf.id >= startId && tf.id <= endId)
        .map((tf) => (
          <Box sx={{ m: 1 }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              {tf.descricao}
            </Typography>
            <TextField
              name={tf.input}
              size="small"
              variant="outlined"
              value={workPermit[tf.input]}
              onChange={handleChange}
              style={{ width: "100%" }}
            />
          </Box>
        ))
    );
  };

  // ############################################################## //
  // ####################  Radio Prorrogação  ##################### //
  // ############################################################## //

  const [prorrogacao, setProrrogacao] = useState("NO"); //Prorrogação

  const handleChangeProrrogacao = (event) => {
    setProrrogacao(event.target.value);
    const newText = emailBodyProrrogacao.body.replace(
      /{data de expiração}/g,
      moment(acessoFinal).format("LL")
    );

    setWorkPermit({
      ...workPermit,
      corpo: event.target.value === "YES" ? newText : emailBodyDefault.body,
    });
  };

  // ############################################################## //
  // ########################  Ficheiros  ######################### //
  // ############################################################## //

  const fileTypes = useSelector((state) => state.obras.workPermitsFileTypes);
  const fileWP =
    fileTypes && fileTypes.find((f) => f.id_jaf_work_permit_file_type == 5);

  const [workPermitF, setWorkPermitF] = useState(); // Array to store uploaded files

  const handleWPChange = (e, ficheiro) => {
    // Create an object for the selected file with additional properties
    const updatedFile = {
      file: e.target.files[0],
      idTipo: ficheiro.id_jaf_work_permit_file_type,
      path: ficheiro.jaf_work_permit_file_type_path,
    };

    // Set the file as the new value (replacing any existing file)
    setWorkPermitF(updatedFile);
  };

  const handleRemoveWP = () => {
    // Clear the selected file by setting it to null or an empty object
    setWorkPermitF(null); // You can choose an appropriate value to represent "no file" here
  };

  // #################################################################### //

  const filePercurso =
    fileTypes && fileTypes.find((f) => f.id_jaf_work_permit_file_type == 1);

  const [percurso, setPercurso] = useState(); // Array to store uploaded files

  const handlePercursoChange = (e, ficheiro) => {
    // Create an object for the selected file with additional properties
    const updatedFile = {
      file: e.target.files[0],
      idTipo: ficheiro.id_jaf_work_permit_file_type,
      path: ficheiro.jaf_work_permit_file_type_path,
    };

    // Set the file as the new value (replacing any existing file)
    setPercurso(updatedFile);
  };

  const handleRemovePercurso = () => {
    // Clear the selected file by setting it to null or an empty object
    setPercurso(null); // You can choose an appropriate value to represent "no file" here
  };

  // #################################################################### //

  const filePlanta =
    fileTypes && fileTypes.find((f) => f.id_jaf_work_permit_file_type == 2);

  const [planta, setPlanta] = useState(); // Array to store uploaded files

  const handlePlantaChange = (e, ficheiro) => {
    // Create an object for the selected file with additional properties
    const updatedFile = {
      file: e.target.files[0],
      idTipo: ficheiro.id_jaf_work_permit_file_type,
      path: ficheiro.jaf_work_permit_file_type_path,
    };

    // Set the file as the new value (replacing any existing file)
    setPlanta(updatedFile);
  };

  const handleRemovePlanta = () => {
    // Clear the selected file by setting it to null or an empty object
    setPlanta(null); // You can choose an appropriate value to represent "no file" here
  };

  // #################################################################### //

  const fileAnexo =
    fileTypes && fileTypes.find((f) => f.id_jaf_work_permit_file_type == 4);

  const [anexos, setAnexos] = useState([]); // Array to store uploaded files

  const handleAnexosChange = (e, ficheiro) => {
    // Create an object for the selected file with additional properties
    const updatedFiles = Array.from(e.target.files).map((file) => ({
      file,
      idTipo: ficheiro.id_jaf_work_permit_file_type,
      path: ficheiro.jaf_work_permit_file_type_path,
    }));

    // Add the new files to the existing array of files
    setAnexos([...anexos, ...updatedFiles]);
  };

  const handleRemoveAnexos = (index) => {
    // Create a copy of the current files array
    const updatedAnexos = [...anexos];
    // Remove the file at the specified index
    updatedAnexos.splice(index, 1);
    // Set the updated array of files
    setAnexos(updatedAnexos);
  };

  // ############################################################## //
  // ###############  Handle Change and Submit Form  ############## //
  // ############################################################## //
  // Verify form credenciação permanente
  const verifyForm = () => {
    // Check if the required fields are filled
    const isRequiredFilled =
      !!workPermit.assunto &&
      !!workPermit.corpo &&
      !!workPermit.numSeqWP &&
      !!obra.descricao &&
      !!entidadeSol.id &&
      !!workPermit.descricao &&
      !!acessoFinal &&
      !!responsavel &&
      !!responsavelAHD &&
      !!emails &&
      !!percurso &&
      !!planta;

    return isRequiredFilled;
  };

  const fetchObra = (obra) => {
    const obraId = obra;
    dispatch(GetObra(obraId));
  };

  const handleChange = (e) => {
    e.preventDefault();
    setWorkPermit({
      ...workPermit,
      [e.target.name]: e.target.value,
    });
  };

  // ############################################################## //
  // ######################  Modal Rascunho  ###################### //
  // ############################################################## //

  const [openRascunho, setOpenRascunho] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);
  const [modalState, setModalState] = useState("");

  const handleOpen = (e, ydata) => {
    e.preventDefault();

    setLoading(false);
    setDados(ydata);
    setModalState("edit");
    setOpenRascunho(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpenRascunho(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        id_obra: idObra,
        numeroWP: workPermit.numeroWP,
        numSeqWP: workPermit.numSeqWP,
        wp_status: estadoWP.id,
        identTrab: obra.descricao,
        entExec: entidadeExec.id,
        entExecText: entidadeExec.descricao,
        entSoli: entidadeSol.id,
        entSoliText: entidadeSol.descricao,
        descricao: workPermit.descricao,
        notas: workPermit.notas,
        prorrogacao: prorrogacao == "NO" ? false : true,
        n_wp: workPermit.n_wp,
        dataInicio: moment(acessoInicial).format("YYYY-MM-DD"),
        dataFim: moment(acessoFinal).format("YYYY-MM-DD"),
        horasInicio: dayjs(horasInicial).format("YYYY-MM-DD HH:mm:ss"),
        horasFim: dayjs(horasFinal).format("YYYY-MM-DD HH:mm:ss"),
        meio: meio,
        veiculos: workPermit.veiculos,
        localEnt: workPermit.localEnt,
        localEst: workPermit.localEst,
        matriculas: workPermit.matriculas,
        obs: workPermit.obs,
        responsavel: responsavel,
        responsavelAHD: responsavelAHD,
        contactos: contactos,
        contactosAHD: contactosAHD,
        enderecos: enderecos,
        assunto: workPermit.assunto,
        corpo: workPermit.corpo,
        emailsDest: emailsDest,
        emailsCC: emailsCC,
      },
    ];

    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!",
      type: "error",
    };
    const warningMessage = {
      isOpen: true,
      message:
        "Pedido work permit editado, mas com campos obrigatórios por preencher",
      type: "warning",
    };
    const message = {
      isOpen: true,
      message: "Pedido work permit editado com sucesso!!",
      type: "success",
    };

    console.log(ydata);

    if (verifyForm()) {
      dispatch(
        UpdateWorkPermit(
          selectedworkpermit.id_jaf_work_permit,
          ydata,
          percurso,
          planta,
          anexos,
          workPermitF
        )
      )
        .then(() => fetchObra(idObra))
        .then(() => fetchWorkPermit(idObra))
        .then(() => toggleDrawer("right", false))
        .then(() => dispatch(ShowNotification(message)))
        .then(() => resetFieldsToNull());
    } else {
      handleOpen(e, ydata);
    }
  };

  const fetchAllWK = () => {
    dispatch(GetAllWorkPermits());
  };

  const fetchAllMEIO = () => {
    dispatch(GetAllMEIO());
  };

  const fetchImageTypes = () => {
    dispatch(GetWPImageTypes());
  };

  const fetchAccountables = () => {
    dispatch(GetWorkPermitAccountables());
  };

  const fetchAccountablesAHD = () => {
    dispatch(GetWorkPermitAccountablesAHD());
  };

  // const fetchContacts = () => {
  //   dispatch(GetWorkPermitContacts());
  // };

  // const fetchContactsAHD = () => {
  //   dispatch(GetWorkPermitContactsAHD());
  // };

  const fetchDefaultAccountablesAHD = async (entity) => {
    dispatch(GetWorkPermitDefaultAccountablesAHD(entity));
  };

  const fetchSendContacts = () => {
    dispatch(GetWorkPermitsSendContacts());
  };

  const fetchEntities = () => {
    dispatch(GetWorkPermitEntities());
  };

  const fetchDefaultEmails = () => {
    dispatch(GetDefaultNotifyEmailsWP());
  };

  useEffect(() => {
    fetchAllWK();
    fetchAllMEIO();
    fetchImageTypes();
    fetchAccountables();
    fetchAccountablesAHD();
    // fetchContacts();
    // fetchContactsAHD();
    fetchEntities();
    fetchSendContacts();
    fetchDefaultEmails();
  }, []);

  // useEffect(() => {
  //   handleChangeDefaultResponsavelAHD();
  // }, [defaultAccountablesAHD]);

  useEffect(() => {
    let arrayMeio = [];
    arrayMeio = selectedworkpermit.meio && selectedworkpermit.meio.split(",");
    setWorkPermit({
      numeroWP: selectedworkpermit.jaf_work_permit_number,
      numSeqWP: selectedworkpermit.jaf_work_permit_number_sequential,
      identTrab: selectedworkpermit.jaf_work_permit_name,
      n_wp: selectedworkpermit.jaf_work_permit_extension_text,
      descricao: selectedworkpermit.jaf_work_permit_description,
      notas: selectedworkpermit.jaf_work_permit_notes,
      veiculos: selectedworkpermit.veiculos,
      localEnt: selectedworkpermit.localEnt,
      localEst: selectedworkpermit.localEst,
      matriculas: selectedworkpermit.matriculas,
      assunto: selectedworkpermit.jaf_work_permit_email_title,
      corpo: selectedworkpermit.jaf_work_permit_body,
    });
    setEstadoWP({
      id: selectedworkpermit.id_jaf_work_permit_status
        ? selectedworkpermit.id_jaf_work_permit_status
        : null,
      descricao: selectedworkpermit.jaf_work_permit_status_text
        ? selectedworkpermit.jaf_work_permit_status_text
        : "-",
    });
    setEntidadeSol({
      id: selectedworkpermit.id_jaf_work_permit_entity
        ? selectedworkpermit.id_jaf_work_permit_entity
        : null,
      descricao: selectedworkpermit.jaf_work_permit_entity_name
        ? selectedworkpermit.jaf_work_permit_entity_name
        : "-",
    });
    setProrrogacao(
      selectedworkpermit.jaf_work_permit_extension == null ||
        selectedworkpermit.jaf_work_permit_extension == 0
        ? "NO"
        : "YES"
    );
    setAcessoInicial(selectedworkpermit.jaf_work_permit_date_start);
    setAcessoFinal(selectedworkpermit.jaf_work_permit_date_end);
    setHorasInicial(dayjs(selectedworkpermit.jaf_work_permit_hour_start));
    setHorasFinal(dayjs(selectedworkpermit.jaf_work_permit_hour_end));
    if (arrayMeio && arrayMeio.length > 0) {
      updateMeioState(arrayMeio);
    }
    if (selectedworkpermit.accountables_type_1) {
      setResponsavel(JSON.parse(selectedworkpermit.accountables_type_1));
    }
    if (selectedworkpermit.accountables_type_2) {
      setResponsavelAHD(JSON.parse(selectedworkpermit.accountables_type_2));
    }
    if (selectedworkpermit.accountables_type_3) {
      setEnderecos(JSON.parse(selectedworkpermit.accountables_type_3));
    }
    if (selectedworkpermit.accountables_type_1) {
      setContactos(JSON.parse(selectedworkpermit.accountables_type_1));
    }
    if (selectedworkpermit.accountables_type_2) {
      setContactosAHD(JSON.parse(selectedworkpermit.accountables_type_2));
    }
    if (selectedworkpermit.accountables_type_4) {
      setEmailsCC(JSON.parse(selectedworkpermit.accountables_type_4));
    }
    if (selectedworkpermit.accountables_type_5) {
      setEmailsDest(JSON.parse(selectedworkpermit.accountables_type_5));
    }
  }, [selectedworkpermit, setWorkPermit]);

  return (
    <div
      style={{
        width: "80vw",
        borderRadius: "10px 0px 0px 10px !important",
      }}
    >
      <ModalRascunhoWP
        openRascunho={openRascunho}
        handleClose={handleClose}
        fetchObra={fetchObra}
        handleSubmit={handleSubmit}
        dados={dados}
        loading={loading}
        setLoading={setLoading}
        toggleDrawer={toggleDrawer}
        ShowNotification={ShowNotification}
        modalState={modalState}
        idObra={idObra}
        percurso={percurso}
        planta={planta}
        anexos={anexos}
        workPermitF={workPermitF}
        fetchWorkPermit={fetchWorkPermit}
        resetFieldsToNull={resetFieldsToNull}
        selectedworkpermit={selectedworkpermit}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 5,
        }}
      >
        <Box
          onClick={() => toggleDrawer("right", false)}
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <div>
            <FeatherIcon
              icon="chevron-left"
              size={20}
              style={{ strokeWidth: "1.5" }}
            />
          </div>
          <div className="text-underline-close-drawer">Work Permits</div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={classes.btnGuardar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box>
      </Box>
      <Card
        sx={{
          m: 5,
          p: 5,
          boxShadow: "0px 10px 15px lightgrey",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ mx: 1, width: "30%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              {fileWP && fileWP.jaf_work_permit_file_type_name}
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Button
                sx={{
                  height: "40px",
                  width: "150px",
                  borderRadius: "5px",
                  mr: 2,
                }}
                onChange={(e) => handleWPChange(e, fileWP)}
                className={classes.btnUpload}
              >
                <label
                  htmlFor={
                    fileWP && fileWP.jaf_work_permit_file_type_name_input
                  }
                  className="custom-file-upload"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      cursor: "pointer",
                    }}
                  >
                    <p>Carregar</p>
                  </div>
                </label>
                <input
                  type="file"
                  name={fileWP && fileWP.jaf_work_permit_file_type_name_input}
                  id={fileWP && fileWP.jaf_work_permit_file_type_name_input}
                  hidden
                  accept=".pdf"
                />
              </Button>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {workPermitF && (
                  <div
                    style={{
                      marginRight: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        const fileName = workPermitF.file.name;
                        const fileExtension = fileName.slice(
                          (Math.max(0, fileName.lastIndexOf(".")) || Infinity) +
                            1
                        );

                        if (fileExtension == "pdf") {
                          const pdfFile = workPermitF.file;

                          // Create a Blob from the PDF file
                          const blob = new Blob([pdfFile], {
                            type: "application/pdf",
                          });

                          // Create a URL for the Blob
                          const blobUrl = URL.createObjectURL(blob);

                          // Now you can use `blobUrl` to open or display the PDF
                          window.open(blobUrl, "_blank");
                        } else {
                          const blobUrl = URL.createObjectURL(workPermitF.file);

                          // Now you can use `blobUrl` to open or display the PDF
                          window.open(blobUrl, "_blank");
                        }
                      }}
                    >
                      <FeatherIcon icon="eye" size={"1rem"} />
                    </IconButton>
                    <IconButton onClick={(e) => handleRemoveWP()}>
                      <FeatherIcon icon="trash-2" size={"1rem"} />
                    </IconButton>
                  </div>
                )}
              </div>
              {selectedworkpermit.fileLength > 0 && (
                <Button
                  sx={{
                    height: "40px",
                    borderRadius: "5px",
                    mr: 1,
                  }}
                  className={classes.btnUpload}
                  onClick={() => {
                    Instance()
                      .get(
                        `${BASE_URL}/construcoes/workpermits/workPermit/${selectedworkpermit.id_jaf_work_permit}/workPermit/download`,
                        {
                          responseType: "blob",
                        }
                      )
                      .then(({ data }) => {
                        const filename = "workPermit.pdf";
                        fileDownload(data, filename);
                      })
                      .catch((err) => {
                        console.log(err.response);
                        if (err.response) {
                          if (err.response === 403) {
                            // dispatch(ShowNotification(errorMessage));
                          }
                        }
                      });
                  }}
                >
                  <FeatherIcon icon="download" size={20} />
                </Button>
              )}
            </Box>
          </Box>
          <Box sx={{ mx: 1, width: "30%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Número Sequencial *
            </Typography>
            <TextField
              name="numSeqWP"
              size="small"
              variant="outlined"
              value={workPermit.numSeqWP}
              onChange={(e) => {
                setWorkPermit({
                  ...workPermit,
                  [e.target.name]: e.target.value,
                  assunto: `${e.target.value} - ${workPermit.identTrab}`,
                });
              }}
              style={{ width: "100%" }}
            />
          </Box>
          <Box sx={{ mx: 1, width: "30%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Número do WP
            </Typography>
            <TextField
              name="numeroWP"
              size="small"
              variant="outlined"
              value={workPermit.numeroWP}
              onChange={handleChange}
              style={{ width: "100%" }}
            />
          </Box>
          <Box sx={{ mx: 1, width: "30%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Estado
            </Typography>
            <Autocomplete
              fullWidth
              name="estadoWP"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              value={estadoWP}
              className="unidadesId-form"
              options={estadosWP || []}
              getOptionLabel={(option) => `${option.descricao}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setEstadoWP({ id: null, descricao: "" });
                  return;
                } else {
                  setEstadoWP(value);
                  return value;
                }
              }}
            />
          </Box>
        </Box>
        <Box sx={{ mx: 1, my: 3 }}>
          <h4
            style={{
              fontSize: "22px",
              width: "100%",
              marginBottom: "0px",
            }}
            className="text-underline"
          >
            Editar Pedido de Work Permit
          </h4>
        </Box>
        <Box sx={{ m: 1, display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Identificação dos trabalhos *
            </Typography>
            <TextField
              name="identTrab"
              size="small"
              variant="outlined"
              value={workPermit.identTrab}
              onChange={(e) => {
                setWorkPermit({
                  ...workPermit,
                  [e.target.name]: e.target.value,
                  assunto: `${workPermit.numSeqWP} - ${e.target.value}`,
                });
              }}
              style={{ width: "100%" }}
            />
          </Box>
          <Box sx={{ width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Entidade executante
            </Typography>
            <TextField
              disabled
              name="entExec"
              size="small"
              variant="outlined"
              value={entidadeExec.descricao}
              onChange={handleChange}
              style={{ width: "100%" }}
            />
          </Box>
        </Box>
        <Box sx={{ m: 1, display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Entidade a solicitar o WP *
            </Typography>
            <Autocomplete
              fullWidth
              name="entSoli"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              value={entidadeSol}
              className="unidadesId-form"
              options={entidades || []}
              getOptionLabel={(option) => `${option.descricao}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setEntidadeSol({ id: null, descricao: "" });
                  return;
                } else {
                  setEntidadeSol(value);
                  return value;
                }
              }}
            />
          </Box>
          <Box sx={{ width: "48%", display: "flex" }}>
            <Box sx={{ width: "15%" }}>
              <FormControl>
                <Typography style={{ fontWeight: "bold" }}>
                  Prorrogação
                </Typography>
                <RadioGroup
                  name="prorrogacao"
                  value={prorrogacao}
                  onChange={handleChangeProrrogacao}
                >
                  <FormControlLabel
                    value="NO"
                    control={<Radio />}
                    label="Não"
                  />
                  <FormControlLabel
                    value="YES"
                    control={<Radio />}
                    label="Sim"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              sx={{
                width: "85%",
                display: "flex",
                alignItems: "flex-end",
                visibility: prorrogacao == "NO" ? "hidden" : null,
              }}
            >
              <Box>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Qual?
                </Typography>
                <TextField
                  name="n_wp"
                  size="small"
                  variant="outlined"
                  value={workPermit.n_wp}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ m: 1 }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Descrição detalhada dos trabalhos a efetuar *
          </Typography>
          <TextField
            multiline
            rows={16}
            name="descricao"
            size="small"
            variant="outlined"
            value={workPermit.descricao}
            onChange={handleChange}
            style={{ width: "100%" }}
          />
        </Box>
        <Box sx={{ m: 1 }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Notas
          </Typography>
          <TextField
            multiline
            rows={4}
            name="notas"
            size="small"
            variant="outlined"
            value={workPermit.notas}
            onChange={handleChange}
            style={{ width: "100%" }}
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              m: 1,
              width: "50%",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={ptLocale}
            >
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Validade de acesso *
              </Typography>
              <Box sx={{ display: "flex" }}>
                <DatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  okLabel="ok"
                  cancelLabel="cancelar"
                  invalidDateMessage="Data Inválida"
                  value={acessoInicial}
                  onChange={handleAcessoInicialChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "dd/mm/aaaa",
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      sx={{ width: "200px" }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
                <Typography
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    mx: 2,
                  }}
                >
                  até
                </Typography>
                <DatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  okLabel="ok"
                  cancelLabel="cancelar"
                  invalidDateMessage="Data Inválida"
                  value={acessoFinal}
                  onChange={handleAcessoFinalChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "dd/mm/aaaa",
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      sx={{ width: "200px" }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Box>
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              m: 1,
              width: "50%",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Horário de acesso
              </Typography>
              <Box sx={{ display: "flex" }}>
                <TimePicker
                  format="hh:mm"
                  defaultValue
                  ampm={false}
                  value={horasInicial}
                  onChange={handleHorasInicialChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "hh:mm",
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      sx={{ width: "200px" }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
                <Typography
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    mx: 2,
                  }}
                >
                  até
                </Typography>
                <TimePicker
                  format="hh:mm"
                  ampm={false}
                  value={horasFinal}
                  onChange={handleHorasFinalChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "hh:mm",
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      sx={{ width: "200px" }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Box>
            </LocalizationProvider>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              m: 1,
              width: "50%",
              display: "flex",
            }}
          >
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                  maxHeight: "20px",
                  mb: 2,
                }}
              >
                {filePercurso && filePercurso.jaf_work_permit_file_type_name} *
                {uploadedFiles &&
                uploadedFiles.find(
                  (file) => file.id_jaf_work_permit_file_type == 1
                ) ? (
                  <Tooltip title="Este pedido já tem percurso de acesso">
                    <IconButton>
                      <FeatherIcon icon="info" color="black" size="18" />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Button
                  sx={{
                    height: "40px",
                    width: "150px",
                    borderRadius: "5px",
                    mr: 2,
                  }}
                  onChange={(e) => handlePercursoChange(e, filePercurso)}
                  className={classes.btnUpload}
                >
                  <label
                    htmlFor={
                      filePercurso &&
                      filePercurso.jaf_work_permit_file_type_name_input
                    }
                    className="custom-file-upload"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        cursor: "pointer",
                      }}
                    >
                      <p>Carregar</p>
                    </div>
                  </label>
                  <input
                    type="file"
                    name={
                      filePercurso &&
                      filePercurso.jaf_work_permit_file_type_name_input
                    }
                    id={
                      filePercurso &&
                      filePercurso.jaf_work_permit_file_type_name_input
                    }
                    hidden
                    accept="image/jpeg"
                  />
                </Button>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {percurso && (
                    <div
                      style={{
                        marginRight: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          const fileName = percurso.file.name;
                          const fileExtension = fileName.slice(
                            (Math.max(0, fileName.lastIndexOf(".")) ||
                              Infinity) + 1
                          );

                          if (fileExtension == "pdf") {
                            const pdfFile = percurso.file;

                            // Create a Blob from the PDF file
                            const blob = new Blob([pdfFile], {
                              type: "application/pdf",
                            });

                            // Create a URL for the Blob
                            const blobUrl = URL.createObjectURL(blob);

                            // Now you can use `blobUrl` to open or display the PDF
                            window.open(blobUrl, "_blank");
                          } else {
                            const blobUrl = URL.createObjectURL(percurso.file);

                            // Now you can use `blobUrl` to open or display the PDF
                            window.open(blobUrl, "_blank");
                          }
                        }}
                      >
                        <FeatherIcon icon="eye" size={"1rem"} />
                      </IconButton>
                      <IconButton onClick={(e) => handleRemovePercurso()}>
                        <FeatherIcon icon="trash-2" size={"1rem"} />
                      </IconButton>
                    </div>
                  )}
                </div>
              </Box>
            </Box>
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                  maxHeight: "20px",
                  mb: 2,
                }}
              >
                {filePlanta && filePlanta.jaf_work_permit_file_type_name} *
                {uploadedFiles &&
                uploadedFiles.find(
                  (file) => file.id_jaf_work_permit_file_type == 2
                ) ? (
                  <Tooltip title="Este pedido já tem planta ilustrativa">
                    <IconButton>
                      <FeatherIcon icon="info" color="black" size="16" />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Button
                  sx={{
                    height: "40px",
                    width: "150px",
                    borderRadius: "5px",
                    mr: 2,
                  }}
                  onChange={(e) => handlePlantaChange(e, filePlanta)}
                  className={classes.btnUpload}
                >
                  <label
                    htmlFor={
                      filePlanta &&
                      filePlanta.jaf_work_permit_file_type_name_input
                    }
                    className="custom-file-upload"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        cursor: "pointer",
                      }}
                    >
                      <p>Carregar</p>
                    </div>
                  </label>
                  <input
                    type="file"
                    name={
                      filePlanta &&
                      filePlanta.jaf_work_permit_file_type_name_input
                    }
                    id={
                      filePlanta &&
                      filePlanta.jaf_work_permit_file_type_name_input
                    }
                    hidden
                    accept="image/jpeg"
                  />
                </Button>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {planta && (
                    <div
                      style={{
                        marginRight: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          const fileName = planta.file.name;
                          const fileExtension = fileName.slice(
                            (Math.max(0, fileName.lastIndexOf(".")) ||
                              Infinity) + 1
                          );

                          if (fileExtension == "pdf") {
                            const pdfFile = planta.file;

                            // Create a Blob from the PDF file
                            const blob = new Blob([pdfFile], {
                              type: "application/pdf",
                            });

                            // Create a URL for the Blob
                            const blobUrl = URL.createObjectURL(blob);

                            // Now you can use `blobUrl` to open or display the PDF
                            window.open(blobUrl, "_blank");
                          } else {
                            const blobUrl = URL.createObjectURL(planta.file);

                            // Now you can use `blobUrl` to open or display the PDF
                            window.open(blobUrl, "_blank");
                          }
                        }}
                      >
                        <FeatherIcon icon="eye" size={"1rem"} />
                      </IconButton>
                      <IconButton onClick={(e) => handleRemovePlanta()}>
                        <FeatherIcon icon="trash-2" size={"1rem"} />
                      </IconButton>
                    </div>
                  )}
                </div>
              </Box>
            </Box>
          </Box>
          <Box sx={{ m: 1, width: "50%" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
                maxHeight: "20px",
                mb: 2,
              }}
            >
              {fileAnexo && fileAnexo.jaf_work_permit_file_type_name}
              {uploadedFiles &&
              uploadedFiles.find(
                (file) => file.id_jaf_work_permit_file_type == 4
              ) ? (
                <Tooltip title="Este pedido já tem anexos">
                  <IconButton>
                    <FeatherIcon icon="info" color="black" size="18" />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ width: "25%" }}>
                <Button
                  sx={{
                    height: "40px",
                    width: "150px",
                    borderRadius: "5px",
                    mr: 2,
                  }}
                  onChange={(e) => handleAnexosChange(e, fileAnexo)}
                  className={classes.btnUpload}
                >
                  <label
                    htmlFor={
                      fileAnexo &&
                      fileAnexo.jaf_work_permit_file_type_name_input
                    }
                    className="custom-file-upload"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        cursor: "pointer",
                      }}
                    >
                      <p>Carregar</p>
                    </div>
                  </label>
                  <input
                    multiple
                    type="file"
                    name={
                      fileAnexo &&
                      fileAnexo.jaf_work_permit_file_type_name_input
                    }
                    id={
                      fileAnexo &&
                      fileAnexo.jaf_work_permit_file_type_name_input
                    }
                    hidden
                    accept="image/jpeg, .pdf, .docx"
                  />
                </Button>
              </Box>
              <Box sx={{ width: "75%" }}>
                <div
                  style={{
                    marginRight: "10px",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {anexos &&
                    anexos.map((anexo, index) => (
                      <div key={index}>
                        <span>{anexo.file.name}</span>
                        <IconButton onClick={(e) => handleRemoveAnexos(index)}>
                          <FeatherIcon icon="trash-2" size={"1rem"} />
                        </IconButton>
                      </div>
                    ))}
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ m: 1, mt: 3 }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Meios Envolvidos / Implicações Operacionais
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "50%" }}>
            {renderCheckboxes(1, 7)}
            <Box sx={{ m: 1, mt: 3 }}>
              <Typography style={{ fontWeight: "bold" }}>
                Outras Implicações
              </Typography>
            </Box>
            {renderCheckboxes(8, 13)}
          </Box>
          <Box sx={{ width: "50%" }}>{renderMeioText(14, 17)}</Box>
        </Box>

        <Box sx={{ m: 1 }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Observações
          </Typography>
          <TextField
            multiline
            rows={2}
            name="obs"
            size="small"
            variant="outlined"
            value={workPermit.obs}
            onChange={handleChange}
            style={{ width: "100%" }}
          />
        </Box>
        <Box sx={{ m: 1, display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ width: "46.5%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Responsável JAF *
            </Typography>
            <Autocomplete
              multiple
              disableCloseOnSelect
              fullWidth
              name="responsavel"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              value={responsavel}
              className="unidadesId-form"
              options={accountables || []}
              getOptionLabel={(option) => (
                <>
                  {option.descricao}
                  <br />
                  {option.email}
                </>
              )}
              filterOptions={filterOptions}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setResponsavel([]);
                  setContactos([]);
                  // Assuming you want to clear the emails related to responsavel but keep others
                  const newEmailsCC = emailsCC.filter(
                    (email) => !responsavel.some((res) => res.id === email.id)
                  );
                  setEmailsCC(newEmailsCC);
                } else {
                  setResponsavel(value);
                  setContactos(value);
                  const newEmailsCC = [...emailsCC];

                  // Add new values from `value` that are not in `emailsCC`
                  value.forEach((val) => {
                    if (!emailsCC.some((email) => email.id === val.id)) {
                      newEmailsCC.push(val);
                    }
                  });

                  // Remove values from `emailsCC` that are in `responsavel` but not in the new `value`
                  responsavel.forEach((res) => {
                    if (!value.some((val) => val.id === res.id)) {
                      const index = newEmailsCC.findIndex(
                        (email) => email.id === res.id
                      );
                      if (index !== -1) {
                        newEmailsCC.splice(index, 1);
                      }
                    }
                  });

                  setEmailsCC(newEmailsCC);
                }
              }}
            />
          </Box>
          <Box sx={{ width: "46.5%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Responsável JAF - Contactos
            </Typography>
            <Autocomplete
              disabled
              freeSolo
              multiple
              disableCloseOnSelect
              fullWidth
              name="contactos"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              value={contactos}
              className="unidadesId-form"
              getOptionLabel={(option) => (
                <>
                  {option.descricao}
                  <br />
                  {option.contact}
                </>
              )}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setContactos([]);
                  return;
                } else {
                  setContactos(value);
                  return value;
                }
              }}
            />
          </Box>
        </Box>
        <Box sx={{ m: 1, display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ width: "46.5%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Responsável AHD *
            </Typography>
            <Autocomplete
              multiple
              disableCloseOnSelect
              fullWidth
              name="responsavelAHD"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              value={responsavelAHD}
              className="unidadesId-form"
              options={accountablesAHD || []}
              getOptionLabel={(option) => (
                <>
                  {option.descricao}
                  <br />
                  {option.email}
                </>
              )}
              filterOptions={filterOptions}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setResponsavelAHD([]);
                  setContactosAHD([]);
                  // Assuming you want to clear the emails related to responsavel but keep others
                  const newEmailsDest = emailsDest.filter(
                    (email) =>
                      !responsavelAHD.some((res) => res.id === email.id)
                  );
                  setEmailsDest(newEmailsDest);
                } else {
                  setResponsavelAHD(value);
                  setContactosAHD(value);
                  const newEmailsDest = [...emailsDest];

                  // Add new values from `value` that are not in `emailsCC`
                  value.forEach((val) => {
                    if (!emailsDest.some((email) => email.id === val.id)) {
                      newEmailsDest.push(val);
                    }
                  });

                  // Remove values from `emailsCC` that are in `responsavel` but not in the new `value`
                  responsavelAHD.forEach((res) => {
                    if (!value.some((val) => val.id === res.id)) {
                      const index = newEmailsDest.findIndex(
                        (email) => email.id === res.id
                      );
                      if (index !== -1) {
                        newEmailsDest.splice(index, 1);
                      }
                    }
                  });

                  setEmailsDest(newEmailsDest);
                }
              }}
            />
          </Box>
          <Box sx={{ width: "46.5%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Responsável AHD - Contactos
            </Typography>
            <Autocomplete
              disabled
              freeSolo
              multiple
              disableCloseOnSelect
              fullWidth
              name="contactosAHD"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              value={contactosAHD}
              className="unidadesId-form"
              options={contactsAHDList || []}
              getOptionLabel={(option) => (
                <>
                  {option.descricao}
                  <br />
                  {option.contact}
                </>
              )}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setContactosAHD([]);
                  return;
                } else {
                  setContactosAHD(value);
                  return value;
                }
              }}
            />
          </Box>
        </Box>
        <Box sx={{ m: 1 }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Endereços a incluir na emissão do WP *
          </Typography>
          <Autocomplete
            multiple
            disableCloseOnSelect
            fullWidth
            name="enderecos"
            clearText="Limpar"
            openText="Abrir"
            closeText="Fechar"
            noOptionsText="Sem dados"
            value={enderecos}
            className="unidadesId-form"
            options={emails || []}
            getOptionLabel={(option) => (
              <>
                {option.descricao}
                <br />
                {option.email}
              </>
            )}
            filterOptions={filterOptions}
            isOptionEqualToValue={(option, value) => {
              // if (!value.id) return true;
              return value.id === option.id;
            }}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} size="small" variant="outlined" />
            )}
            onChange={(_, value, reason) => {
              if (reason === "clear") {
                setEnderecos([]);
                return;
              } else {
                setEnderecos(value);
                return value;
              }
            }}
          />
        </Box>
        <Box sx={{ m: 1 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Emails
          </h4>
        </Box>
        <Box sx={{ m: 1 }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Destinatários *
          </Typography>
          <Autocomplete
            multiple
            disableCloseOnSelect
            fullWidth
            name="emailsDest"
            clearText="Limpar"
            openText="Abrir"
            closeText="Fechar"
            noOptionsText="Sem dados"
            value={emailsDest}
            className="unidadesId-form"
            options={emailsdefAHD || []}
            getOptionLabel={(option) => (
              <>
                {option.descricao}
                <br />
                {option.email}
              </>
            )}
            filterOptions={filterOptions}
            isOptionEqualToValue={(option, value) => {
              // if (!value.id) return true;
              return value.id === option.id;
            }}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} size="small" variant="outlined" />
            )}
            onChange={(_, value, reason) => {
              if (reason === "clear") {
                setEmailsDest([]);
                return;
              } else {
                setEmailsDest(value);
                return value;
              }
            }}
          />
        </Box>
        <Box sx={{ m: 1 }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            CC *
          </Typography>
          <Autocomplete
            multiple
            disableCloseOnSelect
            fullWidth
            name="emailsCC"
            clearText="Limpar"
            openText="Abrir"
            closeText="Fechar"
            noOptionsText="Sem dados"
            value={emailsCC}
            className="unidadesId-form"
            options={emailsdefJAF || []}
            getOptionLabel={(option) => (
              <>
                {option.descricao}
                <br />
                {option.email}
              </>
            )}
            filterOptions={filterOptions}
            isOptionEqualToValue={(option, value) => {
              // if (!value.id) return true;
              return value.id === option.id;
            }}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} size="small" variant="outlined" />
            )}
            onChange={(_, value, reason) => {
              if (reason === "clear") {
                setEmailsCC([]);
                return;
              } else {
                setEmailsCC(value);
                return value;
              }
            }}
          />
        </Box>

        <Box sx={{ m: 1 }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Assunto do email *
          </Typography>
          <TextField
            multiline
            rows={2}
            name="assunto"
            value={workPermit.assunto}
            size="small"
            variant="outlined"
            onChange={handleChange}
            style={{ width: "100%" }}
          />
        </Box>
        <Box sx={{ m: 1 }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Corpo do email *
          </Typography>
          <TextField
            multiline
            rows={16}
            name="corpo"
            value={workPermit.corpo}
            size="small"
            variant="outlined"
            onChange={handleChange}
            style={{ width: "100%" }}
          />
        </Box>
      </Card>
    </div>
  );
};

export default FormEditarWorkPermit;
