/* eslint-disable eqeqeq */
import Instance from "../../../Axios";

const GetIntervencoes =
  (situacaoPreSelectedId, ano, estado, subEstado, perfil) =>
  async (dispatch) => {
    const sitRespondida = situacaoPreSelectedId[0].estado === true ? 1 : 0;
    const sitNaoRespondida = situacaoPreSelectedId[1].estado === true ? 1 : 0;
    const sitAguardaVisita = situacaoPreSelectedId[2].estado === true ? 1 : 0;
    const estadoId = estado.id;
    const subEstadoId = subEstado.id;
    const perfilId = perfil == 3 ? perfil : null;

    console.log(estado);

    await Instance()
      .get(`/intervencoes/intervencoes`, {
        params: {
          sitRespondida,
          sitNaoRespondida,
          sitAguardaVisita,
          ano,
          estadoId,
          subEstadoId,
          perfilId,
        },
      })
      .then(({ data }) => {
        //console.log(data);
        dispatch({ type: "GET_INTERVENCOES", payload: data.yData });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default GetIntervencoes;
