import Instance from "../../../../Axios";

// New Actions for the app

const DeleteCredFuncionario = (id) => async (dispatch) => {
  await Instance()
    .put(`/credenciacoes/funcionarios/delete/${id}`)
    .then(({ data }) => {
      //   console.log(data);
      dispatch({ type: "DELETE_CRED_FUNCIONARIO", payload: data.yData[0] });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default DeleteCredFuncionario;