/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, styled } from "@mui/styles";
import {
  Typography,
  Box,
  Card,
  ListItem,
  TextField,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import "moment/locale/pt";
import ptLocale from "date-fns/locale/pt";
import { startOfYear } from "date-fns";
import pt from "apexcharts/dist/locales/pt.json";
import ReactPDF, { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactApexChart from "react-apexcharts";
import logo from "../../../../assets/images/grupoJaf_logo.png";

// Components
import FooterLinks from "../../../../Global/Components/Footer";
import FilterDrawer from "./components/filterDrawer";
import MyDocument from "./components/PDFFile";

//Actions
import GetIntervencoesTimeline from "../../../../utils/redux/actions/intervencoes/GetIntervencoesTimeline";
import GetDepartamentos from "../../../../utils/redux/actions/utilizadores/GetDepartamentosAction";
import GetAeroportosTimeline from "../../../../utils/redux/actions/intervencoes/GetAeroportosTimeline";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "900",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  stateLabel: {
    textTransform: "none",
    transition: "0.2s",
    display: "flex",
    alignItems: "center",
    maxWidth: "100px",
  },

  paperFilters: {
    background: "transparent",
    padding: "20px 0px",
    boxShadow: "none",
  },
}));

// ############################################################## //
// ####################  Window Dimensions  ##################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const IntervencoesTimelineParceiro = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const timeline = useSelector(
    (state) => state.intervencoes.intervencoesTimeline
  );

  // ############################################################## //
  // #########################  User Aero  ######################## //
  // ############################################################## //

  const userAeroporto = useSelector(
    (state) => state.user.aeroportoFilter.id
  );
  
  const userDepartamento = useSelector(
    (state) => state.user.departamentoFilter.id
  );

  // ############################################################## //
  // #######################  Drawer Width  ####################### //
  // ############################################################## //
  
  const [drawerWidth, setDrawerWidth] = useState("25vw");
  const dimensions = useWindowDimensions();
  const [state, setState] = useState({
    right: false,
  });
  const [drawerState, setDrawerState] = useState(null);
  const toggleDrawer = (anchor, open) => {
    if (open == false) {
      setState({ ...state, [anchor]: open });
    }
    if (open == true) {
      setState({ ...state, [anchor]: open });
    }
  };

  // FILTERS
  const situacoes = useSelector((state) => state.intervencoes.timelineSit);

  let situacoesPreSelectedId = useSelector(
    (state) => state.intervencoes.timelineSitPreSelected
  );

  const dataInicial = useSelector(
    (state) => state.intervencoes.timelineDataInicio
  );
  const dataFinal = useSelector((state) => state.intervencoes.timelineDataFim);

  const [dataInicio, setDataInicio] = useState(dataInicial);
  const [dataFim, setDataFim] = useState(dataFinal);

  const aeroportos = useSelector(
    (state) => state.intervencoes.aeroportosTimeline
  );
  const aeroporto = useSelector(
    (state) => state.intervencoes.aeroportoSelected
  );

  const [aeroportoId, setAeroportoId] = useState(userAeroporto);

  const departamentos = useSelector(
    (state) => state.utilizadores.departamentos
  );
  const departamento = useSelector(
    (state) => state.intervencoes.timelineDepartamentoSelected
  );
  const [departamentoId, setDepartamentoId] = useState(userDepartamento);

  const fetchDepartamentos = (aeroportoId) => {
    dispatch(GetDepartamentos(aeroportoId));
  };

  useEffect(() => fetchDepartamentos(aeroportoId), [aeroportoId]);

  const fetchAeroportos = () => {
    dispatch(GetAeroportosTimeline());
  };

  useEffect(() => fetchAeroportos(), []);

  const fetchIntervencoesTimeline = (
    dataInicial,
    dataFinal,
    aeroportoId,
    departamentoId,
    situacoesId
  ) => {
    dispatch(
      GetIntervencoesTimeline(
        dataInicial,
        dataFinal,
        aeroportoId,
        departamentoId,
        situacoesId
      )
    );
  };

  useEffect(() => {
    const situacoesId = situacoesPreSelectedId.map((sit) => sit.id);
    fetchIntervencoesTimeline(
      dataInicio,
      dataFim,
      aeroportoId,
      departamentoId,
      situacoesId
    );
  }, []);

  timeline.map((item) => {
    item.data_inicial = moment(item.data_inicial).format("YYYY-MM-DD");
    item.data_final = moment(item.data_final).format("YYYY-MM-DD");
  });

  const [timelineState, setTimelineState] = useState({
    series: [],
    options: {
      chart: {
        id: "basic-bar",
        type: "rangeBar",
        locales: [pt],
        defaultLocale: "pt",
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      xaxis: {
        type: "datetime",
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        labels: {
          maxWidth: 400,
        },
      },
    },
  });

  const [height, setHeight] = useState(650);

  useEffect(() => {
    setTimelineState({
      ...timelineState,
      series: [
        {
          data: timeline.map((item) => {
            return {
              x: item.descricao,
              y: [
                new Date(item.data_inicial).getTime(),
                new Date(item.data_final).getTime(),
              ],
            };
          }),
        },
      ],
    });

    let newheight = timeline && timeline.length * 40;
    setHeight(newheight);
  }, [timeline]);

  //PDF
  const getDataUri = () => {
    ApexCharts.exec("basic-bar", "dataURI").then(({ imgURI }) => {
      const canvas = document.getElementById("gantt");

      const doc = new jsPDF("l", "pt", "a4");

      let imgWidth = 820;
      let pageHeight = 530;
      let imgHeight = (canvas.offsetHeight * imgWidth) / canvas.offsetWidth;
      let heightLeft = imgHeight;

      var position = 0;

      // HEADER
      const headerContent = () => {
        // Logotipo
        doc.addImage(logo, "PNG", 10, 10, 120, 40);
        const data = moment().format("DD-MM-YYYY");

        // Titulo do documento
        doc.setFontSize(9);

        doc
          .text("Timeline ", 430, 60, { align: "center" })
          .setFont("Lato", "normal");

        // Data
        doc.text(710, 25, `Data de Impressão:`).setFont("Lato", "normal");
        doc.text(780, 25, `${data}`).setFont("Lato", "normal");
      };

      headerContent();

      //CORPO DOCUMENTO PDF
      doc.addImage(imgURI, "JPEG", 5, 62, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position += heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgURI, "JPEG", 5, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        headerContent();
      }

      // FOOTER (Paginação)
      const totalPag = doc.internal.getNumberOfPages();

      for (let i = 0; i < totalPag; i++) {
        doc.setPage(i);

        let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;

        doc.setFontSize(8);
        doc.text(
          "Página " + pageCurrent + " de " + totalPag,
          430,
          doc.internal.pageSize.height - 10,
          { align: "center" }
        );
      }

      doc.save("file.pdf");

      // pdf.addImage(logo, 'PNG', 10, 10, 120, 40);
      // pdf.addImage(imgURI, 'PNG ', 5, 60, 820, 700);
      // pdf.save("pdf-chart.pdf");
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <FilterDrawer
          state={state}
          toggleDrawer={toggleDrawer}
          drawerState={drawerState}
          drawerWidth={drawerWidth}
          dimensions={dimensions}
          fetchIntervencoesTimeline={fetchIntervencoesTimeline}
          dataInicio={dataInicio}
          dataFim={dataFim}
          setDataInicio={setDataInicio}
          setDataFim={setDataFim}
          aeroporto={aeroporto}
          aeroportos={aeroportos}
          aeroportoId={aeroportoId}
          setAeroportoId={setAeroportoId}
          departamento={departamento}
          departamentos={departamentos}
          departamentoId={departamentoId}
          setDepartamentoId={setDepartamentoId}
          situacoes={situacoes}
          situacoesPreSelectedId={situacoesPreSelectedId}
        />
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mx: 5,
              mt: 3,
            }}
          >
            <Box>
              <h4
                style={{
                  fontSize: "22px",
                  width: "100%",
                }}
                className="text-underline"
              >
                Timeline
              </h4>
            </Box>
            <Box>
              <Button
                variant="contained"
                className={classes.btnFiltrar}
                onClick={() => toggleDrawer("right", true)}
              >
                Filtros
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mx: 5,
              mb: 3,
            }}
          >
            <Button
              variant="contained"
              className={classes.btnFiltrar}
              onClick={getDataUri}
            >
              Download
            </Button>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mx: 5 }}>
            <Card
              sx={{
                mb: 5,
                p: 2,
                boxShadow: "0px 5px 15px lightgrey",
                width: "100%",
              }}
            >
              <Box
                sx={{ display: "flex", justifyContent: "center" }}
                id="gantt"
              >
                <ReactApexChart
                  options={timelineState.options}
                  series={timelineState.series}
                  type="rangeBar"
                  height={height}
                  width={1725}
                />
              </Box>
            </Card>
          </Box>
          <div style={{ margin: "20px 40px" }}>
            <FooterLinks />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default IntervencoesTimelineParceiro;
