import Instance from "../../../../Axios";

// New Actions for the app

const GetCredDownloadFiles = (credId, tipoId) => async (dispatch) => {
  await Instance()
    .get(`/credenciacoes/cred/${credId}/${tipoId}/download/files`)
    .then(({ data }) => {
      dispatch({
        type: "GET_CRED_DOWNLOAD_FILES",
        payload: data.yData,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetCredDownloadFiles;
