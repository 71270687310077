import React from 'react';
import { TextField } from '@mui/material';

const Materiais = ({ obraSelecionada }) => {
  return (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '5px',
            background: '#F1F1F1',
            border: '1px solid #707070',
            borderRadius: '8px',
          }}
        >
          <h4
            style={{
              width: '13%',
              margin: '10px 0px 10px 10px',
              padding: '0px',
            }}
          >
            Materiais
          </h4>

          <h4
            style={{
              width: '15.5%',
              margin: '10px 2% 10px 0px',
              padding: '0px',
            }}
          >
            Estado
          </h4>

          <h4
            style={{
              width: '10.5%',
              margin: '10px 2% 10px 0px',
              padding: '0px',
            }}
          >
            Quantidade
          </h4>
          <h4
            style={{
              width: '21.7%',
              margin: '10px 0px 10px 0px',
              padding: '0px',
            }}
          >
            Observações
          </h4>
        </div>

        {obraSelecionada &&
          obraSelecionada.materiais &&
          obraSelecionada.materiais.map((material, index) => (
            <div
              key={index}
              style={{
                background: 'white',
                minHeight: '40px',
                marginTop: '10px',
                marginBottom: '10px',
                border: '1px solid #707070',
                borderRadius: '8px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  padding: '5px',
                }}
              >
                <p
                  style={{
                    height: '30',
                    width: '15%',
                    padding: '0px',
                    margin: '0px 0px 0px 10px',
                  }}
                >
                  {material.materiaisDescricao}
                </p>
                <form
                  style={{ width: '100%', display: 'flex' }}
                  autocomple='off'
                >
                  <TextField
                    size='small'
                    value={material.estadosDescricao || ' '}
                    disabled
                    style={{
                      width: '18%',
                      marginRight: '2.5%',
                    }}
                    variant='standard'
                    placeholder='Selecione uma opção'
                    InputProps={{ disableUnderline: true }}
                  />

                  <TextField
                    size='small'
                    value={material.qtd || ' '}
                    disabled
                    style={{
                      width: '12%',
                      marginRight: '2.5%',
                    }}
                    variant='standard'
                    placeholder='Selecione uma opção'
                    InputProps={{ disableUnderline: true }}
                  />

                  <TextField
                    size='small'
                    value={material.observacoes || ' '}
                    disabled
                    style={{
                      width: '65.5%',
                      // marginRight: '2.5%',
                    }}
                    variant='standard'
                    placeholder='Selecione uma opção'
                    InputProps={{ disableUnderline: true }}
                  />
                </form>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default Materiais;
