import React from 'react';
import { TextField, Typography } from '@mui/material';

const Detalhes = ({ intervencaoSelecionada, obraId, handleObraId }) => {
  return (
    <>
      <div>
        <h4
          style={{ color: '#E21450' }}
          className='text-underline-title-drawer '
        >
          Detalhes
        </h4>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold' }}>Id</Typography>
            <TextField
              size='small'
              value={
                (intervencaoSelecionada && intervencaoSelecionada.id) || ' - '
              }
              variant='outlined'
              disabled
              style={{ width: '30%', marginBottom: '16px' }}
            />

            <Typography style={{ fontWeight: 'bold' }}>
              Nome do Pedido
            </Typography>

            <TextField
              size='small'
              value={
                (intervencaoSelecionada && intervencaoSelecionada.pedidoNome) ||
                ' - '
              }
              multiline
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <div style={{ width: '100%', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold' }}>Responsável</Typography>
            <TextField
              size='small'
              value={
                (intervencaoSelecionada &&
                  intervencaoSelecionada.funcionarioNome) ||
                ' - '
              }
              multiline
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ width: '100%', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold' }}>Descrição</Typography>
            <TextField
              size='small'
              value={
                (intervencaoSelecionada &&
                  intervencaoSelecionada.pedidoDescricao) ||
                ' - '
              }
              multiline
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ width: '100%', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold' }}>Id Obra</Typography>

            <TextField
              value={obraId || ''}
              // onChange={handleObraId}
              disabled
              size='small'
              sx={{ width: '60%' }}
            ></TextField>
          </div>
          <div style={{ width: '100%', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold' }}>Obra</Typography>

            <TextField
              size='small'
              value={
                (intervencaoSelecionada &&
                  intervencaoSelecionada.obraDescricao) ||
                ' - '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <div style={{ width: '100%', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold' }}>
              Valor da Proposta
            </Typography>
            <TextField
              size='small'
              value={
                intervencaoSelecionada.propostaValor
                  ? intervencaoSelecionada.propostaValor.toLocaleString('PT') +
                    ' €'
                  : ' - '
              }
              variant='outlined'
              disabled
              style={{ width: '85%' }}
            />
          </div>
        </div>

        <div style={{ display: 'flex' }}></div>
      </div>
    </>
  );
};

export default Detalhes;
