/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect } from "react";

// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ptLocale from "date-fns/locale/pt";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment from "moment";
import advancedFormat from "dayjs/plugin/advancedFormat";
import ModalRascunho from "./modalRascunho";
import MultipleDatesPicker from "@ambiot/material-ui-multiple-dates-picker";
import Instance from "../../../../../utils/Axios";
import BASE_URL from "../../../../../utils/BASE_URL";
import fileDownload from "js-file-download";
import MUIDataTable from "mui-datatables";

//ACTIONS
import AddCredenciacao from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/AddCredenciacaoAction";
import GetCredFuncionarios from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionariosAction";
import GetCredFuncionarioById from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionarioByIdAction";
import GetCredAeroportosByFuncId from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredAeroportosByFuncIdAction";
import ShowNotification from "../../../../../utils/redux/actions/global/notifications/NotificationAction";
import GetCredVeiculosById from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredVeiculosByIdAction";
import GetCredAcompanhanteById from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredAcompanhanteByIdAction";
import GetCredAeroportos from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredAeroportosAction";
import GetCredPedidosAdicionaisDocs from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredPedidosAdicionaisDocsAction";
import GetCredViaturaFicheiros from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredViaturaFicheirosAction";
import GetCredViaturaFicheirosPermanente from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredViaturaFicheirosPermanenteAction";
import GetCredAcompanhantesDisponibilidade from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredAcompanhantesDisponibilidadeAction";
import NotifyCredenciacoes from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/NotifyCredenciacoesActions";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnUpload: {
    color: "#E21450",
    // width: '125px',
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  blurredText: {
    color: "transparent", // Make the text transparent
    textShadow: "0 0 10px #000", // Apply a text shadow to create the blur effect
    userSelect: "none", // Prevent text selection
  },
  disabledTextField: {
    opacity: 0.7,
    pointerEvents: "none",
    userSelect: "none", // Adjust opacity to indicate the disabled state
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "transparent",
    },
  },
  textField: {
    userSelect: "none",
  },
});

const FormAdd = ({
  toggleDrawer,
  drawerWidth,
  dimensions,
  credenciacao,
  setCredenciacao,
  fetchCredenciacoes,
  funcoes,
  tipoCartoes,
  tipoVinculos,
  cores,
  funcionarios,
  acompanhantes,
  motivos,
  estados,
  empresas,
  habilitacoes,
  tipoDocs,
  copCred,
  portariaAcesso,
  veiculos,
  obrasState,
  destinatariosOptions,
  globalFetch,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  dayjs.extend(advancedFormat);
  const today = new Date();

  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");

  const user = useSelector((state) => state.user.perfilId);
  const permissoes = useSelector((state) => state.user.permissoes);
  const superuser = permissoes.includes("APP_CRED_SUPER_USER");

  const acompanhantesDisponibilidade = useSelector(
    (state) => state.credenciais.acompanhantesDisponibilidade
  );

  const filteredAcompanhantes = acompanhantes.filter((acompanhante) => {
    // Check if there's no record in acompanhados array with acompanhados >= 6 for the current acompanhante
    return !acompanhantesDisponibilidade.some(
      (a) => a.id === acompanhante.id && a.acompanhados >= 6
    );
  });

  const acompanhanteSelecionado = useSelector(
    (state) => state.credenciais.acompanhante
  );

  const credAdicionada = useSelector(
    (state) => state.credenciais.credenciacaoAdicionada
  );

  function checkConsecutiveDates(aero, selectedDates) {
    // Define the max consecutive days allowed based on the consecutiveLimit value
    const maxConsecutiveDays = aero === 1313 ? 3 : 5;

    // Ensure dates are sorted
    const sortedDates = selectedDates.sort((a, b) => new Date(a) - new Date(b));

    let consecutiveCount = 1; // Start counting from 1 because we have at least one date

    // Iterate through the dates and check for consecutive days
    for (let i = 1; i < sortedDates.length; i++) {
      const previousDate = new Date(sortedDates[i - 1]);
      const currentDate = new Date(sortedDates[i]);

      // Calculate the difference in days
      const diffInDays = (currentDate - previousDate) / (1000 * 60 * 60 * 24);

      if (diffInDays === 1) {
        // If consecutive, increment the counter
        consecutiveCount++;
        if (consecutiveCount > maxConsecutiveDays) {
          // If more than 3 consecutive days are found, return false
          return false;
        }
      } else {
        // Reset the counter if dates are not consecutive
        consecutiveCount = 1;
      }
    }

    // If no more than 3 consecutive days were found, return true
    return true;
  }

  // const ficheirosViaturasState = useSelector(
  //   (state) => state.credenciais.ficheirosViaturas
  // );

  // const ficheirosViaturasPermanentesState = useSelector(
  //   (state) => state.credenciais.ficheirosViaturasPermanente
  // );

  // const ficheirosAdicionaisState = useSelector(
  //   (state) => state.credenciais.pedidosAdicionaisDocs
  // );

  // ############################################################## //
  // #########################  Collapse ########################## //
  // ############################################################## //

  const [open, setOpen] = useState(false);

  // ############################################################## //
  // ###################  Tipos Credenciações  #################### //
  // ############################################################## //

  const [tipoCredSel, setTipoCredSel] = useState({ id: null, descricao: "" });

  const tiposCreds = [
    {
      id: 1,
      descricao: "Permanente Funcionário",
      permissao: "APP_CRED_CREDENCIACOES_PERM_FUNCIONARIO",
    },
    {
      id: 4,
      descricao: "Permanente Viatura",
      permissao: "APP_CRED_CREDENCIACOES_PERM_VIATURA",
    },
    {
      id: 2,
      descricao: "Pontual Funcionário",
      permissao: "APP_CRED_CREDENCIACOES_PONT_FUNCIONARIO",
    },
    {
      id: 3,
      descricao: "Pontual Visitante",
      permissao: "APP_CRED_CREDENCIACOES_PONT_VISITANTE",
    },
    {
      id: 5,
      descricao: "Pontual Viatura",
      permissao: "APP_CRED_CREDENCIACOES_PONT_VIATURA",
    },
  ];

  const filteredTiposCreds = tiposCreds.filter((cred) =>
    permissoes.includes(cred.permissao)
  );

  // ############################################################## //
  // #####################  Autocompletes  ######################## //
  // ############################################################## //

  const [funcionario, setFuncionario] = useState({ id: null, nome: "" });
  const [veiculo, setVeiculo] = useState({ id: null, descricao: "" });
  const [funcionarioSel, setFuncionarioSel] = useState({});
  const [viaturaSel, setViaturaSel] = useState({});
  const [estado, setEstado] = useState({ id: null, descricao: "" });
  const [projeto, setProjeto] = useState({ id: null, descricao: "" });
  const [aeroporto, setAeroporto] = useState({
    id: null,
    descricao: "",
  });
  const [funcao, setFuncao] = useState({
    id: null,
    descricao: "",
  });
  const [acompanhante, setAcompanhante] = useState({
    id: null,
    nome: "",
    aeroporto: "",
    numero: "",
  });
  const [motivo, setMotivo] = useState({
    id: null,
    descricao: "",
  });
  const [tipoVinculo, setTipoVinculo] = useState({
    id: null,
    descricao: "",
  });
  const [portAcesso, setPortAcesso] = useState({
    id: null,
    descricao: "",
  });
  const [cor, setCor] = useState({
    id: null,
    descricao: "",
  });
  const [empresa, setEmpresa] = useState({
    id: null,
    descricao: "",
  });
  const [habilitacao, setHabilitacao] = useState({
    id: null,
    descricao: "",
  });
  const [tipoDoc, setTipoDoc] = useState({
    id: null,
    descricao: "",
  });
  const [tipoCartao, setTipoCartao] = useState({
    id: null,
    descricao: "",
  });
  const aeroportos = useSelector(
    (state) => state.credenciais.aeroportosByFuncId
  );
  const aeroportosViatura = useSelector(
    (state) => state.credenciais.aeroportos
  );
  const uploadedFiles = useSelector(
    (state) => state.credenciais.copiaCredenciacaoUploaded
  );
  const uploadedCopia =
    uploadedFiles.find(
      (copia) => copia.id_crede_elementos_files_tipos == "8"
    ) || null;

  const [destinatarios, setDestinatarios] = useState([]);

  // ############################################################## //
  // ######################  Date Changes  ######################## //
  // ############################################################## //

  const [dataNascimento, setDataNascimento] = useState(null);
  const [dataValidade, setDataValidade] = useState(null);
  const [dataEmissao, setDataEmissao] = useState(null);
  const [dataValidadeApolice, setDataValidadeApolice] = useState(null);
  const [dataInspecao, setDataInspecao] = useState(null);
  const [dataProxInspecao, setDataProxInspecao] = useState(null);

  const [openDates, setOpenDates] = useState(false);

  const [datasSelected, setDatasSelected] = useState([]);

  const handleDatesChange = (dates) => {
    setDatasSelected(dates);
  };

  const handleDataNascimentoChange = (date) => {
    setDataNascimento(date);
  };

  const handleDataValidadeChange = (date) => {
    setDataValidade(date);
  };

  const handleDataEmissaoChange = (date) => {
    setDataEmissao(date);
  };

  const handleDataValidadeApoliceChange = (date) => {
    setDataValidadeApolice(date);
  };

  const handleDataInspecaoChange = (date) => {
    setDataInspecao(date);
  };

  const handleDataProxInspecaoChange = (date) => {
    setDataProxInspecao(date);
  };

  // ############################################################## //
  // #################  Dates and Times changes  ################## //
  // ############################################################## //

  const [maxDate, setMaxDate] = useState();
  const [acessoInicial, setAcessoInicial] = useState(null);
  const [acessoFinal, setAcessoFinal] = useState(null);
  const [horasInicial, setHorasInicial] = useState(dayjs().startOf("day"));
  const [horasFinal, setHorasFinal] = useState(dayjs().startOf("day"));
  const [vinculoInicial, setVinculoInicial] = useState(null);
  const [vinculoFinal, setVinculoFinal] = useState(null);

  const handleAcessoInicialChange = (date) => {
    if (maxState === 1) {
      // Add one year to acessoInicial
      const oneYearLater = new Date(date);
      oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
      setMaxDate(oneYearLater);
    } else if (maxState === 2) {
      // Add three years to acessoInicial
      const threeYearsLater = new Date(date);
      threeYearsLater.setFullYear(threeYearsLater.getFullYear() + 3);
      setMaxDate(threeYearsLater);
    }
    setAcessoInicial(date);
  };

  const handleAcessoFinalChange = (date) => {
    setAcessoFinal(date);
  };

  const handleHorasInicialChange = (hour) => {
    setHorasInicial(hour);
  };

  const handleHorasFinalChange = (hour) => {
    setHorasFinal(hour);
  };

  const handleVinculoInicialChange = (date) => {
    setVinculoInicial(date);
  };

  const handleVinculoFinalChange = (date) => {
    setVinculoFinal(date);
  };

  const filterOptions = (options, { inputValue }) => {
    return options.filter(
      (option) =>
        option.descricao.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.email.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  // ############################################################## //
  // ##########################  States  ########################## //
  // ############################################################## //

  const [maxState, setMaxState] = useState(); //1 == 1 ano && 2 == 3 anos
  const [areaA, setAreaA] = useState(false);
  const [areaB, setAreaB] = useState(false);
  const [areaC, setAreaC] = useState(false);
  const [areaE, setAreaE] = useState(false);
  const [areaI, setAreaI] = useState(false);
  const [areaL, setAreaL] = useState(false);
  const [areaM, setAreaM] = useState(false);
  const [areaO, setAreaO] = useState(false);
  const [areaP, setAreaP] = useState(false);
  const [areaT, setAreaT] = useState(false);
  const [saca, setSACA] = useState(false);
  const [renovacao, setRenovacao] = useState(false);
  const [pedido, setPedido] = useState(false);
  const [alteracao, setAlteracao] = useState(false);
  // const [inquerito, setInquerito] = useState(false);
  const [checkIn, setCheckIn] = useState(false);
  const [portaEm, setPortaEm] = useState(false);
  const [portaDes, setPortaDes] = useState(false);
  const [vitalicio, setVitalicio] = useState(false);
  const [outrasPortas, setOutrasPortas] = useState(false);
  const [tools, setTools] = useState(false);
  const [carAccess, setCarAccess] = useState(false);

  // ############################################################## //
  // ####################  Cópia Credenciação  #################### //
  // ############################################################## //

  const [copiaCredenciacao, setCopiaCredenciacao] = useState([]);

  const handleCopiaChange = (e, copCred) => {
    const file = e.target.files[0];
    console.log(file);

    setCopiaCredenciacao(() => {
      const updatedFicheiros = [];
      updatedFicheiros.push({
        file,
        idTipo: copCred.id_crede_elementos_files_tipos,
        path: copCred.crede_elementos_files_tipos_path,
      });
      return updatedFicheiros;
    });
  };

  const handleRemoveCopia = (e, filName) => {
    e.preventDefault();

    setCopiaCredenciacao(
      copiaCredenciacao.filter(function (copiaCredenciacao) {
        return copiaCredenciacao.name !== filName.name;
      })
    );
  };

  // ############################################################## //
  // ####################  Cópia Credenciação  #################### //
  // ############################################################## //

  const [copiaCredenciacaoExterna, setCopiaCredenciacaoExterna] = useState([]);

  const handleCopiaExternaChange = (e) => {
    const file = e.target.files[0];
    console.log(file);

    setCopiaCredenciacaoExterna(() => {
      const updatedFicheiros = [];
      updatedFicheiros.push({
        file,
        idTipo: 23,
        path: "/copiaCredenciacaoExterna/",
      });
      return updatedFicheiros;
    });
  };

  const handleRemoveCopiaExterna = (e, filName) => {
    e.preventDefault();

    setCopiaCredenciacaoExterna(
      copiaCredenciacaoExterna.filter(function (copiaCredenciacaoExterna) {
        return copiaCredenciacaoExterna.name !== filName.name;
      })
    );
  };

  // ############################################################## //
  // ########################  Ficheiros  ######################### //
  // ############################################################## //

  const [ficheiros, setFicheiros] = useState([]);
  const [datePickerValues, setDatePickerValues] = useState({});

  // const handleFilesChange = (e, index, ficheiro) => {
  //   const file = e.target.files[0];

  //   setFicheiros((prevFicheiros) => {
  //     const updatedFicheiros = [...prevFicheiros];
  //     updatedFicheiros[index] = {
  //       file,
  //       idTipo: ficheiro.id_crede_elementos_files_tipos,
  //       path: ficheiro.crede_elementos_files_tipos_path,
  //     };

  //     // Preenche os índices vazios com objetos vazios
  //     for (let i = 0; i < updatedFicheiros.length; i++) {
  //       if (updatedFicheiros[i] === undefined) {
  //         updatedFicheiros[i] = null; // ou { file: null, idTipo: null, path: null } para manter a estrutura
  //       }
  //     }

  //     return updatedFicheiros;
  //   });
  // };

  // const handleRemoveFiles = (index) => {
  //   setFicheiros((prevFicheiros) => {
  //     const updatedFicheiros = [...prevFicheiros];
  //     updatedFicheiros[index] = null;

  //     // Preenche os índices vazios com objetos vazios
  //     for (let i = 0; i < updatedFicheiros.length; i++) {
  //       if (updatedFicheiros[i] === undefined) {
  //         updatedFicheiros[i] = null; // ou { file: null, idTipo: null, path: null } para manter a estrutura
  //       }
  //     }

  //     return updatedFicheiros;
  //   });
  // };

  // const handleDatePickerChange = (date, index) => {
  //   setDatePickerValues((prevValues) => {
  //     return {
  //       ...prevValues,
  //       [index]: date,
  //     };
  //   });
  // };

  // ############################################################## //
  // ########################  Ficheiros  ######################### //
  // ############################################################## //

  // const [ficheirosAdicionais, setFicheirosAdicionais] = useState([]);

  // const handleFilesAdicionaisChange = (e, index, ficheiro) => {
  //   const file = e.target.files[0];

  //   setFicheirosAdicionais((prevFicheiros) => {
  //     const updatedFicheiros = [...prevFicheiros];
  //     updatedFicheiros[index] = {
  //       file,
  //       idTipo: ficheiro.id_crede_elementos_files_tipos,
  //       path: ficheiro.crede_elementos_files_tipos_path,
  //     };
  //     return updatedFicheiros;
  //   });
  // };

  // const handleRemoveFilesAdicionais = (index) => {
  //   setFicheirosAdicionais((prevFicheiros) => {
  //     const updatedFicheiros = [...prevFicheiros];
  //     updatedFicheiros[index] = null;
  //     return updatedFicheiros;
  //   });
  // };

  // ############################################################## //
  // ###################  Viatura Selecionada  #################### //
  // ############################################################## //

  const viaturaSelecionada = useSelector(
    (state) => state.credenciais.veiculosById
  );

  useEffect(() => {
    setViaturaSel({
      modelo: viaturaSelecionada.crede_credenciacao_veiculo_modelo,
      marca: viaturaSelecionada.crede_credenciacao_veiculo_marca,
      n_apolice: viaturaSelecionada.crede_credenciacao_veiculo_apolice_nr,
      requisitante:
        viaturaSelecionada.crede_credenciacao_veiculo_nome_requisitante,
      n_distico: viaturaSelecionada.crede_credenciacao_veiculo_distico_nr,
    });
    setDataInspecao(
      viaturaSelecionada.crede_credenciacao_veiculo_inspecao_data
    );
    setDataProxInspecao(
      viaturaSelecionada.crede_credenciacao_veiculo_inspecao_data_proxima
    );
    setDataValidadeApolice(
      viaturaSelecionada.crede_credenciacao_veiculo_apolice_validade
    );
  }, [viaturaSelecionada]);

  // ############################################################## //
  // ##################  Proprietário Default  #################### //
  // ############################################################## //

  useEffect(() => {
    setEmpresa({
      id: 1609,
      descricao: "José A.F. Cardoso Sociedade Unipessoal, Lda",
    });
  }, [empresa]);

  // ############################################################## //
  // #################  Funcionario Selecionado  ################## //
  // ############################################################## //

  const funcionarioSelecionado = useSelector(
    (state) => state.credenciais.funcionario
  );

  useEffect(() => {
    setFuncionarioSel({
      categoria_profissional:
        funcionarioSelecionado.crede_elementos_categoria_profissional,
      cp: funcionarioSelecionado.crede_elementos_cp,
      data_nascimento: funcionarioSelecionado.crede_elementos_data_nascimento,
      doc_data_emissao: funcionarioSelecionado.crede_elementos_doc_data_emissao,
      doc_entidade_emissora:
        funcionarioSelecionado.crede_elementos_doc_entidade_emissora,
      doc_nr_identificacao:
        funcionarioSelecionado.crede_elementos_doc_nr_identificacao,
      doc_nr_identificacao_controlo:
        funcionarioSelecionado.crede_elementos_doc_nr_identificacao_controlo,
      doc_validade: funcionarioSelecionado.crede_elementos_doc_validade,
      doc_vitalicio: funcionarioSelecionado.crede_elementos_doc_vitalicio,
      email: funcionarioSelecionado.crede_elementos_email,
      filiacao_mae: funcionarioSelecionado.crede_elementos_filiacao_mae,
      filiacao_pai: funcionarioSelecionado.crede_elementos_filiacao_pai,
      funcao: funcionarioSelecionado.crede_elementos_funcao,
      local_nascimento: funcionarioSelecionado.crede_elementos_local_nascimento,
      localidade: funcionarioSelecionado.crede_elementos_localidade,
      morada: funcionarioSelecionado.crede_elementos_morada,
      nacionalidade: funcionarioSelecionado.crede_elementos_nacionalidade,
      nif: funcionarioSelecionado.crede_elementos_nif,
      nome: funcionarioSelecionado.crede_elementos_nome,
      telefone: funcionarioSelecionado.crede_elementos_telefone,
      telemovel: funcionarioSelecionado.crede_elementos_telemovel,
    });
    setDataEmissao(funcionarioSelecionado.crede_elementos_doc_data_emissao);
    setDataNascimento(funcionarioSelecionado.crede_elementos_data_nascimento);
    setDataValidade(funcionarioSelecionado.crede_elementos_doc_validade);
    setVitalicio(
      funcionarioSelecionado.crede_elementos_doc_vitalicio == "0" ? false : true
    );
    setHabilitacao({
      id: funcionarioSelecionado.id_crede_elementos_habilitacoes_tipos,
      descricao: funcionarioSelecionado.nome_habilitacoes,
    });
    setTipoDoc({
      id: funcionarioSelecionado.id_crede_tipo_doc_identificacao,
      descricao: funcionarioSelecionado.nome_tipo_doc_identificacao,
    });
    setEmpresa({
      id: funcionarioSelecionado.id_crede_fornecedores_servicos,
      descricao: funcionarioSelecionado.nome_fornecedores_servicos,
    });
  }, [funcionarioSelecionado]);

  // ############################################################## //
  // ######################  Switch Estados  ###################### //
  // ############################################################## //

  const estadosFiltered = estados.filter((e) => e.id === 1 || e.id === 2);

  // ############################################################## //
  // ######################  Modal Rascunho  ###################### //
  // ############################################################## //

  const [openRascunho, setOpenRascunho] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);
  const [modalState, setModalState] = useState("");

  const handleOpen = (e, ydata) => {
    e.preventDefault();

    setLoading(false);
    setDados(ydata);
    setModalState("add");
    setOpenRascunho(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpenRascunho(false);
  };

  // ############################################################## //
  // ##################  MUI Datatable columns  ################### //
  // ############################################################## //

  const formacoes = useSelector(
    (state) => state.credenciais.funcionario.formacoes
  );

  const columns = [
    {
      name: "id_crede_elementos_formacoes",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "crede_elementos_formacoes_nome",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
      },
    },
    {
      name: "crede_elementos_formacoes_cod",
      label: "Codigo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "crede_elementos_formacoes_tipo",
      label: "Tipo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "crede_elementos_formacoes_formador",
      label: "Formador",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "crede_elementos_formacoes_estados_nome",
      label: "Estado",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  // ############################################################## //
  // ##################  MUI Datatable options  ################### //
  // ############################################################## //

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },
  };

  // ############################################################## //
  // ##################  Verificar Formulário  #################### //
  // ############################################################## //

  // Verify form credenciação permanente
  const verifyFormPerm = () => {
    // Check if the required fields are filled
    const isRequiredFilled =
      !!funcionario.id &&
      !!estado.id &&
      !!aeroporto.id &&
      !!funcao.id &&
      !!tipoCartao.id;

    return isRequiredFilled;
  };

  // Verify form credenciação viatura
  const verifyFormViatura = () => {
    // Check if the required fields are filled
    const isRequiredFilled = !!veiculo.id && !!aeroporto.id && !!estado.id; // Add more conditions for other required fields

    return isRequiredFilled;
  };

  // Verify form credenciação pontual
  const verifyFormPontual = () => {
    // Check if the required fields are filled
    if (carAccess) {
      const isRequiredFilled = !!veiculo.id && !!estado.id && !!tipoCredSel.id;
      return isRequiredFilled;
    } else {
      const isRequiredFilled =
        !!funcionario.id &&
        !!estado.id &&
        !!aeroporto.id &&
        !!motivo.id &&
        !!tipoCredSel.id;
      return isRequiredFilled;
    }
  };

  const switchVerifications = (idCred) => {
    switch (idCred) {
      case 1:
        return verifyFormPerm();
      case 2:
      case 3:
      case 5:
        return verifyFormPontual();
      case 4:
        return verifyFormViatura();
      default:
        break;
    }
  };

  // ############################################################## //
  // ##############  Handle Change and Submit Form  ############### //
  // ############################################################## //

  const handleChange = (e) => {
    e.preventDefault();
    setCredenciacao({
      ...credenciacao,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ds = datasSelected.map((date) => {
      return moment(date).format("YYYY-MM-DD");
    });
    const ydata = [
      {
        funcionario_id: funcionario.id,
        estado_id: estado.id,
        pedido_nr: credenciacao.pedido_nr,
        nr_cartao: credenciacao.nr_cartao,
        motivo_recusa: credenciacao.motivo_recusa,
        area_acesso_a: areaA,
        area_acesso_b: areaB,
        area_acesso_c: areaC,
        area_acesso_e: areaE,
        area_acesso_i: areaI,
        area_acesso_l: areaL,
        area_acesso_m: areaM,
        area_acesso_o: areaO,
        area_acesso_p: areaP,
        area_acesso_t: areaT,
        saca: saca,
        inscricao_adicional: credenciacao.inscricao_adicional,
        obs: credenciacao.obs,
        validade_inicio: acessoInicial
          ? moment(acessoInicial).format("YYYY-MM-DD")
          : null,
        validade_fim: acessoFinal
          ? moment(acessoFinal).format("YYYY-MM-DD")
          : null,
        horario_inicio: dayjs(horasInicial).format("YYYY-MM-DD HH:mm:ss"),
        horario_fim: dayjs(horasFinal).format("YYYY-MM-DD HH:mm:ss"),
        renovar_cartao: renovacao,
        pedido_segunda_via: pedido,
        pedido_alteracao_areas: alteracao,
        vinculo_laboral_inicio: vinculoInicial
          ? moment(vinculoInicial).format("YYYY-MM-DD")
          : null,
        vinculo_laboral_fim: vinculoFinal
          ? moment(vinculoFinal).format("YYYY-MM-DD")
          : null,
        // inquerito_pessoal_exito: inquerito,
        porta_check_in: checkIn,
        porta_embarque: portaEm,
        porta_desembarque: portaDes,
        porta_outras: outrasPortas,
        aeroporto_id: aeroporto.id,
        tipoCartao: tipoCartao.id,
        funcao_id: funcao.id,
        cor_id: cor.id,
        tipoVinculo: tipoVinculo.id,
        tipoCredenciacao: tipoCredSel.id,
        acompanhante_id: acompanhante.id,
        motivo_id: motivo.id,
        numero_cred_externa: credenciacao.numero_cred_externa,
        tools: tools,
        car_access: carAccess,
        veiculo_id: veiculo.id,
        portAcesso_id: portAcesso.id,
        rascunho: 0,
        selectedDates: ds,
        projeto_id: projeto.id,
        acompanhante_nome: acompanhante.nome,
        acompanhante_cartao: acompanhante.numero,
        projeto_nome: projeto.descricao,
        desc_trab: credenciacao.desc_trab,
        destinatarios: destinatarios,
      },
    ];

    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!",
      type: "error",
    };
    const message = {
      isOpen: true,
      message: "Credenciação criada com sucesso!!",
      type: "success",
    };
    const messageEmail = {
      isOpen: true,
      message: "Email enviado com sucesso!!",
      type: "success",
    };
    const messageEmailErro = {
      isOpen: true,
      message: "Email não foi enviado tente novamente!",
      type: "warning",
    };

    if (switchVerifications(tipoCredSel.id)) {
      dispatch(
        AddCredenciacao(
          ydata,
          copiaCredenciacao,
          copiaCredenciacaoExterna,
          ficheiros,
          datePickerValues,
          toggleDrawer,
          ShowNotification,
          message,
          errorMessage,
          messageEmail,
          messageEmailErro,
          estado,
          tipoCredSel,
          fetchCredenciacoes
        )
      ).then(() => {
        // dispatch(NotifyCredenciacoes(credAdicionada.insertId, estado.id));
        toggleDrawer("right", false);
        fetchCredenciacoes();
        globalFetch(estado.id);
      });
    } else {
      // dispatch(ShowNotification(warningMessage));
      ydata[0] = { ...ydata[0], rascunho: 1 };
      handleOpen(e, ydata);
    }
  };

  const fetchCredFuncionarios = () => {
    dispatch(GetCredFuncionarios(null, 0));
  };

  const fetchCredPedidosAdicionaisDocs = () => {
    dispatch(GetCredPedidosAdicionaisDocs());
  };

  const fetchCredFicheirosViaturas = () => {
    dispatch(GetCredViaturaFicheiros());
  };

  const fetchCredFicheirosViaturasPermanentes = () => {
    dispatch(GetCredViaturaFicheirosPermanente());
  };

  useEffect(() => {
    fetchCredFuncionarios();
    fetchCredPedidosAdicionaisDocs();
    fetchCredFicheirosViaturas();
    fetchCredFicheirosViaturasPermanentes();
  }, []);

  const isToday = (date) => {
    const todayDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const checkDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );

    return checkDate.getTime() === todayDate.getTime();
  };

  const getDateStyle = (date) => {
    if (isToday(date)) {
      return {
        backgroundColor: "#ffeb3b", // Yellow background for today's date
        color: "#000", // Black text for better readability
      };
    }
    return {};
  };

  return (
    <div
      className={classes.list}
      style={
        dimensions.width <= 1100
          ? { width: "60vw" }
          : { width: `${drawerWidth}` }
      }
    >
      <ModalRascunho
        openRascunho={openRascunho}
        handleClose={handleClose}
        fetchCredenciacoes={fetchCredenciacoes}
        handleSubmit={handleSubmit}
        dados={dados}
        loading={loading}
        setLoading={setLoading}
        ficheiros={ficheiros}
        datePickerValues={datePickerValues}
        toggleDrawer={toggleDrawer}
        ShowNotification={ShowNotification}
        modalState={modalState}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 5,
        }}
      >
        <Box
          onClick={() => toggleDrawer("right", false)}
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <div>
            <FeatherIcon
              icon="chevron-left"
              size={20}
              style={{ strokeWidth: "1.5" }}
            />
          </div>
          <div className="text-underline-close-drawer">Credenciações</div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box>
      </Box>
      <Card
        sx={{
          m: 5,
          p: 2,
          boxShadow: "0px 10px 15px lightgrey",
        }}
      >
        <Box sx={{ m: 2, width: "46.5%" }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Tipo de Credenciação
          </Typography>
          <Autocomplete
            fullWidth
            name="tipoCred"
            clearText="Limpar"
            openText="Abrir"
            closeText="Fechar"
            noOptionsText="Sem dados"
            value={tipoCredSel}
            className="unidadesId-form"
            options={filteredTiposCreds || []}
            getOptionLabel={(option) => `${option.descricao}` || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} size="small" variant="outlined" />
            )}
            onChange={(_, value, reason) => {
              if (reason === "clear") {
                setTipoCredSel({ id: null, descricao: "" });
                return;
              } else {
                if (value.id == 4) {
                  dispatch(GetCredAeroportos());
                }
                if (value.id == 5) {
                  setCarAccess(true);
                } else {
                  setCarAccess(false);
                }
                setTipoCredSel(value);
                return value;
              }
            }}
          />
        </Box>
        {tipoCredSel.id == "1" ? (
          <>
            <Box
              sx={{
                m: 2,
              }}
            >
              <h4
                style={{
                  fontSize: "22px",
                  width: "100%",
                  marginBottom: "0px",
                }}
                className="text-underline"
              >
                Credenciação Permanente
              </h4>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 2, width: "30%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Nome *
                </Typography>
                <Autocomplete
                  fullWidth
                  name="nome"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  value={funcionario}
                  className="unidadesId-form"
                  options={funcionarios || []}
                  getOptionLabel={(option) => `${option.nome}` || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setFuncionario({ id: null, nome: "" });
                      return;
                    } else {
                      setFuncionario(value);
                      dispatch(GetCredFuncionarioById(value.id));
                      dispatch(GetCredAeroportosByFuncId(value.id));
                      return value;
                    }
                  }}
                />
              </Box>
              <Box sx={{ m: 2, width: "30%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Estado *
                </Typography>
                <Autocomplete
                  fullWidth
                  name="nome"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  value={estado}
                  className="unidadesId-form"
                  options={!superuser ? estadosFiltered : estados}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setEstado({ id: null, descricao: "" });
                      return;
                    } else {
                      setEstado(value);
                      return value;
                    }
                  }}
                />
              </Box>
              <Box sx={{ m: 2, width: "30%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Projeto
                </Typography>
                <Autocomplete
                  fullWidth
                  name="nome"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  value={projeto}
                  className="unidadesId-form"
                  options={obrasState || []}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setProjeto({ id: null, descricao: "" });
                      return;
                    } else {
                      setProjeto(value);
                      return value;
                    }
                  }}
                />
              </Box>
            </Box>
            {estado.descricao == "Levantada" ? (
              <>
                <Box
                  sx={{
                    m: 2,
                  }}
                >
                  <h4
                    style={{ color: "#E21450" }}
                    className="text-underline-title-drawer "
                  >
                    Credenciação
                  </h4>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box
                    sx={{
                      mx: 2,
                      display: "flex",
                      width: "46.5%",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Cópia Cartão
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      {uploadedCopia && (
                        <Button
                          sx={{
                            height: "40px",
                            borderRadius: "5px",
                            mr: 1,
                          }}
                          className={classes.btnUpload}
                          onClick={() => {
                            Instance()
                              .get(
                                `${BASE_URL}/credenciacoes/funcionarios/files/${uploadedCopia.id_crede_elementos_files}/download`,
                                { responseType: "blob" }
                              )
                              .then(({ data }) => {
                                const filename =
                                  uploadedCopia.crede_elementos_files_path.replace(
                                    /^.*[\\/]/,
                                    ""
                                  );
                                fileDownload(data, filename);
                              })
                              .catch((err) => {
                                console.log(err.response);
                                if (err.response) {
                                  if (err.response === 403) {
                                    // dispatch(ShowNotification(errorMessage));
                                  }
                                }
                              });
                          }}
                        >
                          <FeatherIcon icon="download" size={20} />
                        </Button>
                      )}
                      <Button
                        sx={{
                          height: "40px",
                          width: "200px",
                          borderRadius: "5px",
                          mr: 2,
                        }}
                        onChange={(e) => handleCopiaChange(e, copCred)}
                        className={classes.btnUpload}
                      >
                        <label
                          htmlFor={
                            copCred.crede_elementos_files_tipos_nome_input
                          }
                          className="custom-file-upload"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              cursor: "pointer",
                            }}
                          >
                            <p> Carregar Ficheiro</p>
                          </div>
                        </label>
                        <input
                          type="file"
                          name={copCred.crede_elementos_files_tipos_nome_input}
                          id={copCred.crede_elementos_files_tipos_nome_input}
                          hidden
                          accept=".pdf"
                        />
                      </Button>
                      <div style={{ width: "auto" }}>
                        {copiaCredenciacao &&
                          copiaCredenciacao.map((copiaCredenciacao, index) => {
                            return (
                              <div
                                style={{
                                  // marginRight: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                key={index}
                              >
                                {/* {copiaCredenciacao.file.name} */}
                                <IconButton
                                  onClick={() => {
                                    const fileName =
                                      copiaCredenciacao.file.name;
                                    const fileExtension = fileName.slice(
                                      (Math.max(0, fileName.lastIndexOf(".")) ||
                                        Infinity) + 1
                                    );

                                    if (fileExtension == "pdf") {
                                      const pdfFile = copiaCredenciacao.file;

                                      // Create a Blob from the PDF file
                                      const blob = new Blob([pdfFile], {
                                        type: "application/pdf",
                                      });

                                      // Create a URL for the Blob
                                      const blobUrl = URL.createObjectURL(blob);

                                      // Now you can use `blobUrl` to open or display the PDF
                                      window.open(blobUrl, "_blank");
                                    } else {
                                      const blobUrl = URL.createObjectURL(
                                        copiaCredenciacao.file
                                      );

                                      // Now you can use `blobUrl` to open or display the PDF
                                      window.open(blobUrl, "_blank");
                                    }
                                  }}
                                >
                                  <FeatherIcon icon="eye" size={"1rem"} />
                                </IconButton>
                                <IconButton
                                  onClick={(e) =>
                                    handleRemoveCopia(e, copiaCredenciacao)
                                  }
                                >
                                  <FeatherIcon icon="trash-2" size={"1rem"} />
                                </IconButton>
                              </div>
                            );
                          })}
                      </div>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mx: 2,
                      display: "flex",
                      width: "46.5%",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Nº Cartão
                    </Typography>
                    <TextField
                      name="nr_cartao"
                      size="small"
                      variant="outlined"
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      autoComplete="off"
                    />
                  </Box>
                </Box>
              </>
            ) : estado.descricao == "Recusada" ? (
              <Box
                sx={{
                  mx: 2,
                  display: "flex",
                  width: "46.5%",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Motivo de Recusa
                </Typography>
                <TextField
                  name="motivo_recusa"
                  multiline
                  rows={2}
                  size="small"
                  variant="outlined"
                  value={credenciacao.motivo_recusa}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            ) : null}
            {funcionario.id ? (
              <>
                <Box
                  sx={{
                    mb: 2,
                    mx: 2,
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <h4
                      style={{ color: "#E21450" }}
                      className="text-underline-title-drawer "
                    >
                      Funcionário Info
                    </h4>
                  </Box>
                  <Box>
                    <h4
                      onClick={() => setOpen(!open)}
                      className="text-underline-title-drawer"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {open ? (
                        <FeatherIcon
                          icon="chevron-up"
                          strokeWidth={2}
                          color="#707070"
                          sx={{ pt: 1 }}
                        />
                      ) : (
                        <FeatherIcon
                          icon="chevron-down"
                          strokeWidth={2}
                          color="#707070"
                          sx={{ pt: 1 }}
                        />
                      )}
                    </h4>
                  </Box>
                </Box>
                <Collapse in={open}>
                  <Card
                    sx={{
                      m: 2,
                      boxShadow: "0px 10px 15px lightgrey",
                    }}
                  >
                    <Box sx={{ m: 2 }}>
                      <h4
                        style={{ color: "#E21450" }}
                        className="text-underline-title-drawer "
                      >
                        Detalhes Pessoais
                      </h4>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mx: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "48%",
                        }}
                      >
                        <Box sx={{ m: 1 }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Nome Completo
                          </Typography>
                          <TextField
                            disabled
                            name="nome"
                            size="small"
                            variant="outlined"
                            value={funcionarioSel.nome}
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            autoComplete="off"
                          />
                        </Box>
                        <Box sx={{ m: 1 }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Morada
                          </Typography>
                          <TextField
                            disabled
                            multiline
                            rows={2}
                            name="morada"
                            size="small"
                            variant="outlined"
                            value={funcionarioSel.morada}
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            className={
                              funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                                ? classes.textField
                                : null
                            }
                            InputProps={{
                              readOnly: true, // Make the TextField read-only
                              classes: {
                                root:
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.disabledTextField
                                    : null, // Apply the disabledTextField class to the root element
                                input:
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.blurredText
                                    : null, // Apply the blurred text class to the input element
                              },
                            }}
                            autoComplete="off"
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ m: 1, width: "46.5%" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              Localidade
                            </Typography>
                            <TextField
                              disabled
                              name="localidade"
                              size="small"
                              variant="outlined"
                              value={funcionarioSel.localidade}
                              onChange={handleChange}
                              style={{ width: "100%" }}
                              className={
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                1
                                  ? classes.textField
                                  : null
                              }
                              InputProps={{
                                readOnly: true, // Make the TextField read-only
                                classes: {
                                  root:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.disabledTextField
                                      : null, // Apply the disabledTextField class to the root element
                                  input:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.blurredText
                                      : null, // Apply the blurred text class to the input element
                                },
                              }}
                              autoComplete="off"
                            />
                          </Box>
                          <Box sx={{ m: 1, width: "46.5%" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              Código Postal
                            </Typography>
                            <TextField
                              disabled
                              name="cp"
                              size="small"
                              variant="outlined"
                              value={funcionarioSel.cp}
                              onChange={handleChange}
                              style={{ width: "100%" }}
                              className={
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                1
                                  ? classes.textField
                                  : null
                              }
                              InputProps={{
                                readOnly: true, // Make the TextField read-only
                                classes: {
                                  root:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.disabledTextField
                                      : null, // Apply the disabledTextField class to the root element
                                  input:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.blurredText
                                      : null, // Apply the blurred text class to the input element
                                },
                              }}
                              autoComplete="off"
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ m: 1, width: "46.5%" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              Telefone
                            </Typography>
                            <TextField
                              disabled
                              name="telefone"
                              size="small"
                              variant="outlined"
                              value={funcionarioSel.telefone}
                              onChange={handleChange}
                              style={{ width: "100%" }}
                              className={
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                1
                                  ? classes.textField
                                  : null
                              }
                              InputProps={{
                                readOnly: true, // Make the TextField read-only
                                classes: {
                                  root:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.disabledTextField
                                      : null, // Apply the disabledTextField class to the root element
                                  input:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.blurredText
                                      : null, // Apply the blurred text class to the input element
                                },
                              }}
                              autoComplete="off"
                            />
                          </Box>
                          <Box sx={{ m: 1, width: "46.5%" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              Telemóvel
                            </Typography>
                            <TextField
                              disabled
                              name="telemovel"
                              size="small"
                              variant="outlined"
                              value={funcionarioSel.telemovel}
                              onChange={handleChange}
                              style={{ width: "100%" }}
                              className={
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                1
                                  ? classes.textField
                                  : null
                              }
                              InputProps={{
                                readOnly: true, // Make the TextField read-only
                                classes: {
                                  root:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.disabledTextField
                                      : null, // Apply the disabledTextField class to the root element
                                  input:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.blurredText
                                      : null, // Apply the blurred text class to the input element
                                },
                              }}
                              autoComplete="off"
                            />
                          </Box>
                        </Box>
                        <Box sx={{ m: 1 }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Email
                          </Typography>
                          <TextField
                            disabled
                            name="email"
                            size="small"
                            variant="outlined"
                            value={funcionarioSel.email}
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            className={
                              funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                                ? classes.textField
                                : null
                            }
                            InputProps={{
                              readOnly: true, // Make the TextField read-only
                              classes: {
                                root:
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.disabledTextField
                                    : null, // Apply the disabledTextField class to the root element
                                input:
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.blurredText
                                    : null, // Apply the blurred text class to the input element
                              },
                            }}
                            autoComplete="off"
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "48%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: 1,
                          }}
                        >
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={ptLocale}
                          >
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              Data de Nascimento
                            </Typography>
                            <DatePicker
                              disabled
                              margin="normal"
                              id="date-picker-dialog"
                              format="dd/MM/yyyy"
                              okLabel="ok"
                              cancelLabel="cancelar"
                              invalidDateMessage="Data Inválida"
                              value={dataNascimento}
                              onChange={handleDataNascimentoChange}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "dd/mm/aaaa",
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                  }}
                                  name="data_nascimento"
                                  sx={{ width: "200px" }}
                                  variant="outlined"
                                  size="small"
                                  className={
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.textField
                                      : null
                                  }
                                />
                              )}
                              InputProps={{
                                readOnly: true, // Make the TextField read-only
                                classes: {
                                  root:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.disabledTextField
                                      : null, // Apply the disabledTextField class to the root element
                                  input:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.blurredText
                                      : null, // Apply the blurred text class to the input element
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ m: 1, width: "46.5%" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              Local de nascimento
                            </Typography>
                            <TextField
                              disabled
                              name="local_nascimento"
                              size="small"
                              variant="outlined"
                              value={funcionarioSel.local_nascimento}
                              onChange={handleChange}
                              style={{ width: "100%" }}
                              className={
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                1
                                  ? classes.textField
                                  : null
                              }
                              InputProps={{
                                readOnly: true, // Make the TextField read-only
                                classes: {
                                  root:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.disabledTextField
                                      : null, // Apply the disabledTextField class to the root element
                                  input:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.blurredText
                                      : null, // Apply the blurred text class to the input element
                                },
                              }}
                              autoComplete="off"
                            />
                          </Box>
                          <Box sx={{ m: 1, width: "46.5%" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              Nacionalidade
                            </Typography>
                            <TextField
                              disabled
                              name="nacionalidade"
                              size="small"
                              variant="outlined"
                              value={funcionarioSel.nacionalidade}
                              onChange={handleChange}
                              style={{ width: "100%" }}
                              className={
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                1
                                  ? classes.textField
                                  : null
                              }
                              InputProps={{
                                readOnly: true, // Make the TextField read-only
                                classes: {
                                  root:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.disabledTextField
                                      : null, // Apply the disabledTextField class to the root element
                                  input:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.blurredText
                                      : null, // Apply the blurred text class to the input element
                                },
                              }}
                              autoComplete="off"
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ m: 1, width: "46.5%" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              Filiação - Pai
                            </Typography>
                            <TextField
                              disabled
                              name="filiacao_pai"
                              size="small"
                              variant="outlined"
                              value={funcionarioSel.filiacao_pai}
                              onChange={handleChange}
                              style={{ width: "100%" }}
                              className={
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                1
                                  ? classes.textField
                                  : null
                              }
                              InputProps={{
                                readOnly: true, // Make the TextField read-only
                                classes: {
                                  root:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.disabledTextField
                                      : null, // Apply the disabledTextField class to the root element
                                  input:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.blurredText
                                      : null, // Apply the blurred text class to the input element
                                },
                              }}
                              autoComplete="off"
                            />
                          </Box>
                          <Box sx={{ m: 1, width: "46.5%" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              Filiação - Mãe
                            </Typography>
                            <TextField
                              disabled
                              name="filiacao_mae"
                              size="small"
                              variant="outlined"
                              value={funcionarioSel.filiacao_mae}
                              onChange={handleChange}
                              style={{ width: "100%" }}
                              className={
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                1
                                  ? classes.textField
                                  : null
                              }
                              InputProps={{
                                readOnly: true, // Make the TextField read-only
                                classes: {
                                  root:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.disabledTextField
                                      : null, // Apply the disabledTextField class to the root element
                                  input:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.blurredText
                                      : null, // Apply the blurred text class to the input element
                                },
                              }}
                              autoComplete="off"
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ m: 1, width: "46.5%" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              Categoria Profissional
                            </Typography>
                            <TextField
                              disabled
                              name="categoria_profissional"
                              size="small"
                              variant="outlined"
                              value={funcionarioSel.categoria_profissional}
                              onChange={handleChange}
                              style={{ width: "100%" }}
                              className={
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                1
                                  ? classes.textField
                                  : null
                              }
                              InputProps={{
                                readOnly: true, // Make the TextField read-only
                                classes: {
                                  root:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.disabledTextField
                                      : null, // Apply the disabledTextField class to the root element
                                  input:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.blurredText
                                      : null, // Apply the blurred text class to the input element
                                },
                              }}
                              autoComplete="off"
                            />
                          </Box>
                          <Box sx={{ m: 1, width: "46.5%" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              Função
                            </Typography>
                            <TextField
                              disabled
                              name="funcao"
                              size="small"
                              variant="outlined"
                              value={funcionarioSel.funcao}
                              onChange={handleChange}
                              style={{ width: "100%" }}
                              className={
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                1
                                  ? classes.textField
                                  : null
                              }
                              InputProps={{
                                readOnly: true, // Make the TextField read-only
                                classes: {
                                  root:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.disabledTextField
                                      : null, // Apply the disabledTextField class to the root element
                                  input:
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.blurredText
                                      : null, // Apply the blurred text class to the input element
                                },
                              }}
                              autoComplete="off"
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ m: 1, width: "46.5%" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              Entidade Patronal
                            </Typography>
                            <Autocomplete
                              disabled
                              fullWidth
                              clearText="Limpar"
                              openText="Abrir"
                              closeText="Fechar"
                              noOptionsText="Sem dados"
                              options={empresas || []}
                              value={empresa}
                              getOptionLabel={(option) =>
                                `${option.descricao}` || ""
                              }
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  variant="outlined"
                                  className={
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.textField
                                      : null
                                  }
                                  InputProps={{
                                    readOnly: true, // Make the TextField read-only
                                    classes: {
                                      root:
                                        funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                        1
                                          ? classes.disabledTextField
                                          : null, // Apply the disabledTextField class to the root element
                                      input:
                                        funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                        1
                                          ? classes.blurredText
                                          : null, // Apply the blurred text class to the input element
                                    },
                                  }}
                                  autoComplete="off"
                                />
                              )}
                              onChange={(_, value, reason) => {
                                if (reason === "clear") {
                                  setEmpresa({
                                    id: null,
                                    descricao: "",
                                  });
                                  return;
                                } else {
                                  setEmpresa(value);
                                  return value;
                                }
                              }}
                            />
                          </Box>
                          <Box sx={{ m: 1, width: "46.5%" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              Habilitações
                            </Typography>
                            <Autocomplete
                              disabled
                              fullWidth
                              clearText="Limpar"
                              openText="Abrir"
                              closeText="Fechar"
                              noOptionsText="Sem dados"
                              options={habilitacoes || []}
                              value={habilitacao}
                              getOptionLabel={(option) =>
                                `${option.descricao}` || ""
                              }
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  variant="outlined"
                                  className={
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.textField
                                      : null
                                  }
                                  InputProps={{
                                    readOnly: true, // Make the TextField read-only
                                    classes: {
                                      root:
                                        funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                        1
                                          ? classes.disabledTextField
                                          : null, // Apply the disabledTextField class to the root element
                                      input:
                                        funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                        1
                                          ? classes.blurredText
                                          : null, // Apply the blurred text class to the input element
                                    },
                                  }}
                                />
                              )}
                              onChange={(_, value, reason) => {
                                if (reason === "clear") {
                                  setHabilitacao({
                                    id: null,
                                    descricao: "",
                                  });
                                  return;
                                } else {
                                  setHabilitacao(value);
                                  return value;
                                }
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ m: 2 }}>
                      <h4
                        style={{ color: "#E21450" }}
                        className="text-underline-title-drawer "
                      >
                        Documento de Identificação
                      </h4>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mx: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "48%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ m: 1, width: "46.5%" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              Tipo de Documento
                            </Typography>
                            <Autocomplete
                              disabled
                              fullWidth
                              clearText="Limpar"
                              openText="Abrir"
                              closeText="Fechar"
                              noOptionsText="Sem dados"
                              options={tipoDocs || []}
                              value={tipoDoc}
                              getOptionLabel={(option) =>
                                `${option.descricao}` || ""
                              }
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  variant="outlined"
                                  className={
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.textField
                                      : null
                                  }
                                  InputProps={{
                                    readOnly: true, // Make the TextField read-only
                                    classes: {
                                      root:
                                        funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                        1
                                          ? classes.disabledTextField
                                          : null, // Apply the disabledTextField class to the root element
                                      input:
                                        funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                        1
                                          ? classes.blurredText
                                          : null, // Apply the blurred text class to the input element
                                    },
                                  }}
                                />
                              )}
                              onChange={(_, value, reason) => {
                                if (reason === "clear") {
                                  setTipoDoc({
                                    id: null,
                                    descricao: "",
                                  });
                                  return;
                                } else {
                                  setTipoDoc(value);
                                  return value;
                                }
                              }}
                            />
                          </Box>
                          <Box sx={{ m: 1, width: "46.5%" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              Nº Doc Identificação
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <TextField
                                disabled
                                name="doc_nr_identificacao"
                                size="small"
                                variant="outlined"
                                value={funcionarioSel.doc_nr_identificacao}
                                onChange={handleChange}
                                style={{ width: "69%" }}
                                className={
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.textField
                                    : null
                                }
                                InputProps={{
                                  readOnly: true, // Make the TextField read-only
                                  classes: {
                                    root:
                                      funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                      1
                                        ? classes.disabledTextField
                                        : null, // Apply the disabledTextField class to the root element
                                    input:
                                      funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                      1
                                        ? classes.blurredText
                                        : null, // Apply the blurred text class to the input element
                                  },
                                }}
                                autoComplete="off"
                              />
                              <TextField
                                disabled
                                name="doc_nr_identificacao_controlo"
                                size="small"
                                variant="outlined"
                                value={
                                  funcionarioSel.doc_nr_identificacao_controlo
                                }
                                onChange={handleChange}
                                style={{ width: "29%" }}
                                className={
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.textField
                                    : null
                                }
                                InputProps={{
                                  readOnly: true, // Make the TextField read-only
                                  classes: {
                                    root:
                                      funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                      1
                                        ? classes.disabledTextField
                                        : null, // Apply the disabledTextField class to the root element
                                    input:
                                      funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                      1
                                        ? classes.blurredText
                                        : null, // Apply the blurred text class to the input element
                                  },
                                }}
                                autoComplete="off"
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              m: 1,
                              width: "46.5%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "45%",
                              }}
                            >
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={ptLocale}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    marginBottom: "10px",
                                  }}
                                >
                                  Data de Validade
                                </Typography>
                                <DatePicker
                                  disabled
                                  margin="normal"
                                  id="date-picker-dialog"
                                  format="dd/MM/yyyy"
                                  okLabel="ok"
                                  cancelLabel="cancelar"
                                  invalidDateMessage="Data Inválida"
                                  value={dataValidade}
                                  onChange={handleDataValidadeChange}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "dd/mm/aaaa",
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                      }}
                                      variant="outlined"
                                      size="small"
                                      className={
                                        funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                        1
                                          ? classes.textField
                                          : null
                                      }
                                    />
                                  )}
                                  InputProps={{
                                    readOnly: true, // Make the TextField read-only
                                    classes: {
                                      root:
                                        funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                        1
                                          ? classes.disabledTextField
                                          : null, // Apply the disabledTextField class to the root element
                                      input:
                                        funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                        1
                                          ? classes.blurredText
                                          : null, // Apply the blurred text class to the input element
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </Box>
                            <Box
                              sx={{
                                display: "flex-end",
                                flexDirection: "column",
                                width: "45%",
                              }}
                            >
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={ptLocale}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    marginBottom: "10px",
                                  }}
                                >
                                  Data de Emissão
                                </Typography>
                                <DatePicker
                                  disabled
                                  margin="normal"
                                  id="date-picker-dialog"
                                  format="dd/MM/yyyy"
                                  okLabel="ok"
                                  cancelLabel="cancelar"
                                  invalidDateMessage="Data Inválida"
                                  value={dataEmissao}
                                  onChange={handleDataEmissaoChange}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "dd/mm/aaaa",
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                      }}
                                      variant="outlined"
                                      size="small"
                                      className={
                                        funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                        1
                                          ? classes.textField
                                          : null
                                      }
                                    />
                                  )}
                                  InputProps={{
                                    readOnly: true, // Make the TextField read-only
                                    classes: {
                                      root:
                                        funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                        1
                                          ? classes.disabledTextField
                                          : null, // Apply the disabledTextField class to the root element
                                      input:
                                        funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                        1
                                          ? classes.blurredText
                                          : null, // Apply the blurred text class to the input element
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              m: 1,
                              width: "46.5%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex-end",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  marginBottom: "10px",
                                }}
                              >
                                Vitalicio
                              </Typography>
                              <Box>
                                <Checkbox
                                  disabled
                                  checked={vitalicio}
                                  onChange={() => setVitalicio(!vitalicio)}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "48%",
                        }}
                      >
                        <Box sx={{ m: 1 }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            NIF
                          </Typography>
                          <TextField
                            disabled
                            name="nif"
                            size="small"
                            variant="outlined"
                            value={funcionarioSel.nif}
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            className={
                              funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                                ? classes.textField
                                : null
                            }
                            InputProps={{
                              readOnly: true, // Make the TextField read-only
                              classes: {
                                root:
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.disabledTextField
                                    : null, // Apply the disabledTextField class to the root element
                                input:
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.blurredText
                                    : null, // Apply the blurred text class to the input element
                              },
                            }}
                            autoComplete="off"
                          />
                        </Box>
                        <Box sx={{ m: 1 }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Entidade Emissora
                          </Typography>
                          <TextField
                            name="doc_entidade_emissora"
                            size="small"
                            disabled
                            variant="outlined"
                            value={funcionarioSel.doc_entidade_emissora}
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            className={
                              funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                                ? classes.textField
                                : null
                            }
                            InputProps={{
                              readOnly: true, // Make the TextField read-only
                              classes: {
                                root:
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.disabledTextField
                                    : null, // Apply the disabledTextField class to the root element
                                input:
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.blurredText
                                    : null, // Apply the blurred text class to the input element
                              },
                            }}
                            autoComplete="off"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mx: 1,
                      }}
                    >
                      <Box
                        sx={{
                          mx: 1,
                          width: "100%",
                        }}
                      >
                        <MUIDataTable
                          data={formacoes || []}
                          title={
                            <h4
                              style={{
                                fontSize: "22px",
                                width: "100%",
                              }}
                              className="text-underline"
                            >
                              Formações
                            </h4>
                          }
                          columns={columns}
                          options={options}
                        />
                      </Box>
                    </Box>
                  </Card>
                </Collapse>
              </>
            ) : null}
            <Box sx={{ m: 2 }}>
              <h4
                style={{ color: "#E21450" }}
                className="text-underline-title-drawer "
              >
                Cartão de acesso
              </h4>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ m: 1, width: "48%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Nº Pedido no Sistema Externo
                    </Typography>
                    <TextField
                      name="pedido_nr"
                      size="small"
                      variant="outlined"
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box sx={{ m: 1, width: "48%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Local/Aeroporto *
                    </Typography>
                    <Autocomplete
                      disabled={funcionario.id != null ? false : true}
                      fullWidth
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      noOptionsText="Sem dados"
                      options={aeroportos || []}
                      value={aeroporto}
                      getOptionLabel={(option) => `${option.descricao}` || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                        />
                      )}
                      onChange={(_, value, reason) => {
                        if (reason === "clear") {
                          setAeroporto({ id: null, descricao: "" });
                          return;
                        } else {
                          setAeroporto(value);
                          return value;
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ m: 1, width: "48%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Tipo de Cartão Solicitado *
                    </Typography>
                    <Autocomplete
                      fullWidth
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      noOptionsText="Sem dados"
                      options={tipoCartoes || []}
                      value={tipoCartao}
                      getOptionLabel={(option) => `${option.descricao}` || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                        />
                      )}
                      onChange={(_, value, reason) => {
                        if (reason === "clear") {
                          setTipoCartao({ id: null, descricao: "" });
                          return;
                        } else {
                          setMaxState(value.id);
                          setTipoCartao(value);
                          return value;
                        }
                      }}
                    />
                  </Box>
                  <Box sx={{ m: 1, width: "48%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Função a desempenhar *
                    </Typography>
                    <Autocomplete
                      fullWidth
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      noOptionsText="Sem dados"
                      options={funcoes || []}
                      value={funcao}
                      getOptionLabel={(option) => `${option.descricao}` || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                        />
                      )}
                      onChange={(_, value, reason) => {
                        if (reason === "clear") {
                          setFuncao({ id: null, descricao: "" });
                          return;
                        } else {
                          setFuncao(value);
                          return value;
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ m: 1 }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Área de acesso pretendido
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaA}
                          onChange={() => setAreaA(!areaA)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>A</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaB}
                          onChange={() => setAreaB(!areaB)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>B</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaC}
                          onChange={() => setAreaC(!areaC)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>C</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaE}
                          onChange={() => setAreaE(!areaE)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>E</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaI}
                          onChange={() => setAreaI(!areaI)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>I</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaL}
                          onChange={() => setAreaL(!areaL)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>L</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaM}
                          onChange={() => setAreaM(!areaM)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>M</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaO}
                          onChange={() => setAreaO(!areaO)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>O</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaP}
                          onChange={() => setAreaP(!areaP)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>P</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaT}
                          onChange={() => setAreaT(!areaT)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>T</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ m: 1 }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    SACA
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      my: 1,
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Checkbox
                        checked={saca}
                        onChange={() => setSACA(!saca)}
                      />
                    </Box>
                    <Typography style={{ fontWeight: "bold" }}>
                      Necessário SACA
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ m: 1 }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Inscrição adicional de acesso
                  </Typography>
                  <TextField
                    name="inscricao_adicional"
                    size="small"
                    variant="outlined"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
                <Box sx={{ m: 1 }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Observações
                  </Typography>
                  <TextField
                    name="obs"
                    multiline
                    rows={3}
                    size="small"
                    variant="outlined"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptLocale}
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Validade de acesso
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <DatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        minDate={new Date()}
                        format="dd/MM/yyyy"
                        okLabel="ok"
                        cancelLabel="cancelar"
                        invalidDateMessage="Data Inválida"
                        value={acessoInicial}
                        onChange={handleAcessoInicialChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "dd/mm/aaaa",
                            }}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            sx={{ width: "200px" }}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "center",
                          mx: 2,
                        }}
                      >
                        até
                      </Typography>
                      <DatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        format="dd/MM/yyyy"
                        minDate={new Date()}
                        maxDate={maxDate}
                        okLabel="ok"
                        cancelLabel="cancelar"
                        invalidDateMessage="Data Inválida"
                        value={acessoFinal}
                        onChange={handleAcessoFinalChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "dd/mm/aaaa",
                            }}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            sx={{ width: "200px" }}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </Box>
                  </LocalizationProvider>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Horário de acesso
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <TimePicker
                        format="hh:mm"
                        ampm={false}
                        value={horasInicial}
                        onChange={handleHorasInicialChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "hh:mm",
                            }}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            sx={{ width: "200px" }}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "center",
                          mx: 2,
                        }}
                      >
                        até
                      </Typography>
                      <TimePicker
                        format="hh:mm"
                        ampm={false}
                        value={horasFinal}
                        onChange={handleHorasFinalChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "hh:mm",
                            }}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            sx={{ width: "200px" }}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </Box>
                  </LocalizationProvider>
                </Box>
                <Box sx={{ m: 1 }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Cor
                  </Typography>
                  <Autocomplete
                    fullWidth
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    options={cores || []}
                    value={cor}
                    getOptionLabel={(option) => `${option.descricao}` || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        setCor({ id: null, descricao: "" });
                        return;
                      } else {
                        setCor(value);
                        return value;
                      }
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", m: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold", width: "50%" }}>
                      Renovação do Cartão
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "50%",
                      }}
                    >
                      <Checkbox
                        checked={renovacao}
                        onChange={() => setRenovacao(!renovacao)}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", m: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", width: "50%" }}>
                      Pedido de 2ª Via
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "50%",
                      }}
                    >
                      <Checkbox
                        checked={pedido}
                        onChange={() => setPedido(!pedido)}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", m: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", width: "50%" }}>
                      Pedido de Alteração de Áreas
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "50%",
                      }}
                    >
                      <Checkbox
                        checked={alteracao}
                        onChange={() => setAlteracao(!alteracao)}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ m: 1 }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Tipo de Vinculo Laboral
                  </Typography>
                  <Autocomplete
                    fullWidth
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    options={tipoVinculos || []}
                    value={tipoVinculo}
                    getOptionLabel={(option) => `${option.descricao}` || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        setTipoVinculo({ id: null, descricao: "" });
                        return;
                      } else {
                        setTipoVinculo(value);
                        return value;
                      }
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ptLocale}
                    >
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Inicio do Vinculo Laboral
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <DatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          format="dd/MM/yyyy"
                          okLabel="ok"
                          cancelLabel="cancelar"
                          invalidDateMessage="Data Inválida"
                          value={vinculoInicial}
                          onChange={handleVinculoInicialChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "dd/mm/aaaa",
                              }}
                              InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                              }}
                              sx={{ width: "200px" }}
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      </Box>
                    </LocalizationProvider>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ptLocale}
                    >
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Fim do Vinculo Laboral
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <DatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          format="dd/MM/yyyy"
                          okLabel="ok"
                          cancelLabel="cancelar"
                          invalidDateMessage="Data Inválida"
                          value={vinculoFinal}
                          onChange={handleVinculoFinalChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "dd/mm/aaaa",
                              }}
                              InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                              }}
                              sx={{ width: "200px" }}
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      </Box>
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ m: 2 }}>
              <h4
                style={{ color: "#E21450" }}
                className="text-underline-title-drawer "
              >
                Dados Complementares
              </h4>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "auto",
                  justifyContent: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    m: 1,
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>Check Ins</Typography>
                  <Checkbox
                    checked={checkIn}
                    onChange={() => setCheckIn(!checkIn)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    m: 1,
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                  >
                    Porta Embarque
                  </Typography>
                  <Checkbox
                    checked={portaEm}
                    onChange={() => setPortaEm(!portaEm)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    m: 1,
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                  >
                    Porta de desembarque
                  </Typography>
                  <Checkbox
                    checked={portaDes}
                    onChange={() => setPortaDes(!portaDes)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    m: 1,
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                  >
                    Outras Portas
                  </Typography>
                  <Checkbox
                    checked={outrasPortas}
                    onChange={() => setOutrasPortas(!outrasPortas)}
                  />
                </Box>
              </Box>
            </Box>
            {/* <Box sx={{ m: 2 }}>
              <h4
                style={{ color: "#E21450" }}
                className="text-underline-title-drawer "
              >
                Pedidos Adicionais
              </h4>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                mx: 1,
                width: "100%",
              }}
            >
              {ficheirosAdicionaisState &&
                ficheirosAdicionaisState.map((ficheiro, index) => {
                  const matchingFile = uploadedFiles.find(
                    (file) =>
                      file.id_crede_elementos_files_tipos ===
                      ficheiro.id_crede_elementos_files_tipos
                  );
                  return (
                    <Box sx={{ m: 1, width: "20%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        {ficheiro.crede_elementos_files_tipos_nome}
                        {/* {ficheiro.id_crede_elementos_files_tipos != 1 &&
                      ficheiro.id_crede_elementos_files_tipos != 7
                        ? " *"
                        : null} */}
            {/* </Typography>
                      <Box sx={{ display: "flex" }}>
                        {matchingFile && (
                          <Button
                            sx={{
                              height: "40px",
                              borderRadius: "5px",
                              mr: 1,
                            }}
                            className={classes.btnUpload}
                            onClick={() => {
                              Instance()
                                .get(
                                  `${BASE_URL}/credenciacoes/funcionarios/files/${matchingFile.id_crede_elementos_files}/download`,
                                  { responseType: "blob" }
                                )
                                .then(({ data }) => {
                                  const filename =
                                    matchingFile.crede_elementos_files_path.replace(
                                      /^.*[\\/]/,
                                      ""
                                    );
                                  fileDownload(data, filename);
                                })
                                .catch((err) => {
                                  console.log(err.response);
                                  if (err.response) {
                                    if (err.response === 403) {
                                      // dispatch(ShowNotification(errorMessage));
                                    }
                                  }
                                });
                            }}
                          >
                            <FeatherIcon icon="download" size={20} />
                          </Button>
                        )}
                        <Button
                          sx={{
                            height: "40px",
                            width: "200px",
                            borderRadius: "5px",
                            mr: 2,
                          }}
                          onChange={(e) =>
                            handleFilesChange(e, index, ficheiro)
                          }
                          className={classes.btnUpload}
                        >
                          <label
                            htmlFor={
                              ficheiro.crede_elementos_files_tipos_nome_input
                            }
                            className="custom-file-upload"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                cursor: "pointer",
                              }}
                            >
                              <p> Carregar </p>
                            </div>
                          </label>
                          <input
                            type="file"
                            name={
                              ficheiro.crede_elementos_files_tipos_nome_input
                            }
                            id={ficheiro.crede_elementos_files_tipos_nome_input}
                            hidden
                            accept="image/jpeg, .pdf"
                          />
                        </Button>
                        <div style={{ width: "100%" }}>
                          {ficheiros[index] && (
                            <div
                              style={{
                                marginRight: "10px",
                                display: "flex",
                                alignItems: "center",
                              }}
                              key={index}
                            >
                              <IconButton
                                onClick={() => {
                                  const fileName = ficheiros[index].file.name;
                                  const fileExtension = fileName.slice(
                                    (Math.max(0, fileName.lastIndexOf(".")) ||
                                      Infinity) + 1
                                  );

                                  if (fileExtension == "pdf") {
                                    const pdfFile = ficheiros[index].file;

                                    // Create a Blob from the PDF file
                                    const blob = new Blob([pdfFile], {
                                      type: "application/pdf",
                                    });

                                    // Create a URL for the Blob
                                    const blobUrl = URL.createObjectURL(blob);

                                    // Now you can use `blobUrl` to open or display the PDF
                                    window.open(blobUrl, "_blank");
                                  } else {
                                    const blobUrl = URL.createObjectURL(
                                      ficheiros[index].file
                                    );

                                    // Now you can use `blobUrl` to open or display the PDF
                                    window.open(blobUrl, "_blank");
                                  }
                                }}
                              >
                                <FeatherIcon icon="eye" size={"1rem"} />
                              </IconButton>
                              <IconButton
                                onClick={() => handleRemoveFiles(index)}
                              >
                                <FeatherIcon icon="trash-2" size={"1rem"} />
                              </IconButton>
                            </div>
                          )}
                        </div>
                      </Box>
                    </Box>
                  );
                })}
            </Box> */}
          </>
        ) : tipoCredSel.id == "4" ? (
          <>
            <Box
              sx={{
                m: 2,
              }}
            >
              <h4
                style={{
                  fontSize: "22px",
                  width: "100%",
                  marginBottom: "0px",
                }}
                className="text-underline"
              >
                Credenciação Viatura
              </h4>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 2, width: "32%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Aeroporto *
                </Typography>
                <Autocomplete
                  fullWidth
                  name="nome"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  value={aeroporto}
                  className="unidadesId-form"
                  options={aeroportosViatura || []}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setAeroporto({ id: null, descricao: "" });
                      return;
                    } else {
                      setAeroporto(value);
                      return value;
                    }
                  }}
                />
              </Box>

              <Box sx={{ m: 2, width: "32%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Matricula *
                </Typography>
                <Autocomplete
                  fullWidth
                  name="nome"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  value={veiculo}
                  className="unidadesId-form"
                  options={veiculos || []}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setVeiculo({ id: null, descricao: "" });
                      return;
                    } else {
                      setVeiculo(value);
                      dispatch(GetCredVeiculosById(value.id));
                      return value;
                    }
                  }}
                />
              </Box>
              <Box sx={{ m: 2, width: "32%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Estado *
                </Typography>
                <Autocomplete
                  fullWidth
                  name="nome"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  value={estado}
                  className="unidadesId-form"
                  options={!superuser ? estadosFiltered : estados}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setEstado({ id: null, descricao: "" });
                      return;
                    } else {
                      setEstado(value);
                      return value;
                    }
                  }}
                />
              </Box>
              {/* <Box sx={{ m: 2, width: "23.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Projeto *
                </Typography>
                <Autocomplete
                  fullWidth
                  name="nome"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  value={projeto}
                  className="unidadesId-form"
                  options={obrasState || []}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setProjeto({ id: null, descricao: "" });
                      return;
                    } else {
                      setProjeto(value);
                      return value;
                    }
                  }}
                />
              </Box> */}
            </Box>
            {estado.descricao == "Recusada" && (
              <Box
                sx={{
                  mx: 2,
                  display: "flex",
                  width: "46.5%",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Motivo de Recusa
                </Typography>
                <TextField
                  name="motivo_recusa"
                  multiline
                  rows={2}
                  size="small"
                  variant="outlined"
                  value={credenciacao.motivo_recusa}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            )}
            <Box sx={{ m: 2 }}>
              <h4
                style={{ color: "#E21450" }}
                className="text-underline-title-drawer "
              >
                Dados Viatura
              </h4>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 2, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Proprietário
                </Typography>
                <Autocomplete
                  fullWidth
                  disabled
                  name="nome"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  value={empresa}
                  className="unidadesId-form"
                  options={empresas || []}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setEmpresa({ id: null, descricao: "" });
                      return;
                    } else {
                      setEmpresa(value);
                      return value;
                    }
                  }}
                />
              </Box>
              <Box sx={{ m: 2, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Modelo
                </Typography>
                <TextField
                  disabled
                  name="modelo"
                  size="small"
                  variant="outlined"
                  value={viaturaSel.modelo}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 2, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Marca
                </Typography>
                <TextField
                  disabled
                  name="marca"
                  size="small"
                  variant="outlined"
                  value={viaturaSel.marca}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  m: 2,
                  width: "46.5%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "46.5%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Nº Apólice
                  </Typography>
                  <TextField
                    disabled
                    name="n_apolice"
                    size="small"
                    variant="outlined"
                    value={viaturaSel.n_apolice}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
                <Box sx={{ width: "46.5%" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptLocale}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      Data de Validade Apólice
                    </Typography>
                    <DatePicker
                      disabled
                      margin="normal"
                      id="date-picker-dialog"
                      format="dd/MM/yyyy"
                      okLabel="ok"
                      cancelLabel="cancelar"
                      invalidDateMessage="Data Inválida"
                      value={dataValidadeApolice}
                      onChange={handleDataValidadeApoliceChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "dd/mm/aaaa",
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          variant="outlined"
                          size="small"
                          style={{ width: "100%" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box
                sx={{
                  m: 2,
                  width: "46.5%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "46.5%" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptLocale}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      Data da Inspeção
                    </Typography>
                    <DatePicker
                      disabled
                      margin="normal"
                      id="date-picker-dialog"
                      format="dd/MM/yyyy"
                      okLabel="ok"
                      cancelLabel="cancelar"
                      invalidDateMessage="Data Inválida"
                      value={dataInspecao}
                      onChange={handleDataInspecaoChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "dd/mm/aaaa",
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          variant="outlined"
                          size="small"
                          style={{ width: "100%" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Box sx={{ width: "46.5%" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptLocale}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      Data da Próxima Inspeção
                    </Typography>
                    <DatePicker
                      disabled
                      margin="normal"
                      id="date-picker-dialog"
                      format="dd/MM/yyyy"
                      okLabel="ok"
                      cancelLabel="cancelar"
                      invalidDateMessage="Data Inválida"
                      value={dataProxInspecao}
                      onChange={handleDataProxInspecaoChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "dd/mm/aaaa",
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          variant="outlined"
                          size="small"
                          style={{ width: "100%" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            </Box>
            <Box sx={{ m: 2 }}>
              <h4
                style={{ color: "#E21450" }}
                className="text-underline-title-drawer "
              >
                Entidade Requisitante
              </h4>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  m: 2,
                  width: "46.5%",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Nome
                </Typography>
                <TextField
                  disabled
                  size="small"
                  variant="outlined"
                  value={viaturaSel.requisitante}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box
                sx={{
                  m: 2,
                  width: "46.5%",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Renovação do dístico nº
                </Typography>
                <TextField
                  disabled
                  name="renovacao_distico_n"
                  size="small"
                  variant="outlined"
                  value={viaturaSel.n_distico}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            {aeroporto.id == 2 && (
              <>
                <Box sx={{ m: 2 }}>
                  <h4
                    style={{ color: "#E21450" }}
                    className="text-underline-title-drawer "
                  >
                    Áreas de acesso / circulação
                  </h4>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      m: 2,
                      width: "30%",
                    }}
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Pistas
                    </Typography>
                    <TextField
                      name="pistas"
                      size="small"
                      variant="outlined"
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box
                    sx={{
                      m: 2,
                      width: "30%",
                    }}
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Plataformas
                    </Typography>
                    <TextField
                      name="plataformas"
                      size="small"
                      variant="outlined"
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box
                    sx={{
                      m: 2,
                      width: "30%",
                    }}
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Outros
                    </Typography>
                    <TextField
                      name="outros"
                      size="small"
                      variant="outlined"
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      autoComplete="off"
                    />
                  </Box>
                </Box>
              </>
            )}
            {/* <Box sx={{ m: 2 }}>
              <h4
                style={{ color: "#E21450" }}
                className="text-underline-title-drawer "
              >
                Documentos
              </h4>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                m: 2,
                width: "100%",
              }}
            >
              {ficheirosViaturasPermanentesState &&
                ficheirosViaturasPermanentesState.map((ficheiro, index) => (
                  <Box sx={{ width: "25%", my: 1 }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      {ficheiro.crede_elementos_files_tipos_nome}
                      {/* {ficheiro.id_crede_elementos_files_tipos != 1 &&
                      ficheiro.id_crede_elementos_files_tipos != 7
                        ? " *"
                        : null} */}
            {/* </Typography>
                    <Box sx={{ display: "flex" }}>
                      <Button
                        sx={{
                          height: "40px",
                          width: "200px",
                          borderRadius: "5px",
                          mr: 2,
                        }}
                        onChange={(e) => handleFilesChange(e, index, ficheiro)}
                        className={classes.btnUpload}
                      >
                        <label
                          htmlFor={
                            ficheiro.crede_elementos_files_tipos_nome_input
                          }
                          className="custom-file-upload"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              cursor: "pointer",
                            }}
                          >
                            <p>Carregar Ficheiro</p>
                          </div>
                        </label>
                        <input
                          type="file"
                          name={ficheiro.crede_elementos_files_tipos_nome_input}
                          id={ficheiro.crede_elementos_files_tipos_nome_input}
                          hidden
                          accept="image/jpeg, .pdf"
                          required={carAccess}
                        />
                      </Button>
                      <div style={{ width: "auto" }}>
                        {ficheiros[index] && (
                          <div
                            style={{
                              // marginRight: "10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                            key={index}
                          >
                            {/* {ficheiros[index].file.name} */}
            {/* <IconButton
                              onClick={() => {
                                const fileName = ficheiros[index].file.name;
                                const fileExtension = fileName.slice(
                                  (Math.max(0, fileName.lastIndexOf(".")) ||
                                    Infinity) + 1
                                );

                                if (fileExtension == "pdf") {
                                  const pdfFile = ficheiros[index].file;

                                  // Create a Blob from the PDF file
                                  const blob = new Blob([pdfFile], {
                                    type: "application/pdf",
                                  });

                                  // Create a URL for the Blob
                                  const blobUrl = URL.createObjectURL(blob);

                                  // Now you can use `blobUrl` to open or display the PDF
                                  window.open(blobUrl, "_blank");
                                } else {
                                  const blobUrl = URL.createObjectURL(
                                    ficheiros[index].file
                                  );

                                  // Now you can use `blobUrl` to open or display the PDF
                                  window.open(blobUrl, "_blank");
                                }
                              }}
                            >
                              <FeatherIcon icon="eye" size={"1rem"} />
                            </IconButton>
                            <IconButton
                              onClick={() => handleRemoveFiles(index)}
                            >
                              <FeatherIcon icon="trash-2" size={"1rem"} />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    </Box>
                    {ficheiro.id_crede_elementos_files_tipos == 10 &&
                      ficheiros[index] && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            mt: 1,
                          }}
                        >
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={ptLocale}
                          >
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              Data de Validade
                            </Typography>
                            <DatePicker
                              margin="normal"
                              id="date-picker-dialog"
                              format="dd/MM/yyyy"
                              okLabel="ok"
                              value={datePickerValues[index]}
                              onChange={(date) =>
                                handleDatePickerChange(date, index)
                              }
                              cancelLabel="cancelar"
                              invalidDateMessage="Data Inválida"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "dd/mm/aaaa",
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                  }}
                                  name="data_nascimento"
                                  sx={{ width: "200px" }}
                                  variant="outlined"
                                  size="small"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Box>
                      )}
                  </Box>
                ))}
            </Box>  */}
          </>
        ) : tipoCredSel.id == "2" ||
          tipoCredSel.id == "3" ||
          tipoCredSel.id == "5" ? (
          <>
            <Box
              sx={{
                m: 2,
              }}
            >
              <h4
                style={{
                  fontSize: "22px",
                  width: "100%",
                  marginBottom: "0px",
                }}
                className="text-underline"
              >
                Credenciação Pontual
              </h4>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              {tipoCredSel.id != 5 && (
                <Box sx={{ m: 2, width: "23%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Nome *
                  </Typography>
                  <Autocomplete
                    fullWidth
                    name="nome"
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    value={funcionario}
                    className="unidadesId-form"
                    options={funcionarios || []}
                    getOptionLabel={(option) => `${option.nome}` || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        setFuncionario({ id: null, nome: "" });
                        return;
                      } else {
                        setFuncionario(value);
                        dispatch(GetCredFuncionarioById(value.id));
                        dispatch(GetCredAeroportosByFuncId(value.id));
                        return value;
                      }
                    }}
                  />
                </Box>
              )}
              <Box sx={{ m: 2, width: tipoCredSel.id != 5 ? "20%" : "30%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Estado *
                </Typography>
                <Autocomplete
                  fullWidth
                  name="nome"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  value={estado}
                  className="unidadesId-form"
                  options={!superuser ? estadosFiltered : estados}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setEstado({ id: null, descricao: "" });
                      return;
                    } else {
                      setEstado(value);
                      return value;
                    }
                  }}
                />
              </Box>
              {tipoCredSel.id != 5 && (
                <Box sx={{ m: 2, width: tipoCredSel.id != 5 ? "20%" : "30%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Projeto
                  </Typography>
                  <Autocomplete
                    fullWidth
                    name="nome"
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    value={projeto}
                    className="unidadesId-form"
                    options={obrasState || []}
                    getOptionLabel={(option) => `${option.descricao}` || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        setProjeto({ id: null, descricao: "" });
                        return;
                      } else {
                        setProjeto(value);
                        return value;
                      }
                    }}
                  />
                </Box>
              )}
              {tipoCredSel.id != 5 && (
                <Box sx={{ m: 2, width: tipoCredSel.id != 5 ? "30%" : "30%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Descrição dos trabalhos
                  </Typography>
                  <TextField
                    name="desc_trab"
                    size="small"
                    variant="outlined"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
              )}
            </Box>
            {estado.descricao == "Recusada" && (
              <Box
                sx={{
                  mx: 2,
                  display: "flex",
                  width: "46.5%",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Motivo de Recusa
                </Typography>
                <TextField
                  name="motivo_recusa"
                  multiline
                  rows={2}
                  size="small"
                  variant="outlined"
                  value={credenciacao.motivo_recusa}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            )}
            {funcionario.id ? (
              <>
                <Box
                  sx={{
                    mb: 2,
                    mx: 2,
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <h4
                      style={{ color: "#E21450" }}
                      className="text-underline-title-drawer "
                    >
                      Funcionário Info
                    </h4>
                  </Box>
                  <Box>
                    <h4
                      onClick={() => setOpen(!open)}
                      className="text-underline-title-drawer"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {open ? (
                        <FeatherIcon
                          icon="chevron-up"
                          strokeWidth={2}
                          color="#707070"
                          sx={{ pt: 1 }}
                        />
                      ) : (
                        <FeatherIcon
                          icon="chevron-down"
                          strokeWidth={2}
                          color="#707070"
                          sx={{ pt: 1 }}
                        />
                      )}
                    </h4>
                  </Box>
                </Box>
                <Collapse in={open}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mx: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "48%",
                      }}
                    >
                      <Box sx={{ m: 1 }}>
                        <Typography
                          style={{ fontWeight: "bold", marginBottom: "10px" }}
                        >
                          Nome Completo
                        </Typography>
                        <TextField
                          disabled
                          name="nome"
                          size="small"
                          variant="outlined"
                          value={funcionarioSel.nome}
                          onChange={handleChange}
                          style={{ width: "100%" }}
                          autoComplete="off"
                        />
                      </Box>
                      <Box sx={{ m: 1 }}>
                        <Typography
                          style={{ fontWeight: "bold", marginBottom: "10px" }}
                        >
                          Morada
                        </Typography>
                        <TextField
                          disabled
                          multiline
                          rows={2}
                          name="morada"
                          size="small"
                          variant="outlined"
                          value={funcionarioSel.morada}
                          onChange={handleChange}
                          style={{ width: "100%" }}
                          className={
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                            1
                              ? classes.textField
                              : null
                          }
                          InputProps={{
                            readOnly: true, // Make the TextField read-only
                            classes: {
                              root:
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                1
                                  ? classes.disabledTextField
                                  : null, // Apply the disabledTextField class to the root element
                              input:
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                1
                                  ? classes.blurredText
                                  : null, // Apply the blurred text class to the input element
                            },
                          }}
                          autoComplete="off"
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ m: 1, width: "46.5%" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Localidade
                          </Typography>
                          <TextField
                            disabled
                            name="localidade"
                            size="small"
                            variant="outlined"
                            value={funcionarioSel.localidade}
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            className={
                              funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                                ? classes.textField
                                : null
                            }
                            InputProps={{
                              readOnly: true, // Make the TextField read-only
                              classes: {
                                root:
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.disabledTextField
                                    : null, // Apply the disabledTextField class to the root element
                                input:
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.blurredText
                                    : null, // Apply the blurred text class to the input element
                              },
                            }}
                            autoComplete="off"
                          />
                        </Box>
                        <Box sx={{ m: 1, width: "46.5%" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Código Postal
                          </Typography>
                          <TextField
                            disabled
                            name="cp"
                            size="small"
                            variant="outlined"
                            value={funcionarioSel.cp}
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            className={
                              funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                                ? classes.textField
                                : null
                            }
                            InputProps={{
                              readOnly: true, // Make the TextField read-only
                              classes: {
                                root:
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.disabledTextField
                                    : null, // Apply the disabledTextField class to the root element
                                input:
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.blurredText
                                    : null, // Apply the blurred text class to the input element
                              },
                            }}
                            autoComplete="off"
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ m: 1, width: "46.5%" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Telefone
                          </Typography>
                          <TextField
                            disabled
                            name="telefone"
                            size="small"
                            variant="outlined"
                            value={funcionarioSel.telefone}
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            className={
                              funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                                ? classes.textField
                                : null
                            }
                            InputProps={{
                              readOnly: true, // Make the TextField read-only
                              classes: {
                                root:
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.disabledTextField
                                    : null, // Apply the disabledTextField class to the root element
                                input:
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.blurredText
                                    : null, // Apply the blurred text class to the input element
                              },
                            }}
                            autoComplete="off"
                          />
                        </Box>
                        <Box sx={{ m: 1, width: "46.5%" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Telemóvel
                          </Typography>
                          <TextField
                            disabled
                            name="telemovel"
                            size="small"
                            variant="outlined"
                            value={funcionarioSel.telemovel}
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            className={
                              funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                                ? classes.textField
                                : null
                            }
                            InputProps={{
                              readOnly: true, // Make the TextField read-only
                              classes: {
                                root:
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.disabledTextField
                                    : null, // Apply the disabledTextField class to the root element
                                input:
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.blurredText
                                    : null, // Apply the blurred text class to the input element
                              },
                            }}
                            autoComplete="off"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "48%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ m: 1, width: "46.5%" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Nacionalidade
                          </Typography>
                          <TextField
                            disabled
                            name="nacionalidade"
                            size="small"
                            variant="outlined"
                            value={funcionarioSel.nacionalidade}
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            className={
                              funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                                ? classes.textField
                                : null
                            }
                            InputProps={{
                              readOnly: true, // Make the TextField read-only
                              classes: {
                                root:
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.disabledTextField
                                    : null, // Apply the disabledTextField class to the root element
                                input:
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.blurredText
                                    : null, // Apply the blurred text class to the input element
                              },
                            }}
                            autoComplete="off"
                          />
                        </Box>
                        <Box sx={{ m: 1, width: "46.5%" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Entidade Patronal
                          </Typography>
                          <Autocomplete
                            disabled
                            fullWidth
                            clearText="Limpar"
                            openText="Abrir"
                            closeText="Fechar"
                            noOptionsText="Sem dados"
                            options={empresas || []}
                            value={empresa}
                            getOptionLabel={(option) =>
                              `${option.descricao}` || ""
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                className={
                                  funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                    ? classes.textField
                                    : null
                                }
                                InputProps={{
                                  readOnly: true, // Make the TextField read-only
                                  classes: {
                                    root:
                                      funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                      1
                                        ? classes.disabledTextField
                                        : null, // Apply the disabledTextField class to the root element
                                    input:
                                      funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                      1
                                        ? classes.blurredText
                                        : null, // Apply the blurred text class to the input element
                                  },
                                }}
                              />
                            )}
                            onChange={(_, value, reason) => {
                              if (reason === "clear") {
                                setEmpresa(null);
                                return;
                              } else {
                                setEmpresa(value);
                                return value;
                              }
                            }}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      ></Box>
                      <Box sx={{ m: 1 }}>
                        <h4
                          style={{ color: "#E21450" }}
                          className="text-underline-title-drawer "
                        >
                          Documento de Identificação
                        </h4>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box sx={{ m: 1, width: "46.5%" }}>
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  marginBottom: "10px",
                                }}
                              >
                                Tipo de Documento
                              </Typography>
                              <Autocomplete
                                disabled
                                fullWidth
                                clearText="Limpar"
                                openText="Abrir"
                                closeText="Fechar"
                                noOptionsText="Sem dados"
                                options={tipoDocs || []}
                                value={tipoDoc}
                                getOptionLabel={(option) =>
                                  `${option.descricao}` || ""
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                    className={
                                      funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                      1
                                        ? classes.textField
                                        : null
                                    }
                                    InputProps={{
                                      readOnly: true, // Make the TextField read-only
                                      classes: {
                                        root:
                                          funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                          1
                                            ? classes.disabledTextField
                                            : null, // Apply the disabledTextField class to the root element
                                        input:
                                          funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                          1
                                            ? classes.blurredText
                                            : null, // Apply the blurred text class to the input element
                                      },
                                    }}
                                  />
                                )}
                                onChange={(_, value, reason) => {
                                  if (reason === "clear") {
                                    setTipoDoc(null);
                                    return;
                                  } else {
                                    setTipoDoc(value);
                                    return value;
                                  }
                                }}
                              />
                            </Box>
                            <Box sx={{ m: 1, width: "46.5%" }}>
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  marginBottom: "10px",
                                }}
                              >
                                Nº Doc Identificação
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <TextField
                                  disabled
                                  name="doc_nr_identificacao"
                                  size="small"
                                  variant="outlined"
                                  value={funcionarioSel.doc_nr_identificacao}
                                  onChange={handleChange}
                                  style={{ width: "69%" }}
                                  className={
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.textField
                                      : null
                                  }
                                  InputProps={{
                                    readOnly: true, // Make the TextField read-only
                                    classes: {
                                      root:
                                        funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                        1
                                          ? classes.disabledTextField
                                          : null, // Apply the disabledTextField class to the root element
                                      input:
                                        funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                        1
                                          ? classes.blurredText
                                          : null, // Apply the blurred text class to the input element
                                    },
                                  }}
                                  autoComplete="off"
                                />
                                <TextField
                                  disabled
                                  name="doc_nr_identificacao_controlo"
                                  size="small"
                                  variant="outlined"
                                  value={
                                    funcionarioSel.doc_nr_identificacao_controlo
                                  }
                                  onChange={handleChange}
                                  style={{ width: "29%" }}
                                  className={
                                    funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                    1
                                      ? classes.textField
                                      : null
                                  }
                                  InputProps={{
                                    readOnly: true, // Make the TextField read-only
                                    classes: {
                                      root:
                                        funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                        1
                                          ? classes.disabledTextField
                                          : null, // Apply the disabledTextField class to the root element
                                      input:
                                        funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                        1
                                          ? classes.blurredText
                                          : null, // Apply the blurred text class to the input element
                                    },
                                  }}
                                  autoComplete="off"
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "45%",
                                  m: 1,
                                }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                  locale={ptLocale}
                                >
                                  <Typography
                                    style={{
                                      fontWeight: "bold",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Data de Validade
                                  </Typography>
                                  <DatePicker
                                    disabled
                                    margin="normal"
                                    id="date-picker-dialog"
                                    format="dd/MM/yyyy"
                                    okLabel="ok"
                                    cancelLabel="cancelar"
                                    invalidDateMessage="Data Inválida"
                                    value={dataValidade}
                                    onChange={handleDataValidadeChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                          placeholder: "dd/mm/aaaa",
                                        }}
                                        InputProps={{
                                          ...params.InputProps,
                                          disableUnderline: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                        className={
                                          funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                          1
                                            ? classes.textField
                                            : null
                                        }
                                      />
                                    )}
                                    InputProps={{
                                      readOnly: true, // Make the TextField read-only
                                      classes: {
                                        root:
                                          funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                          1
                                            ? classes.disabledTextField
                                            : null, // Apply the disabledTextField class to the root element
                                        input:
                                          funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                          1
                                            ? classes.blurredText
                                            : null, // Apply the blurred text class to the input element
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex-end",
                                  flexDirection: "column",
                                  width: "45%",
                                  m: 1,
                                }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                  locale={ptLocale}
                                >
                                  <Typography
                                    style={{
                                      fontWeight: "bold",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Data de Emissão
                                  </Typography>
                                  <DatePicker
                                    disabled
                                    margin="normal"
                                    id="date-picker-dialog"
                                    format="dd/MM/yyyy"
                                    okLabel="ok"
                                    cancelLabel="cancelar"
                                    invalidDateMessage="Data Inválida"
                                    value={dataEmissao}
                                    onChange={handleDataEmissaoChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                          placeholder: "dd/mm/aaaa",
                                        }}
                                        InputProps={{
                                          ...params.InputProps,
                                          disableUnderline: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                        className={
                                          funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                          1
                                            ? classes.textField
                                            : null
                                        }
                                      />
                                    )}
                                    InputProps={{
                                      readOnly: true, // Make the TextField read-only
                                      classes: {
                                        root:
                                          funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                          1
                                            ? classes.disabledTextField
                                            : null, // Apply the disabledTextField class to the root element
                                        input:
                                          funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                          1
                                            ? classes.blurredText
                                            : null, // Apply the blurred text class to the input element
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                m: 1,
                                width: "46.5%",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex-end",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    marginBottom: "10px",
                                  }}
                                >
                                  Vitalicio
                                </Typography>
                                <Box>
                                  <Checkbox
                                    disabled
                                    checked={vitalicio}
                                    onChange={() => setVitalicio(!vitalicio)}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Collapse>
              </>
            ) : null}
            <Box sx={{ m: 2 }}>
              <h4
                style={{ color: "#E21450" }}
                className="text-underline-title-drawer "
              >
                Entidade Requisitante
              </h4>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ m: 1, width: "48%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Acompanhante
                    </Typography>
                    <Autocomplete
                      fullWidth
                      name="nome"
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      noOptionsText="Sem dados"
                      value={acompanhante}
                      className="unidadesId-form"
                      options={filteredAcompanhantes || []}
                      getOptionLabel={(option) =>
                        `${option.numero} - ${option.nome}` || ""
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                        />
                      )}
                      onChange={(_, value, reason) => {
                        if (reason === "clear") {
                          setAcompanhante({
                            id: null,
                            nome: "",
                            aeroporto: "",
                            numero: "",
                          });
                          return;
                        } else {
                          setAcompanhante(value);
                          dispatch(GetCredAcompanhanteById(value.id));
                          console.log(tipoCredSel.id);
                          if (tipoCredSel.id == 5) {
                            dispatch(
                              GetCredAeroportosByFuncId(
                                acompanhanteSelecionado.id_crede_elementos
                              )
                            );
                          }
                          return value;
                        }
                      }}
                    />
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        m: 1,
                        display: "flex",
                        width: "46.5%",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Cópia Cred. Externa
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        {uploadedCopia && (
                          <Button
                            sx={{
                              height: "40px",
                              borderRadius: "5px",
                              mr: 1,
                            }}
                            className={classes.btnUpload}
                            onClick={() => {
                              Instance()
                                .get(
                                  `${BASE_URL}/credenciacoes/funcionarios/files/${uploadedCopia.id_crede_elementos_files}/download`,
                                  { responseType: "blob" }
                                )
                                .then(({ data }) => {
                                  const filename =
                                    uploadedCopia.crede_elementos_files_path.replace(
                                      /^.*[\\/]/,
                                      ""
                                    );
                                  fileDownload(data, filename);
                                })
                                .catch((err) => {
                                  console.log(err.response);
                                  if (err.response) {
                                    if (err.response === 403) {
                                      // dispatch(ShowNotification(errorMessage));
                                    }
                                  }
                                });
                            }}
                          >
                            <FeatherIcon icon="download" size={20} />
                          </Button>
                        )}
                        <Button
                          sx={{
                            height: "40px",
                            width: "200px",
                            borderRadius: "5px",
                          }}
                          onChange={(e) => handleCopiaExternaChange(e)}
                          className={classes.btnUpload}
                        >
                          <label
                            htmlFor={"copiaExterna"}
                            className="custom-file-upload"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                cursor: "pointer",
                              }}
                            >
                              <p> Carregar Ficheiro</p>
                            </div>
                          </label>
                          <input
                            type="file"
                            name={"copiaExterna"}
                            id={"copiaExterna"}
                            hidden
                            accept=".pdf"
                          />
                        </Button>
                        <div style={{ width: "auto" }}>
                          {copiaCredenciacaoExterna &&
                            copiaCredenciacaoExterna.map(
                              (copiaCredenciacaoExterna, index) => {
                                return (
                                  <div
                                    style={{
                                      // marginRight: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    key={index}
                                  >
                                    {/* {copiaCredenciacao.file.name} */}
                                    <IconButton
                                      onClick={() => {
                                        const fileName =
                                          copiaCredenciacaoExterna.file.name;
                                        const fileExtension = fileName.slice(
                                          (Math.max(
                                            0,
                                            fileName.lastIndexOf(".")
                                          ) || Infinity) + 1
                                        );

                                        if (fileExtension == "pdf") {
                                          const pdfFile =
                                            copiaCredenciacaoExterna.file;

                                          // Create a Blob from the PDF file
                                          const blob = new Blob([pdfFile], {
                                            type: "application/pdf",
                                          });

                                          // Create a URL for the Blob
                                          const blobUrl =
                                            URL.createObjectURL(blob);

                                          // Now you can use `blobUrl` to open or display the PDF
                                          window.open(blobUrl, "_blank");
                                        } else {
                                          const blobUrl = URL.createObjectURL(
                                            copiaCredenciacaoExterna.file
                                          );

                                          // Now you can use `blobUrl` to open or display the PDF
                                          window.open(blobUrl, "_blank");
                                        }
                                      }}
                                    >
                                      <FeatherIcon icon="eye" size={"1rem"} />
                                    </IconButton>
                                    <IconButton
                                      onClick={(e) =>
                                        handleRemoveCopiaExterna(
                                          e,
                                          copiaCredenciacaoExterna
                                        )
                                      }
                                    >
                                      <FeatherIcon
                                        icon="trash-2"
                                        size={"1rem"}
                                      />
                                    </IconButton>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        m: 1,
                        display: "flex",
                        width: "46.5%",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Nº Cartão Externo
                      </Typography>
                      <TextField
                        name="numero_cred_externa"
                        size="small"
                        variant="outlined"
                        value={credenciacao.numero_cred_externa}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        autoComplete="off"
                      />
                    </Box>
                  </Box>
                </Box>
                {acompanhante.id && (
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Entidade
                      </Typography>
                      <TextField
                        disabled
                        size="small"
                        value={acompanhanteSelecionado.entidade}
                        sx={{ width: "100%" }}
                      />
                    </Box>
                    <Box sx={{ m: 1, width: "23%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Nº Cartão
                      </Typography>
                      <TextField
                        disabled
                        size="small"
                        value={
                          acompanhanteSelecionado.crede_elementos_credenciacao_numero
                        }
                        sx={{ width: "100%" }}
                      />
                    </Box>
                    <Box sx={{ m: 1, width: "23%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Validade
                      </Typography>
                      <TextField
                        disabled
                        size="small"
                        value={moment(
                          acompanhanteSelecionado.crede_elementos_credenciacao_validade_fim
                        ).format("DD-MM-YYYY")}
                        sx={{ width: "100%" }}
                      />
                    </Box>
                  </Box>
                )}
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ m: 1, width: "48%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Motivo *
                    </Typography>
                    <Autocomplete
                      fullWidth
                      name="nome"
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      noOptionsText="Sem dados"
                      value={motivo}
                      className="unidadesId-form"
                      options={motivos || []}
                      getOptionLabel={(option) => `${option.descricao}` || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                        />
                      )}
                      onChange={(_, value, reason) => {
                        if (reason === "clear") {
                          setMotivo({ id: null, nome: "" });
                          return;
                        } else {
                          setMotivo(value);
                          return value;
                        }
                      }}
                    />
                  </Box>
                  <Box sx={{ m: 1, width: "48%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Local/Aeroporto *
                    </Typography>
                    <Autocomplete
                      disabled={
                        tipoCredSel.id == 5
                          ? false
                          : funcionario.id != null
                          ? false
                          : true
                      }
                      fullWidth
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      noOptionsText="Sem dados"
                      options={aeroportos || []}
                      value={aeroporto}
                      getOptionLabel={(option) => `${option.descricao}` || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                        />
                      )}
                      onChange={(_, value, reason) => {
                        if (reason === "clear") {
                          setAeroporto({ id: null, descricao: "" });
                          return;
                        } else {
                          setAeroporto(value);
                          return value;
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Checkbox
                      checked={tools}
                      onChange={() => setTools(!tools)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>
                    Com Ferramentas
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Checkbox
                      checked={carAccess}
                      onChange={() => setCarAccess(!carAccess)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>
                    Acesso com Viatura
                  </Typography>
                </Box>
                {carAccess && (
                  <>
                    <Box
                      sx={{
                        m: 1,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ width: "49%" }}>
                        <Typography
                          style={{ fontWeight: "bold", marginBottom: "10px" }}
                        >
                          Matricula *
                        </Typography>
                        <Autocomplete
                          fullWidth
                          name="nome"
                          clearText="Limpar"
                          openText="Abrir"
                          closeText="Fechar"
                          noOptionsText="Sem dados"
                          value={veiculo}
                          className="unidadesId-form"
                          options={veiculos || []}
                          getOptionLabel={(option) =>
                            `${option.descricao}` || ""
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          style={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              variant="outlined"
                            />
                          )}
                          onChange={(_, value, reason) => {
                            if (reason === "clear") {
                              setVeiculo({ id: null, nome: "" });
                              return;
                            } else {
                              setVeiculo(value);
                              dispatch(GetCredVeiculosById(value.id));
                              return value;
                            }
                          }}
                        />
                      </Box>

                      <Box sx={{ width: "49%" }}>
                        <Typography
                          style={{ fontWeight: "bold", marginBottom: "10px" }}
                        >
                          Portaria de Acesso
                        </Typography>
                        <Autocomplete
                          fullWidth
                          clearText="Limpar"
                          openText="Abrir"
                          closeText="Fechar"
                          noOptionsText="Sem dados"
                          options={portariaAcesso || []}
                          value={portAcesso}
                          getOptionLabel={(option) =>
                            `${option.descricao}` || ""
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              variant="outlined"
                            />
                          )}
                          onChange={(_, value, reason) => {
                            if (reason === "clear") {
                              setPortAcesso({ id: null, descricao: "" });
                              return;
                            } else {
                              setPortAcesso(value);
                              return value;
                            }
                          }}
                        />
                      </Box>
                    </Box>

                    {/* <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        m: 1,
                        width: "100%",
                      }}
                    >
                      {ficheirosViaturasState &&
                        ficheirosViaturasState.map((ficheiro, index) => (
                          <Box sx={{ width: "50%" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              {ficheiro.crede_elementos_files_tipos_nome} */}
                    {/* {ficheiro.id_crede_elementos_files_tipos != 1 &&
                              ficheiro.id_crede_elementos_files_tipos != 7
                                ? " *"
                                : null} */}
                    {/* </Typography>
                            <Box sx={{ display: "flex" }}>
                              <Button
                                sx={{
                                  height: "40px",
                                  width: "200px",
                                  borderRadius: "5px",
                                  mr: 2,
                                }}
                                onChange={(e) =>
                                  handleFilesChange(e, index, ficheiro)
                                }
                                className={classes.btnUpload}
                              >
                                <label
                                  htmlFor={
                                    ficheiro.crede_elementos_files_tipos_nome_input
                                  }
                                  className="custom-file-upload"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "start",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <p>Carregar Ficheiro</p>
                                  </div>
                                </label>
                                <input
                                  type="file"
                                  name={
                                    ficheiro.crede_elementos_files_tipos_nome_input
                                  }
                                  id={
                                    ficheiro.crede_elementos_files_tipos_nome_input
                                  }
                                  hidden
                                  accept="image/jpeg, .pdf"
                                  required={carAccess}
                                />
                              </Button>
                              <div style={{ width: "auto" }}>
                                {ficheiros[index] && (
                                  <div
                                    style={{
                                      // marginRight: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    key={index}
                                  >
                                    {/* {ficheiros[index].file.name} */}
                    {/* <IconButton
                                      onClick={() => {
                                        const fileName =
                                          ficheiros[index].file.name;
                                        const fileExtension = fileName.slice(
                                          (Math.max(
                                            0,
                                            fileName.lastIndexOf(".")
                                          ) || Infinity) + 1
                                        );

                                        if (fileExtension == "pdf") {
                                          const pdfFile = ficheiros[index].file;

                                          // Create a Blob from the PDF file
                                          const blob = new Blob([pdfFile], {
                                            type: "application/pdf",
                                          });

                                          // Create a URL for the Blob
                                          const blobUrl =
                                            URL.createObjectURL(blob);

                                          // Now you can use `blobUrl` to open or display the PDF
                                          window.open(blobUrl, "_blank");
                                        } else {
                                          const blobUrl = URL.createObjectURL(
                                            ficheiros[index].file
                                          );

                                          // Now you can use `blobUrl` to open or display the PDF
                                          window.open(blobUrl, "_blank");
                                        }
                                      }}
                                    >
                                      <FeatherIcon icon="eye" size={"1rem"} />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => handleRemoveFiles(index)}
                                    >
                                      <FeatherIcon
                                        icon="trash-2"
                                        size={"1rem"}
                                      />
                                    </IconButton>
                                  </div>
                                )}
                              </div>
                            </Box>
                            {ficheiro.id_crede_elementos_files_tipos == 10 &&
                              ficheiros[index] && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    mt: 1,
                                  }}
                                >
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={ptLocale}
                                  >
                                    <Typography
                                      style={{
                                        fontWeight: "bold",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Data de Validade
                                    </Typography>
                                    <DatePicker
                                      margin="normal"
                                      id="date-picker-dialog"
                                      format="dd/MM/yyyy"
                                      okLabel="ok"
                                      value={datePickerValues[index]}
                                      onChange={(date) =>
                                        handleDatePickerChange(date, index)
                                      }
                                      cancelLabel="cancelar"
                                      invalidDateMessage="Data Inválida"
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          inputProps={{
                                            ...params.inputProps,
                                            placeholder: "dd/mm/aaaa",
                                          }}
                                          InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                          }}
                                          name="data_nascimento"
                                          sx={{ width: "200px" }}
                                          variant="outlined"
                                          size="small"
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Box>
                              )}
                          </Box>
                        ))}
                    </Box> */}
                  </>
                )}
                {aeroporto.id == 1314 && (
                  <Box sx={{ m: 1 }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Destinatários
                    </Typography>
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      fullWidth
                      name="destinatarios"
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      noOptionsText="Sem dados"
                      value={destinatarios}
                      className="unidadesId-form"
                      options={destinatariosOptions || []}
                      getOptionLabel={(option) => (
                        <>
                          {option.descricao}
                          <br />
                          {option.email}
                        </>
                      )}
                      filterOptions={filterOptions}
                      isOptionEqualToValue={(option, value) => {
                        // if (!value.id) return true;
                        return value.id === option.id;
                      }}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                        />
                      )}
                      onChange={(_, value, reason) => {
                        if (reason === "clear") {
                          setDestinatarios([]);
                          return;
                        } else {
                          setDestinatarios(value);
                          return value;
                        }
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptLocale}
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Validade de acesso
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <Button
                        variant="outlined"
                        onClick={() => setOpenDates(!open)}
                        sx={{ mr: 2, minWidth: "200px", height: "40px" }}
                      >
                        Selecionar datas
                      </Button>
                      <MultipleDatesPicker
                        open={openDates}
                        selectedDates={datasSelected}
                        onCancel={() => setOpenDates(false)}
                        onSubmit={(dates) => {
                          const warningMessage = {
                            isOpen: true,
                            message: "Selecione datas não inferiores à de hoje",
                            type: "warning",
                          };

                          const warningMaxMessage = (max) => {
                            return {
                              isOpen: true,
                              message: `Selecione no máximo de ${max} dias consecutivos`,
                              type: "warning",
                            };
                          };

                          const warningMessageToday = {
                            isOpen: true,
                            message:
                              "Data atual selecionada, de preferência peça com 24h de antecedência",
                            type: "warning",
                          };

                          const isBeforeToday = dates.some((date) => {
                            const todayDate = new Date(
                              today.getFullYear(),
                              today.getMonth(),
                              today.getDate()
                            );
                            const checkDate = new Date(
                              date.getFullYear(),
                              date.getMonth(),
                              date.getDate()
                            );

                            return checkDate < todayDate;
                          });

                          const isToday = dates.some((date) => {
                            const todayDate = new Date(
                              today.getFullYear(),
                              today.getMonth(),
                              today.getDate()
                            );
                            const checkDate = new Date(
                              date.getFullYear(),
                              date.getMonth(),
                              date.getDate()
                            );

                            return checkDate.getTime() == todayDate.getTime();
                          });
                          if (isBeforeToday) {
                            dispatch(ShowNotification(warningMessage));
                          } else if (
                            !checkConsecutiveDates(aeroporto.id, dates)
                          ) {
                            const maxConsecutiveDays =
                              aeroporto.id === 1313 ? 3 : 5;
                            dispatch(
                              ShowNotification(
                                warningMaxMessage(maxConsecutiveDays)
                              )
                            );
                          } else if (isToday) {
                            dispatch(ShowNotification(warningMessageToday));
                            dispatch(
                              GetCredAcompanhantesDisponibilidade(dates)
                            );
                            handleDatesChange(dates);
                            setOpenDates(false);
                          } else {
                            dispatch(
                              GetCredAcompanhantesDisponibilidade(dates)
                            );
                            handleDatesChange(dates);
                            setOpenDates(false);
                          }
                        }}
                        cancelButtonText="Cancelar"
                        submitButtonText="Confirmar"
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {datasSelected.map((date) => {
                          return (
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                display: "flex",
                                alignItems: "center",
                                mx: 1,
                              }}
                            >
                              {moment(date).format("DD-MM-YYYY")}
                            </Typography>
                          );
                        })}
                      </Box>
                    </Box>
                  </LocalizationProvider>
                </Box>
                <Box sx={{ m: 1 }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Área de acesso pretendido
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaA}
                          onChange={() => setAreaA(!areaA)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>A</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaB}
                          onChange={() => setAreaB(!areaB)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>B</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaC}
                          onChange={() => setAreaC(!areaC)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>C</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaE}
                          onChange={() => setAreaE(!areaE)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>E</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaI}
                          onChange={() => setAreaI(!areaI)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>I</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaL}
                          onChange={() => setAreaL(!areaL)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>L</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaM}
                          onChange={() => setAreaM(!areaM)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>M</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaO}
                          onChange={() => setAreaO(!areaO)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>O</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaP}
                          onChange={() => setAreaP(!areaP)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>P</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        my: 1,
                        alignItems: "center",
                        width: "8%",
                      }}
                    >
                      <Box>
                        <Checkbox
                          checked={areaT}
                          onChange={() => setAreaT(!areaT)}
                        />
                      </Box>
                      <Typography style={{ fontWeight: "bold" }}>T</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ m: 1 }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Observações
                  </Typography>
                  <TextField
                    name="obs"
                    multiline
                    rows={3}
                    size="small"
                    variant="outlined"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
              </Box>
            </Box>
          </>
        ) : null}
        {/* <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Cartão de acesso
          </h4>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "48%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Nº Pedido no Sistema Externo *
                </Typography>
                <TextField
                  name="pedido_nr"
                  size="small"
                  variant="outlined"
                  onChange={handleChange}
                  style={{ width: "100%" }}
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Local/Aeroporto *
                </Typography>
                <Autocomplete
                  disabled={funcionario.id != null ? false : true}
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={aeroportos || []}
                  value={aeroporto}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setAeroporto({ id: null, descricao: "" });
                      console.log(aeroporto);
                    } else {
                      setAeroporto(value);
                    }
                    return value;
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Tipo de Cartão Solicitado *
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={tipoCartoes || []}
                  value={tipoCartao}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setTipoCartao({ id: null, descricao: "" });
                    } else {
                      setTipoCartao(value);
                    }
                    return value;
                  }}
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Função a desempenhar *
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={funcoes || []}
                  value={funcao}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setFuncao({ id: "0", descricao: "" });
                    } else {
                      setFuncao(value);
                    }
                    return value;
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Área de acesso pretendido
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      checked={areaA}
                      onChange={() => setAreaA(!areaA)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>A</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      checked={areaB}
                      onChange={() => setAreaB(!areaB)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>B</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      checked={areaC}
                      onChange={() => setAreaC(!areaC)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>C</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      checked={areaE}
                      onChange={() => setAreaE(!areaE)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>E</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      checked={areaI}
                      onChange={() => setAreaI(!areaI)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>I</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      checked={areaL}
                      onChange={() => setAreaL(!areaL)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>L</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      checked={areaM}
                      onChange={() => setAreaM(!areaM)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>M</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      checked={areaO}
                      onChange={() => setAreaO(!areaO)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>O</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      checked={areaP}
                      onChange={() => setAreaP(!areaP)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>P</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      checked={areaT}
                      onChange={() => setAreaT(!areaT)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>T</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Inscrição adicional de acesso
              </Typography>
              <TextField
                name="inscricao_adicional"
                size="small"
                variant="outlined"
                onChange={handleChange}
                style={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Observações
              </Typography>
              <TextField
                name="obs"
                multiline
                rows={3}
                size="small"
                variant="outlined"
                onChange={handleChange}
                style={{ width: "100%" }}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "48%" }}>
            <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={ptLocale}
              >
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Validade de acesso
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <DatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    okLabel="ok"
                    cancelLabel="cancelar"
                    invalidDateMessage="Data Inválida"
                    value={acessoInicial}
                    onChange={handleAcessoInicialChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        sx={{ width: "200px" }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      mx: 2,
                    }}
                  >
                    até
                  </Typography>
                  <DatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    okLabel="ok"
                    cancelLabel="cancelar"
                    invalidDateMessage="Data Inválida"
                    value={acessoFinal}
                    onChange={handleAcessoFinalChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        sx={{ width: "200px" }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </Box>
              </LocalizationProvider>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Horário de acesso
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <TimePicker
                    format="hh:mm"
                    defaultValue
                    ampm={false}
                    value={horasInicial}
                    onChange={handleHorasInicialChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "hh:mm",
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        sx={{ width: "200px" }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      mx: 2,
                    }}
                  >
                    até
                  </Typography>
                  <TimePicker
                    format="hh:mm"
                    ampm={false}
                    value={horasFinal}
                    onChange={handleHorasFinalChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "hh:mm",
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        sx={{ width: "200px" }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </Box>
              </LocalizationProvider>
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Cor *
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={cores || []}
                value={cor}
                getOptionLabel={(option) => `${option.descricao}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setCor(null);
                    return;
                  } else {
                    setCor(value);
                    return value;
                  }
                }}
              />
            </Box>
            <Box sx={{ display: "flex", m: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography sx={{ fontWeight: "bold", width: "50%" }}>
                  Renovação do Cartão
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}
                >
                  <Checkbox
                    checked={renovacao}
                    onChange={() => setRenovacao(!renovacao)}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", m: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography style={{ fontWeight: "bold", width: "50%" }}>
                  Pedido de 2ª Via
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}
                >
                  <Checkbox
                    checked={pedido}
                    onChange={() => setPedido(!pedido)}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", m: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography style={{ fontWeight: "bold", width: "50%" }}>
                  Pedido de Alteração de Áreas
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}
                >
                  <Checkbox
                    checked={alteracao}
                    onChange={() => setAlteracao(!alteracao)}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Tipo de Vinculo Laboral *
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={tipoVinculos || []}
                value={tipoVinculo}
                getOptionLabel={(option) => `${option.descricao}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setTipoVinculo(null);
                    return;
                  } else {
                    setTipoVinculo(value);
                    return value;
                  }
                }}
              />
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Inicio do Vinculo Laboral
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <DatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      format="dd/MM/yyyy"
                      okLabel="ok"
                      cancelLabel="cancelar"
                      invalidDateMessage="Data Inválida"
                      value={vinculoInicial}
                      onChange={handleVinculoInicialChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "dd/mm/aaaa",
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          sx={{ width: "200px" }}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </Box>
                </LocalizationProvider>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Fim do Vinculo Laboral
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <DatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      format="dd/MM/yyyy"
                      okLabel="ok"
                      cancelLabel="cancelar"
                      invalidDateMessage="Data Inválida"
                      value={vinculoFinal}
                      onChange={handleVinculoFinalChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "dd/mm/aaaa",
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          sx={{ width: "200px" }}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </Box>
                </LocalizationProvider>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Dados Complementares
          </h4>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}>
          <Box
            sx={{
              display: "flex",
              width: "auto",
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                m: 1,
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Check Ins</Typography>
              <Checkbox
                checked={checkIn}
                onChange={() => setCheckIn(!checkIn)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                m: 1,
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                Porta Embarque
              </Typography>
              <Checkbox
                checked={portaEm}
                onChange={() => setPortaEm(!portaEm)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                m: 1,
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                Porta de desembarque
              </Typography>
              <Checkbox
                checked={portaDes}
                onChange={() => setPortaDes(!portaDes)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                m: 1,
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                Outras Portas
              </Typography>
              <Checkbox
                checked={outrasPortas}
                onChange={() => setOutrasPortas(!outrasPortas)}
              />
            </Box>
          </Box>
        </Box> */}
        {/* <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Inquerito Pessoal
          </h4>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: "flex", m: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    width: "50%",
                    whiteSpace: "nowrap",
                  }}
                >
                  Inquérito Pessoal Realizado com êxito?
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Checkbox
                    checked={inquerito}
                    onChange={() => setInquerito(!inquerito)}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ mx: 5, display: "flex", alignItems: "center" }}>
              <Button
                sx={{
                  height: "40px",
                  width: "300px",
                  borderRadius: "5px",
                  mr: 2,
                }}
                onChange={(e) => handleInqPessoalChange(e, inqPessoal)}
                className={classes.btnUpload}
              >
                <label
                  htmlFor={
                    inqPessoal.crede_elementos_files_tipos_nome_input
                  }
                  className="custom-file-upload"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      cursor: "pointer",
                    }}
                  >
                    <p> Carregar </p>
                  </div>
                </label>
                <input
                  type="file"
                  name={inqPessoal.crede_elementos_files_tipos_nome_input}
                  id={inqPessoal.crede_elementos_files_tipos_nome_input}
                  hidden
                  accept=".pdf"
                />
              </Button>
              <div style={{ width: "100%" }}>
                {inqueritoPessoal &&
                  inqueritoPessoal.map((inqueritoPessoal, index) => {
                    return (
                      <div
                        style={{
                          marginRight: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        key={index}
                      >
                        {inqueritoPessoal.file.name}
                        <IconButton
                          onClick={(e) =>
                            handleRemoveInquerito(e, inqueritoPessoal)
                          }
                        >
                          <FeatherIcon icon="trash-2" size={"1rem"} />
                        </IconButton>
                      </div>
                    );
                  })}
              </div>
            </Box>
          </Box>
        </Box> */}
      </Card>
    </div>
  );
};

export default FormAdd;
