/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Box,
  IconButton,
  Tooltip,
  Link,
  Breadcrumbs,
  Grid,
  Paper,
  useTheme,
} from "@mui/material";
import "moment/locale/pt";
import { format } from "date-fns";
import "./index.css";
import MUIDataTable from "mui-datatables";
import ReactApexChart from "react-apexcharts";
import FeatherIcon from "feather-icons-react";

// Components
import FooterLinks from "../../../../Global/Components/Footer";
import SideDrawer from "../Credenciacoes/components/drawer";

//Actions
import GetAllCredenciacoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetAllCredenciacoesAction";
import GetCredenciacaoById from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredenciacaoByIdAction";
import GetCredFuncionarios from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionariosAction";
import GetCredAeroportos from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredAeroportosAction";
import GetCredFuncoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncoesAction";
import GetCredCores from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredCoresAction";
import GetCredTipoCartoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoCartoesAction";
import GetCredTipoVinculos from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoVinculosAction";
import GetCredEstados from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredEstadosAction";
import GetCredEmpresas from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredEmpresasAction";
import GetCredHabilitacoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredHabilitacoesAction";
import GetCredTipoDocs from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoDocsAction";
import GetCredInqueritoPessoal from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredInqueritoPessoalAction";
import GetCredCopiaCredenciacao from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredCopiaCredenciacaoAction";
import GetCredTipoCredenciacoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoCredenciacoesAction";
import GetCredAcompanhantes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredAcompanhantesAction";
import GetCredMotivos from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredMotivosAction";
import GetCredTipoCombustiveis from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoCombustiveisAction";
import GetCredTipoVeiculos from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoVeiculosAction";
import GetCredPortariaAcesso from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredPortariaAcessoAction";
import GetCredViaturaFicheiros from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredViaturaFicheirosAction";
import GetCredViaturaFicheirosPermanente from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredViaturaFicheirosPermanenteAction";
import GetCredViaturaUploadFiles from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredViaturaUploadFilesAction";
import GetCredVeiculos from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredVeiculosAction";
import GetObras from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasAction";
import SendCredPontual from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/SendCredPontualAction";
import ShowNotification from "../../../../utils/redux/actions/global/notifications/NotificationAction";
import GetCredNotificacoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredNotificacoesAction";
import GetCredEstadosCount from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredEstadosCountActions";
import GetCredCredenciacoesByEstado from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredCredenciacoesByEstadoAction";
import GetCredFuncionarioById from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionarioByIdAction";
import GetCredViaturaUploadedFiles from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredViaturaUploadFilesAction";
import { useNavigate } from "react-router-dom";
import GetMediaDiasRespostaCredEstados from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetMediaDiasRespostaCredEstadosAction";
import GetTotalCredMes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetTotalCredenciacoesMesAction";
import moment from "moment";
import GetCredPermanentesValidades from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredPermanentesValidadesAction";
import Instance from "../../../../utils/Axios";
import BASE_URL from "../../../../utils/BASE_URL";
import GetAllCredDestinatarios from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetAllCredDestinatariosActions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
  },
  btnFiltrar: {
    color: "#E21450",
    width: "150px",
    height: "40px",
    fontWeight: "900",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  stateLabel: {
    textTransform: "none",
    transition: "0.2s",
    display: "flex",
    alignItems: "center",
  },

  paperFilters: {
    background: "transparent",
    padding: "20px 0px",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    height: 40,
    borderTop: "4px solid transparent",
    borderBottom: "4px solid transparent",

    "&:hover": {
      borderRight: "4px solid #E21450",
    },
  },
  container: {
    display: "flex",
  },
  chartColumn: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginRight: 20,
  },
  chartGroup: {
    flex: 5,
    display: "flex",
    flexDirection: "column",
  },
  chartCard: {
    flex: 1,
    marginBottom: 20,
    overflow: "hidden",
  },
  chart: {
    flex: 1,
  },
  tableColumn: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
  },
  tableCard: {
    flex: 1,
    marginBottom: 20,
  },
}));

const ColorStateCube = ({ color }) => {
  return (
    <div
      style={{
        height: "10px",
        width: "10px",
        backgroundColor: color,
        marginRight: "10px",
        borderRadius: "3px",
      }}
    />
  );
};

// ############################################################## //
// ####################  Window Dimensions  ##################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const DashboardCredenciacoes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles();
  const notificacoes = useSelector((state) => state.credenciais.notificacoes);
  const credenciacoes = useSelector(
    (state) => state.credenciais.credenciacoesByEstado
  );
  const estadosByCount = useSelector(
    (state) => state.credenciais.credEstadosCount
  );
  const mediaDiasCredEstados = useSelector(
    (state) => state.credenciais.mediaDiasRespostaCredEstados
  );

  // ############################################################## //
  // ######################### Médias Dias ######################## //
  // ############################################################## //

  const recASC = mediaDiasCredEstados.find(
    (m) => m.id_estado == 1 && m.id_aeroporto == 1313
  );
  const mediaRecASC = recASC ? JSON.stringify(recASC.media) : null;
  const recAHD = mediaDiasCredEstados.find(
    (m) => m.id_estado == 1 && m.id_aeroporto == 1314
  );
  const mediaRecAHD = recAHD ? JSON.stringify(recAHD.media) : null;
  const recAGC = mediaDiasCredEstados.find(
    (m) => m.id_estado == 1 && m.id_aeroporto == 1315
  );
  const mediaRecAGC = recAGC ? JSON.stringify(recAGC.media) : null;
  const psASC = mediaDiasCredEstados.find(
    (m) => m.id_estado == 2 && m.id_aeroporto == 1313
  );
  const mediaPSASC = psASC ? JSON.stringify(psASC.media) : null;
  const psAHD = mediaDiasCredEstados.find(
    (m) => m.id_estado == 2 && m.id_aeroporto == 1314
  );
  const mediaPSAHD = psAHD ? JSON.stringify(psAHD.media) : null;
  const psAGC = mediaDiasCredEstados.find(
    (m) => m.id_estado == 2 && m.id_aeroporto == 1315
  );
  const mediaPSAGC = psAGC ? JSON.stringify(psAGC.media) : null;

  // ############################################################## //
  // ######################### Credenciacao ####################### //
  // ############################################################## //
  const [credenciacao, setCredenciacao] = useState({
    pedido_nr: null,
    nr_cartao: null,
    motivo_recusa: null,
    area_acesso_a: null,
    area_acesso_b: null,
    area_acesso_c: null,
    area_acesso_e: null,
    area_acesso_i: null,
    area_acesso_l: null,
    area_acesso_m: null,
    area_acesso_o: null,
    area_acesso_p: null,
    area_acesso_t: null,
    inscricao_adicional: null,
    obs: null,
    validade_inicio: null,
    validade_fim: null,
    horario_inicio: null,
    horario_fim: null,
    renovar_cartao: null,
    pedido_segunda_via: null,
    pedido_alteracao_areas: null,
    vinculo_laboral_inicio: null,
    vinculo_laboral_fim: null,
    inquerito_pessoal_exito: null,
    porta_check_in: null,
    porta_embarque: null,
    porta_desembarque: null,
    porta_outras: null,
    numero_cred_externa: null,
    tools: null,
    car_access: null,
    pistas: null,
    plataformas: null,
    outros: null,
  });

  // ############################################################## //
  // ############################ Drawer ########################## //
  // ############################################################## //

  // ############################################################## //
  // ######################  Autocompletes  ####################### //
  // ############################################################## //

  const [empresa, setEmpresa] = useState({
    id: "0",
    descricao: "",
  });
  const [aeroporto, setAeroporto] = useState({
    id: "0",
    descricao: "",
  });
  const [estado, setEstado] = useState({
    id: "0",
    descricao: "",
  });
  const [tipoCred, setTipoCred] = useState({
    id: "0",
    descricao: "",
  });
  const [tipoCredenciacao, setTipoCredenciacao] = useState({
    id: null,
    descricao: "",
  });
  const [rascunho, setRascunho] = useState({
    id: "0",
    descricao: "",
  });

  const [drawerWidth, setDrawerWidth] = useState("90vw");
  const dimensions = useWindowDimensions();
  const [credenciacaoId, setCredenciacaoId] = useState();
  const aeroportos = useSelector((state) => state.credenciais.aeroportos);
  const funcoes = useSelector((state) => state.credenciais.funcoes);
  const tipoCartoes = useSelector((state) => state.credenciais.tipoCartoes);
  const tipoVinculos = useSelector((state) => state.credenciais.tipoVinculos);
  const tipoCombustiveis = useSelector(
    (state) => state.credenciais.tipoCombustiveis
  );
  const tipoVeiculos = useSelector((state) => state.credenciais.tipoVeiculos);
  const portariaAcesso = useSelector(
    (state) => state.credenciais.portariaAcesso
  );
  const obrasState = useSelector((state) => state.obras.obras);
  const cores = useSelector((state) => state.credenciais.cores);
  const funcionarios = useSelector((state) => state.credenciais.funcionarios);
  const acompanhantes = useSelector((state) => state.credenciais.acompanhantes);
  const destinatariosOptions = useSelector(
    (state) => state.credenciais.credDestinatarios
  );
  const motivos = useSelector((state) => state.credenciais.motivos);
  const estados = useSelector((state) => state.credenciais.estados);
  const veiculos = useSelector((state) => state.credenciais.veiculos);

  const empresas = useSelector((state) => state.credenciais.empresas);
  const habilitacoes = useSelector((state) => state.credenciais.habilitacoes);
  const tipoDocs = useSelector((state) => state.credenciais.tipoDocs);
  const tipoCreds = useSelector((state) => state.credenciais.tipoCredenciacoes);

  const inqPessoal = useSelector((state) => state.credenciais.inqueritoPessoal);
  const copCred = useSelector((state) => state.credenciais.copiaCredenciacao);

  const [state, setState] = useState({
    right: false,
  });

  const [drawerState, setDrawerState] = useState(null);

  const fetchCredenciacaoById = (credId) => {
    dispatch(GetCredenciacaoById(credId));
  };
  const fetchCredFuncionarioById = (funcionarioId) => {
    dispatch(GetCredFuncionarioById(funcionarioId));
  };

  const fetchCredViaturaUploadedFiles = (credId) => {
    dispatch(GetCredViaturaUploadedFiles(credId));
  };

  const toggleDrawer = (anchor, open, id) => {
    if (open == false) {
      setState({ ...state, [anchor]: open });
      setCredenciacaoId(null);
    }
    if (open == true) {
      setState({ ...state, [anchor]: open });
      setCredenciacaoId(id);
      fetchCredenciacaoById(id);
      fetchCredViaturaUploadedFiles(id);
    }
  };

  // ############################################################## //
  // ######################## Estados Count ####################### //
  // ############################################################## //

  function getCountByDescription(arr, targetDescription) {
    let totalCount = 0;
    for (let obj of arr) {
      if (obj.description === targetDescription) {
        totalCount += obj.count;
      }
    }
    return totalCount;
  }

  const credRecolha = getCountByDescription(estadosByCount, "Recolha de dados");
  const credPrepSub = getCountByDescription(
    estadosByCount,
    "Preparada para submissão"
  );
  const credSubmetida = getCountByDescription(estadosByCount, "Submetida");
  const credEmitida = getCountByDescription(estadosByCount, "Emitida");
  const credLevantada = getCountByDescription(estadosByCount, "Levantada");
  const credRecusada = getCountByDescription(estadosByCount, "Recusada");
  const credAproxExp = getCountByDescription(
    estadosByCount,
    "Aproximação da data de expiração"
  );
  const credExpirada = getCountByDescription(estadosByCount, "Expirada");

  // ############################################################## //
  // ########################  Table State  ####################### //
  // ############################################################## //

  const [tableState, setTableState] = useState(false);

  const handleTableCredenciacoes = (estado) => {
    fetchCredCredenciacoesByEstado(estado);
    setTableState(true);
  };

  const closeTableCredenciacoes = (estado) => {
    setTableState(false);
  };

  // ############################################################## //
  // ############  MUI Datatable columns Credenciações ############ //
  // ############################################################## //

  const [estadoTabela, setestadoTabela] = useState();

  const columnsCredenciacoes = [
    {
      name: "id_crede_elementos_credenciacao",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "tipo",
      label: "Tipo",
    },
    {
      name: "estado",
      label: "Estado",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { width: "300px" },
        }),
      },
    },
    {
      name: "elemento",
      label: "Elemento",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                <Typography>{tableMeta.rowData[4]}</Typography>
              ) : (
                <Typography>{value}</Typography>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "veiculo",
      label: "Veiculo",
      options: {
        display: false,
      },
    },
    {
      name: "tempo",
      label: "Em espera",
      options: {
        setCellProps: () => ({
          style: { width: "120px" },
        }),
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          const estado = tableMeta.rowData[2];
          const tempo = tableMeta.rowData[5];
          const hoursMatch = tempo.match(/\d+h/);
          const horas = hoursMatch ? parseInt(hoursMatch[0]) : null;
          const daysMatch = tempo.match(/\d+d/);
          const dias = daysMatch ? parseInt(daysMatch[0]) : 0;

          const monthsMatch = tempo.match(/\d+m/);
          const meses = monthsMatch ? parseInt(monthsMatch[0]) : 0;
          const yearsMatch = tempo.match(/\d+a/);
          const anos = yearsMatch ? parseInt(yearsMatch[0]) : 0;

          const r = "#DE0000";
          const y = "#FFD500";
          const g = "#4CB64C";

          switch (estado) {
            case "Preparada para submissão":
              switch (true) {
                case anos >= 0 && meses >= 0 && dias >= 1:
                  return (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <ColorStateCube color={r} />
                    </Box>
                  );
                case anos < 1 && meses < 1 && dias < 1 && horas >= 12:
                  return (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <ColorStateCube color={y} />
                    </Box>
                  );
                case anos < 1 && meses < 1 && dias < 1 && horas < 12:
                  return (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <ColorStateCube color={g} />
                    </Box>
                  );
              }
            case "Recolha de dados":
              switch (true) {
                case anos >= 0 && meses >= 0 && dias >= 30:
                  return (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <ColorStateCube color={r} />
                    </Box>
                  );
                case anos < 1 && meses < 1 && dias >= 10:
                  return (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <ColorStateCube color={y} />
                    </Box>
                  );
                case anos < 1 && meses < 1 && dias < 10:
                  return (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <ColorStateCube color={g} />
                    </Box>
                  );
              }
            case "Aproximação da data de expiração":
              switch (true) {
                case anos >= 0 && meses >= 0 && dias >= 30:
                  return (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <ColorStateCube color={r} />
                    </Box>
                  );
                case anos < 1 && meses < 1 && dias < 30:
                  return (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <ColorStateCube color={y} />
                    </Box>
                  );
              }
            case "Emitida":
              return (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <ColorStateCube color={g} />
                </Box>
              );
            case "Levantada":
              return (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <ColorStateCube color={g} />
                </Box>
              );
            case "Expirada":
              return (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <ColorStateCube color={r} />
                </Box>
              );
            case "Recusada":
              return (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <ColorStateCube color={r} />
                </Box>
              );

            default:
              break;
          }
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { width: "5%" },
          align: "center",
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const o = tableMeta.rowData[0];
          const idtipo = tableMeta.rowData[8];
          const filesLength = tableMeta.rowData[9];
          const tipo = tableMeta.rowData[1];

          return (
            <div style={{ display: "flex", float: "right" }}>
              {filesLength > 0 ? (
                <IconButton
                  style={{
                    padding: 6,
                  }}
                  onClick={() => {
                    Instance()
                      .get(
                        `${BASE_URL}/credenciacoes/cred/${o}/${idtipo}/download`,
                        {
                          responseType: "blob",
                        }
                      )
                      .then(({ data }) => {
                        const downloadUrl = window.URL.createObjectURL(
                          new Blob([data])
                        );

                        const link = document.createElement("a");

                        link.href = downloadUrl;

                        link.setAttribute(
                          "download",
                          `credenciacao_${o}_documentos.zip`
                        ); //any other extension

                        document.body.appendChild(link);

                        link.click();
                        link.remove();
                        // dispatch(ShowNotification(message));
                      })
                      .catch((err) => {
                        console.log(err.response);
                        if (err.response) {
                          if (err.response === 403) {
                            // dispatch(ShowNotification(errorMessage));
                          }
                        }
                      });
                  }}
                >
                  <FeatherIcon
                    icon="download"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              ) : null}
              <IconButton
                onClick={() => {
                  setTipoCredenciacao({
                    id: idtipo,
                    descricao: tipo,
                  });
                  setDrawerState("edit");
                  toggleDrawer("right", true, o);
                }}
                style={{
                  padding: 6,
                }}
              >
                <FeatherIcon
                  icon="edit"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
            </div>
          );
        },
      },
    },
    {
      name: "id_crede_credenciacao_tipo",
      label: "id_tipo",
      options: {
        display: false,
      },
    },
    {
      name: "numFicheiros",
      label: "numFicheiros",
      options: {
        display: false,
      },
    },
  ];

  // ############################################################## //
  // #############  MUI Datatable columns Notificações ############ //
  // ############################################################## //

  const columns = [
    {
      name: "id_crede_credenciacao_notified",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "email",
      label: "Email Notificado",
    },
    {
      name: "estado",
      label: "Estado",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { width: "250px" },
        }),
        customBodyRender: (value, tableMeta) => {
          switch (value) {
            case "Submetida":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#FFD500" />
                  {value}
                </div>
              );
            case "Levantada":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#4CB64C" />
                  {value}
                </div>
              );
            case "Recusada":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#DE0000" />
                  {value}
                </div>
              );
            case "Expirada":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#DE0000" />
                  {value}
                </div>
              );
            case "Emitida":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#4CB64C" />
                  {value}
                </div>
              );
            case "Recolha de dados":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#FF8521" />
                  {value}
                </div>
              );
            case "Aproximação da data de expiração":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#FF8521" />
                  {value}
                </div>
              );
            case "Preparada para submissão":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#54B7D3" />
                  {value}
                </div>
              );
            default:
              return (
                <div>
                  <ColorStateCube color="" />
                  {value}
                </div>
              );
          }
        },
      },
    },
    {
      name: "elemento",
      label: "Elemento",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {tableMeta.rowData[4] == null ? (
                <>{value}</>
              ) : (
                <>{tableMeta.rowData[4]}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "veiculo",
      label: "Veiculo",
      options: {
        display: false,
      },
    },
    {
      name: "crede_credenciacao_notified_sent_date",
      label: "Data envio",
      options: {
        filter: true,
        sort: true,
        // setCellProps: () => ({
        //   style: { maxWidth: "40px" },
        // }),

        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "crede_credenciacao_notified_flag_0",
      label: "Expiração",
      options: {
        display: false,
      },
    },
    {
      name: "crede_credenciacao_notified_flag_30",
      label: "30 dias",
      options: {
        display: false,
      },
    },
    {
      name: "crede_credenciacao_notified_flag_60",
      label: "60 dias",
      options: {
        display: false,
      },
    },
  ];

  // ############################################################## //
  // ##################  MUI Datatable Options  ################### //
  // ############################################################## //

  const options = {
    filter: false,
    filterType: "dropdown",
    selectableRows: "none",
    tableBodyMaxHeight: "75vh",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },
    customToolbar: () => {
      return (
        <>
          {tableState && (
            <Tooltip title={"Fechar tabela"}>
              <IconButton
                className={classes.iconButton}
                onClick={() => closeTableCredenciacoes()}
              >
                <FeatherIcon
                  icon="x"
                  size={22}
                  className={classes.deleteIcon}
                  style={{ strokeWidth: "2" }}
                />
              </IconButton>
            </Tooltip>
          )}
        </>
      );
    },
  };

  // ############################################################## //
  // ######################## Dados Gráficos ###################### //
  // ############################################################## //

  const totalCredMes = useSelector(
    (state) => state && state.credenciais && state.credenciais.totalCredMes
  );
  const credPermValidades = useSelector(
    (state) => state && state.credenciais && state.credenciais.credPermValidades
  );

  const totalPerm = totalCredMes.map((c) => {
    return c.totalPerm;
  });

  const totalPontual = totalCredMes.map((c) => {
    return c.totalPontual;
  });

  const totalMeses = totalCredMes.map((c) => {
    return moment(c.month).format("MMM/YY");
  });

  const credValTipos = credPermValidades.map((c) => {
    return c.validity_range;
  });

  const credValTotal = credPermValidades.map((c) => {
    return c.totalPerm;
  });

  const colors = [
    "#4CB64C",
    "#4CB64C",
    "#4CB64C",
    "#FFD500",
    "#FFD500",
    "#DE0000",
  ];

  const credValTotalData = credValTotal.map((value, index) => ({
    x: credValTipos[index],
    y: value,
    fillColor: colors[index], // Assign color based on index
  }));

  // ############################################################## //
  // ################## Gráfico de barras Total Mês ############### //
  // ############################################################## //

  const chartData = {
    series: [
      {
        name: "Permanentes",
        data: totalPerm,
      },
      {
        name: "Pontuais",
        data: totalPontual,
      },
    ],
    options: {
      chart: {
        height: 300,
        type: "line",
        toolbar: {
          show: false,
        },
      },
      colors: ["#4CB64C", "#54B7D3"],
      xaxis: {
        categories: totalMeses,
      },
      stroke: {
        width: [0, 4],
      },
      title: {
        text: "Credenciações - Criadas",
        style: {
          color: "#b8b8b8",
          fontSize: "14px",
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "14px",
        },
      },
      legend: {
        labels: {
          colors: "#b8b8b8",
        },
        fontSize: "14px",
      },
      tooltip: {
        theme: "dark",
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
        },
      },
      yaxis: {
        title: {
          text: "Nº Credenciações",
          style: {
            color: "#b8b8b8",
            fontSize: "14px",
          },
        },
        labels: {
          style: {
            colors: "#b8b8b8",
            fontSize: "14px",
          },
        },
      },
    },
  };

  // ############################################################## //
  // ################## Gráfico de barras Total Mês ############### //
  // ############################################################## //

  const chartData2 = {
    series: [
      {
        name: "Expiração (em meses)",
        data: credValTotalData,
      },
    ],
    options: {
      chart: {
        height: 400,
        type: "line",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: credValTipos,
        title: {
          text: "Expiração (em meses)",
          offsetY: 100,
          style: {
            color: "#b8b8b8",
            fontSize: "14px",
          },
        },
      },
      stroke: {
        width: [0, 4],
      },
      title: {
        text: "Credenciações - Expiração",
        style: {
          color: "#b8b8b8",
          fontSize: "14px",
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "14px",
        },
      },
      legend: {
        labels: {
          colors: "#b8b8b8",
        },
        fontSize: "14px",
      },
      tooltip: {
        theme: "dark",
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
        },
      },
      yaxis: {
        title: {
          text: "Nº Credenciações",
          style: {
            color: "#b8b8b8",
            fontSize: "14px",
          },
        },
        labels: {
          style: {
            colors: "#b8b8b8",
            fontSize: "14px",
          },
        },
      },
    },
  };

  const fetchCredNotificacoes = () => {
    dispatch(GetCredNotificacoes());
  };

  const fetchCredEstadosCount = () => {
    dispatch(GetCredEstadosCount());
  };

  const fetchMediaDiasCredEstados = () => {
    dispatch(GetMediaDiasRespostaCredEstados());
  };

  const fetchTotalCredMes = () => {
    dispatch(GetTotalCredMes());
  };

  const fetchCredPermValidades = () => {
    dispatch(GetCredPermanentesValidades());
  };

  const globalFetch = (estado) => {
    fetchCredNotificacoes();
    fetchCredEstadosCount();
    fetchMediaDiasCredEstados();
    fetchTotalCredMes();
    fetchCredPermValidades();
    fetchCredCredenciacoesByEstado(estado);
  };

  useEffect(() => {
    fetchCredNotificacoes();
    fetchCredEstadosCount();
    fetchMediaDiasCredEstados();
    fetchTotalCredMes();
    fetchCredPermValidades();
  }, []);

  const fetchCredCredenciacoesByEstado = (estado) => {
    dispatch(GetCredCredenciacoesByEstado(estado));
  };

  const fetchCredAeroportos = () => {
    dispatch(GetCredAeroportos());
  };

  const fetchCredFuncoes = () => {
    dispatch(GetCredFuncoes());
  };

  const fetchCredTipoCartoes = () => {
    dispatch(GetCredTipoCartoes());
  };

  const fetchCredTipoCredenciacoes = () => {
    dispatch(GetCredTipoCredenciacoes());
  };

  const fetchCredTipoVinculos = () => {
    dispatch(GetCredTipoVinculos());
  };

  const fetchCredTipoCombustiveis = () => {
    dispatch(GetCredTipoCombustiveis());
  };

  const fetchCredTipoVeiculos = () => {
    dispatch(GetCredTipoVeiculos());
  };

  const fetchCredPortariaAcesso = () => {
    dispatch(GetCredPortariaAcesso());
  };

  const fetchCredCores = () => {
    dispatch(GetCredCores());
  };

  const fetchCredVeiculos = () => {
    dispatch(GetCredVeiculos());
  };

  const fetchCredFuncionarios = (intervencaoId, rascunhoId) => {
    dispatch(GetCredFuncionarios(intervencaoId, rascunhoId));
  };

  const fetchCredAcompanhantes = () => {
    dispatch(GetCredAcompanhantes());
  };

  const fetchCredMotivos = () => {
    dispatch(GetCredMotivos());
  };

  const fetchCredEstados = () => {
    dispatch(GetCredEstados());
  };

  const fetchCredCopiaCredenciacao = () => {
    dispatch(GetCredCopiaCredenciacao());
  };

  const fetchCredInqueritoPessoal = () => {
    dispatch(GetCredInqueritoPessoal());
  };

  const fetchCredViaturaFicheiros = () => {
    dispatch(GetCredViaturaFicheiros());
  };

  const fetchCredViaturaFicheirosPermanente = () => {
    dispatch(GetCredViaturaFicheirosPermanente());
  };

  const fetchCredEmpresas = () => {
    dispatch(GetCredEmpresas());
  };

  const fetchCredHabilitacoes = () => {
    dispatch(GetCredHabilitacoes());
  };

  const fetchCredTipoDocs = () => {
    dispatch(GetCredTipoDocs());
  };

  const fetchAllCredDestinatarios = () => {
    dispatch(GetAllCredDestinatarios());
  };

  const fetchCredenciacoes = (
    empresaId,
    aeroportoId,
    estadoId,
    tipoCredId,
    rascunhoId
  ) => {
    dispatch(
      GetAllCredenciacoes(
        empresaId,
        aeroportoId,
        estadoId,
        tipoCredId,
        rascunhoId
      )
    );
  };

  useEffect(() => {
    fetchCredenciacoes(
      empresa.id,
      aeroporto.id,
      estado.id,
      tipoCred.id,
      rascunho.id
    );
  }, []);

  useEffect(() => {
    fetchCredAeroportos();
    fetchCredFuncoes();
    fetchCredTipoCartoes();
    fetchCredTipoVinculos();
    fetchCredCores();
    fetchCredFuncionarios(null, 0);
    fetchCredAcompanhantes();
    fetchCredMotivos();
    fetchCredEstados();
    fetchCredEmpresas();
    fetchCredHabilitacoes();
    fetchCredTipoDocs();
    fetchCredCopiaCredenciacao();
    fetchCredInqueritoPessoal();
    fetchCredTipoCredenciacoes();
    fetchCredPortariaAcesso();
    fetchCredTipoCombustiveis();
    fetchCredTipoVeiculos();
    fetchCredViaturaFicheiros();
    fetchCredVeiculos();
    fetchCredViaturaFicheirosPermanente();
    fetchAllCredDestinatarios();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mr: 10,
          width: "100%",
        }}
      >
        <SideDrawer
          state={state}
          toggleDrawer={toggleDrawer}
          drawerState={drawerState}
          drawerWidth={drawerWidth}
          dimensions={dimensions}
          credenciacaoId={credenciacaoId}
          credenciacao={credenciacao}
          setCredenciacao={setCredenciacao}
          fetchCredenciacoes={fetchCredenciacoes}
          aeroportos={aeroportos}
          funcoes={funcoes}
          tipoCartoes={tipoCartoes}
          tipoVinculos={tipoVinculos}
          cores={cores}
          funcionarios={funcionarios}
          acompanhantes={acompanhantes}
          motivos={motivos}
          estados={estados}
          empresas={empresas}
          habilitacoes={habilitacoes}
          tipoDocs={tipoDocs}
          inqPessoal={inqPessoal}
          copCred={copCred}
          tipoCredenciacao={tipoCredenciacao}
          tipoCombustiveis={tipoCombustiveis}
          tipoVeiculos={tipoVeiculos}
          portariaAcesso={portariaAcesso}
          veiculos={veiculos}
          obrasState={obrasState}
          destinatariosOptions={destinatariosOptions}
          globalFetch={globalFetch}
        />
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              mx: 5,
              mt: 5,
              width: "100%",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/construcoes/obras">
                Gestão Aeroportuária
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href="/credenciacoes/credenciacoes"
              >
                Credenciações
              </Link>
              <Typography color="text.primary">Dashboard</Typography>
            </Breadcrumbs>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  mt: 3,
                  width: "43%",
                }}
              >
                <Typography sx={{ fontSize: "20px", mb: "20px" }}>
                  Estados
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    mb: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 3,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        p: 1,
                        pl: 2,
                        width: "49%",
                        border: "1px solid grey",
                        borderRadius: "10px",
                        backgroundColor: "#54B7D3",
                      }}
                    >
                      <a
                        href="#"
                        onClick={() => {
                          setestadoTabela("Recolha de dados");
                          handleTableCredenciacoes(1);
                        }}
                        style={{ width: "100%" }}
                      >
                        <Box sx={{ display: "flex", height: "100%" }}>
                          <Box
                            sx={{
                              display: "flex",
                              width: "60%",
                              alignItems: "flex-start",
                              justifyContent: "left",
                              fontWeight: "bold",
                              fontSize: "55px",
                              color: "#fff",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              {credRecolha}{" "}
                              <Typography
                                sx={{
                                  fontSize: "20px",
                                  fontWeight: 700,
                                  ml: 2,
                                }}
                              >
                                Recolha <br /> de dados
                              </Typography>
                            </Box>
                          </Box>
                          {/* <Box className="ag-courses-item_date-box">
                          Recolha de dados
                        </Box> */}

                          <Box
                            sx={{
                              width: "40%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                              }}
                            >
                              {/* First half (empty) */}
                              <Box
                                sx={{
                                  flex: 1,
                                }}
                              ></Box>
                              {/* Second half (content) */}
                              <Box
                                sx={{
                                  flex: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-end",
                                  justifyContent: "flex-end",
                                  color: "#fff",
                                  pt: "50%",
                                }}
                              >
                                <Box sx={{ display: "flex", width: "100%" }}>
                                  <Box sx={{ width: "45%" }}>
                                    <Typography> AHD</Typography>
                                  </Box>
                                  <Box sx={{ width: "55%" }}>
                                    <Typography>
                                      {mediaRecAHD ? mediaRecAHD : " 0 "} dias
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box sx={{ display: "flex", width: "100%" }}>
                                  <Box sx={{ width: "45%" }}>
                                    <Typography> ASC </Typography>
                                  </Box>
                                  <Box sx={{ width: "55%" }}>
                                    <Typography>
                                      {mediaRecASC ? mediaRecASC : " 0 "} dias
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box sx={{ display: "flex", width: "100%" }}>
                                  <Box sx={{ width: "45%" }}>
                                    <Typography> AGC </Typography>
                                  </Box>
                                  <Box sx={{ width: "55%" }}>
                                    <Typography>
                                      {mediaRecAGC ? mediaRecAGC : " 0 "} dias
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </a>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        p: 1,
                        pl: 2,
                        width: "49%",
                        border: "1px solid grey",
                        borderRadius: "10px",
                        backgroundColor: "#4CB64C",
                      }}
                    >
                      <a
                        href="#"
                        onClick={() => {
                          setestadoTabela("Para Submissão");
                          handleTableCredenciacoes(2);
                        }}
                        style={{ width: "100%" }}
                      >
                        <Box sx={{ display: "flex", height: "100%" }}>
                          {/* First Box */}
                          <Box
                            sx={{
                              display: "flex",
                              width: "60%",
                              alignItems: "flex-start",
                              justifyContent: "left",
                              fontWeight: "bold",
                              fontSize: "55px",
                              color: "#fff",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              {credPrepSub}{" "}
                              <Typography
                                sx={{
                                  fontSize: "20px",
                                  fontWeight: 700,
                                  ml: 2,
                                }}
                              >
                                Para <br /> submissão
                              </Typography>
                            </Box>
                          </Box>
                          {/* Second Box */}
                          <Box
                            sx={{
                              width: "40%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                              }}
                            >
                              {/* First half (empty) */}
                              <Box
                                sx={{
                                  flex: 1,
                                }}
                              ></Box>
                              {/* Second half (content) */}
                              <Box
                                sx={{
                                  flex: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-end",
                                  justifyContent: "flex-end",
                                  color: "#fff",
                                  pt: "50%",
                                }}
                              >
                                <Box sx={{ display: "flex", width: "100%" }}>
                                  <Box sx={{ width: "45%" }}>
                                    <Typography> AHD</Typography>
                                  </Box>
                                  <Box sx={{ width: "55%" }}>
                                    <Typography>
                                      {mediaPSAHD ? mediaPSAHD : " 0 "} dias
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box sx={{ display: "flex", width: "100%" }}>
                                  <Box sx={{ width: "45%" }}>
                                    <Typography> ASC </Typography>
                                  </Box>
                                  <Box sx={{ width: "55%" }}>
                                    <Typography>
                                      {mediaPSASC ? mediaPSASC : " 0 "} dias
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box sx={{ display: "flex", width: "100%" }}>
                                  <Box sx={{ width: "45%" }}>
                                    <Typography> AGC </Typography>
                                  </Box>
                                  <Box sx={{ width: "55%" }}>
                                    <Typography>
                                      {mediaPSAGC ? mediaPSAGC : " 0 "} dias
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </a>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        p: 1,
                        pl: 2,
                        border: "1px solid grey",
                        borderRadius: "10px",
                        width: "15%",
                      }}
                    >
                      <a
                        href="#"
                        onClick={() => {
                          setestadoTabela("Submetida");
                          handleTableCredenciacoes(3);
                        }}
                        style={{ width: "100%", color: "#000" }}
                      >
                        <Typography
                          sx={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          {credSubmetida}
                        </Typography>
                        <Typography
                          sx={{ fontSize: "15px", fontWeight: "bold" }}
                        >
                          Submetida
                        </Typography>
                      </a>
                      <Box>
                        <Box
                          sx={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            backgroundColor: "#54B7D3",
                          }}
                        ></Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        p: 1,
                        pl: 2,
                        border: "1px solid grey",
                        borderRadius: "10px",
                        width: "15%",
                      }}
                    >
                      <a
                        href="#"
                        onClick={() => {
                          setestadoTabela("Emitida");
                          handleTableCredenciacoes(4);
                        }}
                        style={{ width: "100%", color: "#000" }}
                      >
                        <Typography
                          sx={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          {credEmitida}
                        </Typography>
                        <Typography
                          sx={{ fontSize: "15px", fontWeight: "bold" }}
                        >
                          Emitida
                        </Typography>
                      </a>
                      <Box>
                        <Box
                          sx={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            backgroundColor: "#4CB64C",
                          }}
                        ></Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        p: 1,
                        pl: 2,
                        border: "1px solid grey",
                        borderRadius: "10px",
                        width: "15%",
                      }}
                    >
                      <a
                        href="#"
                        onClick={() => {
                          setestadoTabela("Levantada");
                          handleTableCredenciacoes(6);
                        }}
                        style={{ width: "100%", color: "#000" }}
                      >
                        <Typography
                          sx={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          {credLevantada}
                        </Typography>
                        <Typography
                          sx={{ fontSize: "15px", fontWeight: "bold" }}
                        >
                          Levantada
                        </Typography>
                      </a>
                      <Box>
                        <Box
                          sx={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            backgroundColor: "#4CB64C",
                          }}
                        ></Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        p: 1,
                        pl: 2,
                        border: "1px solid grey",
                        borderRadius: "10px",
                        width: "15%",
                      }}
                    >
                      <a
                        href="#"
                        onClick={() => {
                          setestadoTabela("Aprox. Expiração");
                          handleTableCredenciacoes(7);
                        }}
                        style={{ width: "100%", color: "#000" }}
                      >
                        <Typography
                          sx={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          {credAproxExp}
                        </Typography>
                        <Typography
                          sx={{ fontSize: "15px", fontWeight: "bold" }}
                        >
                          Aprox. Expiração
                        </Typography>
                      </a>
                      <Box>
                        <Box
                          sx={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            backgroundColor: "#FFD500",
                          }}
                        ></Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        p: 1,
                        pl: 2,
                        border: "1px solid grey",
                        borderRadius: "10px",
                        width: "15%",
                      }}
                    >
                      <a
                        href="#"
                        onClick={() => {
                          setestadoTabela("Expirada");
                          handleTableCredenciacoes(8);
                        }}
                        style={{ width: "100%", color: "#000" }}
                      >
                        <Typography
                          sx={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          {credExpirada}
                        </Typography>
                        <Typography
                          sx={{ fontSize: "15px", fontWeight: "bold" }}
                        >
                          Expirada
                        </Typography>
                      </a>
                      <Box>
                        <Box
                          sx={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            backgroundColor: "#DE0000",
                          }}
                        ></Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        p: 1,
                        pl: 2,
                        border: "1px solid grey",
                        borderRadius: "10px",
                        width: "15%",
                      }}
                    >
                      <a
                        href="#"
                        onClick={() => {
                          setestadoTabela("Recusada");
                          handleTableCredenciacoes(5);
                        }}
                        style={{ width: "100%", color: "#000" }}
                      >
                        <Typography
                          sx={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          {credRecusada}
                        </Typography>
                        <Typography
                          sx={{ fontSize: "15px", fontWeight: "bold" }}
                        >
                          Recusada
                        </Typography>
                      </a>
                      <Box>
                        <Box
                          sx={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            backgroundColor: "#DE0000",
                          }}
                        ></Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      p: 1,
                      pl: 2,
                      border: "1px solid grey",
                      borderRadius: "10px",
                      width: "100%",
                      alignContent: "center",
                      height: "250px",
                    }}
                  >
                    <ReactApexChart
                      options={chartData.options}
                      series={chartData.series}
                      type="bar"
                      height="100%"
                      width="100%"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mt: 3,
                      p: 1,
                      pl: 2,
                      border: "1px solid grey",
                      borderRadius: "10px",
                      width: "100%",
                      alignContent: "center",
                      height: "250px",
                    }}
                  >
                    <ReactApexChart
                      options={chartData2.options}
                      series={chartData2.series}
                      type="bar"
                      height="100%"
                      width="100%"
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ width: "55%" }}>
                {tableState ? (
                  <MUIDataTable
                    title={`Credenciações - ${estadoTabela}`}
                    data={credenciacoes || []}
                    // data={[]}
                    columns={columnsCredenciacoes}
                    options={options}
                  />
                ) : (
                  <MUIDataTable
                    title="Notificações"
                    data={notificacoes || []}
                    // data={[]}
                    columns={columns}
                    options={options}
                  />
                )}
              </Box>
            </Box>

            <div style={{ margin: "20px 40px" }}>
              <FooterLinks />
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DashboardCredenciacoes;
