/* eslint-disable eqeqeq */
import "./App.css";
import "./Global/css/modals.css";
import { useSelector } from "react-redux";
// import { ThemeProvider } from '@mui/material/styles';
// import theme from './Global/css/MuiTheme/theme';

//Router
import { Routes, Route, useLocation } from "react-router-dom";

//Components
import TopAppbar from "./Global/TopAppbar";
import Login from "./pages/Global/Login/login";
import Error404 from "./pages/Global/Error/error";
import DefinirSenha from "./pages/Global/definirSenha/definirSenha";
import RecuperarSenha from "./pages/Global/recuperarSenha/recuperarSenha";
import Home from "./pages/App/Aprovisionamento/Test/Home";
import Dashboard from "./pages/App/Dashboard";
import ProtectedRoute from "./Router/ProtectedRoute";
import PersistentDrawerLeft from "./Global/Components/SmallMenu/index";
import NotificationSnackbar from "./Global/Components/Notification/Notification";

// ---- GESTÃO AEROPORTUÁRIA ----- //
import Aprovisionamento from "./pages/App/Aprovisionamento/Obras";
import ResponsaveisWorkPermits from "./pages/App/Aprovisionamento/ResponsaveisWorkPermits";
import Credenciacoes from "./pages/App/Aprovisionamento/Credenciacoes";
import CredFuncionarios from "./pages/App/Aprovisionamento/Funcionarios";
import AprovisionamentoSidebar from "./pages/App/Aprovisionamento/components/AprovisionamentoSidebar";
import CredViaturas from "./pages/App/Aprovisionamento/Viaturas";
import DashboardCredenciacoes from "./pages/App/Aprovisionamento/DashboardCredenciacoes";
import DashboardAHD from "./pages/App/Dashboard AHD";
import DashboardAHDSerralharia from "./pages/App/Dashboard AHD/Dashboard Serralharia";

// ---- INTERVENÇÕES ----- //
import Intervencoes from "./pages/App/Intervencoes/Intervencoes";
import IntervencoesDashboard from "./pages/App/Intervencoes/dashboard";
import IntervencoesTimeline from "./pages/App/Intervencoes/Timeline";
import IntervencoesSidebar from "./pages/App/Intervencoes/components/dashboard/sidebarDashboard";

// ---- ENERGIAS ----- //
import Energias from "./pages/App/Energias/Obras";
import EnergiasDashboard from "./pages/App/Energias/dashboard";
import EnergiasDashboardParceiros from "./pages/App/Energias/dashboardParceiro";
import Sidebar from "./pages/App/Energias/components/sidebarDashboard";
import Manutencoes from "./pages/App/Energias/Manutencoes";
import DocumentosOficiais from "./pages/App/Energias/DocumentosOficiais";
import FichasTecnicas from "./pages/App/Energias/FichasTecnicas";
import Setores from "./pages/App/Energias/Setores";
import Empresas from "./pages/App/Energias/Empresas";
import Blog from "./pages/App/Energias/Blog";
import BlogIndividual from "./pages/App/Energias/Blog/Individual";
import DashboardMensal from "./pages/App/Energias/DashboardMensal";
import Propostas from "./pages/App/Energias/Propostas";
import Timeline from "./pages/App/Energias/Timeline";

// ---- UTILIZADORES ----- //
import Utilizadores from "./pages/App/Utilizadores";
import IntervencoesTimelineParceiro from "./pages/App/Intervencoes/Timeline/indexParceiro";
import CredIntervencoes from "./pages/App/Aprovisionamento/Intervencoes";
import CredDestinatarios from "./pages/App/Aprovisionamento/Destinatarios";
import DashboardAHDAdmin from "./pages/App/Dashboard AHD Admin";

// ---- PLUS ----- //
import PlusAdesao from "./pages/App/Plus/Adesoes";
import PlusClientes from "./pages/App/Plus/Clientes";
import PlusSidebar from "./pages/App/Plus/components/PlusSidebar";
import PlusParceiros from "./pages/App/Plus/Parceiros";

// import Notification from './Global/Components/Alerts/index';

// //COMPONENTS
// import NotificationSnackbar from './Global/Components/Test/Notification';

//ACTIONS

const App = () => {
  const location = useLocation();

  //  PASSAR WINDOW DIMENSIONS PARA O ROUTER

  // const user = localStorage && localStorage.user;
  // const userParsed = user && JSON.parse(user);

  const user = useSelector((state) => state.user);
  const dashtoken = user && user.auth_utilizadores_url_key;
  // console.log(user);
  // console.log(user && Object.keys(user).length);
  // console.log(user ? 'tem dados no user' : null);
  // console.log(!!user);

  const token = localStorage.token;

  // const handleNotification = () => {
  //   dispatch(ShowNotification());
  // };

  // let token_dashboard;

  // if (location.pathname.startsWith("/dashboardahd/")) {
  //   // Split the string by "/"
  //   const parts = location.pathname.split("/");

  //   token_dashboard = parts[2];
  // }

  const page404 = location.pathname === "/404";
  const dashboardahd = location.pathname === `/dashboardahd/${dashtoken}`;
  const dashboardahdadmin =
    location.pathname === `/dashboardahdadmin/${dashtoken}`;
  const dashboardahdserralharia =
    location.pathname === `/dashboardahdserralharia/${dashtoken}`;

  return (
    <div>
      {!page404 == true &&
      !dashboardahd == true &&
      !dashboardahdadmin == true &&
      !dashboardahdserralharia == true ? (
        <>
          {/* {token ? <Drawer /> : null} */}
          {token ? <TopAppbar /> : null}
          {token && <PersistentDrawerLeft />}
        </>
      ) : null}

      <Routes>
        <Route exact path="/" element={<Login />} />
        {/* <Route path='/aprovisionamento' element={<Router />} /> */}
        {/* <Route path='/intervencoes' element={<Intervencoes />} /> */}
        {/* OPENED ROUTES */}
        {/* <Route path='landing' element={<Landing />} /> */}
        <Route path="/definirsenha/:hash" element={<DefinirSenha />} />
        <Route path="/recuperarSenha" element={<RecuperarSenha />} />
        {/* Protected Routes */}
        {/* <Route element={<ProtectedRoute isAllowed={!!user} />}> */}
        <Route element={<ProtectedRoute isAllowed={user} />}>
          <Route path="/home" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
        <Route path="/dashboardahd/:token" element={<DashboardAHD />} />
        <Route
          path="/dashboardahdadmin/:token"
          element={<DashboardAHDAdmin />}
        />
        <Route
          path="/dashboardahdserralharia/:token"
          element={<DashboardAHDSerralharia />}
        />
        {/* PROTECTED ROUTES WITH PERMISSIONS */}
        {/* <Drawer> */}
        {/* ##### ROUTES GESTÃO AEROPOSTUÁRIA #####*/}
        <Route
          path="/construcoes/obras"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_CONSTRUCOES")
              }
            >
              <div style={{ display: "flex" }}>
                {user &&
                user.permissoes &&
                user.permissoes.includes("APP_CREDENCIACOES") ? (
                  <AprovisionamentoSidebar />
                ) : null}
                <Aprovisionamento />
              </div>
              {/* <Aprovisionamento /> */}
            </ProtectedRoute>
          }
        />
        <Route
          path="/credenciacoes/dashboard"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_CRED_CREDENCIACOES_DASHBOARD")
              }
            >
              <div style={{ display: "flex" }}>
                <AprovisionamentoSidebar />
                <DashboardCredenciacoes />
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/construcoes/responsaveisWorkPermits"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_WORK_PERMITS_RESPONSAVEIS")
              }
            >
              <div style={{ display: "flex" }}>
                <AprovisionamentoSidebar />
                <ResponsaveisWorkPermits />
              </div>
              {/* <Aprovisionamento /> */}
            </ProtectedRoute>
          }
        />
        <Route
          path="/credenciacoes/credenciacoes"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_CRED_CREDENCIACOES")
              }
            >
              <div style={{ display: "flex" }}>
                <AprovisionamentoSidebar />
                <Credenciacoes />
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/credenciacoes/colaboradores"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_CRED_COLABORADORES")
              }
            >
              <div style={{ display: "flex" }}>
                <AprovisionamentoSidebar />
                <CredFuncionarios />
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/credenciacoes/obras"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_CRED_OBRAS")
              }
            >
              <div style={{ display: "flex" }}>
                <AprovisionamentoSidebar />
                <CredIntervencoes />
              </div>
              {/* <Aprovisionamento /> */}
            </ProtectedRoute>
          }
        />
        <Route
          path="/credenciacoes/viaturas"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_CRED_VIATURAS")
              }
            >
              <div style={{ display: "flex" }}>
                <AprovisionamentoSidebar />
                <CredViaturas />
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/credenciacoes/destinatarios"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_CRED_DESTINATARIOS")
              }
            >
              <div style={{ display: "flex" }}>
                <AprovisionamentoSidebar />
                <CredDestinatarios />
              </div>
            </ProtectedRoute>
          }
        />
        {/* ##### ---------------- #####*/}
        {/* ##### ROUTES INTERVENÇÕES #####*/}
        {user && user.perfilId != 5 ? (
          <Route
            path="/intervencoes/dashboard"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={
                  user &&
                  user.permissoes &&
                  user.permissoes.includes("APP_INTERVENCOES")
                }
              >
                <div style={{ display: "flex" }}>
                  <IntervencoesSidebar />
                  <IntervencoesDashboard />
                </div>
              </ProtectedRoute>
            }
          />
        ) : (
          <Route
            path="/intervencoes/dashboard"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={
                  user &&
                  user.permissoes &&
                  user.permissoes.includes("APP_INTERVENCOES")
                }
              >
                <div style={{ display: "flex" }}>
                  <IntervencoesSidebar />
                  <IntervencoesDashboard />
                </div>
              </ProtectedRoute>
            }
          />
        )}
        <Route
          path="/intervencoes/lista"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_INTERVENCOES")
              }
            >
              {/* {user && user.perfilId !== 3 ? ( */}
              <div style={{ display: "flex" }}>
                <IntervencoesSidebar />
                <Intervencoes />
              </div>
              {/* ) : (
                <Intervencoes />
              )} */}
            </ProtectedRoute>
          }
        />

        <Route
          path="/intervencoes/timeline"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_INTERVENCOES")
              }
            >
              {user && user.perfilId !== 3 ? (
                <div style={{ display: "flex" }}>
                  <IntervencoesSidebar />
                  <IntervencoesTimeline />
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <IntervencoesSidebar />
                  <IntervencoesTimelineParceiro />
                </div>
              )}
            </ProtectedRoute>
          }
        />
        {/* ##### ---------------- #####*/}

        {/* ##### ROUTES ENERGIAS #####*/}

        {user && user.perfilId != 5 ? (
          <Route
            exact
            path="/energias/dashboard"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={
                  user &&
                  user.permissoes &&
                  user.permissoes.includes("APP_ENERGIAS_DASHBOARD")
                }
              >
                <div style={{ display: "flex" }}>
                  <Sidebar />
                  <EnergiasDashboard />
                </div>
              </ProtectedRoute>
            }
          />
        ) : (
          <Route
            exact
            path="/energias/dashboard"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={
                  user &&
                  user.permissoes &&
                  user.permissoes.includes("APP_ENERGIAS_DASHBOARD")
                }
              >
                <div style={{ display: "flex" }}>
                  <Sidebar />
                  <EnergiasDashboardParceiros />
                </div>
              </ProtectedRoute>
            }
          />
        )}

        <Route
          exact
          path="/energias/controloMensal"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes(
                  "APP_ENERGIAS_ESTATISTICAS_CONTROLO_MENSAL"
                )
              }
            >
              <div style={{ display: "flex" }}>
                <Sidebar />
                <DashboardMensal />
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/energias/timeline"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_ENERGIAS_ESTATISTICAS_TIMELINE")
              }
            >
              <div style={{ display: "flex" }}>
                <Sidebar />
                <Timeline />
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/energias/obras"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_ENERGIAS_LISTAS_OBRAS_CONSULTA")
              }
            >
              <div style={{ display: "flex" }}>
                <Sidebar />
                <Energias />
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/energias/propostas"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes(
                  "APP_ENERGIAS_LISTAS_PROPOSTAS_CONSULTA"
                )
              }
            >
              <div style={{ display: "flex" }}>
                <Sidebar />
                <Propostas />
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/energias/manutencoes"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_ENERGIAS")
              }
            >
              <div style={{ display: "flex" }}>
                <Sidebar />
                <Manutencoes />
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/energias/documentos"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_ENERGIAS")
              }
            >
              <div style={{ display: "flex" }}>
                <Sidebar />
                <DocumentosOficiais />
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/energias/fichas"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_ENERGIAS")
              }
            >
              <div style={{ display: "flex" }}>
                <Sidebar />
                <FichasTecnicas />
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/energias/setores"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_ENERGIAS")
              }
            >
              <div style={{ display: "flex" }}>
                <Sidebar />
                <Setores />
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/energias/empresas"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_ENERGIAS")
              }
            >
              <div style={{ display: "flex" }}>
                <Sidebar />
                <Empresas />
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/energias/blog"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_ENERGIAS")
              }
            >
              <div style={{ display: "flex" }}>
                <Sidebar />
                <Blog />
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/energias/blog/:id"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_ENERGIAS")
              }
            >
              <div style={{ display: "flex" }}>
                <BlogIndividual />
              </div>
            </ProtectedRoute>
          }
        />

        {/* ##### ROUTES PLUS #####*/}

        <Route
          exact
          path="/plus/adesoes"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user && user.permissoes && user.permissoes.includes("APP_PLUS")
              }
            >
              <div style={{ display: "flex" }}>
                <PlusSidebar />
                <PlusAdesao />
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/plus/clientes"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user && user.permissoes && user.permissoes.includes("APP_PLUS")
              }
            >
              <div style={{ display: "flex" }}>
                <PlusSidebar />
                <PlusClientes />
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/plus/parceiros"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user && user.permissoes && user.permissoes.includes("APP_PLUS")
              }
            >
              <div style={{ display: "flex" }}>
                <PlusSidebar />
                <PlusParceiros />
              </div>
            </ProtectedRoute>
          }
        />

        {/* ##### ---------------- #####*/}

        <Route
          path="/utilizadores"
          element={
            <ProtectedRoute
              redirectPath="/"
              isAllowed={
                user &&
                user.permissoes &&
                user.permissoes.includes("APP_UTILIZADORES")
              }
            >
              <Utilizadores />
            </ProtectedRoute>
          }
        />

        {/* <Route
            path='admin'
            element={
              <ProtectedRoute
                redirectPath='/home'
                isAllowed={
                  userParsed &&
                  userParsed.roles &&
                  userParsed.roles.includes('admin')
                }
              >
                <Admin />
              </ProtectedRoute>
            }
          /> */}
        {/* </Drawer> */}
        <Route path="*" element={<Error404 />} />
      </Routes>
      {/* </ThemeProvider> */}
      {/* <Button
        onClick={() =>
          setNotify({
            isOpen: true,
            message: 'Produto alterado com sucesso!',
            type: 'success',
          })
        }
      >
        Sucess
      </Button> */}
      {/* <Notification notify={notify} setNotify={setNotify} />
      <NotificationSnackbar /> */}

      <NotificationSnackbar />
    </div>
  );
};

export default App;
