/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Box,
  IconButton,
  useTheme,
  Breadcrumbs,
  Link,
  Tooltip,
} from "@mui/material";
import "moment/locale/pt";

// Components
import FooterLinks from "../../../../Global/Components/Footer";
import SideDrawer from "./components/drawer";
import CustomToolbar from "./components/customToolbar";
import MUIDataTable from "mui-datatables";
import ModalDelete from "./components/modalDelete";

//Icons
import FeatherIcon from "feather-icons-react";

//Actions
import GetCredViaturas from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredViaturasAction";
import GetCredViaturasById from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredViaturasByIdAction";
import GetCredTipoVeiculos from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoVeiculosAction";
import GetCredTipoCombustiveis from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoCombustiveisAction";
import GetCredVeiculosCores from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredVeiculosCoresAction";
import GetCredViaturasUploadedFiles from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredViaturasUploadedFilesAction";
import GetCredViaturaFicheirosPermanente from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredViaturaFicheirosPermanenteAction";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
  },
  btnFiltrar: {
    color: "#E21450",
    width: "150px",
    height: "40px",
    fontWeight: "900",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  stateLabel: {
    textTransform: "none",
    transition: "0.2s",
    display: "flex",
    alignItems: "center",
    maxWidth: "100px",
  },

  paperFilters: {
    background: "transparent",
    padding: "20px 0px",
    boxShadow: "none",
  },
}));

// ############################################################## //
// #####################  Window Dimensions  #################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const CredViaturas = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");
  const [viatura, setViatura] = useState({
    matricula: "",
    marca: "",
    modelo: "",
    proprietario: "",
    requisitante: "",
    nr_apolice: "",
    validade_apolice: "",
    inspecao_identidade: "",
    inspecao_data: "",
    inspecao_data_proxima: "",
    nr_distico: "",
  });

  const viaturasState = useSelector((state) => state.credenciais.viaturas);
  const permissoes = useSelector((state) => state.user.permissoes);

  const coresState = useSelector((state) => state.credenciais.veiculosCores);
  const tiposVeiculosState = useSelector(
    (state) => state.credenciais.tipoVeiculos
  );
  const tiposCombustiveisState = useSelector(
    (state) => state.credenciais.tipoCombustiveis
  );

  // ############################################################## //
  // #######################  Autocompletes  ###################### //
  // ############################################################## //

  const fetchCredViaturas = () => {
    dispatch(GetCredViaturas());
  };
  const fetchCredViaturasCores = () => {
    dispatch(GetCredVeiculosCores());
  };
  const fetchCredViaturasTipos = () => {
    dispatch(GetCredTipoVeiculos());
  };
  const fetchCredViaturasCombustiveis = () => {
    dispatch(GetCredTipoCombustiveis());
  };

  const fetchCredViaturasUploadedFiles = (funcionarioId) => {
    dispatch(GetCredViaturasUploadedFiles(funcionarioId));
  };

  const fetchCredViaturasById = (viaturaId) => {
    dispatch(GetCredViaturasById(viaturaId));
  };

  const fetchCredFicheirosViaturasPermanentes = () => {
    dispatch(GetCredViaturaFicheirosPermanente());
  };

  useEffect(() => {
    fetchCredViaturas();
    fetchCredViaturasCores();
    fetchCredViaturasTipos();
    fetchCredViaturasCombustiveis();
    fetchCredFicheirosViaturasPermanentes();
  }, []);

  // ############################################################## //
  // ##########################  Drawer  ########################## //
  // ############################################################## //

  const [drawerWidth, setDrawerWidth] = useState("90vw");
  const dimensions = useWindowDimensions();
  const [state, setState] = useState({
    right: false,
  });
  const [cursoState, setCursoState] = useState({
    right: false,
  });
  const [drawerState, setDrawerState] = useState(null);
  const [drawerCursoState, setDrawerCursoState] = useState(null);
  const toggleDrawer = (anchor, open, id) => {
    if (open == false) {
      setState({ ...state, [anchor]: open });
      setViaturaId(null);
    }
    if (open == true) {
      setState({ ...state, [anchor]: open });
      setViaturaId(id);
      fetchCredViaturasById(id);
      fetchCredViaturasUploadedFiles(id);
    }
  };

  // ############################################################## //
  // #######################  Modal Delete  ####################### //
  // ############################################################## //

  const [openDelete, setOpenDelete] = useState(false);
  const [viaturaId, setViaturaId] = useState();
  const [loading, setLoading] = useState(false);

  const handleOpen = (e, id) => {
    e.preventDefault();

    setLoading(false);

    setOpenDelete(true);
    setViaturaId(id);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpenDelete(false);
  };

  // ############################################################## //
  // ##################  MUI Datatable columns  ################### //
  // ############################################################## //

  const columns = [
    {
      name: "id_crede_credenciacao_veiculo",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "crede_credenciacao_veiculo_matricula",
      label: "Matricula",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
      },
    },

    {
      name: "crede_credenciacao_veiculo_marca",
      label: "Marca",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "crede_credenciacao_veiculo_modelo",
      label: "Modelo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "crede_credenciacao_veiculo_nome_proprietario",
      label: "Proprietário",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { width: "2.5%" },
          align: "center",
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const o = tableMeta.rowData[0];

          return (
            <div style={{ display: "flex" }}>
              {permissoes.includes("APP_CRED_VIATURAS_EDITAR") && (
                <IconButton
                  onClick={() => {
                    setDrawerState("edit");
                    toggleDrawer("right", true, o);
                  }}
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                >
                  <FeatherIcon
                    icon="edit"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              )}
              {/* <IconButton
                style={{
                  padding: 6,
                }}
                onClick={(e) => {
                  handleOpen(e, o);
                }}
              >
                <FeatherIcon
                  icon="trash-2"
                  size={25}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton> */}
            </div>
          );
        },
      },
    },
  ];

  // ############################################################## //
  // ##################  MUI Datatable options  ################### //
  // ############################################################## //

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },

    customToolbar: () => {
      return (
        <>
          {permissoes.includes("APP_CRED_COLABORADORES_INSERIR") && (
            <Tooltip title={"Adicionar Viatura"}>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  setDrawerState("add");
                  toggleDrawer("right", true, null);
                }}
              >
                <FeatherIcon
                  icon="plus-square"
                  size={22}
                  className={classes.deleteIcon}
                  style={{ strokeWidth: "2" }}
                />
              </IconButton>
            </Tooltip>
          )}
        </>
      );
    },
  };

  const handleSubmit = () => {
    const intervencaoId = intervencao.id;
    const rascunhoId = rascunho.id;

    fetchCredFuncionarios(intervencaoId, rascunhoId);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mr: 10,
          width: "100%",
        }}
      >
        <SideDrawer
          state={state}
          toggleDrawer={toggleDrawer}
          cursoState={cursoState}
          drawerState={drawerState}
          drawerWidth={drawerWidth}
          dimensions={dimensions}
          viaturaId={viaturaId}
          viatura={viatura}
          setViatura={setViatura}
          fetchCredViaturas={fetchCredViaturas}
          fetchCredViaturasById={fetchCredViaturasById}
          cores={coresState}
          tiposCombustiveis={tiposCombustiveisState}
          tiposVeiculos={tiposVeiculosState}
        />
        <ModalDelete
          openDelete={openDelete}
          handleClose={handleClose}
          viaturaId={viaturaId}
          fetchCredViaturas={fetchCredViaturas}
          // show={show}
          // Loading
          loading={loading}
          setLoading={setLoading}
        />
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              mx: 5,
              mt: 5,
              width: "100%",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/construcoes/obras">
                Gestão Aeroportuária
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href="/credenciacoes/credenciacoes"
              >
                Credenciações
              </Link>
              <Typography color="text.primary">Viaturas</Typography>
            </Breadcrumbs>
            {/* <Box sx={{ display: "flex", mt: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "end",
                }}
              >
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  className={classes.btnFiltrar}
                >
                  Filtrar
                </Button>
              </Box>
            </Box> */}
          </Box>
          <div
            style={{
              margin: "0px 40px 40px 40px",
              width: "100%",
            }}
          >
            <MUIDataTable
              title={
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  className="text-underline"
                >
                  Lista de Viaturas
                </span>
              }
              data={viaturasState || []}
              columns={columns}
              options={options}
            />
          </div>
          <div style={{ margin: "20px 40px" }}>
            <FooterLinks />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default CredViaturas;
