import { setoresInitialState } from "../initialState/setores";

const setoresReducer = (state = setoresInitialState, { type, payload }) => {
  switch (type) {
    case "GET_ALL_SETORES":
      return {
        ...state,
        setores: payload,
      };
    case "GET_SETOR_BY_ID":
      return {
        ...state,
        setor: payload,
      };
    case "DELETE_SETOR":
      return {
        ...state,
        setorApagado: payload,
      };
    case "ADD_SETOR":
      return {
        ...state,
        setorAdicionado: payload,
      };
    case "UPDATE_SETOR":
      return {
        ...state,
        setorEditado: payload,
      };

    default:
      return state;
  }
};

export default setoresReducer;
