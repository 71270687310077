import { intervencoesInitialState } from "../initialState/intervencoes";

const intervencoesReducer = (
  state = intervencoesInitialState,
  { type, payload }
) => {
  switch (type) {
    case "GET_INTERVENCOES":
      return {
        ...state,
        intervencoes: payload,
      };
    case "GET_INTERVENCOES_MOBILE":
      return {
        ...state,
        intervencoesMobile: payload,
      };
    case "GET_INTERVENCAO":
      return {
        ...state,
        intervencao: payload,
      };
    case "ADD_INTERVENCAO":
      return {
        ...state,
        novaIntervencao: payload,
      };
    case "EDIT_INTERVENCAO":
      return {
        ...state,
        intervencaoEditada: payload,
      };
    case "DELETE_INTERVENCAO":
      return {
        ...state,
        intervencaoRemovida: payload,
      };
    case "GET_INTERVENCOES_OBRAS":
      return {
        ...state,
        intervencoesObras: payload,
      };
    case "GET_INTERVENCOES_TIMELINE":
      return {
        ...state,
        intervencoesTimeline: payload,
      };
    case "INTERVENCOES_ANO_SELECTED":
      return {
        ...state,
        intervencoesAnoSelected: payload,
      };
    case "USER_LOGOUT":
      return {
        ...intervencoesInitialState,
      };
    //FILTERS_PRE_SELECTED
    case "SITUACAO_PRE_SELECTED":
      return {
        ...state,
        situacaoPreSelected: payload,
      };

    //TIMELINE
    case "INTERVENCOES_TIMELINE_DATA_INICIO":
      return {
        ...state,
        timelineDataInicio: payload,
      };
    case "INTERVENCOES_TIMELINE_DATA_FIM":
      return {
        ...state,
        timelineDataFim: payload,
      };
    case "INTERVENCOES_TIMELINE_AEROPORTOS":
      return {
        ...state,
        aeroportosTimeline: payload,
      };
    case "INTERVENCOES_TIMELINE_AEROPORTO":
      return {
        ...state,
        aeroportoSelected: payload,
      };
    case "INTERVENCOES_TIMELINE_DEPARTAMENTO_SELECTED":
      return {
        ...state,
        timelineDepartamentoSelected: payload,
      };
    case "TIMELINE_SITUACAO_SELECTED":
      return {
        ...state,
        timelineSitPreSelected: payload,
      };

    //DASHBOARD
    case "GET_DEPARTAMENTOS_DASHBOARD":
      return {
        ...state,
        departamentosDashboard: payload,
      };
    case "GET_INTERVENCOES_INFO_GRAFICOS":
      return {
        ...state,
        intervencoesInfoGraficos: payload,
      };
    case "GET_TIPO_PROPOSTAS":
      return {
        ...state,
        tipoPropostas: payload,
      };
    case "GET_ALL_PRIORIDADES":
      return {
        ...state,
        prioridades: payload,
      };
    default:
      return state;
  }
};

export default intervencoesReducer;
