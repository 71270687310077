import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chart from 'react-apexcharts';

//Router
import { Navigate } from "react-router-dom";

//Actions
import DashboardIdadesObras from '../../../../utils/redux/actions/global/dashboard/GetDashIdadesObrasAction';

const IdadeDeObraChart = () => {
  const dispatch = useDispatch();

  const fetchIdadesObras = () => {
    dispatch(DashboardIdadesObras());
  };

  const idadesState = useSelector(
    (state) => state.dashboard.idadesObrasDashboard
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchIdadesObras(), []);

  const navigateTo = () => {
    console.log('passei pelo handle');
    <Navigate to='/construcoes/obras' replace={true} />;
  };

  return (
    <>
      {idadesState ? (
        <Chart
          type="donut"
          width={492}
          height={400}
          series={idadesState && idadesState.series}
          options={{
            labels: idadesState && idadesState.labels,
            tooltip: {
              y: {
                formatter: (val) => {
                  return `${val} Obras`;
                },
              },
            },
            title: {
              text: "Idade das Obras",
            },
            colors: ["#8CC051", "#FFA65D", "#FF4A4A", "#4FC0E8"],
            chart: {
              events: {
                dataPointSelection: (event, chartContext, config) => {
                  console.log(config.w.config.labels[config.dataPointIndex]);
                  // window.open('https://simpleisbestt.herokuapp.com/');

                  switch (config.w.config.labels[config.dataPointIndex]) {
                    case "Em curso (< 10 dias)":
                      // window.open('https://simpleisbestt.herokuapp.com/');
                      // console.log('passei aqui');
                      // <Link to='/construcoes/obras' />;
                      navigateTo();
                      break;
                    case "Em risco (> 10 & < 100 dias)":
                      // window.open('https://google.com/');
                      console.log("passei ali");
                      // <Link to='/construcoes/obras' />;
                      break;
                    case "Em atraso (> 100 dias)":
                      // window.open('https://yahoo.com/');
                      console.log("passei acolá");
                      // <Link to='/construcoes/obras' />;
                      break;
                    case "Suspensas":
                      // window.open('https://facebook.com/');
                      console.log("passei aquli");
                      // <Link to='/construcoes/obras' />;
                      break;
                    default:
                      window.open("https://instagram.com/");
                  }
                },
                // click: (event, chartContext, config) => {
                //   console.log(event);
                //   console.log(chartContext);
                //   console.log(config);
                //   navigateTo();
                // },
              },
            },
          }}
        />
      ) : null}
    </>
  );
};

export default IdadeDeObraChart;
