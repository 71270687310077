export const obrasEnergiasInitialState = {
  obras: [],
  obra: {},
  obraEditada: {},

  //ESTADOS
  estadosControlo: [],
  estadosEspecialidade: [],
  estadosMaterial: [],

  //FILTERS
  estadosProjeto: [],
  estadosSubProjeto: [],

  //FILTERS_PRE_SELECTED
  estadosProjetoPreSelected: [{ id: 2, descricao: "Aberto" }],
  estadosSubProjetoPreSelected: [
    { id: 0, descricao: "-" },
    { id: 1, descricao: "Aberto" },
    { id: 2, descricao: "Iniciado" },
    { id: 3, descricao: "Executado" },
    { id: 6, descricao: "Suspenso" },
  ],
  controloPreSelected: [],

  //MATERIAIS
  materiais: [],
  subMateriais: [],
};
