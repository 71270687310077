import { obrasEnergiasInitialState } from '../initialState/obrasEnergias';

const obrasEnergiasReducer = (
  state = obrasEnergiasInitialState,
  { type, payload }
) => {
  switch (type) {
    case "GET_ENERGIAS_OBRAS":
      return {
        ...state,
        obras: payload,
      };
    case "GET_ENERGIAS_OBRA":
      return {
        ...state,
        obra: payload,
      };
    case "POST_ENERGIAS_OBRA":
      return {
        ...state,
        // obraEditada: payload,
        obra: payload,
      };
    case "CLEAR_ENERGIAS_OBRAS":
      return {
        ...state,
        obras: [],
      };

    //ESTADOS
    case "GET_ENERGIAS_ESTADOS_CONTROLO":
      return {
        ...state,
        estadosControlo: payload,
      };
    case "GET_ENERGIAS_ESTADOS_ESPECIALIDADE":
      return {
        ...state,
        estadosEspecialidade: payload,
      };
    case "GET_ENERGIAS_ESTADOS_MATERIAL":
      return {
        ...state,
        estadosMaterial: payload,
      };

    //FILTERS
    case "GET_ENERGIAS_ESTADOS_PROJETO":
      return {
        ...state,
        estadosProjeto: payload,
      };
    case "GET_ENERGIAS_ESTADOS_SUB_PROJETO":
      return {
        ...state,
        estadosSubProjeto: payload,
      };
    //FILTERS_PRE_SELECTED
    case "ENERGIAS_ESTADOS_PROJETO_PRE_SELECTED":
      return {
        ...state,
        estadosProjetoPreSelected: payload,
      };
    case "ENERGIAS_ESTADOS_SUBPROJETO_PRE_SELECTED":
      return {
        ...state,
        estadosSubProjetoPreSelected: payload,
      };
    case "ENERGIAS_ESTADOS_CONTROLO_PRE_SELECTED":
      return {
        ...state,
        controloPreSelected: payload,
      };

    //MATERIAIS
    case "GET_ENERGIAS_MATERIAIS":
      return {
        ...state,
        materiais: payload,
      };
    case "GET_ENERGIAS_SUB_MATERIAIS":
      return {
        ...state,
        subMateriais: payload,
      };

    case "USER_LOGOUT":
      return {
        ...obrasEnergiasInitialState,
      };

    default:
      return state;
  }
};

export default obrasEnergiasReducer;
