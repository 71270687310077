import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Drawer } from "@mui/material";
//Components
import FormEdit from "./forms/formEdit";
import FormAdd from "./forms/formAdd";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  tab: {
    fontWeight: "bold",
    textTransform: "capitalize",
  },
});

const schema = yup.object().shape({
  nome: yup.string().required("Preenchimento obrigatório"),
  email: yup
    .string()
    .email("Inisira um email válido")
    .required("Preenchimento obrigatório"),
  aeroportosDropdown: yup.object().nullable(),
  // .required('Preenchimento obrigatório'),
  departamentosDropdown: yup
    .object()
    .nullable()
    .when("aeroportosDropdown", {
      is: (aeroportosDropdown) =>
        aeroportosDropdown && aeroportosDropdown.id > 1,
      then: yup.object().nullable().required("Preenchimento obrigatório"),
    }),

  perfilDropdown: yup.object().nullable().required("Preenchimento obrigatório"),
});

const SideDrawer = ({
  state,
  toggleDrawer,
  utilizadores,
  fetchUtilizadores,
  drawerState,
  utilizador,
  setUtilizador,
  validationUtilizador,
  utilizadorId,
  utilizadorInfo,
  perfis,
  parceiros,
  diretoresObra,
}) => {
  const classes = useStyles();

  const aeroportos = useSelector((state) => state.filters.aeroportos);

  const [guardarLoading, setGuardarLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    watch,
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      nome: "",
      email: "",
      aeroportosDropdown: "",
      departamentosDropdown: "",
      perfilDropdown: "",
      auth_utilizadores_url_key: "",
    },
    resolver: yupResolver(schema),
  });

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={() => toggleDrawer("right", false)}
        classes={{ paper: classes.paper }}
      >
        <div className={classes.list}>
          {drawerState === "add" && (
            <FormAdd
              toggleDrawer={toggleDrawer}
              fetchUtilizadores={fetchUtilizadores}
              utilizador={utilizador}
              setUtilizador={setUtilizador}
              validationUtilizador={validationUtilizador}
              utilizadorInfo={utilizadorInfo}
              perfis={perfis}
              parceiros={parceiros}
              diretoresObra={diretoresObra}
              classes={classes}
              aeroportos={aeroportos}
              guardarLoading={guardarLoading}
              setGuardarLoading={setGuardarLoading}
              // React hook form
              control={control}
              handleSubmitForm={handleSubmit}
              errors={errors}
              reset={reset}
              getValues={getValues}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
            />
          )}
          {drawerState === "edit" && (
            <FormEdit
              toggleDrawer={toggleDrawer}
              utilizadores={utilizadores}
              fetchUtilizadores={fetchUtilizadores}
              utilizador={utilizador}
              setUtilizador={setUtilizador}
              utilizadorId={utilizadorId}
              utilizadorInfo={utilizadorInfo}
              perfis={perfis}
              parceiros={parceiros}
              diretoresObra={diretoresObra}
              classes={classes}
              aeroportos={aeroportos}
              guardarLoading={guardarLoading}
              setGuardarLoading={setGuardarLoading}
              // React hook form
              control={control}
              handleSubmitForm={handleSubmit}
              errors={errors}
              reset={reset}
              getValues={getValues}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
            />
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default SideDrawer;
