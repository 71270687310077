import Instance from "../../../../Axios";

// New Actions for the app

const GetAllCredenciacoes =
  (empresaId, aeroportoId, estadoId, tipoCredId, funcId, rascunhoId) =>
  async (dispatch) => {
    const empresa = empresaId === "0" ? null : empresaId;
    const aeroporto = aeroportoId === "0" ? null : aeroportoId;
    const estado = estadoId === "0" ? null : estadoId;
    const tipoCred = tipoCredId === "0" ? null : tipoCredId;
    const funcionario = funcId === "0" ? null : funcId;
    const rascunho = rascunhoId;
    await Instance()
      .get(`/credenciacoes`, {
        params: {
          empresa,
          aeroporto,
          estado,
          tipoCred,
          funcionario,
          rascunho,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: "GET_ALL_CREDENCIACOES",
          payload: data.yData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default GetAllCredenciacoes;