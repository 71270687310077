import Instance from "../../../../Axios";

// New Actions for the app

const GetObrasAHDRealizadasTotal = () => async (dispatch) => {
  await Instance()
    .get(`/construcoes/obras/ahd/obras/realizadasTotal`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({ type: "GET_OBRAS_AHD_REALIZADAS_TOTAL", payload: data.yData[0].total });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetObrasAHDRealizadasTotal;