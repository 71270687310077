import Instance from "../../../../Axios";

// New Actions for the app

const GetAllMEIO = () => async (dispatch) => {
  await Instance()
    .get(`/construcoes/workpermits/allMEIO`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({ type: "GET_ALL_MEIO", payload: data.yData });
      // setObraSelecionada(data.yData[0]);
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetAllMEIO;