/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Box } from '@mui/material';

const Manutencoes = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {/* <Sidebar /> */}
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mx: 5,
            mt: 3,
          }}
        >
          <Box>
            <h4
              style={{
                fontSize: '22px',
                width: '100%',
              }}
              className='text-underline'
            >
              Manutenções
            </h4>
          </Box>
        </Box>
        {/* Footer */}
        {/* <div style={{ margin: "20px 40px" }}>
          <FooterLinks />
        </div> */}
      </Box>
    </Box>
  );
};

export default Manutencoes;
