import Instance from '../../../../Axios';

// New Actions for the app

const GetObras =
  (
    estadosProjetoPreSelectedId,
    estadosSubProjetoPreSelectedId,
    estadosControloIdPreSelectedId,
    energParceiroId,
  ) =>
  async (dispatch) => {
    const estadosProjetosId = estadosProjetoPreSelectedId;
    const estadosSubProjetosId = estadosSubProjetoPreSelectedId;
    const estadosControloId = estadosControloIdPreSelectedId;
    await Instance()
      .get(`/energias/obras`, {
        params: {
          estadosProjetosId,
          estadosSubProjetosId,
          estadosControloId,
          energParceiroId
        },
      })
      .then(({ data }) => {
        // console.log(data);
        dispatch({ type: 'GET_ENERGIAS_OBRAS', payload: data.yData });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default GetObras;
