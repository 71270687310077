import Instance from "../../../../Axios";

// New Actions for the app

const GetCredIntervencoesElementos= (id) => async (dispatch) => {
  await Instance()
    .get(`/credenciacoes/intervencoes/${id}`)
    .then(({ data }) => {
      dispatch({
        type: "GET_CRED_INTERVENCOES_ELEMENTOS",
        payload: data.yData,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetCredIntervencoesElementos;