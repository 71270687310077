import Instance from '../../../../Axios';

const DashboardConstrucoesEstadosProposta = () => async (dispatch) => {
  await Instance()
    .get(`/dashboard/grConstrucoesEstadosPropostas`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({
        type: 'GET_DASHBOARD_CONSTRUCOES_ESTADOS_PROPOSTAS',
        payload: data.yData,
      });

      //   show('success', 'Foram associados novos funcionários á obra');
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 403) {
          // dispatch(ShowNotification(warningMessage));
        } else {
          // dispatch(ShowNotification(errorMessage));
        }
      }
    });
};

export default DashboardConstrucoesEstadosProposta;
