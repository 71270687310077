/* eslint-disable eqeqeq */
import React from 'react';
import { TextField, Typography } from '@mui/material';

const Informacao = ({ obraSelecionada }) => {
  return (
    <>
      <div style={{ width: "80%" }}>
        <h4
          style={{ color: "#E21450" }}
          className="text-underline-title-drawer "
        >
          Datas
        </h4>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "13%", marginBottom: "16px" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Criação
            </Typography>
            <TextField
              size="small"
              value={
                (obraSelecionada && obraSelecionada.dataCriacao) ||
                " --/--/---- "
              }
              variant="outlined"
              disabled
              style={{ width: "100%" }}
            />
          </div>

          <div style={{ width: "13%", marginBottom: "16px" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Início
            </Typography>
            <TextField
              size="small"
              value={
                (obraSelecionada && obraSelecionada.dataInicial) ||
                " --/--/---- "
              }
              variant="outlined"
              disabled
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ width: "13%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Fim
            </Typography>
            <TextField
              size="small"
              value={
                (obraSelecionada && obraSelecionada.dataFinal) || " --/--/---- "
              }
              variant="outlined"
              disabled
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ width: "13%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Idade
            </Typography>

            <TextField
              size="small"
              value={
                (obraSelecionada && obraSelecionada.idade) || " --/--/---- "
              }
              variant="outlined"
              disabled
              style={{ width: "100%" }}
            />
          </div>

          <div style={{ width: "13%", marginBottom: "16px" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Real de Início
            </Typography>

            <TextField
              size="small"
              value={
                (obraSelecionada && obraSelecionada.dataRealInicio) ||
                " --/--/---- "
              }
              variant="outlined"
              disabled
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ width: "13%", marginBottom: "16px" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Real de Fim
            </Typography>
            <TextField
              size="small"
              value={
                (obraSelecionada && obraSelecionada.dataRealFim) ||
                " --/--/---- "
              }
              variant="outlined"
              disabled
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ width: "13%", marginBottom: "16px" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Duração da obra
            </Typography>

            <TextField
              size="small"
              value={
                obraSelecionada.idadeReal == null
                  ? " - "
                  : obraSelecionada.idadeReal == 1
                  ? obraSelecionada.idadeReal + " dia"
                  : obraSelecionada.idadeReal + " dias"
              }
              variant="outlined"
              disabled
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Informacao;
