import Instance from "../../../Axios";

// New Actions for the app

const AddPlusDoc = (yData, newFile) => async (dispatch) => {
  const formdata = new FormData();

  formdata.append("yData", JSON.stringify(yData[0]));
  formdata.append("file", newFile[0].file);
  await Instance()
    .post(`/plus/adesoes/add/addDocument`, formdata)
    .then(({ data }) => {
      dispatch({ type: "ADD_PLUS_DOC", payload: data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default AddPlusDoc;
