/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box, Card, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import "moment/locale/pt";
import ptLocale from "date-fns/locale/pt";
import { format } from "date-fns";

// Components
import FooterLinks from "../../../../Global/Components/Footer";

//Actions
import GetOrcamentosPerMonth from "../../../../utils/redux/actions/energias/dashboard/GetOrcamentosPerMonth";

//Gráficos
import GraficoTotalPropostasDia from "./components/GraficoTotalPropostasDia";

// ############################################################## //
// #####################  Window Dimensions  #################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const DashboardMensal = () => {
  const dispatch = useDispatch();
  // moment.locale("pt");
  const mesAtual = useSelector(
    (state) => state.filtersEnergias.controloMensalData
  );
  const [month, setMonth] = useState(mesAtual);

  const orcamentosMes = useSelector(
    (state) => state.energiasDashboard.orcamentosPerMonth
  );

  orcamentosMes.map(
    (item) =>
      (item.data_criacao = moment(item.data_criacao).format("YYYY-MM-DD"))
  );

  const fetchDashboardMensal = (startOfMonth, endOfMonth) => {
    dispatch(GetOrcamentosPerMonth(startOfMonth, endOfMonth));
  };

  const startOfMonth = moment(month).startOf("month").format("YYYY-MM-DD");
  const endOfMonth = moment(month).endOf("month").format("YYYY-MM-DD");

  const year = moment(month).format("YYYY");
  const mes = moment(month).format("M");
  const mesEx = format(new Date(month), "MMMM", {locale: ptLocale}) + " de " + format(new Date(month), "yyyy")
  // const mesEx = moment(month).locale("pt").format("MMMM [de] YYYY");
  // console.log(format(new Date(month), "MMMM", {locale: ptLocale}) + " de " + format(new Date(month), "yyyy") );

  const getDaysArray = function (year, month) {
    const monthIndex = month - 1; // 0..11 instead of 1..12
    const date = new Date(year, monthIndex, 1);
    const result = [];
    while (date.getMonth() == monthIndex) {
      result.push({
        data_criacao: moment(date).format("YYYY-MM-DD"),
        propostasRespondidas: 0,
        propostasPorResponder: 0,
      });
      date.setDate(date.getDate() + 1);
    }
    return result;
  };

  const handleChange = (date) => {
    setMonth(date);
    dispatch({
      type: "CONTROLO_MENSAL_DATA",
      payload: date,
    });
  };

  useEffect(() => {
    fetchDashboardMensal(startOfMonth, endOfMonth);
  }, [month]);

  const result = getDaysArray(year, mes);

  result.map((item) => {
    if (
      !orcamentosMes.find((elem) => elem.data_criacao === item.data_criacao)
    ) {
      orcamentosMes.push(item);
    }
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* <Sidebar /> */}
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mx: 5,
              mt: 3,
            }}
          >
            <Box>
              <h4
                style={{
                  fontSize: "22px",
                  width: "100%",
                }}
                className="text-underline"
              >
                Controlo Mensal
              </h4>
            </Box>
            <Box>
              <Typography>Mês de {mesEx}</Typography>
            </Box>
            <Box>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ptLocale}
              >
                <Box>
                  <DatePicker
                    adapter="pt"
                    inputFormat="MM-yyyy"
                    views={["month", "year"]}
                    label="Mês e ano"
                    minDate={new Date("2020-01-01")}
                    maxDate={new Date("2023-12-01")}
                    value={month}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField {...params} helperText={null} size="small" />
                    )}
                  />
                </Box>
              </LocalizationProvider>
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", mx: 5 }}>
            <Card
              sx={{
                mb: 5,
                p: 2,
                boxShadow: "0px 5px 15px lightgrey",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <GraficoTotalPropostasDia orcamentosMes={orcamentosMes} />
              </Box>
            </Card>
          </Box>
          <div style={{ margin: "20px 40px" }}>
            <FooterLinks />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default DashboardMensal;
