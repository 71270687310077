import Instance from '../../../../Axios';

// New Actions for the app

const GetObrasEmpresas = () => async (dispatch) => {
  await Instance()
    .get(`/construcoes/obras/all/empresas`)
    .then(({ data }) => {
      dispatch({ type: 'GET_OBRAS_EMPRESAS', payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetObrasEmpresas;