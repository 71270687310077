import React from 'react';
import { makeStyles } from '@mui/styles';
import { Drawer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
//Components
import DrawerForm from './formEditar';
import DrawerFormConsulta from './formConsulta';

const useStyles = makeStyles({
  list: {
    width: '90vw',
    borderRadius: '10px 0px 0px 10px !important',
    // background: 'red !important',
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    background: '#FAFAFA',
  },
  btnGuardar: {
    color: 'white',
    width: '120px',
    height: '40px',
    // borderRadius: '15px',
    backgroundColor: '#E21450',
    border: `1px solid #E21450`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#bc1041',
      border: '1px solid #bc1041',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#bc1041',
      },
      '&:disabled': {
        backgroundColor: '#bc1041',
      },
    },
  },
  btnCloseDrawer: {
    color: '#464255',
    width: '30px',
    height: '40px',
    borderRadius: '15px',
    backgroundColor: '#f2f2f2',
    border: `1px solid #f2f2f2`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#e0e0e0',
      border: '1px solid #e0e0e0',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#e0e0e0',
      },
    },
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  noBorder: {
    border: 'none',
  },
});

const SideDrawer = ({
  state,
  toggleDrawer,
  obras,
  fetchObras,
  drawerState,
  estadosProjetoPreSelectedId,
  estadosSubProjetoPreSelectedId,
  controloPreSelectedId,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div>
      <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={() => toggleDrawer('right', false)}
        classes={{ paper: classes.paper }}
      >
        <div className={classes.list}>
          {drawerState === 'edit' && (
            <DrawerForm
              obras={obras}
              toggleDrawer={toggleDrawer}
              classes={classes}
              estadosProjetoPreSelectedId={estadosProjetoPreSelectedId}
              estadosSubProjetoPreSelectedId={estadosSubProjetoPreSelectedId}
              fetchObras={fetchObras}
              theme={theme}
              controloPreSelectedId={controloPreSelectedId}
            />
          )}
          {drawerState === 'consultar' && (
            <DrawerFormConsulta toggleDrawer={toggleDrawer} classes={classes} />
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default SideDrawer;
