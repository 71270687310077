import Instance from "../../../../Axios";

// New Actions for the app

const GetSetorById = (id) => async (dispatch) => {
  await Instance()
    .get(`/energias/setores/${id}`)
    .then(({ data }) => {
      dispatch({ type: "GET_SETOR_BY_ID", payload: data.yData[0] });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetSetorById;