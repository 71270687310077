import moment from "moment";
import Instance from "../../../Axios";

const GetAllAdesoes =
  (service, status, date, responsable) => async (dispatch) => {
    const data = date ? moment(date).format("YYYY-MM-DD") : null;
    await Instance()
      .get(`/plus/adesoes`, {
        params: {
          service,
          status,
          data,
          responsable,
        },
      })
      .then(({ data }) => {
        dispatch({ type: "GET_ALL_ADESOES", payload: data.yData });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default GetAllAdesoes;
