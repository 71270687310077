export const credenciacoesAeroportosInitialState = {
  credenciacoes: [],
  credenciacao: {},
  credenciacaoAdicionada: null,
  credenciacaoEditada: {},
  credenciacaoEliminada: {},
  funcionarios: [],
  funcionario: {},
  funcionarioEditado: {},
  funcionarioAdicionado: [],
  funcionarioEliminado: {},
  funcionarioByNIF: [],
  aeroportos: [],
  aeroportosByFuncId: [],
  empresas: [],
  empresa: {},
  empresaAdicionada: {},
  empresaEditada: {},
  empresaEliminada: {},
  funcoes: [],
  habilitacoes: [],
  tipoDocs: [],
  tipoCartoes: [],
  tipoVinculos: [],
  cores: [],
  estados: [],
  estadosFormacoes: [],
  formacaoAdicionada: {},
  formacao: {},
  formacaoEditada: {},
  formacaoEliminada: {},
  resources: [],
  ficheiros: [],
  ficheirosViaturas: [],
  ficheirosViaturasPermanente: [],
  intervencaoElementos: [],
  intervencaoSelecionada: {},
  intervencaoElementoElim: {},
  intervencaoElementoAdd: {},
  intervencoesTotais: [],
  uploadedFiles: [],
  downloadFiles: [],
  viaturaUploadedFiles: [],
  inqueritoPessoal: {},
  copiaCredenciacao: {},
  copiaCredenciacaoUploaded: [],
  certificado: {},
  certificadoUploaded: {},
  tipoCredenciacoes: [],
  acompanhantes: [],
  acompanhantesDisponibilidade: [],
  acompanhante: {},
  motivos: [],
  tipoVeiculos: [],
  tipoCombustiveis: [],
  veiculosCores: [],
  portariaAcesso: [],
  veiculos: [],
  veiculosById: {},
  viaturas: [],
  viaturasById: {},
  viaturaEditada: {},
  viaturaAdicionada: {},
  pedidosAdicionaisDocs: [],
  notificacoes: [],
  credEstadosCount: [],
  credenciacoesByEstado: [],
  mediaDiasRespostaCredEstados: [],
  totalCredMes: [],
  credPermValidades: [],
  credDestinatarios: [],
  credDestinatarioById: [],
  alocacoes: [],
};
