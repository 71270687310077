import { Paper } from '@mui/material';
//Icons
import FeatherIcon from 'feather-icons-react';

const PoliticasCookies = ({ classes, toggleDrawer }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '40px',
        height: '100%',
      }}
    >
      <div className={classes.drawerHeader}>
        <div
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => toggleDrawer('right', false)}
        >
          <div>
            <FeatherIcon
              icon='chevron-left'
              size={20}
              style={{ strokeWidth: '1.5' }}
            />
          </div>
          <div className='text-underline-close-drawer'>Voltar</div>
        </div>
      </div>
      <Paper sx={{ px: 6, py: 2, mt: 4 }}>
        <h4
          style={{
            fontSize: '22px',
            width: '100%',
            marginBottom: '0px',
          }}
          className='text-underline'
        >
          Políticas de cookies
        </h4>
        <div style={{ height: '40px' }} />
      </Paper>
    </div>
  );
};

export default PoliticasCookies;
