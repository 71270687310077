import Instance from "../../../../Axios";

// New Actions for the app

const GetOrcamentosAdjudicados =
  (parceirosId, dataInicio, dataFim) => async (dispatch) => {
    await Instance()
      .get(`/energias/dashboard/orcamentosAdjudicados`, {
        params: { parceirosId, dataInicio, dataFim },
      })
      .then(({ data }) => {
        //   console.log(data);
        dispatch({ type: "GET_ORCAMENTOS_ADJUDICADOS", payload: data.yData });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default GetOrcamentosAdjudicados;
