import Instance from '../../../../Axios';

// New Actions for the app

const GetObras =
  (
    estadosProjetoPreSelectedId,
    estadosSubProjetoPreSelectedId,
    aeroportosId,
    estadosControloId,
    diretorObraId,
  ) =>
  async (dispatch) => {
    const estadosProjetosId = estadosProjetoPreSelectedId;
    const estadosSubProjetosId = estadosSubProjetoPreSelectedId;

    if (diretorObraId === '0') {
      diretorObraId = null;
    }

    await Instance()
      .get(`/construcoes/obras`, {
        params: {
          estadosProjetosId,
          estadosSubProjetosId,
          aeroportosId,
          estadosControloId,
          diretorObraId,
        },
      })
      .then(({ data }) => {
        // console.log(data);
        dispatch({ type: 'GET_OBRAS', payload: data.yData });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default GetObras;
