import Instance from "../../../../Axios";

// New Actions for the app

const GetAllPropostas =
  (estadosId, dataInicio, dataFim) => async (dispatch) => {
    await Instance()
      .get(`/energias/dashboard/allPropostas`, {
        params: {
          estadosId,
          dataInicio,
          dataFim,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: "GET_ALL_PROPOSTAS",
          payload: data.yData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default GetAllPropostas;
