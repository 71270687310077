/* eslint-disable eqeqeq */
import React from "react";
import {
  Button,
  Box,
  TextField,
  Autocomplete,
  DialogActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { yellow } from "@mui/material/colors";
import { useDispatch } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ptLocale from "date-fns/locale/pt";
import moment from "moment/moment";

const useStyles = makeStyles((theme) => ({
  modalBtns: {
    height: "40px",
    width: "125px",
    display: "flex",
    color: "#E21450",
    border: "1px solid grey",
    backgroundColor: yellow,
    "&:hover": {
      backgroundColor: yellow,
    },
    "@media (hover: none)": {
      backgroundColor: yellow,
    },
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
}));

const MyActionBar = ({ onAccept, onCancel, onClear, onSetToday }) => {
  return (
    <DialogActions>
      <Button onClick={onClear}> Todos </Button>
    </DialogActions>
  );
};

const FiltersIntervencoesCheck = ({
  user,
  fetchIntervencoes,
  dimensions,
  ano,
  setAno,
  // Situação
  situacaoPreSelectedId,
  estado,
  setEstado,
  tipoPropostas,
  subEstado,
  setSubEstado,
  subEstados,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    fetchIntervencoes(ano, estado, subEstado);
  };

  const handleChange = (ano) => {
    if (ano) {
      const dataAno = moment(ano).format("YYYY");
      setAno(dataAno);
      dispatch({
        type: "INTERVENCOES_ANO_SELECTED",
        payload: dataAno,
      });
    } else {
      setAno(null);
      dispatch({
        type: "INTERVENCOES_ANO_SELECTED",
        payload: null,
      });
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          // justifyContent: 'space-between',
          alignItems: "start",
          width: "100%",
          marginTop: "40px",
        }}
        className="not-selectable"
      >
        {/* <div
          style={{
            width: dimensions && dimensions.width < 600 ? "160px" : "250px",
          }}
        >
          <div>
            <div
              className="text-underline-title-drawer"
              style={{ width: "100%" }}
            >
              Situação
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {situacaoPreSelectedId &&
                situacaoPreSelectedId.map((situacao, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        minWidth: "33.3%",
                        cursor: "pointer",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            // eslint-disable-next-line eqeqeq
                            checked={situacao.estado}
                            // disabled={user && user.perfilId === 2 ? true : false}
                            onChange={() => {
                              situacaoPreSelectedId[index].estado =
                                !situacao.estado;

                              dispatch({
                                type: "SITUACAO_PRE_SELECTED",
                                payload: [...situacaoPreSelectedId],
                              });
                            }}
                            name={situacao.descricao}
                          />
                        }
                        label={situacao.descricao}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <span style={{ width: "2%" }} /> */}
        <Box sx={{ display: "flex" }}>
          <Box sx={{ mr: 2 }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={ptLocale}
            >
              <Box>
                <DatePicker
                  locale="pt"
                  inputFormat="yyyy"
                  views={["year"]}
                  label="Ano"
                  minDate={moment("2022-01-01").format("YYYY")}
                  maxDate={moment().add(5, "years").format("YYYY")}
                  value={ano}
                  onChange={handleChange}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} size="small" />
                  )}
                  components={{
                    ActionBar: MyActionBar,
                  }}
                  // slots={{
                  //   actionBar: MyActionBar
                  // }}
                  // componentsProps={{
                  //   // pass props `actions={['clear']}` to the actionBar slot
                  //   actionBar: { actions: ["clear"] },
                  // }}
                />
              </Box>
            </LocalizationProvider>
          </Box>
          <Box sx={{ mr: 2 }}>
            <Autocomplete
              fullWidth
              id="estado"
              name="estado"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              className="unidadesId-form"
              options={tipoPropostas || []}
              getOptionLabel={(option) => `${option.descricao}` || ""}
              value={estado}
              isOptionEqualToValue={(option, value) => {
                return value.id === option.id;
              }}
              onChange={(e, value, reason) => {
                if (reason == "clear") {
                  setEstado({ id: null, descricao: "" });
                } else {
                  setEstado(value);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Estado"
                  size="small"
                  sx={{ width: "230px" }}
                />
              )}
            />
          </Box>
          <Box sx={{ mr: 2 }}>
            <Autocomplete
              fullWidth
              id="subEstado"
              name="subEstado"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              className="unidadesId-form"
              options={subEstados || []}
              getOptionLabel={(option) => `${option.descricao}` || ""}
              value={subEstado}
              isOptionEqualToValue={(option, value) => {
                return value.id === option.id;
              }}
              onChange={(e, value, reason) => {
                if (reason == "clear") {
                  setSubEstado({ id: null, descricao: "" });
                } else {
                  setSubEstado(value);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Sub-Estado"
                  size="small"
                  sx={{ width: "230px" }}
                />
              )}
            />
          </Box>
          <Button
            onClick={handleSubmit}
            variant="contained"
            className={classes.btnFiltrar}
          >
            Filtrar
          </Button>
        </Box>
      </div>
    </>
  );
};

export default FiltersIntervencoesCheck;
