import React from 'react';
import { TextField, Typography } from '@mui/material';

const DetalhesControlled = ({ intervencaoSelecionada, obraId }) => {
  return (
    <>
      <div>
        <h4
          style={{ color: '#E21450' }}
          className='text-underline-title-drawer '
        >
          Detalhes
        </h4>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ width: '160px', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Id Intervenção
            </Typography>
            <TextField
              size='small'
              value={
                (intervencaoSelecionada && intervencaoSelecionada.id) || ' - '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <span style={{ width: '24px' }} />
          <div style={{ width: '528px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Nome do Pedido
            </Typography>

            <TextField
              size='small'
              value={
                (intervencaoSelecionada && intervencaoSelecionada.pedidoNome) ||
                ' - '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <span style={{ width: '24px' }} />
          <div
            style={{
              width: '344px',
              marginBottom: '16px',
            }}
          >
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Responsável
            </Typography>
            <TextField
              size='small'
              value={
                (intervencaoSelecionada &&
                  intervencaoSelecionada.funcionarioNome) ||
                ' - '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ width: '100%', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Descrição
            </Typography>
            <TextField
              size='small'
              value={
                (intervencaoSelecionada &&
                  intervencaoSelecionada.pedidoDescricao) ||
                ' - '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ width: '160px', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Id Proposta
            </Typography>

            <TextField
              value={obraId || ''}
              disabled
              size='small'
              sx={{ width: '100%' }}
            ></TextField>
          </div>
          <span style={{ width: '24px' }} />
          <div style={{ width: '712px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Proposta
            </Typography>

            <TextField
              size='small'
              value={
                (intervencaoSelecionada &&
                  intervencaoSelecionada.obraDescricao) ||
                ' - '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <span style={{ width: '24px' }} />
          <div style={{ width: '160px', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Valor da Proposta
            </Typography>
            <TextField
              size='small'
              value={
                intervencaoSelecionada.propostaValor
                  ? intervencaoSelecionada.propostaValor.toLocaleString('PT') +
                    ' €'
                  : ' - '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
        </div>

        <div style={{ display: 'flex' }}></div>
      </div>
    </>
  );
};

export default DetalhesControlled;
