/* eslint-disable array-callback-return */
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { useSelector } from "react-redux";
import { useState } from "react";

const useStyles = makeStyles(() => ({
  button: {
    display: "flex",
    justifyContent: "center",
    height: 40,
    borderTop: "4px solid transparent",
    borderBottom: "4px solid transparent",

    "&:hover": {
      borderRight: "4px solid #E21450",
    },
  },
  divider: {
    borderLeft: "4px solid #E21450",
    borderRadius: "5px 0px 0px 5px",
    orientation: "vertical",
    height: "30px",
    width: "5px",
  },
}));

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const userPerfil = useSelector((state) => state.user.perfilId);

  const permissoes = useSelector((state) => state.user.permissoes);

  const menuList1a = [
    {
      id: 1,
      tooltip: "Home",
      path: "/energias/dashboard",
      icon: "home",
      permission: "APP_ENERGIAS_DASHBOARD",
      fiIconCheck: true,
    },
    {
      id: 2,
      tooltip: "Estatísticas",
      path: "#",
      icon: "bar-chart-2",
      permission: permissoes.includes(
        "APP_ENERGIAS_ESTATISTICAS_CONTROLO_MENSAL"
      )
        ? "APP_ENERGIAS_ESTATISTICAS_CONTROLO_MENSAL"
        : permissoes.includes("APP_ENERGIAS_ESTATISTICAS_TIMELINE")
        ? "APP_ENERGIAS_ESTATISTICAS_TIMELINE"
        : null,
      fiIconCheck: true,
      submenu: [
        {
          id: 1,
          name: "Controlo Mensal",
          path: "/energias/controloMensal",
          permission: "APP_ENERGIAS_ESTATISTICAS_CONTROLO_MENSAL",
        },
        {
          id: 2,
          name: "Timeline",
          path: "/energias/timeline",
          permission: "APP_ENERGIAS_ESTATISTICAS_TIMELINE",
        },
      ],
    },
    {
      id: 3,
      tooltip: "Listas",
      path: "#",
      icon: "list",
      permission: permissoes.includes("APP_ENERGIAS_LISTAS_OBRAS_CONSULTA")
        ? "APP_ENERGIAS_LISTAS_OBRAS_CONSULTA"
        : permissoes.includes("APP_ENERGIAS_LISTAS_PROPOSTAS_CONSULTA")
        ? "APP_ENERGIAS_LISTAS_PROPOSTAS_CONSULTA"
        : null,
      fiIconCheck: true,
      submenu: [
        {
          id: 1,
          name: "Lista Obras",
          path: "/energias/obras",
          permission: "APP_ENERGIAS_LISTAS_OBRAS_CONSULTA",
        },
        {
          id: 2,
          name: "Lista Propostas",
          path: "/energias/propostas",
          permission: "APP_ENERGIAS_LISTAS_PROPOSTAS_CONSULTA",
        },
      ],
    },
    {
      id: 4,
      tooltip: "Manutenções",
      path: "/energias/manutencoes",
      icon: "tool",
      permission: "APP_ENERGIAS_DASHBOARD",
      fiIconCheck: true,
    },
    {
      id: 5,
      tooltip: "Documentos Oficiais",
      path: "/energias/documentos",
      icon: "file",
      permission: "APP_ENERGIAS_DOCS_CONSULTAR",
      fiIconCheck: true,
    },
    {
      id: 6,
      tooltip: "Fichas Técnicas",
      path: "/energias/fichas",
      icon: "file-text",
      permission: "APP_ENERGIAS_FICHASTEC_CONSULTAR",
      fiIconCheck: true,
    },
  ];

  const menuList1p = [
    {
      tooltip: "Home",
      path: "/energias/dashboard",
      icon: "home",
      permission: "APP_ENERGIAS_DASHBOARD",
      fiIconCheck: true,
    },
    {
      id: 3,
      tooltip: "Listas",
      path: "#",
      icon: "list",
      permission: permissoes.includes("APP_ENERGIAS_LISTAS_OBRAS_CONSULTA")
        ? "APP_ENERGIAS_LISTAS_OBRAS_CONSULTA"
        : permissoes.includes("APP_ENERGIAS_LISTAS_PROPOSTAS_CONSULTA")
        ? "APP_ENERGIAS_LISTAS_PROPOSTAS_CONSULTA"
        : null,
      fiIconCheck: true,
      submenu: [
        {
          id: 1,
          name: "Lista Obras",
          path: "/energias/obras",
          permission: "APP_ENERGIAS_LISTAS_OBRAS_CONSULTA",
        },
        {
          id: 2,
          name: "Lista Propostas",
          path: "/energias/propostas",
          permission: "APP_ENERGIAS_LISTAS_PROPOSTAS_CONSULTA",
        },
      ],
    },
    {
      tooltip: "Manutenções",
      path: "/energias/manutencoes",
      icon: "tool",
      permission: "",
      fiIconCheck: true,
    },
    {
      tooltip: "Documentos Oficiais",
      path: "/energias/documentos",
      icon: "file",
      permission: "APP_ENERGIAS_DOCS_CONSULTAR",
      fiIconCheck: true,
    },
    {
      tooltip: "Fichas Técnicas",
      path: "/energias/fichas",
      icon: "file-text",
      permission: "APP_ENERGIAS_FICHASTEC_CONSULTAR",
      fiIconCheck: true,
    },
  ];

  const menuList2 = [
    {
      tooltip: "Empresas",
      path: "/energias/empresas",
      icon: <HiOutlineBuildingOffice2 size={32} />,
      permission: "APP_ENERGIAS_FABRICANTES_CONSULTAR",
      fiIconCheck: false,
    },
    {
      tooltip: "Setores",
      path: "/energias/setores",
      icon: "pie-chart",
      permission: "APP_ENERGIAS_SETORES_CONSULTAR",
      fiIconCheck: true,
    },
  ];

  const menuList3 = [
    {
      tooltip: "Blog",
      path: "/energias/blog",
      icon: "book-open",
      fiIconCheck: true,
    },
  ];

  const [state, setState] = useState({
    settings: [
      { id: 2, open: false },
      { id: 3, open: false },
    ],
  });

  const handleClick = (id) => {
    setState((state) => ({
      ...state,
      settings: state.settings.map((item) =>
        item.id === id
          ? { ...item, open: !item.open }
          : { ...item, open: false }
      ),
    }));
  };

  const settings = state.settings;

  return (
    <Box
      sx={{
        display: "flex",
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          opacity: 1,
          boxShadow: "0px 5px 15px lightgrey",
          width: "70px",
          height: "100%",
          position: "fixed",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          mt: -6,
          pb: 2,
        }}
      >
        <Box>
          <Box sx={{ mt: 9 }}></Box>
          <List
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            {userPerfil !== 5 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: 4,
                  justifyContent: "space-between",
                  height: "auto",
                }}
              >
                {menuList1a.map((item, index) => (
                  <>
                    {permissoes.includes(item.permission) ? (
                      <ListItem
                        key={item.id}
                        disablePadding
                        onClick={() => navigate(item.path)}
                        sx={{ mb: 4 }}
                      >
                        <Tooltip title={item.tooltip} placement="right">
                          <ListItemButton
                            sx={{
                              borderRight:
                                location.pathname === item.path ||
                                (item.submenu &&
                                  item.submenu
                                    .map((item) => item.path)
                                    .includes(location.pathname))
                                  ? "4px solid #E21450"
                                  : null,
                            }}
                            className={classes.button}
                            onClick={() => handleClick(item.id)}
                          >
                            <ListItemIcon
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              {item.fiIconCheck === true ? (
                                <FeatherIcon
                                  icon={item.icon}
                                  color={
                                    location.pathname === item.path
                                      ? "#E21450"
                                      : "#707070"
                                  }
                                  size={30}
                                  strokeWidth={1.5}
                                />
                              ) : (
                                item.icon
                              )}
                            </ListItemIcon>
                          </ListItemButton>
                        </Tooltip>
                      </ListItem>
                    ) : null}
                    {item && item.submenu && item.submenu.length > 0 ? (
                      <Collapse
                        in={settings.find((elem) => elem.id === item.id).open}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box
                          sx={{
                            opacity: 1,
                            boxShadow: "5px 0px 15px lightgrey",
                            width: "160px",
                            height: "auto",
                            position: "fixed",
                            background: "#fff",
                            display: "flex",
                            flexDirection: "column",
                            left: "70px",
                            mt: -9,
                          }}
                        >
                          <List
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                            }}
                          >
                            {item.submenu.map((elem) => (
                              <>
                                {permissoes.includes(elem.permission) ? (
                                  <ListItem
                                    disablePadding
                                    onClick={() => {
                                      console.log(elem.path);
                                      navigate(elem.path);
                                    }}
                                  >
                                    <ListItemButton
                                      className={classes.button}
                                      onClick={() => handleClick(item.id)}
                                    >
                                      <ListItemText primary={elem.name} />
                                    </ListItemButton>
                                  </ListItem>
                                ) : null}
                              </>
                            ))}
                          </List>
                        </Box>
                      </Collapse>
                    ) : null}

                    {/* {location.pathname == item.path ? <Divider orientation="vertical" className={classes.divider} /> : null} */}
                  </>
                ))}
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: 4,
                  justifyContent: "space-between",
                  height: "auto",
                }}
              >
                {menuList1p.map((item, index) => (
                  <>
                    {permissoes.includes(item.permission) ? (
                      <ListItem
                        disablePadding
                        onClick={() => navigate(item.path)}
                        sx={{ mb: 4 }}
                      >
                        <Tooltip title={item.tooltip} placement="right">
                          <ListItemButton
                            sx={{
                              borderRight:
                                location.pathname === item.path
                                  ? "4px solid #E21450"
                                  : null,
                            }}
                            className={classes.button}
                            onClick={() => handleClick(item.id)}
                          >
                            <ListItemIcon
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              {item.fiIconCheck === true ? (
                                <FeatherIcon
                                  icon={item.icon}
                                  color={
                                    location.pathname === item.path
                                      ? "#E21450"
                                      : "#707070"
                                  }
                                  size={30}
                                  strokeWidth={1.5}
                                />
                              ) : (
                                item.icon
                              )}
                            </ListItemIcon>
                          </ListItemButton>
                        </Tooltip>
                        {/* {location.pathname == item.path ? <Divider orientation="vertical" className={classes.divider} /> : null} */}
                      </ListItem>
                    ) : null}
                    {item && item.submenu && item.submenu.length > 0 ? (
                      <Collapse
                        in={settings.find((elem) => elem.id === item.id).open}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box
                          sx={{
                            opacity: 1,
                            boxShadow: "5px 0px 15px lightgrey",
                            width: "160px",
                            height: "auto",
                            position: "fixed",
                            background: "#fff",
                            display: "flex",
                            flexDirection: "column",
                            left: "70px",
                            mt: -9,
                          }}
                        >
                          <List
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                            }}
                          >
                            {item.submenu.map((elem) => (
                              <>
                                {permissoes.includes(elem.permission) ? (
                                  <ListItem
                                    disablePadding
                                    onClick={() => {
                                      console.log(elem.path);
                                      navigate(elem.path);
                                    }}
                                  >
                                    <ListItemButton
                                      className={classes.button}
                                      onClick={() => handleClick(item.id)}
                                    >
                                      <ListItemText primary={elem.name} />
                                    </ListItemButton>
                                  </ListItem>
                                ) : null}
                              </>
                            ))}
                          </List>
                        </Box>
                      </Collapse>
                    ) : null}
                  </>
                ))}
              </Box>
            )}

            {userPerfil !== 5 ? (
              <>
                <Divider variant="middle" sx={{ mb: 2, mt: -2 }} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "auto",
                  }}
                >
                  {menuList2.map((item, index) => (
                    <>
                      {permissoes.includes(item.permission) ? (
                        <ListItem
                          disablePadding
                          onClick={() => navigate(item.path)}
                          sx={{ mb: 4 }}
                        >
                          <Tooltip title={item.tooltip} placement="right">
                            <ListItemButton
                              sx={{
                                borderRight:
                                  location.pathname === item.path
                                    ? "4px solid #E21450"
                                    : null,
                              }}
                              className={classes.button}
                              onClick={() => handleClick(item.id)}
                            >
                              <ListItemIcon
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {item.fiIconCheck === true ? (
                                  <FeatherIcon
                                    icon={item.icon}
                                    color={
                                      location.pathname === item.path
                                        ? "#E21450"
                                        : "#707070"
                                    }
                                    size={30}
                                    strokeWidth={1.5}
                                  />
                                ) : (
                                  item.icon
                                )}
                              </ListItemIcon>
                            </ListItemButton>
                          </Tooltip>
                          {/* {location.pathname == item.path ? <Divider orientation="vertical" className={classes.divider} /> : null} */}
                        </ListItem>
                      ) : null}
                    </>
                  ))}
                </Box>
              </>
            ) : null}
            <Divider variant="middle" sx={{ mb: 2, mt: -2 }} />
            {menuList3.map((item, index) => (
              <>
                <ListItem disablePadding onClick={() => navigate(item.path)}>
                  <Tooltip title={item.tooltip} placement="right">
                    <ListItemButton
                      sx={{
                        borderRight:
                          location.pathname === item.path
                            ? "4px solid #E21450"
                            : null,
                      }}
                      className={classes.button}
                      onClick={() => handleClick(item.id)}
                    >
                      <ListItemIcon
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <FeatherIcon
                          icon={item.icon}
                          color="#707070"
                          size={30}
                          strokeWidth={1.5}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </Tooltip>
                  {/* {location.pathname == item.path ? <Divider orientation="vertical" className={classes.divider} /> : null} */}
                </ListItem>
              </>
            ))}

            <Divider
              variant="middle"
              sx={{
                mt: 2,
              }}
            />
          </List>
        </Box>
        <Box>
          <Divider
            variant="middle"
            sx={{
              mb: 2,
            }}
          />
          <List>
            <ListItem disablePadding>
              <Tooltip title="Sair" placement="right">
                <ListItemButton
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <ListItemIcon
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <FeatherIcon
                      icon="log-out"
                      color="#707070"
                      size={30}
                      strokeWidth={1.5}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </List>
        </Box>
      </Box>
      <Box sx={{ pl: 8 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Sidebar;
