import Instance from '../../../../Axios';
import { format } from 'date-fns';

// New Actions for the app

const GetIntervencoesInfoGraficos =
  (departamentosId, dataInicio, dataFim) => async (dispatch) => {
    dataInicio = dataInicio && format(new Date(dataInicio), 'yyyy-MM-dd');
    dataFim = dataFim && format(new Date(dataFim), 'yyyy-MM-dd');

    await Instance()
      .get(`/intervencoes/dashboard`, {
        params: { departamentosId, dataInicio, dataFim },
      })
      .then(({ data }) => {
        //   console.log(data);
        dispatch({
          type: 'GET_INTERVENCOES_INFO_GRAFICOS',
          payload: data.yData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default GetIntervencoesInfoGraficos;
