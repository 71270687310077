import React from 'react';
import { TextField } from '@mui/material';
import { useSelector } from 'react-redux';

import { format } from 'date-fns';

const Especialidade = ({ especialidade }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '5px',
        }}
      >
        <p
          style={{
            height: '30',
            width: '15%',
            padding: '0px',
            margin: '0px 0px 0px 10px',
            color: '#000',
          }}
        >
          {especialidade.especialidadesDescricao}
        </p>

        <form
          style={{
            width: '100%',
            display: 'flex',
          }}
          autocomple='off'
        >
          <TextField
            size='small'
            value={especialidade.estadosDescricao || ' '}
            disabled
            style={{
              width: '18%',
              marginRight: '2.5%',
            }}
            variant='standard'
            placeholder='Selecione uma opção'
            InputProps={{ disableUnderline: true }}
          />

          <TextField
            size='small'
            value={
              especialidade.dataInicio
                ? format(new Date(especialidade.dataInicio), 'dd/MM/yyyy')
                : ' --/--/---- '
            }
            disabled
            style={{
              width: '12%',
              marginRight: '2.5%',
            }}
            variant='standard'
            placeholder='Selecione uma opção'
            InputProps={{ disableUnderline: true }}
          />

          <TextField
            size='small'
            value={
              especialidade.dataFim
                ? format(new Date(especialidade.dataFim), 'dd/MM/yyyy')
                : ' --/--/---- '
            }
            disabled
            style={{
              width: '12%',
              marginRight: '2.5%',
              border: 'none',
              color: '#000 !important',
            }}
            variant='standard'
            placeholder='Selecione uma opção'
            InputProps={{ disableUnderline: true }}
          />

          <TextField
            size='small'
            value={especialidade.observacoes || ' '}
            disabled
            style={{
              width: '51%',
            }}
            variant='standard'
            placeholder='Selecione uma opção'
            InputProps={{ disableUnderline: true }}
          />
        </form>
      </div>
    </>
  );
};

const Especialidades = ({ obraSelecionada, control }) => {
  const estadosEspecialidade = useSelector(
    (state) => state.obras.estadosEspecialidade
  );

  return (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '5px',
            background: '#F1F1F1',
            border: '1px solid #707070',
            borderRadius: '8px',
          }}
        >
          <h3
            style={{
              width: '13%',
              margin: '10px 0px 10px 10px',
              padding: '0px',
              fontSize: '16px',
            }}
          >
            Especialidades
          </h3>

          <h3
            style={{
              width: '15.5%',
              margin: '10px 2% 10px 0px',
              padding: '0px',
              fontSize: '16px',
            }}
          >
            Estado
          </h3>

          <h3
            style={{
              width: '10.5%',
              margin: '10px 2% 10px 0px',
              padding: '0px',
              fontSize: '16px',
            }}
          >
            Data Início
          </h3>

          <h3
            style={{
              width: '10.5%',
              margin: '10px 2% 10px 0px',
              padding: '0px',
              fontSize: '16px',
            }}
          >
            Data Fim
          </h3>

          <h3
            style={{
              width: '10.5%',
              margin: '10px 0px 10px 0px',
              padding: '0px',
              fontSize: '16px',
            }}
          >
            Observações
          </h3>
        </div>

        <>
          {obraSelecionada &&
            obraSelecionada.especialidades &&
            obraSelecionada.especialidades.map((especialidade, index) => (
              <div
                key={index}
                style={{
                  background: 'white',
                  minHeight: '40px',
                  marginTop: '10px',
                  marginBottom: '10px',
                  border: '1px solid #707070',
                  borderRadius: '8px',
                }}
              >
                <>
                  <Especialidade
                    especialidade={especialidade}
                    estadosEspecialidade={estadosEspecialidade}
                    index={index}
                    control={control}
                  />
                </>
              </div>
            ))}
        </>
      </div>
    </>
  );
};

export default Especialidades;
