import Instance from "../../../Axios";

const DeleteAdesaoFilesCompExcById = (id) => async (dispatch) => {
  await Instance()
    .put(`/plus/adesoes/${id}/delete/filesCompExc`)
    .then(({ data }) => {
      dispatch({
        type: "DELETE_ADESAO_FILE_COMP_EXC_BY_ID",
        payload: data.yData,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default DeleteAdesaoFilesCompExcById;
