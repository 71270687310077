/* eslint-disable eqeqeq */
import React, { useState } from "react";
import {
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FaPlay, FaStop } from "react-icons/fa";
import FeatherIcon from "feather-icons-react";
import { Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptLocale from "date-fns/locale/pt";
import { format } from "date-fns";
import Instance from "../../../../../../../utils/Axios";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

//Components
import HistoricoRevisoes from "./historicoRevisoes";


const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "100%",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnStop: {
    color: "black",
    width: "100%",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#fff",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#f0d8df",
      border: "1px solid #bc1041",
    },
  },
});

const calendarIcon = () => {
  return <FeatherIcon icon="calendar" size={20} />;
};

const Revisoes = ({
  intervencaoSelecionada,
  setIntervencaoSelecionada,
  fetchIntervencoes,
  control,
  getValues,
  resetForm,
  setResetForm,
  obraId,
  setObraId,
  reset,
  setValue,
  watch,

  //Errors
  ShowNotification,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [revisoeswaiting, setRevisoesWaiting] = useState(false);

  const messageIniciar = {
    isOpen: true,
    message: "Revisão iniciada!",
    type: "success",
  };
  const messageParar = {
    isOpen: true,
    message: "Revisão terminada. Verifique o histórico.",
    type: "success",
  };
  const errorMessage = {
    isOpen: true,
    message: "Ocorreu um erro!!",
    type: "error",
  };
  const verificarnRevisao = {
    isOpen: true,
    message: "Preencha o número de revisão!!",
    type: "warning",
  };

  const intervencao = getValues();

  useEffect(() => {
    if (intervencao && intervencao.revisaoAtiva) {
      if (
        intervencao.revisaoAtiva.dataInicio == null &&
        intervencao.revisaoAtiva.dataFim == null
      ) {
        setRevisoesWaiting(false);
      } else if (
        intervencao.revisaoAtiva.dataInicio != null &&
        intervencao.revisaoAtiva.dataFim == null
      ) {
        setRevisoesWaiting(true);
      }
    }
  }, [intervencao]);

  const verificarRevisao = () => {
    const verifica = !!getValues(`revisaoAtiva[nRevisao]`);
    return verifica;
  };

  const handleIniciarRevisao = () => {
    setRevisoesWaiting(true);

    const intId = intervencaoSelecionada && intervencaoSelecionada.id;

    let formatedData = null;

    if (intervencao.revisaoAtiva.dataInicio == null) {
      formatedData = format(new Date(), "yyyy-MM-dd");
      setValue(`revisaoAtiva[dataInicio]`, format(new Date(), "yyyy-MM-dd"));
    } else {
      formatedData = format(
        new Date(intervencao.revisaoAtiva.dataInicio),
        "yyyy-MM-dd"
      );
    }

    const ydata = [
      {
        dataInicio: formatedData,
        nRevisao: intervencao.revisaoAtiva.nRevisao,
      },
    ];

    Instance()
      .put(`intervencoes/intervencoes/${intId}/iniciarRevisao`, { ydata })
      .then(({ data }) => {
        dispatch(ShowNotification(messageIniciar));
        fetchIntervencoes();
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          if (err.response === 403) {
            dispatch(ShowNotification(errorMessage));
          }
        }
      });
  };

  const handleFinalizarRevisao = () => {
    setRevisoesWaiting(false);

    const intId = intervencaoSelecionada && intervencaoSelecionada.id;

    let formatedData = null;

    if (intervencao.revisaoAtiva.dataFim == null) {
      formatedData = format(new Date(), "yyyy-MM-dd");
      setValue(`revisaoAtiva[dataFim]`, format(new Date(), "yyyy-MM-dd"));
    } else {
      formatedData = format(
        new Date(intervencao.revisaoAtiva.dataFim),
        "yyyy-MM-dd"
      );
    }

    const nRevisao = intervencao.revisaoAtiva.nRevisao;

    const ydata = [
      {
        dataFim: formatedData,
        nRevisao: nRevisao,
      },
    ];

    console.log(ydata);

    Instance()
      .put(`intervencoes/intervencoes/${intId}/finalizarRevisao`, { ydata })
      .then(({ data }) => {
        dispatch(ShowNotification(messageParar));
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          if (err.response === 403) {
            dispatch(ShowNotification(errorMessage));
          }
        }
      });

    //Inserção no histórico

    handleAddSub(formatedData, nRevisao);
  };

  const handleAddSub = (formatedData, nRevisao) => {
    setResetForm(true);

    const novaRevisao = {
      dataFim: formatedData,
      dataInicio: intervencao.revisaoAtiva.dataInicio,
      nRevisao: nRevisao,
    };

    intervencaoSelecionada.revisoes.push(novaRevisao);
    setIntervencaoSelecionada({ ...intervencaoSelecionada });
    setResetForm(false);

    setValue(`revisaoAtiva[dataInicio]`, null);
    setValue(`revisaoAtiva[dataFim]`, null);
    setValue(`revisaoAtiva[nRevisao]`, "");
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <h4
          style={{ color: "#E21450" }}
          className="text-underline-title-drawer"
        >
          Revisões
        </h4>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div style={{ width: "15%", marginBottom: "16px" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Data de Inicio
            </Typography>
            {/* <TextField
              size='small'
              value={
                (intervencaoSelecionada &&
                  intervencaoSelecionada.dataCriacao) ||
                ' --/--/---- '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            /> */}

            <Controller
              name={`revisaoAtiva[dataInicio]`}
              control={control}
              render={({ field }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <DatePicker
                    invalidDateMessage="Data Inválida"
                    {...field}
                    minDate={new Date(intervencao.pedidoData)}
                    components={{
                      OpenPickerIcon: calendarIcon,
                    }}
                    disabled
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        // InputProps={{
                        //   ...params.InputProps,
                        //   disableUnderline: true,
                        // }}
                        // variant='standard'
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </div>

          <div style={{ width: "15%", marginBottom: "16px" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Data de Fim
            </Typography>
            <Controller
              name={`revisaoAtiva[dataFim]`}
              control={control}
              render={({ field }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <DatePicker
                    invalidDateMessage="Data Inválida"
                    {...field}
                    components={{
                      OpenPickerIcon: calendarIcon,
                    }}
                    disabled
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        // InputProps={{
                        //   ...params.InputProps,
                        //   disableUnderline: true,
                        // }}
                        // variant='standard'
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </div>
          <div style={{ width: "49%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Nº Revisão
            </Typography>
            <Controller
              name={`revisaoAtiva[nRevisao]`}
              control={control}
              render={({ field }) => (
                <>
                  <TextField
                    color="primary"
                    placeholder="Nº Revisão"
                    size="small"
                    {...field}
                    style={{ width: "100%" }}
                  />
                </>
              )}
            />
          </div>
          <div
            style={{
              width: "15%",
              display: "flex",
              alignItems: "center",
              marginTop: "18px",
            }}
          >
            {revisoeswaiting == false ? (
              <Button
                className={classes.btnGuardar}
                onClick={() => handleIniciarRevisao()}
              >
                <FaPlay style={{ marginRight: "10px" }} />
                Começar
              </Button>
            ) : (
              <Button
                className={classes.btnStop}
                onClick={() => {
                  if (verificarRevisao()) {
                    handleFinalizarRevisao();
                  } else {
                    dispatch(ShowNotification(verificarnRevisao));
                  }
                }}
              >
                <FaStop style={{ marginRight: "10px" }} />
                Parar
              </Button>
            )}
          </div>
          {/* <div
            style={{
              width: '40px',
              height: '40px',
              marginTop: '33px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Tooltip title={'Mostrar histórico'}>
              <IconButton
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={() => setShowHistorico(!showHistorico)}
                color='inherit'
              >
                <FeatherIcon
                  icon='rotate-cw'
                  size={20}
                  // style={
                  //   item.selected === 0
                  //     ? { color: '#E21450', strokeWidth: '1px' }
                  //     : { color: '#333', strokeWidth: '1px' }
                  // }
                />
              </IconButton>
            </Tooltip>
          </div> */}
        </div>
        <div style={{ display: "flex" }}>
          <HistoricoRevisoes
            intervencaoSelecionada={intervencaoSelecionada}
            setIntervencaoSelecionada={setIntervencaoSelecionada}
            control={control}
            getValues={getValues}
            resetForm={resetForm}
            setResetForm={setResetForm}
            obraId={obraId}
            setObraId={setObraId}
            reset={reset}
            watch={watch}
          />
        </div>
      </div>
    </>
  );
};

export default Revisoes;
