import React from "react";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "../../../../../assets/images/grupoJaf_logo.png";

// Create styles
const styles = StyleSheet.create({
  page: {
    width: "100%",
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  logo: {
    width: "120px",
  },
  titulo: {
    fontSize: 15,
    marginTop: 20,
  },
  totalPages: {
    fontSize: 12,
    margin: 10,
    display: "flex",
    alignSelf: "flex-end",
  },
});

// Create Document Component
const MyDocument = () => {
  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape" wrap>
        <View style={styles.section}>
          <Image src={logo} style={styles.logo} />
          <Text style={styles.titulo}>Titulo</Text>
          {/* <ReactApexChart
            options={options}
            series={series}
            type="rangeBar"
            height={height}
            width={width}
          /> */}
        </View>
        <Text
          render={({ pageNumber, totalPages }) => 
            `Página ${pageNumber} de ${totalPages}`
          }
          style={styles.totalPages}
        />
      </Page>
    </Document>
  );
};

export default MyDocument;
