import React from 'react';
import { TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

const MaoDeObra = ({ control }) => {
  return (
    <>
      <div style={{ marginLeft: '30px' }}>
        <h4
          style={{ color: '#E21450' }}
          className='text-underline-title-drawer '
        >
          Mão de Obra
        </h4>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '292px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Contratados{' '}
            </Typography>
            <Controller
              name='maoObraContratada'
              control={control}
              render={({ field }) => (
                <>
                  <TextField
                    // label='Contratados'
                    color='primary'
                    variant='outlined'
                    size='small'
                    {...field}
                    style={{ width: '100%' }}
                  />
                </>
              )}
            />
          </div>
          <span style={{ width: '30px' }} />
          <div style={{ width: '292px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              JAF
            </Typography>
            <Controller
              name='maoObraJaf'
              control={control}
              render={({ field }) => (
                <>
                  <TextField
                    name='maoObraJaf'
                    // label='JAF'
                    color='primary'
                    variant='outlined'
                    size='small'
                    {...field}
                    autoComplete='off'
                    style={{ width: '100%' }}
                  />
                </>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MaoDeObra;
