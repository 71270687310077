/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';
import { Routes, Route, Link, Navigate } from 'react-router-dom';

const Home = ({ redirectPath = '/landing' }) => {
  const user = useSelector((state) => state.user);
  console.log(user);
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return <h2>Hello from Home 🏠</h2>;
};

export default Home;
