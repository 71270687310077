import React from "react";
import { makeStyles, withStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  Drawer,
  FormControlLabel,
  TextField,
  Checkbox,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptLocale from "date-fns/locale/pt";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch } from "react-redux";

const CustomCheckbox = withStyles({
  root: {
    color: "#707070",
    "&$checked": {
      color: "#707070",
    },
    background: "transparent !important",
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
});

const FilterDrawer = ({
  state,
  toggleDrawer,
  drawerState,
  drawerWidth,
  dimensions,
  fetchPropostasTimeline,
  estadoProjeto,
  estadoSubProjeto,
  estadosProjetoPreSelectedId,
  estadosSubProjetoPreSelectedId,
  dataInicio,
  dataFim,
  setDataInicio,
  setDataFim,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();


  const handleDataInicioChange = (date) => {
    setDataInicio(date);
    dispatch({
      type: "TIMELINE_DATA_INICIO",
      payload: date,
    });
  };

  const handleDataFimChange = (date) => {
    setDataFim(date);
    dispatch({
      type: "TIMELINE_DATA_FIM",
      payload: date,
    });
  };

  const handleSubmit = () => {
    const eProjetosId = estadosProjetoPreSelectedId.map((ep) => ep.id);
    const eSubProjetosId = estadosSubProjetoPreSelectedId.map((ep) => ep.id);

    fetchPropostasTimeline(eProjetosId, eSubProjetosId, dataInicio, dataFim);
  };

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={() => toggleDrawer("right", false)}
        classes={{ paper: classes.paper }}
      >
        <div
          className={classes.list}
          style={
            dimensions.width <= 1100
              ? { width: "60vw" }
              : { width: `${drawerWidth}` }
          }
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              m: 5,
            }}
          >
            <Box
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              onClick={() => toggleDrawer("right", false)}
            >
              <div>
                <FeatherIcon
                  icon="chevron-left"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </div>
              <div className="text-underline-close-drawer">Dashboard</div>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                className={classes.btnFiltrar}
                onClick={() => {
                  handleSubmit();
                  toggleDrawer("right", false);
                }}
              >
                Filtrar
              </Button>
            </Box>
          </Box>
          <Card sx={{ m: 5, p: 2, boxShadow: "0px 10px 15px lightgrey" }}>
            <Box
              sx={{
                m: 2,
              }}
            >
              <h4
                style={{
                  fontSize: "22px",
                  width: "100%",
                  marginBottom: "0px",
                }}
                className="text-underline"
              >
                Filtros
              </h4>
            </Box>
            <Box sx={{ m: 2 }}>
              <h4
                style={{ color: "#E21450" }}
                className="text-underline-title-drawer "
              >
                Data de criação
              </h4>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                m: 2,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", mr: 1 }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <b>Data Inicio</b>
                  <DatePicker
                    style={{ width: "100%" }}
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    okLabel="ok"
                    cancelLabel="cancelar"
                    invalidDateMessage="Data Inválida"
                    value={dataInicio}
                    onChange={handleDataInicioChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", ml: 1 }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <b>Data Fim</b>
                  <DatePicker
                    style={{ width: "100%" }}
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    okLabel="ok"
                    cancelLabel="cancelar"
                    invalidDateMessage="Data Inválida"
                    value={dataFim}
                    onChange={handleDataFimChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box sx={{ m: 2 }}>
              <h4
                style={{ color: "#E21450" }}
                className="text-underline-title-drawer "
              >
                Estados Projeto
              </h4>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 2,
                mx: 3,
              }}
            >
              {estadoProjeto.map((estado, index) => {
                const estadoExists = !estadosProjetoPreSelectedId.some(
                  (o2) => o2.id === estado.id
                );

                return (
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        eslint-disable-next-line
                        eqeqeq
                        checked={!estadoExists}
                        onChange={() => {
                          if (!estadoExists) {
                            dispatch({
                              type: "GET_ESTADOS_PROJETO_PRE_SELECTED",
                              payload: estadosProjetoPreSelectedId.filter(
                                (est) => est.id !== estado.id
                              ),
                            });
                          } else {
                            dispatch({
                              type: "GET_ESTADOS_PROJETO_PRE_SELECTED",
                              payload: [...estadosProjetoPreSelectedId, estado],
                            });
                          }
                        }}
                        name={estado.descricao}
                      />
                    }
                    label={estado.descricao}
                  />
                );
              })}
            </Box>
            <Box sx={{ m: 2 }}>
              <h4
                style={{ color: "#E21450" }}
                className="text-underline-title-drawer "
              >
                Estados Sub-Projeto
              </h4>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                mt: 2,
                mx: 3,
              }}
            >
              {estadoSubProjeto.map((estado, index) => {
                const estadoExists = !estadosSubProjetoPreSelectedId.some(
                  (o2) => o2.id === estado.id
                );

                return (
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        eslint-disable-next-line
                        eqeqeq
                        checked={!estadoExists}
                        onChange={() => {
                          if (!estadoExists) {
                            dispatch({
                              type: "GET_ESTADOS_SUB_PROJETO_PRE_SELECTED",
                              payload: estadosSubProjetoPreSelectedId.filter(
                                (est) => est.id !== estado.id
                              ),
                            });
                          } else {
                            dispatch({
                              type: "GET_ESTADOS_SUB_PROJETO_PRE_SELECTED",
                              payload: [
                                ...estadosSubProjetoPreSelectedId,
                                estado,
                              ],
                            });
                          }
                        }}
                        name={estado.descricao}
                      />
                    }
                    label={estado.descricao}
                  />
                );
              })}
            </Box>
          </Card>
        </div>
      </Drawer>
    </div>
  );
};

export default FilterDrawer;
