import React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  FormControlLabel,
  Checkbox,
  Box,
  TextField,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptLocale from "date-fns/locale/pt";

const CustomCheckbox = withStyles({
  root: {
    color: "#707070",
    "&$checked": {
      color: "#707070",
    },
    background: "transparent !important",
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  modalBtns: {
    // paddingTop: 20,
    height: "40px",
    width: "125px",
    display: "flex",
    // flexWrap: 'wrap',
    // width: '100%',
    // justifyContent: 'start',
  },

  btnFiltrar: {
    color: "#E21450",
    width: "125px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
}));

const FiltersPropostas = ({
  estadoProposta,
  estadosPropostasPreSelectedId,
  fetchPropostas,
  dataInicio,
  dataFim,
  setDataInicio,
  setDataFim,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleDataInicioChange = (date) => {
    setDataInicio(date);
    dispatch({
      type: "PROPOSTAS_DATA_INICIO",
      payload: date,
    });
  };

  const handleDataFimChange = (date) => {
    setDataFim(date);
    dispatch({
      type: "PROPOSTAS_DATA_FIM",
      payload: date,
    });
  };

  const handleSubmit = () => {
    const estadosId = estadosPropostasPreSelectedId.map((ep) => ep.id);
    fetchPropostas(estadosId, dataInicio, dataFim);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          // justifyContent: 'space-between',
          alignItems: "start",
          width: "100%",
          marginTop: "40px",
        }}
        className="not-selectable"
      >
        <div style={{ width: "17%" }}>
          <div>
            <div
              className="text-underline-title-drawer"
              style={{ width: "100%" }}
            >
              Estados da Proposta
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {estadoProposta.map((estado, index) => {
                const estadoExists = !estadosPropostasPreSelectedId.some(
                  (o2) => o2.id === estado.id
                );
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "50%",
                      cursor: "pointer",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          checked={!estadoExists}
                          onChange={() => {
                            if (!estadoExists) {
                              dispatch({
                                type: "ESTADOS_PROPOSTAS_PRE_SELECTED",
                                payload: estadosPropostasPreSelectedId.filter(
                                  (est) => est.id !== estado.id
                                ),
                              });
                            } else {
                              dispatch({
                                type: "ESTADOS_PROPOSTAS_PRE_SELECTED",
                                payload: [
                                  ...estadosPropostasPreSelectedId,
                                  estado,
                                ],
                              });
                            }
                          }}
                          name={estado.descricao}
                        />
                      }
                      label={estado.descricao}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <span style={{ width: "2%" }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mx: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", mr: 1 }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={ptLocale}
            >
              <div
                className="text-underline-title-drawer"
                style={{ width: "100%" }}
              >
                Data Inicio
              </div>
              <DatePicker
                style={{ width: "100%" }}
                margin="normal"
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                okLabel="ok"
                cancelLabel="cancelar"
                invalidDateMessage="Data Inválida"
                value={dataInicio}
                onChange={handleDataInicioChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "dd/mm/aaaa",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", ml: 1 }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={ptLocale}
            >
              <div
                className="text-underline-title-drawer"
                style={{ width: "100%" }}
              >
                Data Fim
              </div>
              <DatePicker
                style={{ width: "100%" }}
                margin="normal"
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                okLabel="ok"
                cancelLabel="cancelar"
                invalidDateMessage="Data Inválida"
                value={dataFim}
                onChange={handleDataFimChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "dd/mm/aaaa",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <span style={{ width: "2%" }} />
        <Button
          variant="contained"
          className={classes.btnFiltrar}
          onClick={handleSubmit}
        >
          Filtrar
        </Button>
      </div>
    </>
  );
};

export default FiltersPropostas;
