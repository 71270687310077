import Instance from "../../../../Axios";

// New Actions for the app

const GetArtigoById = (id) => async (dispatch) => {
  await Instance()
    .get(`https://renovaveis.jaf.com.pt/wp-json/wp/v2/posts/${id}`)
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: "GET_ARTIGO_BY_ID", payload: data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetArtigoById;
