import Instance from '../../../Axios';

const EditIntervencao =
  (
    ydata,
    //images,
    images,
    removeImages,
    //files
    files,
    removeFiles,
    intId,
    toggleDrawer,
    ShowNotification,
    message,
    errorMessage
  ) =>
  async (dispatch) => {
    const formdata = new FormData();

    formdata.append("ydata", JSON.stringify(ydata));
    formdata.append("removeImages", JSON.stringify(removeImages));
    formdata.append("removeFiles", JSON.stringify(removeFiles));
    for (var f = 0; f < files.length; f++) {
      formdata.append('files', files[f]);
    }
    for (var i = 0; i < images.length; i++) {
      formdata.append('images', images[i]);
    }

    console.log([...formdata]);

    await Instance()
      .put(`intervencoes/intervencoes/${intId}`, formdata)
      .then(({ data }) => {
        //console.log(data);
        dispatch({ type: "EDIT_INTERVENCAO", payload: data.yData[0] });
        toggleDrawer("right", false);
        dispatch(ShowNotification(message));
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          if (err.response === 403) {
            dispatch(ShowNotification(errorMessage));
          }
        }
      });
  };

export default EditIntervencao;
