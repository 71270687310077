import Instance from "../../../Axios";

const DeleteIntervencao =
  (intId, ShowNotification, message, errorMessage) => async (dispatch) => {
    await Instance()
      .delete(`/intervencoes/intervencoes/${intId}`)
      .then(({ data }) => {
        console.log(data);
        dispatch({ type: "DELETE_INTERVENCAO", payload: data });
        dispatch(ShowNotification(message));
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          if (err.response === 403) {
            dispatch(ShowNotification(errorMessage));
          }
        } else {
          dispatch(ShowNotification(errorMessage));
        }
      });
  };

export default DeleteIntervencao;
