import Instance from "../../../../Axios";

// New Actions for the app

const GetObrasAHDProjetosPenalizadosAnoAtual = () => async (dispatch) => {
  await Instance()
    .get(`/construcoes/obras/ahd/penalizadosAnoAtual`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({
        type: "GET_OBRAS_AHD_PROJETOS_PENALIZADOS_ANO_ATUAL",
        payload: data.yData[0].total,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetObrasAHDProjetosPenalizadosAnoAtual;
