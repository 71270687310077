import { filtersEnergiasInitialState } from "../initialState/filtersEnergias";

const filtersEnergiasReducer = (
  state = filtersEnergiasInitialState,
  { type, payload }
) => {
  switch (type) {
    case "DASHBOARD_DATA_INICIO":
      return {
        ...state,
        dashboardDataInicio: payload,
      };
    case "DASHBOARD_DATA_FIM":
      return {
        ...state,
        dashboardDataFim: payload,
      };
    case "GET_PARCEIROS":
      return {
        ...state,
        dashboardParceiros: payload,
      };
    case "DASHBOARD_PARCEIROS_SELECTED":
      return {
        ...state,
        dashboardParceirosSelected: payload,
      };
    case "CONTROLO_MENSAL_DATA":
      return {
        ...state,
        controloMensalData: payload,
      };
    case "TIMELINE_DATA_INICIO":
      return {
        ...state,
        timelineDataInicio: payload,
      };
    case "TIMELINE_DATA_FIM":
      return {
        ...state,
        timelineDataFim: payload,
      };
    case "GET_ESTADOS_PROJETO_PRE_SELECTED":
      return {
        ...state,
        estadosProjetoPreSelected: payload,
      };
    case "GET_ESTADOS_SUB_PROJETO_PRE_SELECTED":
      return {
        ...state,
        estadosSubProjetoPreSelected: payload,
      };
    case "ESTADOS_PROPOSTAS_PRE_SELECTED":
      return {
        ...state,
        estadosPropostasPreSelected: payload,
      };
    case "PROPOSTAS_DATA_INICIO":
      return {
        ...state,
        propostasDataInicio: payload,
      };
    case "PROPOSTAS_DATA_FIM":
      return {
        ...state,
        propostasDataFim: payload,
      };

    default:
      return state;
  }
};

export default filtersEnergiasReducer;
