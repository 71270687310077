import Instance from "../../../Axios";

const DeleteAdesaoFilesAdesaoById = (id) => async (dispatch) => {
  await Instance()
    .put(`/plus/adesoes/${id}/delete/filesAdesao`)
    .then(({ data }) => {
      dispatch({ type: "DELETE_ADESAO_FILE_ADESAO_BY_ID", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default DeleteAdesaoFilesAdesaoById;