import Instance from "../../../../Axios";

// New Actions for the app

const AddDocumentoOficial = (yData, file) => async (dispatch) => {
  const formdata = new FormData();

  formdata.append("yData", JSON.stringify(yData[0]));

  formdata.append("file", file[0]);

  console.log([...formdata]);

  await Instance()
    .post(`/energias/docsOficiais`, formdata)
    .then(({ data }) => {
      //   console.log(data);
      dispatch({ type: "ADD_DOCUMENTO_OFICIAL", payload: data.yData[0] });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default AddDocumentoOficial;
