import Instance from '../../../Axios';

const AdicionarUtilizador =
  (ydata, ShowNotification, message, errorMessage, toggleDrawer) =>
  async (dispatch) => {
    await Instance()
      .post('/utilizadores', { ydata })
      .then(({ data }) => {
        // console.log(data);
        dispatch({ type: 'POST_UTILIZADOR', payload: data });
        toggleDrawer('right', false);
        dispatch(ShowNotification(message));
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 403) {
            const warningMessage = {
              isOpen: true,
              message: err.response.data.message,
              type: 'warning',
            };

            dispatch(ShowNotification(warningMessage));
          } else {
            dispatch(ShowNotification(errorMessage));
          }
        }
      });
  };

export default AdicionarUtilizador;
