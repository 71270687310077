import { authInitialState } from "../initialState/auth";

const authReducer = (state = authInitialState, { type, payload }) => {
  switch (type) {
    case "SIGN_IN":
      return {
        ...state,
        nome: payload.user.nome,
        email: payload.user.email,
        auth_utilizadores_url_key: payload.user.auth_utilizadores_url_key,
        // id: payload.user.id,
        role: payload.user.perfis_id,
        permissoes: payload.user.permissoes,
        aeroporto: payload.user.aeroportosDescricao,
        aeroportoId: payload.user.aeroportosId,
        aeroportoFilter: payload.user.aeroportosDropdown,
        departamentoFilter: payload.user.departamentosDropdown,
        aeroportoAbreviatura: payload.user.aeroportoAbreviatura,
        lingua: payload.user.aeroportoLingua,
        perfilId: payload.user.perfisId,
        energParceiroId: payload.user.energParceirosId,
        diretorId: payload.user.diretorId,
      };
    case "USER_LOGOUT":
      return {
        ...authInitialState,
      };

    default:
      return state;
  }
};

export default authReducer;
