import moment from "moment";
import Instance from "../../../../Axios";

// New Actions for the app

const EditCredenciacao =
  (
    yData,
    id,
    copiaCredenciacao,
    copiaCredenciacaoExterna,
    ficheiros,
    datePickerValues,
    toggleDrawer,
    ShowNotification,
    message,
    errorMessage
  ) =>
  async (dispatch) => {
    const formdata = new FormData();

    formdata.append("yData", JSON.stringify(yData[0]));

    // if (
    //   inqueritoFicheiros &&
    //   Array.isArray(inqueritoFicheiros) &&
    //   inqueritoFicheiros.length > 0
    // ) {
    //   inqueritoFicheiros.forEach((ficheiro, index) => {
    //     formdata.append(`file${index}`, ficheiro.file);
    //     formdata.append(`idTipo${index}`, ficheiro.idTipo);
    //     formdata.append(`path${index}`, ficheiro.path);
    //   });
    // }
    // if (
    //   inqueritoPessoal &&
    //   Array.isArray(inqueritoPessoal) &&
    //   inqueritoPessoal.length > 0
    // ) {
    //   formdata.append("inqueritoPessoal", inqueritoPessoal[0].file);
    //   formdata.append("inqueritoPessoalidtipo", inqueritoPessoal[0].idTipo);
    //   formdata.append("inqueritoPessoalpath", inqueritoPessoal[0].path);
    // }

    if (
      copiaCredenciacao &&
      Array.isArray(copiaCredenciacao) &&
      copiaCredenciacao.length > 0
    ) {
      formdata.append("copiaCredenciacao", copiaCredenciacao[0].file);
      formdata.append("copiaCredenciacaoidtipo", copiaCredenciacao[0].idTipo);
      formdata.append("copiaCredenciacaopath", copiaCredenciacao[0].path);
    }

    if (
      copiaCredenciacaoExterna &&
      Array.isArray(copiaCredenciacaoExterna) &&
      copiaCredenciacaoExterna.length > 0
    ) {
      formdata.append("copiaCredenciacaoExterna", copiaCredenciacaoExterna[0].file);
      formdata.append("copiaCredenciacaoExternaidtipo", copiaCredenciacaoExterna[0].idTipo);
      formdata.append("copiaCredenciacaoExternapath", copiaCredenciacaoExterna[0].path);
    }

    if (ficheiros && Array.isArray(ficheiros) && ficheiros.length > 0) {
      ficheiros.forEach((ficheiro, index) => {
        if (ficheiro) {
          formdata.append(`file${index}`, ficheiro.file);
          formdata.append(`idTipo${index}`, ficheiro.idTipo);
          formdata.append(`path${index}`, ficheiro.path);

          const datePickerValue = datePickerValues[index];
          if (datePickerValue) {
            formdata.append(
              `datePicker${index}`,
              moment(datePickerValue).format("YYYY-MM-DD")
            );
          }
        }
      });
    }

    await Instance()
      .put(`/credenciacoes/${id}`, formdata)
      .then(({ data }) => {
        console.log(data);
        dispatch({ type: "EDIT_CREDENCIACAO", payload: data.yData[0] });
        dispatch(ShowNotification(message));
      })
      .catch((err) => {
        if (err.response) {
          if (err.response === 403) {
            dispatch(ShowNotification(errorMessage));
          } else {
            dispatch(ShowNotification(errorMessage));
          }
        }
      });
  };

export default EditCredenciacao;
