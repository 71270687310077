import Instance from "../../../../Axios";

// New Actions for the app

const DeleteCredenciacao = (id) => async (dispatch) => {
  await Instance()
    .put(`/credenciacoes/delete/${id}`)
    .then(({ data }) => {
      //   console.log(data);
      dispatch({ type: "DELETE_CREDENCIACAO", payload: data.yData[0] });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default DeleteCredenciacao;