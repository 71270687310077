/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

// import clsx from 'clsx';
import { makeStyles, withStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Drawer,
  FormControlLabel,
  TextField,
  Checkbox,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptLocale from "date-fns/locale/pt";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch, useSelector } from "react-redux";

const CustomCheckbox = withStyles({
  root: {
    color: "#707070",
    "&$checked": {
      color: "#707070",
    },
    background: "transparent !important",
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
});

const FilterDrawer = ({
  state,
  toggleDrawer,
  drawerState,
  drawerWidth,
  dimensions,
  fetchIntervencoesTimeline,
  dataInicio,
  dataFim,
  setDataInicio,
  setDataFim,
  aeroporto,
  aeroportos,
  aeroportoId,
  setAeroportoId,
  departamento,
  departamentos,
  departamentoId,
  setDepartamentoId,
  situacoes,
  situacoesPreSelectedId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const perfil = useSelector((state) => state.user.perfilId);

  const handleDataInicioChange = (date) => {
    setDataInicio(date);
    dispatch({
      type: "INTERVENCOES_TIMELINE_DATA_INICIO",
      payload: date,
    });
  };

  const handleDataFimChange = (date) => {
    setDataFim(date);
    dispatch({
      type: "INTERVENCOES_TIMELINE_DATA_FIM",
      payload: date,
    });
  };

  const handleSubmit = () => {
    const situacoesId = situacoesPreSelectedId.map((sit) => sit.id);
    fetchIntervencoesTimeline(
      dataInicio,
      dataFim,
      aeroportoId,
      departamentoId,
      situacoesId
    );
  };

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={() => toggleDrawer("right", false)}
        classes={{ paper: classes.paper }}
      >
        <div
          className={classes.list}
          style={
            dimensions.width <= 1100
              ? { width: "60vw" }
              : { width: `${drawerWidth}` }
          }
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              m: 5,
            }}
          >
            <Box
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              onClick={() => toggleDrawer("right", false)}
            >
              <div>
                <FeatherIcon
                  icon="chevron-left"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </div>
              <div className="text-underline-close-drawer">Dashboard</div>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                className={classes.btnFiltrar}
                onClick={() => {
                  handleSubmit();
                  toggleDrawer("right", false);
                }}
              >
                Filtrar
              </Button>
            </Box>
          </Box>
          <Card sx={{ m: 5, p: 2, boxShadow: "0px 10px 15px lightgrey" }}>
            <Box>
              <Box
                sx={{
                  m: 2,
                }}
              >
                <h4
                  style={{
                    fontSize: "22px",
                    width: "100%",
                    marginBottom: "0px",
                  }}
                  className="text-underline"
                >
                  Filtros
                </h4>
              </Box>
              <Box sx={{ m: 2 }}>
                <h4
                  style={{ color: "#E21450" }}
                  className="text-underline-title-drawer "
                >
                  Data de criação
                </h4>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  m: 2,
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", mr: 1 }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptLocale}
                  >
                    <b>Data Inicio</b>
                    <DatePicker
                      style={{ width: "100%" }}
                      margin="normal"
                      id="date-picker-dialog"
                      format="dd/MM/yyyy"
                      okLabel="ok"
                      cancelLabel="cancelar"
                      invalidDateMessage="Data Inválida"
                      value={dataInicio}
                      onChange={handleDataInicioChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "dd/mm/aaaa",
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", ml: 1 }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptLocale}
                  >
                    <b>Data Fim</b>
                    <DatePicker
                      style={{ width: "100%" }}
                      margin="normal"
                      id="date-picker-dialog"
                      format="dd/MM/yyyy"
                      okLabel="ok"
                      cancelLabel="cancelar"
                      invalidDateMessage="Data Inválida"
                      value={dataFim}
                      onChange={handleDataFimChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "dd/mm/aaaa",
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              {perfil !== 3 ? (
                <>
                  <Box sx={{ m: 2 }}>
                    <h4
                      style={{ color: "#E21450" }}
                      className="text-underline-title-drawer "
                    >
                      Aeroporto
                    </h4>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                      mx: 2,
                    }}
                  >
                    <Autocomplete
                      fullWidth
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      noOptionsText="Sem dados"
                      options={aeroportos || []}
                      value={aeroporto}
                      getOptionLabel={(option) => `${option.descricao}` || ""}
                      isOptionEqualToValue={(option, value) => {
                        // if (!value.id) return true;
                        return value.id === option.id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Aeroporto"
                          placeholder="Aeroporto"
                          variant="outlined"
                        />
                      )}
                      onChange={(_, value, reason) => {
                        if (reason === "clear") {
                          setAeroportoId(null);
                          dispatch({
                            type: "INTERVENCOES_TIMELINE_AEROPORTO",
                            payload: null,
                          });
                          dispatch({
                            type: "INTERVENCOES_TIMELINE_DEPARTAMENTO_SELECTED",
                            payload: null,
                          });
                          return;
                        } else {
                          setAeroportoId(value.id);
                          dispatch({
                            type: "INTERVENCOES_TIMELINE_AEROPORTO",
                            payload: value,
                          });
                          dispatch({
                            type: "INTERVENCOES_TIMELINE_DEPARTAMENTO_SELECTED",
                            payload: null,
                          });
                          return value;
                        }
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                      mx: 2,
                    }}
                  >
                    <Autocomplete
                      fullWidth
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      noOptionsText="Sem dados"
                      options={departamentos || []}
                      value={departamento}
                      getOptionLabel={(option) => `${option.descricao}` || ""}
                      isOptionEqualToValue={(option, value) => {
                        // if (!value.id) return true;
                        return value.id === option.id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Departamento"
                          placeholder="Departamento"
                          variant="outlined"
                        />
                      )}
                      onChange={(_, value, reason) => {
                        if (reason === "clear") {
                          setDepartamentoId(null);
                          dispatch({
                            type: "INTERVENCOES_TIMELINE_DEPARTAMENTO_SELECTED",
                            payload: null,
                          });
                          return;
                        } else {
                          setDepartamentoId(value.id);
                          dispatch({
                            type: "INTERVENCOES_TIMELINE_DEPARTAMENTO_SELECTED",
                            payload: value,
                          });
                          return value;
                        }
                      }}
                    />
                  </Box>
                </>
              ) : null}

              <Box sx={{ m: 2 }}>
                <h4
                  style={{ color: "#E21450" }}
                  className="text-underline-title-drawer "
                >
                  Situação
                </h4>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  mx: 2,
                }}
              >
                {situacoes.map((sit, index) => {
                  const situacaoExists = !situacoesPreSelectedId.some(
                    (o2) => o2.id === sit.id
                  );
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        minWidth: "50%",
                        cursor: "pointer",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            checked={!situacaoExists}
                            onChange={() => {
                              if (!situacaoExists) {
                                dispatch({
                                  type: "TIMELINE_SITUACAO_SELECTED",
                                  payload: situacoesPreSelectedId.filter(
                                    (est) => est.id !== sit.id
                                  ),
                                });
                              } else {
                                dispatch({
                                  type: "TIMELINE_SITUACAO_SELECTED",
                                  payload: [...situacoesPreSelectedId, sit],
                                });
                              }
                            }}
                            name={sit.descricao}
                          />
                        }
                        label={sit.descricao}
                      />
                    </div>
                  );
                })}
              </Box>
              {/* <Box sx={{ m: 2 }}>
              <h4
                style={{ color: "#E21450" }}
                className="text-underline-title-drawer "
              >
                Estados Sub-Projeto
              </h4>
            </Box> */}
              {/* <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                mt: 2,
                mx: 3,
              }}
            >
              {estadoSubProjeto.map((estado, index) => {
                const estadoExists = !estadosSubProjetoPreSelectedId.some(
                  (o2) => o2.id === estado.id
                );

                return (
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        eslint-disable-next-line
                        eqeqeq
                        checked={!estadoExists}
                        onChange={() => {
                          if (!estadoExists) {
                            dispatch({
                              type: "GET_ESTADOS_SUB_PROJETO_PRE_SELECTED",
                              payload: estadosSubProjetoPreSelectedId.filter(
                                (est) => est.id !== estado.id
                              ),
                            });
                          } else {
                            dispatch({
                              type: "GET_ESTADOS_SUB_PROJETO_PRE_SELECTED",
                              payload: [
                                ...estadosSubProjetoPreSelectedId,
                                estado,
                              ],
                            });
                          }
                        }}
                        name={estado.descricao}
                      />
                    }
                    label={estado.descricao}
                  />
                );
              })}
            </Box> */}
            </Box>
          </Card>
        </div>
      </Drawer>
    </div>
  );
};

export default FilterDrawer;
