import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button, Paper } from '@mui/material';
import { format } from "date-fns";

//Components
import Calendario from './sectionsEditar/calendario';
import MaoDeObra from './sectionsEditar/maoDeObra';
import Materiais from './sectionsEditar/materiais';
import Observacoes from './sectionsEditar/observacoes';
import Controlo from './sectionsEditar/controlo';
import Estados from './sectionsEditar/estados';
import Spinner from '../../../../../../Global/Components/Spinner';
import ShowNotification from '../../../../../../utils/redux/actions/global/notifications/NotificationAction';

//Icons
import FeatherIcon from 'feather-icons-react';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';

//Actions
import EditarObra from '../../../../../../utils/redux/actions/energias/obras/EditarObraAction';

const ColorStateCube = ({ color }) => {
  return (
    <div
      style={{
        height: '15px',
        width: '15px',
        backgroundColor: color,
        marginRight: '10px',
        borderRadius: '3px',
      }}
    />
  );
};

const DrawerForm = ({
  obras,
  toggleDrawer,
  classes,
  fetchObras,
  estadosProjetoPreSelectedId,
  estadosSubProjetoPreSelectedId,
  theme,
  controloPreSelectedId,
}) => {
  const dispatch = useDispatch();

  const [obraSelecionada, setObraSelecionada] = useState(null);

  const [guardarLoading, setGuardarLoading] = useState(false);

  const obra = useSelector((state) => state.energias.obra);

  const user = useSelector((state) => state.user);

  const [resetForm, setResetForm] = useState(false);

  useEffect(() => {
    setObraSelecionada(obra);
  }, [obra]);

  // ############################################################## //
  // #################### Render Cores Estados  ################### //
  // ############################################################## //

  const renderSwitch = (param) => {
    switch (param) {
      case '1':
        return <ColorStateCube color='#4CB64C' />;
      case '2':
        return <ColorStateCube color='#FFD500' />;
      case '3':
        return <ColorStateCube color='#FF5B5B' />;
      default:
        return null;
    }
  };

  // ############################################################## //
  // #################### Form Default Values  #################### //
  // ############################################################## //

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      maoObraContratada: '',
      maoObraJaf: '',
      estadosControloDropdown: '',
      estadodt: '',
      estadodc: '',
      materiais: [
        {
          estadosDescricao: '',
          estadosDropdown: { id: '', descricao: '' },
          estadosId: '',
          materiaisDescricao: '',
          materiaisDropdown: { id: '', descricao: '' },
          materiaisId: '',
          obrasId: '',
          observacoes: '',
          qtd: '',
          dataInicio: null,
          dataFim: null,
          estadodt: '',
          estadodc: '',
          subMateriais: [
            {
              estadosDescricao: '',
              estadosDropdown: { id: '', descricao: '' },
              estadosId: '',
              materiaisDescricao: '',
              materiaisId: '',
              obrasId: '',
              observacoes: '',
              qtd: '',
              dataInicio: null,
              dataFim: null,
              subMateriaisDescricao: '',
              subMateriaisDropdown: { id: '', descricao: '' },
              subMateriaisId: '',
              estadodt: '',
              estadodc: '',
            },
          ],
        },
      ],
    },
    // resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (obraSelecionada && resetForm == false) {
      reset({ ...obraSelecionada });
    }

    console.log('useEffect', obraSelecionada);

    updateGlobals();

    // if (obraSelecionada) {
    //   reset({ ...obraSelecionada });
    // }

    // if (obraSelecionada && resetForm == true) {
    //   setValue('materiais', obraSelecionada.materiais);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obraSelecionada]);

  let edt = [];
  let edc = [];
  const [estDTGlobal, setEstDTGlobal] = useState('');
  const [estDCGlobal, setEstDCGlobal] = useState('');
  const [estDT, setEstDT] = useState([]);
  const [estDC, setEstDC] = useState([]);

  /* eslint-disable eqeqeq */

  const updateGlobals = () => {
    estDT &&
      getValues() &&
      getValues(`materiais`) &&
      // eslint-disable-next-line array-callback-return
      getValues(`materiais`).map((mat) => {
        mat &&
          mat.subMateriais &&
          // eslint-disable-next-line array-callback-return
          mat.subMateriais.map((sMat) => {
            if (sMat.estadodt != null) {
              edt.push(sMat.estadodt);
            }
          });
      });

    estDC &&
      getValues() &&
      getValues(`materiais`) &&
      // eslint-disable-next-line array-callback-return
      getValues(`materiais`).map((mat) => {
        mat &&
          mat.subMateriais &&
          // eslint-disable-next-line array-callback-return
          mat.subMateriais.map((sMat) => {
            if (sMat.estadodc != null) {
              edc.push(sMat.estadodc);
            }
          });
      });

    if (edt.length > 0) {
      if (edt.some((elem) => elem !== '1')) {
        setEstDTGlobal('2');
        setValue('estadodt', '2');
      } else {
        setEstDTGlobal('1');
        setValue('estadodt', '1');
      }
    } else {
      setEstDTGlobal('0');
      setValue('estadodt', '0');
    }

    if (edc.length > 0) {
      if (edc.some((elem) => elem !== '1')) {
        setEstDCGlobal('2');
        setValue('estadodc', '2');
      } else {
        setEstDCGlobal('1');
        setValue('estadodc', '1');
      }
    } else {
      setEstDCGlobal('0');
      setValue('estadodc', '0');
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    const obraId = obraSelecionada && obraSelecionada.id;
    // console.log('data', data);

    const materiais =
      data &&
      data.materiais &&
      data.materiais.map((elem) => ({
        materiaisId: elem.materiaisDropdown && elem.materiaisDropdown.id,
        estadosId: elem.estadosDropdown && elem.estadosDropdown.id,
        qtd: elem.qtd,
        dataInicio:
          elem.dataInicio == null
            ? elem.dataInicio
            : format(new Date(elem.dataInicio), 'yyyy-MM-dd'),
        dataFim:
          elem.dataInicio == null
            ? elem.dataFim
            : format(new Date(elem.dataFim), 'yyyy-MM-dd'),
        observacoes: elem.observacoes,
        estadodt: elem.estadodt,
        estadodc: elem.estadodc,
        subMateriais:
          elem &&
          elem.subMateriais &&
          elem.subMateriais.map((subElem) => ({
            materiaisId: elem.materiaisDropdown && elem.materiaisDropdown.id,
            subMateriaisId:
              subElem.subMateriaisDropdown && subElem.subMateriaisDropdown.id,
            estadosId: subElem.estadosDropdown && subElem.estadosDropdown.id,
            qtd: subElem.qtd,
            dataInicio:
              subElem.dataInicio == null
                ? subElem.dataInicio
                : format(new Date(subElem.dataInicio), 'yyyy-MM-dd'),
            dataFim:
              subElem.dataFim == null
                ? subElem.dataFim
                : format(new Date(subElem.dataFim), 'yyyy-MM-dd'),
            observacoes: subElem.observacoes,
            estadodt: subElem.estadodt,
            estadodc: subElem.estadodc,
          })),
      }));

    const ydata = [
      {
        obrasId: data.obrasId,
        estadosControloId:
          data.estadosControloDropdown && data.estadosControloDropdown.id,
        maoObraContratada: data.maoObraContratada,
        maoObraJaf: data.maoObraJaf,
        estadodt: data.estadodt,
        estadodc: data.estadodc,
        materiais: materiais,
        observacoes: data.observacoes,
      },
    ];

    const eProjetosId = estadosProjetoPreSelectedId.map((ep) => ep.id);
    const eSubProjetosId = estadosSubProjetoPreSelectedId.map((ep) => ep.id);
    const estadosControloId = controloPreSelectedId.map((ep) => ep.id);

    const errorMessage = {
      isOpen: true,
      message: 'Ocorreu um erro!',
      type: 'error',
    };
    const message = {
      isOpen: true,
      message: 'Obra editada com sucesso!!',
      type: 'success',
    };

    setGuardarLoading(true);

    dispatch(
      EditarObra(
        ydata,
        obraId,
        toggleDrawer,
        ShowNotification,
        message,
        errorMessage,
        toggleDrawer,
        setGuardarLoading
      )
    )
      .then(() => fetchObras(eProjetosId, eSubProjetosId, estadosControloId))
      .then(() => setGuardarLoading(false));
  };

  return (
    <>
      {obraSelecionada && Object.keys(obraSelecionada).length > 0 ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '40px',
            height: '100%',
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.drawerHeader}>
              <div
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => toggleDrawer('right', false)}
              >
                <div>
                  <FeatherIcon
                    icon='chevron-left'
                    size={20}
                    style={{ strokeWidth: '1.5' }}
                  />
                </div>
                <div className='text-underline-close-drawer'>
                  Lista de Obras
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {obraSelecionada && obraSelecionada.obrasId}
                <span
                  style={{
                    background: '#D6DFE8',
                    height: '30px',
                    width: '2px',
                    margin: '0px 15px',
                  }}
                />
                {obraSelecionada && obraSelecionada.descricao}
                <span
                  style={{
                    background: '#D6DFE8',
                    height: '30px',
                    width: '2px',
                    margin: '0px 15px',
                  }}
                />
                {obraSelecionada && obraSelecionada.responsavelNome}
              </div>

              {user &&
              user.permissoes.includes('APP_ENERGIAS_LISTAS_OBRAS_EDITAR') ? (
                <>
                  {!guardarLoading ? (
                    <Button className={classes.btnGuardar} type='submit'>
                      Guardar
                    </Button>
                  ) : (
                    <LoadingButton
                      color='secondary'
                      loading={guardarLoading}
                      loadingPosition='start'
                      startIcon={<SaveIcon />}
                      variant='contained'
                      type='submit'
                      className={classes.btnGuardar}
                    >
                      Guardar
                    </LoadingButton>
                  )}
                </>
              ) : (
                <div style={{ width: '40px' }} />
              )}
            </div>
            <Paper sx={{ px: 6, py: 2, mt: 4 }}>
              <h4
                style={{
                  fontSize: '22px',
                  width: '100%',
                  marginBottom: '0px',
                }}
                className='text-underline'
              >
                Caracterização da obra
              </h4>
              <div style={{ display: 'flex' }}>
                <Calendario
                  obraSelecionada={obraSelecionada}
                  control={control}
                />
                <Controlo
                  obraSelecionada={obraSelecionada}
                  control={control}
                  renderSwitch={renderSwitch}
                  estDTGlobal={estDTGlobal}
                  estDCGlobal={estDCGlobal}
                  getValues={getValues}
                />
              </div>
              <div style={{ display: 'flex' }}>
                <Estados obraSelecionada={obraSelecionada} control={control} />
                <MaoDeObra
                  obraSelecionada={obraSelecionada}
                  control={control}
                />
              </div>

              <div
                style={{
                  marginTop: '30px',
                }}
              />
              {/* <Materiais
                obraSelecionada={obraSelecionada}
                setObraSelecionada={setObraSelecionada}
                control={control}
                classes={classes}
              /> */}
              <Materiais
                obraSelecionada={obraSelecionada}
                setObraSelecionada={setObraSelecionada}
                control={control}
                watch={watch}
                classes={classes}
                reset={reset}
                errors={errors}
                resetForm={resetForm}
                setResetForm={setResetForm}
                setValue={setValue}
                getValues={getValues}
                updateGlobals={updateGlobals}
                renderSwitch={renderSwitch}
                setEstDT={setEstDT}
                setEstDC={setEstDC}
              />
              <div
                style={{
                  marginTop: '30px',
                }}
              />
              <Observacoes
                obraSelecionada={obraSelecionada}
                setObraSelecionada={setObraSelecionada}
                control={control}
                classes={classes}
              />
            </Paper>
          </form>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export default DrawerForm;
