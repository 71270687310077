import React from "react";
import { TextField, Typography } from "@mui/material";

const DetalhesControlled = ({
  intervencaoSelecionada,
  obraId,
  handleObraId,
  nomePedido,
  handleNomePedido,
  coordenadas,
  handleCoordenadas,
}) => {
  return (
    <div style={{ width: "83%" }}>
      <h4 style={{ color: "#E21450" }} className="text-underline-title-drawer ">
        Detalhes
      </h4>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "15%", marginBottom: "16px" }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Id Intervenção
          </Typography>
          <TextField
            size="small"
            value={
              (intervencaoSelecionada && intervencaoSelecionada.id) || " - "
            }
            variant="outlined"
            disabled
            style={{ width: "100%" }}
          />
        </div>
        <div style={{ width: "60%" }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Nome do Pedido
          </Typography>

          <TextField
            size="small"
            value={nomePedido || ""}
            variant="outlined"
            disabled={obraId ? true : false}
            style={{ width: "100%" }}
            onChange={handleNomePedido}
          />
        </div>
        <div
          style={{
            width: "20%",
            marginBottom: "16px",
          }}
        >
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Responsável
          </Typography>
          <TextField
            size="small"
            value={
              (intervencaoSelecionada &&
                intervencaoSelecionada.funcionarioNome) ||
              " - "
            }
            variant="outlined"
            disabled
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "77.5%", marginBottom: "16px" }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Descrição
          </Typography>
          <TextField
            size="small"
            value={
              (intervencaoSelecionada &&
                intervencaoSelecionada.pedidoDescricao) ||
              " - "
            }
            variant="outlined"
            disabled
            style={{ width: "100%" }}
          />
        </div>
        <div style={{ width: "20%", marginBottom: "16px" }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Coordenadas
          </Typography>
          <TextField
            value={coordenadas || ""}
            onChange={handleCoordenadas}
            size="small"
            sx={{ width: "100%" }}
          ></TextField>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "15%", marginBottom: "16px" }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Id Proposta
          </Typography>

          <TextField
            value={obraId || ""}
            onChange={handleObraId}
            size="small"
            sx={{ width: "100%" }}
          ></TextField>
        </div>
        <div style={{ width: "60%" }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Proposta
          </Typography>

          <TextField
            size="small"
            value={
              (intervencaoSelecionada &&
                intervencaoSelecionada.obraDescricao) ||
              " - "
            }
            variant="outlined"
            disabled
            style={{ width: "100%" }}
          />
        </div>
        <div style={{ width: "20%", marginBottom: "16px" }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Valor da Proposta
          </Typography>
          <TextField
            size="small"
            value={
              intervencaoSelecionada.propostaValor
                ? intervencaoSelecionada.propostaValor.toLocaleString("PT") +
                  " €"
                : " - "
            }
            variant="outlined"
            disabled
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div style={{ display: "flex" }}></div>
    </div>
  );
};

export default DetalhesControlled;
