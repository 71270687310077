/* eslint-disable eqeqeq */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import './ListaIntervencoesMobile.css';

import {
  FormControlLabel,
  Tooltip,
  IconButton,
  TextField,
  Fade,
  ClickAwayListener,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';

//Icons
import { MdOutlineArrowForwardIos } from 'react-icons/md';

//Components
import CustomToolbar from '../components/customToolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },

  searchIcon: {
    marginTop: '20%',
    marginBottom: '5px',
  },
  searchInput: {
    width: '200px',
    // margin: '5px',
  },
  searchInput1: {
    width: '200px',
    // margin: '5px',

    marginRight: '10px',
  },
  alignItems: {
    display: 'flex',
    alignItems: 'center',
  },
  semReservas: {
    width: '100%',
    textAlign: 'center',
    marginTop: '100px',
  },

  container: {
    // display: 'flex',
    // marginBottom: '5px',
  },
  paper: {
    margin: theme.spacing(1),
  },
  svg: {
    width: 50,
    height: 50,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
}));

const ListaIntervencoesMobile = ({
  toggleDrawer,
  handleOpen,
  handleClose,
  permissionsState,
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [filter, setFilter] = useState('');

  const intervencoesMobile = useSelector(
    (state) => state.intervencoes.intervencoesMobile
  );

  const checkFilter = (info) => {
    if (info && Object.keys(info).length > 0) {
      return (
        info.pedidoNome.toLowerCase().includes(filter.toLowerCase()) ||
        (info.propostaData != null &&
          info.propostaData.toLowerCase().includes(filter.toLowerCase()))
      );
    }
    return null;
  };

  const handleChangeChecked = () => {
    setChecked((prev) => !prev);
  };

  const handleChange = (e) => {
    e.preventDefault();

    setFilter(e.target.value);
  };

  const handleClickAway = () => {
    if (filter.length == 0) {
      setChecked(false);
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        {permissionsState.includes('APP_INTERVENCOES_ADICIONAR') && (
          <CustomToolbar handleOpen={handleOpen} onClose={handleClose} />
        )}
        <FormControlLabel
          control={
            <>
              <Tooltip
                title={'Pesquisar Intervenção'}
                style={{ height: '40px', width: '40px', marginRight: '-14px' }}
              >
                <IconButton
                  className={classes.iconButton}
                  onClick={handleChangeChecked}
                >
                  <SearchIcon
                    className={classes.searchIcon}
                    checked={checked}
                  />
                </IconButton>
              </Tooltip>
            </>
          }
        />
        {checked == true ? (
          <div className={classes.container}>
            <ClickAwayListener onClickAway={handleClickAway}>
              {checked ? (
                <Fade in={checked}>
                  <TextField
                    onChange={handleChange}
                    // label='Pesquisar'
                    placeholder='Pesquisar'
                    variant='standard'
                    className={classes.searchInput1}
                    style={{ maxHeight: '10px' }}
                  />
                </Fade>
              ) : null}
            </ClickAwayListener>
          </div>
        ) : null}
      </div>

      {intervencoesMobile &&
        intervencoesMobile.map((intervencao) => (
          <>
            {intervencao.intervencoes.filter(
              (i) =>
                i.pedidoNome.toLowerCase().includes(filter.toLowerCase()) ||
                (i.propostaData != null &&
                  i.propostaData.toLowerCase().includes(filter.toLowerCase()))
            ).length > 0 && (
              <div>
                <p className='lista-int-data'>{intervencao.data}</p>
              </div>
            )}

            {intervencao.intervencoes.map(
              (info) =>
                checkFilter(info) && (
                  <div
                    className='lista-int-content'
                    onClick={() =>
                      toggleDrawer('right', true, info.id, 'check')
                    }
                  >
                    <div className='lista-int-content-text'>
                      <p>ID: {info.id}</p>
                      <p>
                        <strong>{info.pedidoNome}</strong>
                      </p>
                      <p>
                        Data proposta:{' '}
                        {info.propostaData
                          ? format(new Date(info.propostaData), 'dd/MM/yyyy')
                          : ' --/--/---- '}
                      </p>
                    </div>
                    <div className='lista-int-content-icon'>
                      <MdOutlineArrowForwardIos />
                    </div>
                  </div>
                )
            )}
          </>
        ))}
    </div>
  );
};

export default ListaIntervencoesMobile;
