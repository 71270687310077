import { dashboardInitialState } from '../initialState/dashboard';

const dashboardReducer = (state = dashboardInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_DASHBOARD_ESPECIALIDADE':
      return {
        ...state,
        especialidadesDashboard: payload,
      };
    case 'GET_DASHBOARD_ESTADOS_OBRAS':
      return {
        ...state,
        estadosObrasDashboard: payload,
      };
    case 'GET_DASHBOARD_IDADES_OBRAS':
      return {
        ...state,
        idadesObrasDashboard: payload,
      };
    case 'GET_DASHBOARD_CONSTRUCOES_ESTADOS_PROPOSTAS':
      return {
        ...state,
        construcoesEstadosPropostas: payload,
      };
    case 'GET_DASHBOARD_ENERGIAS_ESTADOS_PROPOSTAS':
      return {
        ...state,
        energiasEstadosPropostas: payload,
      };
    case 'USER_LOGOUT':
      return {
        ...dashboardInitialState,
      };

    default:
      return state;
  }
};

export default dashboardReducer;
