import { Button, Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

//Icons
import FeatherIcon from 'feather-icons-react';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';

//Actions
import ShowNotification from '../../../../../utils/redux/actions/global/notifications/NotificationAction';
import EditIntervencao from '../../../../../utils/redux/actions/intervencoes/EditIntervencaoAction';
import GetObras from '../../../../../utils/redux/actions/intervencoes/GetObrasAction';

//Components
import CalendarioControlled from './sectionsConsultar/CalendarioControlled';
import Controlo from './sectionsConsultar/Controlo';
import Documentos from './sectionsConsultar/Documentos';
import Spinner from '../../../../../Global/Components/Spinner';
import DetalhesControlled from './sectionsConsultar/DetalhesControlled';
import HistoricoEsperas from './sectionsConsultar/historicoEsperas';

const FormConsulta = ({
  intervencaoSelecionada,
  toggleDrawer,
  classes,
  handleSubmitForm,
  dimensions,
  fetchIntervencoes,
}) => {
  const dispatch = useDispatch();
  const [obraId, setObraId] = useState();
  const [guardarLoading, setGuardarLoading] = useState(false);

  const [files, setFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [removeFiles, setRemoveFiles] = useState([]);

  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [removeImages, setRemoveImages] = useState([]);

  const message = {
    isOpen: true,
    message: 'Intervenção editada com sucesso!!',
    type: 'success',
  };
  const errorMessage = {
    isOpen: true,
    message: 'Ocorreu um erro!!',
    type: 'error',
  };
  const fileWarning = {
    isOpen: true,
    message: 'Ficheiros com o mesmo nome não serão adicionados!',
    type: 'warning',
  };

  useEffect(() => {
    setObraId(intervencaoSelecionada && intervencaoSelecionada.obraId);
  }, [intervencaoSelecionada]);

  const fetchObras = () => {
    dispatch(GetObras());
  };

  useEffect(() => {
    fetchIntervencoes();
    fetchObras();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (e) => {
    const intId = intervencaoSelecionada && intervencaoSelecionada.id;

    const ydata = [
      {
        navNumProposta: intId,
      },
    ];

    setGuardarLoading(true);

    dispatch(
      EditIntervencao(
        ydata,
        //images,
        newImages,
        removeImages,
        //files
        newFiles,
        removeFiles,
        intId,
        toggleDrawer,
        ShowNotification,
        message,
        errorMessage
      )
    )
      .then(() => fetchIntervencoes())
      .then(() => setGuardarLoading(false));
  };

  console.log(intervencaoSelecionada);

  return (
    <>
      {intervencaoSelecionada ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '40px',
            height: '100%',
          }}
        >
          <form onSubmit={handleSubmitForm(onSubmit)}>
            <div className={classes.drawerHeader}>
              <div
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => toggleDrawer('right', false)}
              >
                <div>
                  <FeatherIcon
                    icon='chevron-left'
                    size={20}
                    style={{ strokeWidth: '1.5' }}
                  />
                </div>
                <div className='text-underline-close-drawer'>
                  Lista de Intervenções
                </div>
              </div>
              {!guardarLoading ? (
                <Button className={classes.btnGuardar} type='submit'>
                  Guardar
                </Button>
              ) : (
                <LoadingButton
                  color='secondary'
                  loading={guardarLoading}
                  loadingPosition='start'
                  startIcon={<SaveIcon />}
                  variant='contained'
                  type='submit'
                  className={classes.btnGuardar}
                >
                  Guardar
                </LoadingButton>
              )}
            </div>

            <Paper sx={{ px: 6, py: 2, mt: 4 }}>
              <h4
                style={{
                  fontSize: '22px',
                  width: '100%',
                  marginBottom: '0px',
                }}
                className='text-underline'
              >
                Caracterização da Intervenção
              </h4>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  marginTop: '20px',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
              >
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <DetalhesControlled
                    intervencaoSelecionada={intervencaoSelecionada}
                    obraId={obraId}
                  />
                  <Controlo intervencaoSelecionada={intervencaoSelecionada} />
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <CalendarioControlled
                    intervencaoSelecionada={intervencaoSelecionada}
                  />
                  {intervencaoSelecionada &&
                  intervencaoSelecionada.esperas &&
                  intervencaoSelecionada.esperas.length > 0 ? (
                    <HistoricoEsperas
                      intervencaoSelecionada={intervencaoSelecionada}
                    />
                  ) : null}
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Documentos
                    intervencaoSelecionada={intervencaoSelecionada}
                    classes={classes}
                    dimensions={dimensions}
                    //Imagens
                    images={images}
                    setImages={setImages}
                    newImages={newImages}
                    setNewImages={setNewImages}
                    removeImages={removeImages}
                    setRemoveImages={setRemoveImages}
                    //Documentos
                    files={files}
                    setFiles={setFiles}
                    newFiles={newFiles}
                    setNewFiles={setNewFiles}
                    removeFiles={removeFiles}
                    setRemoveFiles={setRemoveFiles}
                    //Errors
                    message={message}
                    errorMessage={errorMessage}
                    fileWarning={fileWarning}
                    ShowNotification={ShowNotification}
                  />
                </div>
              </div>
            </Paper>
          </form>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export default FormConsulta;
