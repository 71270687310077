import { credenciacoesAeroportosInitialState } from "../initialState/credenciacoesAeroportos";

const credenciacoesAeroportosReducer = (
  state = credenciacoesAeroportosInitialState,
  { type, payload }
) => {
  switch (type) {
    case "GET_ALL_CREDENCIACOES":
      return {
        ...state,
        credenciacoes: payload,
      };
    case "GET_CREDENCIACAO_BY_ID":
      return {
        ...state,
        credenciacao: payload,
      };
    case "ADD_CREDENCIACAO":
      return {
        ...state,
        credenciacaoAdicionada: payload,
      };
    case "EDIT_CREDENCIACAO":
      return {
        ...state,
        credenciacaoEditada: payload,
      };
    case "GET_CRED_FUNCIONARIOS":
      return {
        ...state,
        funcionarios: payload,
      };
    case "GET_CRED_FUNCIONARIO_BY_ID":
      return {
        ...state,
        funcionario: payload,
      };
    case "GET_CRED_FUNCIONARIO_FORMACAO_BY_FORM_ID":
      return {
        ...state,
        formacao: payload,
      };
    case "ADD_CRED_FUNCIONARIO":
      return {
        ...state,
        funcionarioAdicionado: payload,
      };
    case "ADD_CRED_FUNCIONARIO_FORMACAO":
      return {
        ...state,
        formacaoAdicionada: payload,
      };
    case "EDIT_CRED_FUNCIONARIO":
      return {
        ...state,
        funcionarioEditado: payload,
      };
    case "EDIT_CRED_FUNCIONARIO_FORMACAO":
      return {
        ...state,
        formacaoEditada: payload,
      };
    case "DELETE_CRED_FUNCIONARIO":
      return {
        ...state,
        funcionarioEliminado: payload,
      };
    case "GET_CRED_AEROPORTOS":
      return {
        ...state,
        aeroportos: payload,
      };
    case "GET_CRED_AEROPORTOS_BY_FUNC_ID":
      return {
        ...state,
        aeroportosByFuncId: payload,
      };
    case "GET_CRED_EMPRESAS":
      return {
        ...state,
        empresas: payload,
      };
    case "GET_CRED_EMPRESA_BY_ID":
      return {
        ...state,
        empresa: payload,
      };
    case "ADD_CRED_EMPRESA":
      return {
        ...state,
        empresaAdicionada: payload,
      };
    case "EDIT_CRED_EMPRESA":
      return {
        ...state,
        empresaEditada: payload,
      };
    case "DELETE_CRED_EMPRESA":
      return {
        ...state,
        empresaEliminada: payload,
      };
    case "GET_CRED_FUNCOES":
      return {
        ...state,
        funcoes: payload,
      };
    case "GET_CRED_HABILITACOES":
      return {
        ...state,
        habilitacoes: payload,
      };
    case "GET_CRED_TIPO_DOCS":
      return {
        ...state,
        tipoDocs: payload,
      };
    case "GET_CRED_TIPO_CARTOES":
      return {
        ...state,
        tipoCartoes: payload,
      };
    case "GET_CRED_TIPO_VINCULOS":
      return {
        ...state,
        tipoVinculos: payload,
      };
    case "GET_CRED_CORES":
      return {
        ...state,
        cores: payload,
      };
    case "GET_CRED_ESTADOS":
      return {
        ...state,
        estados: payload,
      };
    case "GET_CRED_ESTADOS_FORMACOES":
      return {
        ...state,
        estadosFormacoes: payload,
      };
    case "GET_CRED_FUNCIONARIOS_RESOURCES":
      return {
        ...state,
        resources: payload,
      };
    case "GET_CRED_FUNCIONARIOS_FICHEIROS":
      return {
        ...state,
        ficheiros: payload,
      };
    case "GET_CRED_INTERVENCOES_ELEMENTOS":
      return {
        ...state,
        intervencaoElementos: payload,
      };
    case "DELETE_CREDENCIACAO":
      return {
        ...state,
        credenciacaoEliminada: payload,
      };
    case "DELETE_CRED_INTERVENCAO_ELEMENTO":
      return {
        ...state,
        intervencaoElementoElim: payload,
      };
    case "ADD_CRED_INTERVENCAO_ELEMENTO":
      return {
        ...state,
        intervencaoElementoAdd: payload,
      };
    case "GET_CRED_FUNCIONARIOS_UPLOADED_FILES":
      return {
        ...state,
        uploadedFiles: payload,
      };
    case "GET_CRED_INTERVENCOES_TOTAIS":
      return {
        ...state,
        intervencoesTotais: payload,
      };
    case "GET_CRED_INTERVENCAO":
      return {
        ...state,
        intervencaoSelecionada: payload,
      };
    case "GET_CRED_INQUERITO_PESSOAL":
      return {
        ...state,
        inqueritoPessoal: payload,
      };
    case "GET_CRED_COPIA_CRECENCIACAO":
      return {
        ...state,
        copiaCredenciacao: payload,
      };
    case "GET_CRED_CERTIFICADO":
      return {
        ...state,
        certificado: payload,
      };
    case "GET_CRED_COPIA_CRECENCIACAO_UPLOADED":
      return {
        ...state,
        copiaCredenciacaoUploaded: payload,
      };
    case "GET_CRED_CERTIFICADO_UPLOADED":
      return {
        ...state,
        certificadoUploaded: payload,
      };
    case "GET_CRED_TIPO_CREDENCIACOES":
      return {
        ...state,
        tipoCredenciacoes: payload,
      };
    case "GET_CRED_ACOMPANHANTES":
      return {
        ...state,
        acompanhantes: payload,
      };
    case "GET_CRED_ACOMPANHANTE_BY_ID":
      return {
        ...state,
        acompanhante: payload,
      };
    case "GET_CRED_MOTIVOS":
      return {
        ...state,
        motivos: payload,
      };
    case "GET_CRED_TIPO_VEICULOS":
      return {
        ...state,
        tipoVeiculos: payload,
      };
    case "GET_CRED_TIPO_COMBUSTIVEIS":
      return {
        ...state,
        tipoCombustiveis: payload,
      };
    case "GET_CRED_VEICULOS_CORES":
      return {
        ...state,
        veiculosCores: payload,
      };
    case "GET_CRED_PORTARIA_ACESSO":
      return {
        ...state,
        portariaAcesso: payload,
      };
    case "GET_CRED_VIATURA_FICHEIROS":
      return {
        ...state,
        ficheirosViaturas: payload,
      };
    case "GET_CRED_VIATURA_UPLOADED_FILES":
      return {
        ...state,
        viaturaUploadedFiles: payload,
      };
    case "GET_CRED_VEICULOS":
      return {
        ...state,
        veiculos: payload,
      };
    case "GET_CRED_VEICULOS_BY_ID":
      return {
        ...state,
        veiculosById: payload,
      };
    case "GET_CRED_VIATURA_FICHEIROS_PERMANENTE":
      return {
        ...state,
        ficheirosViaturasPermanente: payload,
      };
    case "GET_CRED_VIATURAS":
      return {
        ...state,
        viaturas: payload,
      };
    case "GET_CRED_VIATURAS_BY_ID":
      return {
        ...state,
        viaturasById: payload,
      };
    case "ADD_CRED_VIATURA":
      return {
        ...state,
        viaturaAdicionada: payload,
      };
    case "EDIT_CRED_VIATURA":
      return {
        ...state,
        viaturaEditada: payload,
      };
    case "GET_CRED_PEDIDOS_ADICIONAIS_DOCS":
      return {
        ...state,
        pedidosAdicionaisDocs: payload,
      };
    case "GET_CRED_ACOMPANHANTES_DISPONIBILIDADE":
      return {
        ...state,
        acompanhantesDisponibilidade: payload,
      };
    case "GET_CRED_NOTIFICACOES":
      return {
        ...state,
        notificacoes: payload,
      };
    case "GET_CRED_ESTADOS_COUNT":
      return {
        ...state,
        credEstadosCount: payload,
      };
    case "GET_CRED_CREDENCIACOES_BY_ESTADO":
      return {
        ...state,
        credenciacoesByEstado: payload,
      };
    case "GET_MEDIA_DIAS_RESPOSTA_CRED_ESTADOS":
      return {
        ...state,
        mediaDiasRespostaCredEstados: payload,
      };
    case "GET_TOTAL_CREDENCIACOES_MES":
      return {
        ...state,
        totalCredMes: payload,
      };
    case "GET_CRED_PERMANENTES_VALIDADES":
      return {
        ...state,
        credPermValidades: payload,
      };
    case "GET_ALL_CRED_DESTINATARIOS":
      return {
        ...state,
        credDestinatarios: payload,
      };
    case "GET_CRED_DESTINATARIO_BY_ID":
      return {
        ...state,
        credDestinatarioById: payload,
      };
    case "GET_CRED_VIATURAS_UPLOADED_FILES":
      return {
        ...state,
        viaturaUploadedFiles: payload,
      };
    case "GET_CRED_DOWNLOAD_FILES":
      return {
        ...state,
        downloadFiles: payload,
      };
    case "GET_CRED_ALOCACOES":
      return {
        ...state,
        alocacoes: payload,
      };
    case "GET_FUNCIONARIO_BY_NIF":
      return {
        ...state,
        funcionarioByNIF: payload,
      };
    case "CLEAR_FUNCIONARIO_BY_NIF":
      return {
        ...state,
        funcionarioByNIF: [],
      };
    case "DELETE_CRED_FUNCIONARIO_FORMACAO":
      return {
        ...state,
        formacaoEliminada: payload,
      };
    default:
      return state;
  }
};

export default credenciacoesAeroportosReducer;
