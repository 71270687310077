import Instance from '../../../../Axios';

// New Actions for the app

const GetDiretoresObraProjeto = () => async (dispatch) => {
  await Instance()
    .get(`/construcoes/diretorObra`)
    .then(({ data }) => {
      //   console.log(data);
      dispatch({ type: 'GET_DIRETORES_OBRA', payload: data.yData });
    })
    .catch((err) => {
      if (err.response) {
        if (err.response === 403) {
          console.log('Problema com o fetch da DB');
        } else {
          console.log('Another error != 403');
        }
      }
    });
};

export default GetDiretoresObraProjeto;
