import { filtersInitialState } from '../initialState/filters';

const filtersReducer = (state = filtersInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_AEROPORTOS':
      return {
        ...state,
        aeroportos: payload,
      };
    case 'USER_LOGOUT':
      return {
        ...filtersInitialState,
      };

    default:
      return state;
  }
};

export default filtersReducer;
