/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box } from "@mui/material";
import "moment/locale/pt";
import MUIDataTable from "mui-datatables";

// Components
import FooterLinks from "../../../../Global/Components/Footer";
import FiltersPropostas from "./components/filters";

//Actions
import GetAllPropostas from "../../../../utils/redux/actions/energias/dashboard/GetAllPropostas";
import GetAllPropostasEstados from "../../../../utils/redux/actions/energias/dashboard/GetAllPropostasEstados";

// ############################################################## //
// ####################  Window Dimensions  ##################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Propostas = () => {
  const dispatch = useDispatch();
  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");

  const dataInicial = useSelector(
    (state) => state.filtersEnergias.propostasDataInicio
  );
  const dataFinal = useSelector(
    (state) => state.filtersEnergias.propostasDataFim
  );

  const [dataInicio, setDataInicio] = useState(dataInicial);
  const [dataFim, setDataFim] = useState(dataFinal);

  const propostasState = useSelector(
    (state) => state.energiasDashboard.propostas
  );

  const estadoProposta = useSelector(
    (state) => state.energiasDashboard.propostasEstados
  );

  let estadosPropostasPreSelectedId = useSelector(
    (state) => state.filtersEnergias.estadosPropostasPreSelected
  );

  const columns = [
    {
      name: "id",
      label: "Nº da Proposta",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
      },
    },
    {
      name: "descricao",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "400px" },
        }),
      },
    },
    {
      name: "valor",
      label: "Valor",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography>
              {new Intl.NumberFormat("pt-PT", {
                style: "currency",
                currency: "EUR",
              }).format(value)}
            </Typography>
          );
        },
      },
    },
    {
      name: "estado",
      label: "Estado",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
      },
    },
  ];

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },
  };

  const fetchPropostasEstados = () => {
    dispatch(GetAllPropostasEstados());
  };

  useEffect(() => fetchPropostasEstados(), []);

  const fetchPropostas = (estadosId, dataInicio, dataFim) => {
    dispatch(GetAllPropostas(estadosId, dataInicio, dataFim));
  };

  useEffect(() => {
    const estadosId = estadosPropostasPreSelectedId.map((ep) => ep.id);
    fetchPropostas(estadosId, dataInicio, dataFim);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mr: 10,
          width: "100%",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              mx: 5,
              mt: 3,
              width: "100%",
            }}
          >
            <FiltersPropostas
              estadoProposta={estadoProposta}
              estadosPropostasPreSelectedId={estadosPropostasPreSelectedId}
              fetchPropostas={fetchPropostas}
              dataInicio={dataInicio}
              dataFim={dataFim}
              setDataInicio={setDataInicio}
              setDataFim={setDataFim}
            />
          </Box>
          <div
            style={{
              margin: "0px 40px 40px 40px",
              width: "100%",
            }}
          >
            <MUIDataTable
              title={
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  className="text-underline"
                >
                  Lista de Propostas
                </span>
              }
              data={propostasState || []}
              columns={columns}
              options={options}
            />
          </div>
          <div style={{ margin: "20px 40px" }}>
            <FooterLinks />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Propostas;
