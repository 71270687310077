import Instance from "../../../../Axios";

// New Actions for the app

const GetCredMotivos = () => async (dispatch) => {
  await Instance()
    .get(`/credenciacoes/motivos`)
    .then(({ data }) => {
      dispatch({ type: "GET_CRED_MOTIVOS", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetCredMotivos;
