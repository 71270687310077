/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const GraficoEstadoPropostas = (estadoPropostas) => {
  // const estadoPropostas = useSelector(
  //   (state) => state.energiasDashboard.estadoPropostas
  // );

  const propostas = estadoPropostas && estadoPropostas.estadoPropostas;
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
        width: 300,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        offsetY: -25,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      colors: ["#FFA65D", "#4FC0E8", "#8CC051", "#FF4A4A"],
      title: {
        text: "Estado das Propostas",
        align: "center",
        style: {
          fontFamily: "Lato",
        },
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: true,
      },
      grid: {
        show: true,
      },
      legend: {
        show: false,
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  });

  useEffect(() => {
    setState({
      series: [
        {
          data: propostas.map((item) => item.data),
        },
      ],
      options: {
        xaxis: {
          categories: propostas.map((item) => item.name),
        },
      },
    });
  }, [propostas]);

  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={300}
        width={250}
      />
    </>
  );
};

export default GraficoEstadoPropostas;
