import { obrasInitialState } from "../initialState/obras";

const obrasReducer = (state = obrasInitialState, { type, payload }) => {
  switch (type) {
    case "GET_OBRAS":
      return {
        ...state,
        obras: payload,
      };
    case "GET_OBRAS_AHD":
      return {
        ...state,
        obrasAHD: payload,
      };
    case "GET_OBRAS_AHD_ADMIN":
      return {
        ...state,
        obrasAHDAdmin: payload,
      };
    case "GET_OBRAS_AHD_SERRALHARIA":
      return {
        ...state,
        obrasAHDSerralharia: payload,
      };
    case "GET_OBRAS_AHD_EM_CURSO":
      return {
        ...state,
        obrasAHDEmCurso: payload,
      };
    case "GET_OBRAS_AHD_EM_CURSO_SERRALHARIA":
      return {
        ...state,
        obrasAHDEmCursoSerralharia: payload,
      };
    case "GET_OBRAS_AHD_REALIZADAS_TOTAL":
      return {
        ...state,
        obrasAHDRealizadasTotal: payload,
      };
    case "GET_OBRAS_AHD_REALIZADAS_TOTAL_SEMANA":
      return {
        ...state,
        obrasAHDRealizadasTotalSemana: payload,
      };
    case "GET_OBRAS_AHD_MEDIA_IDADE_REALIZADAS":
      return {
        ...state,
        obrasAHDMediaIdadeRealizadas: payload,
      };
    case "GET_OBRAS_AHD_PROJETOS_TOTAIS":
      return {
        ...state,
        obrasAHDProjetosTotais: payload,
      };
    case "GET_OBRAS_AHD_PROJETOS_TOTAIS_PENALIZADOS":
      return {
        ...state,
        obrasAHDProjetosTotaisPenalizados: payload,
      };
    case "GET_OBRAS_AHD_PROJETOS_PENALIZADOS_ANO_ATUAL":
      return {
        ...state,
        obrasAHDProjetosPenalizadosAnoAtual: payload,
      };
    case "GET_OBRAS_AHD_PROJETOS_PENALIZADOS_ANO_ANTERIOR":
      return {
        ...state,
        obrasAHDProjetosPenalizadosAnoAnterior: payload,
      };
    case "GET_OBRAS_AHD_PROJETOS_PENALIZADOS_INICIADOS":
      return {
        ...state,
        obrasAHDProjetosPenalizadosIniciados: payload,
      };
    case "GET_GRAFICO_OBRAS_SEMANA":
      return {
        ...state,
        graficoObrasSemanal: payload,
      };
    case "GET_GRAFICO_OBRAS_MES":
      return {
        ...state,
        graficoObrasMensal: payload,
      };
    case "GET_OBRA":
      return {
        ...state,
        obra: payload,
      };
    case "POST_OBRA":
      return {
        ...state,
        // obraEditada: payload,
        obra: payload,
      };
    case "CLEAR_OBRAS":
      return {
        ...state,
        obras: [],
      };

    //ESTADOS
    case "GET_ESTADOS_CONTROLO":
      return {
        ...state,
        estadosControlo: payload,
      };
    case "GET_ESTADOS_ESPECIALIDADE":
      return {
        ...state,
        estadosEspecialidade: payload,
      };
    case "GET_ESTADOS_MATERIAL":
      return {
        ...state,
        estadosMaterial: payload,
      };

    //FILTERS
    case "GET_ESTADOS_PROJETO":
      return {
        ...state,
        estadosProjeto: payload,
      };
    case "GET_ESTADOS_SUB_PROJETO":
      return {
        ...state,
        estadosSubProjeto: payload,
      };
    case "GET_DIRETORES_OBRA":
      return {
        ...state,
        diretoresObraProjeto: payload,
      };
    //FILTERS_PRE_SELECTED
    case "ESTADOS_AEROPORTO_PRE_SELECTED":
      return {
        ...state,
        aeroportosPreSelected: payload,
      };
    case "ESTADOS_PROJETO_PRE_SELECTED":
      return {
        ...state,
        estadosProjetoPreSelected: payload,
      };
    case "ESTADOS_SUBPROJETO_PRE_SELECTED":
      return {
        ...state,
        estadosSubProjetoPreSelected: payload,
      };
    case "ESTADOS_CONTROLO_PRE_SELECTED":
      return {
        ...state,
        controloPreSelected: payload,
      };
    case "DIRETOR_OBRA_PRE_SELECTED":
      return {
        ...state,
        diretorObraPreSelected: payload,
      };
    case "GET_OBRAS_TRABALHOS":
      return {
        ...state,
        obrasTrabalhos: payload,
      };
    case "GET_OBRAS_MATERIAIS":
      return {
        ...state,
        obrasMateriais: payload,
      };
    case "GET_OBRAS_SUB_MATERIAIS":
      return {
        ...state,
        obrasSubMateriais: payload,
      };
    case "GET_OBRAS_EMPRESAS":
      return {
        ...state,
        obrasEmpresas: payload,
      };
    case "GET_OBRAS_UNID_MEDIDAS":
      return {
        ...state,
        obrasUnidMedidas: payload,
      };
    case "GET_OBRAS_TIPO_DOCS":
      return {
        ...state,
        ficheiros: payload,
      };
    case "GET_OBRAS_ESTADOS_ESPECIALIDADES":
      return {
        ...state,
        especialidadesEstados: payload,
      };
    case "GET_OBRAS_EQUIPAMENTOS":
      return {
        ...state,
        obrasEquipamentos: payload,
      };
    case "GET_OBRAS_ORCAMENTOS":
      return {
        ...state,
        obraOrcamentos: payload,
      };
    case "GET_OBRAS_FOTOS":
      return {
        ...state,
        obraFotos: payload,
      };
    case "GET_ALL_WORK_PERMITS":
      return {
        ...state,
        workPermits: payload,
      };
    case "GET_ALL_MEIO":
      return {
        ...state,
        workPermitsMEIO: payload,
      };
    case "GET_WP_FILE_TYPES":
      return {
        ...state,
        workPermitsFileTypes: payload,
      };
    case "GET_WORK_PERMIT_ACCOUNTABLES":
      return {
        ...state,
        workPermitsAccountables: payload,
      };
    case "GET_WORK_PERMIT_ACCOUNTABLES_AHD":
      return {
        ...state,
        workPermitsAccountablesAHD: payload,
      };
    case "GET_WORK_PERMIT_ENTITIES":
      return {
        ...state,
        workPermitsEntities: payload,
      };
    case "GET_WORK_PERMIT_RESP_ENTITIES":
      return {
        ...state,
        workPermitsRespEntities: payload,
      };
    case "GET_OBRA_WORK_PERMITS":
      return {
        ...state,
        obraWorkPermits: payload,
      };
    case "GET_WORK_PERMIT_CONTACTS":
      return {
        ...state,
        workPermitsContacts: payload,
      };
    case "GET_WORK_PERMIT_CONTACTS_AHD":
      return {
        ...state,
        workPermitsContactsAHD: payload,
      };
    case "GET_WORK_PERMIT_SEND_CONTACTS":
      return {
        ...state,
        workPermitsSendContacts: payload,
      };
    case "GET_WORK_PERMIT_STATUS":
      return {
        ...state,
        workPermitsStatus: payload,
      };
    case "ADD_WORK_PERMIT":
      return {
        ...state,
        idwp: payload,
      };
    case "GET_WORK_PERMIT":
      return {
        ...state,
        workPermit: payload,
      };
    case "GET_WORK_PERMIT_UPLOADED_FILES":
      return {
        ...state,
        workPermitUploadedFiles: payload,
      };

    case "GET_WORK_PERMIT_DEFAULT_ACCOUNTABLES":
      return {
        ...state,
        workPermitsDefaultAccountables: payload,
      };
    case "GET_WORK_PERMIT_DEFAULT_ACCOUNTABLES_AHD":
      return {
        ...state,
        workPermitsDefaultAccountablesAHD: payload,
      };
    case "GET_WORK_PERMIT_DEFAULT_SEND_EMAILS":
      return {
        ...state,
        workPermitsDefaultSendEmails: payload,
      };
    case "GET_ALL_WORK_PERMIT_ACCOUNTABLES":
      return {
        ...state,
        workPermitsAllAccountables: payload,
      };
    case "GET_WORK_PERMIT_ACCOUNTABLE_ID":
      return {
        ...state,
        workPermitAccountable: payload,
      };
    case "GET_EMAIL_BODY_TEMPLATES":
      return {
        ...state,
        workPermitsEmailBodyTemplate: payload,
      };
    case "GET_DEFAULT_NOTIFY_EMAILS_WP":
      return {
        ...state,
        workPermitsDefaultEmails: payload,
      };

    case "USER_LOGOUT":
      return {
        ...obrasInitialState,
      };

    default:
      return state;
  }
};

export default obrasReducer;
