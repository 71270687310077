/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import './dashboard.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

//ICONS
import { FiCheckSquare, FiSettings, FiUsers } from 'react-icons/fi';
import { AiOutlineThunderbolt } from 'react-icons/ai';

//Actions

//Components

import MiniCards from './components/minicards/MiniCards';
import IdadeDeObraChart from './components/idadeDeObraChart';
import EnergiaEstadosProposta from './components/EnergiaEstadosProposta';
import ConstrucoesEstadosPropostaChart from './components/ConstrucoesEstadosPropostaChart';
import FooterLinks from '../../../Global/Components/Footer/index';

const Dashboard = () => {
  const user = useSelector((state) => state.user.permissoes);

  return (
    <>
      <div
        style={{
          paddingTop: '40px',
          display: 'flex',
          height: '94vh',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          {user && user.includes('APP_CONSTRUCOES') && (
            <>
              {/* <div>
                <EspecialidadesChart />
              </div> */}
              <div>
                <MiniCards />
              </div>
              <div>
                <IdadeDeObraChart />
              </div>
              <div>
                <ConstrucoesEstadosPropostaChart />
              </div>
            </>
          )}

          {user && user.includes('APP_ENERGIAS') && (
            <>
              <div>
                <EnergiaEstadosProposta />
              </div>
            </>
          )}
        </div>
        <div
          style={{
            margin: '20px 40px',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            height: '100%',
          }}
        >
          <FooterLinks />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
