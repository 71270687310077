import Instance from '../../../../Axios';

// New Actions for the app

const GetEstadosSubProjeto = () => async (dispatch) => {
  await Instance()
    .get(`/energias/estadosSubProjetos`)
    .then(({ data }) => {
      //   console.log(data);
      dispatch({
        type: 'GET_ENERGIAS_ESTADOS_SUB_PROJETO',
        payload: data.yData,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetEstadosSubProjeto;
