/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import styled from '@emotion/styled';
import { makeStyles } from '@mui/styles';

//Images
import LoginLeft from '../../../assets/images/login-left.png';

//Components
import FormRecuperarSenha from './components/formRecuperarSenha';
import FooterLinks from '../../../Global/Components/Footer';

const CssTextField = styled(TextField)({
  // borderRadius: '15px',
  '& label.Mui-focused': {
    color: '#464255 !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#464255 !important',
  },
  '& .MuiOutlinedInput-root': {
    // borderRadius: '15px',
    transition: '0.2s',
    background: 'white',

    '& input': {
      backgroundColor: 'white',
      transition: '0.2s',
      borderRadius: '5px',
    },
    '& fieldset': {
      color: '#474a52 !important',
      transition: '0.2s',
    },

    '&:hover fieldset': {
      borderColor: '#464255 !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#464255 !important',
    },
  },
});

const useStyles = makeStyles({
  btnGuardar: {
    color: 'white',
    width: '120px',
    height: '40px',
    // borderRadius: '15px',
    backgroundColor: '#E21450',
    border: `1px solid #E21450`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#bc1041',
      border: '1px solid #bc1041',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#bc1041',
      },
      '&:disabled': {
        backgroundColor: '#bc1041',
      },
    },
  },
});

const RecuperarSenha = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hash = location.pathname.split('/')[2];

  const storage = localStorage;

  return (
    <>
      {storage.token ? (
        navigate('/dashboard')
      ) : (
        <div className='home'>
          <div className='home-left'>
            <div className='left-content'>
              <div className='left-content-inside'>
                <img src={LoginLeft} alt='' />
              </div>
            </div>
          </div>
          <div className='home-right'>
            <div className='login-form'>
              <div className='titulo'>
                <h3>Recuperar senha</h3>
                <p>Plataforma de Gestão Grupo JAF</p>
              </div>

              <div style={{ width: '100%' }}>
                <FormRecuperarSenha
                  onSubmit
                  form='log_in_form'
                  dispatch={dispatch}
                  hash={hash}
                  CssTextField={CssTextField}
                  classes={classes}
                />
              </div>
            </div>
            <div className='bottom'>
              <div style={{ padding: '20px 40px' }}>
                <FooterLinks />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RecuperarSenha;
