/* eslint-disable no-unused-expressions */
import React from "react";

// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  TextField,
  Typography,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { useDispatch } from "react-redux";

//ACTIONS
import ShowNotification from "../../../../../utils/redux/actions/global/notifications/NotificationAction";
import AddCredDestinatario from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/AddCredDestinatarioAction";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnUpload: {
    color: "#E21450",
    // width: '125px',
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
});

const FormAdd = ({
  toggleDrawer,
  drawerState,
  drawerWidth,
  dimensions,
  destinatario,
  setDestinatario,
  resetDestinatario,
  fetchAllCredDestinatarios,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // ############################################################## //
  // ##############  Handle Change and Submit Form  ############### //
  // ############################################################## //

  const handleChange = (e) => {
    e.preventDefault();
    setDestinatario({
      ...destinatario,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        name: destinatario.name,
        email: destinatario.email,
        contact: destinatario.contact,
      },
    ];

    const message = {
      isOpen: true,
      message: "Responsável criado com sucesso!!",
      type: "success",
    };

    console.log(ydata);

    dispatch(AddCredDestinatario(ydata)).then(() => {
      resetDestinatario();
      toggleDrawer("right", false);
      dispatch(ShowNotification(message));
      fetchAllCredDestinatarios();
    });
  };

  return (
    <div
      className={classes.list}
      style={
        dimensions.width <= 1100
          ? { width: "60vw" }
          : { width: `${drawerWidth}` }
      }
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 5,
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => {
            toggleDrawer("right", false);
            resetDestinatario();
          }}
        >
          <div>
            <FeatherIcon
              icon="chevron-left"
              size={20}
              style={{ strokeWidth: "1.5" }}
            />
          </div>
          <div className="text-underline-close-drawer">Colaboradores</div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box>
      </Box>
      <Card
        sx={{
          m: 5,
          p: 2,
          boxShadow: "0px 10px 15px lightgrey",
        }}
      >
        <Box
          sx={{
            m: 2,
          }}
        >
          <h4
            style={{
              fontSize: "22px",
              width: "100%",
              marginBottom: "0px",
            }}
            className="text-underline"
          >
            Responsável
          </h4>
        </Box>
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Detalhes
          </h4>
        </Box>
        <Box sx={{ mx: 1 }}>
          <Box sx={{ m: 1 }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Nome
            </Typography>
            <TextField
              name="name"
              size="small"
              variant="outlined"
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ m: 1, width: "46.5%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Email
              </Typography>
              <TextField
                name="email"
                size="small"
                variant="outlined"
                onChange={handleChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
            <Box sx={{ m: 1, width: "46.5%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Contacto
              </Typography>
              <TextField
                name="contact"
                size="small"
                variant="outlined"
                onChange={handleChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
          </Box>
        </Box>
      </Card>
    </div>
  );
};

export default FormAdd;
