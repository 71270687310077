import Instance from "../../../../Axios";

// New Actions for the app

const SendPedidoWorkPermit =
  (idwp, fetchWorkPermit, obraId, ShowNotification, message) =>
  async (dispatch) => {
    await Instance()
      .get(`/construcoes/workpermits/emailcronjob/${idwp}`)
      .then(({ data }) => {
        // console.log(data);
        // setObraSelecionada(data.yData[0]);
        fetchWorkPermit(obraId);
        dispatch(ShowNotification(message));
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default SendPedidoWorkPermit;
