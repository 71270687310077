import Instance from "../../../Axios";
import GetUtilizador from "./GetUtilizadorAction";

const CreateToken = (userId) => async (dispatch) => {
  await Instance()
    .put(`/utilizadores/createToken/${userId}`)
    .then(({ data }) => {
      dispatch(GetUtilizador(userId));
      //   show('success', 'Foram associados novos funcionários á obra');
    })
    .catch((err) => {});
};

export default CreateToken;
