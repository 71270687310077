import React from "react";
import { IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";

import FeatherIcon from "feather-icons-react";

const defaultToolbarStyles = {
  iconButton: {},
};

class CustomToolbar extends React.Component {
  handleClick = (e) => {
    console.log(this.props.toggleDrawer("right", true));
    console.log(this.props.setDrawerState("add"));
    console.log(this.props.handleOpen("add", e));
    console.log(this.props);
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Tooltip title={"Adicionar Viatura"}>
          <IconButton className={classes.iconButton} onClick={this.handleClick}>
            <FeatherIcon
              icon="plus-square"
              size={22}
              className={classes.deleteIcon}
              style={{ strokeWidth: "2" }}
            />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(
  CustomToolbar
);
