import Instance from '../../../../Axios';

const EditarObra =
  (ydata, obraId, toggleDrawer, ShowNotification, message, errorMessage) =>
  async (dispatch) => {
    await Instance()
      .post(`/energias/obras`, { ydata })
      .then(({ data }) => {
        console.log(data);
        dispatch({ type: 'POST_ENERGIAS_OBRA', payload: data });
        toggleDrawer('right', false);
        dispatch(ShowNotification(message));

        // toggleDrawer('right', false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response === 403) {
            dispatch(ShowNotification(errorMessage));
          } else {
            dispatch(ShowNotification(errorMessage));
          }
        }
      });
  };

export default EditarObra;
