/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Drawer,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptLocale from "date-fns/locale/pt";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fileDownload from "js-file-download";
import Instance from "../../../../../utils/Axios";
import BASE_URL from "../../../../../utils/BASE_URL";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";

//Components
import ShowNotification from "../../../../../utils/redux/actions/global/notifications/NotificationAction";

//ACTIONS
import EditCredFuncionario from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/EditCredFuncionarioAction";
import GetCredFuncionariosFicheiros from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionariosFicheirosAction";
import EditCredViatura from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/EditCredViaturaAction";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnUpload: {
    color: "#E21450",
    // width: '125px',
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
});

const FormEdit = ({
  toggleDrawer,
  cursoState,
  drawerWidth,
  dimensions,
  viaturaId,
  viatura,
  setViatura,
  fetchCredViaturas,
  fetchCredViaturasById,
  cores,
  tiposCombustiveis,
  tiposVeiculos,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const ficheirosViaturasPermanentesState = useSelector(
    (state) => state.credenciais.ficheirosViaturasPermanente
  );

  // ############################################################## //
  // ######################  Autocompletes  ####################### //
  // ############################################################## //

  const [cor, setCor] = useState({
    id: null,
    descricao: "",
  });
  const [tipoVeiculo, setTipoVeiculo] = useState({
    id: null,
    descricao: "",
  });
  const [tipoCombustivel, setTipoCombustivel] = useState({
    id: null,
    descricao: "",
  });

  const uploadedFiles = useSelector(
    (state) => state.credenciais.viaturaUploadedFiles
  );

  // ############################################################## //
  // ######################  Dates Changes  ####################### //
  // ############################################################## //

  const [dataInspecao, setDataInspecao] = useState(null);
  const [dataProxInspecao, setDataProxInspecao] = useState(null);
  const [dataValidadeApolice, setDataValidadeApolice] = useState(null);

  const handleDataInspecaoChange = (date) => {
    setDataInspecao(date);
  };

  const handleDataProxInspecaoChange = (date) => {
    setDataProxInspecao(date);
  };

  const handleDataValidadeApoliceChange = (date) => {
    setDataValidadeApolice(date);
  };

  // ############################################################## //
  // ###################  Verificar Formulário  ################### //
  // ############################################################## //

  const verifyForm = () => {
    // Check if the required fields are filled
    const isRequiredFilled =
      !!viatura.matricula &&
      !!viatura.marca &&
      !!viatura.modelo &&
      !!viatura.proprietario &&
      !!viatura.requisitante &&
      !!viatura.nr_apolice;

    // Update the state variable
    return isRequiredFilled;
  };

  // ############################################################## //
  // ########################  Ficheiros  ######################### //
  // ############################################################## //

  const [ficheiros, setFicheiros] = useState([]);
  const [datePickerValues, setDatePickerValues] = useState({});

  const handleFilesChange = (e, index, ficheiro) => {
    const file = e.target.files[0];

    setFicheiros((prevFicheiros) => {
      const updatedFicheiros = [...prevFicheiros];
      updatedFicheiros[index] = {
        file,
        idTipo: ficheiro.id_crede_elementos_files_tipos,
        path: ficheiro.crede_elementos_files_tipos_path,
      };
      return updatedFicheiros;
    });
  };

  const handleRemoveFiles = (index) => {
    setFicheiros((prevFicheiros) => {
      const updatedFicheiros = [...prevFicheiros];
      updatedFicheiros[index] = null;
      return updatedFicheiros;
    });
  };

  const handleDatePickerChange = (date, index) => {
    setDatePickerValues((prevValues) => {
      return {
        ...prevValues,
        [index]: date,
      };
    });
  };

  // ############################################################## //
  // ###########  Funcionário e Aeroporto Selecionados  ########### //
  // ############################################################## //

  const viaturaSelecionada = useSelector(
    (state) => state.credenciais.viaturasById
  );

  useEffect(() => {
    if (viaturaSelecionada) {
      setViatura({
        matricula: viaturaSelecionada.crede_credenciacao_veiculo_matricula,
        marca: viaturaSelecionada.crede_credenciacao_veiculo_marca,
        modelo: viaturaSelecionada.crede_credenciacao_veiculo_modelo,
        proprietario:
          viaturaSelecionada.crede_credenciacao_veiculo_nome_proprietario,
        requisitante:
          viaturaSelecionada.crede_credenciacao_veiculo_nome_requisitante,
        nr_apolice: viaturaSelecionada.crede_credenciacao_veiculo_apolice_nr,
        validade_apolice:
          viaturaSelecionada.crede_credenciacao_veiculo_apolice_validade,
        inspecao_identidade:
          viaturaSelecionada.crede_credenciacao_veiculo_inspecao_nome_entidade,
        inspecao_data:
          viaturaSelecionada.crede_credenciacao_veiculo_inspecao_data,
        inspecao_data_proxima:
          viaturaSelecionada.crede_credenciacao_veiculo_inspecao_data_proxima,
        nr_distico: viaturaSelecionada.crede_credenciacao_veiculo_distico_nr,
      });
      handleDataInspecaoChange(
        viaturaSelecionada.crede_credenciacao_veiculo_inspecao_data
      );
      handleDataValidadeApoliceChange(
        viaturaSelecionada.crede_credenciacao_veiculo_apolice_validade
      );
      handleDataProxInspecaoChange(
        viaturaSelecionada.crede_credenciacao_veiculo_inspecao_data_proxima
      );
      setCor({
        id: viaturaSelecionada.id_crede_credenciacao_veiculo_cor
          ? viaturaSelecionada.id_crede_credenciacao_veiculo_cor
          : null,
        descricao: viaturaSelecionada.veiculo_cor
          ? viaturaSelecionada.veiculo_cor
          : "-",
      });
      setTipoVeiculo({
        id: viaturaSelecionada.id_crede_credenciacao_veiculo_tipo
          ? viaturaSelecionada.id_crede_credenciacao_veiculo_tipo
          : null,
        descricao: viaturaSelecionada.veiculo_tipo
          ? viaturaSelecionada.veiculo_tipo
          : "-",
      });
      setTipoCombustivel({
        id: viaturaSelecionada.id_crede_credenciacao_veiculo_combustivel
          ? viaturaSelecionada.id_crede_credenciacao_veiculo_combustivel
          : null,
        descricao: viaturaSelecionada.veiculo_combustivel
          ? viaturaSelecionada.veiculo_combustivel
          : "-",
      });
    }
  }, [viaturaSelecionada]);

  // ############################################################## //
  // ###############  Handle Change and Submit Form  ############## //
  // ############################################################## //

  const handleChange = (e) => {
    e.preventDefault();
    setViatura({
      ...viatura,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        matricula: viatura.matricula,
        marca: viatura.marca,
        modelo: viatura.modelo,
        proprietario: viatura.proprietario,
        requisitante: viatura.requisitante,
        nr_apolice: viatura.nr_apolice,
        validade_apolice: dataValidadeApolice
          ? moment(dataValidadeApolice).format("YYYY-MM-DD")
          : null,
        inspecao_identidade: viatura.inspecao_identidade,
        inspecao_data: dataInspecao
          ? moment(dataInspecao).format("YYYY-MM-DD")
          : null,
        inspecao_data_proxima: dataProxInspecao
          ? moment(dataProxInspecao).format("YYYY-MM-DD")
          : null,
        nr_distico: viatura.nr_distico,
        corVeiculo: cor.id,
        tipoVeiculo: tipoVeiculo.id,
        tipoCombustivel: tipoCombustivel.id,
      },
    ];

    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!",
      type: "error",
    };
    const message = {
      isOpen: true,
      message: "Colaborador editado com sucesso!!",
      type: "success",
    };
    const warningMessage = {
      isOpen: true,
      message: "Preencha os campos obrigatórios!",
      type: "warning",
    };

    if (verifyForm()) {
      dispatch(
        EditCredViatura(
          ydata,
          viaturaSelecionada.id_crede_credenciacao_veiculo,
          ficheiros,
          datePickerValues,
          toggleDrawer,
          ShowNotification,
          message,
          errorMessage
        )
      ).then(() => {
        toggleDrawer("right", false);
        fetchCredViaturas();
      });
    } else {
      dispatch(ShowNotification(warningMessage));
    }
  };

  return (
    <div
      className={classes.list}
      style={
        dimensions.width <= 1100
          ? { width: "60vw" }
          : { width: `${drawerWidth}` }
      }
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 5,
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => toggleDrawer("right", false)}
        >
          <div>
            <FeatherIcon
              icon="chevron-left"
              size={20}
              style={{ strokeWidth: "1.5" }}
            />
          </div>
          <div className="text-underline-close-drawer">Viaturas</div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box>
      </Box>
      <Card
        sx={{
          m: 5,
          p: 2,
          boxShadow: "0px 10px 15px lightgrey",
        }}
      >
        <Box
          sx={{
            m: 2,
          }}
        >
          <h4
            style={{
              fontSize: "22px",
              width: "100%",
              marginBottom: "0px",
            }}
            className="text-underline"
          >
            Viatura
          </h4>
        </Box>
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Detalhes
          </h4>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "48%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Matricula *
                </Typography>
                <TextField
                  name="matricula"
                  size="small"
                  variant="outlined"
                  value={viatura.matricula}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Cor
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={cores || []}
                  value={cor}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setCor({
                        id: null,
                        descricao: "",
                      });
                    } else {
                      setCor(value);
                    }
                    return value;
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Tipo Veiculo
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={tiposVeiculos || []}
                  value={tipoVeiculo}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setTipoVeiculo({
                        id: null,
                        descricao: "",
                      });
                    } else {
                      setTipoVeiculo(value);
                    }
                    return value;
                  }}
                />
              </Box>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Tipo Combustível
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={tiposCombustiveis || []}
                  value={tipoCombustivel}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setTipoCombustivel({
                        id: null,
                        descricao: "",
                      });
                    } else {
                      setTipoCombustivel(value);
                    }
                    return value;
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Marca *
                </Typography>
                <TextField
                  name="marca"
                  size="small"
                  variant="outlined"
                  value={viatura.marca}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Modelo *
                </Typography>
                <TextField
                  name="modelo"
                  size="small"
                  variant="outlined"
                  value={viatura.modelo}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Proprietario *
                </Typography>
                <TextField
                  name="proprietario"
                  size="small"
                  variant="outlined"
                  value={viatura.proprietario}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Requisitante *
                </Typography>
                <TextField
                  name="requisitante"
                  size="small"
                  variant="outlined"
                  value={viatura.requisitante}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "48%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Nº Apólice *
                </Typography>
                <TextField
                  name="nr_apolice"
                  size="small"
                  variant="outlined"
                  value={viatura.nr_apolice}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Validade da apólice
                  </Typography>
                  <DatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    okLabel="ok"
                    cancelLabel="cancelar"
                    invalidDateMessage="Data Inválida"
                    value={dataValidadeApolice}
                    onChange={handleDataValidadeApoliceChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        name="data_validade_apolice"
                        sx={{ width: "200px" }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "46.5%",
                  m: 1,
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Data da Inspeção
                  </Typography>
                  <DatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    okLabel="ok"
                    cancelLabel="cancelar"
                    invalidDateMessage="Data Inválida"
                    value={dataInspecao}
                    onChange={handleDataInspecaoChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        name="data_inspecao"
                        sx={{ width: "200px" }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "46.5%",
                  m: 1,
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Data da Próx. Inspeção
                  </Typography>
                  <DatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    okLabel="ok"
                    cancelLabel="cancelar"
                    invalidDateMessage="Data Inválida"
                    value={dataProxInspecao}
                    onChange={handleDataProxInspecaoChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        name="data_prox_inspecao"
                        sx={{ width: "200px" }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Identidade inspeção
                </Typography>
                <TextField
                  name="inspecao_identidade"
                  size="small"
                  variant="outlined"
                  value={viatura.inspecao_identidade}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  NºDístico
                </Typography>
                <TextField
                  name="nr_distico"
                  size="small"
                  variant="outlined"
                  value={viatura.nr_distico}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Documentos
          </h4>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            m: 2,
            width: "100%",
          }}
        >
          {ficheirosViaturasPermanentesState &&
            ficheirosViaturasPermanentesState.map((ficheiro, index) => {
              const matchingFile = uploadedFiles.find(
                (file) =>
                  file.id_crede_elementos_files_tipos ===
                  ficheiro.id_crede_elementos_files_tipos
              );
              return (
                <Box sx={{ width: "25%", my: 1 }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    {ficheiro.crede_elementos_files_tipos_nome}
                    {/* {ficheiro.id_crede_elementos_files_tipos != 1 &&
                      ficheiro.id_crede_elementos_files_tipos != 7
                        ? " *"
                        : null} */}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    {matchingFile && (
                      <Button
                        sx={{
                          height: "40px",
                          borderRadius: "5px",
                          mr: 1,
                        }}
                        className={classes.btnUpload}
                        onClick={() => {
                          Instance()
                            .get(
                              `${BASE_URL}/credenciacoes/funcionarios/files/${matchingFile.id_crede_elementos_files}/download`,
                              { responseType: "blob" }
                            )
                            .then(({ data }) => {
                              const filename =
                                matchingFile.crede_elementos_files_path.replace(
                                  /^.*[\\/]/,
                                  ""
                                );
                              fileDownload(data, filename);
                            })
                            .catch((err) => {
                              console.log(err.response);
                              if (err.response) {
                                if (err.response === 403) {
                                  // dispatch(ShowNotification(errorMessage));
                                }
                              }
                            });
                        }}
                      >
                        <FeatherIcon icon="download" size={20} />
                      </Button>
                    )}
                    <Button
                      sx={{
                        height: "40px",
                        width: "200px",
                        borderRadius: "5px",
                        mr: 2,
                      }}
                      onChange={(e) => handleFilesChange(e, index, ficheiro)}
                      className={classes.btnUpload}
                    >
                      <label
                        htmlFor={
                          ficheiro.crede_elementos_files_tipos_nome_input
                        }
                        className="custom-file-upload"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            cursor: "pointer",
                          }}
                        >
                          <p>Carregar Ficheiro</p>
                        </div>
                      </label>
                      <input
                        type="file"
                        name={ficheiro.crede_elementos_files_tipos_nome_input}
                        id={ficheiro.crede_elementos_files_tipos_nome_input}
                        hidden
                        accept="image/jpeg, .pdf"
                      />
                    </Button>
                    <div style={{ width: "auto" }}>
                      {matchingFile && !ficheiros[index] && (
                        <div
                          style={{
                            marginRight: "10px",
                            display: "flex",
                            alignItems: "center",
                          }}
                          key={index}
                        >
                          <IconButton
                            onClick={() => {
                              // Retrieve the base URL from environment variables
                              let baseUrl = BASE_URL;

                              // Remove the '/api' part from the base URL
                              if (baseUrl.endsWith("/api")) {
                                baseUrl = baseUrl.slice(0, -4); // Remove '/api'
                              }

                              // Construct the full file URL
                              const fileUrl = `${baseUrl}${matchingFile.path}`;

                              // Open the file in a new tab/window
                              window.open(fileUrl, "_blank");
                            }}
                          >
                            <FeatherIcon icon="eye" size={"1rem"} />
                          </IconButton>
                        </div>
                      )}
                      {ficheiros[index] && (
                        <div
                          style={{
                            // marginRight: "10px",
                            display: "flex",
                            alignItems: "center",
                          }}
                          key={index}
                        >
                          {/* {ficheiros[index].file.name} */}
                          <IconButton
                            onClick={() => {
                              const fileName = ficheiros[index].file.name;
                              const fileExtension = fileName.slice(
                                (Math.max(0, fileName.lastIndexOf(".")) ||
                                  Infinity) + 1
                              );

                              if (fileExtension == "pdf") {
                                const pdfFile = ficheiros[index].file;

                                // Create a Blob from the PDF file
                                const blob = new Blob([pdfFile], {
                                  type: "application/pdf",
                                });

                                // Create a URL for the Blob
                                const blobUrl = URL.createObjectURL(blob);

                                // Now you can use `blobUrl` to open or display the PDF
                                window.open(blobUrl, "_blank");
                              } else {
                                const blobUrl = URL.createObjectURL(
                                  ficheiros[index].file
                                );

                                // Now you can use `blobUrl` to open or display the PDF
                                window.open(blobUrl, "_blank");
                              }
                            }}
                          >
                            <FeatherIcon icon="eye" size={"1rem"} />
                          </IconButton>
                          <IconButton onClick={() => handleRemoveFiles(index)}>
                            <FeatherIcon icon="trash-2" size={"1rem"} />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </Box>
                  {ficheiro.id_crede_elementos_files_tipos == 10 &&
                    ficheiros[index] && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: 1,
                        }}
                      >
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={ptLocale}
                        >
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Data de Validade
                          </Typography>
                          <DatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            format="dd/MM/yyyy"
                            okLabel="ok"
                            value={datePickerValues[index]}
                            onChange={(date) =>
                              handleDatePickerChange(date, index)
                            }
                            cancelLabel="cancelar"
                            invalidDateMessage="Data Inválida"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "dd/mm/aaaa",
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                name="data_nascimento"
                                sx={{ width: "200px" }}
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    )}
                </Box>
              );
            })}
        </Box>
      </Card>
    </div>
  );
};

export default FormEdit;
