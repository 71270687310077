/* eslint-disable eqeqeq */
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { persistor } from "../../../../utils/store";
import { FaRegHandshake } from "react-icons/fa";

const useStyles = makeStyles(() => ({
  button: {
    display: "flex",
    justifyContent: "center",
    height: 40,
    borderTop: "4px solid transparent",
    borderBottom: "4px solid transparent",

    "&:hover": {
      borderRight: "4px solid #E21450",
    },
  },
  divider: {
    borderLeft: "4px solid #E21450",
    borderRadius: "5px 0px 0px 5px",
    orientation: "vertical",
    height: "30px",
    width: "5px",
  },
}));

const PlusSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const permissoes = useSelector((state) => state.user.permissoes);

  console.log(permissoes);

  const menuList = [
    {
      id: 1,
      tooltip: "Dashboard",
      path: "#",
      icon: "home",
      fiIconCheck: true,
      permissao: "APP_JAFPLUS_DASHBOARD",
    },
    {
      id: 2,
      tooltip: "Adesões",
      path: "/plus/adesoes",
      icon: "clipboard",
      fiIconCheck: true,
      permissao: "APP_JAFPLUS_CONSULTAR_CONTRATOS",
    },
    {
      id: 3,
      tooltip: "Clientes",
      path: "/plus/clientes",
      icon: "users",
      fiIconCheck: true,
      permissao: "APP_JAFPLUS_CONSULTAR_CLIENTES",
    },
    {
      id: 4,
      tooltip: "Parceiros",
      path: "/plus/parceiros",
      icon: (
        <FaRegHandshake
          size={32}
          color={
            location.pathname === "/plus/parceiros" ? "#E21450" : "#707070"
          }
        />
      ),
      fiIconCheck: false,
      permissao: "APP_JAFPLUS_CONSULTAR_CLIENTES",
    },
  ];

  const [state, setState] = useState({
    settings: [
      { id: 1, open: false },
      { id: 2, open: false },
    ],
  });

  const handleClick = (id) => {
    setState((state) => ({
      ...state,
      settings: state.settings.map((item) =>
        item.id === id
          ? { ...item, open: !item.open }
          : { ...item, open: false }
      ),
    }));
  };

  const handleSair = () => {
    // setAnchorEl(null);

    localStorage.removeItem("token");
    localStorage.removeItem("persist:root");
    navigate("/");
    persistor.purge();
    dispatch({ type: "USER_LOGOUT" });
    // rootReducer(store, { type: 'USER_LOGOUT' });
  };

  return (
    <Box sx={{ display: "flex", zIndex: 1000 }}>
      <Box
        sx={{
          opacity: 1,
          boxShadow: "0px 5px 15px lightgrey",
          width: "70px",
          height: "100%",
          position: "fixed",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          mt: -6,
          pb: 2,
        }}
      >
        <Box>
          <Box sx={{ mt: 9 }}></Box>
          <List
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 4,
                justifyContent: "space-between",
                height: "auto",
              }}
            >
              {menuList.map((item, index) => (
                <>
                  {permissoes.includes(item.permissao) && (
                    <ListItem
                      key={item.id}
                      disablePadding
                      onClick={() => navigate(item.path)}
                      sx={{ mb: 4 }}
                    >
                      <Tooltip title={item.tooltip} placement="right">
                        <ListItemButton
                          sx={{
                            borderRight:
                              location.pathname === item.path ||
                              (item.submenu &&
                                item.submenu
                                  .map((item) => item.path)
                                  .includes(location.pathname))
                                ? "4px solid #E21450"
                                : null,
                          }}
                          className={classes.button}
                          onClick={() => handleClick(item.id)}
                        >
                          <ListItemIcon
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            {item.fiIconCheck === true ? (
                              <FeatherIcon
                                icon={item.icon}
                                color={
                                  location.pathname === item.path
                                    ? "#E21450"
                                    : "#707070"
                                }
                                size={30}
                                strokeWidth={1.5}
                              />
                            ) : (
                              item.icon
                            )}
                          </ListItemIcon>
                        </ListItemButton>
                      </Tooltip>
                      {/* {location.pathname == item.path ? <Divider orientation="vertical" className={classes.divider} /> : null} */}
                    </ListItem>
                  )}
                </>
              ))}
            </Box>
            <Divider
              variant="middle"
              sx={{
                mt: -2,
              }}
            />
          </List>
        </Box>
        <Box>
          <Divider
            variant="middle"
            sx={{
              mb: 2,
            }}
          />
          <List>
            <ListItem disablePadding onClick={handleSair}>
              <Tooltip title="Sair" placement="right">
                <ListItemButton
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <ListItemIcon
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <FeatherIcon
                      icon="log-out"
                      color="#707070"
                      size={30}
                      strokeWidth={1.5}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </List>
        </Box>
      </Box>
      <Box sx={{ pl: 8 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default PlusSidebar;
