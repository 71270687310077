/* eslint-disable eqeqeq */
import React from "react";

// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Box, Button, Card, Drawer, TextField } from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptLocale from "date-fns/locale/pt";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
});

const FilterDrawer = ({
  state,
  toggleDrawer,
  drawerWidth,
  dimensions,
  fetchDashboardEnergias,
  parceiros,
  parceirosSelectedId,
  dataInicio,
  dataFim,
  setDataInicio,
  setDataFim,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const energParceiroId = useSelector((state) => state.user.energParceiroId);
  const perfilId = useSelector((state) => state.user.perfilId);

  const handleDataInicioChange = (date) => {
    setDataInicio(date);
    dispatch({
      type: "DASHBOARD_DATA_INICIO",
      payload: date,
    });
  };

  const handleDataFimChange = (date) => {
    setDataFim(date);
    dispatch({
      type: "DASHBOARD_DATA_FIM",
      payload: date,
    });
  };

  const handleSubmit = () => {
    let parceirosId = [];
    if (perfilId == 5) {
      parceirosId = energParceiroId;
    } else {
      parceirosId = parceirosSelectedId.map((ep) => ep.id);
    }
    fetchDashboardEnergias(parceirosId, dataInicio, dataFim);
  };

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={() => toggleDrawer("right", false)}
        classes={{ paper: classes.paper }}
      >
        <div
          className={classes.list}
          style={
            dimensions.width <= 1100
              ? { width: "60vw" }
              : { width: `${drawerWidth}` }
          }
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              m: 5,
            }}
          >
            <Box
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              onClick={() => toggleDrawer("right", false)}
            >
              <div>
                <FeatherIcon
                  icon="chevron-left"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </div>
              <div className="text-underline-close-drawer">Dashboard</div>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                className={classes.btnFiltrar}
                onClick={() => {
                  handleSubmit();
                  toggleDrawer("right", false);
                }}
              >
                Filtrar
              </Button>
            </Box>
          </Box>
          <Card sx={{ m: 5, p: 2, boxShadow: "0px 10px 15px lightgrey" }}>
            <Box
              sx={{
                m: 2,
              }}
            >
              <h4
                style={{
                  fontSize: "22px",
                  width: "100%",
                  marginBottom: "0px",
                }}
                className="text-underline"
              >
                Filtros
              </h4>
            </Box>
            <Box sx={{ m: 2 }}>
              <h4
                style={{ color: "#E21450" }}
                className="text-underline-title-drawer "
              >
                Data de criação
              </h4>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                m: 2,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", mr: 1 }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <b>Data Inicio</b>
                  <DatePicker
                    style={{ width: "100%" }}
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    okLabel="ok"
                    cancelLabel="cancelar"
                    invalidDateMessage="Data Inválida"
                    value={dataInicio}
                    onChange={handleDataInicioChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", ml: 1 }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <b>Data Fim</b>
                  <DatePicker
                    style={{ width: "100%" }}
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    okLabel="ok"
                    cancelLabel="cancelar"
                    invalidDateMessage="Data Inválida"
                    value={dataFim}
                    onChange={handleDataFimChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            {perfilId !== 5 ? (
              <Box>
                <Box
                  sx={{
                    m: 2,
                    mb: 4,
                  }}
                >
                  <h4
                    style={{ color: "#E21450" }}
                    className="text-underline-title-drawer "
                  >
                    Parceiros
                  </h4>
                </Box>
                {parceiros.map((parceiro) => {
                  const parceiroExists = !parceirosSelectedId.some(
                    (o2) => o2.id === parceiro.id
                  );
                  return (
                    <Box sx={{ m: 2 }} key={parceiro.id}>
                      <Button
                        className={
                          !parceiroExists ? classes.btnTrue : classes.btnFalse
                        }
                        onClick={() => {
                          if (!parceiroExists) {
                            dispatch({
                              type: "DASHBOARD_PARCEIROS_SELECTED",
                              payload: parceirosSelectedId.filter(
                                (est) => est.id !== parceiro.id
                              ),
                            });
                          } else {
                            dispatch({
                              type: "DASHBOARD_PARCEIROS_SELECTED",
                              payload: [...parceirosSelectedId, parceiro],
                            });
                          }
                        }}
                      >
                        {parceiro.nome}
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            ) : null}
          </Card>
        </div>
      </Drawer>
    </div>
  );
};

export default FilterDrawer;
