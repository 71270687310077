import React from 'react';
import { TextField, Typography } from '@mui/material';

const Calendario = ({ obraSelecionada }) => {
  return (
    <>
      <div>
        <h4
          style={{ color: '#E21450' }}
          className='text-underline-title-drawer '
        >
          Calendário
        </h4>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ width: '160px', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Data de Criação
            </Typography>
            <TextField
              size='small'
              value={
                (obraSelecionada && obraSelecionada.dataCriacao) ||
                ' --/--/---- '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <span style={{ width: '24px' }} />
          <div style={{ width: '160px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Idade
            </Typography>

            <TextField
              size='small'
              value={
                (obraSelecionada && obraSelecionada.idade) || ' --/--/---- '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <span style={{ width: '24px' }} />
          <div style={{ width: '160px', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Data de Início
            </Typography>
            <TextField
              size='small'
              value={
                (obraSelecionada && obraSelecionada.dataInicial) ||
                ' --/--/---- '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <span style={{ width: '24px' }} />
          <div style={{ width: '160px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Data de Fim
            </Typography>
            <TextField
              size='small'
              value={
                (obraSelecionada && obraSelecionada.dataFim) || ' --/--/---- '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <span style={{ width: '24px' }} />
          <div style={{ width: '160px', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Data Real de Início
            </Typography>

            <TextField
              size='small'
              value={
                (obraSelecionada && obraSelecionada.dataRealInicio) ||
                ' --/--/---- '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <span style={{ width: '24px' }} />
          <div style={{ width: '160px', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Data Real de Fim
            </Typography>
            <TextField
              size='small'
              value={
                (obraSelecionada && obraSelecionada.dataRealFim) ||
                ' --/--/---- '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
        </div>
        <div style={{ display: 'flex' }}></div>
      </div>
    </>
  );
};

export default Calendario;
