import Instance from "../../../../Axios";

// New Actions for the app

const GetOrcamentosPerMonth = (startOfMonth, endOfMonth) => async (dispatch) => {
  await Instance()
    .get(`/energias/dashboard/orcamentosPerMonth`, {
      params: { startOfMonth, endOfMonth },
    })
    .then(({ data }) => {
      //   console.log(data);
      dispatch({
        type: "GET_ORCAMENTOS_PER_MONTH",
        payload: data.yData,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetOrcamentosPerMonth;