/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Box, IconButton, Fade, TextField, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";

// Components
import ModalDelete from "./components/modalDelete";
import SideDrawer from "./components/drawer";

//Icons
import FeatherIcon from "feather-icons-react";

//Actions
import GetAllFabricantes from "../../../../utils/redux/actions/energias/fabricantes/GetAllFabricantes";

// ############################################################## //
// #####################  Window Dimensions  #################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Empresas = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [fabricante, setFabricante] = useState({
    descricao: "",
    responsavel: "",
    contacto: "",
  });
  const dimensions = useWindowDimensions();

  const permissoes = useSelector((state) => state.user.permissoes);

  // ############################################################## //
  // ##########################  Drawer  ########################## //
  // ############################################################## //

  const [drawerWidth, setDrawerWidth] = useState("25vw");
  const [state, setState] = useState({
    right: false,
  });
  const [action, setAction] = useState();
  const toggleDrawer = (anchor, open, id, action) => {
    if (open == false) {
      setState({ ...state, [anchor]: open });
      setAction(null);
      setFabricanteId(null);
    }
    if (open == true) {
      setState({ ...state, [anchor]: open });
      setAction(action);
      setFabricanteId(id);
    }
  };

  // ############################################################## //
  // ########################  Card Width  ######################## //
  // ############################################################## //

  let cardWidth = "23.5%";
  let spacing = "1.5%";
  if (dimensions.width <= 1500 && dimensions.width > 1150) {
    cardWidth = "31.5%";
  } else if (dimensions.width <= 1150 && dimensions.width > 800) {
    cardWidth = "48.5%";
  } else if (dimensions.width <= 800) {
    cardWidth = "100%";
    spacing = "0%";
  }

  // ############################################################## //
  // ##########################  Search  ########################## //
  // ############################################################## //

  const [filter, setFilter] = useState("");
  const [checked, setChecked] = useState(false);

  const handleChangeChecked = () => {
    setChecked((prev) => !prev);
  };

  const handleChange = (e) => {
    e.preventDefault();

    setFilter(e.target.value);
  };

  const checkFilter = (fichaTec) => {
    if (Object.keys(fichaTec).length > 0) {
      return fichaTec.descricao.toLowerCase().includes(filter.toLowerCase());
    }
    return null;
  };

  // ############################################################## //
  // ########################  Modal Delete  ###################### //
  // ############################################################## //

  const [openDelete, setOpenDelete] = useState(false);
  const [fabricanteId, setFabricanteId] = useState();
  const [loading, setLoading] = useState(false);

  const handleOpen = (e, id) => {
    e.preventDefault();

    setLoading(false);

    setOpenDelete(true);
    setFabricanteId(id);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpenDelete(false);
  };

  // ############################################################## //
  // ###################  MUI Datatable columns  ################## //
  // ############################################################## //

  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");
  const [searchText, setSearchText] = useState("");

  const columns = [
    {
      name: "id",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { width: "95%" },
        }),
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: "center",
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const o = tableMeta.rowData[0];

          return (
            <div style={{ display: "flex" }}>
              {permissoes.includes("APP_ENERGIAS_FABRICANTES_EDITAR") ? (
                <IconButton
                  onClick={() => toggleDrawer("right", true, o, "edit")}
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                >
                  <FeatherIcon
                    icon="edit"
                    size={25}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              ) : null}
              {permissoes.includes("APP_ENERGIAS_FABRICANTES_REMOVER") ? (
                <IconButton
                  onClick={(e) => handleOpen(e, o)}
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                >
                  <FeatherIcon
                    icon="trash-2"
                    size={25}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              ) : null}
            </div>
          );
        },
      },
    },
  ];

  // ############################################################## //
  // ###################  MUI Datatable options  ################## //
  // ############################################################## //

  const options = {
    filter: false,
    filterType: "dropdown",
    searchText: searchText,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    search: false,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },
  };

  const fabricantes = useSelector((state) => state.fabricantes.fabricantes);

  const fetchFabricantes = () => {
    dispatch(GetAllFabricantes());
  };

  useEffect(() => fetchFabricantes(), []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          mx: 5,
          mt: 3,
        }}
      >
        <SideDrawer
          state={state}
          action={action}
          toggleDrawer={toggleDrawer}
          fabricanteId={fabricanteId}
          drawerWidth={drawerWidth}
          dimensions={dimensions}
          fabricante={fabricante}
          setFabricante={setFabricante}
          fetchFabricantes={fetchFabricantes}
        />
        <ModalDelete
          openDelete={openDelete}
          handleClose={handleClose}
          fabricanteId={fabricanteId}
          fetchFabricantes={fetchFabricantes}
          // show={show}
          // Loading
          loading={loading}
          setLoading={setLoading}
        />
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <h4
                style={{
                  fontSize: "22px",
                  width: "100%",
                }}
                className="text-underline"
              >
                Empresas
              </h4>
            </Box>
            <Box sx={{ display: "flex" }}>
              {checked == true ? (
                <Box sx={{ mt: -1 }}>
                  <Fade in={checked}>
                    <TextField
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      label="Pesquisar"
                      variant="standard"
                    />
                  </Fade>
                </Box>
              ) : null}
              <IconButton onClick={handleChangeChecked}>
                <FeatherIcon
                  icon="search"
                  color="#000"
                  size={30}
                  strokeWidth={1.5}
                  checked={checked}
                />
              </IconButton>
              {permissoes.includes("APP_ENERGIAS_FABRICANTES_ADICIONAR") ? (
                <IconButton
                  onClick={() => toggleDrawer("right", true, null, "add")}
                >
                  <FeatherIcon
                    icon="plus-square"
                    color="#000"
                    size={30}
                    strokeWidth={1.5}
                  />
                </IconButton>
              ) : null}
            </Box>
          </Box>
          <Box>
            <MUIDataTable
              data={fabricantes || []}
              columns={columns}
              options={options}
            />
          </Box>
          {/* Footer */}
          {/* <div style={{ margin: "20px 40px" }}>
            <FooterLinks />
          </div> */}
        </Box>
      </Box>
    </>
  );
};

export default Empresas;
