export const obrasInitialState = {
  obras: [],
  obrasAHD: [],
  obrasAHDAdmin: [],
  obrasAHDSerralharia: [],
  obrasAHDEmCurso: null,
  obrasAHDEmCursoSerralharia: null,
  obrasAHDRealizadasTotal: null,
  obrasAHDRealizadasTotalSemana: null,
  obrasAHDMediaIdadeRealizadas: null,
  obrasAHDProjetosTotais: null,
  obrasAHDProjetosTotaisPenalizados: null,
  obrasAHDProjetosPenalizadosAnoAtual: null,
  obrasAHDProjetosPenalizadosAnoAnterior: null,
  obrasAHDProjetosPenalizadosIniciados: null,
  graficoObrasMensal: [],
  graficoObrasSemanal: [],
  obra: {},
  obraEditada: {},

  //ESTADOS
  estadosControlo: [],
  estadosEspecialidade: [],
  estadosMaterial: [],
  especialidadesEstados: [],

  //MATERIAIS E TRABALHOS
  obrasMateriais: [],
  obrasSubMateriais: [],
  obrasTrabalhos: [],
  obrasEmpresas: [],
  obrasUnidMedidas: [],
  obrasEquipamentos: [],

  //FILTERS
  estadosProjeto: [],
  estadosSubProjeto: [],
  aeroportos: [],
  diretoresObraProjeto: [],

  //FILTERS_PRE_SELECTED
  aeroportosPreSelected: [],
  estadosProjetoPreSelected: [{ id: 2, descricao: "Aberto" }],
  estadosSubProjetoPreSelected: [
    { id: 0, descricao: "-" },
    { id: 1, descricao: "Aberto" },
    { id: 2, descricao: "Iniciado" },
    { id: 3, descricao: "Executado" },
    { id: 6, descricao: "Suspenso" },
  ],
  controloPreSelected: [],
  diretorObraPreSelected: { id: "0", nome: "" },

  ficheiros: [],
  obraOrcamentos: [],
  obraFotos: [],

  //WORK PERMITS
  workPermits: {},
  workPermitsMEIO: [],
  workPermitsFileTypes: [],
  workPermitsAccountables: [],
  workPermitsAccountablesAHD: [],
  workPermitsEntities: [],
  workPermitsRespEntities: [],
  workPermitsStatus: [],
  obraWorkPermits: [],
  workPermit: {},
  workPermitsContacts: [],
  workPermitsContactsAHD: [],
  workPermitsSendContacts: [],
  workPermitUploadedFiles: [],
  workPermitsDefaultAccountables: [],
  workPermitsDefaultAccountablesAHD: [],
  workPermitsDefaultSendEmails: [],
  workPermitsAllAccountables: [],
  workPermitAccountable: {},
  workPermitsEmailBodyTemplate: [],
  workPermitsDefaultEmails: [],
};
