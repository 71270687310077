import Instance from "../../../Axios";

// New Actions for the app

const EditPartnerById = (yData, id) => async (dispatch) => {
  await Instance()
    .put(`/plus/adesoes/partners/${id}`, { yData })
    .then(({ data }) => {
      dispatch({ type: "EDIT_PARTNER_BY_ID", payload: data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default EditPartnerById;
