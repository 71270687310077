import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Autocomplete,
  IconButton,
  Button,
  Menu,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptLocale from "date-fns/locale/pt";

//Icons
import FeatherIcon from "feather-icons-react";

//Action
import GetObrasSubMateriais from "../../../../../../../utils/redux/actions/aprovisionamento/obras/GetObrasSubMateriaisAction";

const Materiais = ({
  obraSelecionada,
  setObraSelecionada,
  control,
  reset,
  watch,
  resetForm,
  errors,
  setResetForm,
  getValues,
  setValue,
  updateGlobals,
  renderSwitch,
  setEstDT,
  setEstDC,
}) => {
  const dispatch = useDispatch();
  const estadosMaterial = useSelector((state) => state.obras.estadosMaterial);

  const materiais = useSelector((state) => state.obras.obrasMateriais);
  const subMateriais = useSelector((state) => state.obras.obrasSubMateriais);
  const unidades = useSelector((state) => state.obras.obrasUnidMedidas);
  const [unidMedida, setUnidMedida] = useState([]);

  // ############################################################## //
  // ######################  Handle Unidades  ##################### //
  // ############################################################## //

  const handleUnidadeChange = (index, unidade) => {
    setUnidMedida((prevFicheiros) => {
      const updatedUnidades = [...prevFicheiros];
      updatedUnidades[index] = unidade;
      return updatedUnidades;
    });
  };

  const handleRemoveUnidade = (index) => {
    setUnidMedida((prevFicheiros) => {
      const updatedUnidades = [...prevFicheiros];
      updatedUnidades[index] = null;
      return updatedUnidades;
    });
  };

  const calendarIcon = () => {
    return <FeatherIcon icon="calendar" size={20} />;
  };

  // ############################################################## //
  // #######################  Semaforizações  ##################### //
  // ############################################################## //

  // Valores das Cores
  // 1 - Verde
  // 2 - Amarelo
  // 3 - Vermelho

  // Caso o estado do material seja alterado
  const onSubStateChange = (value, index, subMaterialIndex) => {
    if (value) {
      switch (value.id) {
        case 1:
          setEstDC([index, "1"]);
          setValue(
            `materiais[${index}].subMateriais[${subMaterialIndex}].estadodc`,
            "1"
          );
          break;
        case 2:
          setEstDC([index, "2"]);
          setValue(
            `materiais[${index}].subMateriais[${subMaterialIndex}].estadodc`,
            "2"
          );
          break;
        case 3:
          setEstDC([index, "2"]);
          setValue(
            `materiais[${index}].subMateriais[${subMaterialIndex}].estadodc`,
            "2"
          );
          break;
        case 4:
          setEstDC([index, "2"]);
          setValue(
            `materiais[${index}].subMateriais[${subMaterialIndex}].estadodc`,
            "2"
          );
          break;
        case 5:
          setEstDC([index, "1"]);
          setValue(
            `materiais[${index}].subMateriais[${subMaterialIndex}].estadodc`,
            "1"
          );
          break;
        default:
          break;
      }
      updateGlobals();
    } else {
      setEstDC([index, "3"]);
      setValue(
        `materiais[${index}].subMateriais[${subMaterialIndex}].estadodc`,
        "3"
      );
      updateGlobals();
    }
    console.log(getValues());
  };

  // Caso o material seja alterado
  const onSubMatChange = (value, index, subMaterialIndex) => {
    if (value) {
      if (
        getValues(`materiais[${index}].subMateriais[${subMaterialIndex}].qtd`)
      ) {
        setEstDT([index, "1"]);
        setValue(
          `materiais[${index}].subMateriais[${subMaterialIndex}].estadodt`,
          "1"
        );
      } else {
        setEstDT([index, "2"]);
        setValue(
          `materiais[${index}].subMateriais[${subMaterialIndex}].estadodt`,
          "2"
        );
      }
      if (
        !getValues(
          `materiais[${index}].subMateriais[${subMaterialIndex}].estadosDropdown`
        )
      ) {
        setEstDC([index, "3"]);
        setValue(
          `materiais[${index}].subMateriais[${subMaterialIndex}].estadodc`,
          "3"
        );
      }
      updateGlobals();
    } else {
      setEstDT([index, "2"]);
      setValue(
        `materiais[${index}].subMateriais[${subMaterialIndex}].estadodt`,
        "2"
      );
      updateGlobals();
    }
    console.log(getValues());
  };

  // Caso a quantidade seja alterada
  const onSubQtdChange = (value, index, subMaterialIndex) => {
    if (value) {
      setEstDT([index, "1"]);
      setValue(
        `materiais[${index}].subMateriais[${subMaterialIndex}].estadodt`,
        "1"
      );
      updateGlobals();
    } else {
      setEstDT([index, "2"]);
      setValue(
        `materiais[${index}].subMateriais[${subMaterialIndex}].estadodt`,
        "2"
      );
      updateGlobals();
    }
    console.log(getValues());
  };

  // ############################################################## //
  // #################  Handle Adicionar Familia  ################# //
  // ############################################################## //

  const handleAddMat = async () => {
    console.log(obraSelecionada.materiais.length);
    const lastMat =
      obraSelecionada &&
      obraSelecionada.materiais &&
      obraSelecionada.materiais.length + 1;

    setResetForm(true);

    const newMaterial = {
      dataFim: null,
      dataInicio: null,
      estadosDescricao: null,
      estadosDropdown: { id: "", descricao: "" },
      estadosId: null,
      materiaisDescricao: null,
      materiaisDropdown: { id: "", descricao: "" },
      materiaisId: lastMat,
      obrasId: obraSelecionada && obraSelecionada.obrasId,
      observacoes: null,
      qtd: null,
      subMateriais: [],
      estadodt: null,
      estadodc: null,
    };

    obraSelecionada.materiais.push(newMaterial);
    await setObraSelecionada({ ...obraSelecionada });

    await setResetForm(false);

    console.log(resetForm, obraSelecionada);
  };

  // ############################################################## //
  // ################  Handle Adicionar Material  ################# //
  // ############################################################## //

  const handleAddSub = async (material, index) => {
    console.log(material, index);
    const lastSub = material.subMateriais.length + 1;

    setResetForm(true);

    const newMaterial = {
      dataFim: null,
      dataInicio: null,
      estadosDescricao: null,
      estadosDropdown: null,
      estadosId: null,
      materiaisDescricao: null,
      materiaisId: material.materiaisId,
      obrasId: obraSelecionada && obraSelecionada.obrasId,
      observacoes: null,
      qtd: null,
      subMateriaisDescricao: "",
      subMateriaisDropdown: { id: "", descricao: "" },
      subMateriaisId: lastSub,
      estadodt: null,
      estadodc: null,
    };

    obraSelecionada.materiais[index].subMateriais.push(newMaterial);
    await setObraSelecionada({ ...obraSelecionada });

    await setResetForm(false);

    console.log(resetForm);
  };

  // ############################################################## //
  // ##################  Handle Remover Familia  ################## //
  // ############################################################## //

  const handleRemoveMat = async (index) => {
    console.log(index);
    const obra = getValues();
    await setResetForm(true);

    if (index !== -1) {
      obra.materiais.splice(index, 1);
    }

    await setObraSelecionada({ ...obra });
    await setResetForm(false);
    await reset({ ...obra });
    updateGlobals();
  };

  // ############################################################## //
  // ##################  Handle Remover Material  ################# //
  // ############################################################## //

  const handleRemoveSub = async (
    material,
    index,
    subMaterial,
    subMaterialIndex
  ) => {
    const obra = getValues();
    await setResetForm(true);

    if (subMaterialIndex !== -1) {
      obra.materiais[index].subMateriais.splice(subMaterialIndex, 1);
    }

    await setObraSelecionada({ ...obra });
    await setResetForm(false);
    await reset({ ...obra });
    updateGlobals();
  };

  // ############################################################## //
  // ######################  Button Collapse  ##################### //
  // ############################################################## //

  const [anchorEl, setAnchorEl] = useState([]);
  const handleMenu = (index, event) => {
    let tableMenus = [...anchorEl];
    tableMenus[index] = event.currentTarget;
    setAnchorEl(tableMenus);
  };
  const handleClose = (index) => {
    let tableMenus = [...anchorEl];
    tableMenus[index] = null;
    setAnchorEl(tableMenus);
  };

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            width: "100%",
            padding: "5px",
            background: "#F1F1F1",
            border: "1px solid #707070",
            borderRadius: "8px",
          }}
        >
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => handleAddMat()}
            color="inherit"
          >
            <FeatherIcon icon="plus-square" size={20} />
          </IconButton>
          <h4
            style={{
              width: "25%",
              margin: "10px 2% 10px 10px",
              padding: "0px",
            }}
          >
            Material
          </h4>

          <h4
            style={{
              width: "15%",
              margin: "10px 2% 10px 10px",
              padding: "0px",
            }}
          >
            Estado
          </h4>

          <h4
            style={{
              width: "5%",
              margin: "10px 2% 10px 10px",
              padding: "0px",
            }}
          >
            Qtd.
          </h4>
          <h4
            style={{
              width: "10%",
              margin: "10px 2% 10px 4%",
              padding: "0px",
            }}
          >
            Data Inicio
          </h4>
          <h4
            style={{
              width: "10%",
              margin: "10px 2% 10px 0px",
              padding: "0px",
            }}
          >
            Data Fim
          </h4>
          <h4
            style={{
              width: "25%",
              margin: "10px 2% 10px 10px",
              padding: "0px",
            }}
          >
            Observações
          </h4>
          <div
            style={{
              width: "10%",
              display: "flex",
              justifyContent: "space-around",
              marginLeft: "5px",
              float: "right",
            }}
          >
            <h4
              style={{
                margin: "10px 0px 10px 0px",
                padding: "0px",
              }}
            >
              Téc.
            </h4>
            <h4
              style={{
                margin: "10px 0px 10px 0px",
                padding: "0px",
              }}
            >
              Com.
            </h4>
          </div>
        </div>

        {obraSelecionada &&
          obraSelecionada.materiais &&
          obraSelecionada.materiais.map((material, index) => (
            <div
              key={index}
              style={{
                background: "white",
                minHeight: "40px",
                marginTop: "10px",
                marginBottom: "10px",
                border: "1px solid #707070",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  padding: "5px",
                }}
              >
                <form
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  autocomple="off"
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={(e) => handleMenu(index, e)}
                      color="inherit"
                    >
                      <FeatherIcon icon="menu" size={20} />
                    </IconButton>

                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl[index]}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      // transformOrigin={{
                      //   vertical: "top",
                      //   horizontal: "right",
                      // }}
                      open={Boolean(anchorEl[index])}
                      onClose={(e) => handleClose(index)}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        <Button
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={() => {
                            handleAddSub(material, index);
                            handleClose(index);
                            const catId = getValues(
                              `materiais[${index}].materiaisDropdown.id`
                            );
                            dispatch(GetObrasSubMateriais(catId));
                          }}
                          color="inherit"
                          sx={{
                            display: "flex",
                            textTransform: "capitalize",
                            justifyContent: "flex-start",
                          }}
                        >
                          {/* <FeatherIcon icon="plus-square" size={20} /> */}
                          Adicionar Material
                        </Button>
                        <Button
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={() => {
                            handleRemoveMat(index);
                            handleClose(index);
                          }}
                          color="inherit"
                          sx={{
                            display: "flex",
                            textTransform: "capitalize",
                            justifyContent: "flex-start",
                          }}
                        >
                          {/* <FeatherIcon icon="x-square" size={20} /> */}
                          Remover Familia
                        </Button>
                      </div>
                    </Menu>
                    <Controller
                      name={`materiais[${index}][materiaisDropdown]`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Autocomplete
                            fullWidth
                            clearText="Limpar"
                            openText="Abrir"
                            closeText="Fechar"
                            noOptionsText="Sem dados"
                            className="unidadesId-form"
                            options={materiais || []}
                            value={value || null}
                            getOptionLabel={(option) =>
                              `${option.descricao}` || ""
                            }
                            isOptionEqualToValue={(option, value) => {
                              // if (!value.id) return true;
                              const intOption = option && parseInt(option.id);
                              return value.id === intOption;
                              // console.log(intOption);
                            }}
                            filterOptions={(options, state) => {
                              let newOptions = [];
                              options.forEach((option) => {
                                if (
                                  option.id !== "" &&
                                  option.descricao
                                    .toLowerCase()
                                    .includes(state.inputValue.toLowerCase())
                                )
                                  newOptions.push(option);
                              });
                              return newOptions;
                            }}
                            style={{
                              width: "23%",
                              marginLeft: "10px",
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Familia"
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                variant="standard"
                              />
                            )}
                            onChange={(_, value) => {
                              onChange(value);
                              dispatch(GetObrasSubMateriais(value.id));
                              return value;
                            }}
                          />
                        </>
                      )}
                    />
                    <Controller
                      name={`materiais[${index}][estadosDropdown]`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Autocomplete
                            fullWidth
                            clearText="Limpar"
                            openText="Abrir"
                            closeText="Fechar"
                            noOptionsText="Sem dados"
                            className="unidadesId-form"
                            options={estadosMaterial || []}
                            value={value || null}
                            getOptionLabel={(option) =>
                              `${option.descricao}` || ""
                            }
                            isOptionEqualToValue={(option, value) => {
                              // if (!value.id) return true;
                              return value.id === option.id;
                            }}
                            filterOptions={(options, state) => {
                              let newOptions = [];
                              options.forEach((option) => {
                                if (
                                  option.id > 0 &&
                                  option.descricao
                                    .toLowerCase()
                                    .includes(state.inputValue.toLowerCase())
                                )
                                  newOptions.push(option);
                              });
                              return newOptions;
                            }}
                            style={{
                              width: "14%",
                              marginLeft: "10px",
                              visibility: "hidden",
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Estado"
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                variant="standard"
                              />
                            )}
                            onChange={(_, value) => {
                              onChange(value);
                              return value;
                            }}
                          />
                        </>
                      )}
                    />
                    <Controller
                      name={`materiais[${index}][qtd]`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextField
                            placeholder="Quantidade"
                            color="primary"
                            {...field}
                            style={{
                              width: "5%",
                              marginLeft: "30px",
                              visibility: "hidden",
                            }}
                            variant="standard"
                            InputProps={{ disableUnderline: true }}
                            type="number"
                          />
                        </>
                      )}
                    />
                    <Controller
                      name={`materiais[${index}][unidadesDropdown]`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Autocomplete
                            fullWidth
                            clearText="Limpar"
                            openText="Abrir"
                            closeText="Fechar"
                            noOptionsText="Sem dados"
                            className="unidadesId-form"
                            options={unidades || []}
                            value={value || null}
                            getOptionLabel={(option) =>
                              `${option.descricao}` || ""
                            }
                            isOptionEqualToValue={(option, value) => {
                              // if (!value.id) return true;
                              return value.id === option.id;
                            }}
                            filterOptions={(options, state) => {
                              let newOptions = [];
                              options.forEach((option) => {
                                if (
                                  option.id > 0 &&
                                  option.descricao
                                    .toLowerCase()
                                    .includes(state.inputValue.toLowerCase())
                                )
                                  newOptions.push(option);
                              });
                              return newOptions;
                            }}
                            style={{
                              maxWidth: "5%",
                              visibility: "hidden",
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Uni."
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                variant="standard"
                              />
                            )}
                            onChange={(_, value) => {
                              onChange(value);
                              return value;
                            }}
                          />
                        </>
                      )}
                    />
                    <Controller
                      name={`materiais[${index}][dataInicio]`}
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={ptLocale}
                        >
                          <DatePicker
                            invalidDateMessage="Data Inválida"
                            {...field}
                            components={{
                              OpenPickerIcon: calendarIcon,
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "dd/mm/aaaa",
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                variant="standard"
                                size="small"
                                sx={{
                                  width: "10%",
                                  marginLeft: "20px",
                                  paddingRight: "10px",
                                  visibility: "hidden",
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />

                    <Controller
                      name={`materiais[${index}][dataFim]`}
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={ptLocale}
                        >
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            invalidDateMessage="Data Inválida"
                            {...field}
                            components={{
                              OpenPickerIcon: calendarIcon,
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "dd/mm/aaaa",
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                variant="standard"
                                size="small"
                                sx={{
                                  width: "10%",
                                  marginLeft: "20px",
                                  paddingRight: "10px",
                                  visibility: "hidden",
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />

                    <Controller
                      name={`materiais[${index}][observacoes]`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextField
                            placeholder="Observações"
                            color="primary"
                            {...field}
                            style={{
                              width: "25%",
                              paddingLeft: "30px",
                              visibility: "hidden",
                            }}
                            variant="standard"
                            InputProps={{ disableUnderline: true }}
                          />
                        </>
                      )}
                    />
                    <div
                      style={{
                        width: "10%",
                        display: "flex",
                        justifyContent: "space-around",
                        paddingLeft: "20px",
                        visibility: "hidden",
                      }}
                    ></div>
                  </div>
                  {material &&
                    material.subMateriais &&
                    material.subMateriais.map(
                      (subMaterial, subMaterialIndex) => (
                        <>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              aria-label="account of current user"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              onClick={() =>
                                handleRemoveSub(
                                  material,
                                  index,
                                  subMaterial,
                                  subMaterialIndex
                                )
                              }
                              color="inherit"
                            >
                              <FeatherIcon icon="x-square" size={20} />
                            </IconButton>
                            <Controller
                              name={`materiais[${index}][subMateriais][${subMaterialIndex}][subMateriaisDropdown]`}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <Autocomplete
                                    freeSolo
                                    fullWidth
                                    clearText="Limpar"
                                    openText="Abrir"
                                    closeText="Fechar"
                                    noOptionsText="Sem dados"
                                    className="unidadesId-form"
                                    options={subMateriais || []}
                                    value={value || null}
                                    getOptionLabel={(option) =>
                                      `${option.descricao}` || ""
                                    }
                                    // filterOptions={(options, state) => {
                                    //   let newOptions = [];
                                    //   options.forEach((option) => {
                                    //     if (
                                    //       option.id !== "" &&
                                    //       option.descricao
                                    //         .toLowerCase()
                                    //         .includes(
                                    //           state.inputValue.toLowerCase()
                                    //         )
                                    //     )
                                    //       newOptions.push(option);
                                    //   });
                                    //   return newOptions;
                                    // }}
                                    isOptionEqualToValue={(option, value) => {
                                      // if (!value.id) return true;
                                      return value.id === option.id;
                                    }}
                                    style={{
                                      width: "23%",
                                      marginLeft: "10px",
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Material"
                                        InputProps={{
                                          ...params.InputProps,
                                          disableUnderline: true,
                                        }}
                                        variant="standard"
                                      />
                                    )}
                                    onHighlightChange={(e, value, reason) => {
                                      const catId = getValues(
                                        `materiais[${index}].materiaisDropdown.id`
                                      );
                                      dispatch(GetObrasSubMateriais(catId));
                                    }}
                                    onChange={(_, value, reason) => {
                                      console.log(value);
                                      if (reason === "clear") {
                                        handleRemoveUnidade(subMaterialIndex);
                                      } else {
                                        if (typeof value === "string") {
                                          // The user entered a new value that is not in the options list
                                          const newMaterial = {
                                            id: null,
                                            descricao: value,
                                            unidade: null,
                                            material: {
                                              id: null,
                                              descricao: value,
                                            },
                                          };
                                          onChange(newMaterial.material);
                                          onSubMatChange(
                                            newMaterial.material,
                                            index,
                                            subMaterialIndex
                                          );
                                          setValue(
                                            `materiais[${index}][subMateriais][${subMaterialIndex}][unidadesDropdown]`,
                                            newMaterial.unidade
                                          );
                                          handleUnidadeChange(
                                            subMaterialIndex,
                                            newMaterial.unidade
                                          );
                                          return newMaterial.material;
                                        } else {
                                          // User selected an existing option from the options list
                                          onChange(value.material);
                                          onSubMatChange(
                                            value.material,
                                            index,
                                            subMaterialIndex
                                          );
                                          setValue(
                                            `materiais[${index}][subMateriais][${subMaterialIndex}][unidadesDropdown]`,
                                            value.unidade
                                          );
                                          handleUnidadeChange(
                                            subMaterialIndex,
                                            value.unidade
                                          );
                                          return value.material;
                                        }
                                      }
                                    }}
                                  />
                                </>
                              )}
                            />
                            <Controller
                              name={`materiais[${index}][subMateriais][${subMaterialIndex}][estadosDropdown]`}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <Autocomplete
                                    fullWidth
                                    clearText="Limpar"
                                    openText="Abrir"
                                    closeText="Fechar"
                                    noOptionsText="Sem dados"
                                    className="unidadesId-form"
                                    options={estadosMaterial || []}
                                    value={value || null}
                                    getOptionLabel={(option) =>
                                      `${option.descricao}` || ""
                                    }
                                    isOptionEqualToValue={(option, value) => {
                                      // if (!value.id) return true;
                                      return value.id === option.id;
                                    }}
                                    filterOptions={(options, state) => {
                                      let newOptions = [];
                                      options.forEach((option) => {
                                        if (
                                          option.id > 0 &&
                                          option.descricao
                                            .toLowerCase()
                                            .includes(
                                              state.inputValue.toLowerCase()
                                            )
                                        )
                                          newOptions.push(option);
                                      });
                                      return newOptions;
                                    }}
                                    style={{
                                      width: "14%",
                                      marginLeft: "10px",
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Estado"
                                        InputProps={{
                                          ...params.InputProps,
                                          disableUnderline: true,
                                        }}
                                        variant="standard"
                                      />
                                    )}
                                    onChange={(_, value) => {
                                      onChange(value);
                                      onSubStateChange(
                                        value,
                                        index,
                                        subMaterialIndex
                                      );
                                      return value;
                                    }}
                                  />
                                </>
                              )}
                            />
                            <Controller
                              name={`materiais[${index}][subMateriais][${subMaterialIndex}][qtd]`}
                              control={control}
                              render={({ field }) => (
                                <>
                                  <TextField
                                    placeholder="Qtd"
                                    color="primary"
                                    {...field}
                                    style={{
                                      width: "5%",
                                      marginLeft: "30px",
                                    }}
                                    variant="standard"
                                    disabled={
                                      !getValues(
                                        `materiais[${index}].subMateriais[${subMaterialIndex}].subMateriaisDropdown.descricao`
                                      )
                                    }
                                    InputProps={{
                                      disableUnderline: true,
                                      inputProps: { min: 0 },
                                    }}
                                    type="number"
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      onSubQtdChange(
                                        e.target.value,
                                        index,
                                        subMaterialIndex
                                      );
                                    }}
                                  />
                                </>
                              )}
                            />
                            <Controller
                              name={`materiais[${index}][subMateriais][${subMaterialIndex}][unidadesDropdown]`}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <Autocomplete
                                    fullWidth
                                    clearText="Limpar"
                                    openText="Abrir"
                                    closeText="Fechar"
                                    noOptionsText="Sem dados"
                                    className="unidadesId-form"
                                    options={unidades || []}
                                    value={
                                      value ||
                                      unidMedida[subMaterialIndex] ||
                                      null
                                    }
                                    getOptionLabel={(option) =>
                                      `${option.descricao}` || ""
                                    }
                                    isOptionEqualToValue={(option, value) => {
                                      // if (!value.id) return true;
                                      return value.id === option.id;
                                    }}
                                    filterOptions={(options, state) => {
                                      let newOptions = [];
                                      options.forEach((option) => {
                                        if (
                                          option.id > 0 &&
                                          option.descricao
                                            .toLowerCase()
                                            .includes(
                                              state.inputValue.toLowerCase()
                                            )
                                        )
                                          newOptions.push(option);
                                      });
                                      return newOptions;
                                    }}
                                    style={{
                                      maxWidth: "5%",
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Uni."
                                        InputProps={{
                                          ...params.InputProps,
                                          disableUnderline: true,
                                        }}
                                        variant="standard"
                                      />
                                    )}
                                    onChange={(_, value) => {
                                      onChange(value);
                                      return value;
                                    }}
                                  />
                                </>
                              )}
                            />
                            <Controller
                              name={`materiais[${index}][subMateriais][${subMaterialIndex}][dataInicio]`}
                              control={control}
                              render={({
                                field: { onChange, value },
                                fieldState: { error, invalid },
                              }) => (
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                  locale={ptLocale}
                                >
                                  <DatePicker
                                    invalidDateMessage="Data Inválida"
                                    inputFormat="dd/MM/yyyy"
                                    value={value}
                                    components={{
                                      OpenPickerIcon: calendarIcon,
                                    }}
                                    onChange={(value) => {
                                      onChange(value);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                          placeholder: "dd/mm/aaaa",
                                        }}
                                        InputProps={{
                                          ...params.InputProps,
                                          disableUnderline: true,
                                        }}
                                        variant="standard"
                                        size="small"
                                        sx={{
                                          width: "10%",
                                          marginLeft: "20px",
                                          paddingRight: "10px",
                                        }}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />

                            <Controller
                              name={`materiais[${index}][subMateriais][${subMaterialIndex}][dataFim]`}
                              control={control}
                              render={({
                                field: { onChange, value },
                                fieldState: { error, invalid },
                              }) => (
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                  locale={ptLocale}
                                >
                                  <DatePicker
                                    inputFormat="dd/MM/yyyy"
                                    invalidDateMessage="Data Inválida"
                                    value={value}
                                    components={{
                                      OpenPickerIcon: calendarIcon,
                                    }}
                                    onChange={(value) => {
                                      onChange(value);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                          placeholder: "dd/mm/aaaa",
                                        }}
                                        InputProps={{
                                          ...params.InputProps,
                                          disableUnderline: true,
                                        }}
                                        variant="standard"
                                        size="small"
                                        sx={{
                                          width: "10%",
                                          marginLeft: "20px",
                                          paddingRight: "10px",
                                        }}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />

                            <Controller
                              name={`materiais[${index}][subMateriais][${subMaterialIndex}][observacoes]`}
                              control={control}
                              render={({ field }) => (
                                <>
                                  <TextField
                                    placeholder="Observações"
                                    multiline
                                    color="primary"
                                    {...field}
                                    style={{
                                      width: "25%",
                                      paddingLeft: "30px",
                                    }}
                                    variant="standard"
                                    InputProps={{ disableUnderline: true }}
                                  />
                                </>
                              )}
                            />
                            <div
                              style={{
                                width: "10%",
                                display: "flex",
                                justifyContent: "space-around",
                                paddingLeft: "20px",
                              }}
                            >
                              <div>
                                {renderSwitch(
                                  getValues(
                                    `materiais[${index}].subMateriais[${subMaterialIndex}].estadodt`
                                  )
                                )}
                              </div>
                              <div>
                                {renderSwitch(
                                  getValues(
                                    `materiais[${index}].subMateriais[${subMaterialIndex}].estadodc`
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    )}
                </form>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default Materiais;
