/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import {
  TextField,
  Typography,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FaPlay, FaStop } from 'react-icons/fa';
import FeatherIcon from 'feather-icons-react';
import { Controller } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptLocale from 'date-fns/locale/pt';
import { format } from 'date-fns';

//Components
import HistoricoEsperas from './historicoEsperas';

//Axios
import Instance from '../../../../../../../utils/Axios';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "100%",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnStop: {
    color: "black",
    width: "100%",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#fff",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#f0d8df",
      border: "1px solid #bc1041",
    },
  },
});

const calendarIcon = () => {
  return <FeatherIcon icon="calendar" size={20} />;
};

const Esperas = ({
  intervencaoSelecionada,
  setIntervencaoSelecionada,
  fetchIntervencoes,
  control,
  getValues,
  resetForm,
  setResetForm,
  obraId,
  setObraId,
  reset,
  setValue,
  watch,

  //Errors
  ShowNotification,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [esperaswaiting, setEsperasWaiting] = useState(false);
  const [showHistorico, setShowHistorico] = useState(true);

  const messageIniciar = {
    isOpen: true,
    message: "Espera iniciada!",
    type: "success",
  };
  const messageParar = {
    isOpen: true,
    message: "Espera terminada. Verifique o histórico.",
    type: "success",
  };
  const errorMessage = {
    isOpen: true,
    message: "Ocorreu um erro!!",
    type: "error",
  };
  const verificarObservacoes = {
    isOpen: true,
    message: "Preencha as observações!!",
    type: "warning",
  };

  const intervencao = getValues();

  useEffect(() => {
    if (intervencao && intervencao.esperaAtiva) {
      if (
        intervencao.esperaAtiva.dataInicio == null &&
        intervencao.esperaAtiva.dataFim == null
      ) {
        setEsperasWaiting(false);
      } else if (
        intervencao.esperaAtiva.dataInicio != null &&
        intervencao.esperaAtiva.dataFim == null
      ) {
        setEsperasWaiting(true);
      }
    }
  }, [intervencao]);

  const verificarEspera = () => {
    const verifica = !!getValues(`esperaAtiva[observacoes]`);
    return verifica;
  };

  const handleIniciarEspera = () => {
    setEsperasWaiting(true);

    const intId = intervencaoSelecionada && intervencaoSelecionada.id;

    let formatedData = null;

    if (intervencao.esperaAtiva.dataInicio == null) {
      formatedData = format(new Date(), "yyyy-MM-dd");
      setValue(`esperaAtiva[dataInicio]`, format(new Date(), "yyyy-MM-dd"));
    } else {
      formatedData = format(
        new Date(intervencao.esperaAtiva.dataInicio),
        "yyyy-MM-dd"
      );
    }

    const ydata = [
      {
        dataInicio: formatedData,
        observacoes: intervencao.esperaAtiva.observacoes,
      },
    ];

    Instance()
      .put(`intervencoes/intervencoes/${intId}/iniciarEspera`, { ydata })
      .then(({ data }) => {
        dispatch(ShowNotification(messageIniciar));
        fetchIntervencoes();
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          if (err.response === 403) {
            dispatch(ShowNotification(errorMessage));
          }
        }
      });
  };

  const handleFinalizarEspera = () => {
    setEsperasWaiting(false);

    const intId = intervencaoSelecionada && intervencaoSelecionada.id;

    let formatedData = null;

    if (intervencao.esperaAtiva.dataFim == null) {
      formatedData = format(new Date(), "yyyy-MM-dd");
      setValue(`esperaAtiva[dataFim]`, format(new Date(), "yyyy-MM-dd"));
    } else {
      formatedData = format(
        new Date(intervencao.esperaAtiva.dataFim),
        "yyyy-MM-dd"
      );
    }

    const observacoes = intervencao.esperaAtiva.observacoes;

    const ydata = [
      {
        dataFim: formatedData,
        observacoes: observacoes,
      },
    ];

    console.log(ydata);

    Instance()
      .put(`intervencoes/intervencoes/${intId}/finalizarEspera`, { ydata })
      .then(({ data }) => {
        dispatch(ShowNotification(messageParar));
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          if (err.response === 403) {
            dispatch(ShowNotification(errorMessage));
          }
        }
      });

    //Inserção no histórico

    handleAddSub(formatedData, observacoes);
  };

  const handleAddSub = (formatedData, observacoes) => {
    setResetForm(true);

    const novaEspera = {
      dataFim: formatedData,
      dataInicio: intervencao.esperaAtiva.dataInicio,
      observacoes: observacoes,
    };

    intervencaoSelecionada.esperas.push(novaEspera);
    setIntervencaoSelecionada({ ...intervencaoSelecionada });
    setResetForm(false);

    setValue(`esperaAtiva[dataInicio]`, null);
    setValue(`esperaAtiva[dataFim]`, null);
    setValue(`esperaAtiva[observacoes]`, "");
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <h4
          style={{ color: "#E21450" }}
          className="text-underline-title-drawer"
        >
          Pedidos de Esclarecimento
        </h4>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ width: "15%", marginBottom: "16px" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Data de Inicio
            </Typography>
            {/* <TextField
              size='small'
              value={
                (intervencaoSelecionada &&
                  intervencaoSelecionada.dataCriacao) ||
                ' --/--/---- '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            /> */}

            <Controller
              name={`esperaAtiva[dataInicio]`}
              control={control}
              render={({ field }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <DatePicker
                    invalidDateMessage="Data Inválida"
                    {...field}
                    minDate={new Date(intervencao.pedidoData)}
                    components={{
                      OpenPickerIcon: calendarIcon,
                    }}
                    disabled
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        // InputProps={{
                        //   ...params.InputProps,
                        //   disableUnderline: true,
                        // }}
                        // variant='standard'
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </div>
          <div style={{ width: "15%", marginBottom: "16px" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Data de Fim
            </Typography>
            <Controller
              name={`esperaAtiva[dataFim]`}
              control={control}
              render={({ field }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <DatePicker
                    invalidDateMessage="Data Inválida"
                    {...field}
                    components={{
                      OpenPickerIcon: calendarIcon,
                    }}
                    disabled
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        // InputProps={{
                        //   ...params.InputProps,
                        //   disableUnderline: true,
                        // }}
                        // variant='standard'
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </div>
          <div style={{ width: "49%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Observações
            </Typography>
            <Controller
              name={`esperaAtiva[observacoes]`}
              control={control}
              render={({ field }) => (
                <>
                  <TextField
                    color="primary"
                    placeholder="Observações"
                    size="small"
                    {...field}
                    style={{ width: "100%" }}
                  />
                </>
              )}
            />
          </div>
          <div
            style={{
              width: "15%",
              display: "flex",
              alignItems: "center",
              marginTop: "18px",
            }}
          >
            {esperaswaiting == false ? (
              <Button
                className={classes.btnGuardar}
                onClick={() => {
                  if (verificarEspera()) {
                    handleIniciarEspera();
                  } else {
                    dispatch(ShowNotification(verificarObservacoes));
                  }
                }}
              >
                <FaPlay style={{ marginRight: "10px" }} />
                Começar
              </Button>
            ) : (
              <Button
                className={classes.btnStop}
                onClick={() => handleFinalizarEspera()}
              >
                <FaStop style={{ marginRight: "10px" }} />
                Parar
              </Button>
            )}
          </div>
          {/* <div
            style={{
              width: '40px',
              height: '40px',
              marginTop: '33px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Tooltip title={'Mostrar histórico'}>
              <IconButton
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={() => setShowHistorico(!showHistorico)}
                color='inherit'
              >
                <FeatherIcon
                  icon='rotate-cw'
                  size={20}
                  // style={
                  //   item.selected === 0
                  //     ? { color: '#E21450', strokeWidth: '1px' }
                  //     : { color: '#333', strokeWidth: '1px' }
                  // }
                />
              </IconButton>
            </Tooltip>
          </div> */}
        </div>
        <div style={{ display: "flex" }}>
          {showHistorico && (
            <HistoricoEsperas
              intervencaoSelecionada={intervencaoSelecionada}
              setIntervencaoSelecionada={setIntervencaoSelecionada}
              control={control}
              getValues={getValues}
              resetForm={resetForm}
              setResetForm={setResetForm}
              obraId={obraId}
              setObraId={setObraId}
              reset={reset}
              watch={watch}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Esperas;
