import Instance from "../../../../Axios";

// New Actions for the app

const AddWorkPermitAccountableById =
  (ydata, toggleDrawer, ShowNotification, message, errorMessage) =>
  async (dispatch) => {
    await Instance()
      .post(`/construcoes/workpermits/accountable`, { ydata })
      .then(({ data }) => {
        // console.log(data);
        dispatch({
          type: "ADD_WORK_PERMIT_ACCOUNTABLE_ID",
          payload: data.yData[0],
        });
        toggleDrawer("right", false);
        dispatch(ShowNotification(message));
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default AddWorkPermitAccountableById;
