import Instance from "../../../../Axios";

// New Actions for the app

const GetCredFormCertificadoUploaded = (formId) => async (dispatch) => {
  await Instance()
    .get(`/credenciacoes/certificado/${formId}`)
    .then(({ data }) => {
      dispatch({
        type: "GET_CRED_CERTIFICADO_UPLOADED",
        payload: data.yData[0],
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetCredFormCertificadoUploaded;