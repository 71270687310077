/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  IconButton,
  Paper,
  Tab,
  TextareaAutosize,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ptLocale from "date-fns/locale/pt";
import moment from "moment";
import EditPlusAdesao from "../../../../../utils/redux/actions/plus/EditPlusAdesaoAction";
import ShowNotification from "../../../../../utils/redux/actions/global/notifications/NotificationAction";
import EmitirContratoById from "../../../../../utils/redux/actions/plus/EmitirContratoByIdAction";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import GetAdesaoById from "../../../../../utils/redux/actions/plus/GetAdesaoById";
import EditClientById from "../../../../../utils/redux/actions/plus/EditClientByIdAction";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "auto",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnUpload: {
    color: "#E21450",
    // width: '125px',
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  blurredText: {
    color: "transparent", // Make the text transparent
    textShadow: "0 0 10px #000", // Apply a text shadow to create the blur effect
    userSelect: "none", // Prevent text selection
  },
  disabledTextField: {
    opacity: 0.7,
    pointerEvents: "none",
    userSelect: "none", // Adjust opacity to indicate the disabled state
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "transparent",
    },
  },
  textField: {
    userSelect: "none",
  },
  tab: {
    fontWeight: "bold",
    fontSize: "22px",
    marginBottom: "0px",
    textTransform: "capitalize",
  },
});

const FormCliente = ({
  toggleDrawer,
  drawerState,
  drawerWidth,
  dimensions,
  fetchClientes,
  cliente,
  setCliente,
  tiposDocs,
  servico,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [clienteData, setClienteData] = useState();
  const [clienteLastUpdateData, setClienteLastUpdateData] = useState();
  const [clienteValiDoc, setClienteValiDoc] = useState();

  const user = useSelector((state) => state.user);

  const documentos = useSelector((state) => state.plus.adesaoFiles);

  // ############################################################## //
  // ####################  Cliente Selecionado  ################### //
  // ############################################################## //

  const [tipoDoc, setTipoDoc] = useState({ id: 0, name: "" });

  const clienteSelecionado = useSelector(
    (state) => state && state.plus && state.plus.cliente
  );

  const [personalInfo, setPersonalInfo] = useState({
    nome: "",
    telefone: "",
    telemovel: "",
    email: "",
  });

  const [docsInfo, setDocsInfo] = useState({
    nif: "",
    numero_documento: "",
    certidao_permanente: "",
    swift: "",
    iban: "",
  });

  const [addressInfo, setAddressInfo] = useState({
    morada: "",
    cod_postal: "",
    localidade: "",
    concelho: "",
    distrito: "",
  });

  const [companyInfo, setCompanyInfo] = useState({
    nome_empresa: "",
    nipc: "",
    cae: "",
    rep_legal: "",
  });

  useEffect(() => {
    if (clienteSelecionado) {
      setPersonalInfo({
        nome: clienteSelecionado.jaf_plus_client_profile_name,
        telemovel: clienteSelecionado.jaf_plus_client_profile_contact_mobile,
        telefone: clienteSelecionado.jaf_plus_client_profile_contact_landline,
        email: clienteSelecionado.jaf_plus_client_profile_email,
      });
      setDocsInfo({
        nif: clienteSelecionado.jaf_plus_client_profile_nif,
        numero_documento: clienteSelecionado.jaf_plus_client_profile_cc,
        certidao_permanente:
          clienteSelecionado.jaf_plus_client_profile_company_p_cert_code,
        swift: clienteSelecionado.jaf_plus_client_profile_swift,
        iban: clienteSelecionado.jaf_plus_client_profile_iban,
      });
      setAddressInfo({
        morada: clienteSelecionado.jaf_plus_client_profile_address,
        cod_postal: clienteSelecionado.jaf_plus_client_profile_postal_code,
        localidade: clienteSelecionado.jaf_plus_client_profile_location,
        concelho: clienteSelecionado.jaf_plus_client_profile_county,
        distrito: clienteSelecionado.jaf_plus_client_profile_district,
      });
      setCompanyInfo({
        nipc: clienteSelecionado.jaf_plus_client_profile_company_nipc,
        cae: clienteSelecionado.jaf_plus_client_profile_company_cae,
        nome_empresa: clienteSelecionado.jaf_plus_client_profile_company_name,
        rep_legal:
          clienteSelecionado.jaf_plus_client_profile_company_legal_name,
      });
      setClienteData(
        moment(clienteSelecionado.jaf_plus_client_profile_created_at).format(
          "DD-MM-YYYY HH:mm:ss"
        )
      );
      setClienteLastUpdateData(
        moment(
          clienteSelecionado.jaf_plus_client_profile_updated_at
            ? clienteSelecionado.jaf_plus_client_profile_updated_at
            : clienteSelecionado.jaf_plus_client_profile_created_at
        ).format("DD-MM-YYYY HH:mm:ss")
      );
      setTipoDoc({
        id: clienteSelecionado.id_jaf_plus_doc_type,
        name:
          clienteSelecionado.tipo_documento == null
            ? "N/D"
            : clienteSelecionado.tipo_documento,
      });
    }
  }, [clienteSelecionado]);

  // ############################################################## //
  // ######################  Filtered arrays ###################### //
  // ############################################################## //

  // const filteredTarifas = tiposTarifas.filter((t) => t.plan == tarifPlan.id);
  // const filteredPotencias = potencias.filter((p) => p.type == tarifa.id);
  // const filteredCiclos = ciclos.filter((c) => c.type == tarifPlan.id);
  // const filteredEscaloes = escaloes.filter((e) => e.plan == tarifPlan.id);
  // const filteredStatus = status.filter(
  //   (s) => s.servId == adesaoSelecionada.id_jaf_plus_service_type
  // );

  // ############################################################## //
  // ###############  Handles Change and Submit  ################## //
  // ############################################################## //

  const handleClienteData = (date) => {
    setClienteData(date);
  };

  const handleClienteValiDoc = (date) => {
    setClienteValiDoc(date);
  };

  const handlePersonalInfoChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleCompanyInfoChange = (e) => {
    const { name, value } = e.target;
    setCompanyInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleDocsInfoChange = (e) => {
    const { name, value } = e.target;
    setDocsInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddressInfoChange = (e) => {
    const { name, value } = e.target;
    setAddressInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        clientTypeId: clienteSelecionado.id_jaf_plus_client_type,
        nome: personalInfo.nome,
        telemovel: personalInfo.telemovel,
        telefone: personalInfo.telefone,
        email: personalInfo.email,
        email_fatura: personalInfo.email_fatura,
        nif: docsInfo.nif,
        tipo_documento: tipoDoc.id,
        numero_documento: docsInfo.numero_documento,
        certidao_permanente: docsInfo.certidao_permanente,
        swift: docsInfo.swift,
        iban: docsInfo.iban,
        morada: addressInfo.morada,
        cod_postal: addressInfo.cod_postal,
        localidade: addressInfo.localidade,
        concelho: addressInfo.concelho,
        distrito: addressInfo.distrito,
        data: moment(clienteData).format("YYYY-MM-DD"),
        nipc: companyInfo.nipc,
        cae: companyInfo.cae,
        nome_empresa: companyInfo.nome_empresa,
        rep_legal: companyInfo.rep_legal,
      },
    ];

    console.log(ydata);

    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!",
      type: "error",
    };
    const message = {
      isOpen: true,
      message: "Adesão editada com sucesso!!",
      type: "success",
    };

    dispatch(
      EditClientById(ydata, clienteSelecionado.id_jaf_plus_client_profile)
    )
      .then(() => {
        toggleDrawer("right", false);
        dispatch(ShowNotification(message));
        fetchClientes(); // Ensure fetchAdesoes is called here
      })
      .catch((error) => {
        console.error("Error updating adesao:", error); // Handle error
      });
  };

  return (
    <div
      className={classes.list}
      style={
        dimensions.width <= 1100
          ? { width: "60vw" }
          : { width: `${drawerWidth}` }
      }
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 5,
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => toggleDrawer("right", false)}
        >
          <div>
            <FeatherIcon
              icon="chevron-left"
              size={20}
              style={{ strokeWidth: "1.5" }}
            />
          </div>
          <div className="text-underline-close-drawer">Adesões</div>
        </Box>
        {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box> */}
      </Box>

      <Card
        sx={{
          mx: 5,
          px: 2,
          boxShadow: "0px 10px 15px lightgrey",
        }}
      >
        <Box
          sx={{
            my: 2,
            mx: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4
            style={{
              fontSize: "22px",
              marginBottom: "0px",
            }}
            className="text-underline"
          >
            Cliente
          </h4>
          {user.permissoes.includes("APP_JAFPLUS_EDITAR_CLIENTES") && (
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Button
                variant="contained"
                className={classes.btnFiltrar}
                onClick={handleSubmit}
              >
                Guardar
              </Button>
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              m: 1,
              width: "48%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Data de submissão
              </Typography>
              <TextField
                disabled
                size="small"
                variant="outlined"
                value={clienteData}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
            <Box sx={{ width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Data da última atualização
              </Typography>
              <TextField
                disabled
                size="small"
                variant="outlined"
                value={clienteLastUpdateData}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            mx: 1,
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              marginBottom: "10px",
              fontSize: "20px",
            }}
          >
            Dados Pessoais
          </Typography>
        </Box>
        {clienteSelecionado &&
          clienteSelecionado.id_jaf_plus_client_type == 1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Nome
                </Typography>
                <TextField
                  name="nome"
                  size="small"
                  variant="outlined"
                  value={personalInfo.nome}
                  onChange={handlePersonalInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
          )}
        {clienteSelecionado &&
          clienteSelecionado.id_jaf_plus_client_type == 2 && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Nome da empresa
                  </Typography>
                  <TextField
                    name="nome_empresa"
                    size="small"
                    variant="outlined"
                    value={companyInfo.nome_empresa}
                    onChange={handleCompanyInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Certidão Permanente
                  </Typography>
                  <TextField
                    name="certidao_permanente"
                    size="small"
                    variant="outlined"
                    value={docsInfo.certidao_permanente}
                    onChange={handleDocsInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    NIPC
                  </Typography>
                  <TextField
                    name="nipc"
                    size="small"
                    variant="outlined"
                    value={companyInfo.nipc}
                    onChange={handleCompanyInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    CAE
                  </Typography>
                  <TextField
                    name="cae"
                    size="small"
                    variant="outlined"
                    value={companyInfo.cae}
                    onChange={handleCompanyInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Representante Legal
                </Typography>
                <TextField
                  name="rep_legal"
                  size="small"
                  variant="outlined"
                  value={companyInfo.rep_legal}
                  onChange={handleCompanyInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </>
          )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Telefone
            </Typography>
            <TextField
              name="telefone"
              size="small"
              variant="outlined"
              value={personalInfo.telefone}
              onChange={handlePersonalInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Telemóvel
            </Typography>
            <TextField
              name="telemovel"
              size="small"
              variant="outlined"
              value={personalInfo.telemovel}
              onChange={handlePersonalInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Tipo Documento
            </Typography>
            <Autocomplete
              fullWidth
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              options={tiposDocs || []}
              value={tipoDoc}
              getOptionLabel={(option) => `${option.name}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setTipoDoc(null);
                  return;
                } else {
                  setTipoDoc(value);
                  return value;
                }
              }}
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Nº Doc. Identificação
            </Typography>
            <TextField
              name="numero_documento"
              size="small"
              variant="outlined"
              value={docsInfo.numero_documento}
              onChange={handleDocsInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Email
            </Typography>
            <TextField
              name="email"
              size="small"
              variant="outlined"
              value={personalInfo.email}
              onChange={handlePersonalInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              NIF
            </Typography>
            <TextField
              name="nif"
              size="small"
              variant="outlined"
              value={docsInfo.nif}
              onChange={handleDocsInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              SWIFT
            </Typography>
            <TextField
              name="swift"
              size="small"
              variant="outlined"
              value={docsInfo.swift}
              onChange={handleDocsInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              IBAN
            </Typography>
            <TextField
              name="iban"
              size="small"
              variant="outlined"
              value={docsInfo.iban}
              onChange={handleDocsInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Morada
            </Typography>
            <TextField
              name="morada"
              size="small"
              variant="outlined"
              value={addressInfo.morada}
              onChange={handleAddressInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Cód-Postal
            </Typography>
            <TextField
              name="cod_postal"
              size="small"
              variant="outlined"
              value={addressInfo.cod_postal}
              onChange={handleAddressInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Localidade
            </Typography>
            <TextField
              name="localidade"
              size="small"
              variant="outlined"
              value={addressInfo.localidade}
              onChange={handleAddressInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Concelho
            </Typography>
            <TextField
              name="concelho"
              size="small"
              variant="outlined"
              value={addressInfo.concelho}
              onChange={handleAddressInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Distrito
            </Typography>
            <TextField
              name="distrito"
              size="small"
              variant="outlined"
              value={addressInfo.distrito}
              onChange={handleAddressInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            mx: 1,
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Documentos
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          {documentos.map((d) => (
            <Box sx={{ m: 1, display: "flex", alignItems: "center" }}>
              <Typography>{d.name}</Typography>
              <Button
                sx={{
                  height: "40px",
                  borderRadius: "5px",
                  mx: 2,
                }}
                className={classes.btnUpload}
                onClick={() => {
                  // Define the file URL stored in your system
                  const fileUrl = `https://jafplus.pt/wp-content${d.path}`; // Replace with your actual file URL

                  // Create a temporary anchor element
                  const link = document.createElement("a");
                  link.href = fileUrl;

                  // Set the target attribute to open in a new window
                  link.setAttribute("target", "_blank");

                  // Append the link to the document body
                  document.body.appendChild(link);

                  // Programmatically click the link to open the file in a new window/tab
                  link.click();

                  // Remove the link after the operation
                  document.body.removeChild(link);
                }}
              >
                <FeatherIcon icon="download" size={20} />
              </Button>
            </Box>
          ))}
        </Box> */}
        {user.permissoes.includes("APP_JAFPLUS_EDITAR_CLIENTES") && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", m: 1 }}>
            <Button
              variant="contained"
              className={classes.btnFiltrar}
              onClick={handleSubmit}
            >
              Guardar
            </Button>
          </Box>
        )}
        {/* <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Fatura Eletricidade
            </Typography>
            <TextField
              name="ynb_adesao_fatura_eleticidade_file"
              size="small"
              variant="outlined"
              value={adesao.ynb_adesao_fatura_eleticidade_file}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Comprovativo IBAN
            </Typography>
            <TextField
              name="ynb_adesao_comprovativo_iban_file"
              size="small"
              variant="outlined"
              value={adesao.ynb_adesao_comprovativo_iban_file}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box> */}
      </Card>
    </div>
  );
};

export default FormCliente;
