import Instance from "../../../../Axios";

// New Actions for the app

const DeleteCredFuncionarioFormacao = (id) => async (dispatch) => {
  await Instance()
    .put(`/credenciacoes/funcionarios/formacaoDelete/${id}`)
    .then(({ data }) => {
      console.log(data);
      dispatch({
        type: "DELETE_CRED_FUNCIONARIO_FORMACAO",
        payload: data.yData[0],
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default DeleteCredFuncionarioFormacao;
