import React from 'react';
import { Typography } from '@mui/material';

//Components
import MultipleFileUploader from '../components/MultipleFileUploader';
import ImageUploader from '../components/MultipleImageUploader';

const Files = ({
  intervencaoSelecionada,
  classes,
  //imagens,
  images,
  setImages,
  newImages,
  setNewImages,
  removeImages,
  setRemoveImages,
  //documentos
  files,
  setFiles,
  newFiles,
  setNewFiles,
  removeFiles,
  setRemoveFiles,
  //Errors
  message,
  errorMessage,
  fileWarning,
  ShowNotification,
}) => {
  return (
    <>
      <div style={{ width: '100%' }}>
        <h4
          style={{ color: '#E21450' }}
          className='text-underline-title-drawer '
        >
          Documentos
        </h4>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ width: '528px' }}>
            <>
              <Typography style={{ fontWeight: 'bold' }}>
                Proposta(s)
              </Typography>
              <MultipleFileUploader
                classes={classes}
                files={files}
                setFiles={setFiles}
                intervencaoSelecionada={intervencaoSelecionada}
                newFiles={newFiles}
                setNewFiles={setNewFiles}
                removeFiles={removeFiles}
                setRemoveFiles={setRemoveFiles}
                //Errors
                message={message}
                errorMessage={errorMessage}
                fileWarning={fileWarning}
                ShowNotification={ShowNotification}
              />
            </>
          </div>
          <span style={{ width: '24px' }} />
          <div style={{ width: '528px' }}>
            <>
              <Typography style={{ fontWeight: 'bold' }}>Anexos</Typography>
              <ImageUploader
                classes={classes}
                images={images}
                setImages={setImages}
                intervencaoSelecionada={intervencaoSelecionada}
                newImages={newImages}
                setNewImages={setNewImages}
                removeImages={removeImages}
                setRemoveImages={setRemoveImages}
                //Errors
                message={message}
                errorMessage={errorMessage}
                fileWarning={fileWarning}
                ShowNotification={ShowNotification}
              />
            </>
          </div>
        </div>

        <div style={{ display: 'flex' }}></div>
      </div>
    </>
  );
};

export default Files;
