/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const GraficoEstadoPropostasParceiro = (estadoPropostasParceiro) => {
  // const estadoPropostasParceiro = useSelector(
  //   (state) => state.energiasDashboard.estadoPropostasParceiro
  // );

  const propostas =
    estadoPropostasParceiro && estadoPropostasParceiro.estadoPropostasParceiro;

  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: "easein",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        text: "Estado das Propostas por Parceiro",
        align: "center",
        style: {
          fontFamily: "Lato",
        },
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
          rotate: -65,
          rotateAlways: true,
        },
      },
      yaxis: {
        show: false,
      },
      grid: {
        show: true,
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
      colors: ["#FFA65D", "#4FC0E8", "#8CC051", "#FF4A4A"],
    },
  });

  useEffect(() => {
    setState({
      series: [
        {
          name: "Por resolver",
          data: propostas.map((item) => item.estadoPorResolver),
        },
        {
          name: "Resolvida",
          data: propostas.map((item) => item.estadoResolvido),
        },
        {
          name: "Adjudicada",
          data: propostas.map((item) => item.estadoAdjudicado),
        },
        {
          name: "Fechada",
          data: propostas.map((item) => item.estadoFechado),
        },
      ],
      options: {
        xaxis: {
          categories: propostas.map((item) => item.nome),
        },
      },
    });
  }, [propostas]);

  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={300}
        width={900}
      />
    </>
  );
};

export default GraficoEstadoPropostasParceiro;
