/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CreateToken from "../../../../../utils/redux/actions/utilizadores/CreateTokenAction";
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  Tab,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import LoadingButton from "@mui/lab/LoadingButton";
import { withStyles } from "@mui/styles";

//Icons
import SaveIcon from "@mui/icons-material/Save";
import FeatherIcon from "feather-icons-react";

//Actions
import ShowNotification from "../../../../../utils/redux/actions/global/notifications/NotificationAction";
import EditarUtilizador from "../../../../../utils/redux/actions/utilizadores/editarUtilizadorAction";

//Components
import Spinner from "../../../../../Global/Components/Spinner";
// import GetDepartamentos from "../../../../../utils/redux/actions/utilizadores/GetDepartamentosAction";

const CustomCheckbox = withStyles({
  root: {
    color: "#707070",
    "&$checked": {
      color: "#707070",
    },
    background: "transparent !important",
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const FormEdit = ({
  toggleDrawer,
  fetchUtilizadores,
  utilizador,
  setUtilizador,
  utilizadorId,
  utilizadorInfo,
  perfis,
  parceiros,
  diretoresObra,
  classes,
  aeroportos,
  guardarLoading,
  setGuardarLoading,
  // React hook form
  control,
  handleSubmitForm,
  errors,
  reset,
  getValues,
  watch,
  setValue,
  trigger,
}) => {
  const dispatch = useDispatch();

  const [perfil, setPerfil] = useState();
  // console.log(perfil);

  const user = useSelector((state) => state.user);

  // FETCH DEPARTAMENTOS

  // const change = watch("aeroportosDropdown");

  // const [old, setOld] = useState(
  //   utilizadorInfo &&
  //     utilizadorInfo.aeroportosDropdown &&
  //     utilizadorInfo.aeroportosDropdown.id
  // );

  // useEffect(() => {
  //   const aeroId = change && change.id != null ? change.id : null;

  //   if (old) {
  //     dispatch(GetDepartamentos(aeroId))
  //       .then(() => setOld(aeroId))
  //       .then(() => {
  //         if (old != null && old !== aeroId) {
  //           trigger("departamentosDropdown");
  //           setValue("departamentosDropdown", "");
  //         }
  //       });
  //   } else {
  //     setOld(aeroId);
  //   }
  // }, [change && change.id, old]);

  const departamentos = useSelector(
    (state) => state.utilizadores.departamentos
  );

  //PERMISSOES

  const handleCreateToken = () => {
    dispatch(CreateToken(utilizadorId));
  };

  const handleChangePermissoes = (permissao, grupo) => {
    const hookUser = getValues();
    const grupoId = hookUser.permissoes.findIndex((p) => p.grupo === grupo);

    const permissaoSelecionada = hookUser.permissoes
      .find((p) => p.grupo === grupo)
      // eslint-disable-next-line eqeqeq
      .lista.findIndex((p) => p.id == permissao.id);

    hookUser.permissoes[grupoId].lista[permissaoSelecionada].permissao =
      !hookUser.permissoes[grupoId].lista[permissaoSelecionada].permissao;
    dispatch({
      type: "GET_UTILIZADOR",
      payload: { ...hookUser },
    });
  };

  useEffect(() => {
    utilizadorInfo &&
      setUtilizador({
        email: utilizadorInfo.email,
        nome: utilizadorInfo.nome,
        perfisId: utilizadorInfo.perfisId,
        perfilDropdown: utilizadorInfo.perfilDropdown,
        aeroportoId: utilizadorInfo.aeroportosId,
        aeroportosDropdown: utilizadorInfo.aeroportosDropdown,
        /* Isto eu adicionei */ departamentosDropdown:
          utilizadorInfo.departamentosDropdown,
        energParceirosId: utilizadorInfo.energParceirosId,
        permissoes: utilizadorInfo.permissoes,
        auth_utilizadores_url_key: utilizadorInfo.auth_utilizadores_url_key,
      });
    setPerfil(utilizadorInfo.perfisId);
  }, [utilizadorInfo]);

  useEffect(() => {
    if (utilizadorInfo) {
      reset({ ...utilizadorInfo });
    }
  }, [utilizadorInfo]);

  const onSubmit = (data, e) => {
    const aeroportosId = () => {
      if (
        // data.aeroportosDropdown === 1 ||
        data.aeroportosDropdown === 0 ||
        data.aeroportosDropdown == null
      ) {
        return null;
      } else {
        return data.aeroportosDropdown.id;
      }
    };

    const ydata = [
      {
        nome: data.nome,
        email: data.email,
        perfilId: data.perfilDropdown && data.perfilDropdown.id,
        aeroportosId: aeroportosId(),
        departamentosId:
          data.departamentosDropdown && data.departamentosDropdown.id !== 0
            ? data.departamentosDropdown.id
            : null,
        energParceirosId:
          data.parceirosDropdown && data.parceirosDropdown.id !== 0
            ? data.parceirosDropdown.id
            : null,
        diretorId:
          data.diretoresDropdown && data.diretoresDropdown.id !== 0
            ? data.diretoresDropdown.id
            : null,
        permissoes: utilizador.permissoes
          .map((p) => p.lista)
          .flat()
          .map((permissao) => {
            if (permissao.permissao) {
              return permissao.id;
            }
            return null;
          })
          .filter((permissao) => {
            return permissao != null;
          }),
      },
    ];

    const message = {
      isOpen: true,
      message: "Utilizador editado com sucesso!!",
      type: "success",
    };
    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!!",
      type: "error",
    };

    // console.log("ydata", ydata);

    setGuardarLoading(true);

    dispatch(
      EditarUtilizador(
        ydata,
        utilizadorId,
        ShowNotification,
        message,
        errorMessage,
        toggleDrawer
      )
    )
      .then(() => fetchUtilizadores())
      .then(() => setGuardarLoading(false));
  };

  // ############################################################## //
  // ###########################  Tabs  ########################### //
  // ############################################################## //

  const [valueTab, setValueTab] = useState(0);

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  // const filterOptions = createFilterOptions({
  //   // matchFrom: 'start',
  //   stringify: (option) => option.id > 0, // make it one for it
  // })

  // console.log(utilizador);

  return (
    <>
      {utilizador ? (
        <div
          style={{
            isplay: "flex",
            flexDirection: "column",
            padding: "40px",
            height: "100%",
          }}
        >
          <form onSubmit={handleSubmitForm(onSubmit)}>
            <div className={classes.drawerHeader}>
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => toggleDrawer("right", false)}
              >
                <div>
                  <FeatherIcon
                    icon="chevron-left"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </div>
                <div className="text-underline-close-drawer">
                  Lista de Utilizadores
                </div>
              </div>

              {!guardarLoading ? (
                <Button className={classes.btnGuardar} type="submit">
                  Guardar
                </Button>
              ) : (
                <LoadingButton
                  color="secondary"
                  loading={guardarLoading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  type="submit"
                  className={classes.btnGuardar}
                >
                  Guardar
                </LoadingButton>
              )}
            </div>
            <Paper sx={{ px: 6, py: 2, mt: 4 }}>
              <h4
                style={{
                  fontSize: "22px",
                  width: "100%",
                  marginBottom: "0px",
                }}
                className="text-underline"
              >
                Dados do utilizador
              </h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <h4
                  style={{ color: "#E21450", width: "100%" }}
                  className="text-underline-title-drawer "
                >
                  Informações pessoais
                </h4>
                <Controller
                  name="nome"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        label="Nome"
                        color="primary"
                        variant="outlined"
                        size="small"
                        {...field}
                        fullWidth
                        style={{ marginTop: "10px", width: "49%" }}
                        error={!!errors.nome}
                        helperText={errors.nome ? errors.nome?.message : ""}
                      />
                    </>
                  )}
                />
                <span style={{ width: "2%" }} />
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        label="Email"
                        color="primary"
                        variant="outlined"
                        size="small"
                        {...field}
                        fullWidth
                        style={{ marginTop: "10px", width: "49%" }}
                        error={!!errors.email}
                        helperText={errors.email ? errors.email?.message : ""}
                      />
                    </>
                  )}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <Controller
                  name="perfilDropdown"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Autocomplete
                        clearText="Limpar"
                        openText="Abrir"
                        closeText="Fechar"
                        noOptionsText="Sem dados"
                        options={perfis || []}
                        value={value || null}
                        getOptionLabel={(option) => `${option.descricao}` || ""}
                        isOptionEqualToValue={(option, value) => {
                          // if (!value.id) return true;
                          return value.id === option.id;
                        }}
                        style={{ marginTop: "10px", width: "49%" }}
                        filterOptions={(options, state) => {
                          let newOptions = [];
                          options.forEach((option) => {
                            if (
                              option.id > 0 &&
                              option.descricao
                                .toLowerCase()
                                .includes(state.inputValue.toLowerCase())
                            )
                              newOptions.push(option);
                          });
                          return newOptions;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Perfil"
                            placeholder="Perfil"
                            variant="outlined"
                            error={!!errors.perfilDropdown}
                            helperText={
                              errors.perfilDropdown
                                ? errors.perfilDropdown?.message
                                : ""
                            }
                          />
                        )}
                        onChange={(_, value) => {
                          setPerfil(value.id);
                          onChange(value);
                          return value;
                        }}
                      />
                    </>
                  )}
                />
                <span style={{ width: "2%" }} />
                {perfil == 5 ? (
                  <Controller
                    name="parceirosDropdown"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Autocomplete
                          fullWidth
                          clearText="Limpar"
                          openText="Abrir"
                          closeText="Fechar"
                          noOptionsText="Sem dados"
                          options={parceiros || []}
                          value={value || null}
                          getOptionLabel={(option) => `${option.nome}` || ""}
                          isOptionEqualToValue={(option, value) => {
                            // if (!value.id) return true;
                            return value.id === option.id;
                          }}
                          style={{ marginTop: "10px", width: "49%" }}
                          filterOptions={(options, state) => {
                            let newOptions = [];
                            options.forEach((option) => {
                              if (
                                option.id > 0 &&
                                option.nome
                                  .toLowerCase()
                                  .includes(state.inputValue.toLowerCase())
                              )
                                newOptions.push(option);
                            });
                            return newOptions;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              label="Parceiro"
                              placeholder="Parceiro"
                              variant="outlined"
                              error={!!errors.departamentosDropdown}
                              helperText={
                                errors.departamentosDropdown
                                  ? errors.departamentosDropdown?.message
                                  : ""
                              }
                            />
                          )}
                          onChange={(_, value) => {
                            onChange(value);
                            return value;
                          }}
                        />
                      </>
                    )}
                  />
                ) : perfil !== null ? (
                  <>
                    <Controller
                      name="aeroportosDropdown"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Autocomplete
                            fullWidth
                            clearText="Limpar"
                            openText="Abrir"
                            closeText="Fechar"
                            noOptionsText="Sem dados"
                            options={aeroportos || []}
                            value={value || null}
                            getOptionLabel={(option) =>
                              `${option.descricao}` || ""
                            }
                            isOptionEqualToValue={(option, value) => {
                              // if (!value.id) return true;
                              return value.id === option.id;
                            }}
                            style={{
                              marginTop: "10px",
                              width: "23%",
                              marginRight: "2%",
                            }}
                            filterOptions={(options, state) => {
                              let newOptions = [];
                              options.forEach((option) => {
                                if (
                                  option.id > 0 &&
                                  option.descricao
                                    .toLowerCase()
                                    .includes(state.inputValue.toLowerCase())
                                )
                                  newOptions.push(option);
                              });
                              return newOptions;
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                label="Aeroporto"
                                placeholder="Aeroporto"
                                variant="outlined"
                                error={!!errors.aeroportosDropdown}
                                helperText={
                                  errors.aeroportosDropdown
                                    ? errors.aeroportosDropdown?.message
                                    : ""
                                }
                              />
                            )}
                            onChange={(_, value) => {
                              onChange(value);
                              return value;
                            }}
                          />
                        </>
                      )}
                    />
                    <Controller
                      name="departamentosDropdown"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Autocomplete
                            fullWidth
                            clearText="Limpar"
                            openText="Abrir"
                            closeText="Fechar"
                            noOptionsText="Sem dados"
                            options={departamentos || []}
                            value={value || null}
                            getOptionLabel={(option) =>
                              `${option.descricao}` || ""
                            }
                            isOptionEqualToValue={(option, value) => {
                              // if (!value.id) return true;
                              return value.id === option.id;
                            }}
                            style={{ marginTop: "10px", width: "24%" }}
                            filterOptions={(options, state) => {
                              let newOptions = [];
                              options.forEach((option) => {
                                if (
                                  option.id > 0 &&
                                  option.descricao
                                    .toLowerCase()
                                    .includes(state.inputValue.toLowerCase())
                                )
                                  newOptions.push(option);
                              });
                              return newOptions;
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                label="Departamento"
                                placeholder="Departamento"
                                variant="outlined"
                                error={!!errors.departamentosDropdown}
                                helperText={
                                  errors.departamentosDropdown
                                    ? errors.departamentosDropdown?.message
                                    : ""
                                }
                              />
                            )}
                            onChange={(_, value) => {
                              onChange(value);
                              return value;
                            }}
                          />
                        </>
                      )}
                    />
                  </>
                ) : null}
                {perfil == 3 && (
                  <Controller
                    name="diretoresDropdown"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Autocomplete
                          fullWidth
                          clearText="Limpar"
                          openText="Abrir"
                          closeText="Fechar"
                          noOptionsText="Sem dados"
                          options={diretoresObra || []}
                          value={value || null}
                          getOptionLabel={(option) => `${option.nome}` || ""}
                          isOptionEqualToValue={(option, value) => {
                            // if (!value.id) return true;
                            return value.id === option.id;
                          }}
                          style={{ marginTop: "10px", width: "49%" }}
                          filterOptions={(options, state) => {
                            let newOptions = [];
                            options.forEach((option) => {
                              if (
                                option.id > 0 &&
                                option.nome
                                  .toLowerCase()
                                  .includes(state.inputValue.toLowerCase())
                              )
                                newOptions.push(option);
                            });
                            return newOptions;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              label="Diretor de obra"
                              placeholder="Diretor de obra"
                              variant="outlined"
                              error={!!errors.diretoresDropdown}
                              helperText={
                                errors.diretoresDropdown
                                  ? errors.diretoresDropdown?.message
                                  : ""
                              }
                            />
                          )}
                          onChange={(_, value) => {
                            onChange(value);
                            return value;
                          }}
                        />
                      </>
                    )}
                  />
                )}
                {/* <Controller
                  name="aeroportosDropdown"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Autocomplete
                        fullWidth
                        clearText="Limpar"
                        openText="Abrir"
                        closeText="Fechar"
                        noOptionsText="Sem dados"
                        options={aeroportos || []}
                        value={value || null}
                        getOptionLabel={(option) => `${option.descricao}` || ""}
                        isOptionEqualToValue={(option, value) => {
                          // if (!value.id) return true;
                          return value.id === option.id;
                        }}
                        style={{ marginTop: "10px", width: "23%" }}
                        filterOptions={(options, state) => {
                          let newOptions = [];
                          options.forEach((option) => {
                            if (
                              option.id > 0 &&
                              option.descricao
                                .toLowerCase()
                                .includes(state.inputValue.toLowerCase())
                            )
                              newOptions.push(option);
                          });
                          return newOptions;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Aeroporto"
                            placeholder="Aeroporto"
                            variant="outlined"
                            error={!!errors.aeroportosDropdown}
                            helperText={
                              errors.aeroportosDropdown
                                ? errors.aeroportosDropdown?.message
                                : ""
                            }
                          />
                        )}
                        onChange={(_, value) => {
                          onChange(value);
                          return value;
                        }}
                      />
                    </>
                  )}
                />
                <span style={{ marginRight: "2%" }} />
                <Controller
                  name="departamentosDropdown"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Autocomplete
                        fullWidth
                        clearText="Limpar"
                        openText="Abrir"
                        closeText="Fechar"
                        noOptionsText="Sem dados"
                        options={departamentos || []}
                        value={value || null}
                        getOptionLabel={(option) => `${option.descricao}` || ""}
                        isOptionEqualToValue={(option, value) => {
                          // if (!value.id) return true;

                          return value.id === option.id;
                        }}
                        style={{ marginTop: "10px", width: "24%" }}
                        filterOptions={(options, state) => {
                          let newOptions = [];
                          options.forEach((option) => {
                            if (
                              option.id > 0 &&
                              option.descricao
                                .toLowerCase()
                                .includes(state.inputValue.toLowerCase())
                            )
                              newOptions.push(option);
                          });
                          return newOptions;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Departamento"
                            placeholder="Departamento"
                            variant="outlined"
                            error={!!errors.departamentosDropdown}
                            helperText={
                              errors.departamentosDropdown
                                ? errors.departamentosDropdown?.message
                                : ""
                            }
                          />
                        )}
                        onChange={(_, value) => {
                          onChange(value);
                          return value;
                        }}
                      />
                    </>
                  )}
                /> */}
              </div>

              <h4
                style={{ color: "#E21450" }}
                className="text-underline-title-drawer "
              >
                Permissões
              </h4>
              <TabContext value={valueTab}>
                <>
                  <Box>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      variant="scrollable"
                    >
                      {utilizador &&
                        utilizador.permissoes &&
                        utilizador.permissoes.map((permissao, index) => (
                          <Tab
                            label={permissao.grupo}
                            value={index}
                            className={classes.tab}
                          />
                        ))}
                    </TabList>
                  </Box>
                  {utilizador &&
                    utilizador.permissoes &&
                    utilizador.permissoes.map((permissao, index) => (
                      <TabPanel value={index}>
                        <div
                          className="permissoes-lista-items"
                          style={{
                            columns:
                              permissao.lista.length > 4 ? "4 auto" : null,
                          }}
                        >
                          {permissao &&
                            permissao.lista &&
                            permissao.lista.map((item) => (
                              <div key={item.id}>
                                <div className="permissao-checkbox-tab">
                                  <FormControlLabel
                                    control={
                                      <CustomCheckbox
                                        checked={item.permissao == 1}
                                        onChange={() =>
                                          handleChangePermissoes(
                                            item,
                                            permissao.grupo
                                          )
                                        }
                                        name={item.descricao}
                                      />
                                    }
                                    label={item.descricao}
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      </TabPanel>
                    ))}
                  {user.email == "administrador@gmail.com" && (
                    <Box>
                      <Button
                        className={classes.btnGuardar}
                        onClick={handleCreateToken}
                      >
                        Create token
                      </Button>
                      <Controller
                        name="auth_utilizadores_url_key"
                        control={control}
                        render={({ field }) => (
                          <>
                            <TextField
                              label="Token"
                              color="primary"
                              variant="outlined"
                              size="small"
                              {...field}
                              fullWidth
                              style={{ marginTop: "10px", width: "49%" }}
                              error={!!errors.auth_utilizadores_url_key}
                              helperText={
                                errors.auth_utilizadores_url_key
                                  ? errors.auth_utilizadores_url_key?.message
                                  : ""
                              }
                            />
                          </>
                        )}
                      />
                    </Box>
                  )}
                </>
              </TabContext>
              {/* <div style={{ display: "flex", flexWrap: "wrap" }}>
                {utilizador &&
                  utilizador.permissoes &&
                  utilizador.permissoes.map((permissao, index) => (
                    <div
                      style={{ minWidth: "15%", flexWrap: "wrap" }}
                      key={
                        permissao.lista[index]
                          ? permissao.lista[index].id
                          : index
                      }
                    >
                      <p>{permissao.grupo}</p>

                      <div className="permissoes-lista-items">
                        {permissao &&
                          permissao.lista &&
                          permissao.lista.map((item) => (
                            <div key={item.id}>
                              <div
                                className="permissao-checkbox"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() =>
                                  handleChangePermissoes(item, permissao.grupo)
                                }
                              >
                                <FormControlLabel
                                  control={
                                    <CustomCheckbox
                                      // eslint-disable-next-line eqeqeq
                                      checked={item.permissao == 1}
                                      onChange={() =>
                                        handleChangePermissoes(
                                          item,
                                          permissao.grupo
                                        )
                                      }
                                      name={item.descricao}
                                    />
                                  }
                                  label={item.descricao}
                                />
                                <FeatherIcon
                                  icon={
                                    item.permissao ? 'check-square' : 'square'
                                  }
                                  size={16}
                                  style={
                                    item.permissao
                                      ? {
                                          color: '#E21450',
                                          marginRight: '5px',
                                          strokeWidth: '1.5',
                                        }
                                      : {
                                          color: '#333',
                                          marginRight: '5px',
                                          strokeWidth: '1.5',
                                        }
                                  }
                                /> 
                                <p>{item.descricao}</p>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </div> */}
            </Paper>
          </form>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export default FormEdit;
