import Instance from "../../../../Axios";

// New Actions for the app

const GetObrasAHDProjetosPenalizadosAnoAnterior = () => async (dispatch) => {
  await Instance()
    .get(`/construcoes/obras/ahd/penalizadosAnoAnterior`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({
        type: "GET_OBRAS_AHD_PROJETOS_PENALIZADOS_ANO_ANTERIOR",
        payload: data.yData[0].total,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetObrasAHDProjetosPenalizadosAnoAnterior;
