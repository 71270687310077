/* eslint-disable no-lone-blocks */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Box,
  TextField,
  Button,
  IconButton,
  useTheme,
  Tooltip,
  Autocomplete,
  Link,
  Breadcrumbs,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import "moment/locale/pt";
import { format } from "date-fns";
import MUIDataTable from "mui-datatables";
import Instance from "../../../../utils/Axios";
import BASE_URL from "../../../../utils/BASE_URL";

// Components
import FooterLinks from "../../../../Global/Components/Footer";
import SideDrawer from "./components/drawer";
import ModalDelete from "./components/modalDelete";

//Icons
import FeatherIcon from "feather-icons-react";

//Actions
import GetAllCredenciacoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetAllCredenciacoesAction";
import GetCredenciacaoById from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredenciacaoByIdAction";
import GetCredFuncionarios from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionariosAction";
import GetCredAeroportos from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredAeroportosAction";
import GetCredFuncoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncoesAction";
import GetCredCores from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredCoresAction";
import GetCredTipoCartoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoCartoesAction";
import GetCredTipoVinculos from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoVinculosAction";
import GetCredEstados from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredEstadosAction";
import GetCredEmpresas from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredEmpresasAction";
import GetCredHabilitacoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredHabilitacoesAction";
import GetCredTipoDocs from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoDocsAction";
import GetCredInqueritoPessoal from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredInqueritoPessoalAction";
import GetCredCopiaCredenciacao from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredCopiaCredenciacaoAction";
import GetCredTipoCredenciacoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoCredenciacoesAction";
import GetCredAcompanhantes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredAcompanhantesAction";
import GetCredMotivos from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredMotivosAction";
import GetCredTipoCombustiveis from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoCombustiveisAction";
import GetCredTipoVeiculos from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoVeiculosAction";
import GetCredPortariaAcesso from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredPortariaAcessoAction";
import GetCredViaturaFicheiros from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredViaturaFicheirosAction";
import GetCredViaturaFicheirosPermanente from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredViaturaFicheirosPermanenteAction";
import GetCredViaturaUploadFiles from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredViaturaUploadFilesAction";
import GetCredVeiculos from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredVeiculosAction";
import GetObras from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasAction";
import SendCredPontual from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/SendCredPontualAction";
import ShowNotification from "../../../../utils/redux/actions/global/notifications/NotificationAction";
import GetCredFuncionarioById from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionarioByIdAction";
import GetCredFuncionariosFicheiros from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionariosFicheirosAction";
import GetCredFuncionariosUploadedFiles from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionarioUploadedFilesAction";
import GetCredPedidosAdicionaisDocs from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredPedidosAdicionaisDocsAction";
import GetCredAeroportosByFuncId from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredAeroportosByFuncIdAction";
import GetAllCredDestinatarios from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetAllCredDestinatariosActions";
import GetCredDownloadFiles from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredDownloadFilesAction";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
  },
  btnFiltrar: {
    color: "#E21450",
    width: "150px",
    height: "40px",
    fontWeight: "900",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  stateLabel: {
    textTransform: "none",
    transition: "0.2s",
    display: "flex",
    alignItems: "center",
  },

  paperFilters: {
    background: "transparent",
    padding: "20px 0px",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    height: 40,
    borderTop: "4px solid transparent",
    borderBottom: "4px solid transparent",

    "&:hover": {
      borderRight: "4px solid #E21450",
    },
  },
}));

const ColorStateCube = ({ color }) => {
  return (
    <div
      style={{
        height: "10px",
        width: "10px",
        backgroundColor: color,
        marginRight: "10px",
        borderRadius: "3px",
      }}
    />
  );
};

// ############################################################## //
// ####################  Window Dimensions  ##################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Credenciacoes = () => {
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");
  const [credenciacao, setCredenciacao] = useState({
    pedido_nr: "",
    nr_cartao: "",
    motivo_recusa: "",
    area_acesso_a: "",
    area_acesso_b: "",
    area_acesso_c: "",
    area_acesso_e: "",
    area_acesso_i: "",
    area_acesso_l: "",
    area_acesso_m: "",
    area_acesso_o: "",
    area_acesso_p: "",
    area_acesso_t: "",
    inscricao_adicional: "",
    obs: "",
    validade_inicio: "",
    validade_fim: "",
    horario_inicio: "",
    horario_fim: "",
    renovar_cartao: "",
    pedido_segunda_via: "",
    pedido_alteracao_areas: "",
    vinculo_laboral_inicio: "",
    vinculo_laboral_fim: "",
    inquerito_pessoal_exito: "",
    porta_check_in: "",
    porta_embarque: "",
    porta_desembarque: "",
    porta_outras: "",
    numero_cred_externa: "",
    tools: "",
    car_access: "",
    pistas: "",
    plataformas: "",
    outros: "",
    desc_trab: "",
  });

  const resetCredenciacao = () => {
    setCredenciacao({
      pedido_nr: "",
      nr_cartao: "",
      motivo_recusa: "",
      area_acesso_a: "",
      area_acesso_b: "",
      area_acesso_c: "",
      area_acesso_e: "",
      area_acesso_i: "",
      area_acesso_l: "",
      area_acesso_m: "",
      area_acesso_o: "",
      area_acesso_p: "",
      area_acesso_t: "",
      inscricao_adicional: "",
      obs: "",
      validade_inicio: "",
      validade_fim: "",
      horario_inicio: "",
      horario_fim: "",
      renovar_cartao: "",
      pedido_segunda_via: "",
      pedido_alteracao_areas: "",
      vinculo_laboral_inicio: "",
      vinculo_laboral_fim: "",
      inquerito_pessoal_exito: "",
      porta_check_in: "",
      porta_embarque: "",
      porta_desembarque: "",
      porta_outras: "",
      numero_cred_externa: "",
      tools: "",
      car_access: "",
      pistas: "",
      plataformas: "",
      outros: "",
      descTrab: "",
    });
  };

  // ############################################################## //
  // ######################  Autocompletes  ####################### //
  // ############################################################## //

  const [empresa, setEmpresa] = useState({
    id: "0",
    descricao: "",
  });
  const [aeroporto, setAeroporto] = useState({
    id: "0",
    descricao: "",
  });
  const [estado, setEstado] = useState({
    id: "0",
    descricao: "",
  });
  const [tipoCred, setTipoCred] = useState({
    id: "0",
    descricao: "",
  });
  const [func, setFunc] = useState({
    id: "0",
    nome: "",
  });
  const [tipoCredenciacao, setTipoCredenciacao] = useState({
    id: null,
    descricao: "",
  });
  const [rascunho, setRascunho] = useState({
    id: "0",
    descricao: "",
  });

  const tipoRegistos = [
    {
      id: "1",
      descricao: "Rascunho",
    },
  ];

  const credenciaisState = useSelector(
    (state) => state.credenciais.credenciacoes
  );

  const permissoes = useSelector((state) => state.user.permissoes);
  const aeroId = useSelector((state) => state.user.aeroportoId);
  const perfilId = useSelector((state) => state.user.perfilId);
  const superuser = permissoes.includes("APP_CRED_SUPER_USER");
  const aeroportos = useSelector((state) => state.credenciais.aeroportos);
  const funcoes = useSelector((state) => state.credenciais.funcoes);
  const tipoCartoes = useSelector((state) => state.credenciais.tipoCartoes);
  const tipoVinculos = useSelector((state) => state.credenciais.tipoVinculos);
  const tipoCombustiveis = useSelector(
    (state) => state.credenciais.tipoCombustiveis
  );
  const tipoVeiculos = useSelector((state) => state.credenciais.tipoVeiculos);
  const portariaAcesso = useSelector(
    (state) => state.credenciais.portariaAcesso
  );
  const obrasState = useSelector((state) => state.obras.obras);
  // const tipoCredenciacoes = useSelector(
  //   (state) => state.credenciais.tipoCredenciacoes
  // );
  const tipoCredenciacoes = [
    { id: 1, descricao: "Permanente" },
    { id: 2, descricao: "Pontual" },
    { id: 4, descricao: "Viatura" },
  ];
  const cores = useSelector((state) => state.credenciais.cores);
  const funcionarios = useSelector((state) => state.credenciais.funcionarios);
  const acompanhantes = useSelector((state) => state.credenciais.acompanhantes);
  const motivos = useSelector((state) => state.credenciais.motivos);
  const estados = useSelector((state) => state.credenciais.estados);
  const veiculos = useSelector((state) => state.credenciais.veiculos);

  const empresas = useSelector((state) => state.credenciais.empresas);
  const habilitacoes = useSelector((state) => state.credenciais.habilitacoes);
  const tipoDocs = useSelector((state) => state.credenciais.tipoDocs);
  const tipoCreds = useSelector((state) => state.credenciais.tipoCredenciacoes);
  const destinatariosOptions = useSelector(
    (state) => state.credenciais.credDestinatarios
  );
  const inqPessoal = useSelector((state) => state.credenciais.inqueritoPessoal);
  const copCred = useSelector((state) => state.credenciais.copiaCredenciacao);

  const fetchCredEmpresas = () => {
    dispatch(GetCredEmpresas());
  };

  const fetchCredHabilitacoes = () => {
    dispatch(GetCredHabilitacoes());
  };

  const fetchCredTipoDocs = () => {
    dispatch(GetCredTipoDocs());
  };

  const fetchCredenciacoes = (
    empresaId,
    aeroportoId,
    estadoId,
    tipoCredId,
    funcId,
    rascunhoId
  ) => {
    dispatch(
      GetAllCredenciacoes(
        empresaId,
        aeroportoId,
        estadoId,
        tipoCredId,
        funcId,
        rascunhoId
      )
    );
  };

  const fetchCredAeroportos = () => {
    dispatch(GetCredAeroportos());
  };

  const fetchCredFuncoes = () => {
    dispatch(GetCredFuncoes());
  };

  const fetchCredTipoCartoes = () => {
    dispatch(GetCredTipoCartoes());
  };

  const fetchCredTipoCredenciacoes = () => {
    dispatch(GetCredTipoCredenciacoes());
  };

  const fetchCredTipoVinculos = () => {
    dispatch(GetCredTipoVinculos());
  };

  const fetchCredTipoCombustiveis = () => {
    dispatch(GetCredTipoCombustiveis());
  };

  const fetchCredTipoVeiculos = () => {
    dispatch(GetCredTipoVeiculos());
  };

  const fetchCredPortariaAcesso = () => {
    dispatch(GetCredPortariaAcesso());
  };

  const fetchCredCores = () => {
    dispatch(GetCredCores());
  };

  const fetchCredVeiculos = () => {
    dispatch(GetCredVeiculos());
  };

  const fetchCredFuncionarios = (intervencaoId, rascunhoId) => {
    dispatch(GetCredFuncionarios(intervencaoId, rascunhoId));
  };

  const fetchCredAcompanhantes = () => {
    dispatch(GetCredAcompanhantes());
  };

  const fetchCredMotivos = () => {
    dispatch(GetCredMotivos());
  };

  const fetchCredEstados = () => {
    dispatch(GetCredEstados());
  };

  const fetchCredCopiaCredenciacao = () => {
    dispatch(GetCredCopiaCredenciacao());
  };

  const fetchCredInqueritoPessoal = () => {
    dispatch(GetCredInqueritoPessoal());
  };

  const fetchCredViaturaFicheiros = () => {
    dispatch(GetCredViaturaFicheiros());
  };

  const fetchCredViaturaFicheirosPermanente = () => {
    dispatch(GetCredViaturaFicheirosPermanente());
  };

  const fetchCredPedidosAdicionaisDocs = () => {
    dispatch(GetCredPedidosAdicionaisDocs());
  };

  const fetchAllCredDestinatarios = () => {
    dispatch(GetAllCredDestinatarios());
  };

  const fetchObras = (
    estadosProjetoPreSelectedId,
    estadosSubProjetoPreSelectedId,
    aeroportosId,
    estadosControloId,
    diretorObraId,
    aeroId
  ) => {
    dispatch(
      GetObras(
        estadosProjetoPreSelectedId,
        estadosSubProjetoPreSelectedId,
        aeroportosId,
        estadosControloId,
        diretorObraId,
        aeroId
      )
    );
  };

  useEffect(() => {
    fetchCredenciacoes(
      empresa.id,
      aeroporto.id,
      estado.id,
      tipoCred.id,
      func.id,
      rascunho.id
    );
  }, []);

  useEffect(() => {
    fetchCredAeroportos();
    fetchCredFuncoes();
    fetchCredTipoCartoes();
    fetchCredTipoVinculos();
    fetchCredCores();
    fetchCredFuncionarios(null, 0);
    fetchCredAcompanhantes();
    fetchCredMotivos();
    fetchCredEstados();
    fetchCredEmpresas();
    fetchCredHabilitacoes();
    fetchCredTipoDocs();
    fetchCredCopiaCredenciacao();
    fetchCredInqueritoPessoal();
    fetchCredTipoCredenciacoes();
    fetchCredPortariaAcesso();
    fetchCredTipoCombustiveis();
    fetchCredTipoVeiculos();
    fetchCredViaturaFicheiros();
    fetchCredVeiculos();
    fetchCredViaturaFicheirosPermanente();
    fetchCredPedidosAdicionaisDocs();
    fetchAllCredDestinatarios();
  }, []);

  const user = useSelector((state) => state.user);

  let estadosProjetoPreSelectedId = useSelector(
    (state) => state.obras.estadosProjetoPreSelected
  );
  let estadosSubProjetoPreSelectedId = useSelector(
    (state) => state.obras.estadosSubProjetoPreSelected
  );
  let diretorObraPreSelectedId = useSelector(
    (state) => state.obras.diretorObraPreSelected
  );
  let controloPreSelectedId = useSelector(
    (state) => state.obras.controloPreSelected
  );
  let aeroportoPreSelectedId = useSelector(
    (state) => state.obras.aeroportosPreSelected
  );

  const aeroIds = aeroportoPreSelectedId.map((aero) => aero.id);

  useEffect(() => {
    const eProjetosId = estadosProjetoPreSelectedId.map((ep) => ep.id);
    const eSubProjetosId = estadosSubProjetoPreSelectedId.map((ep) => ep.id);
    let aeroportoId = [];
    const controloId = controloPreSelectedId.map((ep) => ep.id);
    const diretorObraId =
      diretorObraPreSelectedId && diretorObraPreSelectedId.id;
    if (user && user.aeroportoFilter != null) {
      if (aeroIds.includes(user.aeroportoFilter.id) == false) {
        dispatch({
          type: "ESTADOS_AEROPORTO_PRE_SELECTED",
          payload: [user && user.aeroportoFilter],
        });
        aeroportoId = [user && user.aeroportoFilter].map((ep) => ep.id);
      } else {
        dispatch({
          type: "ESTADOS_AEROPORTO_PRE_SELECTED",
          payload: aeroportoPreSelectedId,
        });
        aeroportoId = aeroportoPreSelectedId.map((ep) => ep.id);
      }
    } else {
      dispatch({
        type: "ESTADOS_AEROPORTO_PRE_SELECTED",
        payload: [],
      });
    }

    fetchObras(
      eProjetosId,
      eSubProjetosId,
      aeroportoId,
      controloId,
      diretorObraId
    );
  }, []);

  // ############################################################## //
  // #########################  Drawer  ########################### //
  // ############################################################## //

  const [credenciacaoId, setCredenciacaoId] = useState();
  const fetchCredCopiaCredenciacaoUploaded = (credId) => {
    dispatch(GetCredCopiaCredenciacaoUploaded(credId));
  };
  const fetchCredenciacaoById = (credId) => {
    dispatch(GetCredenciacaoById(credId));
  };
  const fetchCredFuncionarioById = (funcionarioId) => {
    dispatch(GetCredFuncionarioById(funcionarioId));
  };

  const fetchCredViaturaUploadedFiles = (credId) => {
    dispatch(GetCredViaturaUploadFiles(credId));
  };

  const fetchCredFuncionariosUploadedFiles = (funcionarioId) => {
    dispatch(GetCredFuncionariosUploadedFiles(funcionarioId));
  };

  const fetchCredFuncionarioAeroportos = (funcionarioId) => {
    dispatch(GetCredAeroportosByFuncId(funcionarioId));
  };

  const fetchCredDownloadFiles = (credId, tipoId) => {
    dispatch(GetCredDownloadFiles(credId, tipoId));
  };

  const [drawerWidth, setDrawerWidth] = useState("90vw");
  const dimensions = useWindowDimensions();
  const [state, setState] = useState({
    right: false,
  });
  const [drawerState, setDrawerState] = useState(null);
  const toggleDrawer = (anchor, open, id, idFunc, tipoId) => {
    if (open == false) {
      setState({ ...state, [anchor]: open });
      setCredenciacaoId(null);
      resetCredenciacao();
    }
    if (open == true) {
      setState({ ...state, [anchor]: open });
      setCredenciacaoId(id);
      fetchCredenciacaoById(id);
      fetchCredViaturaUploadedFiles(id);
      fetchCredFuncionarioAeroportos(idFunc);
      fetchCredFuncionariosUploadedFiles(idFunc);
      fetchCredFuncionarioById(idFunc);
      fetchCredDownloadFiles(id, tipoId);
    }
  };

  // ############################################################## //
  // #######################  Modal Delete  ####################### //
  // ############################################################## //

  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = (e, id) => {
    e.preventDefault();

    setLoading(false);

    setOpenDelete(true);
    setCredenciacaoId(id);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpenDelete(false);
  };

  // ############################################################## //
  // #########################  Messages  ######################### //
  // ############################################################## //

  const messageEmail = {
    isOpen: true,
    message: "Email enviado com sucesso!!",
    type: "success",
  };

  // ############################################################## //
  // ##################  MUI Datatable columns  ################### //
  // ############################################################## //

  const columns = [
    {
      name: "id",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "id_cred",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "matricula",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "tipo_cred",
      label: "Credenciação",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "nr_cartao",
      label: "Nr. Cartão",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "nome",
      label: "Nome/Matricula",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                <Typography>{tableMeta.rowData[2]}</Typography>
              ) : (
                <Typography>{value}</Typography>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "empresa",
      label: "Empresa",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                <Typography>{tableMeta.rowData[14]}</Typography>
              ) : (
                <Typography>{value}</Typography>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "aeroporto",
      label: "Aeroporto",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "projeto",
      label: "Projeto",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "validade_inicio",
      label: "Validade Inicio",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "validade_fim",
      label: "Validade Fim",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },

    {
      name: "estado",
      label: "Estado",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { width: "250px" },
        }),
        customBodyRender: (value, tableMeta) => {
          switch (value) {
            case "Submetida":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#FFD500" />
                  {value}
                </div>
              );
            case "Levantada":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#4CB64C" />
                  {value}
                </div>
              );
            case "Recusada":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#DE0000" />
                  {value}
                </div>
              );
            case "Expirada":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#DE0000" />
                  {value}
                </div>
              );
            case "Emitida":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#4CB64C" />
                  {value}
                </div>
              );
            case "Recolha de dados":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#FF8521" />
                  {value}
                </div>
              );
            case "Aproximação da data de expiração":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#FF8521" />
                  {value}
                </div>
              );
            case "Preparada para submissão":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#54B7D3" />
                  {value}
                </div>
              );
            case "Devolvida":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#DE0000" />
                  {value}
                </div>
              );
            default:
              return (
                <div>
                  <ColorStateCube color="" />
                  {value}
                </div>
              );
          }
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { width: "5%" },
          align: "center",
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const o = tableMeta.rowData[0];
          const tipoId = tableMeta.rowData[16];
          const filesLength = tableMeta.rowData[13];
          const idFunc = tableMeta.rowData[15];
          const estado = tableMeta.rowData[11];

          return (
            <div style={{ display: "flex", float: "right" }}>
              {/* {filesLength > 0 && estado == "Submetida" && (
                <IconButton
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                  onClick={() => {
                    dispatch(SendCredPontual(o));
                    dispatch(ShowNotification(messageEmail));
                  }}
                >
                  <FeatherIcon
                    icon="send"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              )} */}
              {filesLength > 0 ? (
                <IconButton
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                  onClick={() => {
                    Instance()
                      .get(
                        `${BASE_URL}/credenciacoes/cred/${o}/${tipoId}/download`,
                        {
                          responseType: "blob",
                        }
                      )
                      .then(({ data }) => {
                        const downloadUrl = window.URL.createObjectURL(
                          new Blob([data])
                        );

                        const link = document.createElement("a");

                        link.href = downloadUrl;

                        link.setAttribute(
                          "download",
                          `credenciacao_${o}_documentos.zip`
                        ); //any other extension

                        document.body.appendChild(link);

                        link.click();
                        link.remove();
                        // dispatch(ShowNotification(message));
                      })
                      .catch((err) => {
                        console.log(err.response);
                        if (err.response) {
                          if (err.response === 403) {
                            // dispatch(ShowNotification(errorMessage));
                          }
                        }
                      });
                  }}
                >
                  <FeatherIcon
                    icon="download"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              ) : null}
              {permissoes.includes("APP_CRED_CREDENCIACOES_EDITAR") && (
                <IconButton
                  onClick={() => {
                    setTipoCredenciacao({
                      id: tableMeta.rowData[1],
                      descricao: tableMeta.rowData[2],
                    });
                    setDrawerState("edit");
                    toggleDrawer("right", true, o, idFunc, tipoId);
                  }}
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                >
                  <FeatherIcon
                    icon="edit"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              )}
              {permissoes.includes("APP_CRED_CREDENCIACOES_REMOVER") && (
                <IconButton
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                  onClick={(e) => {
                    handleOpen(e, o);
                  }}
                >
                  <FeatherIcon
                    icon="trash-2"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "numFicheiros",
      label: "numFicheiros",
      options: {
        display: false,
      },
    },
    {
      name: "proprietario",
      label: "Proprietário",
      options: {
        display: false,
      },
    },
    {
      name: "id_func",
      label: "Id_Func",
      options: {
        display: false,
      },
    },
    {
      name: "tipo_cred_id",
      label: "Tipo id",
      options: {
        display: false,
      },
    },
  ];

  // ############################################################## //
  // ##################  MUI Datatable Options  ################### //
  // ############################################################## //

  const searchText =
    location.state && location.state.funcionarioNome
      ? location.state.funcionarioNome
      : null;

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    searchText: searchText,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },

    customToolbar: () => {
      return (
        <>
          {permissoes.includes("APP_CRED_CREDENCIACOES_INSERIR") && (
            <Tooltip title={"Adicionar Credenciação"}>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  setDrawerState("add");
                  toggleDrawer("right", true, null);
                }}
              >
                <FeatherIcon
                  icon="plus-square"
                  size={22}
                  className={classes.deleteIcon}
                  style={{ strokeWidth: "2" }}
                />
              </IconButton>
            </Tooltip>
          )}
        </>
      );
    },
  };

  const handleSubmit = () => {
    const empresaId = empresa.id;
    const aeroportoId = aeroporto.id;
    const estadoId = estado.id;
    const tipoCredId = tipoCred.id;
    const funcId = func.id;
    const rascunhoId = rascunho.id;

    fetchCredenciacoes(
      empresaId,
      aeroportoId,
      estadoId,
      tipoCredId,
      funcId,
      rascunhoId
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mr: 10,
          width: "100%",
        }}
      >
        <SideDrawer
          state={state}
          toggleDrawer={toggleDrawer}
          drawerState={drawerState}
          drawerWidth={drawerWidth}
          dimensions={dimensions}
          credenciacaoId={credenciacaoId}
          credenciacao={credenciacao}
          setCredenciacao={setCredenciacao}
          fetchCredenciacoes={fetchCredenciacoes}
          aeroportos={aeroportos}
          funcoes={funcoes}
          tipoCartoes={tipoCartoes}
          tipoVinculos={tipoVinculos}
          cores={cores}
          funcionarios={funcionarios}
          acompanhantes={acompanhantes}
          motivos={motivos}
          estados={estados}
          empresas={empresas}
          habilitacoes={habilitacoes}
          tipoDocs={tipoDocs}
          inqPessoal={inqPessoal}
          copCred={copCred}
          tipoCredenciacao={tipoCredenciacao}
          tipoCombustiveis={tipoCombustiveis}
          tipoVeiculos={tipoVeiculos}
          portariaAcesso={portariaAcesso}
          veiculos={veiculos}
          obrasState={obrasState}
          destinatariosOptions={destinatariosOptions}
          globalFetch={fetchCredenciacoes}
        />
        <ModalDelete
          openDelete={openDelete}
          handleClose={handleClose}
          credenciacaoId={credenciacaoId}
          fetchCredenciacoes={fetchCredenciacoes}
          // show={show}
          // Loading
          loading={loading}
          setLoading={setLoading}
        />
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              mx: 5,
              mt: 5,
              width: "100%",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/construcoes/obras">
                Gestão Aeroportuária
              </Link>
              <Typography color="text.primary">Credenciações</Typography>
            </Breadcrumbs>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Box sx={{ width: "240px" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Empresa
                </Typography>
                <Autocomplete
                  fullWidth
                  name="nome"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  value={empresa}
                  className="unidadesId-form"
                  options={empresas || []}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason == "clear") {
                      setEmpresa({ id: "0", descricao: "" });
                    } else {
                      setEmpresa(value);
                    }
                    return value;
                  }}
                />
              </Box>
              {superuser && (
                <Box sx={{ width: "240px" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Aeroporto
                  </Typography>
                  <Autocomplete
                    fullWidth
                    name="nome"
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    value={aeroporto}
                    className="unidadesId-form"
                    options={aeroportos || []}
                    getOptionLabel={(option) => `${option.descricao}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason == "clear") {
                        setAeroporto({ id: "0", descricao: "" });
                      } else {
                        setAeroporto(value);
                      }
                      return value;
                    }}
                  />
                </Box>
              )}
              <Box sx={{ width: "240px" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Estado
                </Typography>
                <Autocomplete
                  fullWidth
                  name="nome"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  value={estado}
                  className="unidadesId-form"
                  options={estados || []}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason == "clear") {
                      setEstado({ id: "0", descricao: "" });
                    } else {
                      setEstado(value);
                    }
                    return value;
                  }}
                />
              </Box>
              <Box sx={{ width: "240px" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Tipo de Credenciação
                </Typography>
                <Autocomplete
                  fullWidth
                  name="nome"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  value={tipoCred}
                  className="unidadesId-form"
                  options={tipoCreds || []}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason == "clear") {
                      setTipoCred({ id: "0", descricao: "" });
                    } else {
                      setTipoCred(value);
                    }
                    return value;
                  }}
                />
              </Box>
              <Box sx={{ width: "240px" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Funcionário
                </Typography>
                <Autocomplete
                  fullWidth
                  name="nome"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  value={func}
                  className="unidadesId-form"
                  options={funcionarios || []}
                  getOptionLabel={(option) => `${option.nome}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason == "clear") {
                      setFunc({ id: "0", nome: "" });
                    } else {
                      setFunc(value);
                    }
                    return value;
                  }}
                />
              </Box>
              <Box sx={{ width: "240px" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Registo
                </Typography>
                <Autocomplete
                  fullWidth
                  name="nome"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  value={rascunho}
                  className="unidadesId-form"
                  options={tipoRegistos || []}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason == "clear") {
                      setRascunho({ id: "0", descricao: "" });
                    } else {
                      setRascunho(value);
                    }
                    return value;
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "end",
                }}
              >
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  className={classes.btnFiltrar}
                >
                  Filtrar
                </Button>
              </Box>
            </Box>
          </Box>
          <div
            style={{
              margin: "0px 40px 40px 40px",
              width: "100%",
            }}
          >
            <MUIDataTable
              title={
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  className="text-underline"
                >
                  Lista de Credenciações
                </span>
              }
              data={credenciaisState || []}
              columns={columns}
              options={options}
            />
          </div>
          <div style={{ margin: "20px 40px" }}>
            <FooterLinks />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Credenciacoes;
