import Instance from "../../../Axios";

const GetAdesaoFilesAdesaoById = (id) => async (dispatch) => {
  await Instance()
    .get(`/plus/adesoes/${id}/filesAdesao`)
    .then(({ data }) => {
      dispatch({ type: "GET_ADESAO_FILES_ADESAO_BY_ID", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetAdesaoFilesAdesaoById;