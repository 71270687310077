import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Modal,
  Fade,
  Button,
  Typography,
  TextField,
  Backdrop,
  Box,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

//Action
import addIntervencao from "../../../../../utils/redux/actions/intervencoes/AddIntervencaoAction";
import ShowNotification from "../../../../../utils/redux/actions/global/notifications/NotificationAction";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: 2,
  boxShadow: 3,
  p: 4,
  pt: 3,
};

const useStyles = makeStyles((theme) => ({
  btnConfirm: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCancel: {
    color: "#E21450",
    width: "125px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  modalBtns: {
    paddingTop: 30,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "center",
  },
  btnUpload: {
    color: "#E21450",
    // width: '125px',
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
}));

// const schema = yup.object().shape({
//   pedidoNome: yup.string().required('Preenchimento obrigatório'),
//   pedidoDescricao: yup.string().required('Preenchimento obrigatório'),
// });

const ModalAdd = ({
  openAdd,
  handleClose,
  fetchIntervencoes,
  validateOnChange = true,
  errorsIntervencao,
  setErrorsIntervencao,
  intervencao,
  setIntervencao,
  images,
  setImages,
  anoAtual,
  estado,
  subEstado,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setIntervencao({ ...intervencao, [e.target.name]: e.target.value });
    if (validateOnChange)
      validationIntervencao({ [e.target.name]: e.target.value });
  };

  // ############################################################## //
  // ##########################  States  ########################## //
  // ############################################################## //

  const [urgente, setUrgente] = useState(false);

  // ############################################################## //
  // ##################  Validação Intervenção  ################### //
  // ############################################################## //

  const validationIntervencao = (fieldValues = intervencao) => {
    const temp = { ...errorsIntervencao };

    if ("pedidoNome" in fieldValues)
      temp.pedidoNome = fieldValues.pedidoNome ? "" : "Campo Obrigatório";
    if ("pedidoDescricao" in fieldValues)
      temp.pedidoDescricao = fieldValues.pedidoDescricao
        ? ""
        : "Campo Obrigatório";
    if ("sig" in fieldValues)
      temp.pedidoDescricao = fieldValues.sig ? "" : "Campo Obrigatório";

    setErrorsIntervencao({
      ...temp,
    });
    // eslint-disable-next-line eqeqeq
    if (fieldValues == intervencao) {
      // eslint-disable-next-line eqeqeq
      return Object.values(temp).every((x) => x == "");
    }
    return false;
  };

  const onSubmit = (e) => {
    // e.preventDefault();
    let prioridade = 1;

    if (urgente) {
      prioridade = 3;
    }

    const ydata = [
      {
        pedidoNome: intervencao.pedidoNome,
        pedidoDescricao: intervencao.pedidoDescricao,
        sig: intervencao.sig,
        prioridade: prioridade,
      },
    ];

    const message = {
      isOpen: true,
      message: "Intervenção adicionada com sucesso!!",
      type: "success",
    };
    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!!",
      type: "error",
    };

    console.log(ydata, intervencao);

    if (validationIntervencao()) {
      dispatch(
        addIntervencao(ydata, images, ShowNotification, message, errorMessage)
      )
        .then(() => handleClose("add", e))
        .then(() => fetchIntervencoes(anoAtual, estado, subEstado));
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdd}
        onClose={(e) => {
          handleClose("add", e);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          backgroundColor: "rgb(0,0,0,0.1)",
        }}
      >
        <Fade in={openAdd}>
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography fontWeight="bold">Adicionar Intervenção</Typography>
              <form
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  flexWrap: "wrap",
                }}
                // onSubmit={handleSubmit(onSubmit)}
                onSubmit={onSubmit}
              >
                <TextField
                  label="Nome do Pedido"
                  name="pedidoNome"
                  color="primary"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  style={{ width: "100%" }}
                  onChange={(e) => handleChange(e)}
                  // error={!!errors.pedidoNome}
                  // helperText={errors.pedidoNome ? errors.pedidoNome?.message : ''}
                  {...(errorsIntervencao.pedidoNome && {
                    error: true,
                    helperText: errorsIntervencao.pedidoNome,
                  })}
                />
                <TextField
                  label="Descrição"
                  name="pedidoDescricao"
                  color="primary"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  style={{ width: "100%", marginTop: "20px" }}
                  onChange={(e) => handleChange(e)}
                  // error={!!errors.pedidoNome}
                  // helperText={errors.pedidoNome ? errors.pedidoNome?.message : ''}
                  {...(errorsIntervencao.pedidoDescricao && {
                    error: true,
                    helperText: errorsIntervencao.pedidoDescricao,
                  })}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <Box sx={{ width: "48%" }}>
                    <TextField
                      label="SIG"
                      name="sig"
                      color="primary"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      style={{ width: "100%" }}
                      onChange={(e) => handleChange(e)}
                      // error={!!errors.pedidoNome}
                      // helperText={errors.pedidoNome ? errors.pedidoNome?.message : ''}
                      {...(errorsIntervencao.sig && {
                        error: true,
                        helperText: errorsIntervencao.sig,
                      })}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginRight: "10px" }}
                    >
                      Muito Urgente
                    </Typography>
                    <Checkbox
                      checked={urgente}
                      onChange={() => setUrgente(!urgente)}
                    />
                  </Box>
                </Box>

                {/* <ImageUploader
                  intervencao={intervencao}
                  setIntervencao={setIntervencao}
                  images={images}
                  setImages={setImages}
                  classes={classes}
                /> */}

                <div className={classes.modalBtns}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={(e) => handleClose("add", e)}
                    className={classes.btnCancel}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    onClick={(e) => onSubmit(e)}
                    // type='submit'
                    sx={{ mx: 2 }}
                    className={classes.btnConfirm}
                  >
                    Guardar
                  </Button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalAdd;
