/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Button, IconButton, Paper, Tab, Typography } from "@mui/material";
import { format } from "date-fns";
import moment from "moment";
import Instance from "../../../../../../utils/Axios";
import BASE_URL from "../../../../../../utils/BASE_URL";
import { TabContext, TabList, TabPanel } from "@mui/lab";

//Icons
import FeatherIcon from "feather-icons-react";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

//Actions
import EditarObra from "../../../../../../utils/redux/actions/aprovisionamento/obras/EditarObraAction";
import GetObraWorkPermits from "../../../../../../utils/redux/actions/aprovisionamento/obras/GetObraWorkPermitsAction";
import SendPedidoWorkPermit from "../../../../../../utils/redux/actions/aprovisionamento/obras/SendPedidoWorkPermitAction";
import ShowNotification from "../../../../../../utils/redux/actions/global/notifications/NotificationAction";
import GetWorkPermitById from "../../../../../../utils/redux/actions/aprovisionamento/obras/GetWorkPermitByIdAction";

//Components
import InformacaoTeste from "./sectionsEditar/informacaoControlled";
import Controlo from "./sectionsEditar/controlo";
import MaoDeObra from "./sectionsEditar/maoDeObra";
import Especialidades from "./sectionsEditar/especialidades";
import Materiais from "./sectionsEditar/materiais";
import Estados from "./sectionsEditar/estados";
import Observacoes from "./sectionsEditar/observacoes";
import Spinner from "../../../../../../Global/Components/Spinner";
import Semaforizacao from "./sectionsEditar/semaforizacao";
import Equipamentos from "./sectionsEditar/equipamentos";
import ModalDeleteWorkPermit from "./modalDeleteWorkPermit";
import MUIDataTable from "mui-datatables";
import GetWorkPermitUploadedFiles from "../../../../../../utils/redux/actions/aprovisionamento/obras/GetWorkPermitUploadedFilesAction";
import DeleteFotoObra from "../../../../../../utils/redux/actions/aprovisionamento/obras/DeleteFotoObraAction";
import GetObrasFotos from "../../../../../../utils/redux/actions/aprovisionamento/obras/GetObraFotosAction";
import InserirFotos from "../../../../../../utils/redux/actions/aprovisionamento/obras/InserirFotosAction";
import GetObra from "../../../../../../utils/redux/actions/aprovisionamento/obras/GetObraAction";
import DrawerWorkPermit from "./drawerWorkPermit";

// const Alert = (props) => {
//   return <MuiAlert elevation={6} variant='filled' {...props} />;
// };

const ColorStateCube = ({ color }) => {
  return (
    <div
      style={{
        height: "15px",
        width: "15px",
        backgroundColor: color,
        marginRight: "10px",
        borderRadius: "3px",
      }}
    />
  );
};

const DrawerForm = ({
  state,
  obras,
  toggleDrawer,
  classes,
  fetchObras,
  estadosProjetoPreSelectedId,
  estadosSubProjetoPreSelectedId,
  theme,
  aeroportoPreSelectedId,
  controloPreSelectedId,
  diretorObraPreSelectedId,
  ficheirosState,
}) => {
  const dispatch = useDispatch();

  const [obraSelecionada, setObraSelecionada] = useState(null);

  const [guardarLoading, setGuardarLoading] = useState(false);
  const [guardarFotosLoading, setGuardarFotosLoading] = useState(false);

  const obra = useSelector((state) => state.obras.obra);
  const workpermits = useSelector((state) => state.obras.obraWorkPermits);
  const orcamento = ficheirosState.find(
    (item) => item.id_base_obras_files_tipos == 1
  );

  const user = useSelector((state) => state.user);

  const [resetForm, setResetForm] = useState(false);

  useEffect(() => {
    setObraSelecionada(obra);
  }, [obra]);

  // ############################################################## //
  // ############################ Tabs  ########################### //
  // ############################################################## //

  const [tabsvalue, setTabsValue] = useState("1");

  const handleTabChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  // ############################################################## //
  // #################### Render Cores Estados  ################### //
  // ############################################################## //

  const renderSwitch = (param) => {
    switch (param) {
      case "1":
        return <ColorStateCube color="#4CB64C" />;
      case "2":
        return <ColorStateCube color="#FFD500" />;
      case "3":
        return <ColorStateCube color="#FF5B5B" />;
      default:
        return null;
    }
  };

  // ############################################################## //
  // ######################## Modal Delete  ####################### //
  // ############################################################## //

  const [openDelete, setOpenDelete] = useState(false);
  const [workPermitId, setWorkPermitId] = useState();
  const [loading, setLoading] = useState(false);

  const handleOpen = (e, id) => {
    e.preventDefault();

    setLoading(false);

    setOpenDelete(true);
    setWorkPermitId(id);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpenDelete(false);
  };

  const filesLength = obra && obra.numOrcamentos;
  const fotosLength = obra && obra.numFotos;

  // ############################################################## //
  // ################ Mui Datatables Work Permits  ################ //
  // ############################################################## //

  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");

  const columns = [
    {
      name: "id_jaf_work_permit",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "jaf_work_permit_number_sequential",
      label: "Núm. Seq.",
    },
    {
      name: "jaf_work_permit_number",
      label: "Núm. WP",
      options: {
        setCellProps: () => ({
          style: { maxWidth: "160px" },
        }),
      },
    },
    {
      name: "jaf_work_permit_name",
      label: "Ident. trabalho",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { maxWidth: "200px" },
        }),
      },
    },
    {
      name: "jaf_work_permit_entity_name",
      label: "Ent. Solicitar",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "jaf_work_permit_date_start",
      label: "Data Inicio",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              <Typography>{moment(value).format("DD/MM/YYYY")}</Typography>
            </div>
          );
        },
      },
    },
    {
      name: "jaf_work_permit_date_end",
      label: "Data Fim",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              <Typography>{moment(value).format("DD/MM/YYYY")}</Typography>
            </div>
          );
        },
      },
    },
    {
      name: "id_jaf_work_permit_status",
      label: "Estado",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          switch (value) {
            case 1:
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#FF8521" />
                  <Typography>Em curso</Typography>
                </div>
              );
            case 2:
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#4CB64C" />
                  <Typography>Concluido</Typography>
                </div>
              );
            default:
              return <div>{value}</div>;
          }
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          const idWP = tableMeta.rowData[0];

          const assunto = tableMeta.rowData[12];
          const body = tableMeta.rowData[11];
          const numSeqWP = tableMeta.rowData[1];
          const identTrab = tableMeta.rowData[3];
          const entidade = tableMeta.rowData[4];
          const descricao = tableMeta.rowData[13];
          const validade = tableMeta.rowData[6];
          const respJAF = tableMeta.rowData[14];
          const respAHD = tableMeta.rowData[15];
          const emails = tableMeta.rowData[16];
          const percurso = tableMeta.rowData[17];
          const planta = tableMeta.rowData[18];

          const verifyForm = () => {
            // Check if the required fields are filled
            const isRequiredFilled =
              !!assunto &&
              !!body &&
              !!numSeqWP &&
              !!identTrab &&
              !!entidade &&
              !!descricao &&
              !!validade &&
              !!respJAF &&
              !!respAHD &&
              !!emails &&
              !!percurso &&
              !!planta;

            return isRequiredFilled;
          };

          return (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {user &&
                user.permissoes.includes(
                  "APP_CONSTRUCOES_ENVIAR_WORK_PERMITS"
                ) ? (
                  <Button
                    //disabled
                    onClick={() => {
                      if (body.includes("{") || body.includes("}")) {
                        dispatch(ShowNotification(bodyMessage));
                      } else {
                        if (verifyForm()) {
                          sendEmail(idWP);
                        } else {
                          dispatch(ShowNotification(warningMessage));
                        }
                      }
                    }}
                    variant="contained"
                    className={classes.btnGuardar}
                    endIcon={<FeatherIcon icon="send" />}
                  >
                    Enviar
                  </Button>
                ) : null}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { width: "5%" },
          align: "center",
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const idWP = tableMeta.rowData[0];

          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                style={{
                  padding: 6,
                  color: theme.palette.secondary.main,
                }}
                onClick={() => {
                  Instance()
                    .get(`${BASE_URL}/construcoes/workpermits/watch/${idWP}`, {
                      responseType: "blob",
                    })
                    .then((response) => {
                      // Get the content type from the response headers
                      const contentType = response.headers["content-type"];

                      // Create a blob from the response data
                      const blob = new Blob([response.data], {
                        type: contentType,
                      });

                      // Create a URL for the blob data
                      const fileUrl = URL.createObjectURL(blob);

                      // Open the file in a new window
                      window.open(fileUrl, "_blank");
                    })
                    .catch((err) => {
                      console.log(err.response);
                      if (err.response) {
                        if (err.response === 403) {
                          // dispatch(ShowNotification(errorMessage));
                        }
                      }
                    });
                }}
              >
                <FeatherIcon
                  icon="eye"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
              <IconButton
                style={{
                  padding: 6,
                  color: theme.palette.secondary.main,
                }}
                onClick={() => toggleDrawerWK("right", true, idWP, "edit")}
              >
                <FeatherIcon
                  icon="edit"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
              <IconButton
                style={{
                  padding: 6,
                  color: theme.palette.secondary.main,
                }}
                onClick={(e) => {
                  handleOpen(e, idWP);
                }}
              >
                <FeatherIcon
                  icon="trash-2"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
            </Box>
          );
        },
      },
    },
    {
      name: "jaf_work_permit_sent_flag",
      label: "sent flag",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "jaf_work_permit_body",
      label: "body",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "jaf_work_permit_email_title",
      label: "assunto",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "jaf_work_permit_description",
      label: "descricao",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "respJAF",
      label: "respJAF",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "respAHD",
      label: "respAHD",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "emails",
      label: "emails",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "percurso",
      label: "percurso",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "planta",
      label: "planta",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
  ];

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },
  };

  // ############################################################## //
  // ######################### Ficheiros ########################## //
  // ############################################################## //

  const [ficheiro, setFicheiro] = useState(); // Array to store uploaded files

  const handleFileChange = (e, ficheiro) => {
    // Create an object for the selected file with additional properties
    const updatedFile = {
      file: e.target.files[0],
      idTipo: ficheiro.id_base_obras_files_tipos,
      path: ficheiro.base_obras_files_tipos_path,
    };

    // Set the file as the new value (replacing any existing file)
    setFicheiro(updatedFile);
  };

  const handleRemoveFile = () => {
    // Clear the selected file by setting it to null or an empty object
    setFicheiro(null); // You can choose an appropriate value to represent "no file" here
  };

  // ############################################################## //
  // #################### Form Default Values  #################### //
  // ############################################################## //

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      maoObraContratada: "",
      maoObraJaf: "",
      estadosControloDropdown: "",
      estadodt: "",
      estadodc: "",
      especialidades: [
        {
          dataFim: null,
          dataInicio: null,
          especialidadesDescricao: "",
          especialidadesDropdown: { id: "", descricao: "" },
          especialidadesId: "",
          empresasDropdown: { id: "", descricao: "" },
          empresasId: "",
          empresasDescricao: "",
          estadosDescricao: "",
          estadosDropdown: { id: "", descricao: "" },
          estadosId: "",
          obrasId: "",
          observacoes: "",
          estadodt: "",
          estadodc: "",
        },
      ],
      materiais: [
        {
          estadosDescricao: "",
          estadosDropdown: { id: "", descricao: "" },
          estadosId: "",
          materiaisDescricao: "",
          materiaisDropdown: { id: "", descricao: "" },
          materiaisId: "",
          obrasId: "",
          observacoes: "",
          qtd: "",
          dataInicio: null,
          dataFim: null,
          estadodt: "",
          estadodc: "",
          subMateriais: [
            {
              estadosDescricao: "",
              estadosDropdown: { id: "", descricao: "" },
              estadosId: "",
              materiaisDescricao: "",
              materiaisDropdown: { id: "", descricao: "" },
              materiaisId: "",
              unidadesDropdown: { id: "", descricao: "" },
              unidadesId: "",
              obrasId: "",
              observacoes: "",
              qtd: "",
              dataInicio: null,
              dataFim: null,
              subMateriaisDescricao: "",
              subMateriaisDropdown: { id: "", descricao: "" },
              subMateriaisId: "",
              estadodt: "",
              estadodc: "",
            },
          ],
        },
      ],
      equipamentos: [
        {
          dataFim: null,
          dataInicio: null,
          equipamentosDescricao: "",
          equipamentosDropdown: { id: "", descricao: "" },
          equipamentosId: "",
          empresasDropdown: { id: "", descricao: "" },
          empresasId: "",
          qtd: "",
          estadosDescricao: "",
          estadosDropdown: { id: "", descricao: "" },
          estadosId: "",
          obrasId: "",
          observacoes: "",
          estadodt: "",
          estadodc: "",
          // subEquipamentos: [
          //   {
          //     estadosDescricao: "",
          //     estadosDropdown: { id: "", descricao: "" },
          //     estadosId: "",
          //     equipamentosDescricao: "",
          //     equipamentosDropdown: { id: "", descricao: "" },
          //     equipamentosId: "",
          //     unidadesDropdown: { id: "", descricao: "" },
          //     unidadesId: "",
          //     obrasId: "",
          //     observacoes: "",
          //     qtd: "",
          //     dataInicio: "",
          //     dataFim: "",
          //     subEquipamentosDescricao: "",
          //     subEquipamentosDropdown: { id: "", descricao: "" },
          //     subEquipamentosId: "",
          //     estadodt: "",
          //     estadodc: "",
          //   },
          // ],
        },
      ],
    },
    // resolver: yupResolver(schema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (obraSelecionada && resetForm == false) {
      reset({ ...obraSelecionada });
    }

    // updateGlobals();

    // if (obraSelecionada) {
    //   reset({ ...obraSelecionada });
    // }

    // if (obraSelecionada && resetForm == true) {
    //   setValue('materiais', obraSelecionada.materiais);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obraSelecionada]);

  // ############################################################## //
  // ###################  Semaforização Global  ################### //
  // ############################################################## //

  let edt = [];
  let edc = [];
  const [estDTGlobal, setEstDTGlobal] = useState("");
  const [estDCGlobal, setEstDCGlobal] = useState("");
  const [estDT, setEstDT] = useState([]);
  const [estDC, setEstDC] = useState([]);

  const updateGlobals = () => {
    estDT &&
      getValues() &&
      getValues(`materiais`) &&
      getValues(`materiais`).map((mat) => {
        mat &&
          mat.subMateriais &&
          mat.subMateriais.map((sMat) => {
            if (sMat.estadodt != null) {
              edt.push(sMat.estadodt);
            }
          });
      }) &&
      getValues(`especialidades`).map((esp) => {
        if (esp.estadodt != null) {
          edt.push(esp.estadodt);
        }
      }) &&
      getValues(`equipamentos`).map((esp) => {
        if (esp.estadodt != null) {
          edt.push(esp.estadodt);
        }
      });

    estDC &&
      getValues() &&
      getValues(`materiais`) &&
      getValues(`materiais`).map((mat) => {
        mat &&
          mat.subMateriais &&
          mat.subMateriais.map((sMat) => {
            if (sMat.estadodc != null) {
              edc.push(sMat.estadodc);
            }
          });
      }) &&
      getValues(`especialidades`).map((esp) => {
        if (esp.estadodc != null) {
          edc.push(esp.estadodc);
        }
      }) &&
      getValues(`equipamentos`).map((esp) => {
        if (esp.estadodt != null) {
          edc.push(esp.estadodc);
        }
      });

    if (edt.length > 0) {
      if (edt.some((elem) => elem !== "1")) {
        setEstDTGlobal("2");
        setValue("estadodt", "2");
      } else {
        setEstDTGlobal("1");
        setValue("estadodt", "1");
      }
    } else {
      setEstDTGlobal("0");
      setValue("estadodt", "0");
    }

    if (edc.length > 0) {
      if (edc.some((elem) => elem !== "1")) {
        setEstDCGlobal("2");
        setValue("estadodc", "2");
      } else {
        setEstDCGlobal("1");
        setValue("estadodc", "1");
      }
    } else {
      setEstDCGlobal("0");
      setValue("estadodc", "0");
    }
  };

  // ############################################################## //
  // #####################  Form Edit Submit  ##################### //
  // ############################################################## //

  const onSubmit = (data, e) => {
    e.preventDefault();
    const obraId = obraSelecionada && obraSelecionada.id;
    const especialidades =
      data &&
      data.especialidades.map((elem) => ({
        especialidadesId:
          elem.especialidadesDropdown && elem.especialidadesDropdown.id,
        estadosId: elem.estadosDropdown && elem.estadosDropdown.id,
        empresasId: elem.empresasDropdown && elem.empresasDropdown.id,
        empresasDescricao:
          elem.empresasDropdown && elem.empresasDropdown.descricao,
        // dataInicio: elem.dataInicio,
        // dataFim: elem.dataFim,
        dataInicio:
          elem.dataInicio == null
            ? elem.dataInicio
            : format(new Date(elem.dataInicio), "yyyy-MM-dd"),
        dataFim:
          elem.dataFim == null
            ? elem.dataFim
            : format(new Date(elem.dataFim), "yyyy-MM-dd"),
        observacoes: elem.observacoes,
        estadodt: elem.estadodt,
        estadodc: elem.estadodc,
      }));

    const materiais =
      data &&
      data.materiais.map((elem) => ({
        materiaisId: elem.materiaisId,
        estadosId: elem.estadosDropdown && elem.estadosDropdown.id,
        qtd: elem.qtd,
        observacoes: elem.observacoes,
        subMateriais:
          elem &&
          elem.subMateriais &&
          elem.subMateriais.map((subElem) => ({
            materiaisId: elem.materiaisDropdown && elem.materiaisDropdown.id,
            subMateriaisId:
              subElem.subMateriaisDropdown && subElem.subMateriaisDropdown.id,
            subMateriaisDescricao:
              subElem.subMateriaisDropdown &&
              subElem.subMateriaisDropdown.descricao,
            estadosId: subElem.estadosDropdown && subElem.estadosDropdown.id,
            qtd: subElem.qtd,
            unidadesId: subElem.unidadesDropdown && subElem.unidadesDropdown.id,
            dataInicio:
              subElem.dataInicio == null
                ? subElem.dataInicio
                : format(new Date(subElem.dataInicio), "yyyy-MM-dd"),
            dataFim:
              subElem.dataFim == null
                ? subElem.dataFim
                : format(new Date(subElem.dataFim), "yyyy-MM-dd"),
            observacoes: subElem.observacoes,
            estadodt: subElem.estadodt,
            estadodc: subElem.estadodc,
          })),
      }));

    const equipamentos =
      data &&
      data.equipamentos.map((elem) => ({
        equipamentosId:
          elem.equipamentosDropdown && elem.equipamentosDropdown.id,
        equipamentosDescricao:
          elem.equipamentosDropdown && elem.equipamentosDropdown.descricao,
        estadosId: elem.estadosDropdown && elem.estadosDropdown.id,
        empresasId: elem.empresasDropdown && elem.empresasDropdown.id,
        qtd: elem.qtd,
        dataInicio:
          elem.dataInicio == null
            ? elem.dataInicio
            : format(new Date(elem.dataInicio), "yyyy-MM-dd"),
        dataFim:
          elem.dataFim == null
            ? elem.dataFim
            : format(new Date(elem.dataFim), "yyyy-MM-dd"),
        observacoes: elem.observacoes,
        estadodt: elem.estadodt,
        estadodc: elem.estadodc,
        // subEquipamentos:
        //   elem &&
        //   elem.subEquipamentos &&
        //   elem.subEquipamentos.map((subElem) => ({
        //     equipamentosId: elem.equipamentosDropdown && elem.equipamentosDropdown.id,
        //     subEquipamentosId:
        //       subElem.subEquipamentosDropdown && subElem.subEquipamentosDropdown.id,
        //     subEquipamentosDescricao:
        //       subElem.subEquipamentosDropdown &&
        //       subElem.subEquipamentosDropdown.descricao,
        //     estadosId: subElem.estadosDropdown && subElem.estadosDropdown.id,
        //     qtd: subElem.qtd,
        //     unidadesId: subElem.unidadesDropdown && subElem.unidadesDropdown.id,
        //     dataInicio:
        //       subElem.dataInicio == null
        //         ? subElem.dataInicio
        //         : format(new Date(subElem.dataInicio), "yyyy-MM-dd"),
        //     dataFim:
        //       subElem.dataInicio == null
        //         ? subElem.dataFim
        //         : format(new Date(subElem.dataFim), "yyyy-MM-dd"),
        //     observacoes: subElem.observacoes,
        //     estadodt: subElem.estadodt,
        //     estadodc: subElem.estadodc,
        //   })),
      }));

    const ydata = [
      {
        obrasId: data.obrasId,
        estadosControloId:
          data.estadosControloDropdown && data.estadosControloDropdown.id,
        maoObraContratada: data.maoObraContratada,
        maoObraJaf: data.maoObraJaf,
        especialidades: especialidades,
        materiais: materiais,
        equipamentos: equipamentos,
        observacoes: data.observacoes,
        estadodt: data.estadodt,
        estadodc: data.estadodc,
      },
    ];

    const eProjetosId = estadosProjetoPreSelectedId.map((ep) => ep.id);
    const eSubProjetosId = estadosSubProjetoPreSelectedId.map((ep) => ep.id);
    const aeroportoId = aeroportoPreSelectedId.map((ep) => ep.id);
    const estadosControloId = controloPreSelectedId.map((ep) => ep.id);
    const diretorObraId =
      diretorObraPreSelectedId && diretorObraPreSelectedId.id;
    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!",
      type: "error",
    };
    const message = {
      isOpen: true,
      message: "Obra editada com sucesso!!",
      type: "success",
    };

    setGuardarLoading(true);

    dispatch(
      EditarObra(
        ydata,
        obraId,
        ficheiro,
        toggleDrawer,
        ShowNotification,
        message,
        errorMessage,
        toggleDrawer,
        setGuardarLoading
      )
    )
      .then(() =>
        fetchObras(
          eProjetosId,
          eSubProjetosId,
          aeroportoId,
          estadosControloId,
          diretorObraId
        )
      )
      .then(() => setGuardarLoading(false));
  };

  // const [fotosState, setFotosState] = useState({
  //   right: false,
  // });

  // const toggleDrawerFotos = (anchor, open) => {
  //   if (open == false) {
  //     setFotosState({ ...state, [anchor]: open });
  //   }
  //   if (open == true) {
  //     setFotosState({ ...state, [anchor]: open });
  //   }
  // };

  // ############################################################## //
  // #######################  Send Email  ####################### //
  // ############################################################## //

  const sendEmail = (idwp) => {
    dispatch(
      SendPedidoWorkPermit(
        idwp,
        fetchWorkPermit,
        obra.obrasId,
        ShowNotification,
        emailMessage
      )
    );
  };

  // ############################################################## //
  // ########################  Work Permit  ####################### //
  // ############################################################## //

  const [workPermit, setWorkPermit] = useState({
    numeroWP: null,
    numSeqWP: null,
    estadoWP: null,
    identTrab: null,
    entExec: null,
    entSoli: null,
    descricao: null,
    prorrogacao: null,
    n_wp: null,
    dataInicio: null,
    dataFim: null,
    horasInicio: null,
    horasFim: null,
    fm: null,
    clta: null,
    tcs: null,
    fps: null,
    ume: null,
    veiculos: null,
    localEnt: null,
    localEst: null,
    matriculas: null,
    odores: null,
    poeiras: null,
    sadi: null,
    saca: null,
    avac: null,
    ited: null,
    ie: null,
    pub: null,
    obs: null,
    responsavel: null,
    responsavelAHD: null,
    contactos: null,
    contactosAHD: null,
    enderecos: null,
    assunto: null,
    corpo: null,
  });

  const resetFieldsToNull = () => {
    setWorkPermit({
      numeroWP: null,
      estadoWP: null,
      identTrab: null,
      entExec: null,
      entSoli: null,
      descricao: null,
      prorrogacao: null,
      n_wp: null,
      dataInicio: null,
      dataFim: null,
      horasInicio: null,
      horasFim: null,
      fm: null,
      clta: null,
      tcs: null,
      fps: null,
      ume: null,
      veiculos: null,
      localEnt: null,
      localEst: null,
      matriculas: null,
      odores: null,
      poeiras: null,
      sadi: null,
      saca: null,
      avac: null,
      ited: null,
      ie: null,
      pub: null,
      obs: null,
      responsavel: null,
      responsavelAHD: null,
      contactos: null,
      contactosAHD: null,
      enderecos: null,
      assunto: null,
      corpo: null,
    });
  };

  // ############################################################## //
  // ##################  Formulário Work Permits  ################# //
  // ############################################################## //

  const [wpState, setwpState] = useState({
    right: false,
  });

  const [wpDrawerState, setwpDrawerState] = useState("");

  const toggleDrawerWK = (anchor, open, id, dstate) => {
    if (open == false) {
      setwpState({ ...state, [anchor]: open });
      setwpDrawerState("");
    }
    if (open == true) {
      setwpState({ ...state, [anchor]: open });
      setwpDrawerState(dstate);
      dispatch(GetWorkPermitById(id));
      dispatch(GetWorkPermitUploadedFiles(id));
    }
  };

  const fetchWorkPermit = (idObra) => {
    dispatch(GetObraWorkPermits(idObra));
  };

  useEffect(() => {
    fetchWorkPermit(obra.obrasId);
  }, [obra.obrasId]);

  // ############################################################## //
  // #########################  Fotos  ######################## //
  // ############################################################## //

  const fileFoto = useSelector((state) => state.obras.ficheiros[1]);
  const [fotos, setFotos] = useState([]); // Array to store uploaded files

  const handleFotoChange = (e, foto) => {
    const selectedFotos = e.target.files;

    // Create an array of file objects with additional properties
    const updatedFotos = Array.from(selectedFotos).map((file) => ({
      file,
      idTipo: foto.id_base_obras_files_tipos,
      path: foto.base_obras_files_tipos_path,
    }));

    // Append the new files to the existing files array
    setFotos([...fotos, ...updatedFotos]);
  };

  const handleRemoveFoto = (indexToRemove) => {
    // Filter out the file at the specified index
    const updatedFiles = fotos.filter((_, index) => index !== indexToRemove);

    setFotos(updatedFiles);
  };

  // ############################################################## //
  // ###################  MUI Datatable columns  ################## //
  // ############################################################## //

  const fotosState = useSelector((state) => state.obras.obraFotos);

  const message = {
    isOpen: true,
    message: "Foto eliminada com sucesso!!",
    type: "success",
  };

  const bodyMessage = {
    isOpen: true,
    message: "O corpo de email do permit work permit contém '{}' ",
    type: "warning",
  };

  const warningMessage = {
    isOpen: true,
    message: "Precisa de preencher alguns campos antes de enviar ",
    type: "warning",
  };

  const emailMessage = {
    isOpen: true,
    message: "Email enviado com sucesso!!",
    type: "success",
  };

  const fotosColumns = [
    {
      name: "idObra",
      label: "Nº de Obra",
      options: {
        display: false,
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
      },
    },
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
      },
    },
    {
      name: "name",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "300px" },
        }),
        customBodyRender: (value, tableMeta) => {
          const parts = value.split(".");
          const name = parts[0];
          return <Typography>{name}</Typography>;
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: "center",
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const o = tableMeta.rowData[0];
          const id = tableMeta.rowData[1];
          return (
            <div style={{ display: "flex", float: "right" }}>
              <IconButton
                style={{
                  padding: 6,
                  color: theme.palette.secondary.main,
                }}
                onClick={() => {
                  Instance()
                    .get(`${BASE_URL}/construcoes/obras/${o}/download/${id}`, {
                      responseType: "blob", // Request the response as a blob
                    })
                    .then((response) => {
                      // Create a blob with the file data from the response
                      const blob = new Blob([response.data], {
                        type: "image/jpeg",
                      });

                      // Create a download link
                      const downloadLink = document.createElement("a");
                      downloadLink.href = window.URL.createObjectURL(blob);
                      downloadLink.setAttribute(
                        "download",
                        `${
                          response.headers["content-disposition"].split("=")[1]
                        }`
                      ); // Extract the file name from the Content-Disposition header

                      // Trigger the download
                      downloadLink.click();

                      // Clean up
                      window.URL.revokeObjectURL(downloadLink.href);
                    })
                    .catch((err) => {
                      console.log(err.response);
                      if (err.response) {
                        if (err.response === 403) {
                          // Handle permission denied
                        }
                      }
                    });
                }}
              >
                <FeatherIcon
                  icon="download"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
              <IconButton
                style={{
                  padding: 6,
                  color: theme.palette.secondary.main,
                }}
                onClick={() => {
                  Instance()
                    .get(`${BASE_URL}/construcoes/obras/${o}/watch/${id}`, {
                      responseType: "blob",
                    })
                    .then((response) => {
                      // Get the content type from the response headers
                      const contentType = response.headers["content-type"];

                      // Create a blob from the response data
                      const blob = new Blob([response.data], {
                        type: contentType,
                      });

                      // Create a URL for the blob data
                      const fileUrl = URL.createObjectURL(blob);

                      // Open the file in a new window
                      window.open(fileUrl, "_blank");
                    })
                    .catch((err) => {
                      console.log(err.response);
                      if (err.response) {
                        if (err.response === 403) {
                          // dispatch(ShowNotification(errorMessage));
                        }
                      }
                    });
                }}
              >
                <FeatherIcon
                  icon="eye"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
              <IconButton
                style={{
                  padding: 6,
                  color: theme.palette.secondary.main,
                }}
                onClick={() => {
                  dispatch(DeleteFotoObra(id)).then(() => {
                    dispatch(ShowNotification(message));
                    setFotos([]);
                    dispatch(GetObrasFotos(o));
                    dispatch(GetObra(o));
                  });
                }}
              >
                <FeatherIcon
                  icon="trash-2"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
            </div>
          );
        },
      },
    },
    {
      name: "numOrcamentos",
      label: "numOrcamentos",
      options: {
        display: false,
      },
    },
  ];

  // ############################################################## //
  // ###################  MUI Datatable options  ################## //
  // ############################################################## //

  const fotosOptions = {
    filter: false,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    // setRowProps: (row, dataIndex, rowIndex) => {
    //   return {
    //     style:
    //       rowIndex % 2
    //         ? { backgroundColor: 'transparent' }
    //         : { backgroundColor: '#F3F5F8' },
    //   };
    // },
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },
  };

  // ############################################################## //
  // #######################  Submit Fotos  ####################### //
  // ############################################################## //

  const obraId = obra && obra.obrasId;

  const onSubmitFotos = (e) => {
    e.preventDefault();

    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!",
      type: "error",
    };
    const message = {
      isOpen: true,
      message: "Orçamentos editada com sucesso!!",
      type: "success",
    };

    setGuardarFotosLoading(true);

    dispatch(
      InserirFotos(fotos, obraId, ShowNotification, message, errorMessage)
    ).then(() => {
      setGuardarFotosLoading(false);
      setFotos([]);
      dispatch(GetObrasFotos(obraId));
      dispatch(GetObra(obraId));
    });
  };

  // ############################################################## //
  // #########################  UseEffect  ######################## //
  // ############################################################## //

  const fetchobraFotos = (obraId) => {
    dispatch(GetObrasFotos(obraId));
  };

  useEffect(() => {
    fetchobraFotos(obraId);
  }, []);

  return (
    <>
      {obraSelecionada && Object.keys(obraSelecionada).length > 0 ? (
        <>
          <DrawerWorkPermit
            state={wpState}
            drawerState={wpDrawerState}
            toggleDrawer={toggleDrawerWK}
            classes={classes}
            workPermit={workPermit}
            setWorkPermit={setWorkPermit}
            fetchWorkPermit={fetchWorkPermit}
            resetFieldsToNull={resetFieldsToNull}
          />
          <ModalDeleteWorkPermit
            openDelete={openDelete}
            handleClose={handleClose}
            workPermitId={workPermitId}
            fetchWorkPermits={fetchWorkPermit}
            obraId={obra.obrasId}
            // show={show}
            // Loading
            loading={loading}
            setLoading={setLoading}
          />
          {/* <DrawerFotos
            state={fotosState}
            toggleDrawer={toggleDrawerFotos}
            ficheirosState={ficheirosState}
          /> */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "40px",
              background: "#FAFAFA",
              height: "100%",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={classes.drawerHeader}>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => toggleDrawer("right", false)}
                >
                  <div>
                    <FeatherIcon
                      icon="chevron-left"
                      size={20}
                      style={{ strokeWidth: "1.5" }}
                    />
                  </div>
                  <div className="text-underline-close-drawer">
                    Lista de Obras
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {obraSelecionada && obraSelecionada.obrasId}
                  <span
                    style={{
                      background: "#D6DFE8",
                      height: "30px",
                      width: "2px",
                      margin: "0px 15px",
                    }}
                  />
                  {obraSelecionada && obraSelecionada.descricao}
                  <span
                    style={{
                      background: "#D6DFE8",
                      height: "30px",
                      width: "2px",
                      margin: "0px 15px",
                    }}
                  />
                  {obraSelecionada && obraSelecionada.diretorNome
                    ? obraSelecionada.diretorNome
                    : obraSelecionada.responsavelNome}
                </div>

                {user && user.permissoes.includes("APP_CONSTRUCOES_EDITAR") ? (
                  <>
                    {!guardarLoading ? (
                      <Button className={classes.btnGuardar} type="submit">
                        Guardar
                      </Button>
                    ) : (
                      <LoadingButton
                        color="secondary"
                        loading={guardarLoading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        type="submit"
                        className={classes.btnGuardar}
                      >
                        Guardar
                      </LoadingButton>
                    )}
                  </>
                ) : (
                  <div style={{ width: "40px" }} />
                )}
              </div>
              <TabContext value={tabsvalue}>
                <Paper sx={{ px: 6, py: 2, mt: 4 }}>
                  {/* <h4
                    style={{
                      fontSize: "22px",
                      width: "100%",
                      marginBottom: "0px",
                    }}
                    className="text-underline"
                  >
                    Caracterização da obra
                  </h4> */}
                  <Box>
                    <TabList
                      onChange={handleTabChange}
                      aria-label="lab API tabs example"
                      variant="scrollable"
                      textColor="secondary"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#E21450",
                        },
                      }}
                    >
                      <Tab
                        label="Caracterização da obra"
                        value="1"
                        className={classes.tab}
                      />
                      {user &&
                      user.permissoes.includes(
                        "APP_CONSTRUCOES_CONSULTAR_WORK_PERMITS"
                      ) ? (
                        <Tab
                          label="Work Permits"
                          value="2"
                          className={classes.tab}
                        />
                      ) : null}
                      {/* <Tab
                        label="Credenciações"
                        value="3"
                        className={classes.tab}
                      /> */}
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    {/* <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Informacao
                  obraSelecionada={obraSelecionada}
                  control={control}
                />
                <Controlo obraSelecionada={obraSelecionada} control={control} />
              </div> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        marginTop: "20px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <InformacaoTeste
                          obraSelecionada={obraSelecionada}
                          control={control}
                        />
                        <Semaforizacao
                          obraSelecionada={obraSelecionada}
                          control={control}
                          renderSwitch={renderSwitch}
                          estDTGlobal={estDTGlobal}
                          estDCGlobal={estDCGlobal}
                          getValues={getValues}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Estados
                          obraSelecionada={obraSelecionada}
                          control={control}
                        />
                        <MaoDeObra
                          obraSelecionada={obraSelecionada}
                          control={control}
                        />
                        <div style={{ width: "19%" }}>
                          <>
                            <h4
                              style={{ color: "#E21450" }}
                              className="text-underline-title-drawer "
                            >
                              {orcamento.base_obras_files_tipos_nome}
                            </h4>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                              }}
                            >
                              {orcamento.base_obras_files_tipos_nome}
                            </Typography>
                            <Box sx={{ display: "flex" }}>
                              {/* <Button
                                sx={{
                                  height: "40px",
                                  width: "200px",
                                  borderRadius: "5px",
                                  mr: 2,
                                }}
                                className={classes.btnUpload}
                                onClick={() => toggleDrawerOrca("right", true)}
                              >
                                Ver orçamentos
                              </Button> */}
                              {filesLength > 0 && (
                                <Button
                                  sx={{
                                    height: "40px",
                                    borderRadius: "5px",
                                    mr: 1,
                                  }}
                                  className={classes.btnUpload}
                                  onClick={() => {
                                    Instance()
                                      .get(
                                        `${BASE_URL}/construcoes/obras/${obraSelecionada.obrasId}/download`,
                                        {
                                          responseType: "blob",
                                        }
                                      )
                                      .then(({ data }) => {
                                        const downloadUrl =
                                          window.URL.createObjectURL(
                                            new Blob([data])
                                          );

                                        const link =
                                          document.createElement("a");

                                        link.href = downloadUrl;

                                        link.setAttribute(
                                          "download",
                                          `obra_${obraSelecionada.obrasId}_orcamentos.zip`
                                        ); //any other extension

                                        document.body.appendChild(link);

                                        link.click();
                                        link.remove();
                                        // dispatch(ShowNotification(message));
                                      })
                                      .catch((err) => {
                                        console.log(err.response);
                                        if (err.response) {
                                          if (err.response === 403) {
                                            // dispatch(ShowNotification(errorMessage));
                                          }
                                        }
                                      });
                                  }}
                                >
                                  <FeatherIcon icon="download" size={20} />
                                </Button>
                              )}
                              <Button
                                sx={{
                                  height: "40px",
                                  width: "150px",
                                  borderRadius: "5px",
                                  mr: 2,
                                }}
                                onChange={(e) => handleFileChange(e, orcamento)}
                                className={classes.btnUpload}
                              >
                                <label
                                  htmlFor={
                                    orcamento.base_obras_files_tipos_nome_input
                                  }
                                  className="custom-file-upload"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "start",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <p>Carregar</p>
                                  </div>
                                </label>
                                <input
                                  type="file"
                                  name={
                                    orcamento.base_obras_files_tipos_nome_input
                                  }
                                  id={
                                    orcamento.base_obras_files_tipos_nome_input
                                  }
                                  hidden
                                  accept="image/jpeg, .pdf"
                                />
                              </Button>
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                }}
                              >
                                {ficheiro && (
                                  <div
                                    style={{
                                      marginRight: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton
                                      onClick={(e) => handleRemoveFile()}
                                    >
                                      <FeatherIcon
                                        icon="trash-2"
                                        size={"1rem"}
                                      />
                                    </IconButton>
                                  </div>
                                )}
                              </div>
                            </Box>
                          </>
                        </div>
                        <Controlo
                          obraSelecionada={obraSelecionada}
                          control={control}
                        />
                      </div>
                      <div
                        style={{
                          marginTop: "30px",
                        }}
                      />
                      <Materiais
                        obraSelecionada={obraSelecionada}
                        setObraSelecionada={setObraSelecionada}
                        control={control}
                        classes={classes}
                        reset={reset}
                        errors={errors}
                        resetForm={resetForm}
                        setResetForm={setResetForm}
                        setValue={setValue}
                        getValues={getValues}
                        updateGlobals={updateGlobals}
                        renderSwitch={renderSwitch}
                        setEstDT={setEstDT}
                        setEstDC={setEstDC}
                      />

                      <div
                        style={{
                          marginTop: "30px",
                        }}
                      />
                      <Especialidades
                        obraSelecionada={obraSelecionada}
                        setObraSelecionada={setObraSelecionada}
                        control={control}
                        theme={theme}
                        reset={reset}
                        errors={errors}
                        resetForm={resetForm}
                        setResetForm={setResetForm}
                        setValue={setValue}
                        getValues={getValues}
                        updateGlobals={updateGlobals}
                        renderSwitch={renderSwitch}
                        setEstDT={setEstDT}
                        setEstDC={setEstDC}
                      />

                      <div
                        style={{
                          marginTop: "30px",
                        }}
                      />
                      <Equipamentos
                        obraSelecionada={obraSelecionada}
                        setObraSelecionada={setObraSelecionada}
                        control={control}
                        classes={classes}
                        reset={reset}
                        errors={errors}
                        resetForm={resetForm}
                        setResetForm={setResetForm}
                        setValue={setValue}
                        getValues={getValues}
                        updateGlobals={updateGlobals}
                        renderSwitch={renderSwitch}
                        setEstDT={setEstDT}
                        setEstDC={setEstDC}
                      />
                      <Box>
                        <h4
                          style={{ color: "#E21450" }}
                          className="text-underline-title-drawer "
                        >
                          Fotos
                        </h4>
                        {/* <Button
                            sx={{
                              height: "40px",
                              width: "200px",
                              borderRadius: "5px",
                              mr: 2,
                            }}
                            className={classes.btnUpload}
                            onClick={() => toggleDrawerFotos("right", true)}
                          >
                            Ver Fotos
                          </Button> */}

                        <Box
                          sx={{
                            display: "flex",
                            mt: 3,
                            flexDirection: "column",
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            {fotosLength > 0 && (
                              <Button
                                sx={{
                                  height: "40px",
                                  borderRadius: "5px",
                                  mr: 1,
                                }}
                                className={classes.btnUpload}
                                onClick={() => {
                                  Instance()
                                    .get(
                                      `${BASE_URL}/construcoes/obras/${obraSelecionada.obrasId}/fotos/download`,
                                      {
                                        responseType: "blob",
                                      }
                                    )
                                    .then(({ data }) => {
                                      const downloadUrl =
                                        window.URL.createObjectURL(
                                          new Blob([data])
                                        );

                                      const link = document.createElement("a");

                                      link.href = downloadUrl;

                                      link.setAttribute(
                                        "download",
                                        `obra_${obraSelecionada.obrasId}_fotos.zip`
                                      ); //any other extension

                                      document.body.appendChild(link);

                                      link.click();
                                      link.remove();
                                      // dispatch(ShowNotification(message));
                                    })
                                    .catch((err) => {
                                      console.log(err.response);
                                      if (err.response) {
                                        if (err.response === 403) {
                                          // dispatch(ShowNotification(errorMessage));
                                        }
                                      }
                                    });
                                }}
                              >
                                <FeatherIcon icon="download" size={20} />
                              </Button>
                            )}
                            <Button
                              sx={{
                                height: "40px",
                                width: "200px",
                                borderRadius: "5px",
                                mr: 2,
                              }}
                              onChange={(e) => handleFotoChange(e, fileFoto)}
                              className={classes.btnUpload}
                            >
                              <label
                                htmlFor={
                                  fileFoto.base_obras_files_tipos_nome_input
                                }
                                className="custom-file-upload"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    cursor: "pointer",
                                  }}
                                >
                                  <p>Carregar Foto</p>
                                </div>
                              </label>
                              <input
                                type="file"
                                name={
                                  fileFoto.base_obras_files_tipos_nome_input
                                }
                                id={fileFoto.base_obras_files_tipos_nome_input}
                                hidden
                                accept="image/jpeg"
                                multiple
                              />
                            </Button>
                            {!guardarFotosLoading ? (
                              <Button
                                sx={{
                                  color: "white",
                                  width: "200px",
                                  height: "40px",
                                  // borderRadius: '15px',
                                  backgroundColor: "#E21450",
                                  border: `1px solid #E21450`,
                                  display: "flex",
                                  alignItems: "center",
                                  transition: "0.2s",
                                  "&:hover": {
                                    backgroundColor: "#bc1041",
                                    border: "1px solid #bc1041",
                                    // Reset on touch devices, it doesn't add specificity
                                    "@media (hover: none)": {
                                      backgroundColor: "#bc1041",
                                    },
                                    "&:disabled": {
                                      backgroundColor: "#bc1041",
                                    },
                                  },
                                }}
                                type="submit"
                                onClick={onSubmitFotos}
                              >
                                Guardar Fotos
                              </Button>
                            ) : (
                              <LoadingButton
                                color="secondary"
                                loading={guardarLoading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                type="submit"
                                sx={{
                                  color: "white",
                                  width: "200px",
                                  height: "40px",
                                  // borderRadius: '15px',
                                  backgroundColor: "#E21450",
                                  border: `1px solid #E21450`,
                                  display: "flex",
                                  alignItems: "center",
                                  transition: "0.2s",
                                  "&:hover": {
                                    backgroundColor: "#bc1041",
                                    border: "1px solid #bc1041",
                                    // Reset on touch devices, it doesn't add specificity
                                    "@media (hover: none)": {
                                      backgroundColor: "#bc1041",
                                    },
                                    "&:disabled": {
                                      backgroundColor: "#bc1041",
                                    },
                                  },
                                }}
                              >
                                Guardar Fotos
                              </LoadingButton>
                            )}
                          </Box>
                          <div
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            {fotos.map((ficheiro, index) => (
                              <div
                                style={{
                                  marginRight: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {ficheiro.file.name}
                                <IconButton
                                  onClick={(e) => handleRemoveFoto(index)}
                                >
                                  <FeatherIcon icon="trash-2" size={"1rem"} />
                                </IconButton>
                              </div>
                            ))}
                          </div>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                          <MUIDataTable
                            title={
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                                className="text-underline"
                              >
                                Lista de fotos
                              </span>
                            }
                            data={fotosState || []}
                            columns={fotosColumns}
                            options={fotosOptions}
                          />
                        </Box>
                      </Box>

                      <div
                        style={{
                          marginTop: "30px",
                        }}
                      />
                      <Observacoes
                        obraSelecionada={obraSelecionada}
                        setObraSelecionada={setObraSelecionada}
                        control={control}
                        classes={classes}
                      />
                    </div>
                  </TabPanel>
                  {user &&
                  user.permissoes.includes(
                    "APP_CONSTRUCOES_CONSULTAR_WORK_PERMITS"
                  ) ? (
                    <TabPanel value="2">
                      {user &&
                      user.permissoes.includes(
                        "APP_CONSTRUCOES_CRIAR_WORK_PERMITS"
                      ) ? (
                        <Button
                          onClick={() =>
                            toggleDrawerWK("right", true, null, "add")
                          }
                          variant="contained"
                          sx={{ width: "250px", backgroundColor: "#E21450" }}
                          endIcon={<FeatherIcon icon="plus" />}
                        >
                          Pedido Work Permit
                        </Button>
                      ) : null}
                      <MUIDataTable
                        title={
                          <span
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                            className="text-underline"
                          >
                            Lista de Pedidos de Work Permits
                          </span>
                        }
                        data={workpermits || []}
                        columns={columns}
                        options={options}
                      />
                    </TabPanel>
                  ) : null}
                  {/* <TabPanel value="3">Credenciações</TabPanel> */}
                </Paper>
              </TabContext>
            </form>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export default DrawerForm;
