/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Carousel from 'react-material-ui-carousel';
import { Button, IconButton } from '@mui/material';
import { FiTrash2 } from 'react-icons/fi';
import Instance from '../../../../../../utils/Axios';
import BASE_URL from '../../../../../../utils/BASE_URL';

//Icons
import FeatherIcon from 'feather-icons-react';


function Item(props) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '98%',
        minHeight: '200px',
      }}
    >
      <img
        src={props.item.image}
        alt=''
        style={{
          marginTop: '10px',
          width: '95%',
          borderRadius: '6px',
          boxShadow: '0 0 10px #ebebeb ',
        }}
      />
    </div>
  );
}

const ImageUploader = ({
  classes,
  images,
  setImages,
  intervencaoSelecionada,
  newImages,
  setNewImages,
  removeImages,
  setRemoveImages,
  //Errors
  message,
  errorMessage,
  fileWarning,
  ShowNotification,
  //Component
  consultar,
}) => {
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState(false);

  useEffect(() => {
    if (
      intervencaoSelecionada &&
      intervencaoSelecionada.images &&
      intervencaoSelecionada.images.length > 0
    ) {
      const images = intervencaoSelecionada.images;
      setImages(images);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageChange = (e) => {
    const values = [];

    for (let i = 0; i < e.target.files.length; i++) {
      const element = e.target.files[i];
      const imgRepetida = images.find((obj) => obj.nome === element.name);
      const newImgRepetida = newImages.find((obj) => obj.name === element.name);

      console.log(images);

      if (imgRepetida != null || newImgRepetida != null) {
        dispatch(ShowNotification(fileWarning));
      } else {
        values.push(element);
      }
    }

    setNewImages(newImages.concat(values));

    setImagePreview(false);
  };
  const handleRemoveNewImage = (e, objImagem) => {
    e.preventDefault();

    setNewImages(
      newImages.filter(function (img) {
        return img.name !== objImagem.name;
      })
    );
  };

  const handleRemoveImg = (e, objImagem) => {
    setImages(
      images.filter(function (img) {
        return img.id !== objImagem.id;
      })
    );

    setRemoveImages((current) => [...current, objImagem.id]);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          // marginTop: '20px',
          flexDirection: "column",
          marginBottom: "20px",
          alignItems: "flex-start",
        }}
      >
        <Button
          style={{ height: "40px", borderRadius: "5px", margin: "10px 0px" }}
          className={classes.btnUpload}
        >
          <label htmlFor="imagens" className="custom-file-upload">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                cursor: "pointer",
              }}
            >
              <p style={{ margin: "0px 10px" }}> Carregar Imagens</p>
            </div>
          </label>
          <input
            type="file"
            name="imagens"
            id="imagens"
            hidden
            onChange={(e) => handleImageChange(e)}
            multiple
            accept=".pdf, .jpg"
          />
        </Button>

        <div>
          {images &&
            images.map((img, index) => {
              return (
                <div style={{ marginRight: "10px" }} key={index}>
                  <IconButton onClick={(e) => handleRemoveImg(e, img)}>
                    <FiTrash2 size={"1rem"} />
                  </IconButton>
                  {img.nome}
                </div>
              );
            })}

          {newImages &&
            newImages.map((img, index) => {
              return (
                <div
                  style={{
                    marginRight: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  key={index}
                >
                  <IconButton onClick={(e) => handleRemoveNewImage(e, img)}>
                    <FiTrash2 size={"1rem"} />
                  </IconButton>

                  <p style={{ margin: 0, wordBreak: "break-all" }}>
                    {img.name}
                  </p>
                </div>
              );
            })}

          {(newImages && newImages.length > 0) ||
          (images && images.length <= 0) ? null : (
            <>
              <Button
                style={{
                  color: "#707070",
                }}
                onClick={() => {
                  Instance()
                    .get(
                      `${BASE_URL}/intervencoes/intervencoes/${intervencaoSelecionada.id}/imgdownload`,
                      { responseType: "blob" }
                    )
                    .then(({ data }) => {
                      const downloadUrl = window.URL.createObjectURL(
                        new Blob([data])
                      );

                      const link = document.createElement("a");

                      link.href = downloadUrl;

                      link.setAttribute(
                        "download",
                        `intervencao_${intervencaoSelecionada.id}_imagens.zip`
                      ); //any other extension

                      document.body.appendChild(link);

                      link.click();

                      link.remove();
                      dispatch(ShowNotification(message));
                    })
                    .catch((err) => {
                      console.log(err.response);
                      if (err.response) {
                        if (err.response === 403) {
                          dispatch(ShowNotification(errorMessage));
                        }
                      }
                    });
                }}
              >
                <FeatherIcon
                  icon="download"
                  size={18}
                  style={{
                    strokeWidth: "1.5",
                    marginRight: "10px",
                    marginLeft: "-1px",
                  }}
                />
                Download
              </Button>

              <Button
                onClick={() => setImagePreview(!imagePreview)}
                style={{
                  color: "#707070",
                }}
              >
                <FeatherIcon
                  icon="download"
                  size={18}
                  style={{
                    strokeWidth: "1.5",
                    marginRight: "10px",
                    marginLeft: "-1px",
                  }}
                />
                Preview
              </Button>
            </>
          )}
        </div>

        {/* {images && images.map((img) => img.name)} */}
      </div>

      {imagePreview == true ? (
        <Carousel navButtonsAlwaysVisible={true} autoPlay={false}>
          {images.map((item, i) => (
            <>
              <Item key={i} item={item} />
            </>
          ))}
        </Carousel>
      ) : null}
    </>
  );
};

export default ImageUploader;
