/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import {
  Typography,
  Box,
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import moment from "moment/moment";

// Components
import image from "../../../../../assets/images/error404.jpg";
import FooterLinks from "../../../../../Global/Components/Footer";

//Icons
import FeatherIcon from "feather-icons-react";

//Actions
import GetArtigoById from "../../../../../utils/redux/actions/energias/blog/getArtigoById";
import GetTodosArtigos from "../../../../../utils/redux/actions/energias/blog/getTodosArtigos";

// ############################################################## //
// #####################  Window Dimensions  #################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const useStyles = makeStyles(() => ({
  verMais: {
    color: "#333333",
    "&:hover": {
      color: "#E21450",
    },
  },
  imgBack: {
    height: "450px",
    backgroundImage: `url(${image})`,
  },
}));

const BlogIndividual = () => {
  window.scrollTo(0, 0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dimensions = useWindowDimensions();
  const { id } = useParams();

  // ############################################################## //
  // #########################  Card Width  ####################### //
  // ############################################################## //

  let cardWidth = "23.5%";
  let spacing = "1.5%";
  if (dimensions.width <= 1500 && dimensions.width > 1150) {
    cardWidth = "30%";
  } else if (dimensions.width <= 1150 && dimensions.width > 800) {
    cardWidth = "48.5%";
  } else if (dimensions.width <= 800) {
    cardWidth = "100%";
    spacing = "0%";
  }

  const artigo = useSelector((state) => state.artigos.artigoSelecionado);
  moment().locale("pt");
  const date = moment(artigo.date).format("LL");

  const fetchArtigoSelecionado = (id) => {
    dispatch(GetArtigoById(id));
  };

  const artigoId = parseInt(id);

  const artigos = useSelector((state) => state.artigos.artigos);

  const artigosFiltrados = artigos.filter((artigo) => artigo.id !== artigoId);

  const refreshPage = () => {
    window.location.reload(false);
  };

  const fetchArtigos = () => {
    dispatch(GetTodosArtigos());
  };

  useEffect(() => {
    fetchArtigoSelecionado(id);
    fetchArtigos();
  }, [id]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              height: "500px",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundImage: `url(${
                artigo.yoast_head_json && artigo.yoast_head_json.og_image[0].url
              })`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                mx: 3,
              }}
              onClick={() => navigate(`/energias/blog/`)}
            >
              <Box sx={{ display: "flex", alignItems: "center", mt: 5 }}>
                <Box>
                  <FeatherIcon
                    icon="chevron-left"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </Box>
                <Box className="text-underline-close-drawer">Voltar</Box>
              </Box>
            </Box>
            <Box
              sx={{
                height: "70%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 35,
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {artigo.title && artigo.title.rendered}
                </Typography>
                <Typography sx={{ fontSize: 12, color: "white" }}>
                  {date}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", backgroundColor: "#FAFAFA" }}>
            <Box sx={{ width: dimensions.width < 1300 ? 0 : "33%" }}></Box>
            <Box
              sx={{
                width: dimensions.width < 1300 ? "100%" : "33%",
                my: 5,
                mx: dimensions.width < 1300 ? 3 : 0,
              }}
            >
              <Typography
                sx={{ fontSize: 18 }}
                dangerouslySetInnerHTML={{
                  __html: artigo.content && artigo.content.rendered,
                }}
              />
            </Box>
            <Box sx={{ width: dimensions.width < 1300 ? 0 : "33%" }}></Box>
          </Box>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box>
                <h4
                  style={{
                    fontSize: "22px",
                    width: "100%",
                  }}
                  className="text-underline"
                >
                  Artigos Recentes
                </h4>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {artigosFiltrados.slice(0, 3).map((item) => (
                <Card
                  sx={{
                    mb: 5,
                    mx: 2,
                    boxShadow: "0px 5px 15px lightgrey",
                    width: cardWidth,
                    borderRadius: 2,
                  }}
                >
                  <CardMedia
                    component="img"
                    height="200"
                    image={
                      item.yoast_head_json &&
                      item.yoast_head_json.og_image[0].url
                    }
                  />
                  <CardContent sx={{ p: 3 }}>
                    <Typography />
                    <Typography gutterBottom sx={{ fontSize: 12 }}>
                      {moment(item.date).format("DD.MM.YYYY")}
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{ fontSize: 15, fontWeight: "bold" }}
                      dangerouslySetInnerHTML={{
                        __html: item.title.rendered,
                      }}
                    />
                    <Typography
                      sx={{ fontSize: 15, color: "#707070" }}
                      dangerouslySetInnerHTML={{
                        __html: item.excerpt.rendered,
                      }}
                    />
                  </CardContent>
                  <CardActions sx={{ p: 3 }}>
                    <Link to={"/energias/blog/" + item.id}>
                      <Button
                        size="small"
                        className={classes.verMais}
                        // onClick={() => {
                        //   navigate(`/energias/blog/${item.id}`);
                        // }}
                      >
                        Saber mais
                      </Button>
                    </Link>
                  </CardActions>
                </Card>
              ))}
            </Box>
          </Box>
          {/* Footer */}
          <div style={{ margin: "20px 40px" }}>
            <FooterLinks />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default BlogIndividual;
