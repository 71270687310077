import React from 'react';
import { TextField, Typography } from '@mui/material';
import { format } from 'date-fns';

const CalendarioControlled = ({ intervencaoSelecionada }) => {
  const dataPedido =
    intervencaoSelecionada &&
    format(new Date(intervencaoSelecionada.pedidoData), 'dd/MM/yyyy');
  const dataCriacao =
    intervencaoSelecionada &&
    format(new Date(intervencaoSelecionada.dataCriacao), 'dd/MM/yyyy');
  const dataEntrega =
    intervencaoSelecionada && intervencaoSelecionada.dataEntrega
      ? format(new Date(intervencaoSelecionada.dataEntrega), 'dd/MM/yyyy')
      : '--/--/----';
  const dataProposta =
    intervencaoSelecionada && intervencaoSelecionada.propostaData
      ? format(new Date(intervencaoSelecionada.propostaData), 'dd/MM/yyyy')
      : '--/--/----';
  return (
    <>
      <div>
        <h4
          style={{ color: '#E21450' }}
          className='text-underline-title-drawer '
        >
          Calendário
        </h4>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ width: '160px', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Data do Pedido
            </Typography>
            <TextField
              size='small'
              value={dataPedido || ' --/--/---- '}
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <span style={{ width: '24px' }} />
          <div style={{ width: '160px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Idade do Pedido
            </Typography>

            <TextField
              size='small'
              value={
                (intervencaoSelecionada &&
                  intervencaoSelecionada.pedidoIdade) ||
                ' - '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <span style={{ width: '24px' }} />
          <div style={{ width: '160px', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Data da Proposta
            </Typography>
            <TextField
              size='small'
              value={dataProposta || ' --/--/---- '}
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <span style={{ width: '24px' }} />
          <div style={{ width: '160px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Idade da Proposta
            </Typography>
            <TextField
              size='small'
              value={
                (intervencaoSelecionada &&
                  intervencaoSelecionada.propostaIdade) ||
                ' - '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <span style={{ width: '24px' }} />
          <div style={{ width: '160px', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Data de Criação
            </Typography>

            <TextField
              size='small'
              value={dataCriacao || ' --/--/---- '}
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <span style={{ width: '24px' }} />
          <div style={{ width: '160px', marginBottom: '16px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Data de Entrega
            </Typography>
            <TextField
              size='small'
              value={dataEntrega || ' --/--/---- '}
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
        </div>
        {/* <div style={{ display: 'flex' }}></div> */}
      </div>
    </>
  );
};

export default CalendarioControlled;
