import axios from 'axios';
import BASE_URL from '../../../BASE_URL';

const RecuperarSenha =
  // eslint-disable-next-line no-unused-vars


    (
      ydata,
      navigate,
      counter,
      setCounter,
      ShowNotification,
      message,
      errorMessage
    ) =>
    async (dispatch) => {
      console.log(ydata);
      console.log(counter);

      await axios
        .post(`${BASE_URL}/utilizadores/recuperarsenha`, { ydata })
        .then(({ data }) => {
          console.log(data);
          dispatch(ShowNotification(message));
          setCounter({
            ...counter,
            running: true,
          });
        })

        .catch((err) => {
          if (err.response) {
            if (err.response.status === 403) {
              const warningMessage = {
                isOpen: true,
                message: err.response.data.message,
                type: 'warning',
              };

              dispatch(ShowNotification(warningMessage));
            } else {
              dispatch(ShowNotification(errorMessage));
            }
          }
        });
    };
export default RecuperarSenha;
