import Instance from "../../../Axios";

// New Actions for the app

const AddPlusAdesao = (yData) => async (dispatch) => {
  await Instance()
    .post(`/plus/adesoes/`, { yData })
    .then(({ data }) => {
      dispatch({ type: "ADD_PLUS_ADESAO", payload: data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default AddPlusAdesao;
