import React from "react";

// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { useDispatch } from "react-redux";

//ACTIONS
import AddDocumentoOficial from "../../../../../utils/redux/actions/energias/documentosOficiais/AddDocumentoOficial";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnUpload: {
    color: "#E21450",
    // width: '125px',
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
});

const FormAdd = ({
  toggleDrawer,
  drawerWidth,
  dimensions,
  documento,
  setDocumento,
  fetchDocumentosOficiais,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // FICHEIROS
  const [file, setFile] = useState([]);

  const handleFilesChange = (e) => {
    const values = [];

    for (let i = 0; i < e.target.files.length; i++) {
      const element = e.target.files[i];
      const newObjRepetido = file.find((obj) => obj.name === element.name);

      if (newObjRepetido != null) {
        console.log("Ficheiro repetido");
      } else {
        values.push(element);
      }
    }

    setFile(file.concat(values));
  };

  const handleRemoveFile = (e, filName) => {
    e.preventDefault();

    setFile(
      file.filter(function (file) {
        return file.name !== filName.name;
      })
    );
  };

  const handleChange = (e) => {
    e.preventDefault();
    setDocumento({
      ...documento,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        descricao: documento.descricao,
        versao: documento.versao,
      },
    ];
    dispatch(AddDocumentoOficial(ydata, file))
      .then(() => toggleDrawer("right", false))
      .then(() => fetchDocumentosOficiais());
  };

  return (
    <div
      className={classes.list}
      style={
        dimensions.width <= 1100
          ? { width: "60vw" }
          : { width: `${drawerWidth}` }
      }
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 5,
        }}
        onClick={() => toggleDrawer("right", false)}
      >
        <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
          <div>
            <FeatherIcon
              icon="chevron-left"
              size={20}
              style={{ strokeWidth: "1.5" }}
            />
          </div>
          <div className="text-underline-close-drawer">Documentos Oficiais</div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box>
      </Box>
      <Card sx={{ m: 5, p: 2, boxShadow: "0px 10px 15px lightgrey" }}>
        <Box
          sx={{
            m: 2,
          }}
        >
          <h4
            style={{
              fontSize: "22px",
              width: "100%",
              marginBottom: "0px",
            }}
            className="text-underline"
          >
            Documentos Oficiais
          </h4>
        </Box>
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Detalhes
          </h4>
        </Box>

        <Box sx={{ m: 2 }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Nome
          </Typography>
          <TextField
            name="descricao"
            size="small"
            variant="outlined"
            onChange={handleChange}
            style={{ width: "100%" }}
          />
        </Box>
        <Box sx={{ m: 2 }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Versão
          </Typography>
          <TextField
            name="versao"
            size="small"
            variant="outlined"
            onChange={handleChange}
            style={{ width: "100%" }}
          />
        </Box>
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Documento
          </h4>
        </Box>
        <Box sx={{ m: 2 }}>
          <Button
            style={{ height: "40px", borderRadius: "5px", margin: "10px 0px" }}
            className={classes.btnUpload}
          >
            <label htmlFor="propostas" className="custom-file-upload">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  cursor: "pointer",
                }}
              >
                <p style={{ margin: "0px 10px" }}> Carregar Ficheiros</p>
              </div>
            </label>
            <input
              type="file"
              name="propostas"
              id="propostas"
              hidden
              onChange={(e) => handleFilesChange(e)}
              accept='.pdf'
            />
          </Button>

          <div style={{ width: "100%" }}>
            {file &&
              file.map((file, index) => {
                return (
                  <div
                    style={{
                      marginRight: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    key={index}
                  >
                    <IconButton onClick={(e) => handleRemoveFile(e, file)}>
                      <FeatherIcon icon="trash-2" size={"1rem"} />
                    </IconButton>
                    {file.name}
                  </div>
                );
              })}
          </div>
        </Box>
      </Card>
    </div>
  );
};

export default FormAdd;
