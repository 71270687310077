import Instance from '../../../../Axios';

const DashboardEstadosObras = () => async (dispatch) => {
  await Instance()
    .get(`/dashboard/grObrasEstados`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({ type: 'GET_DASHBOARD_ESTADOS_OBRAS', payload: data.yData });

      //   show('success', 'Foram associados novos funcionários á obra');
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 403) {
          // dispatch(ShowNotification(warningMessage));
        } else {
          // dispatch(ShowNotification(errorMessage));
        }
      }
    });
};

export default DashboardEstadosObras;
