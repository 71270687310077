import Instance from "../../../../Axios";

// New Actions for the app

const GetCredFormCertificado = () => async (dispatch) => {
  await Instance()
    .get(`/credenciacoes/certificado`)
    .then(({ data }) => {
      dispatch({
        type: "GET_CRED_CERTIFICADO",
        payload: data.yData[0],
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetCredFormCertificado;
