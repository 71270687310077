/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";

// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptLocale from "date-fns/locale/pt";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import Instance from "../../../../../utils/Axios";
import BASE_URL from "../../../../../utils/BASE_URL";
import fileDownload from "js-file-download";
import ModalRascunho from "./modalRascunho";

//ACTIONS
import EditCredenciacao from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/EditCredenciacaoAction";
import GetCredFuncionarios from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionariosAction";
import GetCredCopiaCredenciacaoUploaded from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredCopiaCredenciacaoUploadedAction";
import ShowNotification from "../../../../../utils/redux/actions/global/notifications/NotificationAction";
import NotifyCredenciacoes from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/NotifyCredenciacoesActions";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnUpload: {
    color: "#E21450",
    // width: '125px',
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  blurredText: {
    color: "transparent", // Make the text transparent
    textShadow: "0 0 10px #000", // Apply a text shadow to create the blur effect
    userSelect: "none", // Prevent text selection
  },
  disabledTextField: {
    opacity: 0.7,
    pointerEvents: "none",
    userSelect: "none", // Adjust opacity to indicate the disabled state
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "transparent",
    },
  },
  textField: {
    userSelect: "none",
  },
});

const FormEditViatura = ({
  toggleDrawer,
  drawerWidth,
  dimensions,
  credenciacaoId,
  credenciacao,
  setCredenciacao,
  fetchCredenciacoes,
  aeroportos,
  funcoes,
  tipoCartoes,
  tipoVinculos,
  cores,
  funcionarios,
  acompanhantes,
  motivos,
  estados,
  empresas,
  habilitacoes,
  tipoDocs,
  inqPessoal,
  copCred,
  tipoCredenciacao,
  tipoCombustiveis,
  tipoVeiculos,
  portariaAcesso,
  veiculos,
  obrasState,
  globalFetch,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  dayjs.extend(advancedFormat);

  const ficheirosState = useSelector(
    (state) => state.credenciais.ficheirosViaturasPermanente
  );

  const user = useSelector((state) => state.user.perfilId);
  const permissoes = useSelector((state) => state.user.permissoes);
  const superuser = permissoes.includes('APP_CRED_SUPER_USER');

  // ############################################################## //
  // ######################  Autocompletes  ####################### //
  // ############################################################## //

  const [funcionario, setFuncionario] = useState({ id: null, nome: "" });
  const [veiculo, setVeiculo] = useState({ id: null, descricao: "" });
  const [viaturaSel, setViaturaSel] = useState({});
  const [estado, setEstado] = useState({ id: null, descricao: "" });
  const [projeto, setProjeto] = useState({ id: null, descricao: "" });
  const [aeroporto, setAeroporto] = useState({
    id: "0",
    descricao: "",
  });
  const [funcao, setFuncao] = useState({
    id: "0",
    descricao: "",
  });
  const [acompanhante, setAcompanhante] = useState({
    id: null,
    nome: "",
    aeroporto: "",
  });
  const [motivo, setMotivo] = useState({
    id: null,
    descricao: "",
  });
  const [tipoCartao, setTipoCartao] = useState({
    id: "0",
    descricao: "",
  });
  const [tipoVinculo, setTipoVinculo] = useState({
    id: "0",
    descricao: "",
  });
  const [tipoCombustivel, setTipoCombustivel] = useState({
    id: null,
    descricao: "",
  });
  const [tipoVeiculo, setTipoVeiculo] = useState({
    id: null,
    descricao: "",
  });
  const [portAcesso, setPortAcesso] = useState({
    id: null,
    descricao: "",
  });
  const [cor, setCor] = useState({
    id: "0",
    descricao: "",
  });
  const uploadedFiles = useSelector(
    (state) => state.credenciais.viaturaUploadedFiles
  );
  const downloadFiles = useSelector((state) => state.credenciais.downloadFiles);
  const uploadedCopia = uploadedFiles.find(
    (copia) => copia.id_crede_elementos_files_tipos == 8
  );

  // ############################################################## //
  // ##################  Dates and Time Changes  ################## //
  // ############################################################## //

  const [acessoInicial, setAcessoInicial] = useState(null);
  const [acessoFinal, setAcessoFinal] = useState(null);
  const [horasInicial, setHorasInicial] = useState(dayjs());
  const [horasFinal, setHorasFinal] = useState(dayjs());
  const [vinculoInicial, setVinculoInicial] = useState(null);
  const [vinculoFinal, setVinculoFinal] = useState(null);

  const handleAcessoInicialChange = (date) => {
    setAcessoInicial(date);
  };

  const handleAcessoFinalChange = (date) => {
    setAcessoFinal(date);
  };

  const handleHorasInicialChange = (hour) => {
    setHorasInicial(hour);
  };

  const handleHorasFinalChange = (hour) => {
    setHorasFinal(hour);
  };

  const handleVinculoInicialChange = (date) => {
    setVinculoInicial(date);
  };

  const handleVinculoFinalChange = (date) => {
    setVinculoFinal(date);
  };

  // ############################################################## //
  // ##########################  States  ########################## //
  // ############################################################## //

  const [areaA, setAreaA] = useState(false);
  const [areaB, setAreaB] = useState(false);
  const [areaC, setAreaC] = useState(false);
  const [areaE, setAreaE] = useState(false);
  const [areaI, setAreaI] = useState(false);
  const [areaL, setAreaL] = useState(false);
  const [areaM, setAreaM] = useState(false);
  const [areaO, setAreaO] = useState(false);
  const [areaP, setAreaP] = useState(false);
  const [areaT, setAreaT] = useState(false);
  const [renovacao, setRenovacao] = useState(false);
  const [pedido, setPedido] = useState(false);
  const [alteracao, setAlteracao] = useState(false);
  const [inquerito, setInquerito] = useState(false);
  const [checkIn, setCheckIn] = useState(false);
  const [portaEm, setPortaEm] = useState(false);
  const [portaDes, setPortaDes] = useState(false);
  const [outrasPortas, setOutrasPortas] = useState(false);
  const [tools, setTools] = useState(false);
  const [carAccess, setCarAccess] = useState(false);

  // ############################################################## //
  // #######################  Autocompletes  ###################### //
  // ############################################################## //

  const [vitalicio, setVitalicio] = useState(false);
  const [empresa, setEmpresa] = useState({
    id: "0",
    descricao: "",
  });
  const [habilitacao, setHabilitacao] = useState({
    id: "0",
    descricao: "",
  });
  const [tipoDoc, setTipoDoc] = useState({
    id: "0",
    descricao: "",
  });

  // ############################################################## //
  // #######################  Dates Changes  ###################### //
  // ############################################################## //

  const [dataNascimento, setDataNascimento] = useState(null);
  const [dataValidade, setDataValidade] = useState(null);
  const [dataEmissao, setDataEmissao] = useState(null);
  const [dataValidadeApolice, setDataValidadeApolice] = useState(null);
  const [dataInspecao, setDataInspecao] = useState(null);
  const [dataProxInspecao, setDataProxInspecao] = useState(null);

  const handleDataNascimentoChange = (date) => {
    setDataNascimento(date);
  };

  const handleDataValidadeChange = (date) => {
    setDataValidade(date);
  };

  const handleDataEmissaoChange = (date) => {
    setDataEmissao(date);
  };

  const handleDataValidadeApoliceChange = (date) => {
    setDataValidadeApolice(date);
  };

  const handleDataInspecaoChange = (date) => {
    setDataInspecao(date);
  };

  const handleDataProxInspecaoChange = (date) => {
    setDataProxInspecao(date);
  };

  // ############################################################## //
  // ####################  Cópia Credenciação  #################### //
  // ############################################################## //

  const [copiaCredenciacao, setCopiaCredenciacao] = useState([]);

  const handleCopiaChange = (e, copCred) => {
    const file = e.target.files[0];
    console.log(file);

    setCopiaCredenciacao(() => {
      const updatedFicheiros = [];
      updatedFicheiros.push({
        file,
        idTipo: copCred.id_crede_elementos_files_tipos,
        path: copCred.crede_elementos_files_tipos_path,
      });
      return updatedFicheiros;
    });
  };

  const handleRemoveCopia = (e, filName) => {
    e.preventDefault();

    setCopiaCredenciacao(
      copiaCredenciacao.filter(function (copiaCredenciacao) {
        return copiaCredenciacao.name !== filName.name;
      })
    );
  };

  // ############################################################## //
  // ########################  Ficheiros  ######################### //
  // ############################################################## //

  const [ficheiros, setFicheiros] = useState([]);
  const [datePickerValues, setDatePickerValues] = useState({});

  const handleFilesChange = (e, index, ficheiro) => {
    const file = e.target.files[0];

    setFicheiros((prevFicheiros) => {
      const updatedFicheiros = [...prevFicheiros];
      updatedFicheiros[index] = {
        file,
        idTipo: ficheiro.id_crede_elementos_files_tipos,
        path: ficheiro.crede_elementos_files_tipos_path,
      };

      // Preenche os índices vazios com objetos vazios
      for (let i = 0; i < updatedFicheiros.length; i++) {
        if (updatedFicheiros[i] === undefined) {
          updatedFicheiros[i] = null; // ou { file: null, idTipo: null, path: null } para manter a estrutura
        }
      }

      return updatedFicheiros;
    });
  };

  const handleRemoveFiles = (index) => {
    setFicheiros((prevFicheiros) => {
      const updatedFicheiros = [...prevFicheiros];
      updatedFicheiros[index] = null;

      // Preenche os índices vazios com objetos vazios
      for (let i = 0; i < updatedFicheiros.length; i++) {
        if (updatedFicheiros[i] === undefined) {
          updatedFicheiros[i] = null; // ou { file: null, idTipo: null, path: null } para manter a estrutura
        }
      }

      return updatedFicheiros;
    });
  };

  const handleDatePickerChange = (date, index) => {
    setDatePickerValues((prevValues) => {
      return {
        ...prevValues,
        [index]: date,
      };
    });
  };

  // ############################################################## //
  // ######################  Modal Rascunho  ###################### //
  // ############################################################## //

  const [openRascunho, setOpenRascunho] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);
  const [modalState, setModalState] = useState("");

  const handleOpen = (e, ydata) => {
    e.preventDefault();

    setLoading(false);
    setDados(ydata);
    setModalState("edit");
    setOpenRascunho(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpenRascunho(false);
  };

  // ############################################################## //
  // ###################  Verificar Formulário  ################### //
  // ############################################################## //

  // Verify form credenciação viatura
  const verifyFormViatura = () => {
    // Check if the required fields are filled
    const isRequiredFilled = !!veiculo.id && !!aeroporto.id && !!estado.id; // Add more conditions for other required fields

    return isRequiredFilled;
  };

  // ############################################################## //
  // ####################  Viatura Selecionada  ################### //
  // ############################################################## //

  const viaturaSelecionada = useSelector(
    (state) => state.credenciais.veiculosById
  );

  useEffect(() => {
    setViaturaSel({
      modelo: viaturaSelecionada.crede_credenciacao_veiculo_modelo,
      marca: viaturaSelecionada.crede_credenciacao_veiculo_marca,
      n_apolice: viaturaSelecionada.crede_credenciacao_veiculo_apolice_nr,
      requisitante:
        viaturaSelecionada.crede_credenciacao_veiculo_nome_requisitante,
      n_distico: viaturaSelecionada.crede_credenciacao_veiculo_distico_nr,
    });
    setDataInspecao(
      viaturaSelecionada.crede_credenciacao_veiculo_inspecao_data
    );
    setDataProxInspecao(
      viaturaSelecionada.crede_credenciacao_veiculo_inspecao_data_proxima
    );
    setDataValidadeApolice(
      viaturaSelecionada.crede_credenciacao_veiculo_apolice_validade
    );
  }, [viaturaSelecionada, setViaturaSel]);

  // ############################################################## //
  // #################  Credenciação Selecionada  ################# //
  // ############################################################## //

  const credenciacaoSelecionada = useSelector(
    (state) =>
      state &&
      state.credenciais &&
      state.credenciais.credenciacao &&
      state.credenciais.credenciacao.credenciacao
  );

  useEffect(() => {
    if (credenciacaoSelecionada) {
      setCredenciacao({
        inscricao_adicional:
          credenciacaoSelecionada.crede_elementos_credenciacao_inscricao_adicional,
        obs: credenciacaoSelecionada.crede_elementos_credenciacao_obs,
        nr_cartao: credenciacaoSelecionada.crede_elementos_credenciacao_numero,
        motivo_recusa:
          credenciacaoSelecionada.crede_elementos_credenciacao_refused_obs,
        pedido_nr:
          credenciacaoSelecionada.crede_elementos_credenciacao_pedido_nr,
      });

      setVeiculo({
        id: credenciacaoSelecionada.id_crede_credenciacao_veiculo,
        descricao: credenciacaoSelecionada.crede_credenciacao_veiculo_matricula,
      });

      setAreaA(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_a ==
          "0"
          ? false
          : true
      );
      setAreaB(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_b ==
          "0"
          ? false
          : true
      );
      setAreaC(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_c ==
          "0"
          ? false
          : true
      );
      setAreaE(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_e ==
          "0"
          ? false
          : true
      );
      setAreaI(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_i ==
          "0"
          ? false
          : true
      );
      setAreaL(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_l ==
          "0"
          ? false
          : true
      );
      setAreaM(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_m ==
          "0"
          ? false
          : true
      );
      setAreaO(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_o ==
          "0"
          ? false
          : true
      );
      setAreaP(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_p ==
          "0"
          ? false
          : true
      );
      setAreaT(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_t ==
          "0"
          ? false
          : true
      );
      setCheckIn(
        credenciacaoSelecionada.crede_elementos_credenciacao_porta_check_in ==
          "0"
          ? false
          : true
      );
      setPortaEm(
        credenciacaoSelecionada.crede_elementos_credenciacao_porta_embarque ==
          "0"
          ? false
          : true
      );
      setPortaDes(
        credenciacaoSelecionada.crede_elementos_credenciacao_porta_desembarque ==
          "0"
          ? false
          : true
      );
      setOutrasPortas(
        credenciacaoSelecionada.crede_elementos_credenciacao_porta_outras == "0"
          ? false
          : true
      );
      setRenovacao(
        credenciacaoSelecionada.crede_elementos_credenciacao_renovar_cartao ==
          "0"
          ? false
          : true
      );
      setAlteracao(
        credenciacaoSelecionada.crede_elementos_credenciacao_pedido_alteracao_areas ==
          "0"
          ? false
          : true
      );
      setPedido(
        credenciacaoSelecionada.crede_elementos_credenciacao_pedido_segunda_via ==
          "0"
          ? false
          : true
      );
      setInquerito(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_a ==
          "0"
          ? false
          : true
      );
      setTools(
        credenciacaoSelecionada.crede_elementos_credenciacao_with_tools == "0"
          ? false
          : true
      );
      setCarAccess(
        credenciacaoSelecionada.crede_elementos_credenciacao_car_access == "0"
          ? false
          : true
      );
      setVinculoInicial(
        credenciacaoSelecionada.crede_elementos_credenciacao_vinculo_laboral_inicio
      );
      setVinculoFinal(
        credenciacaoSelecionada.crede_elementos_credenciacao_vinculo_laboral_fim
      );
      setAcessoInicial(
        credenciacaoSelecionada.crede_elementos_credenciacao_validade_inicio
      );
      setAcessoFinal(
        credenciacaoSelecionada.crede_elementos_credenciacao_validade_fim
      );
      setHorasInicial(
        credenciacaoSelecionada.crede_elementos_credenciacao_horario_inicio
      );
      setHorasFinal(
        credenciacaoSelecionada.crede_elementos_credenciacao_horario_fim
      );

      setFuncionario({
        id: credenciacaoSelecionada.id_crede_elementos,
        nome: credenciacaoSelecionada.nome_funcionario
          ? credenciacaoSelecionada.nome_funcionario
          : "--",
      });
      setAeroporto({
        id: credenciacaoSelecionada.id_crede_aeroportos,
        descricao: credenciacaoSelecionada.nome_aeroporto
          ? credenciacaoSelecionada.nome_aeroporto
          : "--",
      });
      setEstado({
        id: credenciacaoSelecionada.id_crede_credenciacao_estado,
        descricao: credenciacaoSelecionada.nome_estado
          ? credenciacaoSelecionada.nome_estado
          : "--",
      });
      setFuncao({
        id: credenciacaoSelecionada.id_crede_credenciacao_funcao,
        descricao: credenciacaoSelecionada.nome_funcao
          ? credenciacaoSelecionada.nome_funcao
          : "--",
      });
      setTipoCartao({
        id: credenciacaoSelecionada.id_crede_credenciacao_tipo_cartao,
        descricao: credenciacaoSelecionada.tipo_cartao
          ? credenciacaoSelecionada.tipo_cartao
          : "--",
      });
      setTipoVinculo({
        id: credenciacaoSelecionada.id_crede_credenciacao_tipo_vinculo,
        descricao: credenciacaoSelecionada.tipo_vinculo
          ? credenciacaoSelecionada.tipo_vinculo
          : "--",
      });
      setCor({
        id: credenciacaoSelecionada.id_crede_credenciacao_cor,
        descricao: credenciacaoSelecionada.nome_cor
          ? credenciacaoSelecionada.nome_cor
          : "--",
      });
      setAcompanhante({
        id: credenciacaoSelecionada.id_crede_elementos_credenciacao_acompanhante,
        nome: credenciacaoSelecionada.nome_acompanhante
          ? credenciacaoSelecionada.nome_acompanhante
          : "--",
      });
      setMotivo({
        id: credenciacaoSelecionada.id_crede_credenciacao_motivo,
        descricao: credenciacaoSelecionada.motivo
          ? credenciacaoSelecionada.motivo
          : "--",
      });
      setTipoVeiculo({
        id: credenciacaoSelecionada.id_crede_credenciacao_tipo_veiculo,
        descricao: credenciacaoSelecionada.veiculo
          ? credenciacaoSelecionada.veiculo
          : "--",
      });
      setPortAcesso({
        id: credenciacaoSelecionada.id_crede_credenciacao_portaria_acesso,
        descricao: credenciacaoSelecionada.portariaAcesso
          ? credenciacaoSelecionada.portariaAcesso
          : "--",
      });
      setTipoCombustivel({
        id: credenciacaoSelecionada.id_crede_credenciacao_tipo_combustivel,
        descricao: credenciacaoSelecionada.combustivel
          ? credenciacaoSelecionada.combustivel
          : "--",
      });
    }
  }, [credenciacaoSelecionada, setCredenciacao]);

  // ############################################################## //
  // ######################  Switch Estados  ###################### //
  // ############################################################## //

  let estadosFiltered;

  switch (
    credenciacaoSelecionada &&
    credenciacaoSelecionada.id_crede_credenciacao_estado
  ) {
    case 1:
      estadosFiltered = estados.filter((e) => e.id === 1 || e.id === 2);
      break;
    case 4:
      estadosFiltered = estados.filter((e) => e.id === 4 || e.id === 6);
      break;
    case 5:
      estadosFiltered = estados.filter((e) => e.id === 1 || e.id === 2);
      break;
    default:
      estadosFiltered = [];
      break;
  }

  console.log(
    credenciacaoSelecionada.id_crede_credenciacao_estado,
    estadosFiltered
  );

  // ############################################################## //
  // ###################  Proprietário Default  ################### //
  // ############################################################## //

  useEffect(() => {
    setEmpresa({
      id: 1609,
      descricao: "José A.F. Cardoso Sociedade Unipessoal, Lda",
    });
  }, [empresa, setEmpresa]);

  // ############################################################## //
  // ###############  Handle Change and Submit Form  ############## //
  // ############################################################## //

  const handleChange = (e) => {
    e.preventDefault();
    setCredenciacao({
      ...credenciacao,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        funcionario_id: funcionario.id,
        estado_id: estado.id,
        pedido_nr: credenciacao.pedido_nr,
        motivo_recusa: credenciacao.motivo_recusa,
        area_acesso_a: areaA,
        area_acesso_b: areaB,
        area_acesso_c: areaC,
        area_acesso_e: areaE,
        area_acesso_i: areaI,
        area_acesso_l: areaL,
        area_acesso_m: areaM,
        area_acesso_o: areaO,
        area_acesso_p: areaP,
        area_acesso_t: areaT,
        inscricao_adicional: credenciacao.inscricao_adicional,
        obs: credenciacao.obs,
        validade_inicio: acessoInicial
          ? moment(acessoInicial).format("YYYY-MM-DD")
          : null,
        validade_fim: acessoFinal
          ? moment(acessoFinal).format("YYYY-MM-DD")
          : null,
        horario_inicio: dayjs(horasInicial).format("YYYY-MM-DD HH:mm:ss"),
        horario_fim: dayjs(horasFinal).format("YYYY-MM-DD HH:mm:ss"),
        renovar_cartao: renovacao,
        pedido_segunda_via: pedido,
        pedido_alteracao_areas: alteracao,
        vinculo_laboral_inicio: vinculoInicial
          ? moment(vinculoInicial).format("YYYY-MM-DD")
          : null,
        vinculo_laboral_fim: vinculoFinal
          ? moment(vinculoFinal).format("YYYY-MM-DD")
          : null,
        inquerito_pessoal_exito: inquerito,
        porta_check_in: checkIn,
        porta_embarque: portaEm,
        porta_desembarque: portaDes,
        porta_outras: outrasPortas,
        aeroporto_id: aeroporto.id,
        tipoCartao: tipoCartao.id,
        funcao_id: funcao.id,
        cor_id: cor.id,
        tipoVinculo: tipoVinculo.id,
        tipoCredenciacao: tipoCredenciacao.id,
        acompanhante_id: acompanhante.id,
        motivo_id: motivo.id,
        numero_cred: credenciacao.numero_cred,
        tools: tools,
        car_access: carAccess,
        veiculo_id: veiculo.id,
        portAcesso_id: portAcesso.id,
        rascunho: 0,
        projeto_id: projeto.id,
      },
    ];

    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!",
      type: "error",
    };
    const message = {
      isOpen: true,
      message: "Credenciação editada com sucesso!!",
      type: "success",
    };

    if (verifyFormViatura()) {
      dispatch(
        EditCredenciacao(
          ydata,
          credenciacaoSelecionada.id_crede_elementos_credenciacao,
          copiaCredenciacao,
          null,
          ficheiros,
          datePickerValues,
          toggleDrawer,
          ShowNotification,
          message,
          errorMessage
        )
      ).then(() => {
        toggleDrawer("right", false);
        fetchCredenciacoes();
        globalFetch(estado.id);
        // dispatch(
        //   SendCredNotifications(
        //     credenciacaoSelecionada.id_crede_elementos_credenciacao,
        //     estado.id
        //   )
        // );
        dispatch(
          NotifyCredenciacoes(
            credenciacaoSelecionada.id_crede_elementos_credenciacao,
            estado.id
          )
        );
      });
    } else {
      // dispatch(ShowNotification(warningMessage));
      ydata[0] = { ...ydata[0], rascunho: 1 };
      handleOpen(e, ydata);
    }
  };

  const fetchCredFuncionarios = () => {
    dispatch(GetCredFuncionarios());
  };

  const fetchCredCopiaCredenciacaoUploaded = (credId) => {
    dispatch(GetCredCopiaCredenciacaoUploaded(credId));
  };

  useEffect(() => {
    fetchCredFuncionarios();
    fetchCredCopiaCredenciacaoUploaded(credenciacaoId);
  }, []);

  return (
    <div
      className={classes.list}
      style={
        dimensions.width <= 1100
          ? { width: "60vw" }
          : { width: `${drawerWidth}` }
      }
    >
      <ModalRascunho
        openRascunho={openRascunho}
        handleClose={handleClose}
        fetchCredenciacoes={fetchCredenciacoes}
        handleSubmit={handleSubmit}
        dados={dados}
        loading={loading}
        setLoading={setLoading}
        ficheiros={ficheiros}
        datePickerValues={datePickerValues}
        toggleDrawer={toggleDrawer}
        ShowNotification={ShowNotification}
        modalState={modalState}
        idElem={credenciacaoSelecionada.id_crede_elementos_credenciacao}
        copiaCredenciacao={copiaCredenciacao}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 5,
        }}
      >
        <Box
          onClick={() => toggleDrawer("right", false)}
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <div>
            <FeatherIcon
              icon="chevron-left"
              size={20}
              style={{ strokeWidth: "1.5" }}
            />
          </div>
          <div className="text-underline-close-drawer">Credenciações</div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box>
      </Box>
      <Card
        sx={{
          m: 5,
          p: 2,
          boxShadow: "0px 10px 15px lightgrey",
        }}
      >
        <Box
          sx={{
            m: 2,
          }}
        >
          <h4
            style={{
              fontSize: "22px",
              width: "100%",
              marginBottom: "0px",
            }}
            className="text-underline"
          >
            Credenciação Viatura
          </h4>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ m: 2, width: "30%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Aeroporto
            </Typography>
            <Autocomplete
              // disabled={!superuser && estado.id != 1 ? true : false}
              fullWidth
              name="nome"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              value={aeroporto}
              className="unidadesId-form"
              options={aeroportos || []}
              getOptionLabel={(option) => `${option.descricao}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setAeroporto({ id: null, descricao: "" });
                } else {
                  setAeroporto(value);
                }
                return value;
              }}
            />
          </Box>
          <Box sx={{ m: 2, width: "30%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Matricula *
            </Typography>
            <Autocomplete
              disabled
              fullWidth
              name="nome"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              value={veiculo}
              className="unidadesId-form"
              options={veiculos || []}
              getOptionLabel={(option) => `${option.descricao}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
            />
          </Box>
          <Box sx={{ m: 2, width: "30%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Estado *
            </Typography>
            <Autocomplete
              disabled={
                !superuser &&
                credenciacaoSelecionada &&
                credenciacaoSelecionada.id_crede_credenciacao_estado != 1 &&
                credenciacaoSelecionada.id_crede_credenciacao_estado != 4 &&
                credenciacaoSelecionada.id_crede_credenciacao_estado != 5
                  ? true
                  : false
              }
              fullWidth
              name="nome"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              value={estado}
              className="unidadesId-form"
              options={!superuser ? estadosFiltered : estados}
              getOptionLabel={(option) => `${option.descricao}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setEstado({ id: null, descricao: "" });
                  return;
                } else {
                  setEstado(value);
                  return value;
                }
              }}
            />
          </Box>
        </Box>
        {estado.descricao == "Recusada" && (
          <Box
            sx={{
              mx: 2,
              display: "flex",
              width: "46.5%",
              flexDirection: "column",
            }}
          >
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Motivo de Recusa
            </Typography>
            <TextField
              name="motivo_recusa"
              disabled={!superuser && estado.id != 1 ? true : false}
              multiline
              rows={2}
              size="small"
              variant="outlined"
              value={credenciacao.motivo_recusa}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        )}
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Dados Viatura
          </h4>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 2, width: "46.5%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Proprietário
            </Typography>
            <Autocomplete
              fullWidth
              disabled
              name="nome"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              value={empresa}
              className="unidadesId-form"
              options={empresas || []}
              getOptionLabel={(option) => `${option.descricao}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setEmpresa({ id: null, descricao: "" });
                } else {
                  setEmpresa(value);
                }
                return value;
              }}
            />
          </Box>
          <Box sx={{ m: 2, width: "46.5%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Modelo
            </Typography>
            <TextField
              disabled
              name="modelo"
              size="small"
              variant="outlined"
              value={viaturaSel.modelo}
              onChange={handleChange}
              style={{ width: "100%" }}
            />
          </Box>
          <Box sx={{ m: 2, width: "46.5%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Marca
            </Typography>
            <TextField
              disabled
              name="marca"
              size="small"
              variant="outlined"
              value={viaturaSel.marca}
              onChange={handleChange}
              style={{ width: "100%" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              m: 2,
              width: "46.5%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "46.5%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Nº Apólice
              </Typography>
              <TextField
                disabled
                name="n_apolice"
                size="small"
                variant="outlined"
                value={viaturaSel.n_apolice}
                onChange={handleChange}
                style={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "46.5%" }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={ptLocale}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  Data de Validade Apólice
                </Typography>
                <DatePicker
                  disabled
                  margin="normal"
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  okLabel="ok"
                  cancelLabel="cancelar"
                  invalidDateMessage="Data Inválida"
                  value={dataValidadeApolice}
                  onChange={handleDataValidadeApoliceChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "dd/mm/aaaa",
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      variant="outlined"
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box
            sx={{
              m: 2,
              width: "46.5%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "46.5%" }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={ptLocale}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  Data da Inspeção
                </Typography>
                <DatePicker
                  disabled
                  margin="normal"
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  okLabel="ok"
                  cancelLabel="cancelar"
                  invalidDateMessage="Data Inválida"
                  value={dataInspecao}
                  onChange={handleDataInspecaoChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "dd/mm/aaaa",
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      variant="outlined"
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ width: "46.5%" }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={ptLocale}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  Data da Próxima Inspeção
                </Typography>
                <DatePicker
                  disabled
                  margin="normal"
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  okLabel="ok"
                  cancelLabel="cancelar"
                  invalidDateMessage="Data Inválida"
                  value={dataProxInspecao}
                  onChange={handleDataProxInspecaoChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "dd/mm/aaaa",
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      variant="outlined"
                      size="small"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Entidade Requisitante
          </h4>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              m: 2,
              width: "46.5%",
            }}
          >
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Nome
            </Typography>
            <TextField
              disabled
              size="small"
              variant="outlined"
              value={viaturaSel.requisitante}
              onChange={handleChange}
              style={{ width: "100%" }}
            />
          </Box>
          <Box
            sx={{
              m: 2,
              width: "46.5%",
            }}
          >
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Renovação do dístico nº
            </Typography>
            <TextField
              disabled
              name="renovacao_distico_n"
              size="small"
              variant="outlined"
              value={viaturaSel.n_distico}
              onChange={handleChange}
              style={{ width: "100%" }}
            />
          </Box>
        </Box>
        {aeroporto.id == 2 && (
          <>
            <Box sx={{ m: 2 }}>
              <h4
                style={{ color: "#E21450" }}
                className="text-underline-title-drawer "
              >
                Áreas de acesso / circulação
              </h4>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  m: 2,
                  width: "30%",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Pistas
                </Typography>
                <TextField
                  disabled={!superuser && estado.id != 1 ? true : false}
                  name="pistas"
                  size="small"
                  variant="outlined"
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box
                sx={{
                  m: 2,
                  width: "30%",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Plataformas
                </Typography>
                <TextField
                  disabled={!superuser && estado.id != 1 ? true : false}
                  name="plataformas"
                  size="small"
                  variant="outlined"
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box
                sx={{
                  m: 2,
                  width: "30%",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Outros
                </Typography>
                <TextField
                  disabled={!superuser && estado.id != 1 ? true : false}
                  name="outros"
                  size="small"
                  variant="outlined"
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
          </>
        )}
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Documentos
          </h4>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            mx: 1,
            width: "100%",
          }}
        >
          {downloadFiles &&
            downloadFiles.map((ficheiro, index) => {
              return (
                <Box sx={{ m: 1, width: "20%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    {ficheiro.tipo}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Button
                      sx={{
                        height: "40px",
                        borderRadius: "5px",
                        mr: 1,
                      }}
                      className={classes.btnUpload}
                      onClick={() => {
                        Instance()
                          .get(
                            `${BASE_URL}/credenciacoes/funcionarios/files/${ficheiro.fileId}/download`,
                            { responseType: "blob" }
                          )
                          .then(({ data }) => {
                            const filename = ficheiro.name.replace(
                              /^.*[\\/]/,
                              ""
                            );
                            fileDownload(data, filename);
                          })
                          .catch((err) => {
                            console.log(err.response);
                            if (err.response) {
                              if (err.response === 403) {
                                // dispatch(ShowNotification(errorMessage));
                              }
                            }
                          });
                      }}
                    >
                      <FeatherIcon icon="download" size={20} />
                    </Button>
                    <div style={{ width: "100%" }}>
                      {ficheiro && (
                        <Button
                          sx={{
                            height: "40px",
                            borderRadius: "5px",
                          }}
                          className={classes.btnUpload}
                          onClick={() => {
                            // Retrieve the base URL from environment variables
                            let baseUrl = BASE_URL;

                            // Remove the '/api' part from the base URL
                            if (baseUrl.endsWith("/api")) {
                              baseUrl = baseUrl.slice(0, -4); // Remove '/api'
                            }

                            // Construct the full file URL
                            const fileUrl = `${baseUrl}${ficheiro.name}`;

                            // Open the file in a new tab/window
                            window.open(fileUrl, "_blank");
                          }}
                        >
                          <FeatherIcon icon="eye" size={20} />
                        </Button>
                      )}
                    </div>
                  </Box>
                </Box>
              );
            })}
        </Box>
        {/* <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Documentos
          </h4>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            m: 1,
            width: "100%",
          }}
        >
          {ficheirosState &&
            ficheirosState.map((ficheiro, index) => {
              const matchingFile = uploadedFiles.find(
                (file) =>
                  file.id_crede_elementos_files_tipos ===
                  ficheiro.id_crede_elementos_files_tipos
              );
              return (
                <Box sx={{ m: 1, width: "24%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    {ficheiro.crede_elementos_files_tipos_nome}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    {matchingFile && (
                      <Button
                        sx={{
                          height: "40px",
                          borderRadius: "5px",
                          mr: 1,
                        }}
                        className={classes.btnUpload}
                        onClick={() => {
                          Instance()
                            .get(
                              `${BASE_URL}/credenciacoes/funcionarios/files/${matchingFile.id_crede_elementos_files}/download`,
                              { responseType: "blob" }
                            )
                            .then(({ data }) => {
                              const filename =
                                matchingFile.crede_elementos_files_path.replace(
                                  /^.*[\\/]/,
                                  ""
                                );
                              fileDownload(data, filename);
                            })
                            .catch((err) => {
                              console.log(err.response);
                              if (err.response) {
                                if (err.response === 403) {
                                  // dispatch(ShowNotification(errorMessage));
                                }
                              }
                            });
                        }}
                      >
                        <FeatherIcon icon="download" size={20} />
                      </Button>
                    )}
                    <Button
                      sx={{
                        height: "40px",
                        width: "200px",
                        borderRadius: "5px",
                        mr: 2,
                      }}
                      onChange={(e) => handleFilesChange(e, index, ficheiro)}
                      className={classes.btnUpload}
                      disabled={!superuser && estado.id != 1 ? true : false}
                    >
                      <label
                        htmlFor={
                          ficheiro.crede_elementos_files_tipos_nome_input
                        }
                        className="custom-file-upload"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            cursor: "pointer",
                          }}
                        >
                          <p>Carregar Ficheiro</p>
                        </div>
                      </label>
                      <input
                        type="file"
                        name={ficheiro.crede_elementos_files_tipos_nome_input}
                        id={ficheiro.crede_elementos_files_tipos_nome_input}
                        hidden
                        accept="image/jpeg, .pdf"
                      />
                    </Button>
                    <div style={{ width: "auto" }}>
                      {ficheiros[index] && (
                        <div
                          style={{
                            // marginRight: "10px",
                            display: "flex",
                            alignItems: "center",
                          }}
                          key={index}
                        >
                          {/* {ficheiros[index].file.name} */}
        {/* <IconButton
                            onClick={() => {
                              const fileName = ficheiros[index].file.name;
                              const fileExtension = fileName.slice(
                                (Math.max(0, fileName.lastIndexOf(".")) ||
                                  Infinity) + 1
                              );

                              if (fileExtension == "pdf") {
                                const pdfFile = ficheiros[index].file;

                                // Create a Blob from the PDF file
                                const blob = new Blob([pdfFile], {
                                  type: "application/pdf",
                                });

                                // Create a URL for the Blob
                                const blobUrl = URL.createObjectURL(blob);

                                // Now you can use `blobUrl` to open or display the PDF
                                window.open(blobUrl, "_blank");
                              } else {
                                const blobUrl = URL.createObjectURL(
                                  ficheiros[index].file
                                );

                                // Now you can use `blobUrl` to open or display the PDF
                                window.open(blobUrl, "_blank");
                              }
                            }}
                          >
                            <FeatherIcon icon="eye" size={"1rem"} />
                          </IconButton>
                          <IconButton onClick={(e) => handleRemoveFiles(index)}>
                            <FeatherIcon icon="trash-2" size={"1rem"} />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </Box>
                  {ficheiro.id_crede_elementos_files_tipos == 10 &&
                    ficheiros[index] && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: 1,
                        }}
                      >
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={ptLocale}
                        >
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Data de Validade
                          </Typography>
                          <DatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            format="dd/MM/yyyy"
                            okLabel="ok"
                            value={datePickerValues[index]}
                            onChange={(date) =>
                              handleDatePickerChange(date, index)
                            }
                            cancelLabel="cancelar"
                            invalidDateMessage="Data Inválida"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "dd/mm/aaaa",
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                name="data_nascimento"
                                sx={{ width: "200px" }}
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    )}
                </Box>
              );
            })}
        </Box> */}
      </Card>
    </div>
  );
};

export default FormEditViatura;
