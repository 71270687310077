import Instance from "../../../../Axios";

// New Actions for the app

const GetWorkPermitDefaultAccountablesAHD = (diretor) => async (dispatch) => {
  const diretorId = diretor ? diretor : null;
  await Instance()
    .get(`/construcoes/workpermits/defaultAccountablesAHD`, {
      params: { diretor: diretorId },
    })
    .then(({ data }) => {
      // console.log(data);
      dispatch({
        type: "GET_WORK_PERMIT_DEFAULT_ACCOUNTABLES_AHD",
        payload: data.yData,
      });
      // setObraSelecionada(data.yData[0]);
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetWorkPermitDefaultAccountablesAHD;