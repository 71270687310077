import Instance from "../../../../Axios";

// New Actions for the app

const GetCredVeiculosById = (veiculoId) => async (dispatch) => {
  await Instance()
    .get(`/credenciacoes/veiculos/${veiculoId}`)
    .then(({ data }) => {
      dispatch({
        type: "GET_CRED_VEICULOS_BY_ID",
        payload: data.yData[0],
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetCredVeiculosById;
