import Instance from "../../../../Axios";

// New Actions for the app

const GetObrasAHDMediaIdadeRealizadas = () => async (dispatch) => {
  await Instance()
    .get(`/construcoes/obras/ahd/obras/mediaIdadeRealizadas`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({ type: "GET_OBRAS_AHD_MEDIA_IDADE_REALIZADAS", payload: Math.floor(data.yData[0].diff) });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetObrasAHDMediaIdadeRealizadas;