import Instance from "../../../Axios";

// New Actions for the app

const DeleteClientProfileById = (id) => async (dispatch) => {
  await Instance()
    .put(`/plus/adesoes/clients/profiles/delete/${id}`)
    .then(({ data }) => {
      dispatch({ type: "DELETE_CLIENT_PROFILE_BY_ID", payload: data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default DeleteClientProfileById;
