import React from 'react';
import { IconButton } from '@mui/material';
import { Tooltip } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { format } from 'date-fns';

//Components
import ListaObrasPDF from './pdf/ListaObrasPDF';
// import PDFGenerator from './pdf/ListaObrasPDF1';

const CustomToolbar = ({ obrasState }) => {
  const date = format(new Date(), 'dd/MM/yyyy');

  const handleClick = () => {
    console.log(obrasState, date);
    ListaObrasPDF(obrasState, date);
    // PDFGenerator(ano, mes, date, obrasState, diasMes);
  };

  return (
    <>
      <Tooltip title={'Imprimir Obras'}>
        <IconButton onClick={handleClick}>
          <FeatherIcon icon='printer' size={22} style={{ strokeWidth: '2' }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default CustomToolbar;
