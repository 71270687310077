/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import MUIDataTable from "mui-datatables";

//ICONS
import FeatherIcon from "feather-icons-react";

//Actions
import GetUtilizadores from "../../../utils/redux/actions/utilizadores/GetUtilizadoresAction";
import GetUtilizador from "../../../utils/redux/actions/utilizadores/GetUtilizadorAction";
import GetPerfis from "../../../utils/redux/actions/utilizadores/GetPerfisAction";
import GetAeroportos from "../../../utils/redux/actions/global/filters/GetAeroportosAction";
import GetPermissoes from "../../../utils/redux/actions/utilizadores/GetPermissoesAction";

//Components
import CustomToolbar from "./components/customToolbar";
import ModalDelete from "./components/modalDelete";
import SideDrawer from "./components/drawer";
import FooterLinks from "../../../Global/Components/Footer";
import GetDiretoresObraProjeto from "../../../utils/redux/actions/aprovisionamento/filters/GetDiretoresObraProjetoAction";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
  },
  options: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "end",
  },

  paperFilters: {
    background: "transparent",
    padding: "20px",
    boxShadow: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
}));

const Utilizadores = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const [utilizadorId, setUtilizadorId] = useState();
  const [utilizadorNome, setUtilizadorNome] = useState();
  const [utilizador, setUtilizador] = useState({
    nome: "",
    email: "",
    perfisId: "",
    aeroportoId: "",
    departamentoId: "",
    energParceirosId: "",
    permissoes: [],
    auth_utilizadores_url_key: "",
  });
  const [perfil, setPerfil] = useState({ id: null, descricao: "" });

  const [state, setState] = useState({
    right: false,
  });
  const [drawerState, setDrawerState] = useState(null);

  const userPermissions = useSelector((state) => state.user.permissoes);

  const fetchUtilizadores = (perfilId) => {
    dispatch(GetUtilizadores(perfilId));
  };
  const fetchPerfis = () => {
    dispatch(GetPerfis());
  };
  const fetchAeroportos = () => {
    dispatch(GetAeroportos());
  };
  const fetchPermissoes = () => {
    dispatch(GetPermissoes());
  };
  const fetchDiretoresObra = () => {
    dispatch(GetDiretoresObraProjeto());
  };

  const fetchUtilizador = (id) => {
    return dispatch(GetUtilizador(id));
  };

  useEffect(() => fetchUtilizadores(perfil.id), []);
  useEffect(() => fetchPerfis(), []);
  useEffect(() => fetchAeroportos(), []);
  useEffect(() => fetchDiretoresObra(), []);
  useEffect(() => fetchPermissoes(), []);

  const utilizadores = useSelector((state) => state.utilizadores.utilizadores);
  const perfis = useSelector((state) => state.utilizadores.perfis);
  const utilizadorInfo = useSelector((state) => state.utilizadores.utilizador);
  const parceiros = useSelector((state) => state.energiasDashboard.parceiros);
  const diretoresObra = useSelector((state) => state.obras.diretoresObraProjeto);

  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();
    setErrorsUtilizador({});
    if (btn === "delete") {
      setOpenDelete(true);
      setUtilizadorId(id);
      setUtilizadorNome(tableMeta.rowData[1]);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    if (btn === "delete") {
      setOpenDelete(false);
    }
  };

  const toggleDrawer = (anchor, open, userId, ds) => {
    if (open == false) {
      setUtilizadorId(userId);
      setState({ ...state, [anchor]: open });
      fetchPermissoes();
      dispatch({ type: "GET_DEPARTAMENTOS", payload: [] });
    }
    if (open == true) {
      setErrorsUtilizador({});
      setDrawerState(ds);
      setUtilizadorId(userId);
      setState({ ...state, [anchor]: open });
      fetchUtilizador(userId);
    }
  };

  // ############################################################## //
  // ####################  Validação Unidades  #################### //
  // ############################################################## //

  const [errorsUtilizador, setErrorsUtilizador] = useState({});

  const validationUtilizador = (fieldValues = utilizador) => {
    const temp = { ...errorsUtilizador };

    // console.log(fieldValues);

    if ("email" in fieldValues) {
      // prettier-ignore
      temp.email = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(fieldValues.email) ? '' : 'Coloque um email válido';
    }
    if ("nome" in fieldValues)
      temp.nome = fieldValues.nome ? "" : "Campo Obrigatório";
    if ("perfisId" in fieldValues)
      temp.perfisId = fieldValues.perfisId ? "" : "Campo Obrigatório";
    // if ('departamentoId' in fieldValues)
    //   temp.departamentoId = fieldValues.aeroportoId ? '' : 'Campo Obrigatório';

    setErrorsUtilizador({
      ...temp,
    });
    if (fieldValues == utilizador) {
      return Object.values(temp).every((x) => x == "");
    }
    return false;
  };

  // ############################################################## //
  // ###################  MUI Datatable columns  ################## //
  // ############################################################## //

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "nome",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "perfisDescricao",
      label: "Perfil",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: "center",
        }),
        customBodyRender: (value, tableMeta) => {
          const userId = tableMeta.rowData[0];

          return (
            <div className={classes.options}>
              {userPermissions &&
                userPermissions.includes("APP_UTILIZADORES_EDITAR") && (
                  <IconButton
                    style={{
                      padding: 6,
                    }}
                    // onClick={(e) => handleOpen('edit', e, id, tableMeta)}
                    onClick={() => toggleDrawer("right", true, userId, "edit")}
                  >
                    <FeatherIcon
                      icon="edit"
                      size={20}
                      style={{ strokeWidth: "1.5" }}
                    />
                  </IconButton>
                )}
              {userPermissions &&
                userPermissions.includes("APP_UTILIZADORES_REMOVER") && (
                  <IconButton
                    style={{
                      padding: 6,
                    }}
                    onClick={(e) => handleOpen("delete", e, userId, tableMeta)}
                  >
                    <FeatherIcon
                      icon="trash-2"
                      size={20}
                      style={{ strokeWidth: "1.5" }}
                    />
                  </IconButton>
                )}
            </div>
          );
        },
      },
    },
  ];

  // ############################################################## //
  // ##################  MUI Datatable options  ################### //
  // ############################################################## //

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 100, 150, 200],
    // setRowProps: (row, dataIndex, rowIndex) => {
    //   return {
    //     style:
    //       rowIndex % 2
    //         ? { backgroundColor: 'transparent' }
    //         : { backgroundColor: '#F3F5F8' },
    //   };
    // },
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },

    customToolbar: () => {
      return (
        <>
          {userPermissions &&
            userPermissions.includes("APP_UTILIZADORES_ADICIONAR") && (
              <CustomToolbar
                handleOpen={handleOpen}
                onClose={handleClose}
                toggleDrawer={toggleDrawer}
              />
            )}
        </>
      );
    },
  };

  const handleSubmit = () => {
    const perfilId = perfil.id;
    fetchUtilizadores(perfilId);
  };

  return (
    <>
      <ModalDelete
        openDelete={openDelete}
        handleClose={handleClose}
        utilizadorId={utilizadorId}
        fetchUtilizadores={fetchUtilizadores}
        utilizadorNome={utilizadorNome}
      />
      <SideDrawer
        state={state}
        toggleDrawer={toggleDrawer}
        utilizadores={utilizadores}
        fetchUtilizadores={fetchUtilizadores}
        drawerState={drawerState}
        utilizador={utilizador}
        setUtilizador={setUtilizador}
        validationUtilizador={validationUtilizador}
        utilizadorId={utilizadorId}
        utilizadorInfo={utilizadorInfo}
        perfis={perfis}
        parceiros={parceiros}
        diretoresObra={diretoresObra}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", m: 5 }}>
          <Box sx={{ mr: 2 }}>
            <Autocomplete
              fullWidth
              id="estado"
              name="estado"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              className="unidadesId-form"
              options={perfis || []}
              getOptionLabel={(option) => `${option.descricao}` || ""}
              value={perfil}
              isOptionEqualToValue={(option, value) => {
                return value.id === option.id;
              }}
              onChange={(e, value, reason) => {
                if (reason == "clear") {
                  setPerfil({ id: null, descricao: "" });
                } else {
                  setPerfil(value);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Perfil"
                  size="small"
                  sx={{ width: "230px" }}
                />
              )}
            />
          </Box>
          <Button
            onClick={handleSubmit}
            variant="contained"
            className={classes.btnFiltrar}
          >
            Filtrar
          </Button>
        </Box>
        <Box sx={{ mx: 5 }}>
          <MUIDataTable
            title={
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
                className="text-underline"
              >
                Utilizadores
              </span>
            }
            data={utilizadores || []}
            columns={columns}
            options={options}
          />
        </Box>
        <div style={{ padding: "20px 40px" }}>
          <FooterLinks />
        </div>
      </div>
    </>
  );
};

export default Utilizadores;
