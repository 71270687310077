import './minicard.css';

import { MdOutlineCancel } from 'react-icons/md';

//Actions

const MiniCardSuspensa = ({ color, obrasState }) => {
  const card = obrasState.filter((obra) => obra.descricao === 'Suspensa');

  return (
    <>
      <div className='minicard' style={{ background: color }}>
        <div className='minicard-text'>
          <h1>{card && card[0] && card[0].qtd}</h1>
          <p>{card && card[0] && card[0].descricao}</p>
        </div>
        <div className='minicard-icon'>
          <div className='minicard-icon-around'>
            <MdOutlineCancel style={{ color: '#5D646D' }} size='2em' />
          </div>
        </div>
      </div>
    </>
  );
};

export default MiniCardSuspensa;
