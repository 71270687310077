/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Box,
  IconButton,
  useTheme,
  Breadcrumbs,
  Link,
} from "@mui/material";
import "moment/locale/pt";
import MUIDataTable from "mui-datatables";

// Components
import FooterLinks from "../../../../Global/Components/Footer";
import SideDrawer from "./components/drawer";

//Icons
import FeatherIcon from "feather-icons-react";

//Actions
import GetCredFuncionarios from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionariosAction";
import GetCredIntervencoesElementos from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredIntervencoesElementosAction";
import GetCredIntervencoesTotais from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredIntervencoesTotaisAction";
import GetCredIntervencao from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredIntervencaoAction";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
  },
  btnFiltrar: {
    color: "#E21450",
    width: "150px",
    height: "40px",
    fontWeight: "900",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  stateLabel: {
    textTransform: "none",
    transition: "0.2s",
    display: "flex",
    alignItems: "center",
    maxWidth: "100px",
  },

  paperFilters: {
    background: "transparent",
    padding: "20px 0px",
    boxShadow: "none",
  },
}));

// ############################################################## //
// #####################  Window Dimensions  #################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const CredIntervencoes = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");
  const permissoes = useSelector((state) => state.user.permissoes);

  const intervencoesState = useSelector(
    (state) => state.credenciais.intervencoesTotais
  );

  const fetchIntervencoes = () => {
    dispatch(GetCredIntervencoesTotais());
  };

  useEffect(() => {
    fetchIntervencoes();
  }, []);

  // ############################################################## //
  // #######################  Drawer Width  ####################### //
  // ############################################################## //

  const [drawerWidth, setDrawerWidth] = useState("90vw");
  const dimensions = useWindowDimensions();

  const fetchCredIntervencoesElementos = (intId) => {
    dispatch(GetCredIntervencoesElementos(intId));
  };

  const fetchCredIntervencao = (intId) => {
    dispatch(GetCredIntervencao(intId));
  };

  const [intervencaoId, setIntervencaoId] = useState();
  const [state, setState] = useState({
    right: false,
  });
  const [drawerState, setDrawerState] = useState(null);

  const toggleDrawer = (anchor, open, id) => {
    if (open == false) {
      setState({ ...state, [anchor]: open });
    }
    if (open == true) {
      setState({ ...state, [anchor]: open });
      setIntervencaoId(id);
      fetchCredIntervencoesElementos(id);
      fetchCredIntervencao(id);
    }
  };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: true,
      },
    },
    {
      name: "descricao",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
      },
    },

    {
      name: "responsavel",
      label: "Responsavel",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "300px" },
        }),
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { width: "2.5%" },
          align: "center",
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const o = tableMeta.rowData[0];

          return (
            <div style={{ display: "flex" }}>
              {permissoes.includes("APP_CRED_OBRAS_EDITAR") && (
                <IconButton
                  onClick={() => {
                    setDrawerState("edit");
                    toggleDrawer("right", true, o);
                  }}
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                >
                  <FeatherIcon
                    icon="edit"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              )}
              {/* <IconButton
                style={{
                  padding: 6,
                }}
                onClick={(e) => {
                  handleOpen(e, o);
                }}
              >
                <FeatherIcon
                  icon="trash-2"
                  size={25}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton> */}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },

    // customToolbar: () => {
    //   return (
    //     <>
    //       <CustomToolbar
    //         toggleDrawer={toggleDrawer}
    //         setDrawerState={setDrawerState}
    //       />
    //     </>
    //   );
    // },
  };

  const fetchCredFuncionarios = () => {
    dispatch(GetCredFuncionarios());
  };

  useEffect(() => {
    fetchCredFuncionarios();
  }, []);

  return (
    <>
      <Box
        sx={{
          justifyContent: "space-between",
          mr: 10,
          width: "100%",
        }}
      >
        <SideDrawer
          drawerWidth={drawerWidth}
          dimensions={dimensions}
          state={state}
          toggleDrawer={toggleDrawer}
          drawerState={drawerState}
          intervencaoId={intervencaoId}
          fetchCredIntervencoesElementos={fetchCredIntervencoesElementos}
        />
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              mx: 5,
              mt: 5,
              width: "100%",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/construcoes/obras">
                Gestão Aeroportuária
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href="/credenciacoes/credenciacoes"
              >
                Credenciações
              </Link>
              <Typography color="text.primary">Obras</Typography>
            </Breadcrumbs>
          </Box>
          <div
            style={{
              margin: "40px 40px 40px 40px",
              width: "100%",
            }}
          >
            <MUIDataTable
              title={
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  className="text-underline"
                >
                  Lista de Obras
                </span>
              }
              data={intervencoesState || []}
              columns={columns}
              options={options}
            />
          </div>
          <div style={{ margin: "20px 40px" }}>
            <FooterLinks />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default CredIntervencoes;
