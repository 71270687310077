/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const GraficoEstadoIntervencoes = ({ estadosIntervencoes, dimensions }) => {
  const Estados =
    estadosIntervencoes &&
    estadosIntervencoes.options &&
    estadosIntervencoes.options.xaxis &&
    estadosIntervencoes.options.xaxis.categories;
  // const estadoPropostas = useSelector(
  //   (state) => state.energiasDashboard.estadoPropostas
  // );

  // const propostas = estadoPropostas && estadoPropostas.estadoPropostas;
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        width: '100%',
        type: 'bar',
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        offsetY: -25,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      colors: ['#33b2df', '#546E7A', '#d4526e'],
      title: {
        text: 'Estado das Intervenções',
        align: 'center',
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
        },
        title: {
          // text: 'Total',
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: true,
      },
      grid: {
        show: true,
      },
      legend: {
        show: false,
        position: 'right',
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
      // eslint-disable-next-line no-dupe-keys
      colors: ['#ED5564', '#F6BB43', '#4FC0E8'],
    },
  });

  useEffect(() => {
    estadosIntervencoes && setState(estadosIntervencoes);
  }, [Estados]);

  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type='bar'
        height={300}
        width={dimensions < 1560 ? undefined : 250}
      />
    </>
  );
};

export default GraficoEstadoIntervencoes;
