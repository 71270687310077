import Instance from "../../../../Axios";

// New Actions for the app

const GetObrasAHDSerralharia = () => async (dispatch) => {
  await Instance()
    .get(`/construcoes/obras/ahd/obrasSerra`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({ type: "GET_OBRAS_AHD_SERRALHARIA", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetObrasAHDSerralharia;
