import { fichasTecnicasInitialState } from "../initialState/fichasTecnicas";

const fichasTecnicasReducer = (
  state = fichasTecnicasInitialState,
  { type, payload }
) => {
  switch (type) {
    case "GET_ALL_FICHAS_TECNICAS":
      return {
        ...state,
        fichasTecnicas: payload,
      };
    case "ADD_FICHA_TECNICA":
      return {
        ...state,
        fichaTecnicaAdicionada: payload,
      };
    case "DELETE_FICHA_TECNICA":
      return {
        ...state,
        fichaTecnicaApagada: payload,
      };
    case "GET_LAST_FICHAS_TECNICAS":
      return {
        ...state,
        lastFichasTecnicas: payload,
      };

    default:
      return state;
  }
};

export default fichasTecnicasReducer;
