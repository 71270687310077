import { blogInitialState } from "../initialState/blog";

const blogReducer = (state = blogInitialState, { type, payload }) => {
  switch (type) {
    case "GET_TODOS_ARTIGOS":
      return {
        ...state,
        artigos: payload,
      };
    case "GET_ARTIGO_BY_ID":
      return {
        ...state,
        artigoSelecionado: payload,
      };

    default:
      return state;
  }
};

export default blogReducer;
