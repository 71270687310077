import Instance from "../../../../Axios";

// New Actions for the app

const GetWorkPermitDefaultAccountables = () => async (dispatch) => {
  await Instance()
    .get(`/construcoes/workpermits/defaultAccountables`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({ type: "GET_WORK_PERMIT_DEFAULT_ACCOUNTABLES", payload: data.yData });
      // setObraSelecionada(data.yData[0]);
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetWorkPermitDefaultAccountables;