import { Card, Box, Typography } from '@mui/material';

//Gráficos
import GraficoEstadoIntervencoesDepartamento from '../dashboard/Graficos/GraficoEstadoIntervencoesDepartamento';
import GraficoValorIntervencoesAdjudicadas from '../dashboard/Graficos/GraficoValorIntervencoesAdjudicadas';
import GraficoEstadoIntervencoes from '../dashboard/Graficos/GraficoEstadoIntervencoes';
import GraficoTaxaSucesso from '../dashboard/Graficos/GraficoTaxaSucesso';
import GraficoPercInterAdjudicada from '../dashboard/Graficos/GraficoPercInterAdjudicada';

const TabletDash = ({ dimensions, dashInfoResumo, dashInfo }) => {
  let minWidth = null;

  if (dimensions.width > 1100) {
    minWidth = '25%';
  } else if (dimensions.width >= 860 && dimensions.width < 1100) {
    minWidth = '33%';
  } else if (dimensions.width >= 620 && dimensions.width < 860) {
    minWidth = '50%';
  } else if (dimensions.width < 620) {
    minWidth = '100%';
  }

  return (
    <>
      <div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Card
            sx={{
              mb: 5,
              p: 2,
              boxShadow: '0px 5px 15px lightgrey',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                // justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ width: minWidth, my: 2 }}>
                <Typography sx={{ fontSize: 20, fontWeight: '900' }}>
                  {new Intl.NumberFormat('pt-PT', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(dashInfoResumo && dashInfoResumo.valorAdjudicado)}
                </Typography>
                <Typography sx={{ fontSize: 15, mt: 1 }}>
                  <span
                    style={{
                      backgroundColor: '#DDF6FF',
                      paddingLeft: '7px',
                      paddingRight: '7px',
                      borderRadius: '10px',
                    }}
                  >
                    Valor adjudicado
                  </span>
                </Typography>
              </Box>
              <Box sx={{ width: minWidth, my: 2 }}>
                <Typography sx={{ fontSize: 20, fontWeight: '900' }}>
                  {dashInfoResumo && dashInfoResumo.totalIntervencoes}
                </Typography>
                <Typography sx={{ fontSize: 15, mt: 1 }}>
                  <span
                    style={{
                      backgroundColor: '#E2FEDE',
                      paddingLeft: '7px',
                      paddingRight: '7px',
                      borderRadius: '10px',
                    }}
                  >
                    Pedidos de Intervenções
                  </span>
                </Typography>
              </Box>
              <Box sx={{ width: minWidth, my: 2 }}>
                <Typography sx={{ fontSize: 20, fontWeight: '900' }}>
                  {dashInfoResumo && dashInfoResumo.intervencoesRespondidas}
                </Typography>
                <Typography sx={{ fontSize: 15, mt: 1 }}>
                  <span
                    style={{
                      backgroundColor: '#E2FEDE',
                      paddingLeft: '7px',
                      paddingRight: '7px',
                      borderRadius: '10px',
                    }}
                  >
                    Intervenções Respondidas
                  </span>
                </Typography>
              </Box>
              {/* --- */}

              <Box sx={{ width: minWidth, my: 2 }}>
                <Typography sx={{ fontSize: 20, fontWeight: '900' }}>
                  {dashInfoResumo && dashInfoResumo.intervencoesPorResponder}
                </Typography>
                <Typography sx={{ fontSize: 15, mt: 1 }}>
                  <span
                    style={{
                      backgroundColor: '#FFDFE8',
                      paddingLeft: '7px',
                      paddingRight: '7px',
                      borderRadius: '10px',
                    }}
                  >
                    Intervenções Não Respondidas
                  </span>
                </Typography>
              </Box>
              <Box sx={{ width: minWidth, my: 2 }}>
                <Typography sx={{ fontSize: 20, fontWeight: '900' }}>
                  {Math.floor(
                    dashInfoResumo && dashInfoResumo.idadeMediaIntervencoes
                  )}{' '}
                  dias
                </Typography>
                <Typography sx={{ fontSize: 15, mt: 1 }}>
                  <span
                    style={{
                      backgroundColor: '#E2FEDE',
                      paddingLeft: '7px',
                      paddingRight: '7px',
                      borderRadius: '10px',
                    }}
                  >
                    Idade Média das Intervenções
                  </span>
                </Typography>
              </Box>
              <Box sx={{ width: minWidth, my: 2 }}>
                <Typography sx={{ fontSize: 20, fontWeight: '900' }}>
                  {Math.floor(
                    dashInfoResumo && dashInfoResumo.tempoMedioResposta
                  )}{' '}
                  dias
                </Typography>
                <Typography sx={{ fontSize: 15, mt: 1 }}>
                  <span
                    style={{
                      backgroundColor: '#FFF7CE',
                      paddingLeft: '7px',
                      paddingRight: '7px',
                      borderRadius: '10px',
                    }}
                  >
                    Tempo médio de resposta
                  </span>
                </Typography>
              </Box>
            </div>
          </Card>
          {/* ---- Gráfico Estados das Intervenções por departamento ----*/}
          <Card
            sx={{
              mb: 5,
              p: 2,
              boxShadow: '0px 5px 15px lightgrey',
              width: '100%',
            }}
          >
            <Box>
              <GraficoEstadoIntervencoesDepartamento
                dimensions={dimensions && dimensions.width}
                estadosIntervencoesPorDepartamento={
                  dashInfo.estadosIntervencoesPorDepartamento
                }
              />
            </Box>
          </Card>
        </div>
      </div>
      {/* Segunda row de gráficos */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          style={{
            width: '100%',
            display: 'flex',
            // justifyContent: 'space-between',
          }}
        >
          <Card
            sx={{
              mb: 5,
              p: 2,
              boxShadow: '0px 5px 15px lightgrey',
              width: '49%',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <GraficoTaxaSucesso
                dimensions={dimensions && dimensions.width}
                taxaSucesso={dashInfo.taxaSucesso}
              />
            </Box>
          </Card>
          <span style={{ width: '2%' }} />
          <Card
            sx={{
              mb: 5,
              p: 2,
              boxShadow: '0px 5px 15px lightgrey',
              width: '49%',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <GraficoEstadoIntervencoes
                dimensions={dimensions && dimensions.width}
                estadosIntervencoes={dashInfo.estadoIntervencoes}
              />
            </Box>
          </Card>
        </Box>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Card
            sx={{
              mb: 5,
              p: 2,
              boxShadow: '0px 5px 15px lightgrey',
              width: '49%',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <GraficoValorIntervencoesAdjudicadas
                dimensions={dimensions && dimensions.width}
                valorIntervencoesAdjudicadas={
                  dashInfo.valorIntervencoesAdjudicadas
                }
              />
            </Box>
          </Card>
          <Card
            sx={{
              mb: 5,
              p: 2,
              boxShadow: '0px 5px 15px lightgrey',
              width: '49%',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <GraficoPercInterAdjudicada
                dimensions={dimensions && dimensions.width}
                percentagemIntervencoesAdjudicadas={
                  dashInfo.percentagemIntervencoesAdjudicadas
                }
              />
            </Box>
          </Card>
        </Box>
      </div>
    </>
  );
};

export default TabletDash;
