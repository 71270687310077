import React from 'react';
import './error.css';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const Error404 = () => {
  const location = useLocation();
  const page404 = location.pathname === '/404';
  const navigate = useNavigate();

  return (
    // <MuiThemeProvider theme={theme}>
    <div className='home' style={{ height: '100vh' }}>
      {!page404 && <Navigate to={'/404'} replace />}
      {/* <Navigate to={'/404'} replace />; */}
      <div className='error-back'>
        {/* <img src={Logo} alt="" className="logo" /> */}
        <main
          style={{
            textAlign: 'center',
          }}
          className='mainContent'
        >
          <div>
            <div className='titulo-404'>
              <div>404</div>
            </div>

            <div>
              <p>
                A página que procura não foi encontrada! Por favor volte á
                página anterior.
              </p>
            </div>

            <Button
              variant='contained'
              color='primary'
              // className={classes.btnVoltar}
              onClick={() => navigate(-1)}
            >
              Voltar
            </Button>
          </div>
          <div className='bottom-404' style={{ height: '100%' }}>
            Para mais informações, contacte a administração.
          </div>
        </main>
      </div>
      {/* <div className='error-right'>
          <div className='center404'></div>
        </div> */}
    </div>
    // </MuiThemeProvider>
  );
};

export default Error404;
