import Instance from "../../../../Axios";

// New Actions for the app

const UpdateWorkPermit =
  (wpId, yData, percurso, planta, anexos, workpermit) => async (dispatch) => {
    const formdata = new FormData();
    formdata.append("yData", JSON.stringify(yData[0]));
    if (percurso) {
      formdata.append("percurso", percurso.file);
      formdata.append("percursoidtipo", percurso.idTipo);
      formdata.append("percursopath", percurso.path);
    }

    if (planta) {
      formdata.append("planta", planta.file);
      formdata.append("plantaidtipo", planta.idTipo);
      formdata.append("plantapath", planta.path);
    }

    if (workpermit) {
      formdata.append("workpermit", workpermit.file);
      formdata.append("workpermitidtipo", workpermit.idTipo);
      formdata.append("workpermitpath", workpermit.path);
    }

    if (anexos && Array.isArray(anexos) && anexos.length > 0) {
      anexos.forEach((a, i) => {
        formdata.append(`anexo${i}`, a.file);
        formdata.append(`anexoidtipo${i}`, a.idTipo);
        formdata.append(`anexopath${i}`, a.path);
      });
    }
    await Instance()
      .put(`/construcoes/workpermits/update/${wpId}`, formdata)
      .then(({ data }) => {
        // console.log(data);
        //  dispatch({ type: "ADD_WORK_PERMIT", payload: data.idwp });
        // setObraSelecionada(data.yData[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default UpdateWorkPermit;
