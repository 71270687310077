import React from 'react';
import { makeStyles } from '@mui/styles';
import { Drawer } from '@mui/material';
//Components
import FormConsulta from './forms/formConsulta';
import FormEdit from './forms/formEdit';
import FormConsultaMobile from './forms/mobile/formConsultaMobile';

const useStyles = makeStyles({
  list: {
    // width: '45vw',
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
    width: "90vw",
  },
  fullList: {
    // width: 'auto',
    width: "90vw",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  btnUpload: {
    color: "#E21450",
    // width: '125px',
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const SideDrawer = ({
  state,
  toggleDrawer,
  intervencaoSelecionada,
  setIntervencaoSelecionada,
  fetchIntervencoes,
  drawerState,
  obras,
  drawerWidth,
  dimensions,

  // React hook form
  control,
  handleSubmit,
  reset,
  register,
  getValues,
  setValue,
  watch,

  resetForm,
  setResetForm,
  anoAtual,
  estado,
  subEstado,
  prioridades,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={() => toggleDrawer("right", false)}
        classes={{ paper: classes.paper }}
      >
        <div
          className={classes.list}
          style={
            dimensions.width <= 1100
              ? { width: "100vw" }
              : { width: `${drawerWidth}` }
          }
        >
          {drawerState === "check" && dimensions.width > 1100 ? (
            <FormConsulta
              intervencaoSelecionada={intervencaoSelecionada}
              toggleDrawer={toggleDrawer}
              classes={classes}
              handleSubmitForm={handleSubmit}
              dimensions={dimensions}
              fetchIntervencoes={fetchIntervencoes}
            />
          ) : null}
          {drawerState === "check" && dimensions.width <= 1100 ? (
            <FormConsultaMobile
              intervencaoSelecionada={intervencaoSelecionada}
              toggleDrawer={toggleDrawer}
              classes={classes}
              handleSubmitForm={handleSubmit}
              dimensions={dimensions}
            />
          ) : null}

          {drawerState === "edit" && (
            <FormEdit
              intervencaoSelecionada={intervencaoSelecionada}
              setIntervencaoSelecionada={setIntervencaoSelecionada}
              toggleDrawer={toggleDrawer}
              classes={classes}
              fetchIntervencoes={fetchIntervencoes}
              obras={obras}
              // React hook form
              control={control}
              handleSubmitForm={handleSubmit}
              register={register}
              reset={reset}
              getValues={getValues}
              setValue={setValue}
              resetForm={resetForm}
              setResetForm={setResetForm}
              watch={watch}
              anoAtual={anoAtual}
              estado={estado}
              subEstado={subEstado}
              prioridades={prioridades}
            />
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default SideDrawer;
