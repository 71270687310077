import Instance from "../../../../Axios";

// New Actions for the app

const GetPedidosPropostasParceiros =
  (parceirosId, dataInicio, dataFim) => async (dispatch) => {
    await Instance()
      .get(`/energias/dashboard/pedidosPropostasParceiros`, {
        params: { parceirosId, dataInicio, dataFim },
      })
      .then(({ data }) => {
        //   console.log(data);
        dispatch({
          type: "GET_PEDIDOS_PROPOSTAS_PARCEIROS",
          payload: data.yData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default GetPedidosPropostasParceiros;
