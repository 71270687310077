/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const GraficoTaxaSucesso = (taxasucesso) => {
  // const taxaSucesso = useSelector(
  //   (state) => state.energiasDashboard.taxasucesso
  // );

  const taxa = taxasucesso && taxasucesso.taxasucesso;

  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 25,
        style: {
          fontSize: "12px",
          colors: ["#000"],
        },
        formatter: function (value) {
          return value + " %";
        },
      },
      title: {
        text: "Taxa de Sucesso",
        align: "center",
        style: {
          fontFamily: "Lato",
        },
      },
      tooltip: {
        x: {
          show: false,
        },
        y: {
          formatter: function (value, series) {
            return (
              "<p>" + taxa[series.dataPointIndex].totalAdjudicados + "</p>"
            );
          },
          title: {
            formatter: function () {
              return "Adjudicados:";
            },
          },
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
        },
        max: 100,
        categories: [],
      },
      grid: {
        show: true,
      },
      fill: {
        opacity: 1,
      },
      colors: ["#FFA65D"],
    },
  });

  useEffect(() => {
    setState({
      series: [
        {
          data: taxa.map((item) => Math.round(item.taxaSucesso)),
        },
      ],
      options: {
        xaxis: {
          categories: taxa.map((item) => item.nome),
        },
      },
    });
  }, [taxa]);

  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={300}
        width={350}
      />
    </>
  );
};

export default GraficoTaxaSucesso;
