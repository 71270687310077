import Instance from "../../../../Axios";

// New Actions for the app

const GetCredAcompanhantes = () => async (dispatch) => {
  await Instance()
    .get(`/credenciacoes/acompanhantes`)
    .then(({ data }) => {
      dispatch({ type: "GET_CRED_ACOMPANHANTES", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetCredAcompanhantes;
