import Instance from "../../../Axios";

const GetPartnerById = (id) => async (dispatch) => {
  await Instance()
    .get(`/plus/adesoes/partners/${id}`)
    .then(({ data }) => {
      dispatch({ type: "GET_PARTNER_BY_ID", payload: data.yData[0] });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetPartnerById;
