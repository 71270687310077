import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, IconButton } from '@mui/material';
import { FiTrash2 } from 'react-icons/fi';
import Instance from '../../../../../../utils/Axios';
import BASE_URL from '../../../../../../utils/BASE_URL';

//Icons
import FeatherIcon from 'feather-icons-react';

const MultipleFileUploader = ({
  classes,
  files,
  setFiles,
  intervencaoSelecionada,
  newFiles,
  setNewFiles,
  removeFiles,
  setRemoveFiles,
  //Errors
  message,
  errorMessage,
  fileWarning,
  ShowNotification,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      intervencaoSelecionada &&
      intervencaoSelecionada.files &&
      intervencaoSelecionada.files.length > 0
    ) {
      const files = intervencaoSelecionada.files;
      setFiles(files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilesChange = (e) => {
    const values = [];

    for (let i = 0; i < e.target.files.length; i++) {
      const element = e.target.files[i];
      const objRepetido = files.find((obj) => obj.nome === element.name);
      const newObjRepetido = newFiles.find((obj) => obj.name === element.name);

      if (objRepetido != null || newObjRepetido != null) {
        dispatch(ShowNotification(fileWarning));
      } else {
        values.push(element);
      }
    }

    setNewFiles(newFiles.concat(values));
  };
  const handleRemoveNewFile = (e, filName) => {
    e.preventDefault();

    setNewFiles(
      newFiles.filter(function (file) {
        return file.name !== filName.name;
      })
    );
  };

  const handleRemoveFile = (e, fil) => {
    setFiles(
      files.filter(function (file) {
        return file.id !== fil.id;
      })
    );

    setRemoveFiles((current) => [...current, fil.id]);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          // marginTop: '20px',
          flexDirection: 'column',
          marginBottom: '20px',
          alignItems: 'flex-start',
        }}
      >
        {/* <button onClick={test}>Test</button> */}
        <Button
          style={{ height: '40px', borderRadius: '5px', margin: '10px 0px' }}
          className={classes.btnUpload}
        >
          <label htmlFor='propostas' className='custom-file-upload'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                cursor: 'pointer',
              }}
            >
              <p style={{ margin: '0px 10px' }}> Carregar Ficheiros</p>
            </div>
          </label>
          <input
            type='file'
            name='propostas'
            id='propostas'
            hidden
            onChange={(e) => handleFilesChange(e)}
            multiple
            accept='.pdf, .jpg'
          />
        </Button>

        <div style={{ width: '100%' }}>
          {files &&
            files.map((file, index) => {
              return (
                <div
                  style={{
                    marginRight: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                  key={index}
                >
                  <IconButton onClick={(e) => handleRemoveFile(e, file)}>
                    <FiTrash2 size={'1rem'} />
                  </IconButton>
                  <p style={{ margin: 0, wordBreak: 'break-all' }}>
                    {file.nome}
                  </p>
                </div>
              );
            })}

          {newFiles &&
            newFiles.map((file, index) => {
              return (
                <div
                  style={{
                    marginRight: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  key={index}
                >
                  <IconButton onClick={(e) => handleRemoveNewFile(e, file)}>
                    <FiTrash2 size={'1rem'} />
                  </IconButton>
                  {file.name}
                </div>
              );
            })}

          {(newFiles && newFiles.length > 0) ||
          (files && files.length <= 0) ? null : (
            <>
              <Button
                style={{
                  color: '#707070',
                }}
                onClick={() => {
                  Instance()
                    .get(
                      `${BASE_URL}/intervencoes/intervencoes/${intervencaoSelecionada.id}/download`,
                      { responseType: 'blob' }
                    )
                    .then(({ data }) => {
                      const downloadUrl = window.URL.createObjectURL(
                        new Blob([data])
                      );

                      const link = document.createElement('a');

                      link.href = downloadUrl;

                      link.setAttribute(
                        'download',
                        `intervencao_${intervencaoSelecionada.id}.zip`
                      ); //any other extension

                      document.body.appendChild(link);

                      link.click();

                      link.remove();
                      dispatch(ShowNotification(message));
                    })
                    .catch((err) => {
                      console.log(err.response);
                      if (err.response) {
                        if (err.response === 403) {
                          dispatch(ShowNotification(errorMessage));
                        }
                      }
                    });
                }}
              >
                <FeatherIcon
                  icon='download'
                  size={18}
                  style={{
                    strokeWidth: '1.5',
                    marginRight: '10px',
                    marginLeft: '-1px',
                  }}
                />
                Download
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MultipleFileUploader;
