import Instance from "../../../../Axios";

// New Actions for the app

const DeleteFotoObra = (idfoto) => async (dispatch) => {
  await Instance()
    .put(`/construcoes/obras/fotos/${idfoto}/delete`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({ type: "DELETE_FOTO_OBRA", payload: data.yData });
      // setObraSelecionada(data.yData[0]);
    })
    .catch((err) => {
      console.log(err);
    });
};

export default DeleteFotoObra;
