import Instance from "../../../../Axios";

// New Actions for the app

const DeleteSetor = (id) => async (dispatch) => {
  await Instance()
    .put(`/energias/setores/${id}`)
    .then(({ data }) => {
      //   console.log(data);
      dispatch({ type: "DELETE_SETOR", payload: data.yData[0] });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default DeleteSetor;
