import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Card, Drawer, TextField } from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptLocale from 'date-fns/locale/pt';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import { startOfYear } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles({
  list: {
    width: '80vw',
    borderRadius: '10px 0px 0px 10px !important',
    // background: 'red !important',
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    background: '#FAFAFA',
  },
  btnGuardar: {
    color: 'white',
    width: '120px',
    height: '40px',
    // borderRadius: '15px',
    backgroundColor: '#E21450',
    border: `1px solid #E21450`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#bc1041',
      border: '1px solid #bc1041',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#bc1041',
      },
      '&:disabled': {
        backgroundColor: '#bc1041',
      },
    },
  },
  btnCloseDrawer: {
    color: '#464255',
    width: '30px',
    height: '40px',
    borderRadius: '15px',
    backgroundColor: '#f2f2f2',
    border: `1px solid #f2f2f2`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#e0e0e0',
      border: '1px solid #e0e0e0',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#e0e0e0',
      },
    },
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  noBorder: {
    border: 'none',
  },
  btnFiltrar: {
    color: '#E21450',
    width: '115px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  btnTrue: {
    display: 'inline-block',
    width: '100%',
    height: 40,
    border: '1px solid lightgrey',
    borderRadius: '5px',
    background: 'white',
    '&:focus': {
      background: 'white',
    },
    '&:hover': {
      backgroundColor: 'lightgrey',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'lightgrey',
      },
    },
  },
  btnFalse: {
    display: 'inline-block',
    width: '100%',
    height: 40,
    border: '1px solid lightgrey',
    borderRadius: '5px',
    background: 'lightgrey',
    '&:focus': {
      background: 'lightgrey',
    },
    '&:hover': {
      backgroundColor: 'lightgrey',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'lightgrey',
      },
    },
  },
});

const FilterDrawer = ({
  state,
  toggleDrawer,

  dimensions,
  fetchInfoGraficos,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [dataInicio, setDataInicio] = useState(startOfYear(new Date()));
  const [dataFim, setDataFim] = useState(new Date());

  const perfilId = useSelector((state) => state.user.perfilId);

  const departamentos = useSelector(
    (state) => state.intervencoes.departamentosDashboard
  );

  const handleChange = (item) => {
    // eslint-disable-next-line eqeqeq
    const itemIndex = departamentos.findIndex((i) => i.id == item.id);

    if (departamentos[itemIndex].checked === true) {
      departamentos[itemIndex].checked = false;
    } else if (departamentos[itemIndex].checked === false) {
      departamentos[itemIndex].checked = true;
    }

    dispatch({
      type: 'GET_DEPARTAMENTOS_DASHBOARD',
      // **--- Podemos não fazer o spread do array que estamos a inserir. porém, temos que e, alguma situação, neste caso no dashboard, chamar a variável que se encontra no top level/pai para que os valores serem referenciados e reconhecidos desde inicio --**
      // payload: departamentos,
      payload: [...departamentos],
    });
  };

  const handleDataInicioChange = (date) => {
    setDataInicio(date);
  };

  const handleDataFimChange = (date) => {
    setDataFim(date);
  };
  const handleSubmit = () => {
    if (perfilId === 5) {
      const departamentosId = null;
      fetchInfoGraficos(departamentosId, dataInicio, dataFim);
    } else {
      const departamentosId = departamentos
        .filter(function (par) {
          if (par.checked === false) {
            return false; // skip
          }
          return true;
        })
        .map(function (par) {
          return par.id;
        });
      console.log(departamentosId);
      fetchInfoGraficos(departamentosId, dataInicio, dataFim);
    }
  };

  return (
    <div>
      <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={() => toggleDrawer('right', false)}
        classes={{ paper: classes.paper }}
      >
        <div
          className={classes.list}
          style={
            dimensions.width <= 1100 ? { width: '100%' } : { width: `100%` }
          }
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              m: 5,
            }}
            onClick={() => toggleDrawer('right', false)}
          >
            <Box
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <div>
                <FeatherIcon
                  icon='chevron-left'
                  size={20}
                  style={{ strokeWidth: '1.5' }}
                />
              </div>
              <div className='text-underline-close-drawer'>Dashboard</div>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant='contained'
                className={classes.btnFiltrar}
                onClick={() => {
                  handleSubmit();
                  toggleDrawer('right', false);
                }}
              >
                Filtrar
              </Button>
            </Box>
          </Box>
          <Card sx={{ m: 5, p: 2, boxShadow: '0px 10px 15px lightgrey' }}>
            <Box
              sx={{
                m: 2,
              }}
            >
              <h4
                style={{
                  fontSize: '22px',
                  width: '100%',
                  marginBottom: '0px',
                }}
                className='text-underline'
              >
                Filtros
              </h4>
            </Box>
            <Box sx={{ m: 2 }}>
              <h4
                style={{ color: '#E21450' }}
                className='text-underline-title-drawer '
              >
                Período
              </h4>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                m: 2,
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', mr: 1 }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <b>Data Inicio</b>
                  <DatePicker
                    style={{ width: '100%' }}
                    margin='normal'
                    id='date-picker-dialog'
                    format='dd/MM/yyyy'
                    okLabel='ok'
                    cancelLabel='cancelar'
                    invalidDateMessage='Data Inválida'
                    value={dataInicio}
                    onChange={handleDataInicioChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'dd/mm/aaaa',
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant='outlined'
                        size='small'
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <b>Data Fim</b>
                  <DatePicker
                    style={{ width: '100%' }}
                    margin='normal'
                    id='date-picker-dialog'
                    format='dd/MM/yyyy'
                    okLabel='ok'
                    cancelLabel='cancelar'
                    invalidDateMessage='Data Inválida'
                    value={dataFim}
                    onChange={handleDataFimChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'dd/mm/aaaa',
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant='outlined'
                        size='small'
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>

            <Box>
              <Box
                sx={{
                  m: 2,
                  mb: 4,
                }}
              >
                <h4
                  style={{ color: '#E21450' }}
                  className='text-underline-title-drawer '
                >
                  Departamentos
                </h4>
              </Box>
              {departamentos.map((item) => (
                <Box sx={{ m: 2 }} key={item.id}>
                  <Button
                    className={
                      // eslint-disable-next-line eqeqeq
                      item.checked == true ? classes.btnTrue : classes.btnFalse
                    }
                    onClick={() => handleChange(item)}
                  >
                    {item.descricao}
                  </Button>
                </Box>
              ))}
            </Box>
          </Card>
        </div>
      </Drawer>
    </div>
  );
};

export default FilterDrawer;
