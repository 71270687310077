import Instance from '../../../Axios';

const AddIntervencao =
  (ydata, images, ShowNotification, message, errorMessage) =>
  async (dispatch) => {
    const formdata = new FormData();

    console.log(images);

    formdata.append('ydata', JSON.stringify(ydata));

    for (var i = 0; i < images.length; i++) {
      formdata.append('images', images[i]);
    }

    console.log([...formdata]);

    await Instance()
      .post(`/intervencoes/intervencoes`, formdata)
      .then(({ data }) => {
        console.log(data);
        dispatch({ type: 'ADD_INTERVENCAO', payload: data.yData[0] });
        dispatch(ShowNotification(message));
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          if (err.response.status === 403) {
            dispatch(ShowNotification(errorMessage));
          }
        } else {
          dispatch(ShowNotification(errorMessage));
        }
      });
  };

export default AddIntervencao;
