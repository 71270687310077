import Instance from '../../../Axios';

const EditarUtilizador =
  (
    ydata,
    id,
    ShowNotification,
    message,
    errorMessage,
    toggleDrawer,
    setGuardarLoading
  ) =>
  async (dispatch) => {
    await Instance()
      .put(`/utilizadores/${id}`, { ydata })
      .then(({ data }) => {
        console.log(data);
        dispatch({ type: 'PUT_UTILIZADOR', payload: data });
        toggleDrawer('right', false);
        dispatch(ShowNotification(message));
        //   show('success', 'Foram associados novos funcionários á obra');
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 403) {
            const warningMessage = {
              isOpen: true,
              message: err.response.data.message,
              type: 'warning',
            };

            dispatch(ShowNotification(warningMessage));
          } else {
            dispatch(ShowNotification(errorMessage));
          }
        }
      });
  };

export default EditarUtilizador;
