import Instance from "../../../Axios";

const GetAllPartners = () => async (dispatch) => {
  await Instance()
    .get(`/plus/adesoes/partners/all/`)
    .then(({ data }) => {
      dispatch({ type: "GET_ALL_PARTNERS", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetAllPartners;
