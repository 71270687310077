import React from 'react';
import { TextField, Typography } from '@mui/material';

const Estados = ({ obraSelecionada }) => {
  return (
    <>
      <div>
        <h4
          style={{ color: '#E21450' }}
          className='text-underline-title-drawer '
        >
          Estados
        </h4>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '252px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Projeto
            </Typography>

            <TextField
              size='small'
              value={
                (obraSelecionada && obraSelecionada.estadosProjetosDescricao) ||
                ' --/--/---- '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <span style={{ width: '24px' }} />
          <div style={{ width: '252px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Subprojeto{' '}
            </Typography>
            <TextField
              size='small'
              value={
                (obraSelecionada &&
                  obraSelecionada.estadosSubProjetosDescricao) ||
                ' --/--/---- '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Estados;
