import Instance from "../../../../Axios";

// New Actions for the app

const GetWorkPermitUploadedFiles = (idwp) => async (dispatch) => {
  await Instance()
    .get(`/construcoes/workpermits/workpermit/${idwp}/uploadedFicheiros`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({ type: "GET_WORK_PERMIT_UPLOADED_FILES", payload: data.yData });
      // setObraSelecionada(data.yData[0]);
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetWorkPermitUploadedFiles;