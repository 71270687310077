import { startOfYear } from "date-fns";
import moment from "moment";

export const filtersEnergiasInitialState = {
  dashboardDataInicio: startOfYear(new Date()),
  dashboardDataFim: new Date(),
  dashboardParceiros: [],
  dashboardParceirosSelected: [],
  controloMensalData: moment(),
  timelineDataInicio: startOfYear(new Date()),
  timelineDataFim: new Date(),
  estadosProjetoPreSelected: [
    { id: 0, descricao: "Proposta" },
    { id: 2, descricao: "Aberto" },
    { id: 3, descricao: "Fechado" },
  ],
  estadosSubProjetoPreSelected: [
    { id: 1, descricao: "Aberto" },
    { id: 2, descricao: "Iniciado" },
  ],
  estadosPropostasPreSelected: [
    { id: 0, descricao: "Aberta" },
    { id: 1, descricao: "Adjudicada" },
    { id: 2, descricao: "Fechada" },
  ],
  propostasDataInicio: startOfYear(new Date()),
  propostasDataFim: new Date(),
};
