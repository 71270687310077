import Instance from "../../../../Axios";

const InserirFotos =
  (ficheiros, obraId, ShowNotification, message, errorMessage) =>
  async (dispatch) => {
    const formdata = new FormData();
    if (ficheiros && ficheiros.length > 0) {
      ficheiros.forEach((fileInfo) => {
        formdata.append(`file`, fileInfo.file);
        formdata.append(`idTipo`, fileInfo.idTipo);
        formdata.append(`path`, fileInfo.path);
      });
    }
    await Instance()
      .post(`/construcoes/obras/${obraId}/fotos`, formdata)
      .then(() => {
        dispatch(ShowNotification(message));
      })
      .catch((err) => {
        if (err.response) {
          if (err.response === 403) {
            dispatch(ShowNotification(errorMessage));
          } else {
            dispatch(ShowNotification(errorMessage));
          }
        }
      });
  };

export default InserirFotos;
