/* eslint-disable eqeqeq */
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { persistor } from "../../../../utils/store";
import { useState } from "react";

const useStyles = makeStyles(() => ({
  button: {
    display: "flex",
    justifyContent: "center",
    height: 40,
    borderTop: "4px solid transparent",
    borderBottom: "4px solid transparent",

    "&:hover": {
      borderRight: "4px solid #E21450",
    },
  },
  divider: {
    borderLeft: "4px solid #E21450",
    borderRadius: "5px 0px 0px 5px",
    orientation: "vertical",
    height: "30px",
    width: "5px",
  },
}));

const AprovisionamentoSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const userPerfil = useSelector((state) => state.user.perfilId);
  const permissoes = useSelector((state) => state.user.permissoes);
  const token = useSelector((state) => state.user.auth_utilizadores_url_key);

  console.log(permissoes);

  const menuList1a = [
    {
      id: 1,
      tooltip: "Home",
      path: "#",
      icon: "home",
      fiIconCheck: true,
      submenu: [
        {
          id: 1,
          name: "Dashboard AHD Admin",
          path: `/dashboardahdadmin/${token}`,
          permissao: "APP_DASHBOARD_AHD_ADMIN",
        },
        {
          id: 2,
          name: "Dashboard AHD Public",
          path: `/dashboardahd/${token}`,
          permissao: "APP_DASHBOARD_AHD",
        },
        {
          id: 3,
          name: "Dashboard AHD Serralharia",
          path: `/dashboardahdserralharia/${token}`,
          permissao: "APP_DASHBOARD_AHD",
        },
        {
          id: 4,
          name: "Lista Obras",
          path: "/construcoes/obras",
          permissao: "APP_CONSTRUCOES",
        },
        {
          id: 5,
          name: "Lista Responsáveis ",
          path: "/construcoes/responsaveisWorkPermits",
          permissao: "APP_WORK_PERMITS_RESPONSAVEIS",
        },
      ],
    },
    {
      id: 2,
      tooltip: "Credenciações",
      path: "#",
      icon: "clipboard",
      fiIconCheck: true,
      submenu: [
        {
          id: 1,
          name: " Dashboard",
          path: "/credenciacoes/dashboard",
          permissao: "APP_CRED_CREDENCIACOES_DASHBOARD",
        },
        {
          id: 2,
          name: "Credenciações",
          path: "/credenciacoes/credenciacoes",
          permissao: "APP_CRED_CREDENCIACOES",
        },
        {
          id: 4,
          name: "Colaboradores",
          path: "/credenciacoes/colaboradores",
          permissao: "APP_CRED_COLABORADORES",
        },
        {
          id: 5,
          name: "Viaturas",
          path: "/credenciacoes/viaturas",
          permissao: "APP_CRED_VIATURAS",
        },
        {
          id: 5,
          name: "Destinatários",
          path: "/credenciacoes/destinatarios",
          permissao: "APP_CRED_DESTINATARIOS",
        },
        {
          id: 3,
          name: "Obras",
          path: "/credenciacoes/obras",
          permissao: "APP_CRED_OBRAS",
        },
      ],
    },
  ];

  const menuList1p = [
    {
      tooltip: "Home",
      path: "/construcoes/obras",
      icon: "home",
      fiIconCheck: true,
    },
  ];

  const [state, setState] = useState({
    settings: [
      { id: 1, open: false },
      { id: 2, open: false },
    ],
  });

  const handleClick = (id) => {
    setState((state) => ({
      ...state,
      settings: state.settings.map((item) =>
        item.id === id
          ? { ...item, open: !item.open }
          : { ...item, open: false }
      ),
    }));
  };

  const settings = state.settings;

  const handleSair = () => {
    // setAnchorEl(null);

    localStorage.removeItem("token");
    localStorage.removeItem("persist:root");
    navigate("/");
    persistor.purge();
    dispatch({ type: "USER_LOGOUT" });
    // rootReducer(store, { type: 'USER_LOGOUT' });
  };

  return (
    <Box sx={{ display: "flex", zIndex: 1000 }}>
      <Box
        sx={{
          opacity: 1,
          boxShadow: "0px 5px 15px lightgrey",
          width: "70px",
          height: "100%",
          position: "fixed",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          mt: -6,
          pb: 2,
        }}
      >
        <Box>
          <Box sx={{ mt: 9 }}></Box>
          <List
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            {userPerfil !== 5 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: 4,
                  justifyContent: "space-between",
                  height: "auto",
                }}
              >
                {menuList1a.map((item, index) => (
                  <>
                    <ListItem
                      key={item.id}
                      disablePadding
                      onClick={() => navigate(item.path)}
                      sx={{ mb: 4 }}
                    >
                      <Tooltip title={item.tooltip} placement="right">
                        <ListItemButton
                          sx={{
                            borderRight:
                              location.pathname === item.path ||
                              (item.submenu &&
                                item.submenu
                                  .map((item) => item.path)
                                  .includes(location.pathname))
                                ? "4px solid #E21450"
                                : null,
                          }}
                          className={classes.button}
                          onClick={() => handleClick(item.id)}
                        >
                          <ListItemIcon
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            {item.fiIconCheck === true ? (
                              <FeatherIcon
                                icon={item.icon}
                                color={
                                  location.pathname === item.path
                                    ? "#E21450"
                                    : "#707070"
                                }
                                size={30}
                                strokeWidth={1.5}
                              />
                            ) : (
                              item.icon
                            )}
                          </ListItemIcon>
                        </ListItemButton>
                      </Tooltip>
                      {/* {location.pathname == item.path ? <Divider orientation="vertical" className={classes.divider} /> : null} */}
                    </ListItem>
                    {item && item.submenu && item.submenu.length > 0 ? (
                      <Collapse
                        in={settings.find((elem) => elem.id === item.id).open}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box
                          sx={{
                            opacity: 1,
                            boxShadow: "5px 0px 15px lightgrey",
                            width: "auto",
                            height: "auto",
                            position: "fixed",
                            background: "#fff",
                            display: "flex",
                            flexDirection: "column",
                            left: "70px",
                            mt: -9,
                          }}
                        >
                          <List
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                            }}
                          >
                            {item.submenu.map((elem) => (
                              <>
                                {permissoes.includes(elem.permissao) ? (
                                  <ListItem
                                    disablePadding
                                    onClick={() => {
                                      console.log(elem.path);
                                      elem.path == `/dashboardahd/${token}` ||
                                      elem.path ==
                                        `/dashboardahdadmin/${token}` ||
                                      elem.path ==
                                        `/dashboardahdserralharia/${token}`
                                        ? window.open(elem.path)
                                        : navigate(elem.path);
                                    }}
                                  >
                                    <ListItemButton
                                      className={classes.button}
                                      onClick={() => handleClick(item.id)}
                                    >
                                      <ListItemText primary={elem.name} />
                                    </ListItemButton>
                                  </ListItem>
                                ) : null}
                              </>
                            ))}
                          </List>
                        </Box>
                      </Collapse>
                    ) : null}
                  </>
                ))}
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: 4,
                  justifyContent: "space-between",
                  height: "auto",
                }}
              >
                {menuList1p.map((item, index) => (
                  <>
                    <ListItem
                      disablePadding
                      onClick={() => navigate(item.path)}
                      sx={{ mb: 4 }}
                    >
                      <Tooltip title={item.tooltip} placement="right">
                        <ListItemButton
                          sx={{
                            borderRight:
                              location.pathname === item.path ||
                              (item.submenu &&
                                item.submenu
                                  .map((item) => item.path)
                                  .includes(location.pathname))
                                ? "4px solid #E21450"
                                : null,
                          }}
                          className={classes.button}
                          onClick={() => handleClick(item.id)}
                        >
                          <ListItemIcon
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            {item.fiIconCheck === true ? (
                              <FeatherIcon
                                icon={item.icon}
                                color={
                                  location.pathname === item.path
                                    ? "#E21450"
                                    : "#707070"
                                }
                                size={30}
                                strokeWidth={1.5}
                              />
                            ) : (
                              item.icon
                            )}
                          </ListItemIcon>
                        </ListItemButton>
                      </Tooltip>
                      {/* {location.pathname == item.path ? <Divider orientation="vertical" className={classes.divider} /> : null} */}
                    </ListItem>
                  </>
                ))}
              </Box>
            )}
            <Divider
              variant="middle"
              sx={{
                mt: -2,
              }}
            />
          </List>
        </Box>
        <Box>
          <Divider
            variant="middle"
            sx={{
              mb: 2,
            }}
          />
          <List>
            <ListItem disablePadding onClick={handleSair}>
              <Tooltip title="Sair" placement="right">
                <ListItemButton
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <ListItemIcon
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <FeatherIcon
                      icon="log-out"
                      color="#707070"
                      size={30}
                      strokeWidth={1.5}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </List>
        </Box>
      </Box>
      <Box sx={{ pl: 8 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default AprovisionamentoSidebar;
