import Instance from "../../../Axios";

const GetPlusCiclos = () => async (dispatch) => {
  await Instance()
    .get(`/plus/adesoes/data/ciclos`)
    .then(({ data }) => {
      dispatch({ type: "GET_PLUS_CICLOS", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetPlusCiclos;
