import Instance from "../../../../Axios";

// New Actions for the app

const GetAllFichasTecnicas = (fabricanteId, setorId) => async (dispatch) => {
  await Instance()
    .get(`/energias/fichasTecnicas`, { params: { fabricanteId, setorId } })
    .then(({ data }) => {
      dispatch({ type: "GET_ALL_FICHAS_TECNICAS", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetAllFichasTecnicas;
