/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import moment from "moment";
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const GraficoTotalPropostasDia = (orcamentosMes) => {
  const pedidos = orcamentosMes && orcamentosMes.orcamentosMes;

  pedidos.map(
    (item) =>
      (item.data_criacao = moment(item.data_criacao).format("YYYY-MM-DD"))
  );

  pedidos.sort(
    (a, b) =>
      new moment(a.data_criacao).format("YYYYMMDD") -
      new moment(b.data_criacao).format("YYYYMMDD")
  );

  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: "100%",
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        text: "Pedidos de Orçamentos Mensais",
        style: {
          fontFamily: "Lato",
        },
      },
      tooltip: {
        x: {
          formatter: function (val, opts) {
            return moment(val).format("DD-MM-YYYY");
          },
        },
      },
      xaxis: {
        labels: {
          show: true,
          formatter: function (val, opts) {
            return moment(val).format("DD");
          },
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        max:
          Math.max(...pedidos.map((item) => item.pedidos)) +
          Math.max(...pedidos.map((item) => item.pedidos)) * 0.5,
        categories: [],
      },
      grid: {
        show: true,
      },
      fill: {
        opacity: 1,
      },
    },
  });

  useEffect(() => {
    setState({
      series: [
        {
          name: "Por Responder",
          data: pedidos.map((item) => item.propostasPorResponder),
        },
        {
          name: "Respondidas",
          data: pedidos.map((item) => item.propostasRespondidas),
        },
      ],
      options: {
        xaxis: {
          categories: pedidos.map((item) => item.data_criacao),
          max:
            Math.max(...pedidos.map((item) => item.pedidos)) +
            Math.max(...pedidos.map((item) => item.pedidos)) * 0.5,
        },
      },
    });
  }, [pedidos]);

  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={500}
        width={1740}
      />
    </>
  );
};

export default GraficoTotalPropostasDia;
