import { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { startOfYear } from 'date-fns';
import { Box, Button } from '@mui/material';

// Components
// import FiltersObras from './components/filters';
import FooterLinks from '../../../Global/Components/Footer';
import FilterDrawer from './components/dashboard/filterDrawer';
//Responsive Dashboards
import DesktopDash from './components/responsive/desktopDash';
import TabletDash from './components/responsive/tabletDash';
import MobileDash from './components/responsive/mobileDash';

//Actions
import GetDepartamentos from '../../../utils/redux/actions/intervencoes/dashboard/GetDepartamentosDashboardAction';
import GetIntervencoesInfoGraficos from '../../../utils/redux/actions/intervencoes/dashboard/GetIntervencoesInfoGraficosAction';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      // margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnFiltrar: {
    color: '#E21450',
    width: '115px',
    height: '40px',
    fontWeight: '900',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  stateLabel: {
    textTransform: 'none',
    transition: '0.2s',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100px',
  },

  paperFilters: {
    background: 'transparent',
    padding: '20px 0px',
    boxShadow: 'none',
  },
}));

// ############################################################## //
// ####################  Window Dimensions  ##################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const IntervencoesDashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  //DRAWER
  // eslint-disable-next-line no-unused-vars

  const dimensions = useWindowDimensions();
  const [state, setState] = useState({
    right: false,
  });
  // eslint-disable-next-line no-unused-vars
  const [drawerState, setDrawerState] = useState(null);
  const toggleDrawer = (anchor, open) => {
    // eslint-disable-next-line eqeqeq
    if (open == false) {
      setState({ ...state, [anchor]: open });
    }
    // eslint-disable-next-line eqeqeq
    if (open == true) {
      setState({ ...state, [anchor]: open });
    }
  };

  // ** -- Podemos utilizar esta função abaixo para chamar o "top level"/pai do state que vamos utilizar a seguir para registar ps de+artamentos que stamos a selecionar. Caso nao chamemos isto, temo que posteriormente, utilizar um spread quando formos fazer uma mudança, que neste caso é o onChange do filterDrawer. -- ** //
  // useSelector((state) => state.intervencoes);

  const departamentos = useSelector(
    (state) => state.intervencoes.departamentosDashboard,
    shallowEqual
  );

  const dataInicio = startOfYear(new Date());
  const dataFim = new Date();

  const dashInfo = useSelector(
    (state) => state.intervencoes.intervencoesInfoGraficos
  );

  const dashInfoResumo = dashInfo && dashInfo.resumo;

  //intervencoes
  const fetchInfoGraficos = (departamentosId, dataInicio, dataFim) => {
    dispatch(GetIntervencoesInfoGraficos(departamentosId, dataInicio, dataFim));
  };
  const fetchDepartamentosDashboard = () => {
    dispatch(GetDepartamentos());
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchDepartamentosDashboard(), []);

  useEffect(() => {
    let departamentosId = departamentos.map((item) => item.id);
    fetchInfoGraficos(departamentosId, dataInicio, dataFim);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departamentos]);

  return (
    <>
      <div
        style={{
          width: 'calc(100% - 90px)',
          marginLeft: dimensions && dimensions.width > 620 ? '50px' : '20px',
          marginRight: dimensions && dimensions.width > 620 ? '40px' : '10px',
        }}
      >
        <div
          style={{
            marginTop: '25px',

            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div>
            <h4
              style={{
                fontSize: '22px',
                // width: '100%',
              }}
              className='text-underline'
            >
              Dashboard
            </h4>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              variant='contained'
              className={classes.btnFiltrar}
              onClick={() => toggleDrawer('right', true)}
            >
              Filtros
            </Button>
          </div>
        </div>
        {dimensions ? (
          <>
            {dimensions.width >= 1560 && (
              <DesktopDash
                dashInfoResumo={dashInfoResumo}
                dashInfo={dashInfo}
                dimensions={dimensions}
              />
            )}
            {dimensions.width >= 935 && dimensions.width < 1560 && (
              <TabletDash
                dashInfoResumo={dashInfoResumo}
                dashInfo={dashInfo}
                dimensions={dimensions}
              />
            )}
            {dimensions.width < 935 && (
              <MobileDash
                dashInfoResumo={dashInfoResumo}
                dashInfo={dashInfo}
                dimensions={dimensions}
              />
            )}
          </>
        ) : null}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <FilterDrawer
            state={state}
            toggleDrawer={toggleDrawer}
            drawerState={drawerState}
            dimensions={dimensions}
            fetchInfoGraficos={fetchInfoGraficos}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent:
              dimensions && dimensions.width > 860 ? 'flex-end' : 'flex-start',
            alignItems: 'center',
          }}
        >
          {dashInfo ? (
            <>
              <Box>
                <div style={{ margin: '20px 40px' }}>
                  <FooterLinks dimensions={dimensions} />
                </div>
              </Box>
            </>
          ) : null}
        </Box>
      </div>
    </>
  );
};

export default IntervencoesDashboard;
