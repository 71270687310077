import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chart from 'react-apexcharts';

//Actions
import DashboardEnergiasEstadosProposta from '../../../../utils/redux/actions/global/dashboard/GetDashEnergiasEstadosPropostas';

const EnergiaEstadosProposta = () => {
  const dispatch = useDispatch();

  const fetchEnergiaEstadosProposta = () => {
    dispatch(DashboardEnergiasEstadosProposta());
  };

  const estadosState = useSelector(
    (state) => state.dashboard.energiasEstadosPropostas
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchEnergiaEstadosProposta(), []);

  return (
    <>
      {estadosState ? (
        <Chart
          type="donut"
          width={400}
          height={400}
          series={estadosState && estadosState.series}
          options={{
            labels: estadosState && estadosState.labels,
            tooltip: {
              y: {
                formatter: (val) => {
                  return `${val} Obras`;
                },
              },
            },
            title: {
              text: "Energias - Propostas",
            },
            colors: ["#FFA65D", "#4FC0E8", "#8CC051", "#FF4A4A"],
          }}
        />
      ) : null}
    </>
  );
};

export default EnergiaEstadosProposta;
