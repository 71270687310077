import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, FormControlLabel, Checkbox } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

const CustomCheckbox = withStyles({
  root: {
    color: '#707070',
    '&$checked': {
      color: '#707070',
    },
    background: 'transparent !important',
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

const useStyles = makeStyles((theme) => ({
  modalBtns: {
    // paddingTop: 20,
    height: '40px',
    width: '125px',
    display: 'flex',
    // flexWrap: 'wrap',
    // width: '100%',
    // justifyContent: 'start',
  },

  btnFiltrar: {
    color: '#E21450',
    width: '125px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
}));

const FiltersObrasCheck = ({
  estadoProjeto,
  estadoSubProjeto,
  fetchObras,
  estadosProjetoPreSelectedId,
  estadosSubProjetoPreSelectedId,
  parceiroId,
  // CONTROLO
  controlo,
  controloPreSelectedId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const eProjetosId = estadosProjetoPreSelectedId.map((ep) => ep.id);
    const eSubProjetosId = estadosSubProjetoPreSelectedId.map((ep) => ep.id);
    const controloId = controloPreSelectedId.map((ep) => ep.id);

    fetchObras(eProjetosId, eSubProjetosId, controloId, parceiroId);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          // justifyContent: 'space-between',
          alignItems: "start",
          width: "100%",
          marginTop: "40px",
        }}
        className="not-selectable"
      >
        <div style={{ width: "15%" }}>
          <div>
            <div
              className="text-underline-title-drawer"
              style={{ width: "100%" }}
            >
              Estados do Projeto
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {estadoProjeto.map((estado, index) => {
                const estadoExists = !estadosProjetoPreSelectedId.some(
                  (o2) => o2.id === estado.id
                );

                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "50%",
                      cursor: "pointer",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          // eslint-disable-next-line eqeqeq
                          checked={!estadoExists}
                          onChange={() => {
                            if (!estadoExists) {
                              dispatch({
                                type: "ENERGIAS_ESTADOS_PROJETO_PRE_SELECTED",
                                payload: estadosProjetoPreSelectedId.filter(
                                  (est) => est.id !== estado.id
                                ),
                              });
                            } else {
                              dispatch({
                                type: "ENERGIAS_ESTADOS_PROJETO_PRE_SELECTED",
                                payload: [
                                  ...estadosProjetoPreSelectedId,
                                  estado,
                                ],
                              });
                            }
                          }}
                          name={estado.descricao}
                        />
                      }
                      label={estado.descricao}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <span style={{ width: "2%" }} />
        <div style={{ width: "30%" }}>
          <div>
            <div
              className="text-underline-title-drawer"
              style={{ width: "100%" }}
            >
              Estados do SubProjeto
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                // justifyContent: 'space-evenly',
              }}
            >
              {estadoSubProjeto.map((estado, index) => {
                const estadoExists = !estadosSubProjetoPreSelectedId.some(
                  (o2) => o2.id === estado.id
                );
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "25%",
                      cursor: "pointer",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          // eslint-disable-next-line eqeqeq
                          checked={!estadoExists}
                          onChange={() => {
                            if (!estadoExists) {
                              dispatch({
                                type: "ENERGIAS_ESTADOS_SUBPROJETO_PRE_SELECTED",
                                payload: estadosSubProjetoPreSelectedId.filter(
                                  (est) => est.id !== estado.id
                                ),
                              });
                            } else {
                              dispatch({
                                type: "ENERGIAS_ESTADOS_SUBPROJETO_PRE_SELECTED",
                                payload: [
                                  ...estadosSubProjetoPreSelectedId,
                                  estado,
                                ],
                              });
                            }
                          }}
                          name={estado.descricao}
                        />
                      }
                      label={estado.descricao}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <span style={{ width: "2%" }} />
        <div style={{ width: "20%" }}>
          <div>
            <div
              className="text-underline-title-drawer"
              style={{ width: "100%" }}
            >
              Controlo
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                // justifyContent: 'space-evenly',
              }}
            >
              {controlo.map((estado, index) => {
                const estadoExists = !controloPreSelectedId.some(
                  (o2) => o2.id === estado.id
                );
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "50%",
                      cursor: "pointer",
                    }}
                    // onClick={() => {
                    //   if (!estadoExists) {
                    //     dispatch({
                    //       type: 'ENERGIAS_ESTADOS_CONTROLO_PRE_SELECTED',
                    //       payload: controloPreSelectedId.filter(
                    //         (est) => est.id !== estado.id
                    //       ),
                    //     });
                    //   } else {
                    //     dispatch({
                    //       type: 'ENERGIAS_ESTADOS_CONTROLO_PRE_SELECTED',
                    //       payload: [...controloPreSelectedId, estado],
                    //     });
                    //   }
                    // }}
                  >
                    {/* <FeatherIcon
                      icon={estadoExists ? 'square' : 'check-square'}
                      size={16}
                      style={
                        estadoExists
                          ? {
                              color: '#333',
                              marginRight: '5px',
                              strokeWidth: '1.5',
                            }
                          : {
                              color: '#E21450',
                              marginRight: '5px',
                              strokeWidth: '1.5',
                            }
                      }
                    />
                    <p>{estado.descricao}</p> */}

                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          // eslint-disable-next-line eqeqeq
                          checked={!estadoExists}
                          onChange={() => {
                            if (!estadoExists) {
                              dispatch({
                                type: "ENERGIAS_ESTADOS_CONTROLO_PRE_SELECTED",
                                payload: controloPreSelectedId.filter(
                                  (est) => est.id !== estado.id
                                ),
                              });
                            } else {
                              dispatch({
                                type: "ENERGIAS_ESTADOS_CONTROLO_PRE_SELECTED",
                                payload: [...controloPreSelectedId, estado],
                              });
                            }
                          }}
                          name={estado.descricao}
                        />
                      }
                      label={estado.descricao}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <span style={{ width: "2%" }} />
        <Button
          onClick={handleSubmit}
          variant="contained"
          className={classes.btnFiltrar}
        >
          Filtrar
        </Button>
      </div>
    </>
  );
};

export default FiltersObrasCheck;
