import Instance from "../../../Axios";

// New Actions for the app

const GetIntervencoesTimeline =
  (dataInicial, dataFinal, aeroportoId, departamentoId, situacoesId) => async (dispatch) => {
    await Instance()
      .get(`/intervencoes/intervencoes/timeline`, {
        params: {
          dataInicial,
          dataFinal,
          aeroportoId,
          departamentoId,
          situacoesId,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: "GET_INTERVENCOES_TIMELINE",
          payload: data.yData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default GetIntervencoesTimeline;
