import { useDispatch } from 'react-redux';
import { Modal, Backdrop, Fade, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';

//Action
import removeIntervencao from '../../../../../utils/redux/actions/intervencoes/DeleteIntervencaoAction';
import ShowNotification from '../../../../../utils/redux/actions/global/notifications/NotificationAction';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 2,
  boxShadow: 3,
  p: 4,
  pt: 3,
};

const useStyles = makeStyles((theme) => ({
  btnConfirm: {
    color: 'white',
    width: '120px',
    height: '40px',
    // borderRadius: '15px',
    backgroundColor: '#E21450',
    border: `1px solid #E21450`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#bc1041',
      border: '1px solid #bc1041',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#bc1041',
      },
      '&:disabled': {
        backgroundColor: '#bc1041',
      },
    },
  },
  btnCancel: {
    color: '#E21450',
    width: '125px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  modalBtns: {
    paddingTop: 30,
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
  },
}));

const ModalDelete = ({
  openDelete,
  handleClose,
  intervencaoId,
  fetchIntervencoes,
  anoAtual,
  estado,
  subEstado,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handlerRemover = (e) => {
    const message = {
      isOpen: true,
      message: 'Intervenção eliminada com sucesso!!',
      type: 'success',
    };
    const errorMessage = {
      isOpen: true,
      message: 'Ocorreu um erro!!',
      type: 'error',
    };
    e.preventDefault();
    dispatch(
      removeIntervencao(intervencaoId, ShowNotification, message, errorMessage)
    )
      .then(() => handleClose('delete', e))
      .then(() => fetchIntervencoes(anoAtual, estado, subEstado));
    //.then(() => handleOpenAlert("delete"));
  };

  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={openDelete}
        onClose={(e) => handleClose('delete', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <>
          <Fade in={openDelete}>
            <Box sx={style}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography variant='p' fontWeight='bold'>
                  Eliminar Intervenção
                </Typography>
                <Typography id='transition-modal-description' sx={{ mt: 2 }}>
                  Tem a certeza que pretende eliminar esta intervenção?
                </Typography>
                <div className={classes.modalBtns}>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={(e) => handleClose('delete', e)}
                    className={classes.btnCancel}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant='contained'
                    sx={{ mx: 2 }}
                    onClick={handlerRemover}
                    className={classes.btnConfirm}
                  >
                    Confirmar
                  </Button>
                </div>
              </div>
            </Box>
          </Fade>
        </>
      </Modal>
    </>
  );
};

export default ModalDelete;
