import React from 'react';
import { useSelector } from 'react-redux';
import { TextField, Autocomplete, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

const Controlo = ({ obraSelecionada, control }) => {
  const estadosControlo = useSelector((state) => state.obras.estadosControlo);

  return (
    <>
      <div style={{ width: "18%" }}>
        <h4
          style={{ color: "#E21450" }}
          className="text-underline-title-drawer "
        >
          Controlo
        </h4>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Estados
            </Typography>
            <Controller
              name="estadosControloDropdown"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <Autocomplete
                    fullWidth
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    className="unidadesId-form"
                    options={estadosControlo || []}
                    value={value || null}
                    getOptionLabel={(option) => `${option.descricao}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    filterOptions={(options, state) => {
                      let newOptions = [];
                      options.forEach((option) => {
                        if (
                          option.id > 0 &&
                          option.descricao
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        )
                          newOptions.push(option);
                      });
                      return newOptions;
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                    onChange={(_, value) => {
                      onChange(value);

                      return value;
                    }}
                  />
                </>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Controlo;
