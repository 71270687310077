import Instance from "../../../../Axios";

// New Actions for the app

const GetTodosArtigos = () => async (dispatch) => {
  await Instance()
    .get(`https://renovaveis.jaf.com.pt/wp-json/wp/v2/posts?categories=33`)
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: "GET_TODOS_ARTIGOS", payload: data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetTodosArtigos;
