export const energiasDashboardInitialState = {
  energiasDashboard: [],
  valorOrcamentado: null,
  valorAdjudicado: null,
  pedidosPropostasTotal: null,
  propostasPorResponder: null,
  tempoMedioResposta: null,
  idadeMedia: null,
  estadoPropostas: [],
  estadoPropostasParceiro: [],
  orcamentosAdjudicados: [],
  orcamentosAdjudicadosValor: [],
  orcamentosTotalValor: [],
  taxasucesso: [],
  pedidosPropostasParceiros: [],
  percOrcaAdjudicado: [],
  orcamentosPerMonth: [],
  propostas: [],
  propostasEstados: [],
  propostasTimeline: [],
  parceiros: [],
  estadosProjetos: [],
  estadosSubProjetos: [],
};
