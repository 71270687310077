import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

//Actions
import DashboardEstadosObras from '../../../../../utils/redux/actions/global/dashboard/GetDashEstadosObrasAction';

//Components
import MiniCardAberta from './MiniCardAberta';
import MiniCardSuspensa from './MiniCardSuspensa';
import MiniCardExecutada from './MiniCardExecutada';

const MiniCards = () => {
  const miniCardColor1 = "#FF4A4A";
  const miniCardColor2 = "#8CC051";
  const miniCardColor3 = "#FFA65D";

  const dispatch = useDispatch();

  const fetchEstadosObras = () => {
    dispatch(DashboardEstadosObras());
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchEstadosObras(), []);

  const obrasState = useSelector(
    (state) => state.dashboard.estadosObrasDashboard
  );

  return (
    <>
      {obrasState ? (
        <>
          <MiniCardAberta color={miniCardColor1} obrasState={obrasState} />
          <MiniCardExecutada color={miniCardColor2} obrasState={obrasState} />
          <MiniCardSuspensa color={miniCardColor3} obrasState={obrasState} />
        </>
      ) : null}
    </>
  );
};

export default MiniCards;
