/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const GraficoPercInterAdjudicada = ({ percentagemIntervencoesAdjudicadas }) => {
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        type: 'bar',
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 25,
        style: {
          fontSize: '12px',
          colors: ['#000'],
        },
        formatter: function (value) {
          return value + ' %';
        },
      },
      title: {
        // text: '% de Intervenções Adjudicadas pelo Total das Adjudicações',
        text: '% de Intervenções Adjudicadas',
        align: 'center',
        style: {
          margin: 'auto',
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
        },
        max: null,
        categories: [],
      },
      grid: {
        show: true,
      },
      fill: {
        opacity: 1,
      },
      colors: ['#ED5564'],
    },
  });

  useEffect(() => {
    percentagemIntervencoesAdjudicadas &&
      setState(percentagemIntervencoesAdjudicadas);
  }, [percentagemIntervencoesAdjudicadas]);

  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type='bar'
        height={300}
        width={300}
      />
    </>
  );
};

export default GraficoPercInterAdjudicada;
