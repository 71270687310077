import Instance from "../../../../Axios";

// New Actions for the app

const GetWorkPermitAccountableById = (id) => async (dispatch) => {
  await Instance()
    .get(`/construcoes/workpermits/accountable/${id}`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({
        type: "GET_WORK_PERMIT_ACCOUNTABLE_ID",
        payload: data.yData[0],
      });
      // setObraSelecionada(data.yData[0]);
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetWorkPermitAccountableById;
