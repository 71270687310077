export const dashboardInitialState = {
  //Graphs
  especialidadesDashboard: [],
  //Cards
  estadosObrasDashboard: [],
  //Donuts
  idadesObrasDashboard: null,
  construcoesEstadosPropostas: null,
  energiasEstadosPropostas: null,
};
