import moment from "moment";
import Instance from "../../../../Axios";

// New Actions for the app

const EditCredFuncionarioFormacao =
  (
    yData,
    id,
    certificado,
    datePickerValues,
    toggleCursoDrawer,
    ShowNotification,
    message,
    errorMessage
  ) =>
  async (dispatch) => {
    const formdata = new FormData();

    formdata.append("yData", JSON.stringify(yData[0]));

    if (certificado && Array.isArray(certificado) && certificado.length > 0) {
      formdata.append("certificado", certificado[0].file);
      formdata.append("certificadoidtipo", certificado[0].idTipo);
      formdata.append("certificadopath", certificado[0].path);
      formdata.append(
        `certificadodatePicker`,
        moment(datePickerValues).format("YYYY-MM-DD")
      );
    }

    await Instance()
      .put(`/credenciacoes/funcionarios/formacao/${id}`, formdata)
      .then(({ data }) => {
        console.log(data);
        dispatch({
          type: "EDIT_CRED_FUNCIONARIO_FORMACAO",
          payload: data.yData[0],
        });
        toggleCursoDrawer("right", false);
        dispatch(ShowNotification(message));
      })
      .catch((err) => {
        if (err.response) {
          if (err.response === 403) {
            dispatch(ShowNotification(errorMessage));
          } else {
            dispatch(ShowNotification(errorMessage));
          }
        }
      });
  };

export default EditCredFuncionarioFormacao;
