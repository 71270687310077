import axios from 'axios';
import BASE_URL from '../../../BASE_URL';

const DefinirSenha =
  (ydata, navigate, ShowNotification, message, errorMessage) =>
  async (dispatch) => {
    if (ydata[0].password === ydata[0].confirmarPassword) {
      await axios
        .post(`${BASE_URL}/auth/signup`, { ydata })
        .then(({ data }) => {
          console.log(data);
          dispatch({ type: 'DEFINIR_SENHA', payload: data });
          dispatch(ShowNotification(message));
        })
        .then(() => dispatch({ type: 'CLEAR_DADOS' }))
        .then(() => navigate('/'))

        .catch((err) => {
          if (err.response) {
            if (err.response.status === 403) {
              const warningMessage = {
                isOpen: true,
                message: err.response.data.message,
                type: 'warning',
              };

              dispatch(ShowNotification(warningMessage));
            } else {
              dispatch(ShowNotification(errorMessage));
            }
          }
        });
    }
  };

export default DefinirSenha;
