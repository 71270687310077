import Instance from '../../../../Axios';

const EditarObra =
  (
    ydata,
    obraId,
    ficheiro,
    toggleDrawer,
    ShowNotification,
    message,
    errorMessage
  ) =>
  async (dispatch) => {
    const formdata = new FormData();
    formdata.append("yData", JSON.stringify(ydata[0]));
    if (ficheiro) {
      formdata.append(`file`, ficheiro.file);
      formdata.append(`idTipo`, ficheiro.idTipo);
      formdata.append(`path`, ficheiro.path);
    }
    await Instance()
      .post(`/construcoes/obras`, formdata)
      .then(({ data }) => {
        console.log(data);
        dispatch({ type: "POST_OBRA", payload: data });
        toggleDrawer("right", false);
        dispatch(ShowNotification(message));

        // toggleDrawer('right', false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response === 403) {
            dispatch(ShowNotification(errorMessage));
          } else {
            dispatch(ShowNotification(errorMessage));
          }
        }
      });
  };

export default EditarObra;
