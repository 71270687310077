import React from 'react';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

const Observacoes = ({ control }) => {
  return (
    <>
      <div>
        <h4 style={{ fontSize: '18px' }}>Notas</h4>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '100%', marginBottom: '16px' }}>
            <Controller
              name='observacoes'
              control={control}
              render={({ field }) => (
                <>
                  <TextField
                    multiline
                    color='primary'
                    variant='outlined'
                    size='small'
                    {...field}
                    autoComplete='off'
                    style={{ width: '100%' }}
                  />
                </>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Observacoes;
