/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Box,
  TextField,
  Button,
  IconButton,
  useTheme,
  Autocomplete,
  Breadcrumbs,
  Link,
  Tooltip,
} from "@mui/material";
import "moment/locale/pt";
import { format } from "date-fns";

// Components
import FooterLinks from "../../../../Global/Components/Footer";
import SideDrawer from "./components/drawer";

//Icons
import FeatherIcon from "feather-icons-react";

//Actions
import GetCredFuncionarios from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionariosAction";
import MUIDataTable from "mui-datatables";
import ModalDelete from "./components/modalDelete";
import GetCredEmpresas from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredEmpresasAction";
import GetCredAeroportos from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredAeroportosAction";
import GetCredFuncoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncoesAction";
import GetCredHabilitacoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredHabilitacoesAction";
import GetCredTipoDocs from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoDocsAction";
import GetCredFuncionarioById from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionarioByIdAction";
import GetCredFuncionarioFormacaoByFormId from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionarioFormacaoByFormIdAction";
import GetCredEstadosFormacoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredEstadosFormacoesAction";
import GetCredFuncionariosResources from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionariosResourcesAction";
import GetCredAeroportosByFuncId from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredAeroportosByFuncIdAction";
import GetCredFuncionarioUploadedFiles from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionarioUploadedFilesAction";
import GetCredIntervencoesTotais from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredIntervencoesTotaisAction";
import GetCredAlocacoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredAlocacoesAction";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
  },
  btnFiltrar: {
    color: "#E21450",
    width: "150px",
    height: "40px",
    fontWeight: "900",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  stateLabel: {
    textTransform: "none",
    transition: "0.2s",
    display: "flex",
    alignItems: "center",
    maxWidth: "100px",
  },

  paperFilters: {
    background: "transparent",
    padding: "20px 0px",
    boxShadow: "none",
  },
}));

// ############################################################## //
// #####################  Window Dimensions  #################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const CredFuncionarios = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");
  const [funcionario, setFuncionario] = useState({
    nome: "",
    morada: "",
    cp: "",
    localidade: "",
    telefone: "",
    telemovel: "",
    email: "",
    nif: "",
    data_nascimento: "",
    local_nascimento: "",
    nacionalidade: "",
    filiacao_pai: "",
    filiacao_mae: "",
    categoria_profissional: "",
    funcao: "",
    doc_nr_identificacao: "",
    doc_nr_identificacao_controlo: "",
    doc_validade: "",
    doc_vitalicio: "",
    doc_entidade_emissora: "",
    doc_data_emissao: "",
    email_requisitante: "",
    evidencias: [],
    evidenciasNomes: [],
  });
  const [formacao, setFormacao] = useState({
    nome: "",
    cod: "",
    tipo: "",
    nif_entidade: "",
    formador: "",
    formador_entidade: "",
    solicitacao: "",
    data_formacao: "",
    data_inscricao: "",
    data_conclusao: "",
    nota_final: null,
  });

  const funcionariosState = useSelector(
    (state) => state.credenciais.funcionarios
  );

  const permissoes = useSelector((state) => state.user.permissoes);

  const tipoRegistos = [
    {
      id: "1",
      descricao: "Rascunho",
    },
  ];

  // ############################################################## //
  // #######################  Autocompletes  ###################### //
  // ############################################################## //

  const [intervencao, setIntervencao] = useState({
    id: "0",
    descricao: "",
  });

  const [rascunho, setRascunho] = useState({
    id: "0",
    descricao: "",
  });

  const empresas = useSelector((state) => state.credenciais.empresas);
  const aeroportos = useSelector((state) => state.credenciais.aeroportos);
  const alocacoes = useSelector((state) => state.credenciais.alocacoes);
  const habilitacoes = useSelector((state) => state.credenciais.habilitacoes);
  const tipoDocs = useSelector((state) => state.credenciais.tipoDocs);
  const estadosFormacoes = useSelector(
    (state) => state.credenciais.estadosFormacoes
  );
  const resources = useSelector((state) => state.credenciais.resources);

  const fetchCredFuncionarios = (intervencaoId, rascunhoId) => {
    dispatch(GetCredFuncionarios(intervencaoId, rascunhoId));
  };

  const fetchCredFuncionariosResources = () => {
    dispatch(GetCredFuncionariosResources());
  };

  const fetchCredEmpresas = () => {
    dispatch(GetCredEmpresas());
  };

  const fetchCredAeroportos = () => {
    dispatch(GetCredAeroportos());
  };

  const fetchCredAlocacoes = () => {
    dispatch(GetCredAlocacoes());
  };

  const fetchCredFuncoes = () => {
    dispatch(GetCredFuncoes());
  };

  const fetchCredHabilitacoes = () => {
    dispatch(GetCredHabilitacoes());
  };

  const fetchCredTipoDocs = () => {
    dispatch(GetCredTipoDocs());
  };

  const fetchCredEstadoFormacoes = () => {
    dispatch(GetCredEstadosFormacoes());
  };

  const fetchIntervencoesTotais = () => {
    dispatch(GetCredIntervencoesTotais());
  };

  useEffect(() => {
    const intervencaoId = intervencao.id;
    const rascunhoId = rascunho.id;

    fetchCredFuncionarios(intervencaoId, rascunhoId);
    fetchCredEmpresas();
    fetchCredAeroportos();
    fetchCredFuncoes();
    fetchCredHabilitacoes();
    fetchCredTipoDocs();
    fetchCredEstadoFormacoes();
    fetchCredFuncionariosResources();
    fetchIntervencoesTotais();
    fetchCredAlocacoes();
  }, []);

  // ############################################################## //
  // ##########################  Drawer  ########################## //
  // ############################################################## //

  const fetchCredFuncionarioById = (funcionarioId) => {
    dispatch(GetCredFuncionarioById(funcionarioId));
  };

  const fetchCredAeroportosByFuncId = (funcionarioId) => {
    dispatch(GetCredAeroportosByFuncId(funcionarioId));
  };

  const fetchCredFuncionariosUploadedFiles = (funcionarioId) => {
    dispatch(GetCredFuncionarioUploadedFiles(funcionarioId));
  };

  const fetchCredFuncionarioFormacaoByFormId = (formId) => {
    dispatch(GetCredFuncionarioFormacaoByFormId(formId));
  };

  // eslint-disable-next-line no-unused-vars
  const [drawerWidth, setDrawerWidth] = useState("90vw");
  const dimensions = useWindowDimensions();
  const [state, setState] = useState({
    right: false,
  });
  const [cursoState, setCursoState] = useState({
    right: false,
  });
  const [drawerState, setDrawerState] = useState(null);
  const [drawerCursoState, setDrawerCursoState] = useState(null);
  const toggleDrawer = (anchor, open, id) => {
    if (open == false) {
      setState({ ...state, [anchor]: open });
      setFuncionarioId(null);
      dispatch({
        type: "CLEAR_FUNCIONARIO_BY_NIF",
      });
    }
    if (open == true) {
      setState({ ...state, [anchor]: open });
      setFuncionarioId(id);
      fetchCredFuncionarioById(id);
      fetchCredAeroportosByFuncId(id);
      fetchCredFuncionariosUploadedFiles(id);
    }
  };
  const toggleCursoDrawer = (anchor, open, id) => {
    if (open == false) {
      setCursoState({ ...state, [anchor]: open });
    }
    if (open == true) {
      setCursoState({ ...state, [anchor]: open });
      setFormacaoId(id);
      fetchCredFuncionarioFormacaoByFormId(id);
    }
  };

  // ############################################################## //
  // #######################  Modal Delete  ####################### //
  // ############################################################## //

  const [openDelete, setOpenDelete] = useState(false);
  const [funcionarioId, setFuncionarioId] = useState();
  const [formacaoId, setFormacaoId] = useState();
  const [loading, setLoading] = useState(false);

  const handleOpen = (e, id) => {
    e.preventDefault();

    setLoading(false);

    setOpenDelete(true);
    setFuncionarioId(id);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpenDelete(false);
  };

  // ############################################################## //
  // ##################  MUI Datatable columns  ################### //
  // ############################################################## //

  const columns = [
    {
      name: "id",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "id_nav",
      label: "Nº Mec",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "nome",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
      },
    },

    {
      name: "empresa",
      label: "Empresa",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "documento",
      label: "Tipo Documento",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "doc_nr_identificacao",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "doc_nr_identificacao_controlo",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "",
      label: "Nr Documento",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography>
              {tableMeta.rowData[5]} {tableMeta.rowData[6]}
            </Typography>
          );
        },
      },
    },
    {
      name: "doc_validade",
      label: "Validade Doc",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {tableMeta.rowData[8] == null ? (
                " - "
              ) : (
                <>{format(new Date(tableMeta.rowData[8]), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { width: "2.5%" },
          align: "center",
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const o = tableMeta.rowData[0];

          return (
            <div style={{ display: "flex" }}>
              {permissoes.includes("APP_CRED_COLABORADORES_EDITAR") && (
                <IconButton
                  onClick={() => {
                    setDrawerState("edit");
                    toggleDrawer("right", true, o);
                  }}
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                >
                  <FeatherIcon
                    icon="edit"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              )}
              <IconButton
                style={{
                  padding: 6,
                  color: theme.palette.secondary.main,
                }}
                onClick={(e) => {
                  handleOpen(e, o);
                }}
              >
                <FeatherIcon
                  icon="trash-2"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  // ############################################################## //
  // ##################  MUI Datatable options  ################### //
  // ############################################################## //

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },

    customToolbar: () => {
      return (
        <>
          {permissoes.includes("APP_CRED_COLABORADORES_INSERIR") && (
            <Tooltip title={"Adicionar Colaborador"}>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  setDrawerState("add");
                  toggleDrawer("right", true, null);
                }}
              >
                <FeatherIcon
                  icon="plus-square"
                  size={22}
                  className={classes.deleteIcon}
                  style={{ strokeWidth: "2" }}
                />
              </IconButton>
            </Tooltip>
          )}
        </>
      );
    },
  };

  const handleSubmit = () => {
    const intervencaoId = intervencao.id;
    const rascunhoId = rascunho.id;

    fetchCredFuncionarios(intervencaoId, rascunhoId);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mr: 10,
          width: "100%",
        }}
      >
        <SideDrawer
          state={state}
          toggleDrawer={toggleDrawer}
          cursoState={cursoState}
          setDrawerCursoState={setDrawerCursoState}
          toggleCursoDrawer={toggleCursoDrawer}
          drawerState={drawerState}
          drawerCursoState={drawerCursoState}
          drawerWidth={drawerWidth}
          dimensions={dimensions}
          funcionarioId={funcionarioId}
          funcionario={funcionario}
          setFuncionario={setFuncionario}
          fetchCredFuncionarios={fetchCredFuncionarios}
          formacao={formacao}
          setFormacao={setFormacao}
          formacaoId={formacaoId}
          setFormacaoId={setFormacaoId}
          empresas={empresas}
          aeroportos={aeroportos}
          habilitacoes={habilitacoes}
          tipoDocs={tipoDocs}
          resources={resources}
          estadosFormacoes={estadosFormacoes}
          fetchCredFuncionarioById={fetchCredFuncionarioById}
          alocacoes={alocacoes}
        />
        <ModalDelete
          openDelete={openDelete}
          handleClose={handleClose}
          funcionarioId={funcionarioId}
          fetchCredFuncionarios={fetchCredFuncionarios}
          // show={show}
          // Loading
          loading={loading}
          setLoading={setLoading}
        />
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              mx: 5,
              mt: 5,
              width: "100%",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/construcoes/obras">
                Gestão Aeroportuária
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href="/credenciacoes/credenciacoes"
              >
                Credenciações
              </Link>
              <Typography color="text.primary">Colaboradores</Typography>
            </Breadcrumbs>
            <Box sx={{ display: "flex", mt: 3 }}>
              {/* <Box sx={{ mr: 2, width: "300px" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Intervenção
                </Typography>
                <Autocomplete
                  fullWidth
                  name="nome"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  value={intervencao}
                  className="unidadesId-form"
                  options={intervencoes || []}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason == "clear") {
                      setIntervencao({ id: "0", descricao: "" });
                    } else {
                      setIntervencao(value);
                    }
                    return value;
                  }}
                />
              </Box> */}
              <Box sx={{ mr: 2, width: "300px" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Registo
                </Typography>
                <Autocomplete
                  fullWidth
                  name="nome"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  value={rascunho}
                  className="unidadesId-form"
                  options={tipoRegistos || []}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason == "clear") {
                      setRascunho({ id: "0", descricao: "" });
                    } else {
                      setRascunho(value);
                    }
                    return value;
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "end",
                }}
              >
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  className={classes.btnFiltrar}
                >
                  Filtrar
                </Button>
              </Box>
            </Box>
          </Box>
          <div
            style={{
              margin: "0px 40px 40px 40px",
              width: "100%",
            }}
          >
            <MUIDataTable
              title={
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  className="text-underline"
                >
                  Lista de Colaboradores
                </span>
              }
              data={funcionariosState || []}
              columns={columns}
              options={options}
            />
          </div>
          <div style={{ margin: "20px 40px" }}>
            <FooterLinks />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default CredFuncionarios;
