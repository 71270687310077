/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, styled } from "@mui/styles";
import { startOfYear } from "date-fns";

import {
  IconButton,
  Typography,
  Box,
  Button,
  Card,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

// Components
// import FiltersObras from './components/filters';
import FooterLinks from "../../../Global/Components/Footer";
import FilterDrawer from "./components/filterDrawer";

//Icons
import FeatherIcon from "feather-icons-react";
import { HiArrowTopRightOnSquare } from "react-icons/hi2";

//Actions
import GetValorOrcamentado from "../../../utils/redux/actions/energias/dashboard/GetValorOrcamentado";
import GetValorAdjudicado from "../../../utils/redux/actions/energias/dashboard/GetValorAdjudicado";
import GetEstadosPropostas from "../../../utils/redux/actions/energias/dashboard/GetEstadosPropostas";
import GetEstadosPropostasParceiro from "../../../utils/redux/actions/energias/dashboard/GetEstadosPropostasParceiro";
import GetOrcamentosAdjudicados from "../../../utils/redux/actions/energias/dashboard/GetOrcamentosAdjudicados";
import GetOrcamentosAdjudicadosValor from "../../../utils/redux/actions/energias/dashboard/GetOrcamentosAdjudicadosValor";
import GetOrcamentosTotalValor from "../../../utils/redux/actions/energias/dashboard/GetOrcamentosTotalValor";
import GetOrcamentosParceirosAll from "../../../utils/redux/actions/energias/dashboard/GetOrcamentoParceirosAll";
import GetPedidosPropostaTotal from "../../../utils/redux/actions/energias/dashboard/GetPedidosPropostasTotal";
import GetPedidosPropostasParceiros from "../../../utils/redux/actions/energias/dashboard/GetPedidosPropostasParceiros";
import GetPropostasPorResponder from "../../../utils/redux/actions/energias/dashboard/GetPropostasPorResponder";
import GetTempoMedioResposta from "../../../utils/redux/actions/energias/dashboard/GetTempoMedioResposta";
import GetIdadeMedia from "../../../utils/redux/actions/energias/dashboard/GetIdadeMedia";
import GetPercOrcaAdjudicadosPeloTotal from "../../../utils/redux/actions/energias/dashboard/GetPercOrcaAdjudicadosPeloTotal";

//Gráficos
import GraficoEstadoPropostas from "./components/Graficos/GraficoEstadoPropostas";
import { useNavigate } from "react-router-dom";
import GetDocumentosOficiais from "../../../utils/redux/actions/energias/documentosOficiais/GetDocumentosOficiais";
import GetAllFichasTecnicas from "../../../utils/redux/actions/energias/fichasTecnicas/GetAllFichasTecnicas";
import Instance from "../../../utils/Axios";
import BASE_URL from "../../../utils/BASE_URL";
import fileDownload from "js-file-download";
import GetLastDocumentosOficiais from "../../../utils/redux/actions/energias/documentosOficiais/GetLastDocumentosOficiais";
import GetLastFichasTecnicas from "../../../utils/redux/actions/energias/fichasTecnicas/GetLastFichasTecnicas";
import GetTodosArtigos from "../../../utils/redux/actions/energias/blog/getTodosArtigos";

const LINES_TO_SHOW = 2;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
  },
  btnFiltrar: {
    color: "#E21450",
    width: "120px",
    height: "40px",
    fontWeight: "900",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  stateLabel: {
    textTransform: "none",
    transition: "0.2s",
    display: "flex",
    alignItems: "center",
    maxWidth: "100px",
  },

  paperFilters: {
    background: "transparent",
    padding: "20px 0px",
    boxShadow: "none",
  },

  multiLineEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": LINES_TO_SHOW,
    "-webkit-box-orient": "vertical",
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// ############################################################## //
// #####################  Window Dimensions  #################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const EnergiasDashboardParceiros = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //DRAWER
  // eslint-disable-next-line no-unused-vars
  const [drawerWidth, setDrawerWidth] = useState("25vw");
  const dimensions = useWindowDimensions();
  const [state, setState] = useState({
    right: false,
  });
  // eslint-disable-next-line no-unused-vars
  const [drawerState, setDrawerState] = useState(null);
  const toggleDrawer = (anchor, open) => {
    if (open == false) {
      setState({ ...state, [anchor]: open });
    }
    if (open == true) {
      setState({ ...state, [anchor]: open });
    }
  };

  const dashboardEnergias = useSelector((state) => state.energiasDashboard);

  const propRespondidas =
    dashboardEnergias.pedidosPropostasTotal -
    dashboardEnergias.propostasPorResponder;

  const energParceiroId = useSelector((state) => state.user.energParceiroId);
  const parceirosId = energParceiroId;
  const dataInicio = startOfYear(new Date());
  const dataFim = new Date();

  const documentosOficiais = useSelector(
    (state) => state.documentosOficiais.lastDocumentosOficiais
  );
  const fichasTecnicas = useSelector(
    (state) => state.fichasTecnicas.lastFichasTecnicas
  );

  const fetchDashboardEnergias = (parceirosId, dataInicio, dataFim) => {
    //dispatch(GetDashboardEnergiasAll());
    dispatch(GetValorOrcamentado(parceirosId, dataInicio, dataFim));
    dispatch(GetValorAdjudicado(parceirosId, dataInicio, dataFim));
    dispatch(GetEstadosPropostas(parceirosId, dataInicio, dataFim));
    dispatch(GetEstadosPropostasParceiro(parceirosId, dataInicio, dataFim));
    dispatch(GetOrcamentosAdjudicados(parceirosId, dataInicio, dataFim));
    dispatch(GetOrcamentosAdjudicadosValor(parceirosId, dataInicio, dataFim));
    dispatch(GetOrcamentosTotalValor(parceirosId, dataInicio, dataFim));
    dispatch(GetOrcamentosParceirosAll(parceirosId, dataInicio, dataFim));
    dispatch(GetPedidosPropostaTotal(parceirosId, dataInicio, dataFim));
    dispatch(GetPedidosPropostasParceiros(parceirosId, dataInicio, dataFim));
    dispatch(GetPropostasPorResponder(parceirosId, dataInicio, dataFim));
    dispatch(GetTempoMedioResposta(parceirosId, dataInicio, dataFim));
    dispatch(GetIdadeMedia(parceirosId, dataInicio, dataFim));
    dispatch(GetPercOrcaAdjudicadosPeloTotal(parceirosId, dataInicio, dataFim));
    dispatch(GetDocumentosOficiais());
    dispatch(GetAllFichasTecnicas());
    dispatch(GetLastDocumentosOficiais());
    dispatch(GetLastFichasTecnicas());
  };

  useEffect(() => fetchDashboardEnergias(parceirosId, dataInicio, dataFim), []);

  const artigos = useSelector((state) => state.artigos.artigos);

  const fetchArtigos = () => {
    dispatch(GetTodosArtigos());
  };

  useEffect(() => {
    fetchArtigos();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <FilterDrawer
        state={state}
        toggleDrawer={toggleDrawer}
        drawerState={drawerState}
        drawerWidth={drawerWidth}
        dimensions={dimensions}
        fetchDashboardEnergias={fetchDashboardEnergias}
      />
      {/* <Sidebar /> */}
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mx: 5,
            mt: 3,
          }}
        >
          <Box>
            <h4
              style={{
                fontSize: "22px",
                width: "100%",
              }}
              className="text-underline"
            >
              Dashboard
            </h4>
          </Box>
          <Box>
            <Button
              variant="contained"
              className={classes.btnFiltrar}
              onClick={() => toggleDrawer("right", true)}
            >
              Filtros
            </Button>
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between", mx: 5 }}>
          <Card
            sx={{
              mb: 5,
              p: 2,
              boxShadow: "0px 5px 15px lightgrey",
              width: "39%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                m: 2,
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  mb: 5,
                }}
              >
                <Typography sx={{ fontSize: 15, fontWeight: "900", mb: 1 }}>
                  Valor Orçamentado
                </Typography>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "900",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "#FFDFE8",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "5px",
                      borderRadius: "20px",
                      marginRight: "10px",
                    }}
                  >
                    <FeatherIcon icon="file-text" color="#E21450" />
                  </span>

                  {new Intl.NumberFormat("pt-PT", {
                    style: "currency",
                    currency: "EUR",
                  }).format(dashboardEnergias.valorOrcamentado)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "33%",
                  mb: 5,
                }}
              >
                <Typography sx={{ fontSize: 20, fontWeight: "900" }}>
                  {new Intl.NumberFormat("pt-PT", {
                    style: "currency",
                    currency: "EUR",
                  }).format(dashboardEnergias.valorAdjudicado)}
                </Typography>
                <Typography sx={{ fontSize: 15, mt: 1 }}>
                  <span
                    style={{
                      backgroundColor: "#DDF6FF",
                      paddingLeft: "7px",
                      paddingRight: "7px",
                      borderRadius: "10px",
                    }}
                  >
                    Valor adjudicado
                  </span>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "33%",
                  mb: 5,
                }}
              >
                <Typography sx={{ fontSize: 20, fontWeight: "900" }}>
                  {dashboardEnergias.pedidosPropostasTotal}
                </Typography>
                <Typography sx={{ fontSize: 15, mt: 1 }}>
                  <span
                    style={{
                      backgroundColor: "#E2FEDE",
                      paddingLeft: "7px",
                      paddingRight: "7px",
                      borderRadius: "10px",
                    }}
                  >
                    Pedidos de Orçamento
                  </span>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "33%",
                  mb: 5,
                }}
              >
                <Typography sx={{ fontSize: 20, fontWeight: "900" }}>
                  {propRespondidas}
                </Typography>
                <Typography sx={{ fontSize: 15, mt: 1 }}>
                  <span
                    style={{
                      backgroundColor: "#FFF7CE",
                      paddingLeft: "7px",
                      paddingRight: "7px",
                      borderRadius: "10px",
                    }}
                  >
                    Propostas Respondidas
                  </span>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "33%",
                  mb: 5,
                }}
              >
                <Typography sx={{ fontSize: 20, fontWeight: "900" }}>
                  {Math.floor(dashboardEnergias.propostasPorResponder)}
                </Typography>
                <Typography sx={{ fontSize: 15, mt: 1 }}>
                  <span
                    style={{
                      backgroundColor: "#FFDFE8",
                      paddingLeft: "7px",
                      paddingRight: "7px",
                      borderRadius: "10px",
                    }}
                  >
                    Propostas por responder
                  </span>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "33%",
                  mb: 5,
                }}
              >
                <Typography sx={{ fontSize: 20, fontWeight: "900" }}>
                  {Math.floor(dashboardEnergias.idadeMedia)} dias
                </Typography>
                <Typography sx={{ fontSize: 15, mt: 1 }}>
                  <span
                    style={{
                      backgroundColor: "#E2FEDE",
                      paddingLeft: "7px",
                      paddingRight: "7px",
                      borderRadius: "10px",
                    }}
                  >
                    Idade Média das Propostas
                  </span>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "33%",
                  mb: 5,
                }}
              >
                <Typography sx={{ fontSize: 20, fontWeight: "900" }}>
                  {Math.floor(dashboardEnergias.tempoMedioResposta)} dias
                </Typography>
                <Typography sx={{ fontSize: 15, mt: 1 }}>
                  <span
                    style={{
                      backgroundColor: "#FFF7CE",
                      paddingLeft: "7px",
                      paddingRight: "7px",
                      borderRadius: "10px",
                    }}
                  >
                    Tempo médio de resposta
                  </span>
                </Typography>
              </Box>
            </Box>
          </Card>
          <Card
            sx={{
              mb: 5,
              p: 2,
              boxShadow: "0px 5px 15px lightgrey",
              width: "21%",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <GraficoEstadoPropostas
                estadoPropostas={dashboardEnergias.estadoPropostas}
              />
            </Box>
          </Card>
          <Card
            sx={{
              mb: 5,
              p: 2,
              boxShadow: "0px 5px 15px lightgrey",
              width: "21%",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <GraficoEstadoPropostas
                estadoPropostas={dashboardEnergias.estadoPropostas}
              />
            </Box>
          </Card>
          <Card
            sx={{
              mb: 5,
              p: 2,
              boxShadow: "0px 5px 15px lightgrey",
              width: "14%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              {artigos.slice(0, 2).map((item, index) => (
                <Box sx={{ mx: 2, mb: 2, mt: 1 }}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FeatherIcon icon="zap" size={20} />
                      <Typography
                        sx={{
                          fontSize: 15,
                          fontWeight: "900",
                          ml: 1,
                          display: "flex",
                          alignItems: "center",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: item.title.rendered,
                        }}
                      />
                    </Box>
                    {index == 0 ? (
                      <IconButton
                        sx={{ color: "black", mr: -2, float: "right" }}
                        onClick={() => navigate("/energias/blog")}
                      >
                        <HiArrowTopRightOnSquare stroke="1px" />
                      </IconButton>
                    ) : null}
                  </Box>
                  <Box>
                    <Typography
                      className={classes.multiLineEllipsis}
                      sx={{
                        fontSize: 15,
                        color: "#707070",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: item.excerpt.rendered,
                      }}
                    />
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <Button
                      variant="contained"
                      className={classes.btnFiltrar}
                      onClick={() => navigate(`/energias/blog/${item.id}`)}
                    >
                      Saber Mais
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>
          </Card>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mx: 5 }}>
          <Card
            sx={{
              mb: 5,
              p: 2,
              boxShadow: "0px 5px 15px lightgrey",
              width: "24%",
              height: 380,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: "900",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    backgroundColor: "#AC92ED80",
                    back: 0.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px",
                    borderRadius: "20px",
                    marginRight: "10px",
                  }}
                >
                  <FeatherIcon icon="tool" color="#967BDC" />
                </span>
                Manutenções
              </Typography>
              <IconButton
                sx={{ color: "black" }}
                onClick={() => navigate("/energias/manutencoes")}
              >
                <HiArrowTopRightOnSquare stroke="1px" />
              </IconButton>
            </Box>
          </Card>
          <Card
            sx={{
              mb: 5,
              p: 2,
              boxShadow: "0px 5px 15px lightgrey",
              width: "36%",
              height: 380,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: "900",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "#FB6E5280",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "5px",
                      borderRadius: "20px",
                      marginRight: "10px",
                    }}
                  >
                    <FeatherIcon icon="file" color="#E9573E" />
                  </span>
                  Documentos Oficiais
                </Typography>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={() => navigate("/energias/documentos")}
                >
                  <HiArrowTopRightOnSquare stroke="1px" />
                </IconButton>
              </Box>
              <Divider
                sx={{
                  width: "100%",
                  my: 1,
                  borderWidth: 1,
                }}
              />
              <Box>
                <List>
                  {documentosOficiais.map((item) => (
                    <StyledListItem
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            let ficheiro = null;
                            Instance()
                              .get(
                                `${BASE_URL}/energias/docsOficiais/${item.id}`
                              )
                              .then((file) => {
                                console.log(file);
                                ficheiro = file.data.yData[0];

                                return Instance().get(
                                  `${BASE_URL}/energias/docsOficiais/${item.id}/download`,
                                  { responseType: "blob" }
                                );
                              })
                              .then(({ data }) => {
                                console.log(data, ficheiro);
                                fileDownload(data, ficheiro.ficheiro_nome);
                              })
                              .catch((err) => {
                                console.log(err.response);
                                if (err.response) {
                                  if (err.response === 403) {
                                    // dispatch(ShowNotification(errorMessage));
                                  }
                                }
                              });
                          }}
                        >
                          <FeatherIcon icon="download" />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={item.descricao} />
                    </StyledListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </Card>
          <Card
            sx={{
              mb: 5,
              p: 2,
              boxShadow: "0px 5px 15px lightgrey",
              width: "36%",
              height: 380,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: "900",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "#EBEBEB",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "5px",
                      borderRadius: "20px",
                      marginRight: "10px",
                    }}
                  >
                    <FeatherIcon icon="file" color="#868686" />
                  </span>
                  Fichas Técnicas
                </Typography>

                <IconButton
                  sx={{ color: "black" }}
                  onClick={() => navigate("/energias/fichas")}
                >
                  <HiArrowTopRightOnSquare stroke="1px" />
                </IconButton>
              </Box>
              <Divider
                sx={{
                  width: "100%",
                  my: 1,
                  borderWidth: 1,
                }}
              />
              <Box>
                <List>
                  {fichasTecnicas.map((item) => (
                    <StyledListItem
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            let ficheiro = null;
                            Instance()
                              .get(
                                `${BASE_URL}/energias/fichasTecnicas/${item.id}`
                              )
                              .then((file) => {
                                console.log(file);
                                ficheiro = file.data.yData[0];

                                return Instance().get(
                                  `${BASE_URL}/energias/fichasTecnicas/${item.id}/download`,
                                  { responseType: "blob" }
                                );
                              })
                              .then(({ data }) => {
                                console.log(data, ficheiro);
                                fileDownload(data, ficheiro.ficheiro_nome);
                              })
                              .catch((err) => {
                                console.log(err.response);
                                if (err.response) {
                                  if (err.response === 403) {
                                    // dispatch(ShowNotification(errorMessage));
                                  }
                                }
                              });
                          }}
                        >
                          <FeatherIcon icon="download" />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={item.descricao} />
                    </StyledListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </Card>
        </Box>
        <div style={{ margin: "20px 40px" }}>
          <FooterLinks />
        </div>
      </Box>
    </Box>
  );
};

export default EnergiasDashboardParceiros;
