/* eslint-disable eqeqeq */
import React from "react";
import { useSelector } from "react-redux";
import { TextField, Autocomplete, IconButton } from "@mui/material";
import { Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptLocale from "date-fns/locale/pt";

//Icons
import FeatherIcon from "feather-icons-react";

const Equipamentos = ({
  obraSelecionada,
  setObraSelecionada,
  control,
  reset,
  watch,
  resetForm,
  errors,
  setResetForm,
  getValues,
  setValue,
  updateGlobals,
  renderSwitch,
  setEstDT,
  setEstDC,
}) => {
  const empresas = useSelector((state) => state.obras.obrasEmpresas);
  const estados = useSelector((state) => state.obras.especialidadesEstados);
  const equipamentos = useSelector((state) => state.obras.obrasEquipamentos);

  const calendarIcon = () => {
    return <FeatherIcon icon="calendar" size={20} />;
  };

  // ############################################################## //
  // ######################  Semaforizações  ###################### //
  // ############################################################## //

  // Valores das Cores
  // 1 - Verde
  // 2 - Amarelo
  // 3 - Vermelho

  // Caso estado seja alterado
  const onStateChange = (value, index) => {
    if (value) {
      if (getValues(`equipamentos[${index}].empresasDropdown`)) {
        switch (value.id) {
          case 1:
            setEstDC([index, "1"]);
            setValue(`equipamentos[${index}].estadodc`, "1");
            break;
          case 2:
            setEstDC([index, "2"]);
            setValue(`equipamentos[${index}].estadodc`, "2");
            break;
          case 3:
            setEstDC([index, "2"]);
            setValue(`equipamentos[${index}].estadodc`, "2");
            break;
          case 4:
            setEstDC([index, "1"]);
            setValue(`equipamentos[${index}].estadodc`, "1");
            break;
          default:
            break;
        }
        updateGlobals();
      } else {
        setEstDC([index, "2"]);
        setValue(`equipamentos[${index}].estadodc`, "2");
        updateGlobals();
      }
      console.log(getValues());
    }
  };

  // Caso a empresa/fornecedor seja alterada
  const onEmpChange = (value, index) => {
    if (value) {
      if (getValues(`equipamentos[${index}].estadosDropdown`)) {
        if (
          getValues(`equipamentos[${index}].estadosDropdown.id`) == "1" ||
          getValues(`equipamentos[${index}].estadosDropdown.id`) == "4"
        ) {
          setEstDT([index, "1"]);
          setValue(`equipamentos[${index}].estadodc`, "1");
        } else {
          setEstDT([index, "2"]);
          setValue(`equipamentos[${index}].estadodc`, "2");
        }
      } else {
        setEstDT([index, "2"]);
        setValue(`equipamentos[${index}].estadodc`, "2");
      }
      updateGlobals();
    } else {
      setEstDT([index, "2"]);
      setValue(`equipamentos[${index}].estadodc`, "2");
      updateGlobals();
    }
    console.log(getValues());
  };

  // Caso o equipamento seja alterado
  const onEquipChange = (value, index) => {
    if (value) {
      if (getValues(`equipamentos[${index}].qtd`)) {
        setEstDT([index, "1"]);
        setValue(`equipamentos[${index}].estadodt`, "1");
      } else {
        setEstDT([index, "2"]);
        setValue(`equipamentos[${index}].estadodt`, "2");
      }
      if (!getValues(`equipamentos[${index}].estadosDropdown.id`)) {
        setEstDC([index, "3"]);
        setValue(`equipamentos[${index}].estadodc`, "3");
      }
      updateGlobals();
    } else {
      setEstDT([index, "2"]);
      setValue(`equipamentos[${index}].estadodt`, "2");
      updateGlobals();
    }
    console.log(getValues());
  };

  // Caso a quantidade seja alterada
  const onQtdChange = (value, index) => {
    if (value) {
      if (getValues(`equipamentos[${index}].equipamentosDropdown.id`)) {
        setEstDT([index, "1"]);
        setValue(`equipamentos[${index}].estadodt`, "1");
        updateGlobals();
      } else {
        setEstDT([index, "2"]);
        setValue(`equipamentos[${index}].estadodt`, "2");
        updateGlobals();
      }
    } else {
      setEstDT([index, "2"]);
      setValue(`equipamentos[${index}].estadodt`, "2");
      updateGlobals();
    }
    console.log(getValues());
  };

  // ############################################################## //
  // ###############  Handle Adicionar Equipamento  ############### //
  // ############################################################## //

  const handleAddEquip = async () => {
    console.log(obraSelecionada.equipamentos.length);
    const lastMat =
      obraSelecionada &&
      obraSelecionada.equipamentos &&
      obraSelecionada.equipamentos.length + 1;

    setResetForm(true);

    const newEquipamento = {
      dataFim: null,
      dataInicio: null,
      estadosDescricao: null,
      estadosDropdown: { id: "", descricao: "" },
      estadosId: null,
      equipamentosDescricao: null,
      equipamentosDropdown: { id: "", descricao: "" },
      equipamentosId: lastMat,
      empresasDropdown: { id: "", descricao: "" },
      empresasId: "",
      obrasId: obraSelecionada && obraSelecionada.obrasId,
      observacoes: null,
      qtd: null,
      estadodt: null,
      estadodc: null,
    };

    obraSelecionada.equipamentos.push(newEquipamento);
    await setObraSelecionada({ ...obraSelecionada });

    await setResetForm(false);

    console.log(resetForm, obraSelecionada);
  };

  // ############################################################## //
  // ################  Handle Remover Equipamento  ################ //
  // ############################################################## //

  const handleRemoveEquip = async (index) => {
    console.log(index);
    const obra = getValues();
    await setResetForm(true);
    if (index !== -1) {
      obra.equipamentos.splice(index, 1);
    }

    await setObraSelecionada({ ...obra });
    await setResetForm(false);
    await reset({ ...obra });
    updateGlobals();
  };

  // ############################################################## //
  // #####################  Button Collapse  ###################### //
  // ############################################################## //

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            width: "100%",
            padding: "5px",
            background: "#F1F1F1",
            border: "1px solid #707070",
            borderRadius: "8px",
          }}
        >
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => handleAddEquip()}
            color="inherit"
          >
            <FeatherIcon icon="plus-square" size={20} />
          </IconButton>
          <h4
            style={{
              width: "25%",
              margin: "10px 2% 10px 10px",
              padding: "0px",
            }}
          >
            Equipamento
          </h4>
          <h4
            style={{
              width: "12.5%",
              margin: "10px 2% 10px 0px",
              padding: "0px",
            }}
          >
            Fornecedor
          </h4>

          <h4
            style={{
              width: "12.5%",
              margin: "10px 2% 10px 10px",
              padding: "0px",
            }}
          >
            Estado
          </h4>

          <h4
            style={{
              width: "5%",
              margin: "10px 2% 10px 0px",
              paddingLeft: "40px",
            }}
          >
            Qtd.
          </h4>
          <h4
            style={{
              width: "10%",
              margin: "10px 2% 10px 10px",
              paddingLeft: "30px",
            }}
          >
            Data Inicio
          </h4>
          <h4
            style={{
              width: "10%",
              margin: "10px 2% 10px 10px",
              paddingLeft: "20px",
            }}
          >
            Data Fim
          </h4>
          <h4
            style={{
              width: "25%",
              margin: "10px 2% 10px 10px",
              paddingLeft: "20px",
            }}
          >
            Observações
          </h4>
          <div
            style={{
              width: "10%",
              display: "flex",
              justifyContent: "space-around",
              marginLeft: "5px",
              float: "right",
            }}
          >
            <h4
              style={{
                margin: "10px 0px 10px 0px",
                padding: "0px",
              }}
            >
              Téc.
            </h4>
            <h4
              style={{
                margin: "10px 0px 10px 0px",
                padding: "0px",
              }}
            >
              Com.
            </h4>
          </div>
        </div>

        {obraSelecionada &&
          obraSelecionada.equipamentos &&
          obraSelecionada.equipamentos.map((equipamento, index) => (
            <div
              key={`equipamento[${index}]`}
              style={{
                background: "white",
                minHeight: "40px",
                marginTop: "10px",
                marginBottom: "10px",
                border: "1px solid #707070",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  padding: "5px",
                }}
              >
                <form
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  autocomple="off"
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() => handleRemoveEquip(index)}
                      color="inherit"
                    >
                      <FeatherIcon icon="trash-2" size={20} />
                    </IconButton>

                    {/* <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl[index]}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      // transformOrigin={{
                      //   vertical: "top",
                      //   horizontal: "right",
                      // }}
                      open={Boolean(anchorEl[index])}
                      onClose={(e) => handleClose(index)}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        <Button
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={() => {
                            handleAddSub(equipamento, index);
                            handleClose(index);
                            const catId = getValues(
                              `equipamentos[${index}].equipamentosDropdown.id`
                            );
                            dispatch(GetObrasSubMateriais(catId));
                          }}
                          color="inherit"
                          sx={{
                            display: "flex",
                            textTransform: "capitalize",
                            justifyContent: "flex-start",
                          }}
                        >
                          Adicionar Equipamento
                        </Button>
                        <Button
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={() => {
                            handleRemoveEquip(index);
                            handleClose(index);
                          }}
                          color="inherit"
                          sx={{
                            display: "flex",
                            textTransform: "capitalize",
                            justifyContent: "flex-start",
                          }}
                        >
                          Remover Equipamento
                        </Button>
                      </div>
                    </Menu> */}
                    <Controller
                      name={`equipamentos[${index}][equipamentosDropdown]`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Autocomplete
                            fullWidth
                            clearText="Limpar"
                            openText="Abrir"
                            closeText="Fechar"
                            noOptionsText="Sem dados"
                            className="unidadesId-form"
                            options={equipamentos || []}
                            value={value || null}
                            getOptionLabel={(option) =>
                              `${option.descricao}` || ""
                            }
                            isOptionEqualToValue={(option, value) => {
                              // if (!value.id) return true;
                              const intOption = option && parseInt(option.id);
                              return value.id === intOption;
                              // console.log(intOption);
                            }}
                            filterOptions={(options, state) => {
                              let newOptions = [];
                              options.forEach((option) => {
                                if (
                                  option.id !== "" &&
                                  option.descricao
                                    .toLowerCase()
                                    .includes(state.inputValue.toLowerCase())
                                )
                                  newOptions.push(option);
                              });
                              return newOptions;
                            }}
                            style={{
                              width: "23%",
                              marginLeft: "10px",
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Equipamento"
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                variant="standard"
                              />
                            )}
                            onChange={(_, value) => {
                              onEquipChange(value, index);
                              onChange(value);
                              return value;
                            }}
                          />
                        </>
                      )}
                    />
                    <Controller
                      name={`equipamentos[${index}][empresasDropdown]`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Autocomplete
                            fullWidth
                            clearText="Limpar"
                            openText="Abrir"
                            closeText="Fechar"
                            noOptionsText="Sem dados"
                            className="unidadesId-form"
                            options={empresas || []}
                            value={value || null}
                            getOptionLabel={(option) =>
                              `${option.descricao}` || ""
                            }
                            isOptionEqualToValue={(option, value) => {
                              // if (!value.id) return true;
                              const intOption = option && parseInt(option.id);
                              return value.id === intOption;
                              // console.log(intOption);
                            }}
                            // filterOptions={(options, state) => {
                            //   let newOptions = [];
                            //   options.forEach((option) => {
                            //     if (
                            //       option.id !== "" &&
                            //       option.descricao
                            //         .toLowerCase()
                            //         .includes(state.inputValue.toLowerCase())
                            //     )
                            //       newOptions.push(option);
                            //   });
                            //   return newOptions;
                            // }}
                            style={{
                              width: "14%",
                              marginLeft: "10px",
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Empresa"
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                variant="standard"
                              />
                            )}
                            onChange={(_, value) => {
                              onEmpChange(value, index);
                              onChange(value);
                              return value;
                            }}
                          />
                        </>
                      )}
                    />
                    <Controller
                      name={`equipamentos[${index}][estadosDropdown]`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Autocomplete
                            fullWidth
                            clearText="Limpar"
                            openText="Abrir"
                            closeText="Fechar"
                            noOptionsText="Sem dados"
                            className="unidadesId-form"
                            options={estados || []}
                            value={value || null}
                            getOptionLabel={(option) =>
                              `${option.descricao}` || ""
                            }
                            isOptionEqualToValue={(option, value) => {
                              // if (!value.id) return true;
                              return value.id === option.id;
                            }}
                            filterOptions={(options, state) => {
                              let newOptions = [];
                              options.forEach((option) => {
                                if (
                                  option.id > 0 &&
                                  option.descricao
                                    .toLowerCase()
                                    .includes(state.inputValue.toLowerCase())
                                )
                                  newOptions.push(option);
                              });
                              return newOptions;
                            }}
                            style={{
                              width: "14%",
                              marginLeft: "10px",
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Estado"
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                variant="standard"
                              />
                            )}
                            onChange={(_, value) => {
                              onStateChange(value, index);
                              onChange(value);
                              return value;
                            }}
                          />
                        </>
                      )}
                    />
                    <Controller
                      name={`equipamentos[${index}][qtd]`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextField
                            placeholder="h"
                            color="primary"
                            {...field}
                            style={{
                              width: "5%",
                              marginLeft: "30px",
                            }}
                            variant="standard"
                            InputProps={{
                              disableUnderline: true,
                              inputProps: { min: 0 },
                            }}
                            type="number"
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              onQtdChange(e.target.value, index);
                            }}
                          />
                        </>
                      )}
                    />
                    {/* <Controller
                      name={`equipamentos[${index}][unidadesDropdown]`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Autocomplete
                            fullWidth
                            clearText="Limpar"
                            openText="Abrir"
                            closeText="Fechar"
                            noOptionsText="Sem dados"
                            className="unidadesId-form"
                            options={unidades || []}
                            value={value || null}
                            getOptionLabel={(option) =>
                              `${option.descricao}` || ""
                            }
                            isOptionEqualToValue={(option, value) => {
                              // if (!value.id) return true;
                              return value.id === option.id;
                            }}
                            filterOptions={(options, state) => {
                              let newOptions = [];
                              options.forEach((option) => {
                                if (
                                  option.id > 0 &&
                                  option.descricao
                                    .toLowerCase()
                                    .includes(state.inputValue.toLowerCase())
                                )
                                  newOptions.push(option);
                              });
                              return newOptions;
                            }}
                            style={{
                              maxWidth: "5%",
                              visibility: "hidden",
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Uni."
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                variant="standard"
                              />
                            )}
                            onChange={(_, value) => {
                              onChange(value);
                              return value;
                            }}
                          />
                        </>
                      )}
                    /> */}
                    <Controller
                      name={`equipamentos[${index}][dataInicio]`}
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error, invalid },
                      }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={ptLocale}
                        >
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            invalidDateMessage="Data Inválida"
                            value={value}
                            onChange={(value) => {
                              onChange(value);
                            }}
                            components={{
                              OpenPickerIcon: calendarIcon,
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={invalid}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "dd/mm/aaaa",
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                variant="standard"
                                size="small"
                                sx={{
                                  width: "10%",
                                  marginLeft: "20px",
                                  paddingRight: "10px",
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />

                    <Controller
                      name={`equipamentos[${index}][dataFim]`}
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error, invalid },
                      }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={ptLocale}
                        >
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            invalidDateMessage="Data Inválida"
                            value={value}
                            components={{
                              OpenPickerIcon: calendarIcon,
                            }}
                            onChange={(value) => {
                              onChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={invalid}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "dd/mm/aaaa",
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                variant="standard"
                                size="small"
                                sx={{
                                  width: "10%",
                                  marginLeft: "20px",
                                  paddingRight: "10px",
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />

                    <Controller
                      name={`equipamentos[${index}][observacoes]`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextField
                            placeholder="Observações"
                            color="primary"
                            {...field}
                            style={{
                              width: "23%",
                              paddingLeft: "30px",
                            }}
                            variant="standard"
                            InputProps={{ disableUnderline: true }}
                          />
                        </>
                      )}
                    />
                    <div
                      style={{
                        width: "10%",
                        display: "flex",
                        justifyContent: "space-around",
                        paddingLeft: "20px",
                      }}
                    >
                      <div>
                        {renderSwitch(
                          getValues(`equipamentos[${index}].estadodt`)
                        )}
                      </div>
                      <div>
                        {renderSwitch(
                          getValues(`equipamentos[${index}].estadodc`)
                        )}
                      </div>
                    </div>
                  </div>
                  {/* {equipamento &&
                    equipamento.subEquipamentos &&
                    equipamento.subEquipamentos.map(
                      (subEquipamento, subEquipamentoIndex) => (
                        <>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              aria-label="account of current user"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              onClick={() =>
                                handleRemoveSub(
                                  equipamento,
                                  index,
                                  subEquipamento,
                                  subEquipamentoIndex
                                )
                              }
                              color="inherit"
                            >
                              <FeatherIcon icon="x-square" size={20} />
                            </IconButton>
                            <Controller
                              name={`equipamentos[${index}][subEquipamentos][${subEquipamentoIndex}][subMateriaisDropdown]`}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <Autocomplete
                                    freeSolo
                                    fullWidth
                                    clearText="Limpar"
                                    openText="Abrir"
                                    closeText="Fechar"
                                    noOptionsText="Sem dados"
                                    className="unidadesId-form"
                                    options={subMateriais || []}
                                    value={value || null}
                                    getOptionLabel={(option) =>
                                      `${option.descricao}` || ""
                                    }
                                    // filterOptions={(options, state) => {
                                    //   let newOptions = [];
                                    //   options.forEach((option) => {
                                    //     if (
                                    //       option.id !== "" &&
                                    //       option.descricao
                                    //         .toLowerCase()
                                    //         .includes(
                                    //           state.inputValue.toLowerCase()
                                    //         )
                                    //     )
                                    //       newOptions.push(option);
                                    //   });
                                    //   return newOptions;
                                    // }}
                                    isOptionEqualToValue={(option, value) => {
                                      // if (!value.id) return true;
                                      return value.id === option.id;
                                    }}
                                    style={{
                                      width: "23%",
                                      marginLeft: "10px",
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Equipamento"
                                        InputProps={{
                                          ...params.InputProps,
                                          disableUnderline: true,
                                        }}
                                        variant="standard"
                                      />
                                    )}
                                    onHighlightChange={(e, value, reason) => {
                                      const catId = getValues(
                                        `equipamentos[${index}].equipamentosDropdown.id`
                                      );
                                      dispatch(GetObrasSubMateriais(catId));
                                    }}
                                    onChange={(_, value, reason) => {
                                      console.log(value);
                                      if (reason === "clear") {
                                        handleRemoveUnidade(
                                          subEquipamentoIndex
                                        );
                                      } else {
                                        if (typeof value === "string") {
                                          // The user entered a new value that is not in the options list
                                          const newEquipamento = {
                                            id: null,
                                            descricao: value,
                                            unidade: null,
                                            equipamento: {
                                              id: null,
                                              descricao: value,
                                            },
                                          };
                                          onChange(newEquipamento.equipamento);
                                          onSubMatChange(
                                            newEquipamento.equipamento,
                                            index,
                                            subEquipamentoIndex
                                          );
                                          setValue(
                                            `equipamentos[${index}][subEquipamentos][${subEquipamentoIndex}][unidadesDropdown]`,
                                            newEquipamento.unidade
                                          );
                                          handleUnidadeChange(
                                            subEquipamentoIndex,
                                            newEquipamento.unidade
                                          );
                                          return newEquipamento.equipamento;
                                        } else {
                                          // User selected an existing option from the options list
                                          onChange(value.equipamento);
                                          onSubMatChange(
                                            value.equipamento,
                                            index,
                                            subEquipamentoIndex
                                          );
                                          setValue(
                                            `equipamentos[${index}][subEquipamentos][${subEquipamentoIndex}][unidadesDropdown]`,
                                            value.unidade
                                          );
                                          handleUnidadeChange(
                                            subEquipamentoIndex,
                                            value.unidade
                                          );
                                          return value.equipamento;
                                        }
                                      }
                                    }}
                                  />
                                </>
                              )}
                            />
                            <Controller
                              name={`equipamentos[${index}][subEquipamentos][${subEquipamentoIndex}][estadosDropdown]`}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <Autocomplete
                                    fullWidth
                                    clearText="Limpar"
                                    openText="Abrir"
                                    closeText="Fechar"
                                    noOptionsText="Sem dados"
                                    className="unidadesId-form"
                                    options={estadosMaterial || []}
                                    value={value || null}
                                    getOptionLabel={(option) =>
                                      `${option.descricao}` || ""
                                    }
                                    isOptionEqualToValue={(option, value) => {
                                      // if (!value.id) return true;
                                      return value.id === option.id;
                                    }}
                                    filterOptions={(options, state) => {
                                      let newOptions = [];
                                      options.forEach((option) => {
                                        if (
                                          option.id > 0 &&
                                          option.descricao
                                            .toLowerCase()
                                            .includes(
                                              state.inputValue.toLowerCase()
                                            )
                                        )
                                          newOptions.push(option);
                                      });
                                      return newOptions;
                                    }}
                                    style={{
                                      width: "14%",
                                      marginLeft: "10px",
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Estado"
                                        InputProps={{
                                          ...params.InputProps,
                                          disableUnderline: true,
                                        }}
                                        variant="standard"
                                      />
                                    )}
                                    onChange={(_, value) => {
                                      onChange(value);
                                      onSubStateChange(
                                        value,
                                        index,
                                        subEquipamentoIndex
                                      );
                                      return value;
                                    }}
                                  />
                                </>
                              )}
                            />
                            <Controller
                              name={`equipamentos[${index}][subEquipamentos][${subEquipamentoIndex}][qtd]`}
                              control={control}
                              render={({ field }) => (
                                <>
                                  <TextField
                                    placeholder="Qtd"
                                    color="primary"
                                    {...field}
                                    style={{
                                      width: "5%",
                                      marginLeft: "30px",
                                    }}
                                    variant="standard"
                                    disabled={
                                      !getValues(
                                        `equipamentos[${index}].subEquipamentos[${subEquipamentoIndex}].subEquipamentosDropdown.descricao`
                                      )
                                    }
                                    InputProps={{
                                      disableUnderline: true,
                                      inputProps: { min: 0 },
                                    }}
                                    type="number"
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      onSubQtdChange(
                                        e.target.value,
                                        index,
                                        subEquipamentoIndex
                                      );
                                    }}
                                  />
                                </>
                              )}
                            />
                            <Controller
                              name={`equipamentos[${index}][subEquipamentos][${subEquipamentoIndex}][unidadesDropdown]`}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <Autocomplete
                                    fullWidth
                                    clearText="Limpar"
                                    openText="Abrir"
                                    closeText="Fechar"
                                    noOptionsText="Sem dados"
                                    className="unidadesId-form"
                                    options={unidades || []}
                                    value={
                                      value ||
                                      unidMedida[subEquipamentoIndex] ||
                                      null
                                    }
                                    getOptionLabel={(option) =>
                                      `${option.descricao}` || ""
                                    }
                                    isOptionEqualToValue={(option, value) => {
                                      // if (!value.id) return true;
                                      return value.id === option.id;
                                    }}
                                    filterOptions={(options, state) => {
                                      let newOptions = [];
                                      options.forEach((option) => {
                                        if (
                                          option.id > 0 &&
                                          option.descricao
                                            .toLowerCase()
                                            .includes(
                                              state.inputValue.toLowerCase()
                                            )
                                        )
                                          newOptions.push(option);
                                      });
                                      return newOptions;
                                    }}
                                    style={{
                                      maxWidth: "5%",
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Uni."
                                        InputProps={{
                                          ...params.InputProps,
                                          disableUnderline: true,
                                        }}
                                        variant="standard"
                                      />
                                    )}
                                    onChange={(_, value) => {
                                      onChange(value);
                                      return value;
                                    }}
                                  />
                                </>
                              )}
                            />
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              locale={ptLocale}
                            >
                              <Controller
                                name={`equipamentos[${index}][subEquipamentos][${subEquipamentoIndex}][dataInicio]`}
                                control={control}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error, invalid },
                                }) => (
                                  <>
                                    <DatePicker
                                      invalidDateMessage="Data Inválida"
                                      inputFormat="dd/MM/yyyy"
                                      value={value}
                                      components={{
                                        OpenPickerIcon: calendarIcon,
                                      }}
                                      onChange={(
                                        valueChange,
                                        keyboardInputValue
                                      ) => {
                                        onChange(valueChange);
                                      }}
                                      renderInput={({
                                        error: inputError,
                                        ...params
                                      }) => (
                                        <TextField
                                          {...params}
                                          inputProps={{
                                            ...params.inputProps,
                                            placeholder: "dd/mm/aaaa",
                                          }}
                                          InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                          }}
                                          variant="standard"
                                          size="small"
                                          sx={{
                                            width: "10%",
                                            marginLeft: "20px",
                                            paddingRight: "10px",
                                          }}
                                          error={
                                            !!errors?.equipamentos?.[index]
                                              ?.subEquipamentos?.[
                                              subEquipamentoIndex
                                            ]?.dataInicio
                                          }
                                          helperText={
                                            errors?.equipamentos?.[index]
                                              ?.subEquipamentos?.[
                                              subEquipamentoIndex
                                            ]?.dataInicio
                                              ? errors?.equipamentos[index]
                                                  ?.subEquipamentos?.[
                                                  subEquipamentoIndex
                                                ]?.dataInicio?.message
                                              : ""
                                          }
                                        />
                                      )}
                                    />
                                  </>
                                )}
                              />
                            </LocalizationProvider>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              locale={ptLocale}
                            >
                              <Controller
                                name={`equipamentos[${index}][subEquipamentos][${subEquipamentoIndex}][dataFim]`}
                                control={control}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error, invalid },
                                }) => (
                                  <>
                                    <DatePicker
                                      inputFormat="dd/MM/yyyy"
                                      invalidDateMessage="Data Inválida"
                                      value={value}
                                      components={{
                                        OpenPickerIcon: calendarIcon,
                                      }}
                                      onChange={(value) => {
                                        onChange(value);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          // error={invalid}
                                          inputProps={{
                                            ...params.inputProps,
                                            placeholder: "dd/mm/aaaa",
                                          }}
                                          InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                          }}
                                          variant="standard"
                                          size="small"
                                          sx={{
                                            width: "10%",
                                            marginLeft: "20px",
                                            paddingRight: "10px",
                                          }}
                                          error={
                                            !!errors?.equipamentos?.[index]
                                              ?.subEquipamentos?.[
                                              subEquipamentoIndex
                                            ]?.dataFim
                                          }
                                          helperText={
                                            errors?.equipamentos?.[index]
                                              ?.subEquipamentos?.[
                                              subEquipamentoIndex
                                            ]?.dataFim
                                              ? errors?.equipamentos[index]
                                                  ?.subEquipamentos?.[
                                                  subEquipamentoIndex
                                                ]?.dataFim?.message
                                              : ""
                                          }
                                        />
                                      )}
                                    />
                                  </>
                                )}
                              />
                            </LocalizationProvider>
                            <Controller
                              name={`equipamentos[${index}][subEquipamentos][${subEquipamentoIndex}][observacoes]`}
                              control={control}
                              render={({ field }) => (
                                <>
                                  <TextField
                                    placeholder="Observações"
                                    multiline
                                    color="primary"
                                    {...field}
                                    style={{
                                      width: "25%",
                                      paddingLeft: "30px",
                                    }}
                                    variant="standard"
                                    InputProps={{ disableUnderline: true }}
                                  />
                                </>
                              )}
                            />
                          </div>
                        </>
                      )
                    )} */}
                </form>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default Equipamentos;
