import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Paper } from '@mui/material';

//Components
import Calendario from './sectionsConsulta/calendario';
import MaoDeObra from './sectionsConsulta/maoDeObra';
import Materiais from './sectionsConsulta/materiais';
import Observacoes from './sectionsConsulta/observacoes';
import Controlo from './sectionsConsulta/controlo';
import Estados from './sectionsConsulta/estados';
import Spinner from '../../../../../../Global/Components/Spinner';

//Icons
import FeatherIcon from 'feather-icons-react';

const DrawerFormConsulta = ({ toggleDrawer, classes }) => {
  const [obraSelecionada, setObraSelecionada] = useState(null);

  const obra = useSelector((state) => state.energias.obra);

  useEffect(() => {
    setObraSelecionada(obra);
  }, [obra]);

  return (
    <>
      {obraSelecionada && Object.keys(obraSelecionada).length > 0 ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '40px',
            height: '100%',
          }}
        >
          <div className={classes.drawerHeader}>
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => toggleDrawer('right', false)}
            >
              <div>
                <FeatherIcon
                  icon='chevron-left'
                  size={20}
                  style={{ strokeWidth: '1.5' }}
                />
              </div>
              <div className='text-underline-close-drawer'>Lista de Obras</div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {obraSelecionada && obraSelecionada.obrasId}
              <span
                style={{
                  background: '#D6DFE8',
                  height: '30px',
                  width: '2px',
                  margin: '0px 15px',
                }}
              />
              {obraSelecionada && obraSelecionada.descricao}
              <span
                style={{
                  background: '#D6DFE8',
                  height: '30px',
                  width: '2px',
                  margin: '0px 15px',
                }}
              />
              {obraSelecionada && obraSelecionada.responsavelNome}
            </div>

            <div style={{ width: '40px' }} />
          </div>
          <Paper sx={{ px: 6, py: 2, mt: 4 }}>
            <h4
              style={{
                fontSize: '22px',
                width: '100%',
                marginBottom: '0px',
              }}
              className='text-underline'
            >
              Caracterização da obra
            </h4>
            <div style={{ display: 'flex' }}>
              <Calendario obraSelecionada={obraSelecionada} />
              <Controlo obraSelecionada={obraSelecionada} />
            </div>
            <div style={{ display: 'flex' }}>
              <Estados obraSelecionada={obraSelecionada} />
              <MaoDeObra obraSelecionada={obraSelecionada} />
            </div>

            <div
              style={{
                marginTop: '30px',
              }}
            />
            <Materiais
              obraSelecionada={obraSelecionada}
              setObraSelecionada={setObraSelecionada}
              classes={classes}
            />
            <div
              style={{
                marginTop: '30px',
              }}
            />
            <Observacoes obraSelecionada={obraSelecionada} />
          </Paper>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export default DrawerFormConsulta;
