/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";

//ACTIONS
import ShowNotification from "../../../../../utils/redux/actions/global/notifications/NotificationAction";
import EditWorkPermitAccountableById from "../../../../../utils/redux/actions/aprovisionamento/obras/EditWorkPermitAccountableAction";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnUpload: {
    color: "#E21450",
    // width: '125px',
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
});

const FormEdit = ({
  toggleDrawer,
  drawerWidth,
  dimensions,
  responsavel,
  setResponsavel,
  resetResponsavel,
  entities,
  entity,
  setEntity,
  checkDefault,
  setCheckDefault,
  checkSendEmail,
  setCheckSendEmail,
  checkSendEmailDefault,
  setCheckSendEmailDefault,
  checkCCEmails,
  setCheckCCEmails,
  fetchResponsaveisWorkPermits,
  respId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const responsavelSelecionado = useSelector(
    (state) => state.obras.workPermitAccountable
  );

  const handleCheckboxChange = () => {
    setCheckDefault(!checkDefault);
    setCheckCCEmails(!checkDefault);
  };

  const handleCheckboxEmailChange = () => {
    setCheckSendEmail(!checkSendEmail);
  };

  const handleCheckboxEmailDefaultChange = () => {
    setCheckSendEmailDefault(!checkSendEmailDefault);
    setCheckSendEmail(!checkSendEmailDefault);
  };

  const handleCheckboxCCEmailChange = () => {
    setCheckCCEmails(!checkCCEmails);
  };

  // useEffect(() => {
  //   fetchResponsavelId(respId);
  // }, []);

  // ############################################################## //
  // ##################  Responsável selecionado  ################# //
  // ############################################################## //

  useEffect(() => {
    const types =
      responsavelSelecionado &&
      responsavelSelecionado.types &&
      JSON.parse(responsavelSelecionado.types);
    console.log(types);
    setResponsavel({
      name:
        responsavelSelecionado &&
        responsavelSelecionado.jaf_work_permit_accountable_db_name,
      email:
        responsavelSelecionado &&
        responsavelSelecionado.jaf_work_permit_accountable_db_email,
      contact:
        responsavelSelecionado &&
        responsavelSelecionado.jaf_work_permit_accountable_db_contact,
    });
    setEntity({
      id:
        responsavelSelecionado &&
        responsavelSelecionado.id_jaf_work_permit_entity,
      descricao:
        responsavelSelecionado &&
        responsavelSelecionado.jaf_work_permit_entity_name,
    });
    setCheckDefault(
      responsavelSelecionado &&
        responsavelSelecionado.jaf_work_permit_accountable_db_default_flag ===
          "1"
        ? true
        : false
    );
    setCheckCCEmails(types && types.find((t) => t == 4) ? true : false);
    setCheckSendEmail(types && types.find((t) => t == 3) ? true : false);
    setCheckSendEmailDefault(
      responsavelSelecionado &&
        responsavelSelecionado.jaf_work_permit_accountable_db_emissao_flag ===
          "1"
        ? true
        : false
    );
  }, [responsavelSelecionado, setResponsavel]);

  // ############################################################## //
  // ###############  Handle Change and Submit Form  ############## //
  // ############################################################## //

  const handleChange = (e) => {
    e.preventDefault();
    setResponsavel({
      ...responsavel,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        name: responsavel.name,
        email: responsavel.email,
        contact: responsavel.contact,
        entity: entity.id,
        checkDefault: checkDefault ? 1 : 0,
        checkSendEmail: checkSendEmail ? 1 : 0,
        checkSendEmailDefault: checkSendEmailDefault ? 1 : 0,
        checkCCEmails: checkCCEmails ? 1 : 0,
      },
    ];

    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!",
      type: "error",
    };
    const message = {
      isOpen: true,
      message: "Responsável editado com sucesso!!",
      type: "success",
    };

    dispatch(
      EditWorkPermitAccountableById(
        responsavelSelecionado.id_jaf_work_permit_accountable_db,
        ydata,
        toggleDrawer,
        ShowNotification,
        message,
        errorMessage
      )
    ).then(() => {
      resetResponsavel();
      toggleDrawer("right", false);
      dispatch(ShowNotification(message));
      fetchResponsaveisWorkPermits();
    });
  };

  return (
    <div
      className={classes.list}
      style={
        dimensions.width <= 1100
          ? { width: "60vw" }
          : { width: `${drawerWidth}` }
      }
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 5,
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => {
            toggleDrawer("right", false);
            resetResponsavel();
          }}
        >
          <div>
            <FeatherIcon
              icon="chevron-left"
              size={20}
              style={{ strokeWidth: "1.5" }}
            />
          </div>
          <div className="text-underline-close-drawer">Responsáveis</div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box>
      </Box>
      <Card
        sx={{
          m: 5,
          p: 2,
          boxShadow: "0px 10px 15px lightgrey",
        }}
      >
        <Box
          sx={{
            m: 2,
          }}
        >
          <h4
            style={{
              fontSize: "22px",
              width: "100%",
              marginBottom: "0px",
            }}
            className="text-underline"
          >
            Responsável
          </h4>
        </Box>
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Detalhes
          </h4>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "48%" }}>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Nome
              </Typography>
              <TextField
                name="name"
                size="small"
                value={responsavel.name}
                variant="outlined"
                onChange={handleChange}
                style={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Email
                </Typography>
                <TextField
                  name="email"
                  size="small"
                  value={responsavel.email}
                  variant="outlined"
                  onChange={handleChange}
                  style={{ width: "100%" }}
                />
              </Box>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Contacto
                </Typography>
                <TextField
                  name="contact"
                  size="small"
                  value={responsavel.contact}
                  variant="outlined"
                  onChange={handleChange}
                  style={{ width: "100%" }}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "48%" }}>
            <Box sx={{ m: 1, width: "46.5%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Entidade
              </Typography>
              <Autocomplete
                fullWidth
                name="entity"
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                value={entity}
                className="unidadesId-form"
                options={entities || []}
                getOptionLabel={(option) => `${option.descricao}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setEntity({ id: null, descricao: "" });
                    return;
                  } else {
                    setEntity(value);
                    return value;
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                my: 1,
                alignItems: "center",
              }}
            >
              <Box>
                <Checkbox
                  checked={checkDefault}
                  onChange={handleCheckboxChange}
                />
              </Box>
              <Typography style={{ fontWeight: "bold" }}>
                Responsável padrão
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                my: 1,
                alignItems: "center",
              }}
            >
              <Box>
                <Checkbox
                  disabled={checkDefault ? true : false}
                  checked={checkCCEmails}
                  onChange={handleCheckboxCCEmailChange}
                />
              </Box>
              <Typography style={{ fontWeight: "bold" }}>
                Notificar por email
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                my: 1,
                alignItems: "center",
              }}
            >
              <Box>
                <Checkbox
                  disabled={checkSendEmailDefault ? true : false}
                  checked={checkSendEmail}
                  onChange={handleCheckboxEmailChange}
                />
              </Box>
              <Typography style={{ fontWeight: "bold" }}>
                Notificar na emissão do WP
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                my: 1,
                alignItems: "center",
              }}
            >
              <Box>
                <Checkbox
                  checked={checkSendEmailDefault}
                  onChange={handleCheckboxEmailDefaultChange}
                />
              </Box>
              <Typography style={{ fontWeight: "bold" }}>
                Contacto padrão notificado na emissão do WP
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </div>
  );
};

export default FormEdit;
