import Instance from "../../../Axios";

// New Actions for the app

const EditPlusAdesao = (yData, id) => async (dispatch) => {
  await Instance()
    .put(`/plus/adesoes/${id}`, { yData })
    .then(({ data }) => {
      dispatch({ type: "EDIT_PLUS_ADESAO", payload: data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default EditPlusAdesao;
