import Instance from '../../../Axios';

// New Actions for the app

const GetPermissoesPerfil = (perfisId) => async (dispatch) => {
  // console.log(perfisId);
  await Instance()
    .get(`/perfis/${perfisId}/permissoes`)
    .then(({ data }) => {
      // console.log(data);
      dispatch({ type: 'GET_PERMISSOES', payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetPermissoesPerfil;
