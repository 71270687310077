/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const GraficoPercOrcaAdjudicado = (percOrcaAdjudicado) => {
  // const percOrcaAdjudicado = useSelector(
  //   (state) => state.energiasDashboard.percOrcaAdjudicado
  // );

  const percOrcamento =
    percOrcaAdjudicado && percOrcaAdjudicado.percOrcaAdjudicado;
  
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 25,
        style: {
          fontSize: "12px",
          colors: ["#000"],
        },
        formatter: function (value) {
          return value + " %";
        },
      },
      title: {
        text: "% de Orçamentos Adjudicados pelo Total das Adjudicações",
        align: "center",
        style: {
          fontFamily: "Lato",
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
        },
        max: null,
        categories: [],
      },
      grid: {
        show: true,
      },
      fill: {
        opacity: 1,
      },
      colors: ["#FF4A4A"],
    },
  });

  useEffect(() => {
    setState({
      series: [
        {
          data: percOrcamento.map((item) => Math.round(item.percentagem)),
        },
      ],
      options: {
        xaxis: {
          max: 100,
          categories: percOrcamento.map((item) => item.nome),
        },
      },
    });
  }, [percOrcamento]);

  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={300}
        width={500}
      />
    </>
  );
};

export default GraficoPercOrcaAdjudicado;
