import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  Autocomplete,
  IconButton,
  Menu,
  Button,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptLocale from "date-fns/locale/pt";

//Icons
import FeatherIcon from "feather-icons-react";

const Materiais = ({
  obraSelecionada,
  setObraSelecionada,
  control,
  reset,
  watch,
  resetForm,
  errors,
  setResetForm,
  getValues,
  setValue,
  updateGlobals,
  renderSwitch,
  setEstDT,
  setEstDC,
}) => {
  const estadosMaterial = useSelector(
    (state) => state.energias.estadosMaterial
  );
  const materiais = useSelector((state) => state.energias.materiais);
  const subMateriais = useSelector((state) => state.energias.subMateriais);

  const calendarIcon = () => {
    return <FeatherIcon icon="calendar" size={20} />;
  };

  // ############################################################## //
  // ######################  Semaforizações  ###################### //
  // ############################################################## //

  // Valores das Cores
  // 1 - Verde
  // 2 - Amarelo
  // 3 - Vermelho

  // Caso estado da familia seja alterado
  const onStateChange = (value, index) => {
    switch (value && value.id) {
      case 1:
        setEstDC([index, "1"]);
        setValue(`materiais[${index}].estadodc`, "1");
        break;
      case 2:
        setEstDC([index, "2"]);
        setValue(`materiais[${index}].estadodc`, "2");
        break;
      case 3:
        setEstDC([index, "2"]);
        setValue(`materiais[${index}].estadodc`, "2");
        break;
      case 4:
        setEstDC([index, "2"]);
        setValue(`materiais[${index}].estadodc`, "2");
        break;
      case 5:
        setEstDC([index, "1"]);
        setValue(`materiais[${index}].estadodc`, "1");
        break;
      default:
        setEstDC([index, "3"]);
        setValue(`materiais[${index}].estadodc`, "3");
        break;
    }
    updateGlobals();
    console.log(getValues());
  };

  // Caso estado do material seja alterado
  const onSubStateChange = (value, index, subMaterialIndex) => {
    if (value) {
      switch (value.id) {
        case 1:
          setEstDC([index, "1"]);
          setValue(
            `materiais[${index}].subMateriais[${subMaterialIndex}].estadodc`,
            "1"
          );
          break;
        case 2:
          setEstDC([index, "2"]);
          setValue(
            `materiais[${index}].subMateriais[${subMaterialIndex}].estadodc`,
            "2"
          );
          break;
        case 3:
          setEstDC([index, "2"]);
          setValue(
            `materiais[${index}].subMateriais[${subMaterialIndex}].estadodc`,
            "2"
          );
          break;
        case 4:
          setEstDC([index, "2"]);
          setValue(
            `materiais[${index}].subMateriais[${subMaterialIndex}].estadodc`,
            "2"
          );
          break;
        case 5:
          setEstDC([index, "1"]);
          setValue(
            `materiais[${index}].subMateriais[${subMaterialIndex}].estadodc`,
            "1"
          );
          break;
        // case 7:
        //   setEstDC([index, "0"]);
        //   setValue(
        //     `materiais[${index}].subMateriais[${subMaterialIndex}].estadodc`,
        //     "0"
        //   );
        //   setEstDT([index, "0"]);
        //   setValue(
        //     `materiais[${index}].subMateriais[${subMaterialIndex}].estadodt`,
        //     "0"
        //   );
        //   break;
        default:
          break;
      }
      updateGlobals();
    } else {
      setEstDC([index, "3"]);
      setValue(
        `materiais[${index}].subMateriais[${subMaterialIndex}].estadodc`,
        "3"
      );
      updateGlobals();
    }
    console.log(getValues());
  };

  // Caso familia seja alterado
  const onMatChange = (value, index) => {
    if (value) {
      if (getValues(`materiais[${index}].qtd`)) {
        setEstDT([index, "1"]);
        setValue(`materiais[${index}].estadodt`, "1");
      } else {
        setEstDT([index, "2"]);
        setValue(`materiais[${index}].estadodt`, "2");
      }

      if (!getValues(`materiais[${index}].estadosDropdown`)) {
        setEstDC([index, "3"]);
        setValue(`materiais[${index}].estadodc`, "3");
      }
      updateGlobals();
    }
    console.log(getValues());
  };

  // Caso material seja alterado
  const onSubMatChange = (value, index, subMaterialIndex) => {
    if (value) {
      if (
        getValues(`materiais[${index}].subMateriais[${subMaterialIndex}].qtd`)
      ) {
        setEstDT([index, "1"]);
        setValue(
          `materiais[${index}].subMateriais[${subMaterialIndex}].estadodt`,
          "1"
        );
      } else {
        setEstDT([index, "2"]);
        setValue(
          `materiais[${index}].subMateriais[${subMaterialIndex}].estadodt`,
          "2"
        );
      }
      if (
        !getValues(
          `materiais[${index}].subMateriais[${subMaterialIndex}].estadosDropdown`
        )
      ) {
        setEstDC([index, "3"]);
        setValue(
          `materiais[${index}].subMateriais[${subMaterialIndex}].estadodc`,
          "3"
        );
      }
      updateGlobals();
    } else {
      setEstDT([index, "2"]);
      setValue(
        `materiais[${index}].subMateriais[${subMaterialIndex}].estadodt`,
        "2"
      );
      updateGlobals();
    }
    console.log(getValues());
  };

  // Caso quantidade seja alterada
  const onQtdChange = (value, index) => {
    if (value) {
      setEstDT([index, "1"]);
      setValue(`materiais[${index}].estadodt`, "1");
      updateGlobals();
    }
    console.log(getValues());
  };

  // Caso quantidade seja alterada
  const onSubQtdChange = (value, index, subMaterialIndex) => {
    if (value) {
      setEstDT([index, "1"]);
      setValue(
        `materiais[${index}].subMateriais[${subMaterialIndex}].estadodt`,
        "1"
      );
      updateGlobals();
    } else {
      setEstDT([index, "2"]);
      setValue(
        `materiais[${index}].subMateriais[${subMaterialIndex}].estadodt`,
        "2"
      );
      updateGlobals();
    }
    console.log(getValues());
  };

  // ############################################################## //
  // ####################  Adicionar Familia  ##################### //
  // ############################################################## //

  const handleAddMat = async () => {
    console.log(obraSelecionada.materiais.length);
    const lastMat =
      obraSelecionada &&
      obraSelecionada.materiais &&
      obraSelecionada.materiais.length + 1;

    setResetForm(true);

    const newMaterial = {
      dataFim: null,
      dataInicio: null,
      estadosDescricao: null,
      estadosDropdown: null,
      estadosId: null,
      materiaisDescricao: null,
      materiaisDropdown: { id: "", descricao: "" },
      materiaisId: lastMat,
      obrasId: obraSelecionada && obraSelecionada.obrasId,
      observacoes: null,
      qtd: null,
      subMateriais: [],
      estadodt: null,
      estadodc: null,
    };

    obraSelecionada.materiais.push(newMaterial);
    await setObraSelecionada({ ...obraSelecionada });

    await setResetForm(false);

    console.log(resetForm, obraSelecionada);
  };

  // ############################################################## //
  // ####################  Adicionar Material  #################### //
  // ############################################################## //

  const handleAddSub = async (material, index) => {
    console.log(material, index);
    const lastSub = material.subMateriais.length + 1;

    setResetForm(true);

    const newMaterial = {
      dataFim: null,
      dataInicio: null,
      estadosDescricao: null,
      estadosDropdown: null,
      estadosId: null,
      materiaisDescricao: null,
      materiaisId: material.materiaisId,
      obrasId: obraSelecionada && obraSelecionada.obrasId,
      observacoes: null,
      qtd: null,
      subMateriaisDescricao: "",
      subMateriaisDropdown: { id: "", descricao: "" },
      subMateriaisId: lastSub,
      estadodt: null,
      estadodc: null,
    };

    obraSelecionada.materiais[index].subMateriais.push(newMaterial);
    await setObraSelecionada({ ...obraSelecionada });

    await setResetForm(false);

    console.log(resetForm);
  };

  // ############################################################## //
  // ######################  Remover Familia  ##################### //
  // ############################################################## //

  const handleRemoveMat = async (index) => {
    console.log(index);
    const obra = getValues();
    await setResetForm(true);
    // const removeMaterialId = obra.materiais[index].subMateriais.findIndex(
    //   (sub) => sub.subMateriaisId === subMaterial.subMateriaisId
    // );

    // console.log(subMaterial, removeMaterialId);
    // console.log(obraSelecionada.materiais[index].subMateriais);
    // console.log(subMaterial.subMateriaisId);
    if (index !== -1) {
      obra.materiais.splice(index, 1);
    }

    await setObraSelecionada({ ...obra });
    await setResetForm(false);
    await reset({ ...obra });
    updateGlobals();
  };

  // ############################################################## //
  // #####################  Remover Material  ##################### //
  // ############################################################## //

  const handleRemoveSub = async (
    material,
    index,
    subMaterial,
    subMaterialIndex
  ) => {
    const obra = getValues();
    await setResetForm(true);
    // const removeMaterialId = obra.materiais[index].subMateriais.findIndex(
    //   (sub) => sub.subMateriaisId === subMaterial.subMateriaisId
    // );

    // console.log(subMaterial, removeMaterialId);
    // console.log(obraSelecionada.materiais[index].subMateriais);
    // console.log(subMaterial.subMateriaisId);
    if (subMaterialIndex !== -1) {
      obra.materiais[index].subMateriais.splice(subMaterialIndex, 1);
    }

    await setObraSelecionada({ ...obra });
    await setResetForm(false);
    await reset({ ...obra });
    updateGlobals();
  };

  // ############################################################## //
  // ######################  Button Collapse  ##################### //
  // ############################################################## //

  const [anchorEl, setAnchorEl] = useState([]);
  const handleMenu = (index, event) => {
    let tableMenus = [...anchorEl];
    tableMenus[index] = event.currentTarget;
    setAnchorEl(tableMenus);
  };
  const handleClose = (index) => {
    let tableMenus = [...anchorEl];
    tableMenus[index] = null;
    setAnchorEl(tableMenus);
  };

  useEffect(() => {
    getValues();
  }, [getValues]);

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            width: "100%",
            padding: "5px",
            background: "#F1F1F1",
            border: "1px solid #707070",
            borderRadius: "8px",
          }}
        >
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => handleAddMat()}
            color="inherit"
          >
            <FeatherIcon icon="plus-square" size={20} />
          </IconButton>
          <h4
            style={{
              width: "30%",
              margin: "10px 0px 10px 5px",
              padding: "0px",
            }}
          >
            Materiais
          </h4>

          <h4
            style={{
              width: "15%",
              margin: "10px 0px 10px 25px",
              padding: "0px",
            }}
          >
            Estado
          </h4>

          <h4
            style={{
              width: "5%",
              margin: "10px 0px 10px 10px",
              padding: "0px",
            }}
          >
            Qtd
          </h4>
          <h4
            style={{
              width: "8.75%",
              margin: "10px 0px 10px 10px",
              padding: "0px",
            }}
          >
            Data Inicio
          </h4>
          <h4
            style={{
              width: "8.75%",
              margin: "10px 0px 10px 20px",
              padding: "0px",
            }}
          >
            Data Fim
          </h4>
          <h4
            style={{
              width: "25%",
              margin: "10px 0px 10px 15px",
              padding: "0px",
            }}
          >
            Observações
          </h4>
          <div
            style={{
              width: "10%",
              display: "flex",
              justifyContent: "space-around",
              marginLeft: "5px",
              float: "right",
            }}
          >
            <h4
              style={{
                margin: "10px 0px 10px 0px",
                padding: "0px",
              }}
            >
              Téc.
            </h4>
            <h4
              style={{
                margin: "10px 0px 10px 0px",
                padding: "0px",
              }}
            >
              Com.
            </h4>
          </div>
        </div>

        {obraSelecionada &&
          obraSelecionada.materiais &&
          obraSelecionada.materiais.map((material, index) => (
            <div
              key={index}
              style={{
                background: "white",
                minHeight: "40px",
                marginTop: "10px",
                marginBottom: "10px",
                border: "1px solid #707070",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  padding: "5px",
                }}
              >
                <form
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  autocomple="off"
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={(e) => handleMenu(index, e)}
                      color="inherit"
                    >
                      <FeatherIcon icon="menu" size={20} />
                    </IconButton>

                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl[index]}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      // transformOrigin={{
                      //   vertical: "top",
                      //   horizontal: "right",
                      // }}
                      open={Boolean(anchorEl[index])}
                      onClose={(e) => handleClose(index)}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        <Button
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={() => {
                            handleAddSub(material, index);
                            handleClose(index);
                          }}
                          color="inherit"
                          sx={{
                            display: "flex",
                            textTransform: "capitalize",
                            justifyContent: "flex-start",
                          }}
                        >
                          {/* <FeatherIcon icon="plus-square" size={20} /> */}
                          Adicionar Material
                        </Button>
                        <Button
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={() => {
                            handleRemoveMat(index);
                            handleClose(index);
                          }}
                          color="inherit"
                          sx={{
                            display: "flex",
                            textTransform: "capitalize",
                            justifyContent: "flex-start",
                          }}
                        >
                          {/* <FeatherIcon icon="x-square" size={20} /> */}
                          Remover Familia
                        </Button>
                      </div>
                    </Menu>
                    <Controller
                      name={`materiais[${index}][materiaisDropdown]`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Autocomplete
                            fullWidth
                            clearText="Limpar"
                            openText="Abrir"
                            closeText="Fechar"
                            noOptionsText="Sem dados"
                            className="unidadesId-form"
                            options={materiais || []}
                            value={value || null}
                            getOptionLabel={(option) =>
                              `${option.descricao}` || ""
                            }
                            isOptionEqualToValue={(option, value) => {
                              // if (!value.id) return true;
                              const intOption = option && parseInt(option.id);
                              return value.id === intOption;
                              // console.log(intOption);
                            }}
                            filterOptions={(options, state) => {
                              let newOptions = [];
                              options.forEach((option) => {
                                if (
                                  option.id !== "" &&
                                  option.descricao
                                    .toLowerCase()
                                    .includes(state.inputValue.toLowerCase())
                                )
                                  newOptions.push(option);
                              });
                              return newOptions;
                            }}
                            style={{
                              width: "27%",
                              marginLeft: "5px",
                              marginRight: "30px",
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Familia"
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                variant="standard"
                              />
                            )}
                            onChange={(_, value) => {
                              onMatChange(value, index);
                              onChange(value);
                              return value;
                            }}
                          />
                        </>
                      )}
                    />

                    <Controller
                      name={`materiais[${index}][estadosDropdown]`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Autocomplete
                            fullWidth
                            clearText="Limpar"
                            openText="Abrir"
                            closeText="Fechar"
                            noOptionsText="Sem dados"
                            className="unidadesId-form"
                            options={estadosMaterial || []}
                            value={value || null}
                            getOptionLabel={(option) =>
                              `${option.descricao}` || ""
                            }
                            isOptionEqualToValue={(option, value) => {
                              // if (!value.id) return true;
                              return value.id === option.id;
                            }}
                            filterOptions={(options, state) => {
                              let newOptions = [];
                              options.forEach((option) => {
                                if (
                                  option.id > 0 &&
                                  option.descricao
                                    .toLowerCase()
                                    .includes(state.inputValue.toLowerCase())
                                )
                                  newOptions.push(option);
                              });
                              return newOptions;
                            }}
                            style={{
                              width: "15%",
                              marginLeft: "25px",
                              display: "none",
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Estado"
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                variant="standard"
                              />
                            )}
                            onChange={(_, value) => {
                              onStateChange(value, index);
                              onChange(value);
                              return value;
                            }}
                          />
                        </>
                      )}
                    />
                    <Controller
                      name={`materiais[${index}][qtd]`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextField
                            placeholder="Qtd"
                            color="primary"
                            style={{
                              width: "5%",
                              marginLeft: "10px",
                              display: "none",
                            }}
                            {...field}
                            disabled={
                              !getValues(
                                `materiais[${index}].materiaisDropdown.id`
                              )
                            }
                            variant="standard"
                            InputProps={{ disableUnderline: true }}
                            type="number"
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              onQtdChange(e.target.value, index);
                            }}
                          />
                        </>
                      )}
                    />

                    <Controller
                      name={`materiais[${index}][dataInicio]`}
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={ptLocale}
                        >
                          <DatePicker
                            invalidDateMessage="Data Inválida"
                            {...field}
                            components={{
                              OpenPickerIcon: calendarIcon,
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "dd/mm/aaaa",
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                variant="standard"
                                size="small"
                                sx={{
                                  width: "8.75%",
                                  marginLeft: "10px",
                                  marginTop: "2px",
                                  display: "none",
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />

                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ptLocale}
                    >
                      <Controller
                        name={`materiais[${index}][dataFim]`}
                        control={control}
                        render={({
                          field: { onChange, value },
                          fieldState: { error, invalid },
                        }) => (
                          <>
                            <DatePicker
                              inputFormat="dd/MM/yyyy"
                              invalidDateMessage="Data Inválida"
                              value={value}
                              components={{
                                OpenPickerIcon: calendarIcon,
                              }}
                              onChange={(value) => {
                                onChange(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={invalid}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "dd/mm/aaaa",
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                  }}
                                  variant="standard"
                                  size="small"
                                  sx={{
                                    width: "8.75%",
                                    marginRight: "20px",
                                    marginTop: "2px",
                                    display: "none",
                                  }}
                                />
                              )}
                            />
                          </>
                        )}
                      />
                    </LocalizationProvider>

                    <Controller
                      name={`materiais[${index}][observacoes]`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextField
                            placeholder="Observações"
                            color="primary"
                            {...field}
                            style={{
                              width: "25%",
                              marginLeft: "15px",
                              display: "none",
                            }}
                            variant="standard"
                            InputProps={{ disableUnderline: true }}
                          />
                        </>
                      )}
                    />

                    {/* <div
                      style={{
                        width: '5%',
                        display: 'flex',
                        justifyContent: 'center',
                        // display: 'none',
                      }}
                    >
                      {renderSwitch(getValues(`materiais[${index}].estadodt`))}
                    </div>

                    <div
                      style={{
                        width: '5%',
                        display: 'flex',
                        justifyContent: 'center',
                        // display: 'none',
                      }}
                    >
                      {renderSwitch(getValues(`materiais[${index}].estadodc`))}
                    </div> */}
                  </div>

                  {/* -------------------- SUBMATERIAIS -------------------- */}

                  <div>
                    {material &&
                      material.subMateriais &&
                      material.subMateriais.map(
                        (subMaterial, subMaterialIndex) => (
                          <>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                // background:
                                //   getValues(
                                //     `materiais[${index}].subMateriais[${subMaterialIndex}].estadosDropdown.id`
                                //   ) == 7
                                //     ? "linear-gradient(90deg, rgba(240,122,10,0.2) 0%, rgba(240,122,10,0.2) 100%)"
                                //     : null,
                                // borderRadius: "5px",
                              }}
                            >
                              <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={() =>
                                  handleRemoveSub(
                                    material,
                                    index,
                                    subMaterial,
                                    subMaterialIndex
                                  )
                                }
                                color="inherit"
                              >
                                <FeatherIcon icon="x-square" size={20} />
                              </IconButton>
                              <Controller
                                name={`materiais[${index}][subMateriais][${subMaterialIndex}][subMateriaisDropdown]`}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    {/* {console.log(value)} */}
                                    <Autocomplete
                                      fullWidth
                                      clearText="Limpar"
                                      openText="Abrir"
                                      closeText="Fechar"
                                      noOptionsText="Sem dados"
                                      className="unidadesId-form"
                                      options={subMateriais || []}
                                      value={value || null}
                                      getOptionLabel={(option) =>
                                        `${option.descricao}` || ""
                                      }
                                      filterOptions={(options, state) => {
                                        let newOptions = [];
                                        options.forEach((option) => {
                                          if (
                                            option.id !== "" &&
                                            option.descricao
                                              .toLowerCase()
                                              .includes(
                                                state.inputValue.toLowerCase()
                                              )
                                          )
                                            newOptions.push(option);
                                        });
                                        return newOptions;
                                      }}
                                      isOptionEqualToValue={(option, value) => {
                                        // if (!value.id) return true;
                                        return value.id === option.id;
                                      }}
                                      style={{
                                        width: "30%",
                                        marginLeft: "5px",
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Material"
                                          multiline
                                          InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                          }}
                                          variant="standard"
                                        />
                                      )}
                                      onChange={(_, value) => {
                                        onChange(value);
                                        onSubMatChange(
                                          value,
                                          index,
                                          subMaterialIndex
                                        );
                                        return value;
                                      }}
                                    />
                                  </>
                                )}
                              />
                              <Controller
                                name={`materiais[${index}][subMateriais][${subMaterialIndex}][estadosDropdown]`}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    <Autocomplete
                                      fullWidth
                                      clearText="Limpar"
                                      openText="Abrir"
                                      closeText="Fechar"
                                      noOptionsText="Sem dados"
                                      className="unidadesId-form"
                                      options={estadosMaterial || []}
                                      value={value || null}
                                      getOptionLabel={(option) =>
                                        `${option.descricao}` || ""
                                      }
                                      isOptionEqualToValue={(option, value) => {
                                        // if (!value.id) return true;
                                        return value.id === option.id;
                                      }}
                                      filterOptions={(options, state) => {
                                        let newOptions = [];
                                        options.forEach((option) => {
                                          if (
                                            option.id > 0 &&
                                            option.descricao
                                              .toLowerCase()
                                              .includes(
                                                state.inputValue.toLowerCase()
                                              )
                                          )
                                            newOptions.push(option);
                                        });
                                        return newOptions;
                                      }}
                                      style={{
                                        width: "15%",
                                        marginLeft: "25px",
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Estado"
                                          InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                          }}
                                          variant="standard"
                                        />
                                      )}
                                      onChange={(_, value) => {
                                        onChange(value);
                                        onSubStateChange(
                                          value,
                                          index,
                                          subMaterialIndex
                                        );
                                        return value;
                                      }}
                                    />
                                  </>
                                )}
                              />
                              <Controller
                                name={`materiais[${index}][subMateriais][${subMaterialIndex}][qtd]`}
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <TextField
                                      placeholder="Qtd"
                                      color="primary"
                                      {...field}
                                      style={{
                                        width: "5%",
                                        marginLeft: "10px",
                                      }}
                                      variant="standard"
                                      disabled={
                                        !getValues(
                                          `materiais[${index}].subMateriais[${subMaterialIndex}].subMateriaisDropdown.id`
                                        )
                                      }
                                      InputProps={{
                                        disableUnderline: true,
                                        inputProps: { min: 0 },
                                      }}
                                      type="number"
                                      onChange={(e) => {
                                        field.onChange(e.target.value);
                                        onSubQtdChange(
                                          e.target.value,
                                          index,
                                          subMaterialIndex
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              />

                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={ptLocale}
                              >
                                <Controller
                                  name={`materiais[${index}][subMateriais][${subMaterialIndex}][dataInicio]`}
                                  control={control}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error, invalid },
                                  }) => (
                                    <>
                                      <DatePicker
                                        invalidDateMessage="Data Inválida"
                                        inputFormat="dd/MM/yyyy"
                                        value={value}
                                        components={{
                                          OpenPickerIcon: calendarIcon,
                                        }}
                                        onChange={(
                                          valueChange,
                                          keyboardInputValue
                                        ) => {
                                          onChange(valueChange);
                                        }}
                                        renderInput={({
                                          error: inputError,
                                          ...params
                                        }) => (
                                          <TextField
                                            {...params}
                                            inputProps={{
                                              ...params.inputProps,
                                              placeholder: "dd/mm/aaaa",
                                            }}
                                            InputProps={{
                                              ...params.InputProps,
                                              disableUnderline: true,
                                            }}
                                            variant="standard"
                                            size="small"
                                            sx={{
                                              width: "8.75%",
                                              marginLeft: "10px",
                                              marginTop: "2px",
                                            }}
                                            error={
                                              !!errors?.materiais?.[index]
                                                ?.subMateriais?.[
                                                subMaterialIndex
                                              ]?.dataInicio
                                            }
                                            helperText={
                                              errors?.materiais?.[index]
                                                ?.subMateriais?.[
                                                subMaterialIndex
                                              ]?.dataInicio
                                                ? errors?.materiais[index]
                                                    ?.subMateriais?.[
                                                    subMaterialIndex
                                                  ]?.dataInicio?.message
                                                : ""
                                            }
                                          />
                                        )}
                                      />
                                    </>
                                  )}
                                />
                              </LocalizationProvider>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={ptLocale}
                              >
                                <Controller
                                  name={`materiais[${index}][subMateriais][${subMaterialIndex}][dataFim]`}
                                  control={control}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error, invalid },
                                  }) => (
                                    <>
                                      <DatePicker
                                        inputFormat="dd/MM/yyyy"
                                        invalidDateMessage="Data Inválida"
                                        value={value}
                                        components={{
                                          OpenPickerIcon: calendarIcon,
                                        }}
                                        onChange={(value) => {
                                          onChange(value);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            // error={invalid}
                                            inputProps={{
                                              ...params.inputProps,
                                              placeholder: "dd/mm/aaaa",
                                            }}
                                            InputProps={{
                                              ...params.InputProps,
                                              disableUnderline: true,
                                            }}
                                            variant="standard"
                                            size="small"
                                            sx={{
                                              width: "8.75%",
                                              marginLeft: "20px",
                                              marginTop: "2px",
                                            }}
                                            error={
                                              !!errors?.materiais?.[index]
                                                ?.subMateriais?.[
                                                subMaterialIndex
                                              ]?.dataFim
                                            }
                                            helperText={
                                              errors?.materiais?.[index]
                                                ?.subMateriais?.[
                                                subMaterialIndex
                                              ]?.dataFim
                                                ? errors?.materiais[index]
                                                    ?.subMateriais?.[
                                                    subMaterialIndex
                                                  ]?.dataFim?.message
                                                : ""
                                            }
                                          />
                                        )}
                                      />
                                    </>
                                  )}
                                />
                              </LocalizationProvider>
                              {/* {getValues(
                                `materiais[${index}].subMateriais[${subMaterialIndex}].estadosDropdown.id`
                              ) == 7 ? (
                                <Controller
                                  name={`materiais[${index}][subMateriais][${subMaterialIndex}][motivosDropdown]`}
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <>
                                      <Autocomplete
                                        fullWidth
                                        clearText="Limpar"
                                        openText="Abrir"
                                        closeText="Fechar"
                                        noOptionsText="Sem dados"
                                        className="unidadesId-form"
                                        options={estadosMaterial || []}
                                        value={value || null}
                                        getOptionLabel={(option) =>
                                          `${option.descricao}` || ""
                                        }
                                        isOptionEqualToValue={(
                                          option,
                                          value
                                        ) => {
                                          // if (!value.id) return true;
                                          return value.id === option.id;
                                        }}
                                        filterOptions={(options, state) => {
                                          let newOptions = [];
                                          options.forEach((option) => {
                                            if (
                                              option.id > 0 &&
                                              option.descricao
                                                .toLowerCase()
                                                .includes(
                                                  state.inputValue.toLowerCase()
                                                )
                                            )
                                              newOptions.push(option);
                                          });
                                          return newOptions;
                                        }}
                                        style={{
                                          width: "25%",
                                          marginLeft: "15px",
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="Motivo"
                                            InputProps={{
                                              ...params.InputProps,
                                              disableUnderline: true,
                                            }}
                                            variant="standard"
                                          />
                                        )}
                                        onChange={(_, value) => {
                                          onChange(value);
                                          onSubStateChange(
                                            value,
                                            index,
                                            subMaterialIndex
                                          );
                                          return value;
                                        }}
                                      />
                                    </>
                                  )}
                                />
                              ) : ( */}
                                <Controller
                                  name={`materiais[${index}][subMateriais][${subMaterialIndex}][observacoes]`}
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <TextField
                                        placeholder="Observações"
                                        multiline
                                        color="primary"
                                        {...field}
                                        style={{
                                          width: "25%",
                                          marginLeft: "15px",
                                        }}
                                        variant="standard"
                                        InputProps={{ disableUnderline: true }}
                                      />
                                    </>
                                  )}
                                />
                              {/* )} */}
                              <div
                                style={{
                                  width: "5%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {renderSwitch(
                                  getValues(
                                    `materiais[${index}].subMateriais[${subMaterialIndex}].estadodt`
                                  )
                                )}
                              </div>
                              <div
                                style={{
                                  width: "5%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {renderSwitch(
                                  getValues(
                                    `materiais[${index}].subMateriais[${subMaterialIndex}].estadodc`
                                  )
                                )}
                              </div>
                            </div>
                          </>
                        )
                      )}
                  </div>
                </form>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default Materiais;
