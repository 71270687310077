/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import {
  Typography,
  Box,
  Card,
  IconButton,
  Fade,
  TextField,
} from "@mui/material";
import fileDownload from "js-file-download";
import Instance from "../../../../utils/Axios";
import BASE_URL from "../../../../utils/BASE_URL";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

// Components
import ModalDelete from "./components/modalDelete";
import SideDrawer from "./components/drawer";

//Icons
import FeatherIcon from "feather-icons-react";
import pdfIcon from "../../../../assets/images/pdf.png";

//Actions
import GetDocumentosOficiais from "../../../../utils/redux/actions/energias/documentosOficiais/GetDocumentosOficiais";

// ############################################################## //
// #####################  Window Dimensions  #################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const DocumentosOficiais = () => {
  const dispatch = useDispatch();
  const [documento, setDocumento] = useState({
    descricao: "",
    versao: "",
  });
  const dimensions = useWindowDimensions();

  const permissoes = useSelector((state) => state.user.permissoes);

  // ############################################################## //
  // ###########################  Drawer  ######################### //
  // ############################################################## //

  const [drawerWidth, setDrawerWidth] = useState("25vw");
  const [state, setState] = useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => {
    if (open == false) {
      setState({ ...state, [anchor]: open });
    }
    if (open == true) {
      setState({ ...state, [anchor]: open });
    }
  };

  // ############################################################## //
  // #########################  Card Width  ####################### //
  // ############################################################## //

  let cardWidth = "23.5%";
  let spacing = "1.5%";
  if (dimensions.width <= 1500 && dimensions.width > 1150) {
    cardWidth = "31.5%";
  } else if (dimensions.width <= 1150 && dimensions.width > 800) {
    cardWidth = "48.5%";
  } else if (dimensions.width <= 800) {
    cardWidth = "100%";
    spacing = "0%";
  }

  // ############################################################## //
  // ##########################  Search  ########################## //
  // ############################################################## //

  const [filter, setFilter] = useState("");
  const [checked, setChecked] = useState(false);

  const handleChangeChecked = () => {
    setChecked((prev) => !prev);
  };

  const handleChange = (e) => {
    e.preventDefault();

    setFilter(e.target.value);
  };

  const checkFilter = (documento) => {
    if (Object.keys(documento).length > 0) {
      return documento.descricao.toLowerCase().includes(filter.toLowerCase());
    }
    return null;
  };

  // ############################################################## //
  // #######################  Modal Delete  ####################### //
  // ############################################################## //

  const [openDelete, setOpenDelete] = useState(false);
  const [documentoId, setDocumentoId] = useState();
  const [loading, setLoading] = useState(false);

  const handleOpen = (e, id) => {
    e.preventDefault();

    setLoading(false);

    setOpenDelete(true);
    setDocumentoId(id);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpenDelete(false);
  };

  const fetchDocumentosOficiais = () => {
    dispatch(GetDocumentosOficiais());
  };

  const documentosOficiais = useSelector(
    (state) => state.documentosOficiais.documentosOficiais
  );

  useEffect(() => fetchDocumentosOficiais(), []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          mx: 5,
          mt: 3,
        }}
      >
        <SideDrawer
          state={state}
          toggleDrawer={toggleDrawer}
          documentoId={documentoId}
          drawerWidth={drawerWidth}
          dimensions={dimensions}
          documento={documento}
          setDocumento={setDocumento}
          fetchDocumentosOficiais={fetchDocumentosOficiais}
        />
        <ModalDelete
          openDelete={openDelete}
          handleClose={handleClose}
          documentoId={documentoId}
          fetchDocumentosOficiais={fetchDocumentosOficiais}
          // show={show}
          // Loading
          loading={loading}
          setLoading={setLoading}
        />
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <h4
                style={{
                  fontSize: "22px",
                  width: "100%",
                }}
                className="text-underline"
              >
                Documentos Oficiais
              </h4>
            </Box>
            <Box sx={{ display: "flex" }}>
              {checked == true ? (
                <Box sx={{ mt: -1 }}>
                  <Fade in={checked}>
                    <TextField
                      onChange={handleChange}
                      label="Pesquisar"
                      variant="standard"
                    />
                  </Fade>
                </Box>
              ) : null}
              <IconButton onClick={handleChangeChecked}>
                <FeatherIcon
                  icon="search"
                  color="#000"
                  size={30}
                  strokeWidth={1.5}
                  checked={checked}
                />
              </IconButton>
              {permissoes.includes("APP_ENERGIAS_DOCS_ADICIONAR") ? (
                <IconButton onClick={() => toggleDrawer("right", true)}>
                  <FeatherIcon
                    icon="plus-square"
                    color="#000"
                    size={30}
                    strokeWidth={1.5}
                  />
                </IconButton>
              ) : null}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {documentosOficiais.map(
              (documento) =>
                checkFilter(documento) && (
                  <Card
                    sx={{
                      mb: 5,
                      mr: spacing,
                      boxShadow: "0px 5px 15px lightgrey",
                      width: cardWidth,
                      display: "flex",
                      borderRadius: 2,
                      p: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: dimensions.width <= 500 ? "25%" : "15%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        let ficheiro = null;
                        Instance()
                          .get(
                            `${BASE_URL}/energias/docsOficiais/${documento.id}`
                          )
                          .then((file) => {
                            console.log(file);
                            ficheiro = file.data.yData[0];

                            return Instance().get(
                              `${BASE_URL}/energias/docsOficiais/${documento.id}/download`,
                              { responseType: "blob" }
                            );
                          })
                          .then(({ data }) => {
                            console.log(data, ficheiro);
                            fileDownload(data, ficheiro.ficheiro_nome);
                          })
                          .catch((err) => {
                            console.log(err.response);
                            if (err.response) {
                              if (err.response === 403) {
                                // dispatch(ShowNotification(errorMessage));
                              }
                            }
                          });
                      }}
                    >
                      <img src={pdfIcon} alt="pdf" width={50} />
                      {/* <svg data-testid="pdfIcon"></svg> */}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: dimensions.width <= 500 ? "65%" : "75%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "17px",
                          fontWeight: "bold",
                        }}
                      >
                        {documento.descricao}
                      </Typography>
                      <Typography sx={{ fontSize: "12px", mt: 2 }}>
                        Versão {documento.versao}
                      </Typography>
                    </Box>
                    {permissoes.includes("APP_ENERGIAS_DOCS_REMOVER") ? (
                      <Box sx={{ width: "10%" }}>
                        <IconButton
                          sx={{ mt: -2 }}
                          onClick={(e) => {
                            handleOpen(e, documento.id);
                          }}
                        >
                          <FeatherIcon
                            icon="trash-2"
                            color="#000"
                            size={20}
                            strokeWidth={1.5}
                          />
                        </IconButton>
                      </Box>
                    ) : null}
                  </Card>
                )
            )}
          </Box>
          {/* Footer */}
          {/* <div style={{ margin: "20px 40px" }}>
            <FooterLinks />
          </div> */}
        </Box>
      </Box>
    </>
  );
};

export default DocumentosOficiais;
