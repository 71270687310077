import React from "react";

// import clsx from 'clsx';
import { Drawer } from "@mui/material";
//Components
import FormEditarWorkPermit from "./formEditarWorkPermit";
import FormWorkPermit from "./formWorkPermit";

const DrawerWorkPermit = ({
  state,
  drawerState,
  toggleDrawer,
  classes,
  workPermit,
  setWorkPermit,
  fetchWorkPermit,
  resetFieldsToNull,
}) => {

  return (
    <Drawer
      anchor={"right"}
      open={state["right"]}
      onClose={() => {
        toggleDrawer("right", false);
        resetFieldsToNull();
      }}
      classes={{ paper: classes.paper }}
    >
      {drawerState === "edit" ? (
        <FormEditarWorkPermit
          toggleDrawer={toggleDrawer}
          classes={classes}
          workPermit={workPermit}
          setWorkPermit={setWorkPermit}
          fetchWorkPermit={fetchWorkPermit}
          resetFieldsToNull={resetFieldsToNull}
        />
      ) : (
        <FormWorkPermit
          toggleDrawer={toggleDrawer}
          classes={classes}
          workPermit={workPermit}
          setWorkPermit={setWorkPermit}
          fetchWorkPermit={fetchWorkPermit}
          resetFieldsToNull={resetFieldsToNull}
        />
      )}
    </Drawer>
  );
};

export default DrawerWorkPermit;
