/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
// PACKAGES
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  CircularProgress,
  Box,
  TextField,
  Autocomplete,
} from "@mui/material";
import { grey } from "@mui/material/colors";

// ACTIONS
import AddCredIntervencaoElemento from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/AddCredIntervencaoElementoAction";
import { useState } from "react";
import ShowNotification from "../../../../../utils/redux/actions/global/notifications/NotificationAction";
import GetCredFuncionarios from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionariosAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: 2,
  boxShadow: 3,
  p: 3,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: "600px",
    width: "600px",
    margin: 15,
  },
  btnConfirmar: {
    color: "white",
    backgroundColor: "#E21450",
    width: "100px",
    height: "40px",
    fontWeight: "bold",
  },
  btnConfirmarLoading: {
    width: "48%",
    height: "50px",
    backgroundColor: grey[300],
  },
  btnCancelar: {
    color: "#E21450",
    width: "100px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  modalBtns: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    marginTop: "20px",
    justifyContent: "space-evenly",
  },
}));

const ModalAdicionar = ({
  openAdd,
  handleClose,
  intervencaoId,
  funcionarioId,
  fetchCredIntervencoesElementos,
  // Loading
  loading,
  setLoading,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [elemento, setElemento] = useState();
  const elementos = useSelector((state) => state.credenciais.funcionarios);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!",
      type: "error",
    };
    const message = {
      isOpen: true,
      message: "Elemento adicionado com sucesso!!",
      type: "success",
    };
    dispatch(
      AddCredIntervencaoElemento(
        intervencaoId,
        elemento.id,
        ShowNotification,
        message,
        errorMessage
      )
    )
      .then(() => handleClose(e, "add"))
      .then(() => fetchCredIntervencoesElementos(intervencaoId));
  };

  const fetchElementos = () => {
    dispatch(GetCredFuncionarios(null, 0));
  };

  useEffect(() => {
    fetchElementos();
  }, []);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdd}
        onClose={(e) => handleClose(e, "add")}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAdd}>
          <Box sx={style}>
            <Box className="header-modal">
              <h3
                id="transition-modal-title"
                style={{ fontSize: "20px", textAlign: "center" }}
              >
                Adicionar Elemento
              </h3>
              <p
                id="transition-modal-description"
                style={{ textAlign: "center" }}
              >
                Que elemento pretende adicionar ao grupo de trabalho desta
                intervenção ?
              </p>
            </Box>
            <Box sx={{ mx: 2 }}>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                className="unidadesId-form"
                options={elementos || []}
                getOptionLabel={(option) => `${option.nome}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Elemento"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    size="small"
                  />
                )}
                onChange={(_, value) => {
                  setElemento(value);
                  return value;
                }}
              />
            </Box>

            <div className={classes.modalBtns}>
              <Button
                className={
                  loading ? classes.btnConfirmarLoading : classes.btnConfirmar
                }
                disabled={!!loading}
                variant="contained"
                onClick={handleSubmit}
              >
                {loading && (
                  <CircularProgress
                    size={14}
                    style={{ color: "#9d9d9d", marginRight: "10px" }}
                  />
                )}
                Confirmar
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalAdicionar;
