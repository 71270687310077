/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const GraficoEstadoIntervencoesDepartamento = ({
  estadosIntervencoesPorDepartamento,
  dimensions,
}) => {
  const departamentos =
    estadosIntervencoesPorDepartamento &&
    estadosIntervencoesPorDepartamento.options &&
    estadosIntervencoesPorDepartamento.options.xaxis &&
    estadosIntervencoesPorDepartamento.options.xaxis.categories;

  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        width: '100%',
        type: 'bar',
        stacked: true,
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: 'easein',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      responsive: [
        {
          breakpoint: 950,
          options: {
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        text:
          dimensions < 620
            ? ['Estado das Intervenções ', ' por Departamento']
            : 'Estado das Intervenções por Departamento',
        // text: 'Estado das Intervenções por Departamento',
        align: 'center',
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
        },
      },
      yaxis: {
        show: false,
      },
      grid: {
        show: true,
      },
      legend: {
        position: 'right',
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
      colors: ['#ED5564', '#F6BB43', '#4FC0E8'],
    },
  });

  useEffect(() => {
    estadosIntervencoesPorDepartamento &&
      setState(estadosIntervencoesPorDepartamento);
  }, [departamentos]);

  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type='bar'
        // width='100%'
        height={300}
        width={dimensions < 1560 ? undefined : 800}
      />
    </>
  );
};

export default GraficoEstadoIntervencoesDepartamento;
