/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  IconButton,
  Paper,
  Tab,
  TextareaAutosize,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ptLocale from "date-fns/locale/pt";
import moment from "moment";
import EditPlusAdesao from "../../../../../utils/redux/actions/plus/EditPlusAdesaoAction";
import ShowNotification from "../../../../../utils/redux/actions/global/notifications/NotificationAction";
import EmitirContratoById from "../../../../../utils/redux/actions/plus/EmitirContratoByIdAction";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import GetAdesaoById from "../../../../../utils/redux/actions/plus/GetAdesaoById";
import EditClientById from "../../../../../utils/redux/actions/plus/EditClientByIdAction";
import EditPartnerById from "../../../../../utils/redux/actions/plus/EditPartnerByIdAction";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "auto",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnUpload: {
    color: "#E21450",
    // width: '125px',
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  blurredText: {
    color: "transparent", // Make the text transparent
    textShadow: "0 0 10px #000", // Apply a text shadow to create the blur effect
    userSelect: "none", // Prevent text selection
  },
  disabledTextField: {
    opacity: 0.7,
    pointerEvents: "none",
    userSelect: "none", // Adjust opacity to indicate the disabled state
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "transparent",
    },
  },
  textField: {
    userSelect: "none",
  },
  tab: {
    fontWeight: "bold",
    fontSize: "22px",
    marginBottom: "0px",
    textTransform: "capitalize",
  },
});

const FormParceiro = ({
  state,
  toggleDrawer,
  drawerState,
  drawerWidth,
  dimensions,
  fetchParceiros,
  parceiro,
  setParceiro,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [clienteData, setClienteData] = useState();
  const [clienteLastUpdateData, setClienteLastUpdateData] = useState();
  const [clienteValiDoc, setClienteValiDoc] = useState();

  const user = useSelector((state) => state.user);

  const documentos = useSelector((state) => state.plus.adesaoFiles);

  // ############################################################## //
  // ####################  Parceiro Selecionado  ################### //
  // ############################################################## //

  const parceiroSelecionado = useSelector(
    (state) => state && state.plus && state.plus.parceiro
  );

  const [personalInfo, setPersonalInfo] = useState({
    nome: "",
    contacto: "",
    email: "",
    nif: "",
  });
  const [addressInfo, setAddressInfo] = useState({
    morada: "",
    cod_postal: "",
    localidade: "",
    concelho: "",
    distrito: "",
  });

  const [companyInfo, setCompanyInfo] = useState({
    certidao_permanente: "",
    cae: "",
    rep_legal: "",
  });

  useEffect(() => {
    if (parceiroSelecionado) {
      setPersonalInfo({
        nome: parceiroSelecionado.jaf_plus_partner_name,
        contacto: parceiroSelecionado.jaf_plus_partner_contact,
        nif: parceiroSelecionado.jaf_plus_partner_nif,
        email: parceiroSelecionado.jaf_plus_partner_email,
      });
      setAddressInfo({
        morada: parceiroSelecionado.jaf_plus_partner_address,
        cod_postal: parceiroSelecionado.jaf_plus_partner_postal_code,
        localidade: parceiroSelecionado.jaf_plus_partner_location,
        concelho: parceiroSelecionado.jaf_plus_partner_county,
        distrito: parceiroSelecionado.jaf_plus_partner_district,
      });
      setCompanyInfo({
        certidao_permanente:
          parceiroSelecionado.jaf_plus_partner_p_certificate_code,
        cae: parceiroSelecionado.jaf_plus_partner_cae,
        rep_legal: parceiroSelecionado.jaf_plus_partner_legal_name,
      });
      setClienteData(
        moment(parceiroSelecionado.jaf_plus_partner_created_at).format(
          "DD-MM-YYYY HH:mm:ss"
        )
      );
      setClienteLastUpdateData(
        moment(
          parceiroSelecionado.jaf_plus_partner_updated_at
            ? parceiroSelecionado.jaf_plus_partner_updated_at
            : parceiroSelecionado.jaf_plus_partner_created_at
        ).format("DD-MM-YYYY HH:mm:ss")
      );
    }
  }, [parceiroSelecionado]);

  // ############################################################## //
  // ######################  Filtered arrays ###################### //
  // ############################################################## //

  // const filteredTarifas = tiposTarifas.filter((t) => t.plan == tarifPlan.id);
  // const filteredPotencias = potencias.filter((p) => p.type == tarifa.id);
  // const filteredCiclos = ciclos.filter((c) => c.type == tarifPlan.id);
  // const filteredEscaloes = escaloes.filter((e) => e.plan == tarifPlan.id);
  // const filteredStatus = status.filter(
  //   (s) => s.servId == adesaoSelecionada.id_jaf_plus_service_type
  // );

  // ############################################################## //
  // ###############  Handles Change and Submit  ################## //
  // ############################################################## //

  const handleClienteData = (date) => {
    setClienteData(date);
  };

  const handleClienteValiDoc = (date) => {
    setClienteValiDoc(date);
  };

  const handlePersonalInfoChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleCompanyInfoChange = (e) => {
    const { name, value } = e.target;
    setCompanyInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddressInfoChange = (e) => {
    const { name, value } = e.target;
    setAddressInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        nome: personalInfo.nome,
        contacto: personalInfo.contacto,
        email: personalInfo.email,
        nif: personalInfo.nif,
        morada: addressInfo.morada,
        cod_postal: addressInfo.cod_postal,
        localidade: addressInfo.localidade,
        concelho: addressInfo.concelho,
        distrito: addressInfo.distrito,
        certidao_permanente: companyInfo.certidao_permanente,
        cae: companyInfo.cae,
        rep_legal: companyInfo.rep_legal,
      },
    ];

    console.log(ydata);

    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!",
      type: "error",
    };
    const message = {
      isOpen: true,
      message: "Adesão editada com sucesso!!",
      type: "success",
    };

    dispatch(EditPartnerById(ydata, parceiroSelecionado.id_jaf_plus_partner))
      .then(() => {
        toggleDrawer("right", false);
        dispatch(ShowNotification(message));
        fetchParceiros(); // Ensure fetchAdesoes is called here
      })
      .catch((error) => {
        console.error("Error updating adesao:", error); // Handle error
      });
  };

  return (
    <div
      className={classes.list}
      style={
        dimensions.width <= 1100
          ? { width: "60vw" }
          : { width: `${drawerWidth}` }
      }
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 5,
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => toggleDrawer("right", false)}
        >
          <div>
            <FeatherIcon
              icon="chevron-left"
              size={20}
              style={{ strokeWidth: "1.5" }}
            />
          </div>
          <div className="text-underline-close-drawer">Adesões</div>
        </Box>
        {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box> */}
      </Box>

      <Card
        sx={{
          mx: 5,
          px: 2,
          boxShadow: "0px 10px 15px lightgrey",
        }}
      >
        <Box
          sx={{
            my: 2,
            mx: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4
            style={{
              fontSize: "22px",
              marginBottom: "0px",
            }}
            className="text-underline"
          >
            Cliente
          </h4>
          {user.permissoes.includes("APP_JAFPLUS_EDITAR_CLIENTES") && (
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Button
                variant="contained"
                className={classes.btnFiltrar}
                onClick={handleSubmit}
              >
                Guardar
              </Button>
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              m: 1,
              width: "48%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Data de submissão
              </Typography>
              <TextField
                disabled
                size="small"
                variant="outlined"
                value={clienteData}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
            <Box sx={{ width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Data da última atualização
              </Typography>
              <TextField
                disabled
                size="small"
                variant="outlined"
                value={clienteLastUpdateData}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            mx: 1,
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              marginBottom: "10px",
              fontSize: "20px",
            }}
          >
            Dados Pessoais
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Nome
            </Typography>
            <TextField
              name="nome"
              size="small"
              variant="outlined"
              value={personalInfo.nome}
              onChange={handlePersonalInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Contacto
            </Typography>
            <TextField
              name="contacto"
              size="small"
              variant="outlined"
              value={personalInfo.contacto}
              onChange={handlePersonalInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Email
            </Typography>
            <TextField
              name="email"
              size="small"
              variant="outlined"
              value={personalInfo.email}
              onChange={handlePersonalInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              NIF
            </Typography>
            <TextField
              name="nif"
              size="small"
              variant="outlined"
              value={personalInfo.nif}
              onChange={handlePersonalInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Representante Legal
            </Typography>
            <TextField
              name="rep_legal"
              size="small"
              variant="outlined"
              value={companyInfo.rep_legal}
              onChange={handleCompanyInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              CAE
            </Typography>
            <TextField
              name="cae"
              size="small"
              variant="outlined"
              value={companyInfo.cae}
              onChange={handleCompanyInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Certidão Permanente
            </Typography>
            <TextField
              name="certidao_permanente"
              size="small"
              variant="outlined"
              value={companyInfo.certidao_permanente}
              onChange={handleCompanyInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Morada
            </Typography>
            <TextField
              name="morada"
              size="small"
              variant="outlined"
              value={addressInfo.morada}
              onChange={handleAddressInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Cód-Postal
            </Typography>
            <TextField
              name="cod_postal"
              size="small"
              variant="outlined"
              value={addressInfo.cod_postal}
              onChange={handleAddressInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Localidade
            </Typography>
            <TextField
              name="localidade"
              size="small"
              variant="outlined"
              value={addressInfo.localidade}
              onChange={handleAddressInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Concelho
            </Typography>
            <TextField
              name="concelho"
              size="small"
              variant="outlined"
              value={addressInfo.concelho}
              onChange={handleAddressInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Distrito
            </Typography>
            <TextField
              name="distrito"
              size="small"
              variant="outlined"
              value={addressInfo.distrito}
              onChange={handleAddressInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        {user.permissoes.includes("APP_JAFPLUS_EDITAR_CLIENTES") && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", m: 1 }}>
            <Button
              variant="contained"
              className={classes.btnFiltrar}
              onClick={handleSubmit}
            >
              Guardar
            </Button>
          </Box>
        )}
        {/* <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Fatura Eletricidade
            </Typography>
            <TextField
              name="ynb_adesao_fatura_eleticidade_file"
              size="small"
              variant="outlined"
              value={adesao.ynb_adesao_fatura_eleticidade_file}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Comprovativo IBAN
            </Typography>
            <TextField
              name="ynb_adesao_comprovativo_iban_file"
              size="small"
              variant="outlined"
              value={adesao.ynb_adesao_comprovativo_iban_file}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box> */}
      </Card>
    </div>
  );
};

export default FormParceiro;
