import Instance from "../../../../Axios";

// New Actions for the app

const GetCredAeroportosByFuncId = (funcId) => async (dispatch) => {
  await Instance()
    .get(`/credenciacoes/aeroportosByFuncId/${funcId}`)
    .then(({ data }) => {
      dispatch({
        type: "GET_CRED_AEROPORTOS_BY_FUNC_ID",
        payload: data.yData,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetCredAeroportosByFuncId;
