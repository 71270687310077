import { energiasDashboardInitialState } from "../initialState/energiasDashboard";

const energiasDashboardReducer = (
  state = energiasDashboardInitialState,
  { type, payload }
) => {
  switch (type) {
    case "GET_DASHBOARD_ENERGIAS":
      return {
        ...state,
        energiasDashboard: payload,
      };
    case "GET_VALOR_ORCAMENTADO":
      return {
        ...state,
        valorOrcamentado: payload,
      };
    case "GET_VALOR_ADJUDICADO":
      return {
        ...state,
        valorAdjudicado: payload,
      };
    case "GET_ESTADOS_PROPOSTAS":
      return {
        ...state,
        estadoPropostas: payload,
      };
    case "GET_ESTADOS_PROPOSTAS_PARCEIRO":
      return {
        ...state,
        estadoPropostasParceiro: payload,
      };
    case "GET_ORCAMENTOS_ADJUDICADOS":
      return {
        ...state,
        orcamentosAdjudicados: payload,
      };
    case "GET_ORCAMENTOS_ADJUDICADOS_VALOR":
      return {
        ...state,
        orcamentosAdjudicadosValor: payload,
      };
    case "GET_ORCAMENTOS_TOTAL_VALOR":
      return {
        ...state,
        orcamentosTotalValor: payload,
      };
    case "GET_ORCAMENTOS_PARCEIROS_ALL":
      return {
        ...state,
        taxasucesso: payload,
      };
    case "GET_PEDIDOS_PROPOSTAS_TOTAL":
      return {
        ...state,
        pedidosPropostasTotal: payload,
      };
    case "GET_PEDIDOS_PROPOSTAS_PARCEIROS":
      return {
        ...state,
        pedidosPropostasParceiros: payload,
      };
    case "GET_PROPOSTAS_POR_RESPONDER":
      return {
        ...state,
        propostasPorResponder: payload,
      };
    case "GET_TEMPO_MEDIO_RESPOSTA":
      return {
        ...state,
        tempoMedioResposta: payload,
      };
    case "GET_IDADE_MEDIA":
      return {
        ...state,
        idadeMedia: payload,
      };
    case "GET_PERC_ORCAMENTO_ADJUDICADO":
      return {
        ...state,
        percOrcaAdjudicado: payload,
      };
    case "GET_ORCAMENTOS_PER_MONTH":
      return {
        ...state,
        orcamentosPerMonth: payload,
      };
    case "GET_ALL_PROPOSTAS":
      return {
        ...state,
        propostas: payload,
      };
    case "GET_ALL_PROPOSTAS_ESTADOS":
      return {
        ...state,
        propostasEstados: payload,
      };
    case "GET_ALL_PROPOSTAS_TIMELINE":
      return {
        ...state,
        propostasTimeline: payload,
      };
    case "GET_ALL_ESTADOS_PROJETOS":
      return {
        ...state,
        estadosProjetos: payload,
      };
    case "GET_ALL_ESTADOS_SUB_PROJETOS":
      return {
        ...state,
        estadosSubProjetos: payload,
      };
    case "USER_LOGOUT":
      return {
        ...energiasDashboardInitialState,
      };

    default:
      return state;
  }
};

export default energiasDashboardReducer;
