import { startOfYear } from "date-fns";
import moment from "moment";

export const intervencoesInitialState = {
  intervencoes: [],
  intervencoesMobile: [],
  intervencao: {},
  novaIntervencao: [],
  intervencaoEditada: {},
  intervencaoRemovida: {},
  intervencoesObras: [],
  intervencoesTimeline: [],
  intervencoesAnoSelected: moment().format("YYYY"),
  //FILTERS_PRE_SELECTED
  situacaoPreSelected: [],
  //TIMELINE
  timelineDataInicio: startOfYear(new Date()),
  timelineDataFim: new Date(),
  aeroportosTimeline: [],
  aeroportoSelected: null,
  timelineDepartamentoSelected: null,
  timelineSit: [
    { id: 0, descricao: "Respondida" },
    { id: 1, descricao: "Não Repondida" },
    { id: 2, descricao: "Aguarda Visita" },
  ],
  timelineSitPreSelected: [
    { id: 0, descricao: "Respondida" },
    { id: 1, descricao: "Não Repondida" },
  ],
  //DASHBOARD
  departamentosDashboard: [],
  intervencoesInfoGraficos: [],
  tipoPropostas: [],
  prioridades: [],
};
