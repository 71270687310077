/* eslint-disable no-lone-blocks */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Box,
  TextField,
  Button,
  IconButton,
  useTheme,
  Tooltip,
  Autocomplete,
  Link,
  Breadcrumbs,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import "moment/locale/pt";
import { format } from "date-fns";
import MUIDataTable from "mui-datatables";
import Instance from "../../../../utils/Axios";
import BASE_URL from "../../../../utils/BASE_URL";

// Components
import FooterLinks from "../../../../Global/Components/Footer";
import SideDrawer from "./components/drawer";
import ModalDelete from "./components/modalDelete";

//Icons
import FeatherIcon from "feather-icons-react";

//Actions
import GetAllCredenciacoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetAllCredenciacoesAction";
import GetCredenciacaoById from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredenciacaoByIdAction";
import GetCredFuncionarios from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionariosAction";
import GetCredAeroportos from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredAeroportosAction";
import GetCredFuncoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncoesAction";
import GetCredCores from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredCoresAction";
import GetCredTipoCartoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoCartoesAction";
import GetCredTipoVinculos from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoVinculosAction";
import GetCredEstados from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredEstadosAction";
import GetCredEmpresas from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredEmpresasAction";
import GetCredHabilitacoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredHabilitacoesAction";
import GetCredTipoDocs from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoDocsAction";
import GetCredInqueritoPessoal from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredInqueritoPessoalAction";
import GetCredCopiaCredenciacao from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredCopiaCredenciacaoAction";
import GetCredTipoCredenciacoes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoCredenciacoesAction";
import GetCredAcompanhantes from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredAcompanhantesAction";
import GetCredMotivos from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredMotivosAction";
import GetCredTipoCombustiveis from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoCombustiveisAction";
import GetCredTipoVeiculos from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoVeiculosAction";
import GetCredPortariaAcesso from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredPortariaAcessoAction";
import GetCredViaturaFicheiros from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredViaturaFicheirosAction";
import GetCredViaturaFicheirosPermanente from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredViaturaFicheirosPermanenteAction";
import GetCredViaturaUploadFiles from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredViaturaUploadFilesAction";
import GetCredVeiculos from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredVeiculosAction";
import GetObras from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasAction";
import SendCredPontual from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/SendCredPontualAction";
import ShowNotification from "../../../../utils/redux/actions/global/notifications/NotificationAction";
import GetCredFuncionarioById from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionarioByIdAction";
import GetCredFuncionariosFicheiros from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionariosFicheirosAction";
import GetCredFuncionariosUploadedFiles from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionarioUploadedFilesAction";
import GetCredPedidosAdicionaisDocs from "../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredPedidosAdicionaisDocsAction";
import GetAllWorkPermitAccountables from "../../../../utils/redux/actions/aprovisionamento/obras/GetAllWorkPermitsAccountablesAction";
import GetWorkPermitAccountableById from "../../../../utils/redux/actions/aprovisionamento/obras/GetWorkPermitAccountableByIdAction";
import GetWorkPermitEntities from "../../../../utils/redux/actions/aprovisionamento/obras/GetWorkPermitEntitiesAction";
import GetWorkPermitRespEntities from "../../../../utils/redux/actions/aprovisionamento/obras/GetWorkPermitsRespEntitiesActions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
  },
  btnFiltrar: {
    color: "#E21450",
    width: "150px",
    height: "40px",
    fontWeight: "900",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  stateLabel: {
    textTransform: "none",
    transition: "0.2s",
    display: "flex",
    alignItems: "center",
  },

  paperFilters: {
    background: "transparent",
    padding: "20px 0px",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    height: 40,
    borderTop: "4px solid transparent",
    borderBottom: "4px solid transparent",

    "&:hover": {
      borderRight: "4px solid #E21450",
    },
  },
}));

const ColorStateCube = ({ color }) => {
  return (
    <div
      style={{
        height: "10px",
        width: "10px",
        backgroundColor: color,
        marginRight: "10px",
        borderRadius: "3px",
      }}
    />
  );
};

// ############################################################## //
// ####################  Window Dimensions  ##################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Credenciacoes = () => {
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");
  const [responsavel, setResponsavel] = useState({});

  const resetResponsavel = () => {
    setResponsavel({});
  };

  // ############################################################## //
  // ######################  Autocompletes  ####################### //
  // ############################################################## //

  const responsaveisState = useSelector(
    (state) => state.obras.workPermitsAllAccountables
  );

  const entities = useSelector((state) => state.obras.workPermitsRespEntities);

  const [respId, setRespId] = useState();
  const [entity, setEntity] = useState({ id: null, descricao: "" });
  const [entityFilter, setEntityFilter] = useState({ id: null, descricao: "" });
  const [checkDefault, setCheckDefault] = useState(false);
  const [checkSendEmail, setCheckSendEmail] = useState(false);
  const [checkSendEmailDefault, setCheckSendEmailDefault] = useState(false);
  const [checkCCEmails, setCheckCCEmails] = useState(false);

  const permissoes = useSelector((state) => state.user.permissoes);

  const fetchResponsaveisWorkPermits = (entityId) => {
    dispatch(GetAllWorkPermitAccountables(entityId));
  };

  const fetchEntities = () => {
    dispatch(GetWorkPermitRespEntities());
  };

  useEffect(() => {
    fetchResponsaveisWorkPermits();
    fetchEntities();
  }, []);

  const user = useSelector((state) => state.user);

  // ############################################################## //
  // #########################  Drawer  ########################### //
  // ############################################################## //

  const fetchWorkPermitAccountableById = (id) => {
    dispatch(GetWorkPermitAccountableById(id));
  };

  const [drawerWidth, setDrawerWidth] = useState("90vw");
  const dimensions = useWindowDimensions();
  const [state, setState] = useState({
    right: false,
  });
  const [drawerState, setDrawerState] = useState(null);
  const toggleDrawer = (anchor, open, id) => {
    if (open == false) {
      setState({ ...state, [anchor]: open });
      resetResponsavel();
    }
    if (open == true) {
      setState({ ...state, [anchor]: open });
      setRespId(id);
      fetchWorkPermitAccountableById(id);
    }
  };

  // ############################################################## //
  // #######################  Modal Delete  ####################### //
  // ############################################################## //

  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = (e, id) => {
    e.preventDefault();
    setRespId(id);
    setLoading(false);
    setOpenDelete(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpenDelete(false);
  };

  // ############################################################## //
  // #########################  Messages  ######################### //
  // ############################################################## //

  const messageEmail = {
    isOpen: true,
    message: "Email enviado com sucesso!!",
    type: "success",
  };

  // ############################################################## //
  // ##################  MUI Datatable columns  ################### //
  // ############################################################## //

  const columns = [
    {
      name: "id_jaf_work_permit_accountable_db",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "jaf_work_permit_accountable_db_name",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "jaf_work_permit_accountable_db_email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "jaf_work_permit_accountable_db_contact",
      label: "Contacto",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const phoneNumber = value && value.replace(/\D/g, "");

          // Insert spaces at intervals
          const formattedNumber =
            phoneNumber &&
            phoneNumber.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? " - " : formattedNumber}
            </div>
          );
        },
      },
    },
    {
      name: "jaf_work_permit_entity_name",
      label: "Entidade",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { width: "5%" },
          align: "center",
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const o = tableMeta.rowData[0];

          return (
            <div style={{ display: "flex", float: "right" }}>
              {permissoes.includes("APP_WORK_PERMITS_RESPONSAVEIS_EDITAR") && (
                <IconButton
                  onClick={() => {
                    setDrawerState("edit");
                    toggleDrawer("right", true, o);
                  }}
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                >
                  <FeatherIcon
                    icon="edit"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              )}
              {permissoes.includes("APP_WORK_PERMITS_RESPONSAVEIS_REMOVER") && (
                <IconButton
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                  onClick={(e) => {
                    handleOpen(e, o);
                  }}
                >
                  <FeatherIcon
                    icon="trash-2"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              )}
            </div>
          );
        },
      },
    },
  ];

  // ############################################################## //
  // ##################  MUI Datatable Options  ################### //
  // ############################################################## //

  const searchText =
    location.state && location.state.funcionarioNome
      ? location.state.funcionarioNome
      : null;

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    searchText: searchText,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },

    customToolbar: () => {
      return (
        <>
          {permissoes.includes("APP_WORK_PERMITS_RESPONSAVEIS_ADICIONAR") && (
            <Tooltip title={"Adicionar Responsável"}>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  setDrawerState("add");
                  toggleDrawer("right", true, null);
                }}
              >
                <FeatherIcon
                  icon="plus-square"
                  size={22}
                  className={classes.deleteIcon}
                  style={{ strokeWidth: "2" }}
                />
              </IconButton>
            </Tooltip>
          )}
        </>
      );
    },
  };

  const handleSubmit = () => {
    const entityId = entityFilter.id;

    fetchResponsaveisWorkPermits(entityId);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mr: 10,
          width: "100%",
        }}
      >
        <SideDrawer
          state={state}
          toggleDrawer={toggleDrawer}
          drawerState={drawerState}
          drawerWidth={drawerWidth}
          dimensions={dimensions}
          responsavel={responsavel}
          setResponsavel={setResponsavel}
          resetResponsavel={resetResponsavel}
          entities={entities}
          entity={entity}
          setEntity={setEntity}
          checkDefault={checkDefault}
          setCheckDefault={setCheckDefault}
          checkSendEmail={checkSendEmail}
          setCheckSendEmail={setCheckSendEmail}
          checkSendEmailDefault={checkSendEmailDefault}
          setCheckSendEmailDefault={setCheckSendEmailDefault}
          checkCCEmails={checkCCEmails}
          setCheckCCEmails={setCheckCCEmails}
          fetchResponsaveisWorkPermits={fetchResponsaveisWorkPermits}
          respId={respId}
        />
        <ModalDelete
          openDelete={openDelete}
          handleClose={handleClose}
          respId={respId}
          fetchResponsaveisWorkPermits={fetchResponsaveisWorkPermits}
          loading={loading}
          setLoading={setLoading}
        />
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              mx: 5,
              mt: 5,
              width: "100%",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/construcoes/obras">
                Gestão Aeroportuária
              </Link>
              <Typography color="text.primary">Responsáveis</Typography>
            </Breadcrumbs>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Box sx={{ width: "240px" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Entidade
                </Typography>
                <Autocomplete
                  fullWidth
                  name="entity"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  value={entityFilter}
                  className="unidadesId-form"
                  options={entities || []}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setEntityFilter({ id: null, descricao: "" });
                      return;
                    } else {
                      setEntityFilter(value);
                      return value;
                    }
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "end",
                }}
              >
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  className={classes.btnFiltrar}
                >
                  Filtrar
                </Button>
              </Box>
            </Box>
          </Box>
          <div
            style={{
              margin: "40px 40px 40px 40px",
              width: "100%",
            }}
          >
            <MUIDataTable
              title={
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  className="text-underline"
                >
                  Lista de Responsáveis
                </span>
              }
              data={responsaveisState || []}
              columns={columns}
              options={options}
            />
          </div>
          <div style={{ margin: "20px 40px" }}>
            <FooterLinks />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Credenciacoes;
