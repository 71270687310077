import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { IoIosArrowForward } from 'react-icons/io';
import MenuIcon from '@mui/icons-material/Menu';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
const drawerWidth = 250;
const transitionDuration = 1000; //can also use theme.transitions.duration

const useStyles = makeStyles(() => {
  return {
    menuButton: {
      marginRight: (theme) => theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    appBar: {
      zIndex: (theme) => theme.zIndex.drawer + 1,
    },
    drawer: {
      width: (theme) => theme.drawerWidth,
      '& .MuiBackdrop-root': {
        // display: 'none',
        backgroundColor: 'transparent',
        overflowX: 'hidden',
        border: 'none',
      },
    },
    drawerPaper: {
      width: (theme) => theme.drawerWidth,
      backgroundColor: 'rgba(120, 120, 120, 0.2)',
      elevation: 0,
    },
    content: {
      padding: (theme) => theme.spacing(3),
      transition: (theme) =>
        theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: transitionDuration,
        }),
      minWidth: (theme) => theme.drawerWidth,
      marginLeft: (theme) => 0,
    },
    contentShift: {
      transition: (theme) =>
        theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: transitionDuration,
        }),
      minWidth: (theme) => theme.drawerWidth,
      marginLeft: (theme) => theme.drawerWidth,
    },
  };
});

const styles = (theme) => ({
  drawer: {
    position: 'absolute',
    overflowX: 'hidden',
    zIndex: theme.zIndex.drawer + 2,
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      width: drawerWidth,
      flexShrink: 0,
      zIndex: theme.zIndex.drawer,
    },
    whiteSpace: 'nowrap',
    width: '40px',
    // height: '50px',
    border: 'none',
  },
  drawerOpen: {
    // width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: '40px',
    // height: '50px',
    border: 'none',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1,
    },
    border: 'none',
  },
});

const StyledDrawer = styled(Drawer)`
  ${({ theme, open }) => {
    const classes = styles(theme);
    return {
      ...classes.drawer,
      ...(open ? classes.drawerOpen : classes.drawerClose),
    };
  }}

  .MuiDrawer-paper {
    ${({ theme, open }) => {
      const classes = styles(theme);
      return open ? classes.drawerOpen : classes.drawerClose;
    }}

    &::-webkit-scrollbar {
      width: 2px;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        display: none;
      }
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
    &::-webkit-scrollbar-track {
      display: none;
    }
  }
`;

export default function TemporaryDrawer() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [isOpen, setIsOpen] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const handleDrawerOpen = () => {
    setIsOpen(true);
  };

  // eslint-disable-next-line no-unused-vars
  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const navigateTo = (page) => {
    navigate(`/${page}`);
  };

  const userPermissions = useSelector((state) => state.user.permissoes);

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 260,
        boxShadow: 2,
      }}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List disablePadding>
        {userPermissions && userPermissions.includes('APP_CONSTRUCOES') && (
          <ListItem
            disablePadding
            onClick={() => navigateTo('construcoes/obras')}
          >
            <ListItemButton>
              <ListItemIcon>
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                <IoIosArrowForward />
              </ListItemIcon>
              {/* <ListItemText primary={text} /> */}
              <ListItemText>Gestão Aeroportuária</ListItemText>
            </ListItemButton>
          </ListItem>
        )}
        {userPermissions && userPermissions.includes('APP_INTERVENCOES') && (
          <ListItem
            disablePadding
            onClick={() => navigateTo('intervencoes/dashboard')}
          >
            <ListItemButton>
              <ListItemIcon>
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                <IoIosArrowForward />
              </ListItemIcon>
              {/* <ListItemText primary={text} /> */}
              <ListItemText>Intervenções</ListItemText>
            </ListItemButton>
          </ListItem>
        )}
        {userPermissions && userPermissions.includes('APP_ENERGIAS') && (
          <ListItem
            disablePadding
            onClick={() => navigateTo('energias/dashboard')}
          >
            <ListItemButton>
              <ListItemIcon>
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                <IoIosArrowForward />
              </ListItemIcon>
              {/* <ListItemText primary={text} /> */}
              <ListItemText>Gestão de Obra</ListItemText>
            </ListItemButton>
          </ListItem>
        )}
        {userPermissions && userPermissions.includes('APP_UTILIZADORES') && (
          <ListItem disablePadding onClick={() => navigateTo('utilizadores')}>
            <ListItemButton>
              <ListItemIcon>
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                <IoIosArrowForward />
              </ListItemIcon>
              {/* <ListItemText primary={text} /> */}
              <ListItemText>Utilizadores</ListItemText>
            </ListItemButton>
          </ListItem>
        )}
      </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      {/* {['right'].map((anchor) => ( */}
      {userPermissions && userPermissions.length ? (
        <React.Fragment key={'right'}>
          <Drawer
            sx={{
              '& .MuiPaper-root': {
                width: 300,
                height: '260px',
                marginTop: '130px',
                background: 'transparent',
              },
            }}
            PaperProps={{ elevation: 0 }}
            className={classes.drawer}
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer('right', false)}
            onClick={toggleDrawer('right', false)}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                // background: 'salmon',
              }}
            >
              <div
                style={{
                  height: '40px',
                  background: '#929CA6',
                  color: 'white',
                  width: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '4px 0px 0px 4px',
                  cursor: 'pointer',
                }}
                onClose={toggleDrawer('right', false)}
              >
                <MenuIcon />
              </div>
              <div
                style={{
                  background: 'white',
                }}
              >
                {list('right')}
              </div>
            </div>
          </Drawer>
          {/* <Drawer
          sx={{
            '& .MuiPaper-root': {
              width: 50,
              height: '50px',
              marginTop: '100px',
              background: 'transparent',
              zIndex: -1,
            },
          }}
          PaperProps={{ elevation: 0 }}
          className={classes.drawer}
          anchor={'right'}
          open={true}
          onClose={toggleDrawer('right', false)}
          variant={'permanent'}
        >
          <div
            style={{
              height: '50px',
              background: '#929CA6',
              color: 'white',
              width: '50px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px 0px 0px 4px',
              cursor: 'pointer',
            }}
            onClick={toggleDrawer('right', true)}
          >
            <MenuIcon />
          </div>
        </Drawer> */}
          <ThemeProvider theme={theme}>
            <div>
              <StyledDrawer
                variant='persistent'
                open={isOpen}
                // open={!state['right']}
                anchor={'right'}
                style={{ zIndex: 1200 }}
                sx={{
                  '& .MuiPaper-root': {
                    width: 40,
                    height: '40px',
                    marginTop: '130px',
                    background: 'transparent',
                  },
                }}
                PaperProps={{ height: '40px' }}
              >
                <>
                  <div
                    style={{
                      height: '40px',
                      background: '#929CA6',
                      color: 'white',
                      width: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '4px 0px 0px 4px',
                      cursor: 'pointer',
                    }}
                    onClick={toggleDrawer('right', true)}
                  >
                    <MenuIcon />
                  </div>
                </>
              </StyledDrawer>
              {/* <span>Content</span>
              <button onClick={handleDrawerOpen}>open</button> */}
            </div>
          </ThemeProvider>
        </React.Fragment>
      ) : null}

      {/* )) */}
      {/* } */}
    </div>
  );
}
