import Instance from "../../../../Axios";

// New Actions for the app

const UpdateFabricante = (yData, id) => async (dispatch) => {
  await Instance()
    .put(`/energias/fabricantes/edit/${id}`, { yData })
    .then(({ data }) => {
      //   console.log(data);
      dispatch({ type: "UPDATE_FABRICANTE", payload: data.yData[0] });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default UpdateFabricante;