import Instance from '../../../../Axios';

// New Actions for the app

const GetObrasTipoDocs = () => async (dispatch) => {
  await Instance()
    .get(`/construcoes/obras/all/tipoDocs`)
    .then(({ data }) => {
      dispatch({ type: "GET_OBRAS_TIPO_DOCS", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetObrasTipoDocs;