import Instance from "../../../Axios";

// New Actions for the app

const AddPlusClient = (yData) => async (dispatch) => {
  await Instance()
    .post(`/plus/adesoes/clients/profiles`, { yData })
    .then(({ data }) => {
      dispatch({ type: "ADD_PLUS_CLIENT", payload: data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default AddPlusClient;
