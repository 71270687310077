import Instance from "../../../../Axios";

// New Actions for the app

const AddSetor = (yData) => async (dispatch) => {
  await Instance()
    .post(`/energias/setores`, { yData })
    .then(({ data }) => {
      //   console.log(data);
      dispatch({ type: "ADD_SETOR", payload: data.yData[0] });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default AddSetor;
