import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Paper } from '@mui/material';
import { format } from 'date-fns';

//Icons
import FeatherIcon from 'feather-icons-react';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';

//Actions
import EditIntervencao from '../../../../../utils/redux/actions/intervencoes/EditIntervencaoAction';
import ShowNotification from '../../../../../utils/redux/actions/global/notifications/NotificationAction';

//Components
import CalendarioControlled from './sectionsEditar/CalendarioControlled';
import Esperas from './sectionsEditar/esperas/esperas';
import Controlo from './sectionsEditar/Controlo';
import DetalhesControlled from './sectionsEditar/DetalhesControlled';
import Documentos from './sectionsEditar/Documentos';
import Spinner from '../../../../../Global/Components/Spinner';
import Revisoes from "./sectionsEditar/revisoes/revisoes";

const FormEdit = ({
  intervencaoSelecionada,
  setIntervencaoSelecionada,
  toggleDrawer,
  classes,
  fetchIntervencoes,
  obras,
  // React hook form
  control,
  handleSubmitForm,
  reset,
  getValues,
  setValue,
  watch,

  resetForm,
  setResetForm,
  anoAtual,
  estado,
  subEstado,
  prioridades,
}) => {
  const dispatch = useDispatch();
  const [obraId, setObraId] = useState();
  const [nomePedido, setNomePedido] = useState();
  const [coordenadas, setCoordenadas] = useState();

  const user = useSelector((state) => state.user);

  const [guardarLoading, setGuardarLoading] = useState(false);

  const [files, setFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [removeFiles, setRemoveFiles] = useState([]);

  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [removeImages, setRemoveImages] = useState([]);
  const [prioridade, setPrioridade] = useState({ id: null, descricao: "" });

  useEffect(() => {
    setObraId(intervencaoSelecionada && intervencaoSelecionada.obraId);
    setNomePedido(intervencaoSelecionada && intervencaoSelecionada.pedidoNome);
    setCoordenadas(
      intervencaoSelecionada &&
        intervencaoSelecionada.aero_intervencoes_sig_coordenadas
    );
    setPrioridade({
      id: intervencaoSelecionada && intervencaoSelecionada.prioridadeId,
      descricao:
        intervencaoSelecionada && intervencaoSelecionada.prioridadeDescricao,
    });
  }, [intervencaoSelecionada]);

  console.log(intervencaoSelecionada);

  const handleObraId = (e) => {
    setObraId(e.target.value);
  };

  const handleCoordenadas = (e) => {
    setCoordenadas(e.target.value);
  };

  const handleNomePedido = (e) => {
    setNomePedido(e.target.value);
  };

  const handlePrioridade = (value) => {
    setPrioridade({ id: value.id, descricao: value.descricao });
  };

  const message = {
    isOpen: true,
    message: "Intervenção editada com sucesso!!",
    type: "success",
  };
  const errorMessage = {
    isOpen: true,
    message: "Ocorreu um erro!!",
    type: "error",
  };
  const fileWarning = {
    isOpen: true,
    message: "Ficheiros com o mesmo nome não serão adicionados!",
    type: "warning",
  };

  const onSubmit = (data, e) => {
    const intId = intervencaoSelecionada && intervencaoSelecionada.id;

    const ydata = [
      {
        pedidoNome: nomePedido,
        navNumProposta: obraId,
        aero_intervencoes_sig_coordenadas: coordenadas,
        prioridadeId: prioridade.id,
        esperas: data.esperas.map((elem) => ({
          esperaId: elem.id,
          dataInicio:
            elem.dataInicio == null
              ? elem.dataInicio
              : format(new Date(elem.dataInicio), "yyyy-MM-dd"),
          dataFim:
            elem.dataInicio == null
              ? elem.dataFim
              : format(new Date(elem.dataFim), "yyyy-MM-dd"),
          observacoes: elem.observacoes,
        })),
        revisoes: data.revisoes.map((elem) => ({
          revisaoId: elem.id,
          dataInicio:
            elem.dataInicio == null
              ? elem.dataInicio
              : format(new Date(elem.dataInicio), "yyyy-MM-dd"),
          dataFim:
            elem.dataInicio == null
              ? elem.dataFim
              : format(new Date(elem.dataFim), "yyyy-MM-dd"),
          nRevisao: elem.nRevisao,
        })),
      },
    ];
    setGuardarLoading(true);

    dispatch(
      EditIntervencao(
        ydata,
        //images,
        newImages,
        removeImages,
        //files
        newFiles,
        removeFiles,
        intId,
        toggleDrawer,
        ShowNotification,
        message,
        errorMessage
      )
    )
      .then(() => setGuardarLoading(false))
      .then(() => fetchIntervencoes(anoAtual, estado, subEstado));
  };

  // const filterOptions = createFilterOptions({
  //   // matchFrom: 'start',
  //   // stringify: (option) => option.title,
  //   matchFrom: 'any',
  //   limit: 10,
  // });

  return (
    <>
      {intervencaoSelecionada ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "40px",
            height: "100%",
            overflowX: "hidden",
          }}
        >
          <form onSubmit={handleSubmitForm(onSubmit)}>
            <div className={classes.drawerHeader}>
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => toggleDrawer("right", false)}
              >
                <div>
                  <FeatherIcon
                    icon="chevron-left"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </div>
                <div className="text-underline-close-drawer">
                  Lista de Intervenções
                </div>
              </div>

              {user && user.permissoes.includes("APP_CONSTRUCOES_EDITAR") ? (
                <>
                  {!guardarLoading ? (
                    <Button className={classes.btnGuardar} type="submit">
                      Guardar
                    </Button>
                  ) : (
                    <LoadingButton
                      color="secondary"
                      loading={guardarLoading}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="contained"
                      type="submit"
                      className={classes.btnGuardar}
                    >
                      Guardar
                    </LoadingButton>
                  )}
                </>
              ) : (
                <div style={{ width: "40px" }} />
              )}
            </div>

            <Paper sx={{ px: 6, py: 2, mt: 4 }}>
              <h4
                style={{
                  fontSize: "22px",
                  width: "100%",
                  marginBottom: "0px",
                }}
                className="text-underline"
              >
                Caracterização da Intervenção
              </h4>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: "20px",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <DetalhesControlled
                    intervencaoSelecionada={intervencaoSelecionada}
                    obraId={obraId}
                    handleObraId={handleObraId}
                    nomePedido={nomePedido}
                    handleNomePedido={handleNomePedido}
                    coordenadas={coordenadas}
                    handleCoordenadas={handleCoordenadas}
                  />
                  <Controlo
                    intervencaoSelecionada={intervencaoSelecionada}
                    prioridades={prioridades}
                    prioridade={prioridade}
                    handlePrioridade={handlePrioridade}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <CalendarioControlled
                    intervencaoSelecionada={intervencaoSelecionada}
                  />
                  <Esperas
                    intervencaoSelecionada={intervencaoSelecionada}
                    setIntervencaoSelecionada={setIntervencaoSelecionada}
                    fetchIntervencoes={fetchIntervencoes}
                    control={control}
                    getValues={getValues}
                    resetForm={resetForm}
                    setResetForm={setResetForm}
                    obraId={obraId}
                    setObraId={setObraId}
                    reset={reset}
                    setValue={setValue}
                    watch={watch}
                    //Errors
                    ShowNotification={ShowNotification}
                  />
                  <Revisoes
                    intervencaoSelecionada={intervencaoSelecionada}
                    setIntervencaoSelecionada={setIntervencaoSelecionada}
                    fetchIntervencoes={fetchIntervencoes}
                    control={control}
                    getValues={getValues}
                    resetForm={resetForm}
                    setResetForm={setResetForm}
                    obraId={obraId}
                    setObraId={setObraId}
                    reset={reset}
                    setValue={setValue}
                    watch={watch}
                    //Errors
                    ShowNotification={ShowNotification}
                  />
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <Documentos
                    intervencaoSelecionada={intervencaoSelecionada}
                    classes={classes}
                    //Imagens
                    images={images}
                    setImages={setImages}
                    newImages={newImages}
                    setNewImages={setNewImages}
                    removeImages={removeImages}
                    setRemoveImages={setRemoveImages}
                    //Documentos
                    files={files}
                    setFiles={setFiles}
                    newFiles={newFiles}
                    setNewFiles={setNewFiles}
                    removeFiles={removeFiles}
                    setRemoveFiles={setRemoveFiles}
                    //Errors
                    message={message}
                    errorMessage={errorMessage}
                    fileWarning={fileWarning}
                    ShowNotification={ShowNotification}
                  />
                </div>
              </div>
            </Paper>
          </form>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export default FormEdit;
