import Instance from "../../../Axios";

const GetPlusTipoDonos = () => async (dispatch) => {
  await Instance()
    .get(`/plus/adesoes/data/tipoDonos`)
    .then(({ data }) => {
      dispatch({ type: "GET_PLUS_TIPO_DONOS", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetPlusTipoDonos;
