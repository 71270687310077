import Instance from '../../../Axios';

const GetIntervencao = (id, setIntervencaoSelecionada) => async (dispatch) => {
  await Instance()
    .get(`/intervencoes/intervencoes/${id}`)
    .then(({ data }) => {
      dispatch({ type: 'GET_INTERVENCAO', payload: data.yData[0] });
      setIntervencaoSelecionada(data.yData[0]);
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetIntervencao;
