/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Drawer,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptLocale from "date-fns/locale/pt";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fileDownload from "js-file-download";
import Instance from "../../../../../utils/Axios";
import BASE_URL from "../../../../../utils/BASE_URL";
import moment from "moment";
import MUIDataTable from "mui-datatables";

//Components
import ShowNotification from "../../../../../utils/redux/actions/global/notifications/NotificationAction";
import ModalRascunho from "./modalRascunho";
import FormAddCurso from "./formAddCurso";
import FormEditCurso from "./formEditCurso";

//ACTIONS
import EditCredFuncionario from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/EditCredFuncionarioAction";
import GetCredFuncionariosFicheiros from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionariosFicheirosAction";
import ModalDeleteForm from "./modalDeleteForm";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnUpload: {
    color: "#E21450",
    // width: '125px',
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
});

const FormEdit = ({
  toggleDrawer,
  toggleCursoDrawer,
  drawerCursoState,
  setDrawerCursoState,
  cursoState,
  drawerWidth,
  dimensions,
  funcionarioId,
  funcionarioState,
  funcionario,
  setFuncionario,
  fetchCredFuncionarios,
  empresas,
  aeroportos,
  habilitacoes,
  tipoDocs,
  resources,
  formacao,
  setFormacao,
  formacaoId,
  setFormacaoId,
  estadosFormacoes,
  fetchCredFuncionarioById,
  alocacoes,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");

  // ############################################################## //
  // ######################  Autocompletes  #######3############### //
  // ############################################################## //

  const [vitalicio, setVitalicio] = useState(false);
  const [empresa, setEmpresa] = useState({
    id: "0",
    descricao: "",
  });
  const [alocacao, setAlocacao] = useState([]);
  const [aeroporto, setAeroporto] = useState([
    {
      id: "0",
      descricao: "",
    },
  ]);

  const [habilitacao, setHabilitacao] = useState({
    id: "0",
    descricao: "",
  });
  const [tipoDoc, setTipoDoc] = useState({
    id: "0",
    descricao: "",
  });
  const ficheirosState = useSelector((state) => state.credenciais.ficheiros);
  const uploadedFiles = useSelector((state) => state.credenciais.uploadedFiles);
  const evidencias = uploadedFiles.filter(
    (file) => file.id_crede_elementos_files_tipos == 18
  );
  const evidenciasNomes = evidencias.map((file) => {
    return file.crede_elementos_files_text;
  });

  // ############################################################## //
  // ######################  Dates Changes  ####################### //
  // ############################################################## //

  const [dataNascimento, setDataNascimento] = useState(null);
  const [dataValidade, setDataValidade] = useState(null);
  const [dataEmissao, setDataEmissao] = useState(null);
  const [datePickerValues, setDatePickerValues] = useState({});

  const handleDataNascimentoChange = (date) => {
    setDataNascimento(date);
  };

  const handleDataValidadeChange = (date) => {
    setDataValidade(date);
  };

  const handleDataEmissaoChange = (date) => {
    setDataEmissao(date);
  };

  const handleDatePickerChange = (date, index) => {
    setDatePickerValues((prevValues) => {
      return {
        ...prevValues,
        [index]: date,
      };
    });
  };

  // ############################################################## //
  // #######################  Modal Delete  ####################### //
  // ############################################################## //

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = (e, formId) => {
    e.preventDefault();

    setLoading(false);

    setOpenDelete(true);
    setFormacaoId(formId);
  };

  const handleCloseDelete = (e) => {
    e.preventDefault();
    setOpenDelete(false);
  };

  // ############################################################## //
  // ###################  Verificar Formulário  ################### //
  // ############################################################## //

  const verifyForm = () => {
    // const requiredFilesLoaded = ficheiros.every((ficheiro) => {
    //   if (ficheiro && ficheiro.idTipo !== 1 && ficheiro.idTipo !== 7) {
    //     return ficheiro.file !== null;
    //   }
    //   return true;
    // });
    // Check if the required fields are filled
    const isRequiredFilled =
      !!funcionario.nome &&
      !!funcionario.doc_nr_identificacao &&
      !!funcionario.filiacao_pai &&
      !!funcionario.filiacao_mae &&
      !!empresa.id &&
      !!tipoDoc.id &&
      aeroporto.length !== 0 &&
      alocacao.length !== 0;
    // requiredFilesLoaded; // Add more conditions for other required fields

    // Update the state variable
    return isRequiredFilled;
  };

  // ############################################################## //
  // ########################  Ficheiros  ######################### //
  // ############################################################## //

  const [ficheiros, setFicheiros] = useState([]);

  const handleFilesChange = (e, index, ficheiro) => {
    const file = e.target.files[0];

    setFicheiros((prevFicheiros) => {
      const updatedFicheiros = [...prevFicheiros];
      updatedFicheiros[index] = {
        file,
        idTipo: ficheiro.id_crede_elementos_files_tipos,
        path: ficheiro.crede_elementos_files_tipos_path,
      };

      // Preenche os índices vazios com objetos vazios
      for (let i = 0; i < updatedFicheiros.length; i++) {
        if (updatedFicheiros[i] === undefined) {
          updatedFicheiros[i] = null; // ou { file: null, idTipo: null, path: null } para manter a estrutura
        }
      }

      return updatedFicheiros;
    });
  };

  const handleRemoveFiles = (index) => {
    setFicheiros((prevFicheiros) => {
      const updatedFicheiros = [...prevFicheiros];
      updatedFicheiros[index] = null;

      // Preenche os índices vazios com objetos vazios
      for (let i = 0; i < updatedFicheiros.length; i++) {
        if (updatedFicheiros[i] === undefined) {
          updatedFicheiros[i] = null; // ou { file: null, idTipo: null, path: null } para manter a estrutura
        }
      }

      return updatedFicheiros;
    });
  };

  // ############################################################## //
  // ########################  Evidencias  ######################## //
  // ############################################################## //

  const handleEvidenciasChange = (e, index) => {
    const file = e.target.files[0];

    setFuncionario((prevFuncionario) => {
      const updatedEvidencias = [...prevFuncionario.evidencias];
      updatedEvidencias[index] = {
        file,
        idTipo: 18,
        path: "/evidencia/",
      };
      return { ...prevFuncionario, evidencias: updatedEvidencias };
    });
  };

  const handleRemoveEvidencias = (evidenciaIndex) => {
    setFuncionario((prevFuncionario) => {
      const updatedEvidencias = [...prevFuncionario.evidencias];
      updatedEvidencias[evidenciaIndex] = {};
      return { ...prevFuncionario, evidencias: updatedEvidencias };
    });
  };

  // ############################################################## //
  // ############  Evidencias Curriculo Nome Ficheiros ############ //
  // ############################################################## //

  const handleEvidenciaNomeChange = (value, index) => {
    setFuncionario((prevFuncionario) => {
      const updatedEvidenciasNomes = [...prevFuncionario.evidenciasNomes];
      updatedEvidenciasNomes[index] = value;
      return { ...prevFuncionario, evidenciasNomes: updatedEvidenciasNomes };
    });
  };

  console.log(funcionario.evidenciasNomes);

  // ############################################################## //
  // ###############  Handle Adicionar Evidências  ################ //
  // ############################################################## //

  const handleAddEvidencia = async () => {
    const newEvidencia = {};

    funcionario.evidencias.push(newEvidencia);
    await setFuncionario({ ...funcionario });
  };

  // ############################################################## //
  // ################  Handle Remover Evidência  ################## //
  // ############################################################## //

  const [removeEvList, setRemoveEvList] = useState([]);

  const handleRemoveEvidencia = async (index, idElim) => {
    console.log(idElim);
    if (idElim) {
      setRemoveEvList([...removeEvList, idElim]);
    }
    if (index !== -1) {
      funcionario.evidencias.splice(index, 1);
    }

    await setFuncionario({ ...funcionario });
  };

  // ############################################################## //
  // ###########  Funcionário e Aeroporto Selecionados  ########### //
  // ############################################################## //

  const funcionarioSelecionado = useSelector(
    (state) => state.credenciais.funcionario
  );

  const aeroportoFuncId = useSelector(
    (state) => state.credenciais.aeroportosByFuncId
  );

  useEffect(() => {
    if (funcionarioSelecionado) {
      setFuncionario({
        categoria_profissional:
          funcionarioSelecionado.crede_elementos_categoria_profissional,
        cp: funcionarioSelecionado.crede_elementos_cp,
        data_nascimento: funcionarioSelecionado.crede_elementos_data_nascimento,
        doc_data_emissao:
          funcionarioSelecionado.crede_elementos_doc_data_emissao,
        doc_entidade_emissora:
          funcionarioSelecionado.crede_elementos_doc_entidade_emissora,
        doc_nr_identificacao:
          funcionarioSelecionado.crede_elementos_doc_nr_identificacao,
        doc_nr_identificacao_controlo:
          funcionarioSelecionado.crede_elementos_doc_nr_identificacao_controlo,
        doc_validade: funcionarioSelecionado.crede_elementos_doc_validade,
        doc_vitalicio: funcionarioSelecionado.crede_elementos_doc_vitalicio,
        email: funcionarioSelecionado.crede_elementos_email,
        filiacao_mae: funcionarioSelecionado.crede_elementos_filiacao_mae,
        filiacao_pai: funcionarioSelecionado.crede_elementos_filiacao_pai,
        funcao: funcionarioSelecionado.crede_elementos_funcao,
        local_nascimento:
          funcionarioSelecionado.crede_elementos_local_nascimento,
        localidade: funcionarioSelecionado.crede_elementos_localidade,
        morada: funcionarioSelecionado.crede_elementos_morada,
        nacionalidade: funcionarioSelecionado.crede_elementos_nacionalidade,
        nif: funcionarioSelecionado.crede_elementos_nif,
        nome: funcionarioSelecionado.crede_elementos_nome,
        telefone: funcionarioSelecionado.crede_elementos_telefone,
        telemovel: funcionarioSelecionado.crede_elementos_telemovel,
        email_requisitante:
          funcionarioSelecionado.crede_elementos_email_notificacao,
        evidencias: evidencias,
        evidenciasNomes: evidenciasNomes,
      });
      setDataEmissao(funcionarioSelecionado.crede_elementos_doc_data_emissao);
      setDataNascimento(funcionarioSelecionado.crede_elementos_data_nascimento);
      setDataValidade(funcionarioSelecionado.crede_elementos_doc_validade);
      setVitalicio(
        funcionarioSelecionado.crede_elementos_doc_vitalicio == "0"
          ? false
          : true
      );
      setHabilitacao({
        id: funcionarioSelecionado.id_crede_elementos_habilitacoes_tipos,
        descricao: funcionarioSelecionado.nome_habilitacoes
          ? funcionarioSelecionado.nome_habilitacoes
          : "--",
      });
      setTipoDoc({
        id: funcionarioSelecionado.id_crede_tipo_doc_identificacao,
        descricao: funcionarioSelecionado.nome_tipo_doc_identificacao
          ? funcionarioSelecionado.nome_tipo_doc_identificacao
          : "--",
      });
      setEmpresa({
        id: funcionarioSelecionado.id_crede_fornecedores_servicos,
        descricao: funcionarioSelecionado.nome_fornecedores_servicos
          ? funcionarioSelecionado.nome_fornecedores_servicos
          : "--",
      });
      setAeroporto(aeroportoFuncId);
      setAlocacao(
        funcionarioSelecionado.alocacoes
          ? JSON.parse(funcionarioSelecionado.alocacoes)
          : []
      );
      dispatch(GetCredFuncionariosFicheiros(aeroportoFuncId));
    }
  }, [funcionarioSelecionado, aeroportoFuncId, setFuncionario, setAeroporto]);

  // ############################################################## //
  // ###################  Render Switch Forms  #################### //
  // ############################################################## //

  const renderCursoSwitch = (drawerCursoState) => {
    switch (drawerCursoState) {
      case "addCurso":
        return (
          <FormAddCurso
            toggleCursoDrawer={toggleCursoDrawer}
            dimensions={dimensions}
            funcionario={funcionario}
            setFuncionario={setFuncionario}
            fetchCredFuncionarios={fetchCredFuncionarios}
            formacao={formacao}
            setFormacao={setFormacao}
            funcionarioId={funcionarioId}
            estadosFormacoes={estadosFormacoes}
            fetchCredFuncionarioById={fetchCredFuncionarioById}
          />
        );
      case "editCurso":
        return (
          <FormEditCurso
            toggleCursoDrawer={toggleCursoDrawer}
            dimensions={dimensions}
            funcionario={funcionario}
            setFuncionario={setFuncionario}
            fetchCredFuncionarios={fetchCredFuncionarios}
            formacao={formacao}
            setFormacao={setFormacao}
            formacaoId={formacaoId}
            funcionarioId={funcionarioId}
            estadosFormacoes={estadosFormacoes}
            fetchCredFuncionarioById={fetchCredFuncionarioById}
          />
        );

      default:
        break;
    }
  };

  // ############################################################## //
  // ##################  MUI Datatables columns  ################## //
  // ############################################################## //

  const formacoes = useSelector(
    (state) => state.credenciais.funcionario.formacoes
  );

  const columns = [
    {
      name: "id_crede_elementos_formacoes",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "crede_elementos_formacoes_nome",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
      },
    },
    {
      name: "crede_elementos_formacoes_cod",
      label: "Codigo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "crede_elementos_formacoes_tipo",
      label: "Tipo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "crede_elementos_formacoes_formador",
      label: "Formador",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "crede_elementos_formacoes_estados_nome",
      label: "Estado",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { width: "5%" },
          align: "center",
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const o = tableMeta.rowData[0];

          return (
            <div style={{ display: "flex" }}>
              <IconButton
                onClick={() => {
                  setDrawerCursoState("editCurso");
                  toggleCursoDrawer("right", true, o);
                }}
                style={{
                  padding: 6,
                }}
              >
                <FeatherIcon
                  icon="edit"
                  size={25}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
              <IconButton
                style={{
                  padding: 6,
                }}
                onClick={(e) => {
                  handleOpenDelete(e, o);
                }}
              >
                <FeatherIcon
                  icon="trash-2"
                  size={25}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  // ############################################################## //
  // ##################  MUI Datatables options  ################## //
  // ############################################################## //

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },

    customToolbar: () => {
      return (
        <>
          <Tooltip title={"Adicionar Colaborador"}>
            <IconButton
              className={classes.iconButton}
              onClick={() => {
                setDrawerCursoState("addCurso");
                toggleCursoDrawer("right", true, null);
              }}
            >
              <FeatherIcon
                icon="plus-square"
                size={22}
                className={classes.deleteIcon}
                style={{ strokeWidth: "2" }}
              />
            </IconButton>
          </Tooltip>
        </>
      );
    },
  };

  // ############################################################## //
  // ######################  Modal Rascunho  ###################### //
  // ############################################################## //

  const [openRascunho, setOpenRascunho] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingRascunho, setLoadingRascunho] = useState(false);
  const [dados, setDados] = useState([]);
  const [modalState, setModalState] = useState("");

  const handleOpen = (e, ydata) => {
    e.preventDefault();

    setLoading(false);
    setLoadingRascunho(false);
    setDados(ydata);
    setModalState("edit");
    setOpenRascunho(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpenRascunho(false);
  };

  // ############################################################## //
  // ###############  Handle Change and Submit Form  ############## //
  // ############################################################## //

  const handleChange = (e) => {
    e.preventDefault();
    setFuncionario({
      ...funcionario,
      [e.target.name]: e.target.value,
    });
  };

  console.log(removeEvList);

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        nome: funcionario.nome,
        morada: funcionario.morada,
        cp: funcionario.cp,
        localidade: funcionario.localidade,
        telefone: funcionario.telefone,
        telemovel: funcionario.telemovel,
        email: funcionario.email,
        nif: funcionario.nif,
        data_nascimento: dataNascimento
          ? moment(dataNascimento).format("YYYY-MM-DD")
          : null,
        local_nascimento: funcionario.local_nascimento,
        nacionalidade: funcionario.nacionalidade,
        filiacao_pai: funcionario.filiacao_pai,
        filiacao_mae: funcionario.filiacao_mae,
        categoria_profissional: funcionario.categoria_profissional,
        funcao: funcionario.funcao,
        doc_nr_identificacao: funcionario.doc_nr_identificacao,
        doc_nr_identificacao_controlo:
          funcionario.doc_nr_identificacao_controlo,
        doc_validade: dataValidade
          ? moment(dataValidade).format("YYYY-MM-DD")
          : null,
        doc_vitalicio: vitalicio,
        doc_entidade_emissora: funcionario.doc_entidade_emissora,
        doc_data_emissao: dataEmissao
          ? moment(dataEmissao).format("YYYY-MM-DD")
          : null,
        id_empresa: empresa.id,
        id_tipo_doc: tipoDoc.id,
        id_habilitacao: habilitacao.id,
        email_requisitante: funcionario.email_requisitante,
        rascunho: 0,
      },
    ];

    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!",
      type: "error",
    };
    const message = {
      isOpen: true,
      message: "Colaborador editado com sucesso!!",
      type: "success",
    };

    if (verifyForm()) {
      dispatch(
        EditCredFuncionario(
          ydata,
          aeroporto,
          alocacao,
          funcionarioSelecionado.id_crede_elementos,
          ficheiros,
          funcionario.evidencias,
          funcionario.evidenciasNomes,
          removeEvList,
          datePickerValues,
          toggleDrawer,
          ShowNotification,
          message,
          errorMessage
        )
      ).then(() => {
        setFuncionario({
          nome: "",
          morada: "",
          cp: "",
          localidade: "",
          telefone: "",
          telemovel: "",
          email: "",
          nif: "",
          data_nascimento: "",
          local_nascimento: "",
          nacionalidade: "",
          filiacao_pai: "",
          filiacao_mae: "",
          categoria_profissional: "",
          funcao: "",
          doc_nr_identificacao: "",
          doc_nr_identificacao_controlo: "",
          doc_validade: "",
          doc_vitalicio: "",
          doc_entidade_emissora: "",
          doc_data_emissao: "",
          email_requisitante: "",
          evidencias: [],
          evidenciasNomes: [],
        });
        toggleDrawer("right", false);
        fetchCredFuncionarios();
      });
    } else {
      // dispatch(ShowNotification(warningMessage));
      ydata[0] = { ...ydata[0], rascunho: 1 };
      handleOpen(e, ydata);
    }
  };

  return (
    <div
      className={classes.list}
      style={
        dimensions.width <= 1100
          ? { width: "60vw" }
          : { width: `${drawerWidth}` }
      }
    >
      <ModalRascunho
        openRascunho={openRascunho}
        handleClose={handleClose}
        fetchCredFuncionarios={fetchCredFuncionarios}
        dados={dados}
        loading={loading}
        setLoading={setLoading}
        loadingRascunho={loadingRascunho}
        setLoadingRascunho={setLoadingRascunho}
        aeroporto={aeroporto}
        ficheiros={ficheiros}
        evidencias={funcionario.evidencias}
        evidenciasNomes={funcionario.evidenciasNomes}
        removeEvList={removeEvList}
        datePickerValues={datePickerValues}
        toggleDrawer={toggleDrawer}
        ShowNotification={ShowNotification}
        modalState={modalState}
        idElem={funcionarioSelecionado.id_crede_elementos}
      />
      <ModalDeleteForm
        openDelete={openDelete}
        handleClose={handleCloseDelete}
        formId={formacaoId}
        funcionarioId={funcionarioId}
        fetchCredFuncionarioById={fetchCredFuncionarioById}
        // Loading
        loading={loading}
        setLoading={setLoading}
      />
      <Drawer
        anchor={"right"}
        open={cursoState["right"]}
        onClose={() => toggleCursoDrawer("right", false)}
        classes={{ paper: classes.paper }}
      >
        {renderCursoSwitch(drawerCursoState)}
      </Drawer>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 5,
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => toggleDrawer("right", false)}
        >
          <div>
            <FeatherIcon
              icon="chevron-left"
              size={20}
              style={{ strokeWidth: "1.5" }}
            />
          </div>
          <div className="text-underline-close-drawer">Colaboradores</div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box>
      </Box>
      <Card
        sx={{
          m: 5,
          p: 2,
          boxShadow: "0px 10px 15px lightgrey",
        }}
      >
        <Box
          sx={{
            m: 2,
          }}
        >
          <h4
            style={{
              fontSize: "22px",
              width: "100%",
              marginBottom: "0px",
            }}
            className="text-underline"
          >
            Colaborador
          </h4>
        </Box>
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Detalhes Pessoais
          </h4>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "48%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Nº Mecanográfico
                  </Typography>
                  <Tooltip title="Só se aplica a colaboradores JAF">
                    <IconButton>
                      <FeatherIcon icon="info" size={20} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <TextField
                  name="num_mec"
                  disabled
                  size="small"
                  variant="outlined"
                  value={funcionarioSelecionado.crede_elementos_id_nav}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    NIF
                  </Typography>
                </Box>
                <TextField
                  name="nif"
                  size="small"
                  variant="outlined"
                  value={funcionario.nif}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "48%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Nome Completo *
                </Typography>
                <TextField
                  name="nome"
                  size="small"
                  variant="outlined"
                  value={funcionario.nome}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Local/Aeroporto *
                </Typography>
                <Autocomplete
                  fullWidth
                  multiple
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={aeroportos || []}
                  value={aeroporto || "--"}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, values, reason) => {
                    if (values.length === 0 && reason === "removeOption") {
                      setAeroporto(values);
                      return;
                    }
                    if (reason === "clear") {
                      setAeroporto(values);
                      return;
                    } else {
                      setAeroporto(values);
                      dispatch(GetCredFuncionariosFicheiros(values));
                      return values;
                    }
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Morada **
              </Typography>
              <TextField
                name="morada"
                size="small"
                variant="outlined"
                value={funcionario.morada}
                onChange={handleChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Localidade **
                </Typography>
                <TextField
                  name="localidade"
                  size="small"
                  variant="outlined"
                  value={funcionario.localidade}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Código Postal **
                </Typography>
                <TextField
                  name="cp"
                  size="small"
                  variant="outlined"
                  value={funcionario.cp}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Telefone **
                </Typography>
                <TextField
                  name="telefone"
                  size="small"
                  variant="outlined"
                  value={funcionario.telefone}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Telemóvel **
                </Typography>
                <TextField
                  name="telemovel"
                  size="small"
                  variant="outlined"
                  value={funcionario.telemovel}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Email
              </Typography>
              <TextField
                name="email"
                size="small"
                variant="outlined"
                value={funcionario.email}
                onChange={handleChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "48%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Alocação *
                </Typography>
                <Autocomplete
                  fullWidth
                  multiple
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={alocacoes || []}
                  value={alocacao}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      required
                    />
                  )}
                  onChange={(_, values, reason) => {
                    if (values.length === 0 && reason === "removeOption") {
                      setAlocacao(values);
                    }
                    if (reason === "clear") {
                      setAlocacao(values);
                    } else {
                      setAlocacao(values);
                    }
                    return values;
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  m: 1,
                  width: "46.5%",
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Data de Nascimento **
                  </Typography>
                  <DatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    okLabel="ok"
                    cancelLabel="cancelar"
                    invalidDateMessage="Data Inválida"
                    value={dataNascimento}
                    onChange={handleDataNascimentoChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        name="data_nascimento"
                        sx={{ width: "200px" }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Local de nascimento
                </Typography>
                <TextField
                  name="local_nascimento"
                  size="small"
                  variant="outlined"
                  value={funcionario.local_nascimento}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Nacionalidade **
                </Typography>
                <TextField
                  name="nacionalidade"
                  size="small"
                  variant="outlined"
                  value={funcionario.nacionalidade}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Filiação - Pai *
                </Typography>
                <TextField
                  name="filiacao_pai"
                  size="small"
                  variant="outlined"
                  value={funcionario.filiacao_pai}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Filiação - Mãe *
                </Typography>
                <TextField
                  name="filiacao_mae"
                  size="small"
                  variant="outlined"
                  value={funcionario.filiacao_mae}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Categoria Profissional **
                </Typography>
                <TextField
                  name="categoria_profissional"
                  size="small"
                  variant="outlined"
                  value={funcionario.categoria_profissional}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Função **
                </Typography>
                <TextField
                  name="funcao"
                  size="small"
                  variant="outlined"
                  value={funcionario.funcao}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Entidade Patronal *
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={empresas || []}
                  value={empresa}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setEmpresa({ id: null, descricao: "" });
                      return;
                    } else {
                      setEmpresa(value);
                      return value;
                    }
                  }}
                />
              </Box>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Habilitações **
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={habilitacoes || []}
                  value={habilitacao}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setHabilitacao({ id: null, descricao: "" });
                      return;
                    } else {
                      setHabilitacao(value);
                      return value;
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ m: 2 }}>
          <p>* Campos obrigatórios</p>
          <p>** Campos obrigatórios para credenciações permanentes</p>
        </Box>
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer"
          >
            Documento de Identificação
          </h4>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "48%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Tipo de Documento *
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={tipoDocs || []}
                  value={tipoDoc}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setTipoDoc({ id: null, descricao: "" });
                      return;
                    } else {
                      setTipoDoc(value);
                      return value;
                    }
                  }}
                />
              </Box>
              <Box sx={{ m: 1, width: "46.5%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Nº Doc Identificação *
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <TextField
                    name="doc_nr_identificacao"
                    size="small"
                    variant="outlined"
                    value={funcionario.doc_nr_identificacao}
                    onChange={handleChange}
                    style={{ width: "69%" }}
                    autoComplete="off"
                  />
                  <TextField
                    name="doc_nr_identificacao_controlo"
                    size="small"
                    variant="outlined"
                    value={funcionario.doc_nr_identificacao_controlo}
                    onChange={handleChange}
                    style={{ width: "29%" }}
                    autoComplete="off"
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  m: 1,
                  width: "46.5%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "45%",
                  }}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptLocale}
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Data de Validade
                    </Typography>
                    <DatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      format="dd/MM/yyyy"
                      okLabel="ok"
                      cancelLabel="cancelar"
                      invalidDateMessage="Data Inválida"
                      value={dataValidade}
                      onChange={handleDataValidadeChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "dd/mm/aaaa",
                          }}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "45%",
                  }}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptLocale}
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Data de Emissão
                    </Typography>
                    <DatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      format="dd/MM/yyyy"
                      okLabel="ok"
                      cancelLabel="cancelar"
                      invalidDateMessage="Data Inválida"
                      value={dataEmissao}
                      onChange={handleDataEmissaoChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "dd/mm/aaaa",
                          }}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  m: 1,
                  width: "46.5%",
                }}
              >
                <Box sx={{ display: "flex-end", flexDirection: "column" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Vitalicio
                  </Typography>
                  <Box>
                    <Checkbox
                      checked={vitalicio}
                      onChange={() => setVitalicio(!vitalicio)}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "48%" }}>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Entidade Emissora
              </Typography>
              <TextField
                name="doc_entidade_emissora"
                size="small"
                variant="outlined"
                value={funcionario.doc_entidade_emissora}
                onChange={handleChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
          </Box>
        </Box>
        {aeroporto.length > 0 ? (
          <>
            <Box sx={{ m: 2 }}>
              <h4
                style={{ color: "#E21450" }}
                className="text-underline-title-drawer "
              >
                Documentos
              </h4>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                mx: 1,
                width: "100%",
              }}
            >
              {ficheirosState &&
                ficheirosState.map((ficheiro, index) => {
                  const matchingFile = uploadedFiles.find(
                    (file) =>
                      file.id_crede_elementos_files_tipos ===
                      ficheiro.id_crede_elementos_files_tipos
                  );
                  return (
                    <Box sx={{ m: 1, width: "20%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        {ficheiro.crede_elementos_files_tipos_nome}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        {matchingFile && (
                          <Button
                            sx={{
                              height: "40px",
                              borderRadius: "5px",
                              mr: 1,
                            }}
                            className={classes.btnUpload}
                            onClick={() => {
                              Instance()
                                .get(
                                  `${BASE_URL}/credenciacoes/funcionarios/files/${matchingFile.id_crede_elementos_files}/download`,
                                  { responseType: "blob" }
                                )
                                .then(({ data }) => {
                                  const filename =
                                    matchingFile.crede_elementos_files_path.replace(
                                      /^.*[\\/]/,
                                      ""
                                    );
                                  fileDownload(data, filename);
                                })
                                .catch((err) => {
                                  console.log(err.response);
                                  if (err.response) {
                                    if (err.response === 403) {
                                      // dispatch(ShowNotification(errorMessage));
                                    }
                                  }
                                });
                            }}
                          >
                            <FeatherIcon icon="download" size={20} />
                          </Button>
                        )}
                        <Button
                          sx={{
                            height: "40px",
                            width: "200px",
                            borderRadius: "5px",
                            mr: 2,
                          }}
                          onChange={(e) =>
                            handleFilesChange(e, index, ficheiro)
                          }
                          className={classes.btnUpload}
                        >
                          <label
                            htmlFor={
                              ficheiro.crede_elementos_files_tipos_nome_input
                            }
                            className="custom-file-upload"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                cursor: "pointer",
                              }}
                            >
                              <p> Carregar </p>
                            </div>
                          </label>
                          <input
                            type="file"
                            name={
                              ficheiro.crede_elementos_files_tipos_nome_input
                            }
                            id={ficheiro.crede_elementos_files_tipos_nome_input}
                            hidden
                            accept="image/jpeg, .pdf"
                          />
                        </Button>
                        <div style={{ width: "100%" }}>
                          {matchingFile && !ficheiros[index] && (
                            <div
                              style={{
                                marginRight: "10px",
                                display: "flex",
                                alignItems: "center",
                              }}
                              key={index}
                            >
                              <IconButton
                                onClick={() => {
                                  // Retrieve the base URL from environment variables
                                  let baseUrl = BASE_URL;

                                  // Remove the '/api' part from the base URL
                                  if (baseUrl.endsWith("/api")) {
                                    baseUrl = baseUrl.slice(0, -4); // Remove '/api'
                                  }

                                  // Construct the full file URL
                                  const fileUrl = `${baseUrl}${matchingFile.path}`;

                                  // Open the file in a new tab/window
                                  window.open(fileUrl, "_blank");
                                }}
                              >
                                <FeatherIcon icon="eye" size={"1rem"} />
                              </IconButton>
                            </div>
                          )}
                          {ficheiros[index] && (
                            <div
                              style={{
                                marginRight: "10px",
                                display: "flex",
                                alignItems: "center",
                              }}
                              key={index}
                            >
                              <IconButton
                                onClick={() => {
                                  const fileName = ficheiros[index].file.name;
                                  const fileExtension = fileName.slice(
                                    (Math.max(0, fileName.lastIndexOf(".")) ||
                                      Infinity) + 1
                                  );

                                  if (fileExtension == "pdf") {
                                    const pdfFile = ficheiros[index].file;

                                    // Create a Blob from the PDF file
                                    const blob = new Blob([pdfFile], {
                                      type: "application/pdf",
                                    });

                                    // Create a URL for the Blob
                                    const blobUrl = URL.createObjectURL(blob);

                                    // Now you can use `blobUrl` to open or display the PDF
                                    window.open(blobUrl, "_blank");
                                  } else {
                                    const blobUrl = URL.createObjectURL(
                                      ficheiros[index].file
                                    );

                                    // Now you can use `blobUrl` to open or display the PDF
                                    window.open(blobUrl, "_blank");
                                  }
                                }}
                              >
                                <FeatherIcon icon="eye" size={"1rem"} />
                              </IconButton>
                              <IconButton
                                onClick={(e) => handleRemoveFiles(index)}
                              >
                                <FeatherIcon icon="trash-2" size={"1rem"} />
                              </IconButton>
                            </div>
                          )}
                        </div>
                      </Box>
                      {ficheiro.id_crede_elementos_files_tipos == 2 &&
                        ficheiros[index] && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              mt: 1,
                            }}
                          >
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              locale={ptLocale}
                            >
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  marginBottom: "10px",
                                }}
                              >
                                Data de Validade
                              </Typography>
                              <DatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                format="dd/MM/yyyy"
                                okLabel="ok"
                                value={datePickerValues[index]}
                                onChange={(date) =>
                                  handleDatePickerChange(date, index)
                                }
                                cancelLabel="cancelar"
                                invalidDateMessage="Data Inválida"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: "dd/mm/aaaa",
                                    }}
                                    name="data_nascimento"
                                    sx={{ width: "200px" }}
                                    variant="outlined"
                                    size="small"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Box>
                        )}
                    </Box>
                  );
                })}
            </Box>
          </>
        ) : null}
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Evidências de currículo
          </h4>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mx: 1,
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => handleAddEvidencia()}
              color="inherit"
            >
              <FeatherIcon icon="plus-square" size={20} />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {funcionario &&
              funcionario.evidencias &&
              funcionario.evidencias.map((evidencia, index) => {
                const matchingFile = uploadedFiles.find(
                  (file) =>
                    file.id_crede_elementos_files ===
                    evidencia.id_crede_elementos_files
                );
                return (
                  <Box sx={{ display: "flex", my: 1 }}>
                    <IconButton
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() =>
                        handleRemoveEvidencia(
                          index,
                          evidencia.id_crede_elementos_files
                        )
                      }
                      color="inherit"
                    >
                      <FeatherIcon icon="trash-2" size={20} />
                    </IconButton>
                    {matchingFile && (
                      <Button
                        sx={{
                          height: "40px",
                          borderRadius: "5px",
                          mr: 1,
                        }}
                        className={classes.btnUpload}
                        onClick={() => {
                          Instance()
                            .get(
                              `${BASE_URL}/credenciacoes/funcionarios/files/${matchingFile.id_crede_elementos_files}/download`,
                              { responseType: "blob" }
                            )
                            .then(({ data }) => {
                              const filename =
                                matchingFile.crede_elementos_files_path.replace(
                                  /^.*[\\/]/,
                                  ""
                                );
                              fileDownload(data, filename);
                            })
                            .catch((err) => {
                              console.log(err.response);
                              if (err.response) {
                                if (err.response === 403) {
                                  // dispatch(ShowNotification(errorMessage));
                                }
                              }
                            });
                        }}
                      >
                        <FeatherIcon icon="download" size={20} />
                      </Button>
                    )}
                    {matchingFile ? (
                      <>
                        <IconButton
                          onClick={() => {
                            // Retrieve the base URL from environment variables
                            let baseUrl = BASE_URL;

                            // Remove the '/api' part from the base URL
                            if (baseUrl.endsWith("/api")) {
                              baseUrl = baseUrl.slice(0, -4); // Remove '/api'
                            }

                            // Construct the full file URL
                            const fileUrl = `${baseUrl}${matchingFile.path}`;

                            // Open the file in a new tab/window
                            window.open(fileUrl, "_blank");
                          }}
                        >
                          <FeatherIcon icon="eye" size={"1rem"} />
                        </IconButton>
                        <Typography
                          sx={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          {matchingFile.crede_elementos_files_text}
                        </Typography>
                      </>
                    ) : (
                      <Button
                        sx={{
                          height: "40px",
                          width: "200px",
                          borderRadius: "5px",
                          mr: 2,
                        }}
                        onChange={(e) => handleEvidenciasChange(e, index)}
                        className={classes.btnUpload}
                      >
                        <label
                          htmlFor={`evidencia${index}`}
                          className="custom-file-upload"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              cursor: "pointer",
                            }}
                          >
                            <p> Carregar </p>
                          </div>
                        </label>
                        <input
                          type="file"
                          name={`evidencia${index}`}
                          id={`evidencia${index}`}
                          hidden
                          accept="image/jpeg, .pdf"
                        />
                      </Button>
                    )}
                    <div style={{ width: "100%" }}>
                      {funcionario.evidencias[index].file && (
                        <div
                          style={{
                            marginRight: "10px",
                            display: "flex",
                            alignItems: "bottom",
                          }}
                          key={index}
                        >
                          <Box>
                            <TextField
                              label="Nome Ficheiro"
                              size="small"
                              variant="outlined"
                              onChange={(e) =>
                                handleEvidenciaNomeChange(e.target.value, index)
                              }
                            />
                          </Box>
                          <IconButton
                            onClick={() => {
                              const fileName =
                                funcionario.evidencias[index].file.name;
                              const fileExtension = fileName.slice(
                                (Math.max(0, fileName.lastIndexOf(".")) ||
                                  Infinity) + 1
                              );

                              if (fileExtension == "pdf") {
                                const pdfFile =
                                  funcionario.evidencias[index].file;

                                // Create a Blob from the PDF file
                                const blob = new Blob([pdfFile], {
                                  type: "application/pdf",
                                });

                                // Create a URL for the Blob
                                const blobUrl = URL.createObjectURL(blob);

                                // Now you can use `blobUrl` to open or display the PDF
                                window.open(blobUrl, "_blank");
                              } else {
                                const blobUrl = URL.createObjectURL(
                                  funcionario.evidencias[index].file
                                );

                                // Now you can use `blobUrl` to open or display the PDF
                                window.open(blobUrl, "_blank");
                              }
                            }}
                          >
                            <FeatherIcon icon="eye" size={"1rem"} />
                          </IconButton>
                          <IconButton
                            onClick={(e) => handleRemoveEvidencias(index)}
                          >
                            <FeatherIcon icon="trash-2" size={"1rem"} />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </Box>
                );
              })}
          </Box>
        </Box>
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer"
          >
            Formações
          </h4>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "48%" }}>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Email Responsável Formação
              </Typography>
              <TextField
                name="email_requisitante"
                size="small"
                variant="outlined"
                value={funcionario.email_requisitante}
                onChange={handleChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}>
          <Box
            sx={{
              mx: 1,
              width: "100%",
            }}
          >
            <MUIDataTable
              data={formacoes || []}
              title={
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  className="text-underline"
                >
                  Formações
                </span>
              }
              columns={columns}
              options={options}
            />
          </Box>
        </Box>
      </Card>
    </div>
  );
};

export default FormEdit;
