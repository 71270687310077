import Instance from '../../../../Axios';

// New Actions for the app

const GetObrasSubMateriais = (code) => async (dispatch) => {
  const idCat = code;
  await Instance()
    .get(`/construcoes/obras/all/submateriais`, { params: { idCat } })
    .then(({ data }) => {
      dispatch({ type: "GET_OBRAS_SUB_MATERIAIS", payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetObrasSubMateriais;