/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import {
  Typography,
  Box,
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

//Actions
import GetTodosArtigos from "../../../../utils/redux/actions/energias/blog/getTodosArtigos";

// ############################################################## //
// #####################  Window Dimensions  #################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const useStyles = makeStyles(() => ({
  verMais: {
    color: "#333333",
    "&:hover": {
      color: "#E21450",
    },
  },
}));

const Blog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dimensions = useWindowDimensions();

  // ############################################################## //
  // #########################  Card Width  ####################### //
  // ############################################################## //

  let cardWidth = "23.5%";
  let spacing = "1.5%";
  if (dimensions.width <= 1500 && dimensions.width > 1150) {
    cardWidth = "31.5%";
  } else if (dimensions.width <= 1150 && dimensions.width > 800) {
    cardWidth = "48.5%";
  } else if (dimensions.width <= 800) {
    cardWidth = "100%";
    spacing = "0%";
  }

  // ############################################################## //
  // ##########################  Artigos  ######################### //
  // ############################################################## //

  const artigos = useSelector((state) => state.artigos.artigos);

  const fetchArtigos = () => {
    dispatch(GetTodosArtigos());
  };

  useEffect(() => {
    fetchArtigos();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          mx: 5,
          mt: 3,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <h4
                style={{
                  fontSize: "22px",
                  width: "100%",
                }}
                className="text-underline"
              >
                Blog
              </h4>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {artigos.map((item) => (
              <Card
                sx={{
                  mb: 5,
                  mr: spacing,
                  boxShadow: "0px 5px 15px lightgrey",
                  width: cardWidth,
                  borderRadius: 2,
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={item.yoast_head_json.og_image[0].url}
                />
                <CardContent sx={{ p: 3 }}>
                  <Typography gutterBottom sx={{ fontSize: 12 }}>
                    {moment(item.date).format("DD.MM.YYYY")}
                  </Typography>
                  <Typography
                    gutterBottom
                    sx={{ fontSize: 15, fontWeight: "bold" }}
                    dangerouslySetInnerHTML={{ __html: item.title.rendered }}
                  />
                  <Typography
                    sx={{ fontSize: 15, color: "#707070" }}
                    dangerouslySetInnerHTML={{ __html: item.excerpt.rendered }}
                  />
                </CardContent>
                <CardActions sx={{ p: 3 }}>
                  <Button
                    size="small"
                    className={classes.verMais}
                    onClick={() => navigate(`/energias/blog/${item.id}`)}
                  >
                    Saber mais
                  </Button>
                </CardActions>
              </Card>
            ))}
          </Box>

          {/* Footer */}
          {/* <div style={{ margin: "20px 40px" }}>
              <FooterLinks />
            </div> */}
        </Box>
      </Box>
    </>
  );
};

export default Blog;
