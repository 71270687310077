import Instance from "../../../Axios";

const GetAdesaoById = (id) => async (dispatch) => {
  await Instance()
    .get(`/plus/adesoes/${id}`)
    .then(({ data }) => {
      dispatch({ type: "GET_ADESAO_BY_ID", payload: data.yData[0] });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetAdesaoById;
