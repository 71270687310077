import React from 'react';
import { TextField, Typography } from '@mui/material';

const Controlo = ({ obraSelecionada }) => {
  return (
    <>
      <div style={{ marginLeft: '30px' }}>
        <h4
          style={{ color: '#E21450' }}
          className='text-underline-title-drawer '
        >
          Controlo
        </h4>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '200px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Estados
            </Typography>
            <TextField
              size='small'
              value={
                (obraSelecionada &&
                  obraSelecionada.estadosControloDropdown &&
                  obraSelecionada.estadosControloDropdown.descricao) ||
                ' --/--/---- '
              }
              variant='outlined'
              disabled
              style={{ width: '85%' }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Controlo;
