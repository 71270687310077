/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";

// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ptLocale from "date-fns/locale/pt";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import MUIDataTable from "mui-datatables";
import Instance from "../../../../../utils/Axios";
import BASE_URL from "../../../../../utils/BASE_URL";
import fileDownload from "js-file-download";

//ACTIONS
import EditCredenciacao from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/EditCredenciacaoAction";
import GetCredFuncionarios from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionariosAction";
import ShowNotification from "../../../../../utils/redux/actions/global/notifications/NotificationAction";
import ModalRascunho from "./modalRascunho";
import NotifyCredenciacoes from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/NotifyCredenciacoesActions";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnUpload: {
    color: "#E21450",
    // width: '125px',
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  blurredText: {
    color: "transparent", // Make the text transparent
    textShadow: "0 0 10px #000", // Apply a text shadow to create the blur effect
    userSelect: "none", // Prevent text selection
  },
  disabledTextField: {
    opacity: 0.7,
    pointerEvents: "none",
    userSelect: "none", // Adjust opacity to indicate the disabled state
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "transparent",
    },
  },
  textField: {
    userSelect: "none",
  },
});

const FormEdit = ({
  toggleDrawer,
  drawerWidth,
  dimensions,
  credenciacao,
  setCredenciacao,
  fetchCredenciacoes,
  // aeroportos,
  funcoes,
  tipoCartoes,
  tipoVinculos,
  cores,
  funcionarios,
  estados,
  empresas,
  habilitacoes,
  tipoDocs,
  copCred,
  tipoCredenciacao,
  obrasState,
  globalFetch,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  dayjs.extend(advancedFormat);

  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");

  const user = useSelector((state) => state.user.perfilId);
  const permissoes = useSelector((state) => state.user.permissoes);
  const superuser = permissoes.includes("APP_CRED_SUPER_USER");

  const aeroportos = useSelector(
    (state) => state.credenciais.aeroportosByFuncId
  );
  // ############################################################## //
  // ########################  Collapse  ########################## //
  // ############################################################## //

  const [open, setOpen] = useState(false);

  // ############################################################## //
  // ######################  Autocompletes  ####################### //
  // ############################################################## //

  const [funcionario, setFuncionario] = useState({ id: null, nome: "" });
  const [funcionarioSel, setFuncionarioSel] = useState({});
  const [veiculo, setVeiculo] = useState({ id: null, descricao: "" });
  const [estado, setEstado] = useState({ id: null, descricao: "" });
  const [projeto, setProjeto] = useState({ id: null, descricao: "" });
  const [aeroporto, setAeroporto] = useState({
    id: "0",
    descricao: "",
  });
  const [funcao, setFuncao] = useState({
    id: "0",
    descricao: "",
  });
  const [acompanhante, setAcompanhante] = useState({
    id: null,
    nome: "",
    aeroporto: "",
    numero: "",
  });
  const [motivo, setMotivo] = useState({
    id: null,
    descricao: "",
  });
  const [tipoCartao, setTipoCartao] = useState({
    id: "0",
    descricao: "",
  });
  const [tipoVinculo, setTipoVinculo] = useState({
    id: "0",
    descricao: "",
  });
  const [tipoCombustivel, setTipoCombustivel] = useState({
    id: null,
    descricao: "",
  });
  const [tipoVeiculo, setTipoVeiculo] = useState({
    id: null,
    descricao: "",
  });
  const [portAcesso, setPortAcesso] = useState({
    id: null,
    descricao: "",
  });
  const [cor, setCor] = useState({
    id: "0",
    descricao: "",
  });
  const uploadedFiles = useSelector((state) => state.credenciais.uploadedFiles);
  const downloadFiles = useSelector((state) => state.credenciais.downloadFiles);
  const uploadedCopia =
    uploadedFiles.find(
      (copia) => copia.id_crede_elementos_files_tipos === "8"
    ) || null;
  const ficheirosState = useSelector((state) => state.credenciais.ficheiros);

  // ############################################################## //
  // ##################  Dates and Time Changes  ################## //
  // ############################################################## //

  const [maxDate, setMaxDate] = useState();
  const [acessoInicial, setAcessoInicial] = useState(null);
  const [acessoFinal, setAcessoFinal] = useState(null);
  const [horasInicial, setHorasInicial] = useState(dayjs().startOf("day"));
  const [horasFinal, setHorasFinal] = useState(dayjs().startOf("day"));
  const [vinculoInicial, setVinculoInicial] = useState(null);
  const [vinculoFinal, setVinculoFinal] = useState(null);

  const handleAcessoInicialChange = (date) => {
    if (maxState === 1) {
      // Add one year to acessoInicial
      const oneYearLater = new Date(acessoInicial);
      oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
      setMaxDate(oneYearLater);
    } else if (maxState === 2) {
      // Add three years to acessoInicial
      const threeYearsLater = new Date(acessoInicial);
      threeYearsLater.setFullYear(threeYearsLater.getFullYear() + 3);
      setMaxDate(threeYearsLater);
    }
    setAcessoInicial(date);
  };

  const handleAcessoFinalChange = (date) => {
    setAcessoFinal(date);
  };

  const handleHorasInicialChange = (hour) => {
    setHorasInicial(hour);
  };

  const handleHorasFinalChange = (hour) => {
    setHorasFinal(hour);
  };

  const handleVinculoInicialChange = (date) => {
    setVinculoInicial(date);
  };

  const handleVinculoFinalChange = (date) => {
    setVinculoFinal(date);
  };

  // ############################################################## //
  // ##########################  States  ########################## //
  // ############################################################## //

  const [maxState, setMaxState] = useState(); //1 == 1 ano && 2 == 3 anos
  const [areaA, setAreaA] = useState(false);
  const [areaB, setAreaB] = useState(false);
  const [areaC, setAreaC] = useState(false);
  const [areaE, setAreaE] = useState(false);
  const [areaI, setAreaI] = useState(false);
  const [areaL, setAreaL] = useState(false);
  const [areaM, setAreaM] = useState(false);
  const [areaO, setAreaO] = useState(false);
  const [areaP, setAreaP] = useState(false);
  const [areaT, setAreaT] = useState(false);
  const [saca, setSACA] = useState(false);
  const [renovacao, setRenovacao] = useState(false);
  const [pedido, setPedido] = useState(false);
  const [alteracao, setAlteracao] = useState(false);
  const [inquerito, setInquerito] = useState(false);
  const [checkIn, setCheckIn] = useState(false);
  const [portaEm, setPortaEm] = useState(false);
  const [portaDes, setPortaDes] = useState(false);
  const [outrasPortas, setOutrasPortas] = useState(false);
  const [tools, setTools] = useState(false);
  const [carAccess, setCarAccess] = useState(false);

  // ############################################################## //
  // ######################  Autocompletes  ####################### //
  // ############################################################## //

  const [vitalicio, setVitalicio] = useState(false);
  const [empresa, setEmpresa] = useState({
    id: "0",
    descricao: "",
  });
  const [habilitacao, setHabilitacao] = useState({
    id: "0",
    descricao: "",
  });
  const [tipoDoc, setTipoDoc] = useState({
    id: "0",
    descricao: "",
  });

  // ############################################################## //
  // ######################  Dates Changes  ####################### //
  // ############################################################## //

  const [dataNascimento, setDataNascimento] = useState(new Date());
  const [dataValidade, setDataValidade] = useState(new Date());
  const [dataEmissao, setDataEmissao] = useState(new Date());

  const handleDataNascimentoChange = (date) => {
    setDataNascimento(date);
  };

  const handleDataValidadeChange = (date) => {
    setDataValidade(date);
  };

  const handleDataEmissaoChange = (date) => {
    setDataEmissao(date);
  };

  // ############################################################## //
  // ####################  Inquerito Pessoal  ##################### //
  // ############################################################## //

  const [inqueritoPessoal, setInqueritoPessoal] = useState([]);

  // const [inqueritoList, setInqueritoList] = useState([]);

  // const handleInqueritoChange = (e, index, inqueritoPessoal) => {
  //   const updatedInqueritoList = [...inqueritoList];
  //   const file = e.target.files[0];

  //   // Update the specific inquerito item in the list with the new file
  //   updatedInqueritoList[index] = {
  //     ...updatedInqueritoList[index],
  //     file,
  //     idTipo: inqueritoPessoal.id_crede_elementos_files_tipos,
  //     path: inqueritoPessoal.crede_elementos_files_tipos_path,
  //   };

  //   setInqueritoList(updatedInqueritoList);
  // };

  // const handleRemoveFicheiro = (e, inquerito, index) => {
  //   e.preventDefault();

  //   setInqueritoList((prevList) => {
  //     const updatedList = [...prevList];
  //     updatedList[index] = null;
  //     return updatedList;
  //   });
  // };

  // const handleRemoveInquerito = (e, inqueritoItem) => {
  //   e.preventDefault();

  //   setInqueritoList((prevList) =>
  //     prevList.filter((item) => item !== inqueritoItem)
  //   );
  // };

  // const handleAddInquerito = () => {
  //   setInqueritoList((prevList) => [...prevList, null]);
  // };

  // ############################################################## //
  // ####################  Cópia Credenciação  #################### //
  // ############################################################## //

  const [copiaCredenciacao, setCopiaCredenciacao] = useState([]);

  const handleCopiaChange = (e, copCred) => {
    const file = e.target.files[0];
    console.log(file);

    setCopiaCredenciacao(() => {
      const updatedFicheiros = [];
      updatedFicheiros.push({
        file,
        idTipo: copCred.id_crede_elementos_files_tipos,
        path: copCred.crede_elementos_files_tipos_path,
      });
      return updatedFicheiros;
    });
  };

  console.log(copiaCredenciacao);

  const handleRemoveCopia = (e, filName) => {
    e.preventDefault();

    setCopiaCredenciacao(
      copiaCredenciacao.filter(function (copiaCredenciacao) {
        return copiaCredenciacao.name !== filName.name;
      })
    );
  };

  // ############################################################## //
  // ########################  Ficheiros  ######################### //
  // ############################################################## //

  const [ficheiros, setFicheiros] = useState([]);
  const [datePickerValues, setDatePickerValues] = useState({});

  const handleFilesChange = (e, index, ficheiro) => {
    const file = e.target.files[0];

    setFicheiros((prevFicheiros) => {
      const updatedFicheiros = [...prevFicheiros];
      updatedFicheiros[index] = {
        file,
        idTipo: ficheiro.id_crede_elementos_files_tipos,
        path: ficheiro.crede_elementos_files_tipos_path,
      };

      // Preenche os índices vazios com objetos vazios
      for (let i = 0; i < updatedFicheiros.length; i++) {
        if (updatedFicheiros[i] === undefined) {
          updatedFicheiros[i] = null; // ou { file: null, idTipo: null, path: null } para manter a estrutura
        }
      }

      return updatedFicheiros;
    });
  };

  const handleRemoveFiles = (index) => {
    setFicheiros((prevFicheiros) => {
      const updatedFicheiros = [...prevFicheiros];
      updatedFicheiros[index] = null;

      // Preenche os índices vazios com objetos vazios
      for (let i = 0; i < updatedFicheiros.length; i++) {
        if (updatedFicheiros[i] === undefined) {
          updatedFicheiros[i] = null; // ou { file: null, idTipo: null, path: null } para manter a estrutura
        }
      }

      return updatedFicheiros;
    });
  };

  const ficheirosAdicionaisState = useSelector(
    (state) => state.credenciais.pedidosAdicionaisDocs
  );

  // ############################################################## //
  // ########################  Ficheiros  ######################### //
  // ############################################################## //

  // const [ficheirosAdicionais, setFicheirosAdicionais] = useState([]);

  // const handleFilesAdicionaisChange = (e, index, ficheiro) => {
  //   const file = e.target.files[0];

  //   setFicheirosAdicionais((prevFicheiros) => {
  //     const updatedFicheiros = [...prevFicheiros];
  //     updatedFicheiros[index] = {
  //       file,
  //       idTipo: ficheiro.id_crede_elementos_files_tipos,
  //       path: ficheiro.crede_elementos_files_tipos_path,
  //     };
  //     return updatedFicheiros;
  //   });
  // };

  // const handleRemoveFilesAdicionais = (index) => {
  //   setFicheirosAdicionais((prevFicheiros) => {
  //     const updatedFicheiros = [...prevFicheiros];
  //     updatedFicheiros[index] = null;
  //     return updatedFicheiros;
  //   });
  // };

  // ############################################################## //
  // #################  Credenciação Selecionada  ################# //
  // ############################################################## //

  const credenciacaoSelecionada = useSelector(
    (state) =>
      state &&
      state.credenciais &&
      state.credenciais.credenciacao &&
      state.credenciais.credenciacao.credenciacao
  );

  useEffect(() => {
    if (credenciacaoSelecionada) {
      setCredenciacao({
        inscricao_adicional:
          credenciacaoSelecionada.crede_elementos_credenciacao_inscricao_adicional,
        obs: credenciacaoSelecionada.crede_elementos_credenciacao_obs,
        pedido_nr:
          credenciacaoSelecionada.crede_elementos_credenciacao_pedido_nr,
        nr_cartao: credenciacaoSelecionada.crede_elementos_credenciacao_numero,
        motivo_recusa:
          credenciacaoSelecionada.crede_elementos_credenciacao_refused_obs,
        numero_cred_externa:
          credenciacaoSelecionada.crede_elementos_credenciacao_external_card,
      });

      setAreaA(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_a ==
          "0"
          ? false
          : true
      );
      setAreaB(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_b ==
          "0"
          ? false
          : true
      );
      setAreaC(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_c ==
          "0"
          ? false
          : true
      );
      setAreaE(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_e ==
          "0"
          ? false
          : true
      );
      setAreaI(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_i ==
          "0"
          ? false
          : true
      );
      setAreaL(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_l ==
          "0"
          ? false
          : true
      );
      setAreaM(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_m ==
          "0"
          ? false
          : true
      );
      setAreaO(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_o ==
          "0"
          ? false
          : true
      );
      setAreaP(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_p ==
          "0"
          ? false
          : true
      );
      setAreaT(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_t ==
          "0"
          ? false
          : true
      );
      setSACA(
        credenciacaoSelecionada.crede_elementos_credenciacao_flag_saca == "0"
          ? false
          : true
      );
      setCheckIn(
        credenciacaoSelecionada.crede_elementos_credenciacao_porta_check_in ==
          "0"
          ? false
          : true
      );
      setPortaEm(
        credenciacaoSelecionada.crede_elementos_credenciacao_porta_embarque ==
          "0"
          ? false
          : true
      );
      setPortaDes(
        credenciacaoSelecionada.crede_elementos_credenciacao_porta_desembarque ==
          "0"
          ? false
          : true
      );
      setOutrasPortas(
        credenciacaoSelecionada.crede_elementos_credenciacao_porta_outras == "0"
          ? false
          : true
      );
      setRenovacao(
        credenciacaoSelecionada.crede_elementos_credenciacao_renovar_cartao ==
          "0"
          ? false
          : true
      );
      setAlteracao(
        credenciacaoSelecionada.crede_elementos_credenciacao_pedido_alteracao_areas ==
          "0"
          ? false
          : true
      );
      setPedido(
        credenciacaoSelecionada.crede_elementos_credenciacao_pedido_segunda_via ==
          "0"
          ? false
          : true
      );
      setInquerito(
        credenciacaoSelecionada.crede_elementos_credenciacao_area_acesso_a ==
          "0"
          ? false
          : true
      );
      setVinculoInicial(
        credenciacaoSelecionada.crede_elementos_credenciacao_vinculo_laboral_inicio
      );
      setVinculoFinal(
        credenciacaoSelecionada.crede_elementos_credenciacao_vinculo_laboral_fim
      );
      setAcessoInicial(
        credenciacaoSelecionada.crede_elementos_credenciacao_validade_inicio
      );
      setAcessoFinal(
        credenciacaoSelecionada.crede_elementos_credenciacao_validade_fim
      );
      setHorasInicial(
        credenciacaoSelecionada.crede_elementos_credenciacao_horario_inicio
      );
      setHorasFinal(
        credenciacaoSelecionada.crede_elementos_credenciacao_horario_fim
      );

      setFuncionario({
        id: credenciacaoSelecionada.id_crede_elementos,
        nome: credenciacaoSelecionada.nome_funcionario
          ? credenciacaoSelecionada.nome_funcionario
          : "--",
      });
      setAeroporto({
        id: credenciacaoSelecionada.id_crede_aeroportos,
        descricao: credenciacaoSelecionada.nome_aeroporto
          ? credenciacaoSelecionada.nome_aeroporto
          : "--",
      });
      setEstado({
        id: credenciacaoSelecionada.id_crede_credenciacao_estado,
        descricao: credenciacaoSelecionada.nome_estado
          ? credenciacaoSelecionada.nome_estado
          : "--",
      });
      setFuncao({
        id: credenciacaoSelecionada.id_crede_credenciacao_funcao,
        descricao: credenciacaoSelecionada.nome_funcao
          ? credenciacaoSelecionada.nome_funcao
          : "--",
      });
      setTipoCartao({
        id: credenciacaoSelecionada.id_crede_credenciacao_tipo_cartao,
        descricao: credenciacaoSelecionada.tipo_cartao
          ? credenciacaoSelecionada.tipo_cartao
          : "--",
      });
      setTipoVinculo({
        id: credenciacaoSelecionada.id_crede_credenciacao_tipo_vinculo,
        descricao: credenciacaoSelecionada.tipo_vinculo
          ? credenciacaoSelecionada.tipo_vinculo
          : "--",
      });
      setCor({
        id: credenciacaoSelecionada.id_crede_credenciacao_cor,
        descricao: credenciacaoSelecionada.nome_cor
          ? credenciacaoSelecionada.nome_cor
          : "--",
      });
      setProjeto({
        id: credenciacaoSelecionada.id_obra,
        descricao: credenciacaoSelecionada.obra
          ? credenciacaoSelecionada.obra
          : "--",
      });
    }
  }, [credenciacaoSelecionada, setCredenciacao]);

  // ############################################################## //
  // #################  Funcionário Selecionada  ################## //
  // ############################################################## //

  const funcionarioSelecionado = useSelector(
    (state) =>
      state &&
      state.credenciais &&
      state.credenciais.credenciacao &&
      state.credenciais.credenciacao.funcionario
  );

  useEffect(() => {
    if (funcionarioSelecionado) {
      setFuncionarioSel({
        categoria_profissional:
          funcionarioSelecionado.crede_elementos_categoria_profissional,
        cp: funcionarioSelecionado.crede_elementos_cp,
        data_nascimento: funcionarioSelecionado.crede_elementos_data_nascimento,
        doc_data_emissao:
          funcionarioSelecionado.crede_elementos_doc_data_emissao,
        doc_entidade_emissora:
          funcionarioSelecionado.crede_elementos_doc_entidade_emissora,
        doc_nr_identificacao:
          funcionarioSelecionado.crede_elementos_doc_nr_identificacao,
        doc_nr_identificacao_controlo:
          funcionarioSelecionado.crede_elementos_doc_nr_identificacao_controlo,
        doc_validade: funcionarioSelecionado.crede_elementos_doc_validade,
        doc_vitalicio: funcionarioSelecionado.crede_elementos_doc_vitalicio,
        email: funcionarioSelecionado.crede_elementos_email,
        filiacao_mae: funcionarioSelecionado.crede_elementos_filiacao_mae,
        filiacao_pai: funcionarioSelecionado.crede_elementos_filiacao_pai,
        funcao: funcionarioSelecionado.crede_elementos_funcao,
        local_nascimento:
          funcionarioSelecionado.crede_elementos_local_nascimento,
        localidade: funcionarioSelecionado.crede_elementos_localidade,
        morada: funcionarioSelecionado.crede_elementos_morada,
        nacionalidade: funcionarioSelecionado.crede_elementos_nacionalidade,
        nif: funcionarioSelecionado.crede_elementos_nif,
        nome: funcionarioSelecionado.crede_elementos_nome,
        telefone: funcionarioSelecionado.crede_elementos_telefone,
        telemovel: funcionarioSelecionado.crede_elementos_telemovel,
      });
      setDataEmissao(funcionarioSelecionado.crede_elementos_doc_data_emissao);
      setDataNascimento(funcionarioSelecionado.crede_elementos_data_nascimento);
      setDataValidade(funcionarioSelecionado.crede_elementos_doc_validade);
      setVitalicio(
        funcionarioSelecionado.crede_elementos_doc_vitalicio == "0"
          ? false
          : true
      );
      setHabilitacao({
        id: funcionarioSelecionado.id_crede_elementos_habilitacoes_tipos,
        descricao: funcionarioSelecionado.nome_habilitacoes,
      });
      setTipoDoc({
        id: funcionarioSelecionado.id_crede_tipo_doc_identificacao,
        descricao: funcionarioSelecionado.nome_tipo_doc_identificacao,
      });
      setEmpresa({
        id: funcionarioSelecionado.id_crede_fornecedores_servicos,
        descricao: funcionarioSelecionado.nome_fornecedores_servicos,
      });
    }
  }, [funcionarioSelecionado]);

  // ############################################################## //
  // ######################  Switch Estados  ###################### //
  // ############################################################## //

  let estadosFiltered;

  switch (
    credenciacaoSelecionada &&
    credenciacaoSelecionada.id_crede_credenciacao_estado
  ) {
    case 1:
      estadosFiltered = estados.filter((e) => e.id === 1 || e.id === 2);
      break;
    case 4:
      estadosFiltered = estados.filter((e) => e.id === 4 || e.id === 6);
      break;
    case 5:
      estadosFiltered = estados.filter((e) => e.id === 1 || e.id === 2);
      break;
    default:
      estadosFiltered = [];
      break;
  }

  // ############################################################## //
  // ##################  MUI Datatable columns  ################### //
  // ############################################################## //

  const formacoes = useSelector(
    (state) => state.credenciais.funcionario.formacoes
  );

  const columns = [
    {
      name: "id_crede_elementos_formacoes",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "crede_elementos_formacoes_nome",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
      },
    },
    {
      name: "crede_elementos_formacoes_cod",
      label: "Codigo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "crede_elementos_formacoes_tipo",
      label: "Tipo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "crede_elementos_formacoes_formador",
      label: "Formador",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "crede_elementos_formacoes_estados_nome",
      label: "Estado",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "",
    //   label: "",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     empty: true,
    //     setCellProps: () => ({
    //       style: { width: "5%" },
    //       align: "center",
    //       margin: 0,
    //       padding: 0,
    //     }),
    //     customBodyRender: (value, tableMeta) => {
    //       const o = tableMeta.rowData[0];

    //       return (
    //         <div style={{ display: "flex" }}>
    //           <IconButton
    //             onClick={() => {
    //               setDrawerCursoState("editCurso");
    //               toggleCursoDrawer("right", true, o);
    //             }}
    //             style={{
    //               padding: 6,
    //             }}
    //           >
    //             <FeatherIcon
    //               icon="edit"
    //               size={25}
    //               style={{ strokeWidth: "1.5" }}
    //             />
    //           </IconButton>
    //         </div>
    //       );
    //     },
    //   },
    // },
  ];

  // ############################################################## //
  // ##################  MUI Datatable options  ################### //
  // ############################################################## //

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },
  };

  // ############################################################## //
  // #####################  Modal Rascunho  ####################### //
  // ############################################################## //

  const [openRascunho, setOpenRascunho] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState([]);
  const [modalState, setModalState] = useState("");

  const handleOpen = (e, ydata) => {
    e.preventDefault();

    setLoading(false);
    setDados(ydata);
    setModalState("edit");
    setOpenRascunho(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpenRascunho(false);
  };

  // ############################################################## //
  // ##################  Verificar Formulário  #################### //
  // ############################################################## //

  // Verify form credenciação permanente
  const verifyFormPerm = () => {
    // Check if the required fields are filled
    const isRequiredFilled =
      !!funcionario.id &&
      !!estado.id &&
      !!aeroporto.id &&
      !!funcao.id &&
      !!tipoCartao.id;

    return isRequiredFilled;
  };

  // ############################################################## //
  // #############  Handle Change and Submit Form  ################ //
  // ############################################################## //

  const handleChange = (e) => {
    e.preventDefault();
    setCredenciacao({
      ...credenciacao,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        funcionario_id: funcionario.id,
        estado_id: estado.id,
        pedido_nr: credenciacao.pedido_nr,
        nr_cartao: credenciacao.nr_cartao,
        motivo_recusa: credenciacao.motivo_recusa,
        area_acesso_a: areaA,
        area_acesso_b: areaB,
        area_acesso_c: areaC,
        area_acesso_e: areaE,
        area_acesso_i: areaI,
        area_acesso_l: areaL,
        area_acesso_m: areaM,
        area_acesso_o: areaO,
        area_acesso_p: areaP,
        area_acesso_t: areaT,
        saca: saca,
        inscricao_adicional: credenciacao.inscricao_adicional,
        obs: credenciacao.obs,
        validade_inicio: acessoInicial
          ? moment(acessoInicial).format("YYYY-MM-DD")
          : null,
        validade_fim: acessoFinal
          ? moment(acessoFinal).format("YYYY-MM-DD")
          : null,
        horario_inicio: dayjs(horasInicial).format("YYYY-MM-DD HH:mm:ss"),
        horario_fim: dayjs(horasFinal).format("YYYY-MM-DD HH:mm:ss"),
        renovar_cartao: renovacao,
        pedido_segunda_via: pedido,
        pedido_alteracao_areas: alteracao,
        vinculo_laboral_inicio: vinculoInicial
          ? moment(vinculoInicial).format("YYYY-MM-DD")
          : null,
        vinculo_laboral_fim: vinculoFinal
          ? moment(vinculoFinal).format("YYYY-MM-DD")
          : null,
        inquerito_pessoal_exito: inquerito,
        porta_check_in: checkIn,
        porta_embarque: portaEm,
        porta_desembarque: portaDes,
        porta_outras: outrasPortas,
        aeroporto_id: aeroporto.id,
        tipoCartao: tipoCartao.id,
        funcao_id: funcao.id,
        cor_id: cor.id,
        tipoVinculo: tipoVinculo.id,
        tipoCredenciacao: tipoCredenciacao.id,
        acompanhante_id: acompanhante.id,
        motivo_id: motivo.id,
        numero_cred: credenciacao.numero_cred,
        tools: tools,
        car_access: carAccess,
        veiculo_id: veiculo.id,
        portAcesso_id: portAcesso.id,
        rascunho: 0,
        projeto_id: projeto.id,
        acompanhante_nome: acompanhante.nome,
        acompanhante_cartao: acompanhante.numero,
        projeto_nome: projeto.descricao,
      },
    ];

    console.log(ydata);

    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!",
      type: "error",
    };
    const message = {
      isOpen: true,
      message: "Credenciação editada com sucesso!!",
      type: "success",
    };

    if (verifyFormPerm()) {
      dispatch(
        EditCredenciacao(
          ydata,
          credenciacaoSelecionada.id_crede_elementos_credenciacao,
          copiaCredenciacao,
          null,
          ficheiros,
          datePickerValues,
          toggleDrawer,
          ShowNotification,
          message,
          errorMessage
        )
      ).then(() => {
        toggleDrawer("right", false);
        fetchCredenciacoes();
        globalFetch(estado.id);
        // dispatch(
        //   SendCredNotifications(
        //     credenciacaoSelecionada.id_crede_elementos_credenciacao,
        //     estado.id
        //   )
        // );
        dispatch(
          NotifyCredenciacoes(
            credenciacaoSelecionada.id_crede_elementos_credenciacao,
            estado.id
          )
        );
      });
    } else {
      // dispatch(ShowNotification(warningMessage));
      ydata[0] = { ...ydata[0], rascunho: 1 };
      handleOpen(e, ydata);
    }
  };

  const fetchCredFuncionarios = () => {
    dispatch(GetCredFuncionarios());
  };

  useEffect(() => {
    fetchCredFuncionarios();
  }, []);

  return (
    <div
      className={classes.list}
      style={
        dimensions.width <= 1100
          ? { width: "60vw" }
          : { width: `${drawerWidth}` }
      }
    >
      <ModalRascunho
        openRascunho={openRascunho}
        handleClose={handleClose}
        fetchCredenciacoes={fetchCredenciacoes}
        dados={dados}
        loading={loading}
        setLoading={setLoading}
        ficheiros={ficheiros}
        datePickerValues={datePickerValues}
        toggleDrawer={toggleDrawer}
        ShowNotification={ShowNotification}
        modalState={modalState}
        idElem={
          credenciacaoSelecionada &&
          credenciacaoSelecionada.id_crede_elementos_credenciacao
        }
        copiaCredenciacao={copiaCredenciacao}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 5,
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => toggleDrawer("right", false)}
        >
          <div>
            <FeatherIcon
              icon="chevron-left"
              size={20}
              style={{ strokeWidth: "1.5" }}
            />
          </div>
          <div className="text-underline-close-drawer">Credenciações</div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box>
      </Box>
      <Card
        sx={{
          m: 5,
          p: 2,
          boxShadow: "0px 10px 15px lightgrey",
        }}
      >
        <Box
          sx={{
            m: 2,
          }}
        >
          <h4
            style={{
              fontSize: "22px",
              width: "100%",
              marginBottom: "0px",
            }}
            className="text-underline"
          >
            Credenciação Permanente
          </h4>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ m: 2, width: "30%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Nome *
            </Typography>
            <Autocomplete
              disabled
              fullWidth
              name="nome"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              value={funcionario}
              className="unidadesId-form"
              options={funcionarios || []}
              getOptionLabel={(option) => `${option.nome}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value) => {
                setFuncionario(value);
                return value;
              }}
            />
          </Box>
          <Box sx={{ m: 2, width: "30%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Estado *
            </Typography>
            <Autocomplete
              disabled={
                !superuser &&
                credenciacaoSelecionada &&
                credenciacaoSelecionada.id_crede_credenciacao_estado != 1 &&
                credenciacaoSelecionada.id_crede_credenciacao_estado != 4 &&
                credenciacaoSelecionada.id_crede_credenciacao_estado != 5
                  ? true
                  : false
              }
              fullWidth
              name="nome"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              value={estado}
              className="unidadesId-form"
              options={!superuser ? estadosFiltered : estados}
              getOptionLabel={(option) => `${option.descricao}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setEstado({ id: null, descricao: "" });
                  return;
                } else {
                  setEstado(value);
                  return value;
                }
              }}
            />
          </Box>
          <Box sx={{ m: 2, width: "30%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Projeto
            </Typography>
            <Autocomplete
              disabled={
                credenciacaoSelecionada &&
                credenciacaoSelecionada.id_obra != null
                  ? true
                  : false
              }
              fullWidth
              name="nome"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              value={projeto}
              className="unidadesId-form"
              options={obrasState || []}
              getOptionLabel={(option) => `${option.descricao}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setProjeto({ id: null, descricao: "" });
                  return;
                } else {
                  setProjeto(value);
                  return value;
                }
              }}
            />
          </Box>
        </Box>
        {estado.descricao == "Levantada" ? (
          <>
            <Box
              sx={{
                m: 2,
              }}
            >
              <h4
                style={{ color: "#E21450" }}
                className="text-underline-title-drawer "
              >
                Credenciação
              </h4>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  mx: 2,
                  display: "flex",
                  width: "46.5%",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Cópia Cartão
                </Typography>
                <Box sx={{ display: "flex" }}>
                  {uploadedCopia && (
                    <Button
                      sx={{
                        height: "40px",
                        borderRadius: "5px",
                        mr: 1,
                      }}
                      className={classes.btnUpload}
                      onClick={() => {
                        Instance()
                          .get(
                            `${BASE_URL}/credenciacoes/funcionarios/files/${uploadedCopia.id_crede_elementos_files}/download`,
                            { responseType: "blob" }
                          )
                          .then(({ data }) => {
                            const filename =
                              uploadedCopia.crede_elementos_files_path.replace(
                                /^.*[\\/]/,
                                ""
                              );
                            fileDownload(data, filename);
                          })
                          .catch((err) => {
                            console.log(err.response);
                            if (err.response) {
                              if (err.response === 403) {
                                // dispatch(ShowNotification(errorMessage));
                              }
                            }
                          });
                      }}
                    >
                      <FeatherIcon icon="download" size={20} />
                    </Button>
                  )}
                  <Button
                    sx={{
                      height: "40px",
                      width: "200px",
                      borderRadius: "5px",
                      mr: 2,
                    }}
                    onChange={(e) => handleCopiaChange(e, copCred)}
                    className={classes.btnUpload}
                  >
                    <label
                      htmlFor={copCred.crede_elementos_files_tipos_nome_input}
                      className="custom-file-upload"
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          cursor: "pointer",
                        }}
                      >
                        <p> Carregar Ficheiro</p>
                      </div>
                    </label>
                    <input
                      type="file"
                      name={copCred.crede_elementos_files_tipos_nome_input}
                      id={copCred.crede_elementos_files_tipos_nome_input}
                      hidden
                      accept=".pdf"
                    />
                  </Button>
                  <div style={{ width: "auto" }}>
                    {copiaCredenciacao &&
                      copiaCredenciacao.map((copiaCredenciacao, index) => {
                        return (
                          <div
                            style={{
                              // marginRight: "10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                            key={index}
                          >
                            {/* {copiaCredenciacao.file.name} */}
                            <IconButton
                              onClick={() => {
                                const fileName = copiaCredenciacao.file.name;
                                const fileExtension = fileName.slice(
                                  (Math.max(0, fileName.lastIndexOf(".")) ||
                                    Infinity) + 1
                                );

                                if (fileExtension == "pdf") {
                                  const pdfFile = copiaCredenciacao.file;

                                  // Create a Blob from the PDF file
                                  const blob = new Blob([pdfFile], {
                                    type: "application/pdf",
                                  });

                                  // Create a URL for the Blob
                                  const blobUrl = URL.createObjectURL(blob);

                                  // Now you can use `blobUrl` to open or display the PDF
                                  window.open(blobUrl, "_blank");
                                } else {
                                  const blobUrl = URL.createObjectURL(
                                    copiaCredenciacao.file
                                  );

                                  // Now you can use `blobUrl` to open or display the PDF
                                  window.open(blobUrl, "_blank");
                                }
                              }}
                            >
                              <FeatherIcon icon="eye" size={"1rem"} />
                            </IconButton>
                            <IconButton
                              onClick={(e) =>
                                handleRemoveCopia(e, copiaCredenciacao)
                              }
                            >
                              <FeatherIcon icon="trash-2" size={"1rem"} />
                            </IconButton>
                          </div>
                        );
                      })}
                  </div>
                </Box>
              </Box>
              <Box
                sx={{
                  mx: 2,
                  display: "flex",
                  width: "46.5%",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Nº Cartão
                </Typography>
                <TextField
                  name="nr_cartao"
                  size="small"
                  variant="outlined"
                  value={credenciacao.nr_cartao}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
          </>
        ) : estado.descricao == "Recusada" ? (
          <Box
            sx={{
              mx: 2,
              display: "flex",
              width: "46.5%",
              flexDirection: "column",
            }}
          >
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Motivo de Recusa
            </Typography>
            <TextField
              name="motivo_recusa"
              disabled={!superuser && estado.id != 1 ? true : false}
              multiline
              rows={2}
              size="small"
              variant="outlined"
              value={credenciacao.motivo_recusa}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        ) : null}
        <Box
          sx={{
            mb: 2,
            mx: 2,
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <h4
              style={{ color: "#E21450" }}
              className="text-underline-title-drawer "
            >
              Funcionário Info
            </h4>
          </Box>
          <Box>
            <h4
              onClick={() => setOpen(!open)}
              className="text-underline-title-drawer"
              style={{ display: "flex", alignItems: "center" }}
            >
              {open ? (
                <FeatherIcon
                  icon="chevron-up"
                  strokeWidth={2}
                  color="#707070"
                  sx={{ pt: 1 }}
                />
              ) : (
                <FeatherIcon
                  icon="chevron-down"
                  strokeWidth={2}
                  color="#707070"
                  sx={{ pt: 1 }}
                />
              )}
            </h4>
          </Box>
        </Box>
        <Collapse in={open}>
          <Card
            sx={{
              m: 2,
              boxShadow: "0px 10px 15px lightgrey",
            }}
          >
            <Box sx={{ m: 2 }}>
              <h4
                style={{ color: "#E21450" }}
                className="text-underline-title-drawer "
              >
                Detalhes Pessoais
              </h4>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "48%" }}
              >
                <Box sx={{ m: 1 }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Nome Completo
                  </Typography>
                  <TextField
                    disabled
                    name="nome"
                    size="small"
                    variant="outlined"
                    value={funcionarioSel.nome}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
                <Box sx={{ m: 1 }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Morada
                  </Typography>
                  <TextField
                    disabled
                    multiline
                    rows={2}
                    name="morada"
                    size="small"
                    variant="outlined"
                    value={funcionarioSel.morada}
                    onChange={handleChange}
                    style={{
                      width: "100%",
                    }}
                    className={
                      funcionarioSelecionado &&
                      funcionarioSelecionado.crede_elementos_ocultar_flag == 1
                        ? classes.textField
                        : null
                    }
                    InputProps={{
                      readOnly: true, // Make the TextField read-only
                      classes: {
                        root:
                          funcionarioSelecionado &&
                          funcionarioSelecionado.crede_elementos_ocultar_flag ==
                            1
                            ? classes.disabledTextField
                            : null, // Apply the disabledTextField class to the root element
                        input:
                          funcionarioSelecionado &&
                          funcionarioSelecionado.crede_elementos_ocultar_flag ==
                            1
                            ? classes.blurredText
                            : null, // Apply the blurred text class to the input element
                      },
                    }}
                    autoComplete="off"
                  />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ m: 1, width: "46.5%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Localidade
                    </Typography>
                    <TextField
                      disabled
                      name="localidade"
                      size="small"
                      variant="outlined"
                      value={funcionarioSel.localidade}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      className={
                        funcionarioSelecionado &&
                        funcionarioSelecionado.crede_elementos_ocultar_flag == 1
                          ? classes.textField
                          : null
                      }
                      InputProps={{
                        readOnly: true, // Make the TextField read-only
                        classes: {
                          root:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.disabledTextField
                              : null, // Apply the disabledTextField class to the root element
                          input:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.blurredText
                              : null, // Apply the blurred text class to the input element
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ m: 1, width: "46.5%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Código Postal
                    </Typography>
                    <TextField
                      disabled
                      name="cp"
                      size="small"
                      variant="outlined"
                      value={funcionarioSel.cp}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      className={
                        funcionarioSelecionado &&
                        funcionarioSelecionado.crede_elementos_ocultar_flag == 1
                          ? classes.textField
                          : null
                      }
                      InputProps={{
                        readOnly: true, // Make the TextField read-only
                        classes: {
                          root:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.disabledTextField
                              : null, // Apply the disabledTextField class to the root element
                          input:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.blurredText
                              : null, // Apply the blurred text class to the input element
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ m: 1, width: "46.5%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Telefone
                    </Typography>
                    <TextField
                      disabled
                      name="telefone"
                      size="small"
                      variant="outlined"
                      value={funcionarioSel.telefone}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      className={
                        funcionarioSelecionado &&
                        funcionarioSelecionado.crede_elementos_ocultar_flag == 1
                          ? classes.textField
                          : null
                      }
                      InputProps={{
                        readOnly: true, // Make the TextField read-only
                        classes: {
                          root:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.disabledTextField
                              : null, // Apply the disabledTextField class to the root element
                          input:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.blurredText
                              : null, // Apply the blurred text class to the input element
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ m: 1, width: "46.5%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Telemóvel
                    </Typography>
                    <TextField
                      disabled
                      name="telemovel"
                      size="small"
                      variant="outlined"
                      value={funcionarioSel.telemovel}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      className={
                        funcionarioSelecionado &&
                        funcionarioSelecionado.crede_elementos_ocultar_flag == 1
                          ? classes.textField
                          : null
                      }
                      InputProps={{
                        readOnly: true, // Make the TextField read-only
                        classes: {
                          root:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.disabledTextField
                              : null, // Apply the disabledTextField class to the root element
                          input:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.blurredText
                              : null, // Apply the blurred text class to the input element
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ m: 1 }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Email
                  </Typography>
                  <TextField
                    disabled
                    name="email"
                    size="small"
                    variant="outlined"
                    value={funcionarioSel.email}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    className={
                      funcionarioSelecionado &&
                      funcionarioSelecionado.crede_elementos_ocultar_flag == 1
                        ? classes.textField
                        : null
                    }
                    InputProps={{
                      readOnly: true, // Make the TextField read-only
                      classes: {
                        root:
                          funcionarioSelecionado &&
                          funcionarioSelecionado.crede_elementos_ocultar_flag ==
                            1
                            ? classes.disabledTextField
                            : null, // Apply the disabledTextField class to the root element
                        input:
                          funcionarioSelecionado &&
                          funcionarioSelecionado.crede_elementos_ocultar_flag ==
                            1
                            ? classes.blurredText
                            : null, // Apply the blurred text class to the input element
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "48%" }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptLocale}
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Data de Nascimento
                    </Typography>
                    <DatePicker
                      disabled
                      margin="normal"
                      id="date-picker-dialog"
                      format="dd/MM/yyyy"
                      okLabel="ok"
                      cancelLabel="cancelar"
                      invalidDateMessage="Data Inválida"
                      value={dataNascimento}
                      onChange={handleDataNascimentoChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "dd/mm/aaaa",
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          name="data_nascimento"
                          sx={{ width: "200px" }}
                          className={
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.textField
                              : null
                          }
                          variant="outlined"
                          size="small"
                        />
                      )}
                      InputProps={{
                        readOnly: true, // Make the TextField read-only
                        classes: {
                          root:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.disabledTextField
                              : null, // Apply the disabledTextField class to the root element
                          input:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.blurredText
                              : null, // Apply the blurred text class to the input element
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ m: 1, width: "46.5%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Local de nascimento
                    </Typography>
                    <TextField
                      disabled
                      name="local_nascimento"
                      size="small"
                      variant="outlined"
                      value={funcionarioSel.local_nascimento}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      className={
                        funcionarioSelecionado &&
                        funcionarioSelecionado.crede_elementos_ocultar_flag == 1
                          ? classes.textField
                          : null
                      }
                      InputProps={{
                        readOnly: true, // Make the TextField read-only
                        classes: {
                          root:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.disabledTextField
                              : null, // Apply the disabledTextField class to the root element
                          input:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.blurredText
                              : null, // Apply the blurred text class to the input element
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ m: 1, width: "46.5%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Nacionalidade
                    </Typography>
                    <TextField
                      disabled
                      name="nacionalidade"
                      size="small"
                      variant="outlined"
                      value={funcionarioSel.nacionalidade}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      className={
                        funcionarioSelecionado &&
                        funcionarioSelecionado.crede_elementos_ocultar_flag == 1
                          ? classes.textField
                          : null
                      }
                      InputProps={{
                        readOnly: true, // Make the TextField read-only
                        classes: {
                          root:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.disabledTextField
                              : null, // Apply the disabledTextField class to the root element
                          input:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.blurredText
                              : null, // Apply the blurred text class to the input element
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ m: 1, width: "46.5%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Filiação - Pai
                    </Typography>
                    <TextField
                      disabled
                      name="filiacao_pai"
                      size="small"
                      variant="outlined"
                      value={funcionarioSel.filiacao_pai}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      className={
                        funcionarioSelecionado &&
                        funcionarioSelecionado.crede_elementos_ocultar_flag == 1
                          ? classes.textField
                          : null
                      }
                      InputProps={{
                        readOnly: true, // Make the TextField read-only
                        classes: {
                          root:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.disabledTextField
                              : null, // Apply the disabledTextField class to the root element
                          input:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.blurredText
                              : null, // Apply the blurred text class to the input element
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ m: 1, width: "46.5%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Filiação - Mãe
                    </Typography>
                    <TextField
                      disabled
                      name="filiacao_mae"
                      size="small"
                      variant="outlined"
                      value={funcionarioSel.filiacao_mae}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      className={
                        funcionarioSelecionado &&
                        funcionarioSelecionado.crede_elementos_ocultar_flag == 1
                          ? classes.textField
                          : null
                      }
                      InputProps={{
                        readOnly: true, // Make the TextField read-only
                        classes: {
                          root:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.disabledTextField
                              : null, // Apply the disabledTextField class to the root element
                          input:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.blurredText
                              : null, // Apply the blurred text class to the input element
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ m: 1, width: "46.5%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Categoria Profissional
                    </Typography>
                    <TextField
                      disabled
                      name="categoria_profissional"
                      size="small"
                      variant="outlined"
                      value={funcionarioSel.categoria_profissional}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      className={
                        funcionarioSelecionado &&
                        funcionarioSelecionado.crede_elementos_ocultar_flag == 1
                          ? classes.textField
                          : null
                      }
                      InputProps={{
                        readOnly: true, // Make the TextField read-only
                        classes: {
                          root:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.disabledTextField
                              : null, // Apply the disabledTextField class to the root element
                          input:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.blurredText
                              : null, // Apply the blurred text class to the input element
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ m: 1, width: "46.5%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Função
                    </Typography>
                    <TextField
                      disabled
                      name="funcao"
                      size="small"
                      variant="outlined"
                      value={funcionarioSel.funcao}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      className={
                        funcionarioSelecionado &&
                        funcionarioSelecionado.crede_elementos_ocultar_flag == 1
                          ? classes.textField
                          : null
                      }
                      InputProps={{
                        readOnly: true, // Make the TextField read-only
                        classes: {
                          root:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.disabledTextField
                              : null, // Apply the disabledTextField class to the root element
                          input:
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.blurredText
                              : null, // Apply the blurred text class to the input element
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ m: 1, width: "46.5%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Entidade Patronal
                    </Typography>
                    <Autocomplete
                      disabled
                      fullWidth
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      noOptionsText="Sem dados"
                      options={empresas || []}
                      value={empresa}
                      getOptionLabel={(option) => `${option.descricao}` || ""}
                      isOptionEqualToValue={(option, value) => {
                        // if (!value.id) return true;
                        return value.id === option.id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          className={
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.textField
                              : null
                          }
                          InputProps={{
                            readOnly: true, // Make the TextField read-only
                            classes: {
                              root:
                                funcionarioSelecionado &&
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                  ? classes.disabledTextField
                                  : null, // Apply the disabledTextField class to the root element
                              input:
                                funcionarioSelecionado &&
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                  ? classes.blurredText
                                  : null, // Apply the blurred text class to the input element
                            },
                          }}
                        />
                      )}
                      onChange={(_, value, reason) => {
                        if (reason === "clear") {
                          setEmpresa(null);
                          return;
                        } else {
                          setEmpresa(value);
                          return value;
                        }
                      }}
                    />
                  </Box>
                  <Box sx={{ m: 1, width: "46.5%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Habilitações
                    </Typography>
                    <Autocomplete
                      disabled
                      fullWidth
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      noOptionsText="Sem dados"
                      options={habilitacoes || []}
                      value={habilitacao}
                      getOptionLabel={(option) => `${option.descricao}` || ""}
                      isOptionEqualToValue={(option, value) => {
                        // if (!value.id) return true;
                        return value.id === option.id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          className={
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.textField
                              : null
                          }
                          InputProps={{
                            readOnly: true, // Make the TextField read-only
                            classes: {
                              root:
                                funcionarioSelecionado &&
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                  ? classes.disabledTextField
                                  : null, // Apply the disabledTextField class to the root element
                              input:
                                funcionarioSelecionado &&
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                  ? classes.blurredText
                                  : null, // Apply the blurred text class to the input element
                            },
                          }}
                        />
                      )}
                      onChange={(_, value, reason) => {
                        if (reason === "clear") {
                          setHabilitacao(null);
                          return;
                        } else {
                          setHabilitacao(value);
                          return value;
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ m: 2 }}>
              <h4
                style={{ color: "#E21450" }}
                className="text-underline-title-drawer "
              >
                Documento de Identificação
              </h4>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "48%" }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ m: 1, width: "46.5%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Tipo de Documento
                    </Typography>
                    <Autocomplete
                      disabled
                      fullWidth
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      noOptionsText="Sem dados"
                      options={tipoDocs || []}
                      value={tipoDoc}
                      getOptionLabel={(option) => `${option.descricao}` || ""}
                      isOptionEqualToValue={(option, value) => {
                        // if (!value.id) return true;
                        return value.id === option.id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          className={
                            funcionarioSelecionado &&
                            funcionarioSelecionado.crede_elementos_ocultar_flag ==
                              1
                              ? classes.textField
                              : null
                          }
                          InputProps={{
                            readOnly: true, // Make the TextField read-only
                            classes: {
                              root:
                                funcionarioSelecionado &&
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                  ? classes.disabledTextField
                                  : null, // Apply the disabledTextField class to the root element
                              input:
                                funcionarioSelecionado &&
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                  ? classes.blurredText
                                  : null, // Apply the blurred text class to the input element
                            },
                          }}
                        />
                      )}
                      onChange={(_, value, reason) => {
                        if (reason === "clear") {
                          setTipoDoc(null);
                          return;
                        } else {
                          setTipoDoc(value);
                          return value;
                        }
                      }}
                    />
                  </Box>
                  <Box sx={{ m: 1, width: "46.5%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Nº Doc Identificação
                    </Typography>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <TextField
                        disabled
                        name="doc_nr_identificacao"
                        size="small"
                        variant="outlined"
                        value={funcionarioSel.doc_nr_identificacao}
                        onChange={handleChange}
                        style={{ width: "69%" }}
                        className={
                          funcionarioSelecionado &&
                          funcionarioSelecionado.crede_elementos_ocultar_flag ==
                            1
                            ? classes.textField
                            : null
                        }
                        InputProps={{
                          readOnly: true, // Make the TextField read-only
                          classes: {
                            root:
                              funcionarioSelecionado &&
                              funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                1
                                ? classes.disabledTextField
                                : null, // Apply the disabledTextField class to the root element
                            input:
                              funcionarioSelecionado &&
                              funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                1
                                ? classes.blurredText
                                : null, // Apply the blurred text class to the input element
                          },
                        }}
                      />
                      <TextField
                        disabled
                        name="doc_nr_identificacao_controlo"
                        size="small"
                        variant="outlined"
                        value={funcionarioSel.doc_nr_identificacao_controlo}
                        onChange={handleChange}
                        style={{ width: "29%" }}
                        className={
                          funcionarioSelecionado &&
                          funcionarioSelecionado.crede_elementos_ocultar_flag ==
                            1
                            ? classes.textField
                            : null
                        }
                        InputProps={{
                          readOnly: true, // Make the TextField read-only
                          classes: {
                            root:
                              funcionarioSelecionado &&
                              funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                1
                                ? classes.disabledTextField
                                : null, // Apply the disabledTextField class to the root element
                            input:
                              funcionarioSelecionado &&
                              funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                1
                                ? classes.blurredText
                                : null, // Apply the blurred text class to the input element
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      m: 1,
                      width: "46.5%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "45%",
                      }}
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={ptLocale}
                      >
                        <Typography
                          style={{ fontWeight: "bold", marginBottom: "10px" }}
                        >
                          Data de Validade
                        </Typography>
                        <DatePicker
                          disabled
                          margin="normal"
                          id="date-picker-dialog"
                          format="dd/MM/yyyy"
                          okLabel="ok"
                          cancelLabel="cancelar"
                          invalidDateMessage="Data Inválida"
                          value={dataValidade}
                          onChange={handleDataValidadeChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "dd/mm/aaaa",
                              }}
                              InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                              }}
                              variant="outlined"
                              size="small"
                              className={
                                funcionarioSelecionado &&
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                  ? classes.textField
                                  : null
                              }
                            />
                          )}
                          InputProps={{
                            readOnly: true, // Make the TextField read-only
                            classes: {
                              root:
                                funcionarioSelecionado &&
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                  ? classes.disabledTextField
                                  : null, // Apply the disabledTextField class to the root element
                              input:
                                funcionarioSelecionado &&
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                  ? classes.blurredText
                                  : null, // Apply the blurred text class to the input element
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box
                      sx={{
                        display: "flex-end",
                        flexDirection: "column",
                        width: "45%",
                      }}
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={ptLocale}
                      >
                        <Typography
                          style={{ fontWeight: "bold", marginBottom: "10px" }}
                        >
                          Data de Emissão
                        </Typography>
                        <DatePicker
                          disabled
                          margin="normal"
                          id="date-picker-dialog"
                          format="dd/MM/yyyy"
                          okLabel="ok"
                          cancelLabel="cancelar"
                          invalidDateMessage="Data Inválida"
                          value={dataEmissao}
                          onChange={handleDataEmissaoChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "dd/mm/aaaa",
                              }}
                              InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                              }}
                              variant="outlined"
                              size="small"
                              className={
                                funcionarioSelecionado &&
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                  ? classes.textField
                                  : null
                              }
                            />
                          )}
                          InputProps={{
                            readOnly: true, // Make the TextField read-only
                            classes: {
                              root:
                                funcionarioSelecionado &&
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                  ? classes.disabledTextField
                                  : null, // Apply the disabledTextField class to the root element
                              input:
                                funcionarioSelecionado &&
                                funcionarioSelecionado.crede_elementos_ocultar_flag ==
                                  1
                                  ? classes.blurredText
                                  : null, // Apply the blurred text class to the input element
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      m: 1,
                      width: "46.5%",
                    }}
                  >
                    <Box sx={{ display: "flex-end", flexDirection: "column" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Vitalicio
                      </Typography>
                      <Box>
                        <Checkbox
                          disabled
                          checked={vitalicio}
                          onChange={() => setVitalicio(!vitalicio)}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "48%" }}
              >
                <Box sx={{ m: 1 }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    NIF
                  </Typography>
                  <TextField
                    disabled
                    name="nif"
                    size="small"
                    variant="outlined"
                    value={funcionarioSel.nif}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    className={
                      funcionarioSelecionado &&
                      funcionarioSelecionado.crede_elementos_ocultar_flag == 1
                        ? classes.textField
                        : null
                    }
                    InputProps={{
                      readOnly: true, // Make the TextField read-only
                      classes: {
                        root:
                          funcionarioSelecionado &&
                          funcionarioSelecionado.crede_elementos_ocultar_flag ==
                            1
                            ? classes.disabledTextField
                            : null, // Apply the disabledTextField class to the root element
                        input:
                          funcionarioSelecionado &&
                          funcionarioSelecionado.crede_elementos_ocultar_flag ==
                            1
                            ? classes.blurredText
                            : null, // Apply the blurred text class to the input element
                      },
                    }}
                  />
                </Box>
                <Box sx={{ m: 1 }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Entidade Emissora
                  </Typography>
                  <TextField
                    name="doc_entidade_emissora"
                    size="small"
                    disabled
                    variant="outlined"
                    value={funcionarioSel.doc_entidade_emissora}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    className={
                      funcionarioSelecionado &&
                      funcionarioSelecionado.crede_elementos_ocultar_flag == 1
                        ? classes.textField
                        : null
                    }
                    InputProps={{
                      readOnly: true, // Make the TextField read-only
                      classes: {
                        root:
                          funcionarioSelecionado &&
                          funcionarioSelecionado.crede_elementos_ocultar_flag ==
                            1
                            ? classes.disabledTextField
                            : null, // Apply the disabledTextField class to the root element
                        input:
                          funcionarioSelecionado &&
                          funcionarioSelecionado.crede_elementos_ocultar_flag ==
                            1
                            ? classes.blurredText
                            : null, // Apply the blurred text class to the input element
                      },
                    }}
                    autoComplete="off"
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}
            >
              <Box
                sx={{
                  mx: 1,
                  width: "100%",
                }}
              >
                <MUIDataTable
                  data={formacoes || []}
                  title={
                    <h4
                      style={{
                        fontSize: "22px",
                        width: "100%",
                      }}
                      className="text-underline"
                    >
                      Formações
                    </h4>
                  }
                  columns={columns}
                  options={options}
                />
              </Box>
            </Box>
          </Card>
        </Collapse>
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Cartão de acesso
          </h4>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "48%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Nº Pedido no Sistema Externo
                </Typography>
                <TextField
                  disabled={!superuser && estado.id != 1 ? true : false}
                  name="pedido_nr"
                  size="small"
                  variant="outlined"
                  value={credenciacao.pedido_nr}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Local/Aeroporto *
                </Typography>
                <Autocomplete
                  disabled={
                    credenciacaoSelecionada &&
                    credenciacaoSelecionada.id_crede_aeroportos !== null
                  }
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={aeroportos || []}
                  value={aeroporto}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setAeroporto(null);
                      return;
                    } else {
                      setAeroporto(value);
                      return value;
                    }
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Tipo de Cartão Solicitado *
                </Typography>
                <Autocomplete
                  disabled={!superuser && estado.id != 1 ? true : false}
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={tipoCartoes || []}
                  value={tipoCartao}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setTipoCartao(null);
                      return;
                    } else {
                      setMaxState(value.id);
                      setTipoCartao(value);
                      return value;
                    }
                  }}
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Função a desempenhar *
                </Typography>
                <Autocomplete
                  disabled={!superuser && estado.id != 1 ? true : false}
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={funcoes || []}
                  value={funcao}
                  getOptionLabel={(option) => `${option.descricao}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setFuncao(null);
                      return;
                    } else {
                      setFuncao(value);
                      return value;
                    }
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Área de acesso pretendido
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      disabled={!superuser && estado.id != 1 ? true : false}
                      checked={areaA}
                      onChange={() => setAreaA(!areaA)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>A</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      disabled={!superuser && estado.id != 1 ? true : false}
                      checked={areaB}
                      onChange={() => setAreaB(!areaB)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>B</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      disabled={!superuser && estado.id != 1 ? true : false}
                      checked={areaC}
                      onChange={() => setAreaC(!areaC)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>C</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      disabled={!superuser && estado.id != 1 ? true : false}
                      checked={areaE}
                      onChange={() => setAreaE(!areaE)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>E</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      disabled={!superuser && estado.id != 1 ? true : false}
                      checked={areaI}
                      onChange={() => setAreaI(!areaI)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>I</Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      disabled={!superuser && estado.id != 1 ? true : false}
                      checked={areaL}
                      onChange={() => setAreaL(!areaL)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>L</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      disabled={!superuser && estado.id != 1 ? true : false}
                      checked={areaM}
                      onChange={() => setAreaM(!areaM)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>M</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      disabled={!superuser && estado.id != 1 ? true : false}
                      checked={areaO}
                      onChange={() => setAreaO(!areaO)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>O</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      disabled={!superuser && estado.id != 1 ? true : false}
                      checked={areaP}
                      onChange={() => setAreaP(!areaP)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>P</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    my: 1,
                    alignItems: "center",
                    width: "8%",
                  }}
                >
                  <Box>
                    <Checkbox
                      disabled={!superuser && estado.id != 1 ? true : false}
                      checked={areaT}
                      onChange={() => setAreaT(!areaT)}
                    />
                  </Box>
                  <Typography style={{ fontWeight: "bold" }}>T</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                SACA
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  my: 1,
                  alignItems: "center",
                }}
              >
                <Box>
                  <Checkbox checked={saca} onChange={() => setSACA(!saca)} />
                </Box>
                <Typography style={{ fontWeight: "bold" }}>
                  Necessário SACA
                </Typography>
              </Box>
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Inscrição adicional de acesso
              </Typography>
              <TextField
                disabled={!superuser && estado.id != 1 ? true : false}
                name="inscricao_adicional"
                size="small"
                variant="outlined"
                value={credenciacao.inscricao_adicional}
                onChange={handleChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Observações
              </Typography>
              <TextField
                disabled={!superuser && estado.id != 1 ? true : false}
                name="obs"
                multiline
                rows={3}
                size="small"
                variant="outlined"
                value={credenciacao.obs}
                onChange={handleChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "48%" }}>
            <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={ptLocale}
              >
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Validade de acesso
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <DatePicker
                    disabled={!superuser && estado.id != 1 ? true : false}
                    margin="normal"
                    minDate={new Date()}
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    okLabel="ok"
                    cancelLabel="cancelar"
                    invalidDateMessage="Data Inválida"
                    value={acessoInicial}
                    onChange={handleAcessoInicialChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        sx={{ width: "200px" }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      mx: 2,
                    }}
                  >
                    até
                  </Typography>
                  <DatePicker
                    disabled={!superuser && estado.id != 1 ? true : false}
                    margin="normal"
                    minDate={new Date()}
                    maxDate={maxDate}
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    okLabel="ok"
                    cancelLabel="cancelar"
                    invalidDateMessage="Data Inválida"
                    value={acessoFinal}
                    onChange={handleAcessoFinalChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        sx={{ width: "200px" }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </Box>
              </LocalizationProvider>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Horário de acesso
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <TimePicker
                    disabled={!superuser && estado.id != 1 ? true : false}
                    format="hh:mm"
                    defaultValue
                    ampm={false}
                    value={horasInicial}
                    onChange={handleHorasInicialChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "hh:mm",
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        sx={{ width: "200px" }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      mx: 2,
                    }}
                  >
                    até
                  </Typography>
                  <TimePicker
                    disabled={!superuser && estado.id != 1 ? true : false}
                    format="hh:mm"
                    ampm={false}
                    value={horasFinal}
                    onChange={handleHorasFinalChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "hh:mm",
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        sx={{ width: "200px" }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </Box>
              </LocalizationProvider>
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Cor
              </Typography>
              <Autocomplete
                disabled={!superuser && estado.id != 1 ? true : false}
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={cores || []}
                value={cor}
                getOptionLabel={(option) => `${option.descricao}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setCor(null);
                    return;
                  } else {
                    setCor(value);
                    return value;
                  }
                }}
              />
            </Box>
            <Box sx={{ display: "flex", m: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography sx={{ fontWeight: "bold", width: "50%" }}>
                  Renovação do Cartão
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}
                >
                  <Checkbox
                    disabled={!superuser && estado.id != 1 ? true : false}
                    checked={renovacao}
                    onChange={() => setRenovacao(!renovacao)}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", m: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography style={{ fontWeight: "bold", width: "50%" }}>
                  Pedido de 2ª Via
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}
                >
                  <Checkbox
                    disabled={!superuser && estado.id != 1 ? true : false}
                    checked={pedido}
                    onChange={() => setPedido(!pedido)}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", m: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography style={{ fontWeight: "bold", width: "50%" }}>
                  Pedido de Alteração de Áreas
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}
                >
                  <Checkbox
                    disabled={!superuser && estado.id != 1 ? true : false}
                    checked={alteracao}
                    onChange={() => setAlteracao(!alteracao)}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Tipo de Vinculo Laboral
              </Typography>
              <Autocomplete
                disabled={!superuser && estado.id != 1 ? true : false}
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={tipoVinculos || []}
                value={tipoVinculo}
                getOptionLabel={(option) => `${option.descricao}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setTipoVinculo(null);
                    return;
                  } else {
                    setTipoVinculo(value);
                    return value;
                  }
                }}
              />
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Inicio do Vinculo Laboral
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <DatePicker
                      disabled={!superuser && estado.id != 1 ? true : false}
                      margin="normal"
                      id="date-picker-dialog"
                      format="dd/MM/yyyy"
                      okLabel="ok"
                      cancelLabel="cancelar"
                      invalidDateMessage="Data Inválida"
                      value={vinculoInicial}
                      onChange={handleVinculoInicialChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "dd/mm/aaaa",
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          sx={{ width: "200px" }}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </Box>
                </LocalizationProvider>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Fim do Vinculo Laboral
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <DatePicker
                      disabled={!superuser && estado.id != 1 ? true : false}
                      margin="normal"
                      id="date-picker-dialog"
                      format="dd/MM/yyyy"
                      okLabel="ok"
                      cancelLabel="cancelar"
                      invalidDateMessage="Data Inválida"
                      value={vinculoFinal}
                      onChange={handleVinculoFinalChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "dd/mm/aaaa",
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          sx={{ width: "200px" }}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </Box>
                </LocalizationProvider>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Dados Complementares
          </h4>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}>
          <Box
            sx={{
              display: "flex",
              width: "auto",
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                m: 1,
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                Check Ins
              </Typography>
              <Checkbox
                disabled={!superuser && estado.id != 1 ? true : false}
                checked={checkIn}
                onChange={() => setCheckIn(!checkIn)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                m: 1,
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                Porta Embarque
              </Typography>
              <Checkbox
                disabled={!superuser && estado.id != 1 ? true : false}
                checked={portaEm}
                onChange={() => setPortaEm(!portaEm)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                m: 1,
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                Porta de desembarque
              </Typography>

              <Checkbox
                disabled={!superuser && estado.id != 1 ? true : false}
                checked={portaDes}
                onChange={() => setPortaDes(!portaDes)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                m: 1,
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
                Outras Portas
              </Typography>

              <Checkbox
                disabled={!superuser && estado.id != 1 ? true : false}
                checked={outrasPortas}
                onChange={() => setOutrasPortas(!outrasPortas)}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Documentos
          </h4>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            mx: 1,
            width: "100%",
          }}
        >
          {downloadFiles &&
            downloadFiles.map((ficheiro, index) => {
              return (
                <Box sx={{ m: 1, width: "15%" }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      marginBottom: "10px",
                      height: "40px",
                      // whiteSpace: "nowrap",
                      // overflow: "hidden",
                      // textOverflow: "ellipsis",
                    }}
                  >
                    {ficheiro.tipo}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Button
                      sx={{
                        height: "40px",
                        borderRadius: "5px",
                        mr: 1,
                      }}
                      className={classes.btnUpload}
                      onClick={() => {
                        Instance()
                          .get(
                            `${BASE_URL}/credenciacoes/funcionarios/files/${ficheiro.fileId}/download`,
                            { responseType: "blob" }
                          )
                          .then(({ data }) => {
                            const filename = ficheiro.name.replace(
                              /^.*[\\/]/,
                              ""
                            );
                            fileDownload(data, filename);
                          })
                          .catch((err) => {
                            console.log(err.response);
                            if (err.response) {
                              if (err.response === 403) {
                                // dispatch(ShowNotification(errorMessage));
                              }
                            }
                          });
                      }}
                    >
                      <FeatherIcon icon="download" size={20} />
                    </Button>
                    <div style={{ width: "100%" }}>
                      {ficheiro && (
                        <Button
                          sx={{
                            height: "40px",
                            borderRadius: "5px",
                          }}
                          className={classes.btnUpload}
                          onClick={() => {
                            // Retrieve the base URL from environment variables
                            let baseUrl = BASE_URL;

                            // Remove the '/api' part from the base URL
                            if (baseUrl.endsWith("/api")) {
                              baseUrl = baseUrl.slice(0, -4); // Remove '/api'
                            }

                            // Construct the full file URL
                            const fileUrl = `${baseUrl}${ficheiro.name}`;

                            // Open the file in a new tab/window
                            window.open(fileUrl, "_blank");
                          }}
                        >
                          <FeatherIcon icon="eye" size={20} />
                        </Button>
                      )}
                    </div>
                  </Box>
                </Box>
              );
            })}
        </Box>
        {/* <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Pedidos Adicionais
          </h4>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            mx: 1,
            width: "100%",
          }}
        >
          {ficheirosAdicionaisState &&
            ficheirosAdicionaisState.map((ficheiro, index) => {
              const matchingFile = uploadedFiles.find(
                (file) =>
                  file.id_crede_elementos_files_tipos ===
                  ficheiro.id_crede_elementos_files_tipos
              );
              return (
                <Box sx={{ m: 1, width: "20%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    {ficheiro.crede_elementos_files_tipos_nome}
                    {/* {ficheiro.id_crede_elementos_files_tipos != 1 &&
                      ficheiro.id_crede_elementos_files_tipos != 7
                        ? " *"
                        : null} */}
        {/* </Typography>
                  <Box sx={{ display: "flex" }}>
                    {matchingFile && (
                      <Button
                        sx={{
                          height: "40px",
                          borderRadius: "5px",
                          mr: 1,
                        }}
                        className={classes.btnUpload}
                        onClick={() => {
                          Instance()
                            .get(
                              `${BASE_URL}/credenciacoes/funcionarios/files/${matchingFile.id_crede_elementos_files}/download`,
                              { responseType: "blob" }
                            )
                            .then(({ data }) => {
                              const filename =
                                matchingFile.crede_elementos_files_path.replace(
                                  /^.*[\\/]/,
                                  ""
                                );
                              fileDownload(data, filename);
                            })
                            .catch((err) => {
                              console.log(err.response);
                              if (err.response) {
                                if (err.response === 403) {
                                  // dispatch(ShowNotification(errorMessage));
                                }
                              }
                            });
                        }}
                      >
                        <FeatherIcon icon="download" size={20} />
                      </Button>
                    )}
                    <Button
                      sx={{
                        height: "40px",
                        width: "200px",
                        borderRadius: "5px",
                        mr: 2,
                      }}
                      onChange={(e) => handleFilesChange(e, index, ficheiro)}
                      className={classes.btnUpload}
                      disabled={!superuser && estado.id != 1 ? true : false}
                    >
                      <label
                        htmlFor={
                          ficheiro.crede_elementos_files_tipos_nome_input
                        }
                        className="custom-file-upload"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            cursor: "pointer",
                          }}
                        >
                          <p> Carregar </p>
                        </div>
                      </label>
                      <input
                        type="file"
                        name={ficheiro.crede_elementos_files_tipos_nome_input}
                        id={ficheiro.crede_elementos_files_tipos_nome_input}
                        hidden
                        accept="image/jpeg, .pdf"
                      />
                    </Button>
                    <div style={{ width: "100%" }}>
                      {ficheiros[index] && (
                        <div
                          style={{
                            marginRight: "10px",
                            display: "flex",
                            alignItems: "center",
                          }}
                          key={index}
                        >
                          <IconButton
                            onClick={() => {
                              const fileName = ficheiros[index].file.name;
                              const fileExtension = fileName.slice(
                                (Math.max(0, fileName.lastIndexOf(".")) ||
                                  Infinity) + 1
                              );

                              if (fileExtension == "pdf") {
                                const pdfFile = ficheiros[index].file;

                                // Create a Blob from the PDF file
                                const blob = new Blob([pdfFile], {
                                  type: "application/pdf",
                                });

                                // Create a URL for the Blob
                                const blobUrl = URL.createObjectURL(blob);

                                // Now you can use `blobUrl` to open or display the PDF
                                window.open(blobUrl, "_blank");
                              } else {
                                const blobUrl = URL.createObjectURL(
                                  ficheiros[index].file
                                );

                                // Now you can use `blobUrl` to open or display the PDF
                                window.open(blobUrl, "_blank");
                              }
                            }}
                          >
                            <FeatherIcon icon="eye" size={"1rem"} />
                          </IconButton>
                          <IconButton onClick={() => handleRemoveFiles(index)}>
                            <FeatherIcon icon="trash-2" size={"1rem"} />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </Box>
                </Box>
              );
            })}
        </Box> */}
        {/* <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Inquerito Pessoal
          </h4>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", m: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    width: "50%",
                    whiteSpace: "nowrap",
                  }}
                >
                  Inquérito Pessoal Realizado com êxito?
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Checkbox
                    checked={inquerito}
                    onChange={() => setInquerito(!inquerito)}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ mx: 5, display: "flex", alignItems: "center" }}>
              {uploadedInquerito && (
                <Button
                  sx={{
                    height: "40px",
                    borderRadius: "5px",
                    mr: 1,
                  }}
                  className={classes.btnUpload}
                  onClick={() => {
                    Instance()
                      .get(
                        `${BASE_URL}/credenciacoes/funcionarios/files/${uploadedInquerito.id_crede_elementos_files}/download`,
                        { responseType: "blob" }
                      )
                      .then(({ data }) => {
                        const filename =
                          uploadedInquerito.crede_elementos_files_path.replace(
                            /^.*[\\\/]/,
                            ""
                          );
                        fileDownload(data, filename);
                      })
                      .catch((err) => {
                        console.log(err.response);
                        if (err.response) {
                          if (err.response === 403) {
                            // dispatch(ShowNotification(errorMessage));
                          }
                        }
                      });
                  }}
                >
                  <FeatherIcon icon="download" size={20} />
                </Button>
              )}
              <Button
                sx={{
                  height: "40px",
                  width: "300px",
                  borderRadius: "5px",
                  mr: 2,
                }}
                onChange={(e) => handleInqPessoalChange(e, inqPessoal)}
                className={classes.btnUpload}
              >
                <label
                  htmlFor={inqPessoal.crede_elementos_files_tipos_nome_input}
                  className="custom-file-upload"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      cursor: "pointer",
                    }}
                  >
                    <p> Carregar </p>
                  </div>
                </label>
                <input
                  type="file"
                  name={inqPessoal.crede_elementos_files_tipos_nome_input}
                  id={inqPessoal.crede_elementos_files_tipos_nome_input}
                  hidden
                  accept=".pdf"
                />
              </Button>
              <div style={{ width: "100%" }}>
                {inqueritoPessoal &&
                  inqueritoPessoal.map((inqueritoPessoal, index) => {
                    return (
                      <div
                        style={{
                          marginRight: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        key={index}
                      >
                        {inqueritoPessoal.file.name}
                        <IconButton
                          onClick={(e) =>
                            handleRemoveInquerito(e, inqueritoPessoal)
                          }
                        >
                          <FeatherIcon icon="trash-2" size={"1rem"} />
                        </IconButton>
                      </div>
                    );
                  })}
              </div>
            </Box>
            {/* <Box sx={{ mx: 5 }}>
              <Button
                sx={{
                  height: "40px",
                  width: "350px",
                  borderRadius: "5px",
                  mr: 2,
                }}
                onChange={(e) => handleInqPessoalChange(e, inqueritoPessoal)}
                className={classes.btnUpload}
              >
                <label
                  htmlFor={inqueritoPessoal.crede_elementos_files_tipos_nome_input}
                  className="custom-file-upload"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      cursor: "pointer",
                    }}
                  >
                    <p> Carregar Ficheiro</p>
                  </div>
                </label>
                <input
                  type="file"
                  name={inqueritoPessoal.crede_elementos_files_tipos_nome_input}
                  id={inqueritoPessoal.crede_elementos_files_tipos_nome_input}
                  hidden
                  accept=".pdf"
                />
              </Button>
              <div style={{ width: "100%" }}>
                {inqPessoal &&
                  inqPessoal.map((inqPessoal, index) => {
                    return (
                      <div
                        style={{
                          marginRight: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        key={index}
                      >
                        {inqPessoal.file.name}
                        <IconButton
                          onClick={(e) =>
                            handleRemoveInquerito(e, inqPessoal)
                          }
                        >
                          <FeatherIcon icon="trash-2" size={"1rem"} />
                        </IconButton>
                      </div>
                    );
                  })}
              </div>
            </Box>
          </Box>
        </Box> */}
      </Card>
    </div>
  );
};

export default FormEdit;
