/* eslint-disable eqeqeq */
import React from 'react';
import { Autocomplete, TextField, Typography } from "@mui/material";

const Estados = ({
  intervencaoSelecionada,
  prioridades,
  prioridade,
  handlePrioridade,
}) => {
  console.log(prioridade);
  return (
    <div style={{ width: "15%" }}>
      <h4 style={{ color: "#E21450" }} className="text-underline-title-drawer ">
        Estados
      </h4>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ width: "250px" }}>
          <div style={{ marginBottom: "16px" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Projeto
            </Typography>
            <TextField
              size="small"
              value={
                (intervencaoSelecionada &&
                  intervencaoSelecionada.estadosPropostasName) ||
                " - "
              }
              variant="outlined"
              disabled
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div style={{ width: "250px" }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Prioridade
          </Typography>
          <Autocomplete
            fullWidth
            disabled={prioridade.id == 3 ? true : false}
            name="nome"
            clearText="Limpar"
            openText="Abrir"
            closeText="Fechar"
            noOptionsText="Sem dados"
            value={prioridade}
            className="unidadesId-form"
            options={prioridades || []}
            getOptionLabel={(option) => `${option.descricao}` || ""}
            isOptionEqualToValue={(option, value) => {
              // if (!value.id) return true;
              return value.id === option.id;
            }}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} size="small" variant="outlined" />
            )}
            onChange={(_, value, reason) => {
              if (reason === "clear") {
                handlePrioridade({ id: null, descricao: "" });
              } else {
                handlePrioridade(value);
              }
              return value;
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Estados;
