/* eslint-disable no-lone-blocks */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Box,
  IconButton,
  useTheme,
  Link,
  Breadcrumbs,
  Button,
  TextField,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import "moment/locale/pt";
import MUIDataTable from "mui-datatables";
import Instance from "../../../../utils/Axios";
import BASE_URL from "../../../../utils/BASE_URL";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptLocale from "date-fns/locale/pt";

// Components
import FooterLinks from "../../../../Global/Components/Footer";

//Icons
import FeatherIcon from "feather-icons-react";
import GetAllAdesoes from "../../../../utils/redux/actions/plus/GetAllAdesoes";
import { format } from "date-fns";
import GetAdesaoById from "../../../../utils/redux/actions/plus/GetAdesaoById";
import GetPlusTipoTarifas from "../../../../utils/redux/actions/plus/GetPlusTipoTarifasAction";
import GetPlusCiclos from "../../../../utils/redux/actions/plus/GetPlusCiclosAction";
import GetPlusEscaloes from "../../../../utils/redux/actions/plus/GetPlusEscaloesAction";
import GetPlusPotencias from "../../../../utils/redux/actions/plus/GetPlusPotenciasAction";
import GetPlusPlanos from "../../../../utils/redux/actions/plus/GetPlusPlanosAction";
import fileDownload from "js-file-download";
import GetPlusStatus from "../../../../utils/redux/actions/plus/GetPlusStatusAction";
import GetPlusServices from "../../../../utils/redux/actions/plus/GetPlusServicesAction";
import GetAdesaoFilesById from "../../../../utils/redux/actions/plus/GetAdesaoFilesAdesaoById";
import GetAdesaoFilesCompExcById from "../../../../utils/redux/actions/plus/GetAdesaoFilesCompExcById";
import GetAdesaoFilesAdesaoById from "../../../../utils/redux/actions/plus/GetAdesaoFilesAdesaoById";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import GetPlusTipoEdificios from "../../../../utils/redux/actions/plus/GetPlusTipoEdificiosActions";
import GetPlusTipoDonos from "../../../../utils/redux/actions/plus/GetPlusTipoDonosAction";
import GetPlusTipoDocs from "../../../../utils/redux/actions/plus/GetPlusTipoDocsAction";
import ModalDelete from "./components/modalDelete";
import GetAdesaoHistoricoById from "../../../../utils/redux/actions/plus/GetAdesaoHistoricoById";
import GetAllClients from "../../../../utils/redux/actions/plus/GetAllClientsAction";
import SideDrawerClientes from "./components/drawer";
import ModalDeleteCliente from "./components/modalDelete";
import GetClientById from "../../../../utils/redux/actions/plus/GetClientByIdAction";
import GetAllPartners from "../../../../utils/redux/actions/plus/GetAllPartnersAction";
import GetPartnerById from "../../../../utils/redux/actions/plus/GetPartnerByIdAction";
import ModalDeleteParceiro from "./components/modalDelete";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
  },
  btnFiltrar: {
    color: "#E21450",
    width: "150px",
    height: "40px",
    fontWeight: "900",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  stateLabel: {
    textTransform: "none",
    transition: "0.2s",
    display: "flex",
    alignItems: "center",
  },

  paperFilters: {
    background: "transparent",
    padding: "20px 0px",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    height: 40,
    borderTop: "4px solid transparent",
    borderBottom: "4px solid transparent",

    "&:hover": {
      borderRight: "4px solid #E21450",
    },
  },
}));

const ColorStateCube = ({ color }) => {
  return (
    <div
      style={{
        height: "10px",
        width: "10px",
        backgroundColor: color,
        marginRight: "10px",
        borderRadius: "3px",
      }}
    />
  );
};

// ############################################################## //
// ####################  Window Dimensions  ##################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const PlusParceiros = () => {
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");

  const user = useSelector((state) => state.user);

  const parceirosState = useSelector((state) => state.plus.parceiros);

  // ############################################################## //
  // ###########################  Modal  ########################## //
  // ############################################################## //
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [parceiroId, setParceiroId] = useState(null);

  const handleOpen = (e, id) => {
    e.preventDefault();

    setLoading(false);

    setOpenDelete(true);
    setParceiroId(id);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpenDelete(false);
  };

  const fetchParceiros = () => {
    dispatch(GetAllPartners());
  };

  const fetchParceiroById = (id) => {
    dispatch(GetPartnerById(id));
  };

  useEffect(() => {
    fetchParceiros();
  }, []);

  const [parceiro, setParceiro] = useState({
    nome: "",
    telemovel: "",
    telefone: "",
    email: "",
    nif: "",
    numero_documento: "",
    certidao_permanente: "",
    iban: "",
    swift: "",
    morada: "",
    cod_postal: "",
    localidade: "",
    concelho: "",
    distrito: "",
    data: "",
    nipc: "",
    cae: "",
    nome_empresa: "",
    rep_legal: "",
  });

  const resetParceiro = () => {
    setParceiro({
      id: "",
      nome: "",
      telemovel: "",
      telefone: "",
      email: "",
      nif: "",
      numero_documento: "",
      certidao_permanente: "",
      iban: "",
      swift: "",
      morada: "",
      cod_postal: "",
      localidade: "",
      concelho: "",
      distrito: "",
      data: "",
      nipc: "",
      cae: "",
      nome_empresa: "",
      rep_legal: "",
    });
  };

  // ############################################################## //
  // ########################  Drawer  ############################ //
  // ############################################################## //

  const [drawerWidth, setDrawerWidth] = useState("90vw");
  const dimensions = useWindowDimensions();
  const [state, setState] = useState({
    right: false,
  });
  const [drawerState, setDrawerState] = useState(null);
  const toggleDrawer = (anchor, open, id) => {
    console.log(id);
    if (open == false) {
      setState({ ...state, [anchor]: open });
      // setCredenciacaoId(null);
      resetParceiro();
    }
    if (open == true) {
      setState({ ...state, [anchor]: open });
      // setCredenciacaoId(id);
      fetchParceiroById(id);
    }
  };

  // ############################################################## //
  // ##################  MUI Datatable columns  ################### //
  // ############################################################## //

  const columns = [
    {
      name: "id_jaf_plus_partner",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "jaf_plus_partner_name",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "jaf_plus_partner_contact",
      label: "Contacto",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "jaf_plus_partner_email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { width: "5%" },
          align: "center",
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[0];
          return (
            <div style={{ display: "flex", float: "right" }}>
              {(user.permissoes.includes("APP_JAFPLUS_EDITAR_CLIENTES") ||
                user.permissoes.includes("APP_JAFPLUS_CONSULTAR_CLIENTES")) && (
                <IconButton
                  onClick={() => {
                    setDrawerState("watch");
                    toggleDrawer("right", true, id);
                  }}
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                >
                  <FeatherIcon
                    icon="eye"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              )}
              {user.permissoes.includes("APP_JAFPLUS_APAGAR_CLIENTES") && (
                <IconButton
                  onClick={(e) => {
                    handleOpen(e, id);
                  }}
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                >
                  <FeatherIcon
                    icon="trash-2"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              )}
            </div>
          );
        },
      },
    },
  ];

  // ############################################################## //
  // ##################  MUI Datatable Options  ################### //
  // ############################################################## //

  const searchText =
    location.state && location.state.funcionarioNome
      ? location.state.funcionarioNome
      : null;

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    searchText: searchText,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },
    customToolbar: () => {
      return (
        <>
          {user.permissoes.includes("APP_JAFPLUS_CRIAR_CLIENTES") && (
            <Tooltip title={"Adicionar Contrato"}>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  setDrawerState("add");
                  toggleDrawer("right", true, null);
                }}
              >
                <FeatherIcon
                  icon="plus-square"
                  size={22}
                  className={classes.deleteIcon}
                  style={{ strokeWidth: "2" }}
                />
              </IconButton>
            </Tooltip>
          )}
        </>
      );
    },
  };

  //   const handleSubmit = () => {
  //     const serviceId = servico.id;
  //     const statusId = estado.id;

  //     fetchAdesoes(serviceId, statusId, date);
  //   };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mr: 10,
          width: "100%",
        }}
      >
        <SideDrawerClientes
          state={state}
          toggleDrawer={toggleDrawer}
          drawerState={drawerState}
          drawerWidth={drawerWidth}
          dimensions={dimensions}
          fetchParceiros={fetchParceiros}
          parceiro={parceiro}
          setParceiro={setParceiro}
        />
        <ModalDeleteParceiro
          openDelete={openDelete}
          handleClose={handleClose}
          parceiroId={parceiroId}
          fetchParceiros={fetchParceiros}
          // Loading
          loading={loading}
          setLoading={setLoading}
        />
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              mx: 5,
              mt: 5,
              width: "100%",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/construcoes/obras">
                Gestão Comercial
              </Link>
              <Typography color="text.primary">Parceiros</Typography>
            </Breadcrumbs>
            {/* <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "240px" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Tipo de contrato
                  </Typography>
                  <Autocomplete
                    fullWidth
                    name="nome"
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    value={servico}
                    className="unidadesId-form"
                    options={servicos || []}
                    getOptionLabel={(option) => `${option.name}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason == "clear") {
                        setServico({ id: null, name: "" });
                      } else {
                        setServico(value);
                      }
                      return value;
                    }}
                  />
                </Box>
                <Box sx={{ width: "240px", mx: 4 }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Estado
                  </Typography>
                  <Autocomplete
                    fullWidth
                    name="nome"
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    value={estado}
                    className="unidadesId-form"
                    options={filteredStatus}
                    getOptionLabel={(option) => `${option.name}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason == "clear") {
                        setEstado({ id: null, name: "" });
                      } else {
                        setEstado(value);
                      }
                      return value;
                    }}
                  />
                </Box>
                <Box sx={{ width: "240px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptLocale}
                  >
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Data
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <DatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        format="dd/MM/yyyy"
                        okLabel="ok"
                        cancelLabel="cancelar"
                        invalidDateMessage="Data Inválida"
                        value={date}
                        onChange={handleDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "dd/mm/aaaa",
                            }}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            sx={{ width: "200px" }}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </Box>
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "end",
                }}
              >
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  className={classes.btnFiltrar}
                >
                  Filtrar
                </Button>
              </Box>
            </Box> */}
          </Box>

          <div
            style={{
              margin: "0px 40px 40px 40px",
              width: "100%",
            }}
          >
            <MUIDataTable
              title={
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  className="text-underline"
                >
                  Parceiros
                </span>
              }
              data={parceirosState || []}
              columns={columns}
              options={options}
            />
          </div>
          <div style={{ margin: "20px 40px" }}>
            <FooterLinks />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default PlusParceiros;
