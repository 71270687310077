import Instance from "../../../../Axios";

// New Actions for the app

const AddCredIntervencaoElemento =
  (intId, funcId, ShowNotification, message, errorMessage) =>
  async (dispatch) => {
    await Instance()
      .post(`/credenciacoes/intervencoes/add/${intId}/${funcId}`)
      .then(({ data }) => {
        //   console.log(data);
        dispatch({
          type: "ADD_CRED_INTERVENCAO_ELEMENTO",
          payload: data.yData[0],
        });
        dispatch(ShowNotification(message));
      })
      .catch((err) => {
        if (err.response) {
          if (err.response === 403) {
            dispatch(ShowNotification(errorMessage));
          } else {
            dispatch(ShowNotification(errorMessage));
          }
        }
      });
  };

export default AddCredIntervencaoElemento;