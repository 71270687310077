import Instance from "../../../Axios";

const GetObras = () => async (dispatch) => {
    await Instance()
        .get(`/intervencoes/obras`)
        .then(({ data }) => {
            //console.log(data);
            dispatch({ type: "GET_INTERVENCOES_OBRAS", payload: data.yData});
        })
        .catch((err) => {
            console.log(err);
        });
};

export default GetObras;