import React from "react";
import { Typography, Box } from "@mui/material";

const Semaforizacao = ({
  obraSelecionada,
  control,
  renderSwitch,
  estDTGlobal,
  estDCGlobal,
  getValues,
}) => {

  return (
    <>
      <div style={{ width: "18%" }}>
        <h4
          style={{ color: "#E21450" }}
          className="text-underline-title-drawer "
        >
          Departamentos
        </h4>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Box>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Técnico
            </Typography>
          </Box>
          <Box>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Compras
            </Typography>
          </Box>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div>{renderSwitch(getValues("estadodt"))}</div>
          <div>{renderSwitch(getValues("estadodc"))}</div>
        </div>
      </div>
    </>
  );
};

export default Semaforizacao;
