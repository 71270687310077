import React from 'react';
import { TextField, Typography } from '@mui/material';

const MaoDeObra = ({ obraSelecionada }) => {
  return (
    <>
      <div style={{ marginLeft: '25px' }}>
        <h4
          style={{ color: '#E21450' }}
          className='text-underline-title-drawer '
        >
          Mão de Obra
        </h4>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '251px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Contratados{' '}
            </Typography>

            <TextField
              size='small'
              value={
                (obraSelecionada && obraSelecionada.maoObraContratada) ||
                ' --/--/---- '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
          <span style={{ width: '25px' }} />
          <div style={{ width: '251px' }}>
            <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              JAF
            </Typography>
            <TextField
              size='small'
              value={
                (obraSelecionada && obraSelecionada.maoObraJaf) ||
                ' --/--/---- '
              }
              variant='outlined'
              disabled
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MaoDeObra;
