import moment from "moment";
import Instance from "../../../../Axios";

// New Actions for the app

const EditCredViatura =
  (
    yData,
    id,
    ficheiros,
    datePickerValues,
    toggleDrawer,
    ShowNotification,
    message,
    errorMessage
  ) =>
  async (dispatch) => {
    const formdata = new FormData();

    formdata.append("yData", JSON.stringify(yData[0]));
    // formdata.append("aeroportos", JSON.stringify(aeroporto));

    if (ficheiros && Array.isArray(ficheiros) && ficheiros.length > 0) {
      ficheiros.forEach((ficheiro, index) => {
        if (ficheiro) {
          formdata.append(`file${index}`, ficheiro.file);
          formdata.append(`idTipo${index}`, ficheiro.idTipo);
          formdata.append(`path${index}`, ficheiro.path);

          const datePickerValue = datePickerValues[index];
          if (datePickerValue) {
            formdata.append(
              `datePicker${index}`,
              moment(datePickerValue).format("YYYY-MM-DD")
            );
          }
        }
      });
    }

    await Instance()
      .put(`/credenciacoes/viaturas/${id}`, formdata)
      .then(({ data }) => {
        console.log(data);
        dispatch({ type: "EDIT_CRED_VIATURA", payload: data.yData[0] });
        toggleDrawer("right", false);
        dispatch(ShowNotification(message));
      })
      .catch((err) => {
        if (err.response) {
          if (err.response === 403) {
            dispatch(ShowNotification(errorMessage));
          } else {
            dispatch(ShowNotification(errorMessage));
          }
        }
      });
  };

export default EditCredViatura;
