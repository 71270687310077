/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";

// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptLocale from "date-fns/locale/pt";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

//ICONS
import FeatherIcon from "feather-icons-react";

//ACTIONS
import AddCredFuncionarioFormacao from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/AddCredFuncionarioFormacaoAction";
import GetCredFormCertificado from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFormCertificadoAction";
import ShowNotification from "../../../../../utils/redux/actions/global/notifications/NotificationAction";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnUpload: {
    color: "#E21450",
    // width: '125px',
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
});

const FormAddCurso = ({
  toggleCursoDrawer,
  dimensions,
  funcionario,
  setFuncionario,
  fetchCredFuncionarios,
  formacao,
  setFormacao,
  funcionarioId,
  estadosFormacoes,
  fetchCredFuncionarioById,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [drawerWidth, setDrawerWidth] = useState("85vw");

  // ############################################################## //
  // ######################  Dates Changes  ####################### //
  // ############################################################## //

  const [dataFormacao, setDataFormacao] = useState(null);
  const [dataInscricao, setDataInscricao] = useState(null);
  const [dataConclusao, setDataConclusao] = useState(null);
  const [datePickerValues, setDatePickerValues] = useState();

  const handleDataFormacaoChange = (date) => {
    setDataFormacao(date);
  };

  const handleDataInscricaoChange = (date) => {
    setDataInscricao(date);
  };

  const handleDataConclusaoChange = (date) => {
    setDataConclusao(date);
  };

  const handleDatePickerChange = (date) => {
    setDatePickerValues(date);
  };

  // ############################################################## //
  // ###################  Verificar Formulário  ################### //
  // ############################################################## //

  const verifyForm = () => {
    const requiredFilesLoaded =
      certificado.length > 0 &&
      certificado.every((ficheiro) => {
        if (ficheiro) {
          return ficheiro.file !== null;
        } else {
          return false;
        }
      });
    // Check if the required fields are filled
    const isRequiredFilled =
      !!formacao.nome &&
      requiredFilesLoaded; // Add more conditions for other required fields

    // Update the state variable
    return isRequiredFilled;
  };

  // ############################################################## //
  // ########################  Certificado  ####################### //
  // ############################################################## //

  const certificadoInfo = useSelector((state) => state.credenciais.certificado);
  const uploadedCertificado = useSelector(
    (state) => state.credenciais.certificadoUploaded
  );

  const fetchCredFormCertificado = () => {
    dispatch(GetCredFormCertificado());
  };

  useEffect(() => {
    fetchCredFormCertificado();
  }, []);

  const [certificado, setCertificado] = useState([]);

  const handleCertificadoChange = (e, cert) => {
    const file = e.target.files[0];
    console.log(file);

    setCertificado(() => {
      const updatedFicheiros = [];
      updatedFicheiros.push({
        file,
        idTipo: cert.id_crede_elementos_files_tipos,
        path: cert.crede_elementos_files_tipos_path,
      });
      return updatedFicheiros;
    });
  };

  const handleRemoveCertificado = (e, filName) => {
    e.preventDefault();

    setCertificado(
      certificado.filter(function (certificado) {
        return certificado.name !== filName.name;
      })
    );
  };

  // ############################################################## //
  // ##############  Handle Change and Submit Form  ############### //
  // ############################################################## //

  const [estado, setEstado] = useState({ id: null, descricao: "" });

  const handleChange = (e) => {
    e.preventDefault();
    setFormacao({
      ...formacao,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        funcionarioId: funcionarioId,
        estadoId: estado.id,
        nome: formacao.nome,
        cod: formacao.cod,
        tipo: formacao.tipo,
        nif_entidade: formacao.nif_entidade,
        formador: formacao.formador,
        formador_entidade: formacao.formador_entidade,
        // solicitacao: formacao.solicitacao,
        data_formacao: dataFormacao ? moment(dataFormacao).format("YYYY-MM-DD") : null,
        data_inscricao: dataInscricao ? moment(dataInscricao).format("YYYY-MM-DD") : null,
        data_conclusao: dataConclusao ? moment(dataConclusao).format("YYYY-MM-DD") : null,
        nota_final: formacao.nota_final,
      },
    ];

    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!",
      type: "error",
    };
    const warningMessage = {
      isOpen: true,
      message: "Preencha os campos obrigatórios",
      type: "warning",
    };
    const message = {
      isOpen: true,
      message: "Formação adicionada com sucesso!!",
      type: "success",
    };

    if (verifyForm()) {
      dispatch(
        AddCredFuncionarioFormacao(
          ydata,
          certificado,
          datePickerValues,
          toggleCursoDrawer,
          ShowNotification,
          message,
          errorMessage
        )
      ).then(() => {
        toggleCursoDrawer("right", false);
        fetchCredFuncionarioById(funcionarioId);
      });
    } else {
      dispatch(ShowNotification(warningMessage));
    }
  };

  return (
    <div
      className={classes.list}
      style={
        dimensions.width <= 1100
          ? { width: "60vw" }
          : { width: `${drawerWidth}` }
      }
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 5,
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => toggleCursoDrawer("right", false)}
        >
          <div>
            <FeatherIcon
              icon="chevron-left"
              size={20}
              style={{ strokeWidth: "1.5" }}
            />
          </div>
          <div className="text-underline-close-drawer">Funcionário</div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box>
      </Box>
      <Card
        sx={{
          m: 5,
          p: 2,
          boxShadow: "0px 10px 15px lightgrey",
        }}
      >
        <Box
          sx={{
            m: 2,
          }}
        >
          <h4
            style={{
              fontSize: "22px",
              width: "100%",
              marginBottom: "0px",
            }}
            className="text-underline"
          >
            Formação
          </h4>
        </Box>
        <Box sx={{ m: 2 }}>
          <h4
            style={{ color: "#E21450" }}
            className="text-underline-title-drawer "
          >
            Detalhes
          </h4>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "48%" }}>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Nome *
              </Typography>
              <TextField
                name="nome"
                size="small"
                variant="outlined"
                onChange={handleChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Código
              </Typography>
              <TextField
                name="cod"
                size="small"
                variant="outlined"
                onChange={handleChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Tipo
              </Typography>
              <TextField
                name="tipo"
                size="small"
                variant="outlined"
                onChange={handleChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                NIF (entidade)
              </Typography>
              <TextField
                name="nif_entidade"
                size="small"
                variant="outlined"
                onChange={handleChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Formador
              </Typography>
              <TextField
                name="formador"
                size="small"
                variant="outlined"
                onChange={handleChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Formador Entidade
              </Typography>
              <TextField
                name="formador_entidade"
                size="small"
                variant="outlined"
                onChange={handleChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Solicitação
              </Typography>
              <TextField
                name="solicitacao"
                size="small"
                variant="outlined"
                onChange={handleChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "48%" }}>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Estado
              </Typography>
              <Autocomplete
                fullWidth
                name="nome"
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                value={estado}
                className="unidadesId-form"
                options={estadosFormacoes || []}
                getOptionLabel={(option) => `${option.descricao}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setEstado({ id: null, descricao: "" });
                  } else {
                    setEstado(value);
                  }
                  return value;
                }}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={ptLocale}
              >
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Data da Última Formação
                </Typography>
                <DatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  okLabel="ok"
                  cancelLabel="cancelar"
                  invalidDateMessage="Data Inválida"
                  value={dataFormacao}
                  onChange={handleDataFormacaoChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "dd/mm/aaaa",
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      sx={{ width: "200px" }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ display: "flex", m: 1 }}>
              <Box sx={{ mr: 2 }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Data da Inscrição
                  </Typography>
                  <DatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    okLabel="ok"
                    cancelLabel="cancelar"
                    invalidDateMessage="Data Inválida"
                    value={dataInscricao}
                    onChange={handleDataInscricaoChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        sx={{ width: "200px" }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Data de Conclusão
                  </Typography>
                  <DatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    okLabel="ok"
                    cancelLabel="cancelar"
                    invalidDateMessage="Data Inválida"
                    value={dataConclusao}
                    onChange={handleDataConclusaoChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        sx={{ width: "200px" }}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Nota Final
              </Typography>
              <TextField
                name="nota_final"
                size="small"
                variant="outlined"
                onChange={handleChange}
                style={{ width: "100px" }}
                autoComplete="off"
              />
            </Box>
            <Box
              sx={{
                mb: 2,
                mx: 2,
              }}
            >
              <Box sx={{ width: "100%" }}>
                <h4
                  style={{ color: "#E21450" }}
                  className="text-underline-title-drawer "
                >
                  Certificado *
                </h4>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  sx={{
                    height: "40px",
                    width: "350px",
                    borderRadius: "5px",
                    mr: 2,
                  }}
                  onChange={(e) => handleCertificadoChange(e, certificadoInfo)}
                  className={classes.btnUpload}
                >
                  <label
                    htmlFor={
                      certificadoInfo.crede_elementos_files_tipos_nome_input
                    }
                    className="custom-file-upload"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        cursor: "pointer",
                      }}
                    >
                      <p> Carregar Ficheiro</p>
                    </div>
                  </label>
                  <input
                    type="file"
                    name={
                      certificadoInfo.crede_elementos_files_tipos_nome_input
                    }
                    id={certificadoInfo.crede_elementos_files_tipos_nome_input}
                    hidden
                    accept=".pdf"
                  />
                </Button>
                <div style={{ width: "100%" }}>
                  {certificado &&
                    certificado.map((certificado, index) => {
                      return (
                        <div
                          style={{
                            marginRight: "10px",
                            display: "flex",
                            alignItems: "center",
                          }}
                          key={index}
                        >
                          {certificado.file.name}
                          <IconButton
                            onClick={() => {
                              const fileName = certificado.file.name;
                              const fileExtension = fileName.slice(
                                (Math.max(0, fileName.lastIndexOf(".")) ||
                                  Infinity) + 1
                              );

                              if (fileExtension == "pdf") {
                                const pdfFile = certificado.file;

                                // Create a Blob from the PDF file
                                const blob = new Blob([pdfFile], {
                                  type: "application/pdf",
                                });

                                // Create a URL for the Blob
                                const blobUrl = URL.createObjectURL(blob);

                                // Now you can use `blobUrl` to open or display the PDF
                                window.open(blobUrl, "_blank");
                              } else {
                                const blobUrl = URL.createObjectURL(
                                  certificado.file
                                );

                                // Now you can use `blobUrl` to open or display the PDF
                                window.open(blobUrl, "_blank");
                              }
                            }}
                          >
                            <FeatherIcon icon="eye" size={"1rem"} />
                          </IconButton>
                          <IconButton
                            onClick={(e) =>
                              handleRemoveCertificado(e, certificado)
                            }
                          >
                            <FeatherIcon icon="trash-2" size={"1rem"} />
                          </IconButton>
                        </div>
                      );
                    })}
                </div>
              </Box>
              <div style={{ width: "100%", display: "flex" }}>
                {certificado &&
                  certificado.map((certificado, index) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: 1,
                        }}
                      >
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={ptLocale}
                        >
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Data de Validade
                          </Typography>
                          <DatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            format="dd/MM/yyyy"
                            okLabel="ok"
                            value={datePickerValues}
                            onChange={(date) => handleDatePickerChange(date)}
                            cancelLabel="cancelar"
                            invalidDateMessage="Data Inválida"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "dd/mm/aaaa",
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                name="data_validade"
                                sx={{ width: "200px" }}
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    );
                  })}
              </div>
            </Box>
          </Box>
        </Box>
      </Card>
    </div>
  );
};

export default FormAddCurso;
