export const plusInitialState = {
  adesoes: [],
  adesao: {},
  ciclos: [],
  potencias: [],
  tipoTarifas: [],
  tipoDonos: [],
  tipoEdificios: [],
  tipoDocs: [],
  tipoDocsAdesao: [],
  tipoDocsCompExc: [],
  escaloes: [],
  planos: [],
  status: [],
  responsaveis: [],
  partners: [],
  services: [],
  adesaoFiles: [],
  adesaoEditada: {},
  adesaoHistorico: [],
  clientes: [],
  cliente: {},
  parceiros: [],
  parceiro: {},
};
