import React from "react";
import {
  Button,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  TextField,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { yellow } from "@mui/material/colors";
import { useDispatch } from "react-redux";

const CustomCheckbox = withStyles({
  root: {
    color: "#707070",
    "&$checked": {
      color: "#707070",
    },
    background: "transparent !important",
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  modalBtns: {
    height: "40px",
    width: "125px",
    display: "flex",
    color: "#E21450",
    border: "1px solid grey",
    backgroundColor: yellow,
    "&:hover": {
      backgroundColor: yellow,
    },
    "@media (hover: none)": {
      backgroundColor: yellow,
    },
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
}));

const FiltersObrasCheck = ({
  user,
  fetchObras,
  // Estado Projeto
  estadoProjeto,
  estadosProjetoPreSelectedId,
  // Estado SubProjeto
  estadoSubProjeto,
  estadosSubProjetoPreSelectedId,
  // AEROPORTOS
  aeroportos,
  aeroportoPreSelectedId,
  // CONTROLO
  controlo,
  controloPreSelectedId,
  // RESPONSAVEL
  diretorObra,
  diretorObraPreSelectedId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const eProjetosId = estadosProjetoPreSelectedId.map((ep) => ep.id);
    const eSubProjetosId = estadosSubProjetoPreSelectedId.map((ep) => ep.id);
    const aeroportoId = aeroportoPreSelectedId.map((ep) => ep.id);
    const controloId = controloPreSelectedId.map((ep) => ep.id);
    const diretorObraId =
      user && user.perfilId === 3
        ? user.diretorId
        : diretorObraPreSelectedId && diretorObraPreSelectedId.id;

    fetchObras(
      eProjetosId,
      eSubProjetosId,
      aeroportoId,
      controloId,
      diretorObraId
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          // justifyContent: 'space-between',
          alignItems: "start",
          width: "100%",
          marginTop: "40px",
        }}
        className="not-selectable"
      >
        <div style={{ width: "15%" }}>
          <div>
            <div
              className="text-underline-title-drawer"
              style={{ width: "100%" }}
            >
              Aeroportos
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {aeroportos &&
                // eslint-disable-next-line array-callback-return
                aeroportos.map((aeroporto, index) => {
                  const aeroportoExists =
                    aeroportoPreSelectedId &&
                    !aeroportoPreSelectedId.some(
                      (o2) => o2.id === aeroporto.id
                    );

                  if (
                    aeroporto &&
                    aeroporto.id !== 0 &&
                    aeroporto &&
                    aeroporto.id !== 1
                  ) {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          minWidth: "33.3%",
                          cursor: "pointer",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              // eslint-disable-next-line eqeqeq
                              checked={!aeroportoExists}
                              disabled={
                                user && user.aeroportoFilter ? true : false
                              }
                              onChange={() => {
                                if (!aeroportoExists) {
                                  dispatch({
                                    type: "ESTADOS_AEROPORTO_PRE_SELECTED",
                                    payload: aeroportoPreSelectedId.filter(
                                      (est) => est.id !== aeroporto.id
                                    ),
                                  });
                                } else {
                                  dispatch({
                                    type: "ESTADOS_AEROPORTO_PRE_SELECTED",
                                    payload: [
                                      ...aeroportoPreSelectedId,
                                      aeroporto,
                                    ],
                                  });
                                }
                              }}
                              name={aeroporto.descricao}
                            />
                          }
                          label={aeroporto.abreviatura}
                        />
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
        <span style={{ width: "2%" }} />
        <div style={{ width: "15%" }}>
          <div>
            <div
              className="text-underline-title-drawer"
              style={{ width: "100%" }}
            >
              Estados do Projeto
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {estadoProjeto &&
                estadoProjeto.map((estado, index) => {
                  const estadoExists =
                    estadosProjetoPreSelectedId &&
                    !estadosProjetoPreSelectedId.some(
                      (o2) => o2.id === estado.id
                    );

                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        minWidth: "50%",
                        cursor: "pointer",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            // eslint-disable-next-line eqeqeq
                            checked={!estadoExists}
                            onChange={() => {
                              if (!estadoExists) {
                                dispatch({
                                  type: "ESTADOS_PROJETO_PRE_SELECTED",
                                  payload: estadosProjetoPreSelectedId.filter(
                                    (est) => est.id !== estado.id
                                  ),
                                });
                              } else {
                                dispatch({
                                  type: "ESTADOS_PROJETO_PRE_SELECTED",
                                  payload: [
                                    ...estadosProjetoPreSelectedId,
                                    estado,
                                  ],
                                });
                              }
                            }}
                            name={estado.descricao}
                          />
                        }
                        label={estado.descricao}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <span style={{ width: "2%" }} />
        <div style={{ width: "25%" }}>
          <div>
            <div
              className="text-underline-title-drawer"
              style={{ width: "100%" }}
            >
              Estados do SubProjeto
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {estadoSubProjeto &&
                estadoSubProjeto.map((estado, index) => {
                  const estadoExists =
                    estadosSubProjetoPreSelectedId &&
                    !estadosSubProjetoPreSelectedId.some(
                      (o2) => o2.id === estado.id
                    );
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        minWidth: "33.3%",
                        cursor: "pointer",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            // eslint-disable-next-line eqeqeq
                            checked={!estadoExists}
                            onChange={() => {
                              if (!estadoExists) {
                                dispatch({
                                  type: "ESTADOS_SUBPROJETO_PRE_SELECTED",
                                  payload:
                                    estadosSubProjetoPreSelectedId.filter(
                                      (est) => est.id !== estado.id
                                    ),
                                });
                              } else {
                                dispatch({
                                  type: "ESTADOS_SUBPROJETO_PRE_SELECTED",
                                  payload: [
                                    ...estadosSubProjetoPreSelectedId,
                                    estado,
                                  ],
                                });
                              }
                            }}
                            name={estado.descricao}
                          />
                        }
                        label={estado.descricao}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <span style={{ width: "2%" }} />
        <div style={{ width: "20%" }}>
          <div>
            <div
              className="text-underline-title-drawer"
              style={{ width: "100%" }}
            >
              Controlo
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                // justifyContent: 'space-evenly',
              }}
            >
              {controlo &&
                controlo.map((estado, index) => {
                  const estadoExists =
                    controloPreSelectedId &&
                    !controloPreSelectedId.some((o2) => o2.id === estado.id);
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        minWidth: "50%",
                        cursor: "pointer",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            // eslint-disable-next-line eqeqeq
                            checked={!estadoExists}
                            onChange={() => {
                              if (!estadoExists) {
                                dispatch({
                                  type: "ESTADOS_CONTROLO_PRE_SELECTED",
                                  payload: controloPreSelectedId.filter(
                                    (est) => est.id !== estado.id
                                  ),
                                });
                              } else {
                                dispatch({
                                  type: "ESTADOS_CONTROLO_PRE_SELECTED",
                                  payload: [...controloPreSelectedId, estado],
                                });
                              }
                            }}
                            name={estado.descricao}
                          />
                        }
                        label={estado.descricao}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        {user && user.perfilId !== 3 && (
          <>
            <span style={{ width: "2%" }} />
            <div style={{ width: "20%" }}>
              <div>
                <div
                  className="text-underline-title-drawer"
                  style={{ width: "100%" }}
                >
                  Diretor de Obra
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    // justifyContent: 'space-evenly',
                  }}
                >
                  <Autocomplete
                    fullWidth
                    id="diretorObra"
                    name="diretorObra"
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    className="unidadesId-form"
                    options={diretorObra || []}
                    getOptionLabel={(option) => `${option.nome}` || ""}
                    // getOptionLabel={(option) => console.log(option)}
                    // filterOptions={(options, state) =>
                    //   options.filter((option) => option.id > 0)
                    // }
                    filterOptions={(options, state) => {
                      let newOptions = [];
                      options.forEach((option) => {
                        if (
                          option.id !== "0" &&
                          option.nome
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        )
                          newOptions.push(option);
                      });
                      return newOptions;
                    }}
                    // value={
                    //   responsavelPreSelectedId.length <= 0 ||
                    //   responsavelPreSelectedId == null
                    //     ? { id: '0', nome: '' }
                    //     : responsavelPreSelectedId
                    // }
                    value={diretorObraPreSelectedId}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      // console.log(value[0].id === option.id);
                      return value.id === option.id;
                    }}
                    style={{ width: "100%", marginRight: "1%" }}
                    // onChange={(e, value) => setEstadosProjetoPreSelectedId(value)}
                    onChange={(e, value) =>
                      dispatch({
                        type: "DIRETOR_OBRA_PRE_SELECTED",
                        payload: value,
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        // label='Responsável'
                        placeholder="Diretor de Obra"
                        size="small"
                        // style={{ height: '35px' }}
                        InputProps={{
                          ...params.InputProps,

                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <span style={{ width: "2%" }} />
        <Button
          onClick={handleSubmit}
          variant="contained"
          className={classes.btnFiltrar}
        >
          Filtrar
        </Button>
      </div>
    </>
  );
};

export default FiltersObrasCheck;
