import React, { useState } from "react";
// PACKAGES
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  CircularProgress,
  Box,
  IconButton,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import FeatherIcon from "feather-icons-react";

// ACTIONS
import GetAdesaoById from "../../../../../utils/redux/actions/plus/GetAdesaoById";
import GetAdesaoHistoricoById from "../../../../../utils/redux/actions/plus/GetAdesaoHistoricoById";
import GetAdesaoFilesAdesaoById from "../../../../../utils/redux/actions/plus/GetAdesaoFilesAdesaoById";
import GetAdesaoFilesCompExcById from "../../../../../utils/redux/actions/plus/GetAdesaoFilesCompExcById";

import AddPlusDoc from "../../../../../utils/redux/actions/plus/AddPlusDocAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: 2,
  boxShadow: 3,
  p: 3,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: "600px",
    width: "600px",
    margin: 15,
  },
  btnConfirmar: {
    color: "white",
    backgroundColor: "#E21450",
    width: "100px",
    height: "40px",
    fontWeight: "bold",
  },
  btnConfirmarLoading: {
    width: "48%",
    height: "50px",
    backgroundColor: grey[300],
  },
  btnCancelar: {
    color: "#E21450",
    width: "100px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  modalBtns: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    marginTop: "20px",
    justifyContent: "space-evenly",
  },
}));

const ModalAddFile = ({
  openAddDocs,
  handleCloseAddDoc,
  fileId,
  adesaoId,
  clientId,
  serviceId,
  tiposDocsAdesao,
  tiposDocsCompExc,
  // Loading
  loading,
  setLoading,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fetchAdesaoById = (id) => {
    dispatch(GetAdesaoById(id));
  };

  const fetchAdesaoHistoricoById = (id) => {
    dispatch(GetAdesaoHistoricoById(id));
  };

  const fetchAdesaoFilesAdesaoById = (id) => {
    dispatch(GetAdesaoFilesAdesaoById(id));
  };

  const fetchAdesaoFilesCompExcById = (id) => {
    dispatch(GetAdesaoFilesCompExcById(id));
  };
  const handleAddDoc = (e) => {
    e.preventDefault();
    setLoading(true);
    const yData = [
      {
        docId: tipoDoc.id,
        docPath: tipoDoc.path,
        clientId: clientId,
        serviceId: serviceId,
      },
    ];
    dispatch(AddPlusDoc(yData, newFile))
      .then(() => handleCloseAddDoc(e))
      .then(() => {
        setTipoDoc({ id: 0, name: "" });
        setNewFile([]);
      })
      .then(() => {
        fetchAdesaoById(adesaoId);
        fetchAdesaoHistoricoById(clientId);
        if (serviceId === 1) {
          fetchAdesaoFilesAdesaoById(clientId);
        } else {
          fetchAdesaoFilesCompExcById(clientId);
        }
      });
  };

  // ############################################################## //
  // ########################  New File  ########################## //
  // ############################################################## //
  const [tipoDoc, setTipoDoc] = useState({ id: 0, name: "" });
  const [newFile, setNewFile] = useState([]);

  const handleNewFileChange = (e, tipoDoc) => {
    const file = e.target.files[0];
    console.log(file);

    setNewFile(() => {
      const updatedFicheiros = [];
      updatedFicheiros.push({
        file,
        idTipo: tipoDoc.id,
      });
      return updatedFicheiros;
    });
  };

  const handleRemoveNewFile = (e, fileName) => {
    e.preventDefault();

    setNewFile(
      newFile.filter(function (newFile) {
        return newFile.name !== fileName.name;
      })
    );
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAddDocs}
        onClose={(e) => handleCloseAddDoc(e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAddDocs}>
          <Box sx={style}>
            <div className="header-modal">
              <h3
                id="transition-modal-title"
                style={{ fontSize: "20px", textAlign: "center" }}
              >
                Adicionar ficheiro
              </h3>
              <p
                id="transition-modal-description"
                style={{ textAlign: "center" }}
              >
                Pretende adicionar um ficheiro a este formulário
              </p>
            </div>
            <Box sx={{ m: 1, mb: 3 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Tipo Documento
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={
                  (serviceId === 1 ? tiposDocsAdesao : tiposDocsCompExc) || []
                }
                value={tipoDoc}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setTipoDoc(null);
                    return;
                  } else {
                    setTipoDoc(value);
                    return value;
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{
                  height: "40px",
                  borderRadius: "5px",
                  m: 1,
                }}
                onChange={(e) => handleNewFileChange(e, tipoDoc)}
                className={classes.btnUpload}
              >
                <label htmlFor="file" className="custom-file-upload">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      cursor: "pointer",
                    }}
                  >
                    <p> Carregar Ficheiro</p>
                  </div>
                </label>
                <input type="file" name="file" id="file" hidden accept=".pdf" />
              </Button>
              <div>
                {newFile &&
                  newFile.map((newFile, index) => {
                    return (
                      <div
                        style={{
                          marginRight: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        key={index}
                      >
                        {newFile.file.name}
                        <IconButton
                          onClick={(e) => handleRemoveNewFile(e, newFile)}
                        >
                          <FeatherIcon icon="trash-2" size={"1rem"} />
                        </IconButton>
                      </div>
                    );
                  })}
              </div>
            </Box>
            <div className={classes.modalBtns}>
              <Button
                className={
                  loading ? classes.btnConfirmarLoading : classes.btnConfirmar
                }
                disabled={!!loading}
                variant="contained"
                onClick={handleAddDoc}
              >
                {loading && (
                  <CircularProgress
                    size={14}
                    style={{ color: "#9d9d9d", marginRight: "10px" }}
                  />
                )}
                Confirmar
              </Button>
              <Button
                className={classes.btnCancelar}
                variant="contained"
                onClick={(e) => handleCloseAddDoc(e)}
              >
                Cancelar
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalAddFile;
