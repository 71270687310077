/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const GraficoTaxaSucesso = ({ taxaSucesso, dimensions }) => {
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        width: '100%',
        type: 'bar',
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 25,
        style: {
          fontSize: '12px',
          colors: ['#000'],
        },
        formatter: function (value) {
          return value + ' %';
        },
      },
      title: {
        text: 'Taxa de Sucesso',
        align: 'center',
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
        },
        max: 100,
        categories: [],
      },
      grid: {
        show: true,
      },
      fill: {
        opacity: 1,
      },
      colors: ['#F6BB43'],
    },
  });

  useEffect(() => {
    taxaSucesso && setState(taxaSucesso);
  }, [taxaSucesso]);

  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type='bar'
        height={300}
        width={dimensions < 1560 ? undefined : 250}
      />
    </>
  );
};

export default GraficoTaxaSucesso;
