import Instance from '../../../../Axios';

// New Actions for the app

const GetEstadosMaterial = () => async (dispatch) => {
  await Instance()
    .get(`/energias/estadosMateriais`)
    .then(({ data }) => {
      //   console.log(data);
      dispatch({ type: 'GET_ENERGIAS_ESTADOS_MATERIAL', payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetEstadosMaterial;
