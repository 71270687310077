/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect } from "react";

// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
  Box,
  Card,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";

//COMPONENTS
import ModalDelete from "./modalDelete";
import ModalAdicionar from "./modalAdicionar";

//ACTIONS
import GetCredFuncionarioById from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionarioByIdAction";
import GetCredAeroportosByFuncId from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredAeroportosByFuncIdAction";
import GetCredFuncionariosUploadedFiles from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionarioUploadedFilesAction";
import GetCredFuncionarioFormacaoByFormId from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionarioFormacaoByFormIdAction";
import SideDrawer from "../../Funcionarios/components/drawer";
import GetCredFuncionarios from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionariosAction";
import GetCredFuncionariosResources from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredFuncionariosResourcesAction";
import GetCredEmpresas from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredEmpresasAction";
import GetCredAeroportos from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredAeroportosAction";
import GetCredHabilitacoes from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredHabilitacoesAction";
import GetCredTipoDocs from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredTipoDocsAction";
import GetCredEstadosFormacoes from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredEstadosFormacoesAction";
import GetCredIntervencoesTotais from "../../../../../utils/redux/actions/aprovisionamento/credenciacoes/GetCredIntervencoesTotaisAction";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnUpload: {
    color: "#E21450",
    // width: '125px',
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
});

const FormEdit = ({
  drawerWidth,
  dimensions,
  toggleDrawer,
  drawerState,
  intervencaoId,
  fetchCredIntervencoesElementos,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");

  // ############################################################## //
  // ########################  Modal Delete  ###################### //
  // ############################################################## //

  const [openDelete, setOpenDelete] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [funcionarioId, setFuncionarioId] = useState();
  const [loading, setLoading] = useState(false);

  const handleOpen = (e, type, id) => {
    e.preventDefault();

    setLoading(false);

    if (type == "add") {
      setOpenAdd(true);
    } else {
      setOpenDelete(true);
      setFuncionarioId(id);
    }
  };

  const handleClose = (e, type) => {
    e.preventDefault();
    if (type == "add") {
      setOpenAdd(false);
    } else {
      setOpenDelete(false);
    }
  };

  // ############################################################## //
  // ##################  Drawer Edit Funcionário  ################# //
  // ############################################################## //

  const [funcionario, setFuncionario] = useState({
    nome: "",
    morada: "",
    cp: "",
    localidade: "",
    telefone: "",
    telemovel: "",
    email: "",
    nif: "",
    data_nascimento: "",
    local_nascimento: "",
    nacionalidade: "",
    filiacao_pai: "",
    filiacao_mae: "",
    categoria_profissional: "",
    funcao: "",
    doc_nr_identificacao: "",
    doc_nr_identificacao_controlo: "",
    doc_validade: "",
    doc_vitalicio: "",
    doc_entidade_emissora: "",
    doc_data_emissao: "",
  });

  const [formacao, setFormacao] = useState({
    nome: "",
    cod: "",
    tipo: "",
    nif_entidade: "",
    formador: "",
    formador_entidade: "",
    solicitacao: "",
    data_formacao: "",
    data_inscricao: "",
    data_conclusao: "",
    nota_final: "",
  });

  const empresas = useSelector((state) => state.credenciais.empresas);
  const aeroportos = useSelector((state) => state.credenciais.aeroportos);
  const habilitacoes = useSelector((state) => state.credenciais.habilitacoes);
  const tipoDocs = useSelector((state) => state.credenciais.tipoDocs);
  const estadosFormacoes = useSelector(
    (state) => state.credenciais.estadosFormacoes
  );
  const resources = useSelector((state) => state.credenciais.resources);

  const fetchCredFuncionarios = (intervencaoId) => {
    dispatch(GetCredFuncionarios(intervencaoId));
  };

  const fetchCredFuncionariosResources = () => {
    dispatch(GetCredFuncionariosResources());
  };

  const fetchCredEmpresas = () => {
    dispatch(GetCredEmpresas());
  };

  const fetchCredAeroportos = () => {
    dispatch(GetCredAeroportos());
  };

  const fetchCredHabilitacoes = () => {
    dispatch(GetCredHabilitacoes());
  };

  const fetchCredTipoDocs = () => {
    dispatch(GetCredTipoDocs());
  };

  const fetchCredEstadoFormacoes = () => {
    dispatch(GetCredEstadosFormacoes());
  };

  const fetchIntervencoesTotais = () => {
    dispatch(GetCredIntervencoesTotais());
  };

  useEffect(() => {
    fetchCredFuncionarios();
    fetchCredEmpresas();
    fetchCredAeroportos();
    fetchCredHabilitacoes();
    fetchCredTipoDocs();
    fetchCredEstadoFormacoes();
    fetchCredFuncionariosResources();
    fetchIntervencoesTotais();
  }, []);

  const fetchCredFuncionarioById = (funcionarioId) => {
    dispatch(GetCredFuncionarioById(funcionarioId));
  };

  const fetchCredAeroportosByFuncId = (funcionarioId) => {
    dispatch(GetCredAeroportosByFuncId(funcionarioId));
  };

  const fetchCredFuncionariosUploadedFiles = (funcionarioId) => {
    dispatch(GetCredFuncionariosUploadedFiles(funcionarioId));
  };

  const fetchCredFuncionarioFormacaoByFormId = (formId) => {
    dispatch(GetCredFuncionarioFormacaoByFormId(formId));
  };

  const [cursoState, setCursoState] = useState({
    right: false,
  });
  const [drawerFuncState, setDrawerFuncState] = useState(null);
  const [drawerCursoState, setDrawerCursoState] = useState(null);
  const [state, setState] = useState({
    right: false,
  });

  const toggleFuncDrawer = (anchor, open, id) => {
    if (open == false) {
      setState({ ...state, [anchor]: open });
      setFuncionarioId(null);
      setFuncionario({ ...funcionario, nome: "" });
    }
    if (open == true) {
      setState({ ...state, [anchor]: open });
      setFuncionarioId(id);
      fetchCredFuncionarioById(id);
      fetchCredAeroportosByFuncId(id);
      fetchCredFuncionariosUploadedFiles(id);
    }
  };
  const toggleCursoDrawer = (anchor, open, id) => {
    if (open == false) {
      setCursoState({ ...state, [anchor]: open });
    }
    if (open == true) {
      setCursoState({ ...state, [anchor]: open });
      fetchCredFuncionarioFormacaoByFormId(id);
    }
  };

  // ############################################################## //
  // ####################  MUI Datatable columns  ################# //
  // ############################################################## //

  const intervencao = useSelector(
    (state) => state.credenciais.intervencaoSelecionada
  );

  const elementos = useSelector(
    (state) => state.credenciais.intervencaoElementos
  );

  const columns = [
    {
      name: "id",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "descricao",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
      },
    },

    {
      name: "empresa",
      label: "Empresa",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "documento",
      label: "Tipo Documento",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "doc_nr_identificacao",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "doc_nr_identificacao_controlo",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "",
      label: "Nr Documento",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography>
              {tableMeta.rowData[4]} {tableMeta.rowData[5]}
            </Typography>
          );
        },
      },
    },
    {
      name: "doc_validade",
      label: "Validade Doc",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {tableMeta.rowData[7] == null ? (
                " - "
              ) : (
                <>{format(new Date(tableMeta.rowData[7]), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { width: "5%" },
          align: "center",
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const o = tableMeta.rowData[0];
          const nome = tableMeta.rowData[1];

          return (
            <div style={{ display: "flex" }}>
              <IconButton
                onClick={() => {
                  navigate("/credenciacoes/credenciacoes", {
                    state: {
                      funcionarioNome: nome,
                    },
                  });
                }}
                style={{
                  padding: 6,
                  color: theme.palette.secondary.main,
                }}
              >
                <FeatherIcon
                  icon="clipboard"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
              <IconButton
                onClick={() => {
                  setDrawerFuncState("edit");
                  toggleFuncDrawer("right", true, o);
                }}
                style={{
                  padding: 6,
                  color: theme.palette.secondary.main,
                }}
              >
                <FeatherIcon
                  icon="edit"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
              <IconButton
                onClick={(e) => handleOpen(e, "delete", o)}
                style={{
                  padding: 6,
                  color: theme.palette.secondary.main,
                }}
              >
                <FeatherIcon
                  icon="trash-2"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },

    customToolbar: () => {
      return (
        <>
          <Tooltip title={"Adicionar Elemento"}>
            <IconButton
              className={classes.iconButton}
              onClick={(e) => {
                handleOpen(e, "add", null);
              }}
            >
              <FeatherIcon
                icon="plus-square"
                size={22}
                className={classes.deleteIcon}
                style={{ strokeWidth: "2" }}
              />
            </IconButton>
          </Tooltip>
        </>
      );
    },
  };

  // ############################################################## //
  // ####################  Render Switch Forms  ################### //
  // ############################################################## //

  // const renderCursoSwitch = (drawerCursoState) => {
  //   switch (drawerCursoState) {
  //     case "addCurso":
  //       return (
  //         <FormAddElemento
  //           toggleCursoDrawer={toggleCursoDrawer}
  //           dimensions={dimensions}
  //         />
  //       );
  //       break;
  //     case "editCurso":
  //       return (
  //         <FormEditElemento
  //           toggleCursoDrawer={toggleCursoDrawer}
  //           dimensions={dimensions}
  //         />
  //       );
  //       break;

  //     default:
  //       break;
  //   }
  // };

  return (
    <div
      className={classes.list}
      style={
        dimensions.width <= 1100
          ? { width: "60vw" }
          : { width: `${drawerWidth}` }
      }
    >
      <SideDrawer
        state={state}
        toggleDrawer={toggleFuncDrawer}
        cursoState={cursoState}
        setDrawerCursoState={setDrawerCursoState}
        toggleCursoDrawer={toggleCursoDrawer}
        drawerState={drawerFuncState}
        drawerCursoState={drawerCursoState}
        drawerWidth={"80vw"}
        dimensions={dimensions}
        funcionarioId={funcionarioId}
        funcionario={funcionario}
        setFuncionario={setFuncionario}
        fetchCredFuncionarios={fetchCredFuncionarios}
        formacao={formacao}
        setFormacao={setFormacao}
        empresas={empresas}
        aeroportos={aeroportos}
        habilitacoes={habilitacoes}
        tipoDocs={tipoDocs}
        resources={resources}
        estadosFormacoes={estadosFormacoes}
        fetchCredFuncionarioById={fetchCredFuncionarioById}
      />
      <ModalDelete
        openDelete={openDelete}
        handleClose={handleClose}
        intervencaoId={intervencaoId}
        funcionarioId={funcionarioId}
        fetchCredIntervencoesElementos={fetchCredIntervencoesElementos}
        // show={show}
        // Loading
        loading={loading}
        setLoading={setLoading}
      />
      <ModalAdicionar
        openAdd={openAdd}
        handleClose={handleClose}
        intervencaoId={intervencaoId}
        fetchCredIntervencoesElementos={fetchCredIntervencoesElementos}
        // show={show}
        // Loading
        loading={loading}
        setLoading={setLoading}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 5,
        }}
        onClick={() => toggleDrawer("right", false)}
      >
        <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
          <div>
            <FeatherIcon
              icon="chevron-left"
              size={20}
              style={{ strokeWidth: "1.5" }}
            />
          </div>
          <div className="text-underline-close-drawer">Intervenções</div>
        </Box>
      </Box>
      <Card
        sx={{
          m: 5,
          p: 2,
          boxShadow: "0px 10px 15px lightgrey",
        }}
      >
        <Box sx={{ mx: 2, mt: 2 }}>
          <h4
            style={{
              fontSize: "22px",
              width: "100%",
            }}
            className="text-underline"
          >
            Obra
          </h4>
          <Box sx={{ mt: 1 }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Nome da Obra
            </Typography>
            <TextField
              value={intervencao.descricao}
              InputProps={{ readOnly: true }}
              size="small"
              variant="outlined"
              sx={{ width: "100%" }}
            />
          </Box>
          <Box sx={{ display: "flex", mt: 3, justifyContent: "space-between" }}>
            <Box sx={{ width: "19%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                ID
              </Typography>
              <TextField
                value={intervencao.id}
                InputProps={{ readOnly: true }}
                size="small"
                variant="outlined"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "79%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Responsável
              </Typography>
              <TextField
                value={intervencao.responsavel}
                InputProps={{ readOnly: true }}
                size="small"
                variant="outlined"
                sx={{ width: "100%" }}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mx: 1 }}>
          <Box
            sx={{
              mx: 1,
              width: "100%",
            }}
          >
            <MUIDataTable
              data={elementos || []}
              title={
                <h4
                  style={{
                    fontSize: "22px",
                    width: "100%",
                  }}
                  className="text-underline"
                >
                  Colaboradores
                </h4>
              }
              columns={columns}
              options={options}
            />
          </Box>
        </Box>
      </Card>
    </div>
  );
};

export default FormEdit;
